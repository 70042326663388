/** @jsxImportSource @emotion/react */

import React from 'react'
import { jsx, css } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import useRootStore from '@store/useRootStore'
import { numberWithCommas } from '@utils/utils'
import asset_channel from '@assets/channel'

// css
import { Box, Button, Container, Grid } from '@mui/material'
import { FooterStyle } from './index.style'

import { MEDIA_MODE } from '~/store/globalStore'
//import { getDeviceType } from '~/game/util'

// image
import {
  gameTitle01,
  gameTitle02,
  gameTitle03,
  gameTitle04,
  gameTitle05,
  gameTitle06,
  gameTitle07,
  gameTitle08,
  gameTitle09,
  gameTitle10,
  gameTitle11,
  gameTitle12,
  gameTitle13,
  gameTitle14,
  gameTitle15,
  gameTitle16,
  gameTitle17,
  gameTitle18,
  gameTitle19,
  gameTitle20,
  gameTitle21,
} from '@assets/images/layout_1'
import { useIntl } from 'react-intl'

const APP_NAME = process.env.REACT_APP_NAME || ''

function Footer() {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()
  const { partnerMode } = globalStore

  const { desktop } = globalStore.decideMedia(isDesktop)

  const onClickSwitchDesktopView = (mode: string) => {
    globalStore.setMediaMode(mode)
  }

  let boxStyle = {}
  let switchContents = null

  if (desktop) {
    boxStyle = {
      py: 5,
      marginLeft: '290px',
      minWidth: 1368,
      '& .MuiContainer-root': {
        maxWidth: 1368,
      },
      height: 255,
      '& .logo_wrap > img': {
        height: 50,
      },
      '& .partner_img > img': {
        width: 110,
      },
    }

    if (!isDesktop) {
      switchContents = (
        <Button onClick={() => onClickSwitchDesktopView(MEDIA_MODE.RESPONSIVE)} sx={{ color: '#cccccc' }}>
          <SmartphoneIcon></SmartphoneIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.mobile-view' })}
        </Button>
      )
    }
  } else {
    boxStyle = {
      py: 2,
      '& .logo_wrap > img': {
        height: 55,
      },
      '& .partner_img > img': {
        width: '20%',
      },
    }
    if (!isDesktop) {
      switchContents = (
        <Button onClick={() => onClickSwitchDesktopView(MEDIA_MODE.DESKTOP)} sx={{ color: '#cccccc' }}>
          <DesktopWindowsIcon></DesktopWindowsIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.pc-view' })}
        </Button>
      )
    }
  }

  return (
    <FooterStyle sx={boxStyle} className="footer">
      <Box>{switchContents}</Box>
      <Container fixed>
        <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Grid item className="logo_wrap">
            <img src={asset_channel.common.footer_logo} alt="" />
          </Grid>
          <Grid item className="copyright">
            Copyright ⓒ<span>{APP_NAME}</span> All rights Reserved.
          </Grid>
        </Grid>

        <Box className="partner_img" sx={{ mt: 2 }}>
          <img src={gameTitle01} alt="" />
          <img src={gameTitle02} alt="" />
          <img src={gameTitle03} alt="" />
          <img src={gameTitle04} alt="" />
          <img src={gameTitle05} alt="" />
          <img src={gameTitle06} alt="" />
          <img src={gameTitle07} alt="" />
          <img src={gameTitle08} alt="" />
          <img src={gameTitle09} alt="" />
          <img src={gameTitle10} alt="" />
          <img src={gameTitle11} alt="" />
          <img src={gameTitle12} alt="" />
          <img src={gameTitle13} alt="" />
          <img src={gameTitle14} alt="" />
          <img src={gameTitle15} alt="" />
          <img src={gameTitle16} alt="" />
          <img src={gameTitle17} alt="" />
          <img src={gameTitle18} alt="" />
          <img src={gameTitle19} alt="" />
          <img src={gameTitle20} alt="" />
          <img src={gameTitle21} alt="" />
        </Box>
      </Container>
    </FooterStyle>
  )
}
export default Footer
