import { observable, action, makeObservable, runInAction } from 'mobx'
import * as _ from 'lodash'
import cookies from 'react-cookies'

import { StoreExt } from '@utils/reactExt'
import { delay } from '@utils/utils'
import { authStore, globalStore, miniGameStore, userStore, sportsStore } from '@store/index'
import { SocketStore } from '@store/socketStore'
import { Message, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { ERR } from '@interface/error'
import { SpoLiveProtocol } from '@spolive/spolive-protocol'
import {
  PacketStructure,
  IEnterSpace,
  IReloadSpace,
  IAuth,
  IWatch,
  INotifyPrematches,
  INotifyMarkets,
  INotifyPrematch,
  INotifyMarket,
  ILeaveSpace,
  INotifySportsOptions,
  INotifyMetas,
} from '@spolive/spolive'
import { ISyncEnter } from '@live/live'

import { LIST_FLAG } from '~/v2/interface/types'
import { makeBrowserData } from '~/game/util'
import { timelineLog } from '~/utils'
import { testExchangeRate } from '../sportsStore/sports-util'

export class SportsLiveStore extends SocketStore {
  targetSpace = '' // provider
  targetTradingGroup = ''
  targetTradingKind = ''
  targetGameId = ''
  targetWatch = ''

  @observable
  inSpace = ''

  @observable
  inTradingGroup = ''

  @observable
  inTradingKind = ''

  @observable
  inGameId = ''

  @observable
  inWatch = ''

  @observable
  updatedQna = 0

  @observable
  spaceReloadedTimestamp = 0

  constructor() {
    super()

    makeObservable(this)

    this.updateUrl()

    this.dispatchMessage = this.handleMessage

    const intervaleSec = 10
    setInterval(() => {
      // 10초마다 핑 테스트 session id 로 핑 테스트
      if (authStore.sid && this.serviceState === SOCKET_SERVICE_STATE.ENTERED) {
        const data: IAuth.Params = {
          sid: authStore.sid,
        }
        this.sendPacket(SpoLiveProtocol.AUTH, data)
      }
    }, intervaleSec * 1000)
  }

  updateUrl() {
    if (sportsStore.flight === 'inplay') {
      this.url = process.env.REACT_APP_INPLAY_LIVE_URL
      if (process.env.NODE_ENV === 'production') {
        const protocol = process.env.REACT_APP_INPLAY_LIVE_PROTOCOL
        const port = process.env.REACT_APP_INPLAY_LIVE_PORT
        const { hostname } = window.location
        this.url = `${protocol}://${hostname}:${port}`
      }
    } else {
      this.url = process.env.REACT_APP_PREMATCH_LIVE_URL
      if (process.env.NODE_ENV === 'production') {
        const protocol = process.env.REACT_APP_PREMATCH_LIVE_PROTOCOL
        const port = process.env.REACT_APP_PREMATCH_LIVE_PORT
        const { hostname } = window.location
        this.url = `${protocol}://${hostname}:${port}`
      }
    }
  }

  @action
  spaceReloaded = () => {
    this.spaceReloadedTimestamp = new Date().getTime()
  }

  @action
  setInSpace = (space: string) => {
    if (this.inSpace !== space) {
      this.inSpace = space
    }
  }

  @action
  setInTrading = (group: string, kind: string) => {
    if (this.inTradingGroup !== group) {
      this.inTradingGroup = group
    }
    if (this.inTradingKind !== kind) {
      this.inTradingKind = kind
    }
  }

  @action
  setInGameId = (gameId: string) => {
    if (this.inGameId !== gameId) {
      this.inGameId = gameId
    }
  }

  @action
  setInWatch = (watch: string) => {
    if (this.inWatch !== watch) {
      this.inWatch = watch
    }
  }

  @action
  cleanup = () => {
    this.targetSpace = ''
    this.targetTradingGroup = ''
    this.targetTradingKind = ''
    this.targetGameId = ''
    this.targetWatch = ''

    this.inSpace = ''
    this.inTradingGroup = ''
    this.inTradingKind = ''
    this.inGameId = ''
    this.inWatch = ''

    // this.close()
    this.rebind()
  }

  @action
  setUpdatedQna = () => {
    this.updatedQna = new Date().getTime()
  }

  @action
  enter = async () => {
    timelineLog(`SportsLiveStore enter. flight: ${sportsStore.flight}`)
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.DISCONNECTED) {
        return
      }

      if (!authStore.sid) {
        timelineLog('sid is not set')
        return
      }

      this.targetSpace = ''
      this.targetTradingGroup = ''
      this.targetTradingKind = ''
      this.targetGameId = ''
      this.targetWatch = ''

      this.setInSpace('')
      this.setInTrading('', '')
      this.setInGameId('')
      this.setInWatch('')

      this.updateUrl()

      await this.connect()

      // 이미 odds 서버의 서비스에 입장하였어도 입장을 요청한다.(서버쪽에서 같은 인스턴스에 대한 중복 처리)
      this.setServiceState(SOCKET_SERVICE_STATE.ENTERING)

      // const data: ISyncEnter.Params = {
      //   uuid: userStore.userInfo.uuid,
      //   authKey: 'b',
      //   overlapLogin: true,
      // }
      // this.sendPacket(SpoLiveProtocol.SYNC_ENTER, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  enterSpace = async (
    spaceName: string,
    trading_group: string,
    trading_kind: string,
    gameId: string,
    retry: boolean,
  ) => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      timelineLog('SportsLiveStore enter space')

      this.targetSpace = spaceName
      this.targetTradingGroup = trading_group
      this.targetTradingKind = trading_kind
      this.targetGameId = gameId
      this.targetWatch = ''

      this.setInSpace('')
      this.setInTrading('', '')
      this.setInGameId('')
      this.setInWatch('')

      const data: IEnterSpace.Params = {
        spaceName,
        trading_group,
        trading_kind,
        gameId,
        retry,
      }
      this.sendPacket(SpoLiveProtocol.ENTER_SPACE, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  leaveSpace = async () => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      timelineLog('SportsLiveStore leave space')

      this.targetSpace = ''
      this.targetTradingGroup = ''
      this.targetTradingKind = ''
      this.targetGameId = ''
      this.targetWatch = ''

      this.setInSpace('')
      this.setInTrading('', '')
      this.setInGameId('')
      this.setInWatch('')

      const data: ILeaveSpace.Params = {}
      this.sendPacket(SpoLiveProtocol.LEAVE_SPACE, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  reloadSpace = async () => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      timelineLog('SportsLiveStore reload space')

      const data: IReloadSpace.Params = {
        spaceName: this.targetSpace,
        trading_group: this.targetTradingGroup,
        trading_kind: this.targetTradingKind,
        gameId: this.targetGameId,
      }
      this.sendPacket(SpoLiveProtocol.RELOAD_SPACE, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  watch = async (name: string, filter: string) => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      timelineLog('SportsLiveStore watch')

      this.targetWatch = name

      const data: IWatch.Params = {
        name,
        filter,
      }
      this.sendPacket(SpoLiveProtocol.WATCH, data)

      sportsStore.setFetching(true)
    } catch (err) {
      console.error(err.message)
    }
  }

  handleMessage = (msg: Message) => {
    if (msg.from !== 'server') {
      const { event } = msg
      switch (event) {
        case 'connect':
          userStore.fetchedPosition = ''
          this.setServiceState(SOCKET_SERVICE_STATE.CONNECTED)
          const data: ISyncEnter.Params = {
            userType: 'user',
            uuid: userStore.userInfo.uuid,
            sid: authStore.sid,
            overlapLogin: false,
          }
          this.sendPacket(SpoLiveProtocol.ENTER, data)
          break
        case 'close':
          userStore.fetchedPosition = ''
          this.setServiceState(SOCKET_SERVICE_STATE.DISCONNECTED)
          this.setInSpace('')
          this.setInTrading('', '')
          this.setInGameId('')
          this.setInWatch('')
          break
      }
      return
    }
    if (msg.data === 'SR') {
      return
    }

    try {
      const packet = JSON.parse(msg.data) as PacketStructure
      const { data } = packet

      switch (packet.protocol) {
        case SpoLiveProtocol.ENTER:
          {
            // timelineLog(`SYNC_ENTER: ${JSON.stringify(packet.data)}`)
            const { errcode } = data as ISyncEnter.Schema
            if (errcode === ERR.OK) {
              this.setServiceState(SOCKET_SERVICE_STATE.ENTERED)
            } else {
              this.cleanup()
              if (errcode === ERR.INVALID_AUTHKEY) {
                //
              } else {
                globalStore.pushDialogLocaleOk({
                  title: 'error',
                  text: 'msg.failed.user_authentication',
                })
              }
            }
          }
          break
        case SpoLiveProtocol.ENTER_SPACE:
          {
            // timelineLog(`SYNC_ENTER_SPACE: ${JSON.stringify(packet.data)}`)
            const {
              spaceName,
              trading_group,
              trading_kind,
              gameId,
              url,
              errcode,
            } = data as IEnterSpace.Schema
            if (errcode === ERR.OK) {
              this.setInSpace(spaceName)
              this.setInTrading(trading_group, trading_kind)
              this.setInGameId(gameId)
              if (url) {
                globalStore.popupGame(trading_kind, gameId, url)
              }
            } else {
              if (errcode === ERR.ALREADY_IN_SPACE) {
                // maybe do something
                const a = 0
              } else if (errcode === ERR.RETRY_ENTER_SPACE) {
                globalStore.pushDialogLocaleOk({ text: 'msg.try_again_later' })
              } else {
                // this.cleanup()
                globalStore.pushDialogLocaleOk({
                  title: 'error',
                  text: 'msg.failed.enter_game',
                  arg1: errcode.toString(),
                })
              }
            }
          }
          break
        case SpoLiveProtocol.RELOAD_SPACE:
          this.spaceReloaded()
          break
        case SpoLiveProtocol.AUTH:
          const { errcode } = data as IAuth.Schema
          if (errcode) {
            authStore.logout(null)
            this.cleanup()
            globalStore.pushDialogLocaleOk({
              text: 'msg.logged_out.inactivity',
            })
          }
          break
        case SpoLiveProtocol.WATCH:
          {
            // timelineLog(`WATCH: ${JSON.stringify(packet.data)}`)
            const { name, errcode } = data as IWatch.Schema
            if (errcode === ERR.OK) {
              this.setInWatch(name)
            } else {
              globalStore.pushDialogLocaleOk({
                text: 'msg.failed.game_monitor',
                arg1: errcode.toString(),
              })
            }
          }
          break
        case SpoLiveProtocol.NOTIFY_SPORTS_OPTIONS:
          {
            // timelineLog('NOTIFY_SPORTS_OPTIONS')
            const { options } = data as INotifySportsOptions.Schema
            // console.log(options.sports_configs)
            sportsStore.setSportsOptions(options)
          }
          break
        // sports
        case SpoLiveProtocol.NOTIFY_PREMATCHES:
          {
            const { name, list_flag, items } = data as INotifyPrematches.Schema
            // timelineLog(`NOTIFY_PREMATCHES items.length:${items ? items.length : 0}`)
            if (list_flag === LIST_FLAG.LIST_BEGIN) {
              sportsStore.dataQueue.statePrematches = 'none'
              sportsStore.dataQueue.initialPrematches = []
              // timelineLog('NOTIFY_PREMATCHES begin')
            }
            if (items) {
              sportsStore.dataQueue.initialPrematches.push(...items)
            }
            if (list_flag === LIST_FLAG.LIST_END) {
              sportsStore.dataQueue.statePrematches = 'pending'
              // timelineLog(`NOTIFY_PREMATCHES end size: ${sportsStore.dataQueue.initialPrematches.length}`)
            }
          }
          break
        case SpoLiveProtocol.NOTIFY_MARKETS:
          {
            const {
              name,
              list_flag,
              items,
              sliceSize,
              total,
              accSize,
              count,
              size,
            } = data as INotifyMarkets.Schema
            // console.log(`NOTIFY_MARKETS items.length:${items ? items.length : 0}`)
            if (list_flag === LIST_FLAG.LIST_BEGIN) {
              sportsStore.dataQueue.stateMarkets = 'none'
              sportsStore.dataQueue.initialMarkets = []
              // timelineLog(`NOTIFY_MARKETS begin ${sliceSize}, ${total} / ${accSize}, ${count}, ${size}`)
            }
            if (items && items.length > 0) {
              sportsStore.dataQueue.initialMarkets.push(...items)
              // timelineLog(`NOTIFY_MARKETS ${sliceSize}, ${total} / ${accSize}, ${count}, ${size}`)
            }
            if (list_flag === LIST_FLAG.LIST_END) {
              sportsStore.dataQueue.stateMarkets = 'pending'
              // timelineLog(`NOTIFY_MARKETS end size: ${sliceSize}, ${total} / ${accSize}, ${count}, ${size} / ${sportsStore.dataQueue.initialMarkets.length}`)
            }
          }
          break
        case SpoLiveProtocol.NOTIFY_PREMATCH:
          {
            const { prematch } = data as INotifyPrematch.Schema
            // timelineLog(`NOTIFY_PREMATCH fixtureId:${prematch.FixtureId}`)
            sportsStore.dataQueue.updatePrematches.push(prematch)
          }
          break
        case SpoLiveProtocol.NOTIFY_MARKET:
          {
            const { market } = data as INotifyMarket.Schema
            // timelineLog(`NOTIFY_MARKET fixtureId:${market.FixtureId}`)
            sportsStore.dataQueue.updateMarkets.push(market)
          }
          break
        case SpoLiveProtocol.NOTIFY_METAS:
          {
            const { name, list_flag, items } = data as INotifyMetas.Schema
            if (list_flag === LIST_FLAG.LIST_BEGIN) {
              // timelineLog('NOTIFY_METAS begin')
            }
            sportsStore.meta_manager.updateMetas(name, items)
            if (list_flag === LIST_FLAG.LIST_END) {
              // timelineLog('NOTIFY_METAS end')
            }
          }
          break
        case SpoLiveProtocol.NOTIFY_AHEAD_MARKETS:
          {
            const {
              name,
              list_flag,
              items,
              sliceSize,
              total,
              accSize,
              count,
              size,
            } = data as INotifyMarkets.Schema
            // console.log(`NOTIFY_AHEAD_MARKETS items.length:${items ? items.length : 0}`)
            if (list_flag === LIST_FLAG.LIST_BEGIN) {
              sportsStore.dataQueue.stateMarkets = 'none'
              sportsStore.dataQueue.initialMarkets = []
              // timelineLog(`NOTIFY_AHEAD_MARKETS begin ${sliceSize}, ${total} / ${accSize}, ${count}, ${size}`)
            }
            if (items && items.length > 0) {
              sportsStore.dataQueue.initialMarkets.push(...items)
              // timelineLog(`NOTIFY_AHEAD_MARKETS ${sliceSize}, ${total} / ${accSize}, ${count}, ${size}`)
            }
            if (list_flag === LIST_FLAG.LIST_END) {
              sportsStore.dataQueue.stateMarkets = 'pending'
              // timelineLog(`NOTIFY_AHEAD_MARKETS end size: ${sliceSize}, ${total} / ${accSize}, ${count}, ${size} / ${sportsStore.dataQueue.initialMarkets.length}`)
            }
          }
          break
        case SpoLiveProtocol.NOTIFY_BEHIND_MARKETS:
          {
            const {
              name,
              list_flag,
              items,
              sliceSize,
              total,
              accSize,
              count,
              size,
            } = data as INotifyMarkets.Schema
            // console.log(`NOTIFY_BEHIND_MARKETS items.length:${items ? items.length : 0}`)
            if (list_flag === LIST_FLAG.LIST_BEGIN) {
              sportsStore.dataQueue.behindState = 'none'
              sportsStore.dataQueue.behindMarkets = []
              // timelineLog(`NOTIFY_BEHIND_MARKETS begin ${sliceSize}, ${total} / ${accSize}, ${count}, ${size}`)
            }
            if (items && items.length > 0) {
              sportsStore.dataQueue.behindMarkets.push(...items)
              // timelineLog(`NOTIFY_BEHIND_MARKETS ${sliceSize}, ${total} / ${accSize}, ${count}, ${size}`)
            }
            if (list_flag === LIST_FLAG.LIST_END) {
              sportsStore.dataQueue.behindState = 'pending'
              // timelineLog(`NOTIFY_BEHIND_MARKETS end size: ${sliceSize}, ${total} / ${accSize}, ${count}, ${size} / ${sportsStore.dataQueue.behindMarkets.length}`)
            }
          }
          break
      }
    } catch (err) {
      console.error(err.message)
      console.error(err.stack)
    }
  }
}

export default new SportsLiveStore()
