import { css, Theme } from '@emotion/react'
//import styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

// 모달 공통 스타일
export const GlobalStyle = () => css`
  overflow-x: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* scrollbar custom for desktop */
  span,
  ol,
  ul,
  pre,
  div {
    scrollbar-width: thin;
    scrollbar-color: #182740 transparent;
  }
  span::-webkit-scrollbar,
  ol::-webkit-scrollbar,
  ul::-webkit-scrollbar,
  pre::-webkit-scrollbar,
  div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  span::-webkit-scrollbar-thumb,
  ol::-webkit-scrollbar-thumb,
  ul::-webkit-scrollbar-thumb,
  pre::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: #182740;
  }
  span:hover,
  ol:hover,
  ul:hover,
  pre:hover,
  div:hover {
    scrollbar-color: #19253a transparent;
  }
  span:hover::-webkit-scrollbar-thumb,
  ol:hover::-webkit-scrollbar-thumb,
  ul:hover::-webkit-scrollbar-thumb,
  pre:hover::-webkit-scrollbar-thumb,
  div:hover::-webkit-scrollbar-thumb {
    background-color: #19253a;
  }
`

export const MobileDrawerStyle = (theme: Theme) => css`
  .MuiPaper-root {
    margin: 0;
    max-width: none;
    width: 100%;
    min-height: 100%;
    background-color: ${theme.colors.modalBg};
  }
  .MuiAppBar-root {
    background-color: #080f1c;
    padding: 11px 0;
    box-shadow: none;

    .modal_title {
      padding-left: 17px;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
      top: 5px;
      right: 12px;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
`

export const MobileModalStyle = (theme: Theme) => css`
  .MuiDialog-paper {
    margin: 0;
    max-width: none;
    width: 100%;
    min-height: 100%;
    background-color: ${theme.colors.modalBg};
  }
  .MuiAppBar-root {
    background-color: #080f1c;
    padding: 11px 0;
    box-shadow: none;

    .modal_title {
      padding-left: 17px;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
      top: 5px;
      right: 12px;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
`

export const MobileModalStyle2 = (theme: Theme) => css`
  .MuiDialog-paper {
    margin: 0;
    max-width: none;
    width: 100%;
    min-height: 100%;
    background-color: ${theme.colors.modalBg};
  }
  .MuiAppBar-root {
    background-color: transparent;
    box-shadow: none;

    .modal_title {
      padding-left: 17px;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
      top: 5px;
      right: 12px;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
`

export const ModalStyle = (theme: Theme) => css`
  overflow-x: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* scrollbar custom for desktop */
  span,
  ol,
  ul,
  pre,
  div {
    scrollbar-width: thin;
    scrollbar-color: #182740 transparent;
  }
  span::-webkit-scrollbar,
  ol::-webkit-scrollbar,
  ul::-webkit-scrollbar,
  pre::-webkit-scrollbar,
  div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  span::-webkit-scrollbar-thumb,
  ol::-webkit-scrollbar-thumb,
  ul::-webkit-scrollbar-thumb,
  pre::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: #182740;
  }
  span:hover,
  ol:hover,
  ul:hover,
  pre:hover,
  div:hover {
    scrollbar-color: #19253a transparent;
  }
  span:hover::-webkit-scrollbar-thumb,
  ol:hover::-webkit-scrollbar-thumb,
  ul:hover::-webkit-scrollbar-thumb,
  pre:hover::-webkit-scrollbar-thumb,
  div:hover::-webkit-scrollbar-thumb {
    background-color: #19253a;
  }

  .MuiDialog-container {
    height: auto;
  }

  .MuiDialog-paper {
    height: auto;
    margin: 80px auto;
    min-height: calc(100vh - 240px);
    background-color: ${theme.colors.modalBg};
  }
  
  &.login_dialog .MuiDialog-paper {
    min-width: 500px;
    max-width: 500px;
  }

  .modal_content {
    height: 100%;
  }

  .MuiAppBar-root {
    background-color: #080f1c;
    padding: 11px 35px 11px 11px;
    box-shadow: none;

    .modal_title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
      top: 5px;
      right: 12px;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
`

export const ModalStyle2 = (theme: Theme) => css`
  overflow-x: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* scrollbar custom for desktop */
  span,
  ol,
  ul,
  pre,
  div {
    scrollbar-width: thin;
    scrollbar-color: #182740 transparent;
  }
  span::-webkit-scrollbar,
  ol::-webkit-scrollbar,
  ul::-webkit-scrollbar,
  pre::-webkit-scrollbar,
  div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  span::-webkit-scrollbar-thumb,
  ol::-webkit-scrollbar-thumb,
  ul::-webkit-scrollbar-thumb,
  pre::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: #182740;
  }
  span:hover,
  ol:hover,
  ul:hover,
  pre:hover,
  div:hover {
    scrollbar-color: #19253a transparent;
  }
  span:hover::-webkit-scrollbar-thumb,
  ol:hover::-webkit-scrollbar-thumb,
  ul:hover::-webkit-scrollbar-thumb,
  pre:hover::-webkit-scrollbar-thumb,
  div:hover::-webkit-scrollbar-thumb {
    background-color: #19253a;
  }

  .MuiDialog-container {
    height: auto;
  }

  .MuiDialog-paper {
    height: auto;
    margin: 80px auto;
    min-height: calc(100vh - 240px);
    background-color: #101118;
    border: 1px solid #41424c;
  }
  
  &.login_dialog .MuiDialog-paper {
    min-width: 500px;
    max-width: 500px;
  }

  .modal_content {
    height: 100%;
  }

  .MuiAppBar-root {
    background-color: #101118;
    box-shadow: none;

    .MuiButtonBase-root {
      position: absolute;
      top: 5px;
      right: 12px;
    }
    .MuiSvgIcon-root {
      fill: rgb(255 255 255 / 50%);
      width: 3rem;
      height: 3rem;
    }
  }
`

// form style
export const FormStyle = styled('div')`
  .form_table {
    width: 100%;
    border-top: 1px solid #1e2b3d;

    & th,
    & td {
      border-bottom: 1px solid #1e2b3d;
    }
    & th {
      background-color: #16202f;
      padding: 8px 24px;
      background-color: #16202f;
      font-weight: normal;
    }
    & td {
      padding: 8px 16px;
    }
  }

  .form_label {
    color: ${props => props.theme.colors.themeTxt};
    font-size: ${props => props.theme.fonts.small};
  }
  .form_control {
    width: 100%;
    padding: 0.75rem;
    background-color: ${props => props.theme.forms.formBg};
    border: 1px solid ${props => props.theme.forms.formLine};
    border-radius: 0.3rem;
    color: ${props => props.theme.colors.themeTxt};

    &::placeholder {
      color: ${props => props.theme.forms.placeholderColor};
    }

    &.disabled {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      background-color: ${props => props.theme.forms.disabledBg};
    }
    &:disabled {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      backgroundcolor: ${props => props.theme.forms.disabledBg};
    }

    &.readonly {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      background-color: ${props => props.theme.forms.disabledBg};
    }
    &:readonly {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      backgroundcolor: ${props => props.theme.forms.disabledBg};
    }
  }
  .required:after {
    color: #ff2e2e;
    content: '*';
    font-size: inherit;
    font-weight: 700;
    padding-left: 0.25rem;
    position: relative;
  }

  .text_info {
    background-color: ${props => props.theme.colors.mainBg};
    border: 1px solid ${props => props.theme.colors.modalLine};
    color: #fe3d3d;
  }
  .text_info .MuiInputBase-root {
    color: ${props => props.theme.colors.themeTxt};
  }
`

// form style New
export const FormStyle2 = styled('div')`
  .form_table {
    width: 100%;
    border-top: 1px solid #1e2b3d;

    & th,
    & td {
      border-bottom: 1px solid #1e2b3d;
    }
    & th {
      background-color: #16202f;
      padding: 8px 24px;
      background-color: #16202f;
      font-weight: normal;
    }
    & td {
      padding: 8px 16px;
    }
  }

  .form_label {
    color: ${props => props.theme.colors.themeTxt};
    font-size: ${props => props.theme.fonts.small};
  }
  .form_control {
    width: 100%;
    padding: 0.75rem;
    background-color: #1d1e24;
    border: 1px solid #41424c;
    border-radius: 0.3rem;
    color: ${props => props.theme.colors.themeTxt};

    &::placeholder {
      color: #9597a4;
    }

    &.disabled {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      background-color: ${props => props.theme.forms.disabledBg};
    }
    &:disabled {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      backgroundcolor: ${props => props.theme.forms.disabledBg};
    }

    &.readonly {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      background-color: ${props => props.theme.forms.disabledBg};
    }
    &:readonly {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      backgroundcolor: ${props => props.theme.forms.disabledBg};
    }
  }
  .required:after {
    color: #ff2e2e;
    content: '*';
    font-size: inherit;
    font-weight: 700;
    padding-left: 0.25rem;
    position: relative;
  }

  .text_info {
    background-color: ${props => props.theme.colors.mainBg};
    border: 1px solid ${props => props.theme.colors.modalLine};
    color: #fe3d3d;
  }
  .text_info .MuiInputBase-root {
    color: ${props => props.theme.colors.themeTxt};
  }
`

export const NoList = styled('div')`
  text-align: center;
  margin-top: 40px;
  font-size: 14px;
  color: ${props => props.theme.colors.themeTxt};

  & > img: {
    width: 100%,
    max-width: 417px;
  }
`

export const TableStyle = styled('div')`
  min-height: 500px;
  height: auto;
  text-align: center;

  .table {
    background-color: ${props => props.theme.tabs.default};
    --bs-table-striped-color: ${props => props.theme.colors.themeTxt};

    margin-bottom: 0;
    table-layout: fixed;
    vertical-align: middle;
    border: ${props => props.theme.borders.default};
    color: ${props => props.theme.colors.themeTxt};
  }
  .table > thead th {
    background-color: ${props => props.theme.boards.titleBg};
    font-weight: normal;
  }
  .table > tbody .table > :not(caption) > * > * {
    padding: 0.8rem 0.5rem;
  }
  .table > :not(:first-child) {
    border-top: none;
  }
  .table td .date {
    text-align: center;
    color: ${props => props.theme.colors.themeTxtLighten};
    font-size: ${props => props.theme.fonts.default};
  }
  .table td .read_mark {
    color: ${props => props.theme.colors.themeTxtLighten};
    font-size: ${props => props.theme.fonts.tiny};
  }
  .table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: ${props => props.theme.boards.contentBg};
  }
  a {
    color: ${props => props.theme.colors.themeTxt};
  }
  a:hover {
    color: ${props => props.theme.boards.txtHover};
  }
  .MuiChip-root {
    height: 26px;
    color: #fff;
    font-size: 0.9rem;
  }

  .MuiTable-root {
    background-color: ${props => props.theme.tabs.default};
    margin-bottom: 0;
    table-layout: fixed;
  }
  .MuiTableRow-root {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
  }
  th.MuiTableCell-root {
    font-size: 1rem;
    background-color: ${props => props.theme.boards.titleBg};
    color: ${props => props.theme.colors.themeTxt};
    font-weight: normal;
  }
  td.MuiTableCell-root {
    padding: 10px 16px;
    font-size: 0.9rem;
    border-bottom: ${props => props.theme.borders.default};
    color: ${props => props.theme.colors.themeTxtLighten};
  }
`

export const MobileTableStyle = styled(Box)(({ theme }) => ({
  '.MuiGrid-container': {
    alignItems: 'center',
    marginBottom: 16,
    backgroundColor: '#16202F',
    borderRadius: 4,
    color: '#D1D9E3',
    padding: 16,
    border: '1px solid #1E2B3D',

    '& .date': {
      color: '#747D8A',
      textAlign: 'end',
    },
    '& .choice': {
      fontSize: '0.9rem',
    },
    '& .align_right_side': {
      textAlign: 'end',
      '.align_inner': {
        display: 'inline-flex',
        flexDirection: 'column',
        textAlign: 'start',
        width: '58px',
      },
    },
    '.MuiChip-root': {
      minWidth: 56,
      height: 20,
      color: '#fff',
      '& .MuiChip-label': {
        padding: 0,
      },
    },
    '& .MuiBox-root.title': {
      paddingTop: 5,
      color: '#747D8A',
      fontWeight: 'normal',
      fontSize: '0.9rem',
    },
  },
}))

export const BoardStyle = styled('div')`
  &.board_list {
    text-align: center;

    .list_head {
      background-color: ${props => props.theme.boards.titleBg};
      border-bottom: ${props => props.theme.borders.default};
      color: ${props => props.theme.colors.themeTxt};
      font-weight: normal;
    }
    .list_title {
      background-color: #16202f;
      border-bottom: 1px solid #1e2b3d;
      color: #d1d9e3;
    }
  }

  &.board_view {
    border-bottom: ${props => props.theme.borders.default};
    text-align: start;
    background-color: ${props => props.theme.boards.titleBg};

    .board_content {
      color: ${props => props.theme.colors.themeTxtLighten};
      font-size: 1.1rem;
    }
    .board_content img {
      max-width: 100%;
    }
    .board_pre {
      margin-bottom: 0;
      font-family: 'Noto Sans';
      font-size: 1.1rem;
    }
  }
`
