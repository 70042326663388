import { observable, action, makeObservable, computed } from 'mobx'
import * as _ from 'lodash'

import { StoreExt } from '@utils/reactExt'
import { globalStore, miniGameStore, userStore } from '@store/index'
import { Schema$SlotGameData, ISlotGameList } from '@protocol/casino'
import { IntlShape } from 'react-intl'

export class CasinoStore extends StoreExt {
  @observable
  slItems: { [trading_kind: string]: Schema$SlotGameData } = {}

  constructor() {
    super()

    makeObservable(this)
  }

  @action
  reset = () => {
    this.slItems = {}
  }

  @action
  fetchSlotGames = async (kind: string, intl: IntlShape) => {
    try {
      const params: ISlotGameList.Params = {
        trading_kind: kind,
      }
      const { data } = await this.api.public.slotGames(params)
      const cloned = _.clone(this.slItems)
      cloned[data.trading_kind] = data
      this.slItems = cloned
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }
}

export default new CasinoStore()
