/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Container, Button, Grid, Box, Typography } from '@mui/material'

import asset_channel from '@assets/channel'
import JackpotCounter from './JackpotCounter'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { randomBetween } from '~/utils/utils'
import apiPublic from '@services/api/public'

const CounterBanner = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& > img': {
    width: '100%',
  },
  '& > .MuiBox-root': {
    position: 'absolute',
    top: '51%',
    right: 0,
    display: 'flex',
    alignItems:  'end',
    justifyContent: 'center',
    width: '100%',
  },
  '.MuiTypography-root': {
    color: '#ffc801',
    fontFamily: 'Gmarket',
    filter: 'drop-shadow(1px 1px 2px #111)',
  },
}))

let timer: NodeJS.Timeout = null

function Jackpot() {
  const [volume, setVolume] = useState(0) // 최대 1000억자리

  const fetchJackpot = async () => {
    try {
      const { volume } = await apiPublic.jackpot({})
      setVolume(volume)
      // const r = Math.floor(randomBetween(111111111111, 999999999999))
      // setVolume(r)
    } catch (err) {
      //
    }
  }

  useOnMount(() => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setInterval(() => {
      fetchJackpot()
    }, 5000) as NodeJS.Timeout
    fetchJackpot()
  })

  useOnUnmount(() => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  })

  return (
    <CounterBanner>
      <img src={asset_channel.common.m_banner_s01} alt="small banner" />
      <Box>
        <Typography sx={{ fontSize: 20, mr: 1 / 4, mb: 1 / 2 }}>₩ </Typography>
        <JackpotCounter volume={volume} />
      </Box>
    </CounterBanner>
  )
}

export default Jackpot
