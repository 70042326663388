import React, { useState } from 'react'
import * as _ from 'lodash'
import { Grid } from '@mui/material'
import { getDetailOddsboxName, getOddsRates } from '~/game/st-util'
import { Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import { observer } from 'mobx-react'
import useRootStore from '~/store/useRootStore'
import { Theme } from '@mui/material/styles'
import DetailOddsBoxAtom from './DetailOddsBoxAtom'

interface OddsProps {
  theme: Theme
  prematch: Schema$Prematch
  market: Schema$Market
}

function DetailOddsBox12({ theme, prematch, market }: OddsProps) {
  const { sportsStore } = useRootStore()
  const { cartItems, lang } = sportsStore

  const { FixtureId } = prematch

  const { bets } = getOddsRates('12', null, market.Bets)
  const [home, away] = bets

  if (!home || !away) {
    return <></>
  }

  const homeName = getDetailOddsboxName('12', prematch, market.Id, home, lang)
  const awayName = getDetailOddsboxName('12', prematch, market.Id, away, lang)

  const foundCartItem = _.find(cartItems, o => {
    return o.fixtureId === FixtureId && o.marketId === market.Id
  })
  const homeSelected = foundCartItem && home?.Id === foundCartItem.bet.Id
  const awaySelected = foundCartItem && away?.Id === foundCartItem.bet.Id

  return (
    <Grid container justifyContent="center">
      <Grid item xs={4} sx={{ height: '30px', p: '0px 2px 0px 2px' }}>
        {home ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={homeName}
            unifiedName="12"
            atomName="home"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={home}
            selected={homeSelected}
            drawSymbol={false}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
      <Grid item xs={4} sx={{ height: '30px', p: '0px 2px 0px 2px' }}></Grid>
      <Grid item xs={4} sx={{ height: '30px', p: '0px 2px 0px 2px' }}>
        {away ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={awayName}
            unifiedName="12"
            atomName="away"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={away}
            selected={awaySelected}
            drawSymbol={false}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default observer(DetailOddsBox12)
