/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState, Fragment, useLayoutEffect } from 'react'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import useRootStore from '@store/useRootStore'
import moment from 'moment'

// icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import apiPublic from '@services/api/public'
import { Schema$NoticeItem } from '@interface/common'
import { INotices } from '@protocol/public'
import { NOTICE_KIND } from '@interface/config'

function NoticeBox({}) {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const { signedin } = authStore

  const [notices, setNotices] = useState<Schema$NoticeItem[]>([])

  useEffect(() => {
    fetchNotices()
  }, [signedin])

  const fetchNotices = async () => {
    try {
      const params: INotices.Params = {
        kind: 'ALL',
        limit: 5,
        offset: 0,
      }
      const { kind, offset, limit, total, items } = await apiPublic.getNotices(params)
      const notices: Schema$NoticeItem[] = [...items]
      setNotices(notices)
    } catch (err) {}
  }

  const onClickMore = () => {
    history.push('/notice/search?main=NOTICE')
    globalStore.setNavChange('/notice/search?main=NOTICE')
  }

  const onClickNoticeItem = (kind: string) => {
    history.push(`/notice/search?main=${kind}`)
  }

  const listItems = []
  for (const el of notices) {
    const noticeKind = _.find(NOTICE_KIND, { id: el.kind })
    const writeAt = el.write_date
    const color = noticeKind.id === 'EVENT' ? 'event' : noticeKind.id === 'NOTICE' ? 'notice' : 'update'
    listItems.push(
      <ListItem key={el.id}>
        <Grid container>
          <Grid item className="date" xs={3}>
            {writeAt}
          </Grid>
          <Grid item className="title" xs={9}>
            <div className="content ellipsis">
              <a
                href={undefined}
                onClick={() => onClickNoticeItem(noticeKind.id)}
                dangerouslySetInnerHTML={{ __html: el.title }}
              ></a>
            </div>
          </Grid>
        </Grid>
      </ListItem>,
    )
  }

  return (
    <Box className="main_list">
      <div className="main_title">
        <span>{intl.formatMessage({ id: 'component.notice' })}</span>
        <button type="button" onClick={() => onClickMore()} className="more">
          <Typography sx={{ fontSize: '1.1rem' }}>{intl.formatMessage({ id: 'all' })}</Typography>
        </button>
      </div>

      <List>{listItems}</List>
    </Box>
  )
}

export default NoticeBox
