/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'
import queryString from 'query-string'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { styled } from '@mui/material/styles'
import { Box, Button, Grid, MenuItem, Stack, TextField } from '@mui/material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useHistory } from 'react-router-dom'
import ComponentChargeSummary from './ComponentChargeSummary'
import ComponentBettingSummary from './ComponentBettingSummary'
import { useOnMount } from '~/utils/reactExt'

const MyHeadCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: '6px',
  paddingRight: '6px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

const MyRowCell = styled(TableCell)(({ theme }) => ({
  padding: '6px',
  paddingRight: '6px',
  paddingTop: '10px',
  paddingBottom: '10px',
}))

const SEARCH_KEY_NAMES = [
  { id: 'ID', name: '아이디' },
  { id: 'NICK', name: '닉네임' },
]

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

interface Props {
  location: any
}

function PanelChargeSummary({ location }: Props) {
  const query = queryString.parse(location.search)
  const { uuid, main, begin, end, key, value, ts } = query

  const [searchBegin, setSearchBegin] = useState<Date>(begin ? new Date(begin as string) : start)
  const [searchEnd, setSearchEnd] = useState<Date>(end ? new Date(end as string) : now)
  const [searchPeriod, setSearchPeriod] = useState('')

  const history = useHistory()

  useOnMount(() => {})

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
    setSearchPeriod('')
  }

  const onClickSearchPeriod = newValue => {
    setSearchPeriod(searchPeriod === newValue ? '' : newValue)
    const now = new Date()
    switch (newValue) {
      case 'today':
        setSearchBegin(now)
        setSearchEnd(now)
        break
      case '7':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6))
        setSearchEnd(now)
        break
      case '15':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14))
        setSearchEnd(now)
        break
    }
  }

  const onClickSearch = () => {
    let date_begin = new Date(searchBegin)
    let date_end = new Date(searchEnd)
    let q = `uuid=${uuid}&main=detail&begin=${moment(date_begin).format('YYYY-MM-DD')}&end=${moment(date_end).format(
      'YYYY-MM-DD',
    )}&ts=${new Date().getTime()}`
    let encoded = encodeURI(q)
    history.push(`/partner/member/search?${encoded}`)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ marginTop: '14px' }}>
          <Stack direction="row-reverse" spacing={1}>
            <Button variant="contained" sx={{ background: '#007f93' }} onClick={() => onClickSearch()}>
              검색
            </Button>
            <Button
              variant="contained"
              sx={{ background: searchPeriod === '15' ? '#fdb721' : 'grey' }}
              onClick={() => onClickSearchPeriod('15')}
            >
              15일
            </Button>
            <Button
              variant="contained"
              sx={{ background: searchPeriod === '7' ? '#fdb721' : 'grey' }}
              onClick={() => onClickSearchPeriod('7')}
            >
              1주
            </Button>
            <Button
              variant="contained"
              sx={{ background: searchPeriod === 'today' ? '#fdb721' : 'grey' }}
              onClick={() => onClickSearchPeriod('today')}
            >
              오늘
            </Button>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="종료"
                inputFormat="yyyy-MM-dd"
                mask={'____-__-__'}
                value={searchEnd}
                onChange={newValue => handleChangeSearchEnd(newValue)}
                renderInput={params => <TextField size="small" {...params} />}
              />
              <DesktopDatePicker
                label="시작"
                inputFormat="yyyy-MM-dd"
                mask={'____-__-__'}
                value={searchBegin}
                onChange={newValue => handleChangeSearchBegin(newValue)}
                renderInput={params => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>충/환전 합계 (하위 포함)</Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <ComponentChargeSummary
          location={location}
          default_begin={searchBegin}
          default_end={searchEnd}
        ></ComponentChargeSummary>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '32px' }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>게임별 베팅 합계 (하위 포함)</Typography>
      </Grid>

      <Grid item xs={12} sx={{ marginTop: '16px' }}>
        <ComponentBettingSummary
          location={location}
          default_begin={searchBegin}
          default_end={searchEnd}
        ></ComponentBettingSummary>
      </Grid>
    </Grid>
  )
}

export default observer(PanelChargeSummary)
