/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Chip } from '@mui/material'
import { popupCenter } from '@utils/utils'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'

// css
import { ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'
import { MobileModalStyle } from '@styles/modal.style'

// icon
import CloseIcon from '@mui/icons-material/Close'

// image
import { imgMenualM01, imgMenualM02, imgMenualM03, imgMenualM04, imgMenualM05 } from '@assets/images/layout_1'

export const MobileImages = [imgMenualM01, imgMenualM02, imgMenualM03, imgMenualM04, imgMenualM05]

interface Props {
  visible: boolean
  onClose: () => void
  innerContents?: any
}

const MobileContainer: React.FC<Props> = ({ visible, onClose, innerContents }) => {
  return (
    <Dialog open={visible} onClose={onClose} css={MobileModalStyle}>
      <Box className="modal_content">
        <AppBar sx={{ position: 'relative' }}>
          <Typography className="modal_title" variant="h6" component="div">
            코인충전 메뉴얼
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Box sx={{ p: 2, color: '#D1D9E3' }}>{innerContents}</Box>
      </Box>
    </Dialog>
  )
}

export default observer(MobileContainer)
