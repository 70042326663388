/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import { useEffect } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Container, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { styled } from '@mui/material/styles'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'

// css
import { ImageListItemStyle, ImageListItemStyle2 } from '@styles/base.style'

// image
import * as imgCasino from '~/assets/images/layout_1/casino'
import * as imgCasinoMarine from '~/assets/images/layout_1/casino/marine'
import * as imgCasinoMarine2 from '~/assets/images/layout_3/casino/marine2'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

let mergedImgCasino = { ...imgCasino }

switch (channelSymbol) {
  case 'marine':
  case 'tiger':
  case 'hilton':
  case 'soft':
  case 'gangnam':
  case 'milky':
  case 'hulk':
    mergedImgCasino = { ...mergedImgCasino, ...imgCasinoMarine }
    break
  case 'marine2':
    mergedImgCasino = { ...mergedImgCasino, ...imgCasinoMarine2 }
    break
}

const ListTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  color: theme.colors.listTitle,
  fontWeight: 'bold',
  fontSize: '1.8rem',
}))

let lcaResources = [
  {
    kind: 'EV',
    thumb: mergedImgCasino.imgLiveCasino_evo,
    title: '에볼루션 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_evo_h,
    localeId: 'trading.LCA.EV',
  },
  {
    kind: 'PR',
    thumb: mergedImgCasino.imgLiveCasino_pragmatic,
    title: '프라그마틱 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_pragmatic_h,
    localeId: 'trading.LCA.PR',
  },
  {
    kind: 'DG',
    thumb: mergedImgCasino.imgLiveCasino_dream,
    title: '드림게이밍 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_dream_h,
    localeId: 'trading.LCA.DG',
  },
  {
    kind: 'OG',
    thumb: mergedImgCasino.imgLiveCasino_oriental,
    title: '오리엔탈게이밍 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_oriental_h,
    localeId: 'trading.LCA.OG',
  },
  {
    kind: 'MG',
    thumb: mergedImgCasino.imgLiveCasino_micro,
    title: '마이크로게이밍 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_micro_h,
    localeId: 'trading.LCA.MG',
  },
  {
    kind: 'AG',
    thumb: mergedImgCasino.imgLiveCasino_asian,
    title: '아시안게이밍 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_asian_h,
    localeId: 'trading.LCA.AG',
  },
  {
    kind: 'CQ9',
    thumb: mergedImgCasino.imgLiveCasino_cq9,
    title: 'CQ9 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_cq9_h,
    localeId: 'trading.LCA.CQ9',
  },
  {
    kind: 'EZ',
    thumb: mergedImgCasino.imgLiveCasino_ezugi,
    title: '이주기 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_ezugi_h,
    localeId: 'trading.LCA.EZ',
  },
  {
    kind: 'VI',
    thumb: mergedImgCasino.imgLiveCasino_vivo,
    title: '비보 게이밍 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_vivo_h,
    localeId: 'trading.LCA.VI',
  },
  {
    kind: 'PT',
    thumb: mergedImgCasino.imgLiveCasino_portomaso,
    title: '포르토마소 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_portomaso_h,
    localeId: 'trading.LCA.PT',
  },
  {
    kind: 'BG',
    thumb: mergedImgCasino.imgLiveCasino_biggame,
    title: '빅게이밍 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_biggame_h,
    localeId: 'trading.LCA.BG',
  },
  {
    kind: 'WM',
    thumb: mergedImgCasino.imgLiveCasino_wm,
    title: 'WM 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_wm_h,
    localeId: 'trading.LCA.WM',
  },
  {
    kind: 'MTV',
    thumb: mergedImgCasino.imgLiveCasino_motivation,
    title: '모티베이션 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_motivation_h,
    localeId: 'trading.LCA.MTV',
  },
  {
    kind: 'VG',
    thumb: mergedImgCasino.imgLiveCasino_vegas,
    title: '베가스 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_vegas_h,
    localeId: 'trading.LCA.VG',
  },
  {
    kind: 'TS',
    thumb: mergedImgCasino.imgLiveCasino_taishan,
    title: '타이산 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_taishan_h,
    localeId: 'trading.LCA.TS',
  },
  {
    kind: 'GPI',
    thumb: mergedImgCasino.imgLiveCasino_gpi,
    title: 'GPI 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_gpi_h,
    localeId: 'trading.LCA.GPI',
  },
  {
    kind: 'SG',
    thumb: mergedImgCasino.imgLiveCasino_sexygaming,
    title: '섹시게이밍 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_sexygaming_h,
    localeId: 'trading.LCA.SG',
  },
  {
    kind: 'SW',
    thumb: mergedImgCasino.imgLiveCasino_skywind,
    title: '스카이윈드 카지노 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_skywind_h,
    localeId: 'trading.LCA.SW',
  },
  {
    kind: 'BGTV',
    thumb: mergedImgCasino.imgLiveCasino_betgames,
    title: '벳게임즈 입장',
    hoverimg: imgCasinoMarine.imgLiveCasino_betgames_h,
    localeId: 'trading.LCA.BGTV',
  },
  {
    kind: 'AB',
    thumb: mergedImgCasino.imgLiveCasino_allbet,
    title: '올벳카지노 입장',
    //hoverimg: imgCasinoMarine.imgLiveCasino_allbet_h,
    localeId: 'trading.LCA.AB',
  },
  {
    kind: 'PLT',
    thumb: mergedImgCasino.imgLiveCasino_allbet,
    title: '플레이텍 입장',
    //hoverimg: imgCasinoMarine.imgLiveCasino_allbet_h,
    localeId: 'trading.LCA.PLT',
  },
  {
    kind: 'SA',
    thumb: mergedImgCasino.imgLiveCasino_allbet,
    title: '사게이밍 입장',
    //hoverimg: imgCasinoMarine.imgLiveCasino_allbet_h,
    localeId: 'trading.LCA.SA',
  },
]

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const LiveCasino: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('LCA')
    }
  }, [signedin, initialized, serviceState])

  function onClickCard(name: string) {
    globalStore.signedinNewGame('LCA', name, signedin, intl)
  }

  const listItems = []

  let lcaItems = _.filter(open_trading_kinds, o => {
    return o.trading_group === 'LCA'
  })
  const ordered = _.orderBy(lcaItems, ['order'], ['asc'])
  for (const trading of ordered) {
    const el = _.find(lcaResources, o => {
      return o.kind === trading.trading_kind
    })
    if (el) {
      if (
        channelSymbol === 'marine' ||
        channelSymbol === 'tiger' ||
        channelSymbol === 'hilton' ||
        channelSymbol === 'soft' ||
        channelSymbol === 'gangnam' ||
        channelSymbol === 'milky' ||
        channelSymbol === 'hulk'
      ) {
        listItems.push(
          <ImageListItemStyle onClick={() => onClickCard(el.kind)} key={el.kind}>
            <div className="img_wrap">
              <img
                src={el.thumb}
                alt={'live casino image ' + el.kind}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line">
                <img
                  src={el.hoverimg}
                  alt={'live casino image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </ImageListItemStyle>,
        )
      } else if (channelSymbol === 'marine2') {
        listItems.push(
          <ImageListItemStyle2 onClick={() => onClickCard(el.kind)} key={el.kind}>
            <div className="img_wrap">
              <img
                src={el.thumb}
                alt={'live casino image ' + el.kind}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line"></div>
            </div>
          </ImageListItemStyle2>,
        )
      } else {
        listItems.push(
          <ImageListItemStyle onClick={() => onClickCard(el.kind)} key={el.kind}>
            <div className="img_wrap">
              {el.thumb ? (
                <img
                  src={el.thumb}
                  alt={'live casino image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
              ) : null}
              <div className="border_line"></div>
            </div>
            <ImageListItemBar
              title={
                <div style={{ fontSize: '1.1rem' }}>
                  {intl.formatMessage({ id: el.localeId, defaultMessage: trading.name })}
                </div>
              }
              position="below"
              sx={{
                border: 'none',
                '& .MuiImageListItemBar-titleWrap': {
                  padding: '6px 0',
                },
              }}
            />
          </ImageListItemStyle>,
        )
      }
    } else {
      listItems.push(
        <ImageListItemStyle
          onClick={() => onClickCard(trading.trading_kind)}
          key={trading.trading_kind}
        >
          <div className="img_wrap">
            {null}
            <div className="border_line"></div>
          </div>
          <ImageListItemBar
            title={<div style={{ fontSize: '1.1rem' }}>{trading.name}</div>}
            position="below"
            sx={{
              border: 'none',
              '& .MuiImageListItemBar-titleWrap': {
                padding: '6px 0',
              },
            }}
          />
        </ImageListItemStyle>,
      )
    }
  }

  let contents = null
  let minWidthDesktop = '1248px'

  if (
    channelSymbol === 'today' ||
    channelSymbol === 'hongyeom' ||
    channelSymbol === 'ace' ||
    channelSymbol === 'ssg' ||
    channelSymbol === 'praha'
  ) {
    minWidthDesktop = '1448px'
  }

  if (desktop) {
    if (
      channelSymbol === 'marine' ||
      channelSymbol === 'tiger' ||
      channelSymbol === 'hilton' ||
      channelSymbol === 'soft' ||
      channelSymbol === 'gangnam' ||
      channelSymbol === 'milky' ||
      channelSymbol === 'hulk'
    ) {
      contents = (
        <Container className="content_wrap" sx={{ minWidth: '1648px', pb: 5, mt: 4 }}>
          <ImageList cols={5} gap={12}>
            {listItems}
          </ImageList>
        </Container>
      )
    } else if (channelSymbol === 'marine2') {
      contents = (
        <Box className="content_wrap" sx={{ pb: 5 }}>
          <Container sx={{ minWidth: '1368px', mx: 0 }}>
            <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
              {intl.formatMessage({ id: 'trading.LCA' })}
            </ListTitle>
            <ImageList cols={4} gap={12}>
              {listItems}
            </ImageList>
          </Container>
        </Box>
      )
    } else {
      contents = (
        <Container
          className="content_wrap"
          sx={{ minWidth: minWidthDesktop, pb: 5, backgroundColor: appBackground }}
        >
          <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
            {intl.formatMessage({ id: 'trading.LCA' })}
          </ListTitle>
          <ImageList cols={4} gap={12}>
            {listItems}
          </ImageList>
        </Container>
      )
    }
  } else {
    if (
      channelSymbol === 'marine' ||
      channelSymbol === 'marine2' ||
      channelSymbol === 'tiger' ||
      channelSymbol === 'hilton' ||
      channelSymbol === 'soft' ||
      channelSymbol === 'gangnam' ||
      channelSymbol === 'milky' ||
      channelSymbol === 'hulk'
    ) {
      contents = (
        <Box
          className="content_wrap_m"
          sx={{ p: 2, backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}
        >
          <ImageList cols={2} gap={12}>
            {listItems}
          </ImageList>
        </Box>
      )
    } else {
      contents = (
        <Box
          className="content_wrap_m"
          sx={{ p: 2, backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}
        >
          <ListTitle sx={{ fontSize: '1.2rem' }}>
            {intl.formatMessage({ id: 'trading.LCA' })}
          </ListTitle>
          <ImageList cols={2} gap={12}>
            {listItems}
          </ImageList>
        </Box>
      )
    }
  }

  return <>{contents}</>
}

export default observer(LiveCasino)
