import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react'
import {
  TextField,
  Container,
  Box,
  Grid,
  Divider,
  Typography,
  Stack,
  Button,
} from '@mui/material'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useHistory } from 'react-router-dom'

import WhiteTheme from '@themes/white.theme'
import { GlobalStyle } from '@shared/Common'
import useRootStore from '@store/useRootStore'
import { USER_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import PartnerIntergratedChargeSummary from './PartnerIntergratedChargeSummary'
import PartnerIntergratedBettingSummary from './PartnerIntergratedBettingSummary'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const end = new Date()
// const begin = new Date()
const begin = new Date(end.getFullYear(), end.getMonth(), 1)

// const ItemPerPage = 10

function PartnerIntergratedDashboard({ location }) {
  const [searchBegin, setSearchBegin] = useState<Date>(begin)
  const [searchEnd, setSearchEnd] = useState<Date>(end)
  const [searchPeriod, setSearchPeriod] = useState('')

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-HOME')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.dashboard)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
    globalStore.showModal('login', 0)
    return <></>
  }

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
    setSearchPeriod('')
  }

  const onClickSearchPeriod = newValue => {
    setSearchPeriod(searchPeriod === newValue ? '' : newValue)
    const now = new Date()
    switch (newValue) {
      case 'today':
        setSearchBegin(now)
        setSearchEnd(now)
        break
      case '7':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6))
        setSearchEnd(now)
        break
      case '15':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14))
        setSearchEnd(now)
        break
    }
  }

  const onClickSearch = () => {
    let date_begin = new Date(searchBegin)
    let date_end = new Date(searchEnd)
    let q = `begin=${moment(date_begin).format('YYYY-MM-DD')}&end=${moment(date_end).format(
      'YYYY-MM-DD',
    )}&ts=${new Date().getTime()}`
    let encoded = encodeURI(q)
    history.push(`/partner/dashboard/search?${encoded}`)
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1248px',
          maxWidth: '1248px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}>
            <Divider sx={{ width: '200px', fontSize: '16px' }}>대시보드</Divider>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button variant="contained" sx={{ background: '#007f93' }} onClick={() => onClickSearch()}>
                  검색
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '15' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('15')}
                >
                  15일
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '7' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('7')}
                >
                  1주
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === 'today' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('today')}
                >
                  오늘
                </Button>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '16px' }}>
            <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>충/환전 합계 (하위 포함)</Typography>
          </Grid>
          <Grid item xs={10} sx={{ marginTop: '10px' }}>
            <PartnerIntergratedChargeSummary
              location={location}
              default_begin={searchBegin}
              default_end={searchEnd}
            ></PartnerIntergratedChargeSummary>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '32px' }}>
            <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>게임별 베팅 합계 (하위 포함)</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <PartnerIntergratedBettingSummary
              location={location}
              default_begin={searchBegin}
              default_end={searchEnd}
            ></PartnerIntergratedBettingSummary>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedDashboard)
