/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import { useEffect } from 'react'
import { Grid, Box, GlobalStyles, Typography, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CasinoComing, imgLoading } from '@assets/images/layout_1'
import { useHistory } from 'react-router-dom'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { NeatTextField } from '../views/Partner/PartnerIntergratedMember/index.component'

const GlobalStyle = () => css`
  @media (min-width: 992px) {
    html, body {
      min-width: 0;
    }
  }
`

const TextStyle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: theme.colors.listTitle,
}))

const InputStyle = styled(NeatTextField)(({ theme }) => ({
  marginTop: 55,
  width: '100%',
  '& textarea': {
    color: theme.colors.listTitle,
    textAlign: 'center',
    fontSize: '16px',
  },
  '& fieldset': {
    border: 'none',
  },
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

let timer: NodeJS.Timeout = null

function ProxyLoading(props) {
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()

  let txtInfo = ` 게임을 실행중입니다.
  잠시만 기다려 주세요.`

  return (
    <>
      <Global styles={GlobalStyle} />
      <Box sx={{ background: 'black' }}>
        <Grid container justifyContent="center" alignItems="center" sx={{ maxWidth: 700, m: 'auto', height: '100vh' }}>
          <Grid item xs={10} sx={{ mt: '-40px', textAlign: 'center' }}>
            {/* <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} /> */}
            <img src={imgLoading} alt="loading" style={{ maxWidth: '100%' }} />
            <InputStyle multiline value={txtInfo} InputProps={{ readOnly: true }}></InputStyle>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default observer(ProxyLoading)
