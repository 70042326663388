import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const ModalStyle = (theme: Theme) => css`
  overflow-x: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  /* scrollbar custom for desktop */
  span,
  ol,
  ul,
  pre,
  div {
    scrollbar-width: thin;
    scrollbar-color: #182740 transparent;
  }
  span::-webkit-scrollbar,
  ol::-webkit-scrollbar,
  ul::-webkit-scrollbar,
  pre::-webkit-scrollbar,
  div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  span::-webkit-scrollbar-thumb,
  ol::-webkit-scrollbar-thumb,
  ul::-webkit-scrollbar-thumb,
  pre::-webkit-scrollbar-thumb,
  div::-webkit-scrollbar-thumb {
    background-color: #182740;
  }
  span:hover,
  ol:hover,
  ul:hover,
  pre:hover,
  div:hover {
    scrollbar-color: #19253a transparent;
  }
  span:hover::-webkit-scrollbar-thumb,
  ol:hover::-webkit-scrollbar-thumb,
  ul:hover::-webkit-scrollbar-thumb,
  pre:hover::-webkit-scrollbar-thumb,
  div:hover::-webkit-scrollbar-thumb {
    background-color: #19253a;
  }

  .MuiDialog-container {
    height: auto;
  }

  .MuiDialog-paper {
    height: auto;
    margin: 80px auto;
    min-height: calc(100vh - 240px);
    background-color: ${theme.colors.modalBg};
  }
  
  &.login_dialog .MuiDialog-paper {
    min-width: 500px;
    max-width: 500px;
  }

  .modal_content {
    height: 100%;
  }

  .MuiAppBar-root {

    .modal_title {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
`

export const MobileModalStyle = (theme: Theme) => css`
  .MuiDialog-paper {
    margin: 0;
    max-width: none;
    width: 100%;
    min-height: 100%;
    background-color: ${theme.colors.modalBg};
  }
  .MuiAppBar-root {
    .modal_title {
      padding-left: 17px;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
      top: 0;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
  }
`