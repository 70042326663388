import React, { createContext, ReactElement, useState } from 'react'
import { Observer } from 'mobx-react'

import * as store from '@store/index'
import { RootStore } from '@typings/store'

interface ChildrenProps<T> {
  children: (value: T) => ReactElement
}

export const RootContext = createContext<RootStore>({
  ...store,
  currentTheme: '',
  themeSwitchHandler: (theme: string) => {},
})

/**
 * Observer
 * @param param0
 */
// export const RootConsumer = ({ children }: ChildrenProps<RootStore>) => <Observer>{() => children({store)}</Observer>

// incluce next line to control theme
// const { currentTheme, themeSwitchHandler } = useRootStore()

export default function Provider({ children }: { children?: React.ReactNode }) {
  const [currentTheme, setCurrentTheme] = useState(
    window.localStorage.getItem('theme') == null ? 'dark' : window.localStorage.getItem('theme'),
  )
  const themeSwitchHandler = (themeType: string) => {
    setCurrentTheme(themeType)
  }

  return (
    <RootContext.Provider
      value={{
        ...store,
        currentTheme: currentTheme,
        themeSwitchHandler: themeSwitchHandler,
      }}
    >
      {children}
    </RootContext.Provider>
  )
}
