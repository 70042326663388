/** @jsxImportSource @emotion/react */
/* eslint-disable jsx-a11y/iframe-has-title */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Grid, Box } from '@mui/material'
import * as _ from 'lodash'

import useRootStore from '@store/useRootStore'
import { MakeTestMatchupMatrix, MarketMatrixData } from '~/game/common'
import { POWERBALL_MARKET_GROUP, MarketMatrix } from '~/game/powerball'
import { BettingCartItem } from '~/store/minigameStore'
import { SOCKET_SERVICE_STATE } from '@store/socketStore'
import { useOnMount } from '~/utils/reactExt'

// css
import { BasicButton } from '~/styles/button.style'
import { SelectBox, ScrollableStyle } from './index.style'

// const loadableText = 'N/A'
const loadableText = '. . .'

export const makeMarketBlock = (
  tradingKind: string,
  marketGroup: number,
  srcMatrix: MarketMatrixData[][],
  selectedId: string,
  onClick: (tradingKind: string, marketGroup: number, marketId: string, oddsId: string) => void,
  colType: string,
  exclude: string[],
) => {
  const contents = []
  for (const row of srcMatrix) {
    for (const col of row) {
      const innerItems = []
      const disabled = selectedId === '0' || !col.odds || exclude.indexOf(col.id) >= 0
      // const color = selectedId === '1' || selectedId === col.id ? col.acolor : col.dcolor
      const active = selectedId === col.id ? 'selected' : ''
      let bgColor = col.dcolor
      if (selectedId === col.id) {
        bgColor = col.acolor
      }
      let oddsName = <div></div>
      if (col.odds) {
        if (col.odds.disabled) {
          continue
        }
        if (col.marketId === 'PABCD') {
          const [section, tmp] = col.odds.locale.name.split('-')
          const numbers = tmp.split(',')
          let balls = []
          for (const el of numbers) {
            //const num = Number(el)
            balls.push(<span>{el}</span>)
          }
          oddsName = (
            <div className="ball">
              {section} - {balls}
            </div>
          )
        } else if (col.marketId === 'PNUMBER') {
          const num = Number(col.odds.locale.name)
          oddsName = (
            <div className="ball">
              <span>{num}</span>
            </div>
          )
        } else {
          oddsName = <div>{col.odds ? col.odds.locale.name : loadableText}</div>
        }
      }

      innerItems.push(
        <BasicButton
          key={col.id}
          className={'btn_bet ' + active}
          disabled={disabled}
          onClick={() => onClick(tradingKind, marketGroup, col.marketId, col.id)}
          style={{ backgroundColor: bgColor }}
        >
          {oddsName}
          <div>{col.odds ? col.odds.rate : loadableText}</div>
        </BasicButton>,
      )
      if (colType === 'type1') {
        contents.push(
          <Grid item xs={3} key={col.id}>
            {innerItems}
          </Grid>,
        )
      } else if (colType === 'type2') {
        contents.push(
          <Grid item className="col_lg_25" key={col.id}>
            {innerItems}
          </Grid>,
        )
      } else if (colType === 'type3') {
        if (col.marketId === 'NLMS' || col.marketId === 'NODD_NEVEN+NLMS') {
          contents.push(
            <Grid item xs={4} key={col.id}>
              {innerItems}
            </Grid>,
          )
        } else {
          contents.push(
            <Grid item xs={3} key={col.id}>
              {innerItems}
            </Grid>,
          )
        }
      }
    }
    //const marginTop = innerItems.length > 0 ? '6px' : '0px'
    //contents.push(<Row style={{ marginTop }}>{innerItems}</Row>)
  }
  return contents
}

const getSelectedId = (items: BettingCartItem[], marketGroup: number) => {
  const powerItem = _.find(items, function(o) {
    return o.marketGroup === marketGroup
  })
  return powerItem ? powerItem.oddsId : '1'
}

interface Props {
  kind: string
}

const MarketPanelPowerball: React.FC<Props> = ({ kind }) => {
  // '0': disabled, '1': interfactable, 'xxxx': selected
  const { miniGameStore, globalStore } = useRootStore()
  const [mounted, setMounted] = useState<boolean>(false)
  const { matchup, cartItems } = miniGameStore
  const { option } = globalStore
  const { usePowerballCombination } = option

  useOnMount(() => {
    miniGameStore.setGameTab(kind)
    setTimeout(() => {
      setMounted(true)
    }, 150)
  })

  let ppowerGroup = POWERBALL_MARKET_GROUP.ALL
  let pnumberGroup = POWERBALL_MARKET_GROUP.ALL
  let nnormalGroup = POWERBALL_MARKET_GROUP.ALL
  let nlmsGroup = POWERBALL_MARKET_GROUP.ALL
  let nperfectGroup = POWERBALL_MARKET_GROUP.ALL

  let powerSelected = '0'
  let numberSelected = '0'
  let normalSelected = '0'
  let lmsSelected = '0'
  let perfectSelected = '0'

  let powerExclude = []
  let normalExclude = []

  if (!usePowerballCombination) {
    let allSelected = getSelectedId(cartItems, POWERBALL_MARKET_GROUP.ALL)
    powerSelected = allSelected
    numberSelected = allSelected
    normalSelected = allSelected
    lmsSelected = allSelected
    perfectSelected = allSelected
  } else {
    ppowerGroup = POWERBALL_MARKET_GROUP.PPOWER
    pnumberGroup = POWERBALL_MARKET_GROUP.PNUMBER
    nnormalGroup = POWERBALL_MARKET_GROUP.NNORMAL
    nlmsGroup = POWERBALL_MARKET_GROUP.NLMS
    nperfectGroup = POWERBALL_MARKET_GROUP.NPERFECT

    powerSelected = getSelectedId(cartItems, POWERBALL_MARKET_GROUP.PPOWER)
    numberSelected = getSelectedId(cartItems, POWERBALL_MARKET_GROUP.PNUMBER)
    normalSelected = getSelectedId(cartItems, POWERBALL_MARKET_GROUP.NNORMAL)
    lmsSelected = getSelectedId(cartItems, POWERBALL_MARKET_GROUP.NLMS)
    perfectSelected = getSelectedId(cartItems, POWERBALL_MARKET_GROUP.NPERFECT)

    let powerBasic = ['PODD', 'PEVEN', 'PUNDER', 'POVER']
    let normalBasic = ['NODD', 'NEVEN', 'NUNDER', 'NOVER']

    if (powerSelected.length > 1) {
      numberSelected = '0'
      lmsSelected = '0'
      perfectSelected = '0'

      if (powerBasic.indexOf(powerSelected) >= 0) {
        normalExclude = normalBasic
      }
    }
    if (normalSelected.length > 1) {
      numberSelected = '0'
      lmsSelected = '0'
      perfectSelected = '0'

      if (normalBasic.indexOf(normalSelected) >= 0) {
        powerExclude = powerBasic
      }
    }

    if (numberSelected.length > 1) {
      powerSelected = '0'
      normalSelected = '0'
      lmsSelected = '0'
      perfectSelected = '0'
    }
    if (lmsSelected.length > 1) {
      powerSelected = '0'
      numberSelected = '0'
      normalSelected = '0'
      perfectSelected = '0'
    }
    if (perfectSelected.length > 1) {
      powerSelected = '0'
      numberSelected = '0'
      lmsSelected = '0'
      normalSelected = '0'
    }
  }

  const matchupMatrix = MakeTestMatchupMatrix(MarketMatrix, matchup)

  const powerContents = makeMarketBlock(
    kind,
    ppowerGroup,
    matchupMatrix['PBP']['POWER'],
    powerSelected,
    miniGameStore.toggleCartItem,
    'type1',
    powerExclude,
  )
  const numberContents = makeMarketBlock(
    kind,
    pnumberGroup,
    matchupMatrix['PBP']['NUMBER'],
    numberSelected,
    miniGameStore.toggleCartItem,
    'type2',
    [],
  )
  const normalContents = makeMarketBlock(
    kind,
    nnormalGroup,
    matchupMatrix['PBN']['NORMAL'],
    normalSelected,
    miniGameStore.toggleCartItem,
    'type3',
    normalExclude,
  )
  const lmsContents = makeMarketBlock(
    kind,
    nlmsGroup,
    matchupMatrix['PBN']['LMS'],
    lmsSelected,
    miniGameStore.toggleCartItem,
    'type3',
    [],
  )
  const perfectContents = makeMarketBlock(
    kind,
    nperfectGroup,
    matchupMatrix['PBN']['PERFECT'],
    perfectSelected,
    miniGameStore.toggleCartItem,
    'type1',
    [],
  )

  // const url = 'https://game.tspick.com/bong/TRUST_POWERBALL/?kind=powerball_01'
  let url = ''
  let urlResult = ''
  let sx = {}
  let height = '588px'
  switch (kind) {
    case 'HPB1':
      url = 'https://bepick.net/live/happy545/scrap'
      urlResult = 'https://bepick.net/#/game/daily/happy545'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'NPB1':
      url = 'https://bepick.net/live/ntry_power/scrap'
      urlResult = 'https://bepick.net/#/game/daily/nlotto_power'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'PB1':
      url = 'https://dhpowerball.net/rpowerball/live.php'
      urlResult = 'https://dhpowerball.net/rpowerball/main.php'
      sx = { transformOrigin: '0 0', transform: 'scale(0.882)', width: '900px', height: '640px' }
      height = '566px'
      break
    case 'PBG1':
      url = 'https://bepick.net/live/pbgpowerball/scrap'
      urlResult = 'https://bepick.net/#/game/daily/pbgpowerball'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'EOS1M':
      url = 'https://bepick.net/live/eosball1m/scrap'
      urlResult = 'https://bepick.net/#/game/daily/eosball1m'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'EOS2M':
      url = 'https://bepick.net/live/eosball2m/scrap'
      urlResult = 'https://bepick.net/#/game/daily/eosball2m'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'EOS3M':
      url = 'https://bepick.net/live/eosball3m/scrap'
      urlResult = 'https://bepick.net/#/game/daily/eosball3m'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'EOS4M':
      url = 'https://bepick.net/live/eosball4m/scrap'
      urlResult = 'https://bepick.net/#/game/daily/eosball4m'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'EOS5M':
      url = 'https://bepick.net/live/eosball5m/scrap'
      urlResult = 'https://bepick.net/#/game/daily/eosball5m'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'CPB3M':
      url = 'https://bepick.net/live/coinpower3/scrap'
      urlResult = 'https://bepick.net/#/game/daily/coinpower3'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'CPB5M':
      url = 'https://bepick.net/live/coinpower5/scrap'
      urlResult = 'https://bepick.net/#/game/daily/coinpower5'
      sx = { transformOrigin: '0 0', transform: 'scale(0.965)', width: '830px', height: '660px' }
      height = '640px'
      break
    case 'WRBTCPB1':
      url = 'https://funbtc-2023.wooriball.com/funplay_info/funtogether_btcpowerball_play_2023.php'
      urlResult = 'https://www.wooriball.com/game_btcpowerball_date.php'
      sx = { transformOrigin: '0 0', transform: 'scale(0.91)', width: '873px', height: '710px' }
      height = '640px'
      break
    case 'WRBEXPB1':
      url = 'https://funbex-2023.wooriball.com/funplay_info/funtogether_bexpowerball_play_2023.php'
      urlResult = 'https://www.wooriball.com/game_bexpowerball_date.php'
      sx = { transformOrigin: '0 0', transform: 'scale(0.91)', width: '873px', height: '710px' }
      height = '640px'
      break
    case 'EVOPB1':
      url = 'https://gopick.com/games/powerball_evo/live'
      urlResult = 'https://gopick.com/games/powerball_evo/analysis/round'
      sx = { transformOrigin: '0 0', transform: 'scale(0.882)', width: '900px', height: '760px' }
      height = '670px'
      break
  }

  const onClickPowerballGuide = () => {
    globalStore.setPowerballBettingGuideVisible(true)
  }

  const onClickResult = () => {
    window.open(urlResult, '_blank')
  }

  return (
    <Fragment>
      <ScrollableStyle style={{ height: height }}>
        {mounted && (
          <>
            <iframe
              id="contentFrame"
              className="game"
              scrolling="no"
              src={url}
              style={{ ...sx }}
            ></iframe>
          </>
        )}
      </ScrollableStyle>
      <Card css={SelectBox}>
        <Box className="card_header" sx={{ textAlign: 'end' }}>
          {usePowerballCombination ? (
            <BasicButton
              className="btn_sub"
              sx={{ display: 'inline-block', padding: '0.4rem 0.55rem', minWidth: 120, mr: 1 }}
              onClick={() => onClickPowerballGuide()}
            >
              베팅조합 안내
            </BasicButton>
          ) : null}
          <BasicButton
            className="btn_sub"
            sx={{ display: 'inline-block', padding: '0.4rem 0.55rem', minWidth: 120 }}
            onClick={() => onClickResult()}
          >
            지난 회차 보기
          </BasicButton>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4 }}>
        <Box className="card_header">파워볼 베팅</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {powerContents}
          </Grid>
        </Box>
        <Box className="card_body" sx={{ mt: 6 }}>
          <Grid container spacing={1}>
            {numberContents}
          </Grid>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 6 }}>
        <Box className="card_header">일반볼 베팅</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {normalContents}
          </Grid>
        </Box>
        <Box className="card_body" sx={{ mt: 6 }}>
          <Grid container spacing={1}>
            {lmsContents}
          </Grid>
        </Box>
        <Box className="card_body" sx={{ mt: 6 }}>
          <Grid container spacing={1}>
            {perfectContents}
          </Grid>
        </Box>
      </Card>
    </Fragment>
  )
}

export default observer(MarketPanelPowerball)
