import * as _ from 'lodash'

import { ODDS_COLOR, MarketMatrixMap } from './common'

export const POWERBALL_MARKET_GROUP = {
  ALL: 0,
  PPOWER: 1,
  PNUMBER: 2,
  NNORMAL: 3,
  NLMS: 4,
  NPERFECT: 5,
  MAX: 6,
}

export const POWERBALL_MARKET_GROUP_TEXT = ['', 'POWER', 'NUMBER', 'NORMAL', 'LMS', 'PERFECT']

// category/marketGroup/matrix
export const MarketMatrix: MarketMatrixMap = {
  PBP: {
    POWER: [
      [
        { marketId: 'PODD_PEVEN', id: 'PODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PODD_PEVEN', id: 'PEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'PUNDER_POVER', id: 'PUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PUNDER_POVER', id: 'POVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'PODD_PEVEN+PUNDER_POVER',
          id: 'PODD+PUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        { marketId: 'PODD_PEVEN+PUNDER_POVER', id: 'PODD+POVER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PODD_PEVEN+PUNDER_POVER', id: 'PEVEN+PUNDER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'PODD_PEVEN+PUNDER_POVER', id: 'PEVEN+POVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        { marketId: 'PABCD', id: 'PA', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PABCD', id: 'PB', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PABCD', id: 'PC', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PABCD', id: 'PD', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      ],
    ],
    NUMBER: [
      [
        { marketId: 'PNUMBER', id: 'PNUMBER_0', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_1', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_2', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_3', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_4', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      ],
      [
        { marketId: 'PNUMBER', id: 'PNUMBER_5', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_6', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_7', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_8', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
        { marketId: 'PNUMBER', id: 'PNUMBER_9', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      ],
    ],
  },
  PBN: {
    NORMAL: [
      [
        { marketId: 'NODD_NEVEN', id: 'NODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'NODD_NEVEN', id: 'NEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'NUNDER_NOVER', id: 'NUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'NUNDER_NOVER', id: 'NOVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'NODD_NEVEN+NUNDER_NOVER',
          id: 'NODD+NUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NUNDER_NOVER',
          id: 'NODD+NOVER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NUNDER_NOVER',
          id: 'NEVEN+NUNDER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NUNDER_NOVER',
          id: 'NEVEN+NOVER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
      ],
    ],
    LMS: [
      [
        { marketId: 'NLMS', id: 'NSMALL', acolor: ODDS_COLOR.green_a, dcolor: ODDS_COLOR.green_d, odds: null },
        { marketId: 'NLMS', id: 'NMEDIUM', acolor: ODDS_COLOR.green_a, dcolor: ODDS_COLOR.green_d, odds: null },
        { marketId: 'NLMS', id: 'NLARGE', acolor: ODDS_COLOR.green_a, dcolor: ODDS_COLOR.green_d, odds: null },
      ],
      [
        {
          marketId: 'NODD_NEVEN+NLMS',
          id: 'NODD+NSMALL',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NLMS',
          id: 'NODD+NMEDIUM',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NLMS',
          id: 'NODD+NLARGE',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NLMS',
          id: 'NEVEN+NSMALL',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NLMS',
          id: 'NEVEN+NMEDIUM',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
        {
          marketId: 'NODD_NEVEN+NLMS',
          id: 'NEVEN+NLARGE',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
      ],
    ],
    PERFECT: [
      [
        {
          marketId: 'NPERFECT_ODD_EVEN',
          id: 'NPERFECT_ODD',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
          odds: null,
        },
        {
          marketId: 'NPERFECT_ODD_EVEN',
          id: 'NPERFECT_EVEN',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
          odds: null,
        },
        {
          marketId: 'NPERFECT_UNDER_OVER',
          id: 'NPERFECT_UNDER',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
          odds: null,
        },
        {
          marketId: 'NPERFECT_UNDER_OVER',
          id: 'NPERFECT_OVER',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
          odds: null,
        },
      ],
    ],
  },
}

export const GetPowerballOddsColor = (
  category: string,
  marketGroup: number,
  marketId: string,
  oddsId: string,
): string => {
  // console.log(`category: ${category}, marketGroup: ${marketGroup}, marketId: ${marketId}, oddsId: ${oddsId}`)
  if (!_.has(MarketMatrix, category)) {
    return ''
  }
  if (marketGroup >= POWERBALL_MARKET_GROUP.MAX) {
    return ''
  }
  const txtMargetGroup = POWERBALL_MARKET_GROUP_TEXT[marketGroup]
  if (!_.has(MarketMatrix[category], txtMargetGroup)) {
    return ''
  }
  for (const row of MarketMatrix[category][txtMargetGroup]) {
    const found = _.find(row, { marketId, id: oddsId })
    if (found) {
      alert(found.acolor)
      return found.acolor
    }
  }
  return ''
}
