/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { Container, Tab, Tabs, Grid, Box, Fab, Zoom, Drawer, Typography, AppBar, Button, Card } from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { BasicTabs } from '@styles/base.style'
import * as _ from 'lodash'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import queryString from 'query-string'

// icon
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'

import useRootStore from '@store/useRootStore'
import { SOCKET_SERVICE_STATE } from '@store/socketStore'
import { GlobalStore, ModalKeys } from '@store/globalStore'
import { AuthStore } from '@store/authStore'
import { numberWithCommas } from '@utils/utils'

import EvoGame from '../../Canvas/evo/game'
import MarketPanelPowerball from './MarketPanelPowerball'
import MarketPanelPowerLadder from './MarketPanelPowerladder'
import MarketPanelSquidgame from './MarketPanelSquidgame'
import MarketPanelWooriKenoPowerball from './MarketPanelWooriKenoPowerball'

// image
import { betStart, betEnd } from '~/assets/images/layout_1'

// icon
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

// css
import { ScrollableStyle, SelectBox } from './index.style'
//import styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import { BasicButton } from '@styles/button.style'
import { useHistory } from 'react-router-dom'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import MarketPanelKino from './MarketPanelKino'
import {
  POWERBALL_MINIGAME_GROUP,
  POWERLADDER_MINIGAME_GROUP,
  WOORI_POWERBALL_MINIGAME_GROUP,
} from '~/v2/interface/config'
import { TopButtonWrap } from '../index.style'

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

// 탭 관련 ----------
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
// ----------

function ScrollTop(props) {
  const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-top-anchor')

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <TopButtonWrap onClick={handleClick} role="presentation">
        {children}
      </TopButtonWrap>
    </Zoom>
  )
}

const upperHeight = 300
const bottomHeight = 100

interface Props {
  group: string
  kind: string
}

const MobileMinigameEvo: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const history = useHistory()

  const { globalStore, liveStore, miniGameStore, authStore } = useRootStore()
  const { option } = globalStore
  const { signedin } = authStore
  const { serviceState } = liveStore
  const { message, matchupSource } = miniGameStore
  const { open_trading_groups, open_trading_kinds } = option || {}
  const boundaryRef = useRef(null)

  const [mounted, setMounted] = useState<boolean>(false)
  const [noti, setNoti] = useState(null)

  const { group, kind } = props

  let width = undefined
  if (boundaryRef && boundaryRef.current) {
    // console.log(`width: ${boundaryRef.current.offsetWidth}`)
    width = boundaryRef.current.offsetWidth
  }

  let tabItemList = _.filter(open_trading_kinds, o => {
    return o.trading_group === group
  })
  tabItemList = _.orderBy(tabItemList, ['order'], ['asc'])

  let tabIndex = _.findIndex(tabItemList, o => {
    return o.trading_kind === kind
  })

  const showMessage = (message: string) => {
    const tmp = message.split(':')
    const backDrop = <div className="backdrop"></div>
    if (_.isArray(tmp)) {
      const [command, bet, type, trading_kind] = tmp
      if (command === 'command' && bet === 'bet' && kind === trading_kind) {
        if (type === 'start') {
          // show start ui
          setNoti(
            <>
              <div className="inner">
                <img src={betStart} alt="베팅 시작" />
              </div>
              {backDrop}
            </>,
          )
          setTimeout(() => {
            setNoti(null)
          }, 3000)
        } else if (type === 'end') {
          // show end ui
          setNoti(
            <>
              <div className="inner">
                <img src={betEnd} alt="베팅 끝" />
              </div>
              {backDrop}
            </>,
          )
          setTimeout(() => {
            setNoti(null)
          }, 3000)
        }
      }
    }
  }

  useOnMount(() => {
    miniGameStore.setGameTab(kind)
    setTimeout(() => {
      setMounted(true)
    }, 150)
  })

  useOnUnmount(() => {
    liveStore.leaveSpace()
  })

  useEffect(() => {
    showMessage(message)
  }, [message])

  useEffect(() => {
    async function inline() {
      try {
        await liveStore.enterSpace('mg', 'MG', kind, '', false)
      } catch (err) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: err.message,
          callbackPositive: () => {},
        })
      }
    }

    if (serviceState === SOCKET_SERVICE_STATE.ENTERED && signedin) {
      setTimeout(() => {
        inline()
      }, 1000)
    }
    // eslint-disable-next-line
  }, [serviceState])

  const tabContents = []
  for (let i = 0; i < tabItemList.length; i++) {
    tabContents.push(<Tab key={i} className="basic_tab" label={tabItemList[i].name} {...a11yProps(i)} />)
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const sel = tabItemList[newValue]
    history.push(`/minigame/search?group=${sel.trading_group}&kind=${sel.trading_kind}`)
    miniGameStore.setGameTab(sel.trading_kind)
  }

  const onClickBettingRecord = () => {
    globalStore.showModal(ModalKeys.betting_record, 0)
  }

  const url = 'https://gopick.com/games/powerball_evo/live'
  const urlResult = 'https://gopick.com/games/powerball_evo/analysis/round'
  const origin = { w: 900, h: 760 }

  const sclX = (width || window.innerWidth) / origin.w
  const height = origin.h * sclX

  return (
    // <Fragment>
    //   <Box sx={{ background: '#16202F', minWidth: '1248px' }} id="back-to-top-anchor">
    //     <Box sx={{ m: 'auto', width: '1248px' }}>
    //       <Tabs value={tabIndex} onChange={handleChangeTab} css={BasicTabs} variant="fullWidth">
    //         {tabContents}
    //       </Tabs>
    //     </Box>
    //   </Box>
    //   <Container fixed sx={{ backgroundColor: appBackground, minWidth: '1248px', pt: 2 }}>
    //     <Box display="flex" justifyContent="center">
    //       <ScrollableStyle style={{ height: height, width: '794px' }}>
    //         {mounted && (
    //           <>
    //             <iframe id="contentFrame" className="game" scrolling="no" src={url} style={{ ...sx }}></iframe>
    //           </>
    //         )}
    //       </ScrollableStyle>
    //     </Box>
    //     <Box display="flex" justifyContent="center">
    //       <Box sx={{ width: '794px', textAlign: 'end' }}>
    //         <Button
    //           onClick={onClickBettingRecord}
    //           size="large"
    //           sx={{
    //             mt: 1,
    //             mx: 'auto',
    //             py: 0,
    //             color: '#2366CB',
    //             fontSize: '1.1rem',
    //             textDecoration: 'underline',
    //             '&:hover': {
    //               textDecoration: 'underline',
    //             },
    //           }}
    //         >
    //           베팅 내역 확인
    //         </Button>
    //       </Box>
    //     </Box>
    //   </Container>
    //   <Box sx={{ width: '100%', height: '100%' }} display="flex" justifyContent="center">
    //     <Box sx={{ width: '1200px' }}>
    //       <EvoGame></EvoGame>
    //     </Box>
    //   </Box>
    // </Fragment>
    <Fragment>
      <Box sx={{ background: '#16202F' }}>
        <Container sx={{ p: 0 }}>
          <Tabs value={tabIndex} onChange={handleChangeTab} css={BasicTabs} variant="fullWidth">
            {tabContents}
          </Tabs>
        </Container>
      </Box>
      <Container fixed sx={{ backgroundColor: appBackground, p: 0, maxWidth: 'none !important' }}>
        <Grid container>
          <Grid item xs={12} ref={boundaryRef}>
            <ScrollableStyle style={{ height: `${height}px` }}>
              {mounted && (
                <iframe
                  id="contentFrame"
                  className="game"
                  scrolling="no"
                  src={url}
                  style={{
                    transform: `scale(${sclX})`,
                    transformOrigin: '0 0',
                    width: `${origin.w}px`,
                    height: `${origin.h}px`,
                  }}
                ></iframe>
              )}
            </ScrollableStyle>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center">
          <Box sx={{ width: '794px', textAlign: 'end' }}>
            <Button
              onClick={onClickBettingRecord}
              size="large"
              sx={{
                mt: 1,
                mx: 'auto',
                py: 0,
                color: '#2366CB',
                fontSize: '1.1rem',
                textDecoration: 'underline',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              베팅 내역 확인
            </Button>
          </Box>
        </Box>
      </Container>
      <Box sx={{ width: '100%', height: '100%' }} display="flex" justifyContent="center">
        <EvoGame></EvoGame>
      </Box>
    </Fragment>
  )
}

export default observer(MobileMinigameEvo)
