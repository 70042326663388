/** @jsxImportSource @emotion/react */

import React from 'react'
import { jsx, css } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import useRootStore from '@store/useRootStore'
import { numberWithCommas } from '@utils/utils'
import asset_channel from '@assets/channel'

// css
import { Box, Button, Container, Typography } from '@mui/material'
import { FooterStyle } from './index.style'

import { MEDIA_MODE } from '~/store/globalStore'
import { useIntl } from 'react-intl'
//import { getDeviceType } from '~/game/util'

const APP_NAME = process.env.REACT_APP_NAME || ''

function Footer() {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()
  const { partnerMode } = globalStore

  const { desktop } = globalStore.decideMedia(isDesktop)

  const onClickSwitchDesktopView = (mode: string) => {
    globalStore.setMediaMode(mode)
  }

  let footerStyle = {}
  let switchContents = null
  let imageStyle = {
    '&.image': {
      height: '75px',
    },
  }

  if (desktop) {
    footerStyle = {
      '& .MuiContainer-root': {
        maxWidth: '1648px',
      },
    }

    if (!isDesktop) {
      switchContents = (
        <Button onClick={() => onClickSwitchDesktopView(MEDIA_MODE.RESPONSIVE)} sx={{ color: '#cccccc' }}>
          <SmartphoneIcon></SmartphoneIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.mobile-view' })}
        </Button>
      )
    }
  } else {
    footerStyle = {
      py: 2,
      '& .MuiContainer-root .inner > img': {
        height: '55px',
      },
    }
    if (!isDesktop) {
      switchContents = (
        <Button onClick={() => onClickSwitchDesktopView(MEDIA_MODE.DESKTOP)} sx={{ color: '#cccccc' }}>
          <DesktopWindowsIcon></DesktopWindowsIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.pc-view' })}
        </Button>
      )
    }
  }

  return (
    <FooterStyle sx={footerStyle} className="footer">
      <Box>{switchContents}</Box>
      <Container fixed>
        <Box className="inner">
          <Box className="image" sx={imageStyle}>
            <img src={asset_channel.common.footer_logo} alt="" />
          </Box>
          <Box className="copyright">
            {/* {APP_NAME} Group ⓒ {APP_NAME} Corp. All Rights Reserved. */}
            Copyright All rights Reserved.
          </Box>
        </Box>
      </Container>
    </FooterStyle>
  )
}

export default Footer
