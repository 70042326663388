/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css, Theme } from '@emotion/react'
import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Grid, Container, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { styled } from '@mui/material/styles'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import { LOCALSTORAGE_KEYS, MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { getStateString, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { AllMiniGames } from '~/game/mg_data'

// css
import { ImageListItemStyle, ImageListItemStyle2 } from '@styles/base.style'

// image
import { CasinoComing } from '~/assets/images/layout_1'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const TextStyle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: theme.colors.listTitle,
}))

const ListTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  color: theme.colors.listTitle,
  fontWeight: 'bold',
  fontSize: '1.8rem',
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const GameList: React.FC = props => {
  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)
  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('MG')
    }
  }, [signedin, initialized, serviceState])

  function onClickMingameCard(trading_group: string, trading_kind: string) {
    history.push(`/minigame/search?group=${trading_group}&kind=${trading_kind}`)
  }

  // exceptional maintenance
  let exMaintenance = false
  // switch (channelSymbol) {
  //   case 'myeongseong':
  //     exMaintenance = true
  //     break
  // }

  if (exMaintenance) {
    let responsiveHeight = ''
    if (desktop) {
      responsiveHeight = 'calc(100vh - 288px)'
    } else {
      responsiveHeight = 'calc(100vh - 202px)'
    }

    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: 1248, m: 'auto', minHeight: responsiveHeight, background: '#15191c' }}
        >
          <Grid item xs={10} sx={{ p: '40px 0', textAlign: 'center' }}>
            <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} />
            <TextStyle>{intl.formatMessage({ id: 'msg.maintenance' })}</TextStyle>
          </Grid>
        </Grid>
      </>
    )
  }

  let colsValue = desktop ? 4 : 2

  const ordered = _.orderBy(open_trading_kinds, ['order'], ['asc'])
  const listItems = []
  for (const trading of ordered) {
    const data = _.find(AllMiniGames, o => {
      return o.kind === trading.trading_kind
    })
    if (data) {
      if (channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk') {
        listItems.push(
          <ImageListItemStyle
            onClick={() => onClickMingameCard(trading.trading_group, trading.trading_kind)}
            key={trading.trading_kind}
          >
            <div className="img_wrap">
              <img
                src={data.thumbnail}
                alt={'game image ' + trading.trading_kind}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line">
                <img
                  src={data.hoverimg}
                  alt={'beginners image ' + data.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </ImageListItemStyle>,
        )
      } else if (channelSymbol === 'marine2') {
        listItems.push(
          <ImageListItemStyle2
            onClick={() => onClickMingameCard(trading.trading_group, trading.trading_kind)}
            key={trading.trading_kind}
          >
            <div className="img_wrap">
              <img
                src={data.thumbnail}
                alt={'game image ' + trading.trading_kind}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line"></div>
            </div>
          </ImageListItemStyle2>,
        )
      } else {
        listItems.push(
          <ImageListItemStyle
            onClick={() => onClickMingameCard(trading.trading_group, trading.trading_kind)}
            key={trading.trading_kind}
          >
            <div className="img_wrap">
              <img
                src={data.thumbnail}
                alt={'game image ' + trading.trading_kind}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line"></div>
            </div>
            <ImageListItemBar
              title={
                <div style={{ fontSize: '1.1rem' }}>
                  {intl.formatMessage({ id: data.localeId, defaultMessage: trading.name })}
                </div>
              }
              position="below"
              sx={{
                border: 'none',
                '& .MuiImageListItemBar-titleWrap': {
                  padding: '6px 0',
                },
              }}
            />
          </ImageListItemStyle>,
        )
      }
    }
  }

  let contents = null
  let minWidthDesktop = '1248px'

  if (channelSymbol === 'today' || channelSymbol === 'hongyeom' || channelSymbol === 'ace' || channelSymbol === 'ssg' || channelSymbol === 'praha') {
    minWidthDesktop = '1448px'
  }

  if (desktop) {
    if (channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk') {
      contents = (
        <Container className="content_wrap" sx={{ minWidth: '1648px', pb: 5, mt: 4 }}>
          <ImageList cols={5} gap={12}>
            {listItems}
          </ImageList>
        </Container>
      )
    } else if (channelSymbol === 'marine2') {
      contents = (
        <Box className="content_wrap" sx={{ pb: 5 }}>
          <Container sx={{ minWidth: '1368px', mx: '0' }}>
            <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
              {intl.formatMessage({ id: 'menu.header.minigame' })}
            </ListTitle>
            <ImageList cols={4} gap={12}>
              {listItems}
            </ImageList>
          </Container>
        </Box>
      )
    } else {
      contents = (
        <Container className="content_wrap" sx={{ minWidth: minWidthDesktop, pb: 5, backgroundColor: appBackground }}>
          <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>{intl.formatMessage({ id: 'menu.header.minigame' })}</ListTitle>
          <ImageList cols={4} gap={12}>
            {listItems}
          </ImageList>
        </Container>
      )
    }
  } else {
    if (channelSymbol === 'marine' || channelSymbol === 'marine2' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk') {
      contents = (
        <Box className="content_wrap_m" sx={{ p: 2, minHeight: 'calc(100vh - 202px)' }}>
          <ImageList cols={2} gap={12}>
            {listItems}
          </ImageList>
        </Box>
      )
    } else {
      contents = (
        <Box className="content_wrap_m" sx={{ backgroundColor: appBackground, p: 2, minHeight: 'calc(100vh - 202px)' }}>
          <ListTitle sx={{ fontSize: '1.2rem' }}>{intl.formatMessage({ id: 'menu.header.minigame' })}</ListTitle>
          <ImageList cols={2} gap={12}>
            {listItems}
          </ImageList>
        </Box>
      )
    }
  }

  return <>{contents}</>
}

export default observer(GameList)
