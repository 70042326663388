/** @jsxImportSource @emotion/react */
import { jsx, css, ClassNames } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react'
import {
  Grid,
  Box,
  Typography,
  Dialog,
  AppBar,
  IconButton,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Container,
  Card,
  Divider,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'

import useRootStore from '@store/useRootStore'
import { MakeHistoryTrack } from '@store/minigameStore'
import { numberWithCommas } from '@utils/utils'
import apiGame from '@services/api/game'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { ISportsBetRecords } from '~/v2/protocol/game'
import { SPORTS_LOCALE, SPORT_FILTER_ITEMS } from '@game/st-util'
import { useTheme } from '@mui/material/styles'

// icon
import CloseIcon from '@mui/icons-material/Close'

//css
import { MobileModalStyle, ModalStyle, MobileTableStyle, TableStyle } from '@styles/modal.style'
import { PaginationStyle } from '~/styles/base.style'
import { useHistory } from 'react-router-dom'
import BettingRecordItem from './BettingRecordItem'
import MobileBettingRecordItem from './MobileBettingRecordItem'

export const MobileRecordContainerStyle = styled(Box)(({ theme }) => ({
  '.MuiGrid-container': {
    alignItems: 'center',
    backgroundColor: '#16202F',
    color: '#D1D9E3',

    '& .date': {
      color: '#747D8A',
      textAlign: 'end',
    },
    '& .choice': {
      fontSize: '0.9rem',
    },
    '& .align_right_side': {
      textAlign: 'end',
      '.align_inner': {
        display: 'inline-flex',
        flexDirection: 'column',
        textAlign: 'start',
        width: '58px',
      },
    },
    '.MuiChip-root': {
      minWidth: 56,
      height: 20,
      color: '#fff',
      '& .MuiChip-label': {
        padding: 0,
      },
    },
    '& .MuiBox-root.title': {
      paddingTop: 5,
      color: '#747D8A',
      fontWeight: 'normal',
      fontSize: '0.9rem',
    },
  },
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const DarkenBg = styled(Box)(({ theme }) => ({
  paddingBottom: 1,
  background: theme.colors.darken,
}))

function timestamp(date: Date) {
  var time = new Date(date)
  time.setHours(time.getHours() + 9)
  return time
    .toISOString()
    .replace('T', ' ')
    .substring(0, 19)
}

interface PageData {
  currPage: number
  totalPage: number
}

type BettingRecordTrackData = ISportsBetRecords.Schema

const getPageData = (track: BettingRecordTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

const BettingRecord: React.FC<{}> = ({}) => {
  const theme = useTheme()
  const history = useHistory()
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const [track, setTrack] = useState<BettingRecordTrackData>(null)

  const { globalStore, authStore, sportsStore } = useRootStore()
  const { currentKey, currentValue } = globalStore
  const { signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)
  const { lang } = sportsStore

  const modalKey = ModalKeys.betting_record

  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex >= 0
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin) {
    // popup login
    globalStore.showModal('login', 0)
  }

  const fetch = async (params: ISportsBetRecords.Params) => {
    try {
      setTrack(null)
      const { offset, limit, total, items } = await apiGame.sportsRecords(params)
      const data: BettingRecordTrackData = {
        offset,
        limit,
        total,
        items,
      }
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    fetch({ offset: 0, limit: ItemPerPage })
  }, [])

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ offset, limit: ItemPerPage })
  }

  const onClickBack = () => {
    history.push('/sports')
  }

  // bet record
  const recordContents = []
  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    if (desktop) {
      for (let i = 0; i < track.items.length; i++) {
        const el = track.items[i]

        if (recordContents.length > 0) {
          recordContents.push(<div style={{ height: '20px' }}></div>)
        }

        recordContents.push(
          <BettingRecordItem item={el} theme={theme} intl={intl} lang={lang}></BettingRecordItem>,
        )
      }
    } else {
      for (let i = 0; i < track.items.length; i++) {
        const el = track.items[i]

        if (recordContents.length > 0) {
          recordContents.push(<div style={{ height: '20px' }}></div>)
        }

        recordContents.push(
          <MobileBettingRecordItem
            item={el}
            theme={theme}
            intl={intl}
            lang={lang}
          ></MobileBettingRecordItem>,
        )
      }
    }
  }

  const pagination = (
    <PaginationStyle
      count={totalTrackPage}
      variant="outlined"
      page={currTrackPage}
      onChange={handlePagingChange}
    />
  )

  let innerContents = null
  if (desktop) {
    innerContents = (
      <>
        <TableStyle>{recordContents}</TableStyle>
        {pagination}
      </>
    )
  } else {
    innerContents = (
      <>
        <Box sx={{ p: 1 }}>
          <MobileRecordContainerStyle>{recordContents}</MobileRecordContainerStyle>
          {pagination}
        </Box>
      </>
    )
  }

  let sx = {}
  const class_name = desktop ? 'content_wrap' : ''

  if (desktop) {
    sx = { margin: '0 auto', width: '1200px', padding: 0 }
  } else {
    sx = { width: '100%', minHeight: 'calc(100vh - 202px)' }
  }

  return (
    <DarkenBg sx={{ ...sx }} className={class_name}>
      <Box
        sx={{
          padding: '10px 0',
          backgroundColor: appBackground,
        }}
      >
        <Grid container>
          <Grid item xs={3}>
            <Box
              sx={{
                height: '42px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                onClick={() => onClickBack()}
                sx={{
                  color: '#adadad',
                  fontSize: '13px',
                  ml: desktop ? '26px' : '10px',
                  cursor: 'pointer',
                }}
              >
                &lt; {SPORTS_LOCALE.back.name[lang]}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                height: '42px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ color: 'white', fontSize: '16px' }}>
                {SPORTS_LOCALE.sports_betting_records.name[lang]}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        <Box component="div" sx={{ overflow: 'auto' }}>
          {innerContents}
        </Box>
      </Box>
    </DarkenBg>
  )
}

export default observer(BettingRecord)
