import http from '../http'
import {
  IPublicSettings,
  INotices,
  IPopups,
  ICaptcha,
  IWithdrawRanking,
  IRealtimeTransfer,
  IJackpot,
  ISignupV5,
} from '@protocol/public'
import { ISlotGameList } from '@protocol/casino'

const defs = {
  getSettings(data: IPublicSettings.Params): Promise<IPublicSettings.Schema> {
    return http.get('api/public/settings', data || {})
  },

  slotGames(data: ISlotGameList.Params): Promise<ISlotGameList.Schema> {
    return http.post('api/public/slot-games', data || {})
  },

  getNotices(data: INotices.Params): Promise<INotices.Schema> {
    return http.post('api/public/notices', data || {})
  },

  getWithdrawRanking(data: IWithdrawRanking.Params): Promise<IWithdrawRanking.Schema> {
    return http.post('api/public/withdraw-ranking', data || {})
  },

  getRealtimeTransfer(data: IRealtimeTransfer.Params): Promise<IRealtimeTransfer.Schema> {
    return http.post('api/public/realtime-transfer', data || {})
  },

  getPopups(data: IPopups.Params): Promise<IPopups.Schema> {
    return http.post('api/public/popups', data || {})
  },

  getCaptcha(data: ICaptcha.Params): Promise<ICaptcha.Schema> {
    return http.post('api/public/captcha', data || {})
  },

  jackpot(data: IJackpot.Params): Promise<IJackpot.Schema> {
    return http.post('api/public/jackpot', data || {})
  },

  signupV5(data: ISignupV5.Params): Promise<IJackpot.Schema> {
    return http.post('api/public/v5/signup', data || {})
  },

  signinV6(data: ISignupV5.Params): Promise<IJackpot.Schema> {
    return http.post('api/public/v6/signin', data || {})
  },
}

export default defs
