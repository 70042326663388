import * as _ from 'lodash'

export const MERCHANT_CLASS = ['운영사', '총본사', '본사', '총판', '매장', '회원']

export const BALANCE_PERIOD_DEFAULT = 'MONTHLY'
export const BALANCE_PERIOD = ['MONTHLY', '15_DAILY', '10_DAILY', 'DAILY']

export const FOLD_TYPE = [
  {
    id: 'SINGLE',
    name: '단폴',
  },
  {
    id: 'MULTI',
    name: '다폴',
  },
]

export const NOTICE_KIND = [
  {
    id: 'NOTICE',
    name: '공지사항',
  },
  {
    id: 'EVENT',
    name: '이벤트',
  },
  {
    id: 'POLICY',
    name: '이용규정',
  },
]

export const USER_TYPE = [
  {
    id: 'NORMAL',
    name: '일반회원',
  },
  {
    id: 'TEST',
    name: '테스트회원',
  },
]

export const INVOICE_STATE = [
  {
    id: 'REQUEST',
    name: '요청',
  },
  {
    id: 'PAID',
    name: '지급완료',
  },
  {
    id: 'CUT',
    name: '삭감',
  },
  {
    id: 'CARRYOVER',
    name: '이월',
  },
]

export const BET_STATE = [
  {
    id: 'BET',
    name: '베팅',
  },
  {
    id: 'WIN',
    name: '승',
  },
  {
    id: 'LOSE',
    name: '패',
  },
  {
    id: 'CANCEL',
    name: '취소',
  },
  {
    id: 'DRAW',
    name: '무',
  },
]

export const QNA_KIND = [
  {
    id: 'NORMAL',
    name: '일반문의',
  },
  {
    id: 'DEPOSIT',
    name: '입금문의',
  },
  {
    id: 'PARTNER',
    name: '파트너문의',
  },
]

export const QNA_STATE = [
  {
    id: 'QUESTION',
    name: '문의중',
  },
  {
    id: 'ANSWER',
    name: '답변완료',
  },
]

export const USER_STATE = [
  {
    id: 'NORMAL',
    name: '정상',
  },
  {
    id: 'WAITING',
    name: '승인대기',
  },
  {
    id: 'BLOCK',
    name: '정지',
  },
  {
    id: 'BLACK',
    name: '블랙',
  },
  {
    id: 'OUT',
    name: '탈퇴',
  },
]

export const TRANSFER_TYPE = [
  {
    id: 'NORMAL',
    name: '일반',
  },
  {
    id: 'COIN',
    name: '코인',
  },
  {
    id: 'WONP',
    name: '원피',
  },
]

export const TRANSFER_KIND = [
  {
    id: 'IN',
    name: '입금',
  },
  {
    id: 'OUT',
    name: '출금',
  },
]

export const TRANSFER_STATE = [
  {
    id: 'REQUEST',
    name: '신청',
  },
  {
    id: 'PROCESS',
    name: '진행중',
  },
  {
    id: 'SUCCESS',
    name: '완료',
  },
  {
    id: 'CANCEL',
    name: '취소',
  },
  {
    id: 'FAIL',
    name: '실패',
  },
]

export const BONUS_TYPE = [
  {
    id: 'NONE',
    name: '없음',
  },
  {
    id: 'MONEY',
    name: '머니',
  },
  {
    id: 'POINT',
    name: '포인트',
  },
]

export const BONUS_KIND = [
  {
    id: 'NONE',
    name: '없음',
  },
  {
    id: 'JOIN_DEPOSIT',
    name: '가입 첫충',
  },
  {
    id: 'FIRST_DEPOSIT',
    name: '매일 첫충',
  },
  {
    id: 'EVERY_DEPOSIT',
    name: '매충',
  },
]

export const COUPON_STATE = [
  { id: 'NORMAL', name: '사용가능' },
  { id: 'USED', name: '사용완료' },
  { id: 'EXPIRED', name: '기간만료' },
  { id: 'RECALLED', name: '회수' },
]

export const ALL_GAME_INFO = [
  {
    group: 'LCA',
    name: '라이브카지노',
    category: 'lca',
  },
  {
    group: 'HCA',
    name: '호텔카지노',
    category: 'hca',
  },
  {
    group: 'REEL',
    name: '릴',
    category: 'reel',
  },
  {
    group: 'SL',
    name: '슬롯게임',
    category: 'sl',
  },
  {
    group: 'BOARDGAME',
    name: '보드게임',
    category: 'bg',
  },
  {
    group: 'SP',
    name: '스포츠',
    category: 'sp',
  },
  {
    group: 'EVOPB',
    name: '에볼루션파워볼',
    category: 'mg',
    source: '',
  },
  {
    group: 'HPB',
    name: '해피파워볼',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'NPB',
    name: '엔트리파워볼',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'PB',
    name: '동행파워볼',
    category: 'mg',
    source: '',
  },
  {
    group: 'PL',
    name: '동행사다리',
    category: 'mg',
    source: '',
  },
  {
    group: 'CPB',
    name: '코인파워볼',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'CL',
    name: '코인사다리',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'SG',
    name: '오징어게임',
    category: 'mg',
    source: '',
  },
  {
    group: 'EOS',
    name: 'EOS파워볼',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'PBG',
    name: 'PBG파워볼',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'KINO',
    name: '동행스피드키노',
    category: 'mg',
    source: 'betpick',
  },
  {
    group: 'WRBTCPB',
    name: '우리볼BTC파워볼',
    category: 'mg',
    source: 'woori',
  },
  {
    group: 'WRBTCPL',
    name: '우리볼BTC사다리',
    category: 'mg',
    source: 'woori',
  },
  {
    group: 'WRBEXPB',
    name: '우리볼BEX파워볼',
    category: 'mg',
    source: 'woori',
  },
  {
    group: 'WRKENOPB',
    name: '동행키노플러스파워볼',
    category: 'mg',
    source: 'woori',
  },
  {
    group: 'WRKENOPL',
    name: '동행키노사다리',
    category: 'mg',
    source: 'woori',
  },
  {
    group: 'WRKENO',
    name: '동행키노파워볼',
    category: 'mg',
    source: 'woori',
  },
]

export const SUPPORT_MINIGAME_GROUP = [
  'PB',
  'PL',
  'EVOPB',
  'HPB',
  'NPB',
  'EOS',
  'PBG',
  'CPB',
  'CL',
  // 'KINO',
  // 'WRBTCPB',
  // 'WRBTCPL',
  // 'WRBEXPB',
  // 'WRKENOPB',
  // 'WRKENOPL',
  // 'WRKENO',
]

export const API_SUPPORT_MINIGAME_GROUP = [
  'PB',
  'PL',
  'HPB',
  'NPB',
  'EOS',
  'PBG',
  'CPB',
  'CL',
  // 'KINO',
  // 'WRBTCPB',
  // 'WRBTCPL',
  // 'WRBEXPB',
  // 'WRKENOPB',
  // 'WRKENOPL',
  // 'WRKENO',
]

//--------------------------------------------------------------------------------
// BETTING VISIBLE GROUP
// export const POWERBALL_MINIGAME_GROUP = ['PB', 'HPB', 'NPB', 'EOS', 'PBG', 'CPB', 'WRBTCPB', 'WRBEXPB'] // 파워볼, 일반볼
export const POWERBALL_MINIGAME_GROUP = [
  'EVOPB',
  'PB',
  'HPB',
  'NPB',
  'EOS',
  'PBG',
  'CPB',
  'WRBTCPB',
  'WRBEXPB',
] // 파워볼, 일반볼
export const POWERLADDER_MINIGAME_GROUP = ['PL', 'CL', 'WRBTCPL', 'WRKENOPL'] // 좌/우,3줄/4줄,홀/짝
export const WOORI_POWERBALL_MINIGAME_GROUP = ['WRKENOPB', 'WRKENO'] // 파워볼, 슈퍼볼
export const KINO_MINIGAME_GROUP = ['KINO']
// SG 독립

//--------------------------------------------------------------------------------
// COMPUTE GROUP
// [일반볼][파워볼]=[nb1, nb2, nb3, nb4, nb5][pb] 데이터로 계산되는 그룹(기본)
export const COMPUTE_GROUP_POWERBALL_NORMAL = [
  'EVOPB',
  'PB',
  'HPB',
  'NPB',
  'EOS',
  'PBG',
  'CPB',
  'WRBTCPB',
  'WRBEXPB',
]
// [일반볼 첫번째 숫자] =[nb1] 데이터로 계산되는 그룹(기본)
export const COMPUTE_GROUP_POWERLADDER_NORMAL = ['PL', 'CL']
// bepick 스피드키노
export const COMPUTE_GROUP_KINO_BETPICK = ['KINO']
// [좌/우][3줄/4줄]=[nb3][nb4] 데이터로 우리볼 btc 사다리 계산방식을 따르는 그룹
export const COMPUTE_GROUP_POWERLADDER_WOORI_BTC = ['WRBTCPL']
// [슈퍼볼][파워볼]=[nb1, pb] 데이터로 우리볼의 키노게임 계산방식을 따르는 그룹
export const COMPUTE_GROUP_KENO_POWERBALL_WOORI = ['WRKENOPB', 'WRKENO']
// [21번째볼]=[nb1] 데이터로 우리볼의 동행키노사다리 계산방식을을 따르는 그룹
export const COMPUTE_GROUP_KENO_POWERLADDER_WOORI = ['WRKENOPL']

export const MOBILE_CARRIERS = [
  { code: 'SKT', name: 'SKT' },
  { code: 'LGT', name: 'LG U+' },
  { code: 'KT', name: 'KT' },
  { code: 'SKT_NET', name: '알뜰폰(SKT)' },
  { code: 'LGT_NET', name: '알뜰폰(LG U+)' },
  { code: 'KT_NET', name: '알뜰폰(KT)' },
]

export const GENDERS = [
  { code: 'M', name: '남성' },
  { code: 'F', name: '여성' },
]
