import React, { useState } from 'react'
import * as _ from 'lodash'
import { Avatar, Box, Card, CardContent, CardHeader, Container, Grid, IconButton, Typography } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getDetailOddsboxName, getOddsRates } from '~/game/st-util'
import { Schema$Prematch, Schema$Market, Schema$FilteredMarket } from '~/v2/interface/st-schema'
import { observer } from 'mobx-react'
import useRootStore from '~/store/useRootStore'
import { Theme } from '@mui/material/styles'
import DetailOddsBoxAtom from './DetailOddsBoxAtom'

interface OddsProps {
  theme: Theme
  isTop: boolean
  type: string // represent, detail
  unifiedName: string // market name
  prematch: Schema$Prematch
  market: Schema$FilteredMarket
  expand: boolean
  expanded: boolean
  onToggleExpanded?: () => void
  sx?: any
}

function DetailOddsBoxN2W({
  theme,
  isTop,
  type,
  unifiedName,
  prematch,
  market,
  sx,
  expand,
  expanded,
  onToggleExpanded,
}: OddsProps) {
  const { sportsStore } = useRootStore()
  const { cartItems, lang } = sportsStore

  const { FixtureId } = prematch

  const { baseLine, bets } = getOddsRates(unifiedName, market.BaseLine, market.Bets)
  const [a, b] = bets

  if (!a || !b || !baseLine) {
    return <></>
  }

  const base = baseLine.split(' ')[0]

  let aname = getDetailOddsboxName(unifiedName, prematch, market.Id, a, lang)
  let bname = getDetailOddsboxName(unifiedName, prematch, market.Id, b, lang)

  const foundCartItem = _.find(cartItems, o => {
    return o.fixtureId === FixtureId && o.marketId === market.Id && o.bet.BaseLine === market.BaseLine
  })
  const aSelected = foundCartItem && a?.Id === foundCartItem.bet.Id
  const bSelected = foundCartItem && b?.Id === foundCartItem.bet.Id

  const handleExpanded = () => {
    if (expand && onToggleExpanded) {
      onToggleExpanded()
    }
  }

  const sxBaseline = isTop ? { border: '1px solid #182f49' } : null
  const arrWidth = [2, 5, 5]

  return (
    <Grid container justifyContent="center" sx={{ ...sx }}>
      <Grid
        item
        xs={arrWidth[0]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '30px', p: '0px 2px 0px 2px' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: '100%',
            ...sxBaseline,
          }}
        >
          <Grid container onClick={() => handleExpanded()}>
            <Grid item xs={3} display="flex" alignItems="center" justifyContent="center"></Grid>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{ color: '#62E2ED', fontWeight: 'bold' }}>{base}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center" justifyContent="center">
                {expand ? (
                  <IconButton sx={{}} color="primary">
                    {expanded ? <ExpandLessIcon sx={{ color: 'white' }} /> : <ExpandMoreIcon sx={{ color: 'white' }} />}
                  </IconButton>
                ) : null}{' '}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={arrWidth[1]} sx={{ height: '30px', p: '0px 2px 0px 2px' }}>
        {a ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={aname}
            unifiedName={unifiedName}
            atomName="a"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={a}
            selected={aSelected}
            drawSymbol={false}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
      <Grid item xs={arrWidth[2]} sx={{ height: '30px', p: '0px 2px 0px 2px' }}>
        {b ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={bname}
            unifiedName={unifiedName}
            atomName="b"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={b}
            selected={bSelected}
            drawSymbol={false}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default observer(DetailOddsBoxN2W)
