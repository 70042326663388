import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { bgTelegram } from '@assets/images/layout_4'

export const FooterStyle = styled('div')`
  background-color: ${props=>props.theme.footer.background};
  font-size: ${props => props.theme.fonts.tiny};
  text-align: center;

  .top_inner {
    background-color: #000;
  }

  .address {
    color: #999;
  }
  .copyright {
    margin-top: 24px;
    color: ${props=>props.theme.footer.copyright};
  }
`

export const SnsLink = styled(Box)(({ theme }) => ({
  display: 'flex',
  
  '& .MuiButton-root, & .no_link': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    padding: '15px 0 8px',
    width: '250px',
    height: '100px',
    borderRadius: 0,
    background: `url(${bgTelegram}) center 0 no-repeat`,
    backgroundSize: '100% 100%',
    lineHeight: 1.5,

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    '& img': {
      marginRight: 8,
      width: 26,
      height: 26,
    },

    '& .title': {
      color: '#000',
      fontSize: '.9rem',
      fontWeight: 'bold',
    },

    '& .id': {
      marginTop: '6px',

      '& > p': {
        textTransform: 'none',
        background: 'linear-gradient(#ffde46 0%, #ffbd2b 100%)',
        backgroundClip: 'text',
        color: 'transparent',
        fontSize: theme.fonts.title,
        fontWeight: 'bold',
      }
    },

    '& .tip': {
      marginTop: '2px',
      color: '#c1c3c4',
      fontSize: '.8rem',
    },
  },
}))