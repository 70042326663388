/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Chip, Tooltip, Typography } from '@mui/material'
import { popupCenter } from '@utils/utils'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { CopyToClipboard } from 'react-copy-to-clipboard'

// css
import { ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// icon
import { IconCopy } from '~/assets/images/layout_1'

// image
import DefaultContainer, { DefaultImages } from './d'
import MobileContainer, { MobileImages } from './m'

const NumberStyle = {
  mr: 1,
  borderRadius: 0,
  backgroundColor: '#FE3D3D',
  color: '#FFFFFF',
  height: 26,
  '.MuiChip-label': {
    padding: '0 10px',
  },
}

const PopupManualCoin: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore } = useRootStore()

  const [state, setState] = useState(false)

  const { currentKey, currentValue } = globalStore
  const { initialized, signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.menual_onepay_coin
  const tabIndex = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = tabIndex !== ''
  const [copiedTooltip, setCopiedTooltip] = useState(false)

  let visible = visibleOnDemand && signedin
  if (!signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
    return null
  }

  if (visible !== state) {
    setState(visible)
    return null
  }

  function onClose() {
    globalStore.hideModal(modalKey)
  }

  const handleTooltipClose = () => {
    setCopiedTooltip(false)
  }

  const handleTooltipOpen = () => {
    setCopiedTooltip(true)
  }

  const openYouTube = () => {
    const url = 'https://www.youtube.com/watch?v=vU01Oku22t8'
    window.open(url, '_blank')
  }

  const targetImages = desktop ? DefaultImages : MobileImages

  const innerContents = (
    <>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="1" />
        갤럭시 - 플레이스토어, 아이폰 - 앱스토어에서 업스톤을 검색하여 다운로드 해주세요.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[0]} alt="menual01" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="2" />
        회면에 안내되는 지갑을 누르고 톱니바퀴를 눌러 설정으로 들어갑니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[1]} alt="menual02" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="3" />
        내 계좌 관리를 클릭하고 양식에 맞게 계좌 정보를 등록합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[2]} alt="menual03" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="4" />
        본인정보 확인 후 진행되는 1원 인증 및 휴대폰 인증을 완료합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[3]} alt="menual04" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="5" />
        업스톤 아이콘을 클릭하여 금액에 맞는 캐시충전 선물하기를 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[4]} alt="menual05" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="6" />
        선물받는 사람의 아이디를 입력 후 선물하기를 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[5]} alt="menual05" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="7" />
        반드시 인증된 계좌를 통해 이체를 진행해야 구매가 가능합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[6]} alt="menual05" style={{ width: '100%' }} />
      </Box>

      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <CopyToClipboard text='A3AC' onCopy={handleTooltipOpen}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            open={copiedTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="top"
            title="복사되었습니다."
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '.7em',
                },
              },
            }}
          >
            <BasicButton onMouseLeave={handleTooltipClose} sx={{ width: '300px' }} style={{ border: '1px solid #3b4a65', backgroundColor: '#252f41', color: '#fff' }}>
              <span>선물 받을 아이디 "A3AC"</span>
              <IconCopy style={{ width: '15px', marginLeft: '8px' }} />
            </BasicButton>
          </Tooltip>
        </CopyToClipboard>
      </Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <BasicButton className="btn_main" onClick={() => openYouTube()} sx={{ width: '300px' }}>
          유튜브 영상 설명 보러가기
        </BasicButton>
      </Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <BasicButton className="btn_sub" onClick={() => onClose()} sx={{ width: '300px' }}>
          닫기
        </BasicButton>
      </Box>
    </>
  )

  let contents = null
  if (desktop) {
    contents = <DefaultContainer visible={visible} onClose={onClose} innerContents={innerContents}></DefaultContainer>
  } else {
    contents = <MobileContainer visible={visible} onClose={onClose} innerContents={innerContents}></MobileContainer>
  }
  return <>{contents}</>
}

export default observer(PopupManualCoin)
