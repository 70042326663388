import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const PointStyle = styled('div')`
  .point_title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .point_box {
    padding: 2rem;
    border: 1px solid #dddfe1;
    border-radius: 1rem;
  }
  .center_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5rem auto;
    width: 46px;
    height: 46px;
    border: 1px solid #dddfe1;
    border-radius: 50%;
    background-color: #fafafa;
    color: #bbbbbb;
    transform: rotate(90deg);
  }

  // 포인트 내 input 스타일 정의
  .form_control {
    text-align: end;
    font-weight: bold;

    &:read-only {
      background-color: #f5f5f5;

      &:focus {
        outline: none;
      }
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }

  @media (min-width: 600px) {
    .center_arrow {
      margin: 5rem auto 0;
      transform: rotate(0deg);
    }
  }
`
