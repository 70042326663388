// import { MarketGroup } from './market_group_types'
// export const BASEBALL_PREMATCH_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const BASEBALL_PREMATCH_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      { id: 1, unifiedName: '1X2', name: { en: '1X2', ko: '승무패' } },
      {
        id: 50,
        unifiedName: '1X2',
        name: { en: '1X2 Including Overtime', ko: '승무패 (연장포함)' },
      },
      { id: 52, unifiedName: '12', name: { en: '12', ko: '승패' } },
      { id: 226, unifiedName: '12', name: { en: '12 Including Overtime', ko: '승패 (연장포함)' } },
      { id: 3, unifiedName: 'HDP', name: { en: 'Asian Handicap', ko: '핸디캡 *' } },
      {
        id: 342,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap Including Overtime', ko: '핸디캡 (연장포함) *' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'Asian',
        },
        id: 835,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over', ko: '총 득점 *' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'Asian',
        },
        id: 1061,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over Including Overtime', ko: '총 득점 (연장포함) *' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'European',
        },
        id: 2,
        unifiedName: 'UO',
        name: { en: 'Under/Over', ko: '총 득점' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'European',
        },
        id: 28,
        unifiedName: 'UO',
        name: { en: 'Under/Over Including Overtime', ko: '총 득점 (연장포함)' },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: '1st 5 Innings', ko: '5이닝' },
    markets: [
      {
        id: 281,
        unifiedName: 'HDP',
        name: { en: '1st 5 Innings Asian Handicap', ko: '핸디캡 (5이닝) *' },
        period: 5,
      },
      {
        id: 236,
        unifiedName: 'UO',
        name: { en: '1st 5 Innings Under/Over', ko: '총 득점 (5이닝)' },
        period: 5,
      },
      {
        id: 2644,
        unifiedName: 'UO',
        name: { en: '1st 5 Innings Under/Over - Home Team', ko: '총 득점 - 홈팀 (5이닝)' },
        period: 5,
      },
      {
        id: 2645,
        unifiedName: 'UO',
        name: { en: '1st 5 Innings Under/Over - Away Team', ko: '총 득점 - 원정팀 (5이닝)' },
        period: 5,
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: '1st Inning', ko: '1이닝' },
    markets: [],
  },
  {
    groupId: 4,
    groupName: { en: 'Hits+Score+Error', ko: '안타+득점+실책' },
    markets: [],
  },
  {
    groupId: 5,
    groupName: { en: 'Team Specials', ko: '팀 스페셜' },
    markets: [
      {
        id: 101,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Home Team', ko: '총 득점 - 홈팀' },
      },
      {
        id: 102,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Away Team', ko: '총 득점 - 원정팀' },
      },
      {
        id: 221,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Home Team Including Overtime', ko: '총 득점 - 홈팀 (연장포함)' },
      },
      {
        id: 220,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over - Away Team Including Overtime',
          ko: '총 득점 - 원정팀 (연장포함)',
        },
      },
    ],
  },
  {
    groupId: 6,
    groupName: { en: 'Player Specials', ko: '플레이어 스페셜' },
    markets: [],
  },
]
