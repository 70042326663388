/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Grid, Box, List, ListItem } from '@mui/material'

import apiPublic from '@services/api/public'
import { Schema$RealtimeTransferItem } from '@interface/common'
import { IRealtimeTransfer } from '@protocol/public'
import { numberWithCommas, fillZero, difficultUsername } from '@utils/utils'

function RealtimeTransferBox({}) {
  const intl = useIntl()
  const history = useHistory()

  const [transfers, setTransfers] = useState<Schema$RealtimeTransferItem[]>([])

  useEffect(() => {
    fetchRealtimeTransfer()
  }, [])

  const fetchRealtimeTransfer = async () => {
    try {
      const params: IRealtimeTransfer.Params = {
        kind: 'OUT',
        limit: 10,
      }
      const { limit, total, items } = await apiPublic.getRealtimeTransfer(params)
      setTransfers([...items])
    } catch (err) {}
  }

  const listItems = []
  for (let i = 0; i < transfers.length && i < 5; i++) {
    const el = transfers[i]
    const username = difficultUsername(el.userId)
    const date = new Date(el.date)
    const dateAt = `${fillZero(2, date.getFullYear().toString())}-${fillZero(
      2,
      (date.getMonth() + 1).toString(),
    )}-${fillZero(2, date.getDate().toString())} ${fillZero(2, date.getHours().toString())}:${fillZero(
      2,
      date.getMinutes().toString(),
    )}`
    listItems.push(
      <ListItem key={i + 1}>
        <Grid container>
          <Grid item className="date" xs={5}>
            {dateAt}
          </Grid>
          <Grid item className="amount" xs={4}>
            {numberWithCommas(el.volume)}원
          </Grid>
          <Grid item className="name" xs={3}>
            <div className="content ellipsis">{username}</div>
          </Grid>
        </Grid>
      </ListItem>,
    )
  }

  return (
    <Box className="main_list">
      <div className="main_title">
        <span>{intl.formatMessage({ id: 'main.realtime-withdraw' })}</span>
      </div>

      <List>{listItems}</List>
    </Box>
  )
}

export default RealtimeTransferBox
