/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import moment from 'moment'
import * as _ from 'lodash'
import { Box, Chip, Divider, Grid, Theme, Typography } from '@mui/material'
import { numberWithCommas } from '~/utils/utils'
import { Schema$SportsBetFold, Schema$SportsBetRecord } from '~/v2/protocol/game'
import { IntlShape } from 'react-intl'
import {
  SPORTS_LOCALE,
  FLIGHT_FILTER_ITEMS,
  getBetTokenName,
  getCartTitle,
  getTeamName,
  getTeamScore,
  SPORT_FILTER_ITEMS,
  toClientbaseline,
} from '~/game/st-util'
import useRootStore from '~/store/useRootStore'
import { makeStyles } from '@mui/styles'
import apiGame from '@services/api/game'
import { makeSportsBetStateColor } from '~/store/sportsStore/sports-util'

interface Props {
  item: Schema$SportsBetRecord
  theme: Theme
  intl: IntlShape
  lang: string
}

function MobileBettingRecordItem({ item, theme, intl, lang }: Props) {
  const { globalStore } = useRootStore()
  const [updated, setUpdated] = useState<number>(new Date().getTime())

  const fetchCancel = async () => {
    try {
      const { notice, record } = await apiGame.cancelBet({ betId: item.id })
      if (record) {
        item.dividend = record.dividend
        item.rate = record.rate
        item.state = record.state
        item.folds = record.folds
        item.canCancel = record.canCancel
        item.cancelDueDate = record.cancelDueDate
        setUpdated(new Date().getTime())
      }

      if (notice) {
        globalStore.pushDialogOk({ text: notice })
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchHide = async () => {
    try {
      const { user_field } = await apiGame.hideSportsRecord({ id: item.id })
      item.user_field = user_field
      setUpdated(new Date().getTime())
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickCancel = () => {
    globalStore.pushDialogYesNo({
      text: '베팅을 취소하시겠습니까?',
      callbackPositive: async () => {
        fetchCancel()
      },
    })
  }

  const onClickHide = () => {
    globalStore.pushDialogYesNo({
      text: '해당 내역을 삭제하시겠습니까?',
      callbackPositive: () => {
        fetchHide()
      },
    })
  }

  const classes = makeStyles(theme => ({
    deleteIcon: {
      '&.MuiChip-deleteIcon': {
        color: '#dee1e6',
        '&:hover': {
          color: '#dee1e6',
        },
      },
    },
  }))()

  const {
    name: betStateName,
    background: betBackground,
    foreground: betForeground,
  } = makeSportsBetStateColor(item.state, null, false, lang)
  const foldCount = item.folds.length
  const betVolume = numberWithCommas(item.betMoney)
  const rate = item.rate
  const winMoney = numberWithCommas(item.dividend)
  const bettingTime = moment(new Date(item.bettingTime)).format('MM/DD HH:mm:ss')

  const canCancel = item.canCancel
  let ssCancelDueDate = null
  if (canCancel && item.cancelDueDate) {
    const dateName = moment(item.cancelDueDate).format('MM/DD HH:mm:ss')
    ssCancelDueDate = lang === 'ko' ? `${dateName} 까지 취소 가능` : `Can cancel until ${dateName}`
  }

  const canHide = item.state !== 'BET'

  const contents = []
  const betContents = []
  betContents.push(
    <Box sx={{ background: theme.sportsRecord.betTitle }}>
      <Box sx={{ padding: '10px 10px 10px 10px', background: '#16202F' }}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex">
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.bet_datetime' })}
            </Typography>
            <Typography sx={{ ml: 1, fontSize: '13px', color: '#d1d9e3' }}>
              {bettingTime}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end">
            {canCancel ? (
              <Chip
                label={lang === 'ko' ? '취소' : 'Cancel'}
                size="small"
                variant="outlined"
                onClick={() => onClickCancel()}
                onDelete={onClickCancel}
                classes={{ deleteIcon: classes.deleteIcon }}
                style={{
                  minWidth: '60px',
                  color: 'white',
                }}
              />
            ) : (
              <>
                <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.state' })}
                </Typography>
                <Chip
                  label={betStateName}
                  size="small"
                  sx={{ ml: 1, minWidth: '60px', background: betBackground, color: betForeground }}
                />
              </>
            )}
          </Box>
        </Box>
        {canCancel && (
          <Box display="flex" justifyContent="end">
            <Typography sx={{ color: 'red', mt: '4px' }}>{ssCancelDueDate}</Typography>
          </Box>
        )}
      </Box>
      <Grid container sx={{ m: 0, padding: '0 10px 10px 10px' }}>
        <Grid item xs={2}>
          <Box>
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.bet_fold_size' })}
            </Typography>
            <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{foldCount}</Typography>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
            {intl.formatMessage({ id: 'table.odds' })}
          </Typography>
          <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{rate}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="right" sx={{ fontSize: '13px', color: '#747d8a', width: '100%' }}>
            {intl.formatMessage({ id: 'minigame.power.table.bet.volume' })}
          </Typography>
          <Typography align="right" sx={{ fontSize: '13px', color: '#d1d9e3', align: '100%' }}>
            {betVolume}
            {intl.formatMessage({ id: 'money-locale' })}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography align="right" sx={{ fontSize: '13px', color: '#747d8a', width: '100%' }}>
            {intl.formatMessage({ id: 'minigame.power.table.bet.winmoney' })}
          </Typography>
          <Typography align="right" sx={{ fontSize: '13px', color: '#d1d9e3', width: '100%' }}>
            {winMoney}
            {intl.formatMessage({ id: 'money-locale' })}
          </Typography>
        </Grid>
      </Grid>
    </Box>,
  )

  const validFolds = _.filter(item.folds, (o: Schema$SportsBetFold) => {
    return o.marketName?.en !== 'Fold Bonus'
  })

  const bonusFolds = _.filter(item.folds, (o: Schema$SportsBetFold) => {
    return o.marketName?.en === 'Fold Bonus'
  })

  const orderedFolds = _.orderBy(
    validFolds,
    o => {
      return o.prematch ? new Date(o.prematch.StartDate).getTime() : 0
    },
    ['desc'],
  )

  const foldContents = []
  for (const fold of orderedFolds) {
    const homeName = getTeamName(fold.prematch, '1', lang)
    const awayName = getTeamName(fold.prematch, '2', lang)
    const title = fold.prematch
      ? getCartTitle(fold.prematch, fold.marketId, fold.marketBetName, lang)
      : getBetTokenName(fold.prematch.SportId, fold.marketId, fold.marketBetName, lang)
    let marketNameKo = fold.marketName[lang]
    if (fold.baseLine) {
      marketNameKo = `${marketNameKo} [${toClientbaseline(fold.baseLine)}]`
    }
    const leagueName = fold.prematch?.LeagueName[lang] || fold.prematch?.LeagueName.en
    const vsTitle = `${homeName} vs ${awayName}`
    const homeScore = getTeamScore(fold.prematch, '1')
    const awayScore = getTeamScore(fold.prematch, '2')

    const {
      name: foldStateName,
      background: foldBackground,
      foreground: foldForeground,
    } = makeSportsBetStateColor(fold.state, fold.prematch?.Status, true, lang)

    const startTime = fold.prematch
      ? moment(new Date(fold.prematch.StartDate)).format('MM/DD HH:mm:ss')
      : 'Unknown'
    const objSymbol = _.find(SPORT_FILTER_ITEMS, o => {
      return o.value === fold.prematch?.SportId.toString()
    })
    const symbol = objSymbol && objSymbol.symbol

    foldContents.push(
      <Box sx={{ background: theme.sportsRecord.foldTitle, color: 'white' }}>
        {foldContents.length > 0 && <Divider sx={{ background: '#484f5a' }}></Divider>}
        <Grid container sx={{ padding: '8px 8px' }}>
          <Grid item xs={6} display="flex">
            {symbol}
            <Typography sx={{ ml: 0, fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.date_of_match' })}
            </Typography>
            <Typography sx={{ ml: 1, fontSize: '13px', color: '#d1d9e3' }}>{startTime}</Typography>
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="end">
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'sports.table.bet.state' })}
            </Typography>
            <Chip
              label={foldStateName}
              size="small"
              sx={{
                ml: 1,
                fontSize: '11px',
                width: '56px',
                height: '24px',
                background: foldBackground,
                color: foldForeground,
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ padding: '0 10px 8px 10px', background: '#16202F' }}>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {lang === 'ko' ? '리그' : 'League'}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{leagueName}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.match' })}
            </Typography>
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.odds' })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{vsTitle}</Typography>
            <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{fold.oddsRate}</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            {homeScore && (
              <Grid container>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ border: 1, borderColor: '#2d4464' }}
                >
                  {homeScore}
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ border: 1, borderColor: '#2d4464' }}
                >
                  {awayScore}
                </Grid>
              </Grid>
            )}
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.betting' })}
            </Typography>
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>
              {intl.formatMessage({ id: 'table.betting_choice' })}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{marketNameKo}</Typography>
            <Typography sx={{ fontSize: '13px', color: '#d1d9e3' }}>{title}</Typography>
          </Box>
        </Box>
      </Box>,
    )
  }

  for (const fold of bonusFolds) {
    let condition = null
    const { sportsFoldBonus } = item.bonusResult || {}
    if (sportsFoldBonus) {
      condition = (
        <Box display="flex">
          <Typography sx={{ color: '#e6a739' }}>{sportsFoldBonus.base}</Typography>
          <Typography sx={{ color: '#999999', ml: 1 }}>
            {SPORTS_LOCALE.odds_more.name[lang]}
          </Typography>
        </Box>
      )
    }
    const title = `${fold.marketBetName} ${intl.formatMessage({ id: 'fold-bonus' })}`
    foldContents.push(
      <Box sx={{ background: theme.sportsRecord.foldTitle, color: 'white' }}>
        <Divider sx={{ background: '#484f5a' }}></Divider>
        <Grid container sx={{ padding: '8px 8px' }}>
          <Grid item xs={6} display="flex"></Grid>
          <Grid item xs={6} display="flex" justifyContent="end"></Grid>
        </Grid>
        <Box sx={{ padding: '0 10px 8px 10px', background: '#16202F' }}>
          <Box display="flex" justifyContent={condition ? 'space-between' : 'end'}>
            {condition}
            <Typography sx={{ fontSize: '13px', color: '#747d8a' }}>배당률</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ fontSize: '13px', color: '#ffdc1e' }}>{title}</Typography>
            <Typography sx={{ fontSize: '13px', color: '#ffdc1e' }}>{fold.oddsRate}</Typography>
          </Box>
        </Box>
      </Box>,
    )
  }

  betContents.push(
    <Box sx={{ background: theme.sportsRecord.foldBg, p: '6px' }}>{foldContents}</Box>,
  )

  const flightItem = _.find(FLIGHT_FILTER_ITEMS, o => {
    return o.value === item.flight
  })
  contents.push(
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" justifyContent="begin">
        <Typography
          sx={{ ml: '2px', mb: '2px', fontSize: '13px', color: flightItem?.color || 'white' }}
        >
          {flightItem?.name[lang] || ''}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end">
        {item.user_field === 'HIDDEN' ? (
          <Typography
            sx={{ mr: '2px', mb: '2px', fontSize: '13px', color: '#ff3333', cursor: 'pointer' }}
          >
            {lang === 'ko' ? '삭제됨' : 'deleted'}
          </Typography>
        ) : (
          canHide && (
            <Typography
              onClick={() => onClickHide()}
              sx={{ mr: '2px', mb: '2px', fontSize: '13px', color: '#cccccc', cursor: 'pointer' }}
            >
              {lang === 'ko' ? '삭제' : 'delete'}
            </Typography>
          )
        )}
      </Box>
    </Box>,
  )
  contents.push(
    <Box sx={{ border: 2, borderColor: theme.sportsRecord.betTitle }}>{betContents}</Box>,
  )

  return <>{contents}</>
}

export default MobileBettingRecordItem
