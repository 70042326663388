import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Formik, useFormik } from 'formik'
import { Box, Grid, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import * as _ from 'lodash'
import NumberFormat from 'react-number-format'

// images
import { ReactComponent as DoubleArrow } from '@assets/images/layout_1/double_arrow.svg'

import { FormStyle } from '@styles/modal.style'
import { PointStyle } from './index.style'
import useRootStore from '@store/useRootStore'
import { BasicButton } from '@styles/button.style'
import { Schema$PartnerMemeberItem, Schema$PartnerUserConfig } from '@protocol/partner'
import apiPartner from '@services/api/partner'
import apiUser from '@services/api/user'
import { numberWithCommas, invalidateNumber } from '~/utils/utils'

interface Props {
  member_id: string
}

const MoneyGive: React.FC<Props> = ({ member_id }) => {
  const intl = useIntl()
  const { globalStore, authStore, userStore } = useRootStore()
  const { option } = globalStore
  const { userInfo, userMoney, userPoint } = userStore
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { initialized } = authStore

  const [memberInfo, setMemberInfo] = useState<Schema$PartnerMemeberItem | null>(null)

  const targetMoney = memberInfo ? memberInfo.money : 0
  const targetName = memberInfo ? memberInfo.nick : ''

  if (userInfo) {
    if (userInfo.uuid === member_id) {
      window.close()
    }
  }

  const { canTransferMoney } = option || {}
  const [partnerUserConfig, setPartnerUserConfig] = useState<Schema$PartnerUserConfig | null>(null)
  const { is_active_give_money, is_active_take_money } = partnerUserConfig || {}
  const canGiveMoney = canTransferMoney && is_active_give_money

  const fetchMember = async () => {
    // get user info
    try {
      const { item } = await apiPartner.getMember({ uuid: member_id })
      setMemberInfo(item)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchPartnerUserConfig = async () => {
    try {
      const { config } = await apiPartner.getUserConfig({})
      setPartnerUserConfig(config)
    } catch (err) {}
  }

  useEffect(() => {
    async function inline() {
      await globalStore.getPublicSettings(intl)
      const recovered = await authStore.recoverSession(intl)
      if (!recovered) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: intl.formatMessage({ id: 'msg.no_login_info' }),
          callbackPositive: () => {
            window.close()
          },
        })
      } else {
        await fetchMember()
        await fetchPartnerUserConfig()
      }
    }
    inline()
  }, [])

  const submitTransfer = async (values: any) => {
    if (!canGiveMoney) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.money_payout_not_available' }),
      })
      formik.setSubmitting(false)
      return
    }
    const { volume } = values
    let amount = invalidateNumber(volume)
    if (amount === 0) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.enter_quantity' }),
      })
      formik.setSubmitting(false)
      return
    }

    globalStore.pushDialogLocaleYesNo({
      text: 'msg.format.ask.pay_money_to',
      arg1: targetName,
      arg2: numberWithCommas(amount),
      callbackPositive: async () => {
        try {
          await apiUser.transferMoney({
            type: 'give',
            target_uuid: member_id,
            volume: amount,
          })
          globalStore.pushDialogOk({
            text: intl.formatMessage({ id: 'msg.req-transfer-money-give' }),
          })
        } catch (err) {
          globalStore.pushErrorObject(err, intl)
        }

        await userStore.loadAsset(intl)
        await fetchMember()
        formik.setFieldValue('volume', 0)

        formik.setSubmitting(false)
      },
      callbackNegative: () => {
        formik.setSubmitting(false)
      },
    })
  }

  const formik = useFormik({
    initialValues: {
      volume: 0,
    },
    onSubmit: values => {
      submitTransfer(values)
    },
    // validationSchema={Yup.object().shape({
    // 	// volume: Yup.number().required(intl.formatMessage({ id: 'msg.field-require' })),
    // })}
  })

  return (
    <Box py={6}>
      <PointStyle>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <Grid container sx={{ px: 3, position: 'relative' }}>
            <Grid item xs={12} sm={5.5} sx={{ px: 1 }}>
              <Box className="point_title" sx={{ mb: 2 }}>
                내 머니
              </Box>
              <FormStyle className="point_box">
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <label className="form_label">머니</label>
                  </Grid>
                  <Grid item xs={8}>
                    <NumberFormat
                      className="form_control"
                      name="balanceMoney"
                      value={balanceMoney}
                      thousandSeparator={true}
                      style={{ fontSize: '1.3rem', textAlign: 'end' }}
                      readOnly
                    ></NumberFormat>
                  </Grid>
                </Grid>
              </FormStyle>
            </Grid>

            <Grid xs={12} sm={1}>
              <div className="center_arrow" style={{ background: '#1979e6' }}>
                <DoubleArrow width="23" />
              </div>
            </Grid>

            <Grid item xs={12} sm={5.5} sx={{ px: 1 }}>
              <Box className="point_title" sx={{ mb: 2, color: '#2A73E1' }}>
                {targetName ? `${targetName} 의 머니` : null}&nbsp;
              </Box>
              <FormStyle className="point_box">
                <Grid container alignItems="center">
                  <Grid item xs={4}>
                    <label className="form_label">머니</label>
                  </Grid>
                  <Grid item xs={8}>
                    <NumberFormat
                      className="form_control"
                      name="targetMoney"
                      value={targetMoney}
                      thousandSeparator={true}
                      style={{ fontSize: '1.3rem', textAlign: 'end' }}
                      readOnly
                    ></NumberFormat>
                  </Grid>
                </Grid>
              </FormStyle>
            </Grid>
          </Grid>

          {canGiveMoney ? (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ maxWidth: '500px', m: '0 auto', pt: 4 }}
            >
              <Grid item xs={7}>
                <Typography component="p" sx={{ mb: 1, fontWeight: '700' }}>
                  지급 머니
                </Typography>
                <FormStyle>
                  <NumberFormat
                    className="form_control point"
                    name="volume"
                    placeholder="수량을 입력하세요"
                    value={formik.values.volume}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    thousandSeparator={true}
                    min={0}
                    style={{ fontSize: '1.3rem', textAlign: 'end' }}
                  ></NumberFormat>
                </FormStyle>
              </Grid>
              {formik.errors.volume && (
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
                  <span style={{ color: 'red' }}>수량를 입력해주세요.</span>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              sx={{ maxWidth: '500px', m: '0 auto', pt: 10 }}
            >
              <Grid item xs={12}>
                <Typography component="p" sx={{ mb: 1, fontWeight: '700', textAlign: 'center' }}>
                  머니 지급 권한이 없습니다.
                </Typography>
              </Grid>
            </Grid>
          )}

          {canGiveMoney ? (
            <Box sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}>
              <BasicButton
                type="submit"
                className="btn_main"
                disabled={formik.isSubmitting}
                style={{ minWidth: '240px' }}
              >
                지급하기
              </BasicButton>
            </Box>
          ) : null}
        </form>
      </PointStyle>
    </Box>
  )
}

export default observer(MoneyGive)
