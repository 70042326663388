import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import {
  Tab,
  Tabs,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
} from '@mui/material'

import { green, pink } from '@mui/material/colors'
import DateRangeIcon from '@mui/icons-material/DateRange'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps } from '@shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { IPartnerAccountBalanceSource } from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { ALL_GAME_INFO } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: number
  date: Date
  bet_volume: number
  win_volume: number
  profit: number
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>날짜</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>당첨액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>수익</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData }) {
  const { row } = props

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{moment(row.date).format('YYYY-MM-DD')}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(Math.floor(row.bet_volume))}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(Math.floor(row.win_volume))}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.profit)) }}>
            {numberWithCommas(Math.floor(row.profit))}
          </Typography>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

function PartnerIntergratedBalanceAccountRequest({ location }) {
  const query = queryString.parse(location.search)
  const { main } = query
  const startTab = Math.max(
    0,
    _.findIndex(ALL_GAME_INFO, o => {
      return o.group === main
    }),
  )

  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<IPartnerAccountBalanceSource.Schema | null>(null)

  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { option } = globalStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups } = option || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  if (startTab !== tab) {
    setTab(startTab)
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-BALANCE-REQUEST')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.balance_account_request)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const fetch = async (params: IPartnerAccountBalanceSource.Params) => {
    setLoading(true)

    try {
      const ret = await apiPartner.getBalanceAccountSource(params)
      setData(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetch({ trading_group: ALL_GAME_INFO[tab].group })
  }, [tab])

  const handleChangeTab = (event, newValue) => {
    history.push(`/partner/balance-request/search?main=${ALL_GAME_INFO[newValue].group}`)
  }

  const onClickRequest = () => {
    async function test() {
      try {
        await apiPartner.reqBalanceAccount({
          start_date: data.begin_date,
          end_date: data.end_date,
          volume: data.volume,
          trading_group: ALL_GAME_INFO[tab].group,
        })
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.settlement_requested' }),
        })
        fetch({ trading_group: ALL_GAME_INFO[tab].group })
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }
    }
    test()
  }

  const tabItems = []
  for (let i = 0; i < ALL_GAME_INFO.length; i++) {
    const tabHidden =
      _.findIndex(open_trading_groups, o => {
        return o.trading_group === ALL_GAME_INFO[i].group
      }) < 0
    tabItems.push(
      <Tab hidden={tabHidden} label={ALL_GAME_INFO[i].name} {...a11yProps(i)} style={{ fontSize: '14px' }} />,
    )
  }

  let rows = []
  let dateRange = null
  let volume = null
  let disabledRequest = true

  const pendingRequest = data && data.top_invoice_state === 'REQUEST'

  if (data) {
    for (let i = 0; i < data.items.length; i++) {
      const el = data.items[i]
      const row = {
        id: i,
        date: new Date(el.date),
        bet_volume: el.bet_volume,
        win_volume: el.win_volume,
        profit: el.profit,
      }
      rows.push(<Row key={row.id} row={row}></Row>)
    }

    rows = _.orderBy(rows, ['date'], ['asc'])

    if (data.begin_date && data.end_date) {
      const beginDate = new Date(data.begin_date)
      let endDate = new Date(data.end_date)
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() - 1)
      dateRange = `~ ${moment(endDate).format('YYYY-MM-DD')}`
      dateRange = `${moment(beginDate).format('YYYY-MM-DD')} ${dateRange}`
      volume = data.volume
    }

    disabledRequest = !data.canRequest
  }

  const pending_card = (
    <List sx={{ width: '100%', marginTop: '16px', border: '1px solid #c2c5c5', bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary=""
          secondary={
            <Box display="flex" alignItems="center" justifyContent="center">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', width: '100%', textAlign: 'center' }}>
                    정산요청중입니다.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '16px', width: '100%', textAlign: 'center' }}>
                    정산내역에서 확인하세요.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          }
        />
      </ListItem>
    </List>
  )

  const invoice_card = (
    <List sx={{ width: '100%', marginTop: '16px', border: '1px solid #c2c5c5', bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: pink[500] }}>
            <DateRangeIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="기간"
          secondary={
            <Fragment>
              <Stack direction="row-reverse">
                <Typography
                  sx={{ fontSize: '15px', display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {dateRange}
                </Typography>
              </Stack>
            </Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: green[500] }}>
            <MonetizationOnIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="금액"
          secondary={
            <Fragment>
              <Stack direction="row-reverse">
                <Typography
                  sx={{ fontSize: '16px', display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {_.isNumber(volume) ? numberWithCommas(Math.floor(volume)) : null}
                </Typography>
              </Stack>
            </Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start" style={{ height: '50px' }}>
        <ListItemText
          primary=""
          secondary={
            <Box display="flex" alignItems="center" justifyContent="center">
              <Button
                disabled={disabledRequest}
                onClick={() => onClickRequest()}
                variant={disabledRequest ? 'outlined' : 'contained'}
                style={disabledRequest ? {} : { background: '#007f93' }}
              >
                정산요청
              </Button>
            </Box>
          }
        />
      </ListItem>
    </List>
  )

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1248px',
          maxWidth: '1248px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}>
            <Divider sx={{ width: '200px', fontSize: '16px' }}>정산 요청</Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
                {tabItems}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
              <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
              <Table size="small" aria-label="collapsible table">
                {Head()}
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={0.4}></Grid>
          <Grid item xs={5.6}>
            {pendingRequest ? pending_card : invoice_card}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedBalanceAccountRequest)
