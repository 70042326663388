/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Grid, Box, Typography } from '@mui/material'
import * as _ from 'lodash'

import useRootStore from '@store/useRootStore'

import { MarketMatrixData, MakeTestMatchupMatrix } from '~/game/common'
import { POWERLADDER_MARKET_GROUP, MarketMatrix } from '~/game/powerladder'
import { BettingCartItem } from '~/store/minigameStore'

// css
import { BasicButton } from '~/styles/button.style'
import { SelectBox, ScrollableStyle } from './index.style'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'

// const loadableText = 'N/A'
const loadableText = '. . .'

const makeMarketBlock = (
  tradingKind: string,
  marketGroup: number,
  srcMatrix: MarketMatrixData[][],
  selectedId: string,
  onClick: (tradingKind: string, marketGroup: number, marketId: string, oddsId: string) => void,
  colType: string,
) => {
  const contents = []
  for (const row of srcMatrix) {
    for (const col of row) {
      const innerItems = []
      const disabled = selectedId === '0' || !col.odds
      //const color = selectedId === '1' || selectedId === col.id ? col.acolor : col.dcolor
      const active = selectedId === col.id ? 'selected' : ''
      let bgColor = col.dcolor
      if (selectedId === col.id) {
        bgColor = col.acolor
      }
      if (col.odds) {
        if (col.odds.disabled) {
          continue
        }
      }
      innerItems.push(
        <BasicButton
          key={col.id}
          className={'btn_bet ' + active}
          disabled={disabled}
          onClick={() => onClick(tradingKind, marketGroup, col.marketId, col.id)}
          style={{ backgroundColor: bgColor, minWidth: 'auto' }}
        >
          <div>{col.odds ? col.odds.locale.name : loadableText}</div>
          <div>{col.odds ? col.odds.rate : loadableText}</div>
        </BasicButton>,
      )

      if (colType === 'type1') {
        if (
          col.marketId === 'PLLEFT_PLRIGHT' ||
          col.marketId === 'PLLINE' ||
          col.marketId === 'PLODD_PLEVEN'
        ) {
          contents.push(
            <Grid item xs={6} key={col.id}>
              {innerItems}
            </Grid>,
          )
        } else {
          contents.push(
            <Grid item xs={6} key={col.id}>
              {innerItems}
            </Grid>,
          )
        }
      }
    }
    //const marginTop = innerItems.length > 0 ? '6px' : '0px'
    //contents.push(<Row style={{ marginTop }}>{innerItems}</Row>)
  }
  return contents
}

const getSelectedId = (items: BettingCartItem[], marketGroup: number) => {
  const powerItem = _.find(items, function(o) {
    return o.marketGroup === marketGroup
  })
  return powerItem ? powerItem.oddsId : '1'
}

interface Props {
  kind: string
  width?: number
}

const MarketPanelPowerladder: React.FC<Props> = ({ kind, width }) => {
  // '0': disabled, '1': interfactable, 'xxxx': selected
  const { miniGameStore } = useRootStore()
  const [mounted, setMounted] = useState<boolean>(false)
  const { matchup, cartItems } = miniGameStore

  useOnMount(() => {
    miniGameStore.setGameTab(kind)
    setTimeout(() => {
      setMounted(true)
    }, 150)
  })

  let selected = getSelectedId(cartItems, POWERLADDER_MARKET_GROUP.ALL)

  const matchupMatrix = MakeTestMatchupMatrix(MarketMatrix, matchup)

  const powerContents = makeMarketBlock(
    'PL',
    POWERLADDER_MARKET_GROUP.ALL,
    matchupMatrix['PL']['PL1'],
    selected,
    miniGameStore.toggleCartItem,
    'type1',
  )

  let url = ''
  let urlResult = ''
  let origin = { w: 830, h: 660 }
  switch (kind) {
    case 'PL1':
      url = 'https://dhpowerball.net/rpowerball/live.php'
      urlResult = 'https://dhpowerball.net/rpowerball/main.php'
      origin = { w: 900, h: 640 }
      break
    case 'CL':
      url = 'https://game.tspick.com/webgl/Lottery_Pick_LD/index.html?kind=powerball_ladder'
      urlResult = 'https://dr-score.com/minigame/date/powerladder'
      origin = { w: 900, h: 506 }
      break
    case 'CL3M':
      url = 'https://bepick.net/live/coinladder3/scrap'
      urlResult = 'https://bepick.net/#/game/daily/coinladder3'
      origin = { w: 830, h: 660 }
      break
    case 'CL5M':
      url = 'https://bepick.net/live/coinladder5/scrap'
      urlResult = 'https://bepick.net/#/game/daily/coinladder5'
      origin = { w: 830, h: 660 }
      break
    case 'WRBTCPL1':
      url = 'https://funbtc-2023.wooriball.com/funplay_info/funtogether_btcladder_play_2023.php'
      urlResult = 'https://www.wooriball.com/game_btcladder_date.php'
      origin = { w: 873, h: 710 }
      break
    case 'WRKENOPL1':
      url = 'https://funken-2023.wooriball.com/funplay_info/funtogether_knladder_play_2023.php'
      urlResult = 'https://www.wooriball.com/game_kenoladder_date.php'
      origin = { w: 873, h: 710 }
      break
  }

  const onClickResult = () => {
    window.open(urlResult, '_blank')
  }

  const sclX = (width || window.innerWidth) / origin.w
  const height = origin.h * sclX

  return (
    <Fragment>
      <ScrollableStyle style={{ height: `${height}px` }}>
        {mounted && (
          <iframe
            scrolling="no"
            src={url}
            className="game"
            style={{
              transform: `scale(${sclX})`,
              transformOrigin: '0 0',
              width: `${origin.w}px`,
              height: `${origin.h}px`,
            }}
          ></iframe>
        )}
      </ScrollableStyle>
      <Card css={SelectBox}>
        <Box className="card_header" sx={{ textAlign: 'end' }}>
          <BasicButton
            className="btn_sub"
            sx={{ display: 'inline-block', padding: '0.4rem 0.55rem', minWidth: 120 }}
            onClick={() => onClickResult()}
          >
            지난 회차 보기
          </BasicButton>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2, pb: 7 }}>
        <Box className="card_header">사다리 베팅</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {powerContents}
          </Grid>
        </Box>
      </Card>
    </Fragment>
  )
}

export default observer(MarketPanelPowerladder)
