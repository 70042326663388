import { PropTypes } from 'mobx-react'
import React, { Component, Fragment } from 'react'
import { IntlProvider } from 'react-intl'
import { PropertySignature } from 'typescript'

import language_en from '@locales/en_US.json'
import language_ko from '@locales/ko_KR.json'
import language_zh from '@locales/zh_CN.json'
import language_vi from '@locales/vi_VN.json'
import language_tl from '@locales/tl_PH.json'
import language_th from '@locales/th_TH.json'
import language_mn from '@locales/mn-MN.json'
import { DEFAULT_LOCALE, LOCALES_KEYS, getInitialLocale } from '~/locales/loader'

// app locale data
// const locales = {
//   en: language_en,
//   ko: language_ko,
// }

interface State {
  initDone: boolean
}

interface Props {
  children?: React.ReactNode
}

const locale = localStorage.getItem('locale') ?? DEFAULT_LOCALE
const messages = {
  'en-US': language_en,
  'ko-KR': language_ko,
  'zh-CN': language_zh,
  'vi-VN': language_vi,
  'tl-PH': language_tl,
  'th-TH': language_th,
  'mn-MN': language_mn,
}[locale]

class IntlWrapper extends React.Component<Props, State> {
  state = { initDone: false }

  componentDidMount() {
    let newLocale = getInitialLocale()

    if (newLocale) {
      console.log(`set locale ${newLocale}`)
      localStorage.setItem('locale', newLocale)
      window.location.reload()
    }
  }

  render() {
    const { children } = this.props
    return (
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    )
  }
}

export default IntlWrapper
