/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { Fragment, useState, useEffect } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import queryString from 'query-string'

import {
  Paper,
  Typography,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  IconButton,
  Collapse,
  Box,
  LinearProgress,
  Button,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { styled } from '@mui/material/styles'
import { ALL_GAME_INFO } from '@interface/config'

import { IPartnerBettingSummary, Schema$PartnerBettingSummaryItem } from '~/v2/protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import useRootStore from '@store/useRootStore'
import { useIntl } from 'react-intl'

const SINGLE_FOLD_GROUP = ['LCA', 'HCA', 'MSL', 'SL', 'REEL', 'BOARDGAME']
const SINGLE_FOLD_KIND = ['MIX']

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

type StatusType = 'group' | 'kind' | 'fold' | 'sum'

interface RowData {
  id: string
  trading_group: string
  trading_kind: string
  fold_type: string
  name: string
  betting_amount: number
  win_amount: number
  draw_amount: number
  betting_win_offset: number
  less_commission_amount: number
  less_losing_amount: number
  my_commision_amount: number
  my_losing_amount: number
  status: StatusType
  children: any[]
}

function EmptyRowDataItem(
  _trading_group: string,
  _trading_kind: string,
  _fold_type: string,
  _name: string,
  _status: StatusType,
): Schema$PartnerBettingSummaryItem {
  const data: Schema$PartnerBettingSummaryItem = {
    trading_group: _trading_group,
    trading_kind: _trading_kind,
    fold_type: _fold_type,
    name: _name,
    betting_amount: 0,
    win_amount: 0,
    draw_amount: 0,
    betting_win_offset: 0,
    less_commission_amount: 0,
    less_losing_amount: 0,
    my_commision_amount: 0,
    my_losing_amount: 0,
  }
  return data
}

function MakeRowData(
  src: Schema$PartnerBettingSummaryItem,
  status: StatusType,
  valueInit: boolean,
): RowData {
  const data: RowData = {
    id: src.trading_group,
    trading_group: src.trading_group,
    trading_kind: src.trading_kind,
    fold_type: src.fold_type,
    name: src.name,
    betting_amount: valueInit ? 0 : src.betting_amount,
    win_amount: valueInit ? 0 : src.win_amount,
    draw_amount: valueInit ? 0 : src.draw_amount,
    betting_win_offset: valueInit ? 0 : src.betting_win_offset,
    less_commission_amount: valueInit ? 0 : src.less_commission_amount,
    less_losing_amount: valueInit ? 0 : src.less_losing_amount,
    my_commision_amount: valueInit ? 0 : src.my_commision_amount,
    my_losing_amount: valueInit ? 0 : src.my_losing_amount,
    status: status,
    children: [],
  }
  return data
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center" sx={{ width: '100px' }}>
          <Typography sx={{ fontSize: '12px', color: 'white' }}>세부</Typography>
        </MyTableCell>
        <MyTableCell align="center" sx={{ width: '190px' }}>
          <Typography sx={{ fontSize: '12px', color: 'white' }}>게임</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>총베팅</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>총당첨</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>무승부</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅손익</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>하위 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>하위 루징</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>내 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>내 루징</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData }) {
  const { row } = props
  const [open, setOpen] = useState(false)

  const hasChildren = row.children.length > 0

  const isSum = row.status === 'sum'
  let name = row.name
  let rowStyle = {}
  if (isSum) {
    name = '합'
    rowStyle = { background: '#d8e4ff' }
  }

  let foldTypeName = ''
  let foldTypeColor = ''
  if (row.status === 'fold') {
    foldTypeName = row.fold_type === 'SINGLE' ? '단폴' : '다폴'
    foldTypeColor = row.fold_type === 'SINGLE' ? '#157347' : '#bb2d3b'
  }

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...rowStyle }}>
        {hasChildren ? (
          <MyTableCell sx={{ width: '100px' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </MyTableCell>
        ) : (
          <MyTableCell align="center" sx={{ width: '100px' }}>
            <Typography sx={{ fontSize: '12x', color: foldTypeColor }}>{foldTypeName}</Typography>
          </MyTableCell>
        )}
        <MyTableCell align="center" sx={{ width: '190px' }}>
          <Typography sx={{ fontSize: '12x' }}>{name}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.betting_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.win_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.draw_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.betting_win_offset)) }}
          >
            {numberWithCommas(Math.floor(row.betting_win_offset))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.less_commission_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.less_losing_amount)) }}
          >
            {numberWithCommas(Math.floor(row.less_losing_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.my_commision_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.my_losing_amount)) }}
          >
            {numberWithCommas(Math.floor(row.my_losing_amount))}
          </Typography>
        </MyTableCell>
      </TableRow>
      {hasChildren ? (
        <TableRow sx={{ background: '#a4a4a4' }}>
          <TableCell style={{ padding: 0 }} colSpan={16}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer component={Paper} sx={{ margin: '3px', width: 'calc(100% - 6px)' }}>
                <Table size="small" aria-label="collapsible table">
                  {Head()}
                  <TableBody>
                    {row.children.map(row => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </Fragment>
  )
}

interface Props {
  location: any
  default_begin: Date
  default_end: Date
}

function PartnerIntergratedBettingSummary({ location, default_begin, default_end }: Props) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { begin, end, ts } = query

  const [timestamp, setTimestamp] = useState('')
  const [loading, setLoading] = useState(false)
  const [track, setTrack] = useState<IPartnerBettingSummary.Schema | null>(null)
  const { globalStore } = useRootStore()

  const { option } = globalStore
  const { open_trading_groups, open_trading_kinds } = option || {}

  if (timestamp !== (ts as string)) {
    setTimestamp(ts as string)
  }

  const fetch = async (params: IPartnerBettingSummary.Params) => {
    setLoading(true)
    try {
      const ret = await apiPartner.getBettingSummary(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    let date_begin = begin ? (begin as string) : default_begin.toString()
    let date_end = end ? (end as string) : default_end.toString()
    const params: IPartnerBettingSummary.Params = {
      date_begin: date_begin,
      date_end: date_end,
      searchKey: '',
      searchValue: '',
      target_uuid: '',
    }

    fetch(params)
  }, [timestamp])

  const onClickCollapseAll = (collapse: boolean) => {
    //
  }

  const rows = []
  if (track && track.items && track.items.length > 0) {
    const groupByGroup = _.groupBy(track.items, 'trading_group')

    for (const el of ALL_GAME_INFO) {
      const open_group = _.find(open_trading_groups, o => {
        return o.trading_group === el.group
      })
      if (!open_group) {
        continue
      }

      if (el.group in groupByGroup) {
        const isSingFoldGroup = SINGLE_FOLD_GROUP.indexOf(el.group) >= 0
        let groupByKind = _.groupBy(groupByGroup[el.group], 'trading_kind')
        const group =
          _.find(groupByGroup[el.group], o => {
            return o.trading_kind === ''
          }) || EmptyRowDataItem(el.group, '', '', el.name, 'fold')

        const rowGroup = MakeRowData(group, 'group', false)
        let orderedKind: { kind: string; items: any }[] = []
        for (const [k, v] of Object.entries(groupByKind)) {
          if (k === '') {
            continue
          }
          orderedKind.push({
            kind: k,
            items: v,
          })
        }
        if (el.group === 'REEL') {
          orderedKind = _.orderBy(orderedKind, o => {
            const tmp = o.kind.split('STAR_SEASTORY')
            return tmp.length === 2 ? Number(tmp[1]) : 0
          })
        }
        for (const o of orderedKind) {
          const k = o.kind
          const v = o.items
          // const open_kind = _.find(open_trading_kinds, o => {
          //   return o.trading_kind === k
          // })
          // if (!open_kind) {
          //   continue
          // }

          const isSingFoldKind = SINGLE_FOLD_KIND.indexOf(k) >= 0

          if (isSingFoldGroup || isSingFoldKind) {
            for (const el of v) {
              const rowKind = MakeRowData(el, 'kind', false)
              rowGroup.children.push(rowKind)
            }
          } else {
            const [top] = v.length > 0 ? v : []
            const rowKind = MakeRowData(top, 'kind', true)
            rowGroup.children.push(rowKind)

            const fold_types = ['SINGLE', 'MULTI']
            for (const fold_type of fold_types) {
              const el2 =
                _.find(v, o => {
                  return o.fold_type === fold_type
                }) ||
                EmptyRowDataItem(top.trading_group, top.trading_kind, fold_type, top.name, 'fold')

              const row = MakeRowData(el2, 'fold', false)
              rowKind.children.push(row)

              rowKind.betting_amount += row.betting_amount
              rowKind.win_amount += row.win_amount
              rowKind.draw_amount += row.draw_amount
              rowKind.betting_win_offset += row.betting_win_offset
              rowKind.less_commission_amount += row.less_commission_amount
              rowKind.less_losing_amount += row.less_losing_amount
              rowKind.my_commision_amount += row.my_commision_amount
              rowKind.my_losing_amount += row.my_losing_amount
            }
          }
        }

        rows.push(rowGroup)
      }
    }

    if (track.sum) {
      rows.push({
        id: '',
        trading_group: '',
        trading_kind: '',
        name: '합',
        betting_amount: track.sum.betting_amount,
        win_amount: track.sum.win_amount,
        draw_amount: track.sum.draw_amount,
        betting_win_offset: track.sum.betting_win_offset,
        less_commission_amount: track.sum.less_commission_amount,
        less_losing_amount: track.sum.less_losing_amount,
        my_commision_amount: track.sum.my_commision_amount,
        my_losing_amount: track.sum.my_losing_amount,
        status: 'sum',
        children: [],
      })
    }
  }

  return (
    <>
      {/* <Button
        variant="contained"
        sx={{ minHeight: '24px', maxHeight: '24px', background: 'grey' }}
        onClick={() => onClickCollapseAll(true)}
      >
        모두접기
      </Button>
      <Button
        variant="contained"
        sx={{ minHeight: '24px', maxHeight: '24px', background: 'grey', marginLeft: '4px' }}
        onClick={() => onClickCollapseAll(false)}
      >
        모두펼치기
      </Button> */}
      <TableContainer component={Paper}>
        <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
        <Table size="small" aria-label="collapsible table">
          {Head()}
          <TableBody>
            {rows.map(row => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default observer(PartnerIntergratedBettingSummary)
