/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import * as _ from 'lodash'
import queryString from 'query-string'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import DesktopMyPage from './d'
import MobileMyPage from './m'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'

function MyPage({ location }) {
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { authStore, globalStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  if (!signedin && initialized) {
    history.push('/')
    globalStore.showModal('login', 0)
    return <></>
  }

  const query = queryString.parse(location.search)
  const { main, sub, type, kind } = query

  if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
    switch (main) {
      case 'point':
        userStore.setPositionPage('MYPAGE-POINT')
        break
      case 'comp':
        userStore.setPositionPage('MYPAGE-COMP')
        break
      case 'myinfo':
        userStore.setPositionPage('MYPAGE-ACCOUNT')
        break
      case 'qnaPersonal':
        userStore.setPositionPage('MYPAGE-QNA')
        break
    }
  }

  const childProps = {
    main: main as string,
    sub: (sub as string) || '',
    type: (type as string) || '',
    kind: (kind as string) || '',
    location,
  }

  let contents = null
  if (desktop) {
    contents = <DesktopMyPage {...childProps}></DesktopMyPage>
  } else {
    contents = <MobileMyPage {...childProps}></MobileMyPage>
  }

  return <>{contents}</>
}

export default observer(MyPage)
