/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Grid, Typography, Stack, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { useIntl } from 'react-intl'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
//import WhiteTheme from '@themes/white.theme'
import NumberFormat from 'react-number-format'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { IBankState, IMyBankInfo, Schema$BankState, Schema$MyBankInfo } from '@protocol/bank'
import apiUser from '@services/api/user'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'
import { invalidateNumber, numberWithCommas, popupCenter } from '@utils/utils'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton, ItemButton } from '@styles/button.style'
import { BANK_MENU } from '~/constants/attrs'
import { DepositCoinStyle } from './index.style'

const KOIN_JOIN_URL = process.env.REACT_APP_5KOIN_URL || ''

const RequestDeposit5Koin: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [bonusChecked, setBonusChecked] = useState(-1)
  const [confirmedPassword, setConfirmedPassword] = useState('') // set password
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const [bankInfo, setBankInfo] = useState<Schema$MyBankInfo | null>(null)
  const { globalStore } = useRootStore()
  const refBlockSubmitting = useRef<boolean>(false)

  let canDeposit = false
  let txtDepositInfo = null
  let canBonus = false
  let canModifyAccountHolder = true

  if (bankState) {
    canModifyAccountHolder = !bankState.transaction.is_readonly_depositor
    canDeposit = bankState.can_deposit
    txtDepositInfo = bankState.deposit_coin.deposit_text

    canBonus = bankState.is_bonus_active
    if (!canBonus && bonusChecked !== 0) {
      setBonusChecked(0)
    }
  }

  if (bankInfo) {
    if (!bankInfo.accountHolder) {
      canModifyAccountHolder = true
    }
  }

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  useEffect(() => {
    fetchBankState({})
  }, [confirmedPassword])

  const submitTransferPassword = async (values: any) => {
    const { transferPassword } = values
    try {
      const { password2 } = await apiUser.confirmTransferPassword({
        password2: transferPassword,
      })
      setConfirmedPassword(password2)
      if (KOIN_JOIN_URL) {
        const w = (window.screen.width * 80) / 100
        const h = (window.screen.height * 80) / 100
        popupCenter({ url: KOIN_JOIN_URL, title: '5KoinCharge', w, h })
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchBankInfo = async (params: IMyBankInfo.Params) => {
    try {
      const { info } = await apiUser.getBankInfo(params)
      const sliceLength = info.accountNumber.length / 4
      const hideStart = sliceLength
      const hideEnd = info.accountNumber.length - sliceLength - 1
      const newAccountNumber = []
      for (let i = 0; i < info.accountNumber.length; i++) {
        if (hideStart <= i && i <= hideEnd) {
          newAccountNumber.push('*')
        } else {
          newAccountNumber.push(info.accountNumber[i])
        }
      }
      info.accountNumber = newAccountNumber.join('')
      setBankInfo(info)
      formik.setFieldValue('name', info.accountHolder)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    fetchBankState({})
    fetchBankInfo({})
  }, [])

  const submit = async (values: any) => {
    if (refBlockSubmitting.current) {
      return
    }
    if (!canDeposit) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.cannot.request.recharge' }),
      })
      return
    }
    const { name, volume } = values
    if (!name) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.deposit-name-require' })}`,
      })
      return
    }

    let amount = invalidateNumber(volume)
    try {
      if (bonusChecked === -1) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.check.recharge_bonus' }),
        })
        return
      }
      await apiUser.deposit5Koin({
        name: name.trim(),
        volume: amount,
        getBonus: bonusChecked === 1,
      })
      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-deposit-done' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const handleChangeBonus = (v: number) => {
    setBonusChecked(v)
  }

  const formik = useFormik({
    initialValues: { name: '', volume: 0 },
    onSubmit: values => {
      submit(values)
    },
  })

  const min_amount = bankState ? bankState.transaction.min_deposit_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_deposit_unit : 1000
  const transaction_text = `※ 충전은 ${numberWithCommas(min_amount)}원 이상부터 ${numberWithCommas(
    min_unit,
  )}원 단위로 신청 가능`

  let contents = null
  if (confirmedPassword) {
    contents = (
      // <Grid container sx={{ py: 3, justifyContent: 'center' }}>
      //   <Grid item xs={7}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <FormStyle>
          <Grid container sx={{ mb: 2, alignItems: 'center' }}>
            <Grid item xs={3}>
              <label className="form_label">
                {intl.formatMessage({ id: 'component.bank.deposit-name' })}
              </label>
            </Grid>
            <Grid item xs={9}>
              <input
                readOnly={!canModifyAccountHolder}
                className="form_control"
                name="name"
                placeholder={intl.formatMessage({ id: 'component.bank.deposit-placeholder' })}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ fontSize: '1.3rem', textAlign: 'end' }}
              />
              {formik.touched.name && formik.errors.name && <div>{formik.errors.name}</div>}
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 2, alignItems: 'baseline' }}>
            <Grid item xs={3}>
              <label className="form_label required">
                {intl.formatMessage({ id: 'component.bank.deposit-volume' })}
              </label>
            </Grid>
            <Grid item xs={9}>
              <NumberFormat
                className="form_control"
                name="volume"
                placeholder={intl.formatMessage({ id: 'component.bank.volume-placeholder' })}
                value={formik.values.volume}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                thousandSeparator={true}
                style={{ fontSize: '1.3rem', textAlign: 'end' }}
              ></NumberFormat>
              <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '12px', color: 'red' }}>{transaction_text}</Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  '& > button': {
                    width: 'calc(25% - 4px)',
                  },
                }}
              >
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[0])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume0' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[1])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume1' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[2])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume2' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[3])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume3' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[4])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume4' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[5])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume5' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[6])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume6' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    formik.setFieldValue('volume', 0)
                  }}
                  className="clear"
                >
                  {intl.formatMessage({ id: 'component.bank.volume-clear' })}
                </ItemButton>
              </Box>
            </Grid>
          </Grid>

          <DepositCoinStyle>
            <NeatTextField
              multiline
              defaultValue={txtDepositInfo}
              InputProps={{ readOnly: true }}
            ></NeatTextField>
          </DepositCoinStyle>
        </FormStyle>

        {canBonus ? (
          <Grid container sx={{ mt: 2, alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bonusChecked === 1}
                      onChange={() => handleChangeBonus(1)}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                    />
                  }
                  sx={{ color: '#D1D9E3', justifyContent: 'center' }}
                  label="첫충(매충) 보너스 받기"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bonusChecked === 0}
                      onChange={() => handleChangeBonus(0)}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                    />
                  }
                  sx={{ color: '#D1D9E3', justifyContent: 'center' }}
                  label="첫충(매충) 보너스 받지 않기"
                />
              </FormGroup>
            </Grid>
          </Grid>
        ) : null}

        <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 4 }}>
          <BasicButton
            className="btn_main"
            type="submit"
            onClick={() => {
              refBlockSubmitting.current = false
              formik.setFieldValue('volume', formik.values.volume)
            }}
          >
            {intl.formatMessage({ id: 'component.bank.req-deposit' })}
          </BasicButton>
        </Stack>
      </form>
      //   </Grid>
      // </Grid>
    )
  } else {
    contents = (
      <Formik
        initialValues={{
          transferPassword: '',
        }}
        onSubmit={submitTransferPassword}
        validationSchema={Yup.object().shape({
          transferPassword: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setSubmitting,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormStyle>
              <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                <Grid item xs={3}>
                  <label className="form_label required">
                    {intl.formatMessage({ id: 'component.bank.trasnfer-password' })}
                  </label>
                </Grid>
                <Grid item xs={9}>
                  <input
                    name="transferPassword"
                    className="form_control"
                    type="password"
                    style={{ fontSize: '1.3rem' }}
                    value={values.transferPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              <DepositCoinStyle>
                <Box sx={{ color: '#FE3D3D' }}>
                  환전 비밀번호 확인 후 충전신청을 할 수 있습니다.
                </Box>
              </DepositCoinStyle>
            </FormStyle>

            <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 4 }}>
              <BasicButton className="btn_main" type="submit" disabled={isSubmitting}>
                {intl.formatMessage({ id: 'ok' })}
              </BasicButton>
            </Stack>
          </form>
        )}
      </Formik>
    )
  }

  return (
    <Grid container sx={{ py: 3, justifyContent: 'center' }}>
      <Grid item xs={7}>
        {contents}
      </Grid>
    </Grid>
  )
}

export default RequestDeposit5Koin
