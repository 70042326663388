import { OddsHeaderData, OddsPosition, OddsSize } from './types'

export default class OddsHeader extends Phaser.GameObjects.Container {
  box: Phaser.GameObjects.NineSlice = null

  title: Phaser.GameObjects.Text = null

  headerData: OddsHeaderData = null

  constructor(scene: Phaser.Scene, data: OddsHeaderData) {
    super(scene, data.position.x, data.position.y)

    this.headerData = data

    this.box = scene.add.nineslice(
      0,
      0,
      'ui',
      'black_24.png',
      24,
      24,
      12,
      12,
      12,
      12,
    )
    this.box.setAlpha(0.6)

    this.add(this.box)

    // sizing
    this.transformImmediate(data.size, data.position)

    // add text
    this.title = this.scene.add
      .text(0, 0, data.title, {
        fontSize: '13px',
        color: '#ffffff',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(this.title)
  }

  recover() {
    this.transform(this.headerData.size, this.headerData.position)
  }

  transform(
    size: { cx: number; cy: number },
    position: { x: number; y: number },
  ) {
    this.tweenTransform(size, position)
  }

  transformImmediate(
    size: { cx: number; cy: number },
    position: { x: number; y: number },
  ) {
    this.setPosition(position.x, position.y)
    this.setSize(size.cx, size.cy)

    // children
    this.box.setSize(size.cx, size.cy)
  }

  tweenTransform(
    size: { cx: number; cy: number },
    position: { x: number; y: number },
  ) {
    // children
    const duration = 250
    const width = size.cx
    const height = size.cy
    const x = position.x
    const y = position.y
    const ease = Phaser.Math.Easing.Quadratic.Out

    // tween container position
    this.scene.tweens.add({
      targets: this,
      x,
      y,
      duration,
      ease,
    })
    // tween container size
    this.scene.tweens.add({
      targets: this,
      width,
      height,
      duration,
      ease,
    })
    // tween image size
    this.scene.tweens.add({
      targets: this.box,
      width,
      height,
      duration,
      ease,
    })
  }

  tweenVisible(visible: boolean) {
    this.setVisible(visible)
  }
}
