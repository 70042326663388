/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Grid, Box, IconButton } from '@mui/material'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import apiPublic from '@services/api/public'
import { Schema$Captcha } from '@protocol/public'
import RefreshIcon from '@mui/icons-material/Refresh'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { BasicButton } from '@styles/button.style'

//image
import asset_channel from '~/assets/channel'

//css
import { LogoStyle } from './index.style'
import { FormStyle } from '@styles/modal.style'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const logoStyle = channelSymbol === 'texas' ? { width: '70%' } : { width: '60%' }

function Logo(msg: string) {
  return (
    <LogoStyle
      style={{
        display: 'flex',
        flexDirection: 'column',
        //height: '130px',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '40px',
      }}
    >
      {channelSymbol === 'epic' || channelSymbol === 'noah' || channelSymbol === 'tsunami' ? (
        <img src={asset_channel.common.main_logo_gif_m} alt="" style={logoStyle} />
      ) : (
        <img src={asset_channel.common.main_logo_m} alt="" style={logoStyle} />
      )}
      <p className="text" style={{ marginTop: '53px' }}>
        {msg}
      </p>
    </LogoStyle>
  )
}

const SigninComponent: React.FC = () => {
  const intl = useIntl()
  const { globalStore, authStore } = useRootStore()
  const [captcha, setCaptcha] = useState<Schema$Captcha | null>(null)

  const { initialized } = authStore
  const { option } = globalStore // watch media_mode to get event
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const fetchCaptcha = async () => {
    try {
      const { captcha: c } = await apiPublic.getCaptcha({ type: 'signin' })
      if (c) {
        setCaptcha(c)
      }
    } catch (err) { }
  }

  useEffect(() => {
    if (option && option.useLoginCaptcha) {
      fetchCaptcha()
    }
  }, [option])

  const onClickRefreshCaptcha = () => {
    fetchCaptcha()
  }

  const submit = async (values: any) => {
    const { username, password, captchaText } = values

    if (captcha) {
      if (!captchaText) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'input-captcha' }),
        })
        return
      }
    }

    let usernameVerified = username.trim()
    const succeed = await authStore.login(
      { username: usernameVerified, password, captchaText },
      intl,
    )
    if (!succeed) {
      fetchCaptcha()
    }
  }

  let bgSize = ''
  const GlobalStyle = () => css`
    body {
      //${isDesktop ? (bgSize = 'background-size: calc(100% - 17px) auto;') : ''}
    }
  `
  return (
    <>
      <Global styles={GlobalStyle} />
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={9} sx={{ pt: 4, pb: 1 }}>
          {Logo(intl.formatMessage({ id: 'available.after-login' }))}
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
              password: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <FormStyle>
                  <Grid container rowSpacing={1} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                      <input
                        className="form_control"
                        type="text"
                        name="username"
                        placeholder={intl.formatMessage({ id: 'username' })}
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <input
                        className="form_control"
                        type="password"
                        name="password"
                        placeholder={intl.formatMessage({ id: 'password' })}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                      />
                    </Grid>
                  </Grid>
                  {captcha ? (
                    <>
                      <Grid container sx={{ justifyContent: 'center', mt: 3 }}>
                        <Grid item xs={10} sx={{ justifyContent: 'center', background: 'white' }}>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <div
                              dangerouslySetInnerHTML={{ __html: captcha ? captcha.data : '' }}
                            ></div>
                          </Box>
                        </Grid>
                        <Grid item xs={2} sx={{ background: 'black' }}>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <IconButton
                              color="primary"
                              size="large"
                              onClick={onClickRefreshCaptcha}
                              aria-label="close"
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12}>
                          <input
                            id="captchaInput"
                            className="form_control"
                            type="text"
                            name="captchaText"
                            placeholder={intl.formatMessage({
                              id: captcha.isMath ? 'input-captcha-math' : 'input-captcha',
                            })}
                            value={values.captchaText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={16}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </FormStyle>
                <Box sx={{ display: 'flex', jsutifyContent: 'center', marginTop: 3 }}>
                  <BasicButton
                    type="submit"
                    className="btn_main"
                    disabled={isSubmitting}
                    sx={{ width: '100%' }}
                  >
                    {intl.formatMessage({ id: 'login' })}
                  </BasicButton>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(SigninComponent)
