import Phaser from 'phaser'

let instance: EventManager = null
export default class EventManager extends Phaser.Events.EventEmitter {
  // constructor() {
  //   super()
  // }

  static getInstance() {
    if (instance == null) {
      instance = new EventManager()
    }
    return instance
  }
}
