/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Grid, Divider, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import { QNA_STATE } from '@interface/config'
import { IBankTransferPointRecord } from '@protocol/bank'
import apiUser from '@services/api/user'

//css
import { BoardStyle, NoList } from '@styles/modal.style'
import { PaginationStyle } from '~/styles/base.style'
import { numberWithCommas } from '~/utils/utils'

type TrackData = IBankTransferPointRecord.Schema
const ItemPerPage = 10

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

function PointRecord({ location }) {
  const query = queryString.parse(location.search)
  const { page } = query
  const startPage = page ? Number(page) : 1

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)

  const intl = useIntl()
  const history = useHistory()
  const { globalStore, userStore } = useRootStore()
  const { userPoint } = userStore

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  const fetch = async (params: IBankTransferPointRecord.Params) => {
    try {
      const ret = await apiUser.transferPointRecord(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    const params: IBankTransferPointRecord.Params = {
      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetch(params)
  }, [tabPage, userPoint])

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/mypage/search?main=point&page=${value}`)
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const listItems = []
  if (track) {
    for (const el of track.items) {
      const dateName = moment(el.time).format('YYYY-MM-DD HH:mm:ss')
      listItems.push(
        <Grid container sx={{ py: 1 }} className="list_title">
          <Grid item xs={3}>
            {dateName}
          </Grid>
          <Grid item xs={3}>
            {intl.formatMessage({ id: 'component.point.transfer' })}
          </Grid>
          <Grid item xs={3}>
            {numberWithCommas(el.amount)}
          </Grid>
          <Grid item xs={3}>
            {numberWithCommas(Math.floor(el.own))}
          </Grid>
        </Grid>,
        <Divider sx={{ opacity: 0.2 }}></Divider>,
      )
    }

    pageData = getPageData(track)
  }

  let emptyContents = null
  if (listItems.length === 0) {
    emptyContents = (
      <NoList>
        <p>{intl.formatMessage({ id: 'msg.point-transfer.no-history' })}</p>
      </NoList>
    )
  }

  return (
    <Box sx={{ mb: 8 }}>
      <Typography
        variant="h5"
        component="h3"
        sx={{ mt: 3, py: 3, fontWeight: 'bold', color: '#D1D9E3' }}
      >
        {intl.formatMessage({ id: 'component.point.history' })}
      </Typography>
      <BoardStyle className="board_list">
        <Grid container className="list_head" sx={{ py: 1 }}>
          <Grid item xs={3}>
            {intl.formatMessage({ id: 'time' })}
          </Grid>
          <Grid item xs={3}>
            {intl.formatMessage({ id: 'reason' })}
          </Grid>
          <Grid item xs={3}>
            {intl.formatMessage({ id: 'component.withdrawal' })}
          </Grid>
          <Grid item xs={3}>
            {intl.formatMessage({ id: 'component.balance' })}
          </Grid>
        </Grid>
        {listItems}
      </BoardStyle>
      {emptyContents}

      <PaginationStyle
        count={pageData.totalPage}
        variant="outlined"
        page={pageData.currPage}
        onChange={handlePagingChange}
      />

      <Divider sx={{ mt: 3, mb: 11, opacity: 1, borderColor: '#1E2B3D' }} />
    </Box>
  )
}

export default PointRecord
