/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { observer } from 'mobx-react'

import PopupLogin from '@shared/PopupLogin'
import PopupMessage from './PopupMessage'
import PopupCoupon from './PopupCoupon'
import PopupBettingRecord from './PopupBettingRecord'
import PopupSL from './PopupSL'
import PopupManualCoin from './PopupManualCoin'
import PopupManualWonp from './PopupManualWonp'
import PopupManualOnepay from './PopupManualOnepay'
import PopupManualOnepayCoin from './PopupManualOnepayCoin'
import PopupWriteQnaPersonal from './PopupWriteQnaPersonal'
import PopupWriteQnaPartner from './PopupWriteQnaPartner'
import PopupBanner from './PopupBanner'
import useRootStore from '@store/useRootStore'

import { ModalStyle } from '@styles/modal.style'
import { ModalKeys } from '~/store/globalStore'
import PopupSportMarketDetail from './PopupSportMarketDetail'

function PopupLayout() {
  const { globalStore } = useRootStore()
  const { currentKey } = globalStore

  let contents = null
  switch (currentKey) {
    case ModalKeys.login:
      contents = <PopupLogin />
      break
    // case ModalKeys.sport_market:
    //   contents = <PopupSportMarketDetail />
    //   break
    case ModalKeys.message:
      contents = <PopupMessage />
      break
    case ModalKeys.coupon:
      contents = <PopupCoupon />
      break
    case ModalKeys.betting_record:
      contents = <PopupBettingRecord />
      break
    case ModalKeys.slot:
      contents = <PopupSL />
      break
    case ModalKeys.menual_coin:
      contents = <PopupManualCoin />
      break
    case ModalKeys.menual_wonp:
      contents = <PopupManualWonp />
      break
    case ModalKeys.menual_onepay:
      contents = <PopupManualOnepay />
      break
    case ModalKeys.menual_onepay_coin:
      contents = <PopupManualOnepayCoin />
      break
    case ModalKeys.write_qna_personal:
      contents = <PopupWriteQnaPersonal />
      break
    case ModalKeys.write_qna_partner:
      contents = <PopupWriteQnaPartner />
      break
  }

  return <Fragment>{contents}</Fragment>
}

export default observer(PopupLayout)
