/** @jsxImportSource @emotion/react */
import { jsx, ThemeProvider, Global } from '@emotion/react'
import { Theme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { useContext, useEffect, useState } from 'react'
import { useUpdateCheck } from 'react-update-notification'
// import Loadable from 'react-loadable'Route
import { HashRouter, Router, Route, Switch, useHistory } from 'react-router-dom'
import SendIcon from '@mui/icons-material/Send'

import PageLoading from '@components/PageLoading'
import Error from '@components/Error'
import DarkTheme from '@themes/dark.theme'
import TexasTheme from '@themes/texas.theme'
import MarimoTheme from '@themes/marimo.theme'
import CityTheme from '@themes/city.theme'
import GugeTheme from '@themes/guge.theme'
import KoiTheme from '@themes/koi.theme'
import SolaireTheme from '@themes/solaire.theme'
import MgmTheme from '@themes/mgm.theme'
import TsunamiTheme from '@themes/tsunami.theme'
import IphoneTheme from '@themes/iphone.theme'
import Sky2Theme from '@themes/sky2.theme'
import MarineTheme from '@themes/marine.theme'
import TigerTheme from '@themes/tiger.theme'
import HiltonTheme from '@themes/hilton.theme'
import SoftTheme from '@themes/soft.theme'
import GangnamTheme from '@themes/gangnam.theme'
import MilkyTheme from '@themes/milky.theme'
import HulkTheme from '@themes/hulk.theme'
import Marine2Theme from '@themes/marine2.theme'
import BandTheme from '@themes/band.theme'
import WaterTheme from '@themes/water.theme'
import EmartTheme from '@themes/emart.theme'
import CigarTheme from '@themes/cigar.theme'
import WorldTheme from '@themes/world.theme'
import AceTheme from '@themes/ace.theme'
import TodayTheme from '@themes/today.theme'
import HongyeomTheme from '@themes/hongyeom.theme'
import SsgTheme from '@themes/ssg.theme'
import PrahaTheme from '@themes/praha.theme'
import LoungeTheme from '~/themes/lounge.theme'
import RoundTheme from '~/themes/round.theme'
import TurtleTheme from '~/themes/turtle.theme'
import MegaTheme from '~/themes/mega.theme'
import TayoTheme from '~/themes/tayo.theme'
import KtxTheme from '~/themes/ktx.theme'
import PacificTheme from '~/themes/pacific.theme'
import CloverTheme from '~/themes/clover.theme'
import ChanelTheme from '~/themes/chanel.theme'
import JJTheme from '~/themes/jj.theme'
import BlackjackTheme from '~/themes/blackjack.theme'
import ParagonTheme from '~/themes/paragon.theme'
import BrotherTheme from '~/themes/brother.theme'
import FlyTheme from '~/themes/fly.theme'
import EpicTheme from '~/themes/epic.theme'
import LastTheme from '~/themes/last.theme'
import CoreTheme from '~/themes/core.theme'
import FourSeasonsTheme from '~/themes/fourseasons.theme'
import StarstarTheme from '~/themes/starstar.theme'
import StingrayTheme from '~/themes/stingray.theme'
import CoupangTheme from '~/themes/coupang.theme'
import LionTheme from '~/themes/lion.theme'
import CaptainTheme from '~/themes/captain.theme'
import GoldentulipTheme from '~/themes/goldentulip.theme'
import GentlemenTheme from '~/themes/gentlemen.theme'
import TmapTheme from '~/themes/tmap.theme'
import JabaTheme from '~/themes/jaba.theme'
import PicassoTheme from '~/themes/picasso.theme'
import PlayTheme from '~/themes/play.theme'
import WonderlandTheme from '~/themes/wonderland.theme'
import AGCTheme from '~/themes/agc.theme'
import SharkTheme from '~/themes/shark.theme'
import ElephantTheme from '~/themes/elephant.theme'
import FairTheme from '~/themes/fair.theme'
import MammothTheme from '~/themes/mammoth.theme'
import NinecasinoTheme from '~/themes/ninecasino.theme'
import Bolton2Theme from '~/themes/bolton2.theme'
import NewjeansTheme from '~/themes/newjeans.theme'
import CastleTheme from '~/themes/castle.theme'
import SevenTheme from '~/themes/seven.theme'
import SRTTheme from '~/themes/srt.theme'
import NoahTheme from '~/themes/noah.theme'
import Star2Theme from '~/themes/star2.theme'
import HunterbetTheme from '~/themes/hunterbet.theme'
import MarlboroTheme from '~/themes/marlboro.theme'
import QualityTheme from '~/themes/quality.theme'
import PotatoTheme from '~/themes/potato.theme'
import RoyalTheme from '~/themes/royal.theme'
import BillionaireTheme from '~/themes/billionaire.theme'
import BananaTheme from '~/themes/banana.theme'
import AuroraTheme from '~/themes/aurora.theme'
import MountainTheme from '~/themes/mountain.theme'
import BlueTheme from '~/themes/blue.theme'
import RokmcTheme from '~/themes/rokmc.theme'
import StarTheme from '@themes/star.theme'
import TaepyeongyangTheme from '@themes/taepyeongyang.theme'
import HeraTheme from '@themes/hera.theme'
// import WhiteTheme from '@themes/white.theme'
import * as store from '@store/index'
import Minigame from '~/containers/views/Minigame'

import Provider, { RootContext } from '@shared/Context/Provider'
import IntlWrapper from '@shared/Context/IntlWrapper'
import { createBrowserHistory } from 'history'
import { syncHistoryWithStore } from 'mobx-react-router'

// css
import { GlobalStyle } from './index.style'

import PopupLayout from '@views/Popup'
import PartnerCreateMember from '@views/Partner/CreateMember'
import Fallback from '@views/Fallback'
import ProxyCasino from '~/containers/views/ProxyCasino'
import ProxySL from '@views/ProxySL'
import PartnerMoneyTransfer from '@views/Partner/MoneyTransfer'
import MoneyTransferRecord from '@views/Partner/MoneyTransferRecord'
import PartnerIntergratedMember from '@views/Partner/PartnerIntergratedMember'

import PrimaryLayout from '@views/PrimaryLayout'
import Layout2 from '@views/SecondaryLayout'
import Layout3 from '@views/TertiaryLayout'
import Layout4 from '@views/QuaternaryLayout'
import Layout5 from '@views/QuinaryLayout'
import Landing from '~/containers/app/landing'
import LandingR from '~/containers/app/landingR'
import Login from '@views/Login'
import Maintenance from './maintenance'
import PartnerCouponRecord from '../views/Partner/PartnerIntergratedCouponRecord'
import ProxyMSL from '../views/ProxyMSL'
import CoinwikiDone from './coinwiki-callback'
import useRootStore from '~/store/useRootStore'
import proxyLoading from './proxy-loading'
import ProxySignup from './proxy-signup'
import ProxySignIn from './proxy-signin'
import DialogLocaleReceiver from './dialog-locale-receiver'

let DefaultTheme: Theme = null
let Layout = PrimaryLayout
switch (process.env.REACT_APP_CHANNEL) {
  case 'texas':
    DefaultTheme = TexasTheme
    break
  case 'marimo':
    DefaultTheme = MarimoTheme
    break
  case 'city':
    DefaultTheme = CityTheme
    break
  case 'guge':
    DefaultTheme = GugeTheme
    break
  case 'koi':
    DefaultTheme = KoiTheme
    break
  case 'solaire':
    DefaultTheme = SolaireTheme
    break
  case 'mgm':
    DefaultTheme = MgmTheme
    break
  case 'tsunami':
    DefaultTheme = TsunamiTheme
    break
  case 'iphone':
    DefaultTheme = IphoneTheme
    break
  case 'sky2':
    DefaultTheme = Sky2Theme
    break
  case 'marine':
    DefaultTheme = MarineTheme
    Layout = Layout2
    break
  case 'tiger':
    DefaultTheme = TigerTheme
    Layout = Layout2
    break
  case 'hilton':
    DefaultTheme = HiltonTheme
    Layout = Layout2
    break
  case 'soft':
    DefaultTheme = SoftTheme
    Layout = Layout2
    break
  case 'gangnam':
    DefaultTheme = GangnamTheme
    Layout = Layout2
    break
  case 'milky':
    DefaultTheme = MilkyTheme
    Layout = Layout2
    break
  case 'hulk':
    DefaultTheme = HulkTheme
    Layout = Layout2
    break
  case 'marine2':
    DefaultTheme = Marine2Theme
    Layout = Layout3
    break
  case 'band':
    DefaultTheme = BandTheme
    Layout = Layout4
    break
  case 'water':
    DefaultTheme = WaterTheme
    Layout = Layout4
    break
  case 'emart':
    DefaultTheme = EmartTheme
    Layout = Layout4
    break
  case 'cigar':
    DefaultTheme = CigarTheme
    Layout = Layout4
    break
  case 'world':
    DefaultTheme = WorldTheme
    Layout = Layout4
    break
  case 'ace':
    DefaultTheme = AceTheme
    Layout = Layout5
    break
  case 'today':
    DefaultTheme = TodayTheme
    Layout = Layout5
    break
  case 'hongyeom':
    DefaultTheme = HongyeomTheme
    Layout = Layout5
    break
  case 'ssg':
    DefaultTheme = SsgTheme
    Layout = Layout5
    break
  case 'praha':
    DefaultTheme = PrahaTheme
    Layout = Layout5
    break
  case 'lounge':
    DefaultTheme = LoungeTheme
    break
  case 'round':
    DefaultTheme = RoundTheme
    break
  case 'turtle':
    DefaultTheme = TurtleTheme
    break
  case 'mega':
    DefaultTheme = MegaTheme
    break
  case 'tayo':
    DefaultTheme = TayoTheme
    break
  case 'ktx':
    DefaultTheme = KtxTheme
    break
  case 'pacific':
    DefaultTheme = PacificTheme
    break
  case 'clover':
    DefaultTheme = CloverTheme
    break
  case 'chanel':
    DefaultTheme = ChanelTheme
    break
  case 'jj':
    DefaultTheme = JJTheme
    break
  case 'blackjack':
    DefaultTheme = BlackjackTheme
    break
  case 'paragon':
    DefaultTheme = ParagonTheme
    break
  case 'brother':
    DefaultTheme = BrotherTheme
    break
  case 'fly':
    DefaultTheme = FlyTheme
    break
  case 'epic':
    DefaultTheme = EpicTheme
    break
  case 'last':
    DefaultTheme = LastTheme
    break
  case 'core':
    DefaultTheme = CoreTheme
    break
  case 'fourseasons':
    DefaultTheme = FourSeasonsTheme
    break
  case 'starstar':
    DefaultTheme = StarstarTheme
    break
  case 'stingray':
    DefaultTheme = StingrayTheme
    break
  case 'coupang':
    DefaultTheme = CoupangTheme
    break
  case 'lion':
    DefaultTheme = LionTheme
    break
  case 'captain':
    DefaultTheme = CaptainTheme
    break
  case 'goldentulip':
    DefaultTheme = GoldentulipTheme
    break
  case 'gentlemen':
    DefaultTheme = GentlemenTheme
    break
  case 'tmap':
    DefaultTheme = TmapTheme
    break
  case 'jaba':
    DefaultTheme = JabaTheme
    break
  case 'picasso':
    DefaultTheme = PicassoTheme
    break
  case 'play':
    DefaultTheme = PlayTheme
    break
  case 'shark':
    DefaultTheme = SharkTheme
    break
  case 'elephant':
    DefaultTheme = ElephantTheme
    break
  case 'wonderland':
    DefaultTheme = WonderlandTheme
    break
  case 'agc':
    DefaultTheme = AGCTheme
    break
  case 'fair':
    DefaultTheme = FairTheme
    break
  case 'mammoth':
    DefaultTheme = MammothTheme
    break
  case 'ninecasino':
    DefaultTheme = NinecasinoTheme
    break
  case 'bolton2':
    DefaultTheme = Bolton2Theme
    break
  case 'newjeans':
    DefaultTheme = NewjeansTheme
    break
  case 'castle':
    DefaultTheme = CastleTheme
    break
  case 'srt':
    DefaultTheme = SRTTheme
    break
  case 'seven':
    DefaultTheme = SevenTheme
    break
  case 'noah':
    DefaultTheme = NoahTheme
    break
  case 'star2':
    DefaultTheme = Star2Theme
    break
  case 'hunterbet':
    DefaultTheme = HunterbetTheme
    break
  case 'marlboro':
    DefaultTheme = MarlboroTheme
    break
  case 'quality':
    DefaultTheme = QualityTheme
    break
  case 'potato':
    DefaultTheme = PotatoTheme
    break
  case 'royal':
    DefaultTheme = RoyalTheme
    break
  case 'billionaire':
    DefaultTheme = BillionaireTheme
    break
  case 'banana':
    DefaultTheme = BananaTheme
    break
  case 'aurora':
    DefaultTheme = AuroraTheme
    break
  case 'mountain':
    DefaultTheme = MountainTheme
    break
  case 'blue':
    DefaultTheme = BlueTheme
    break
  case 'rokmc':
    DefaultTheme = RokmcTheme
    break
  case 'star':
    DefaultTheme = StarTheme
    break
  case 'taepyeongyang':
    DefaultTheme = TaepyeongyangTheme
    break
  case 'hera':
    DefaultTheme = HeraTheme
    break
  default:
    DefaultTheme = DarkTheme
    break
}

const browserHistory = createBrowserHistory()
// const history = syncHistoryWithStore(hashHistory, store.routerStore)

// const PrimaryLayout = Loadable({
//   loader: () => import(/* webpackChunkName: "home" */ 'containers/views/PrimaryLayout'),
//   loading: PageLoading,
// })

function App(props) {
  const { globalStore } = useRootStore()
  const { status } = useUpdateCheck({
    type: 'interval',
    interval: 10000,
  })
  const [needUpdate, setNeedUpdate] = useState<boolean>(false)

  // console.log(`update status: ${status}`)

  useEffect(() => {
    if (needUpdate) {
      window.location.reload()
    }
  }, [globalStore, needUpdate])

  const updateAvailable = status === 'available'
  if (updateAvailable !== needUpdate) {
    setNeedUpdate(updateAvailable)
  }

  return (
    <MuiThemeProvider theme={DefaultTheme}>
      <ThemeProvider theme={DefaultTheme}>
        <Global styles={GlobalStyle} />
        <IntlWrapper>
          <Router history={browserHistory}>
            <Switch>
              <Route exact path="/fallback" component={Fallback}></Route>
              <Route exact path="/proxy/casino" component={ProxyCasino}></Route>
              <Route exact path="/proxy/sl" component={ProxySL}></Route>
              <Route exact path="/proxy/msl" component={ProxyMSL}></Route>
              <Route exact path="/proxy/loading" component={proxyLoading} />
              <Route exact path="/partner/member/:search" component={PartnerIntergratedMember}></Route>
              <Route exact path="/partner/create-member" component={PartnerCreateMember}></Route>
              <Route exact path="/partner/coupon-record" component={PartnerCouponRecord}></Route>
              <Route exact path="/partner/coupon-record/:search" component={PartnerCouponRecord}></Route>
              <Route exact path="/partner/money-transfer" component={PartnerMoneyTransfer}></Route>
              <Route exact path="/partner/money-transfer/:search" component={PartnerMoneyTransfer}></Route>
              <Route exact path="/partner/money-transfer-record" component={MoneyTransferRecord}></Route>
              <Route exact path="/partner/money-transfer-record/:search" component={MoneyTransferRecord}></Route>
              <Route exact path="/landing/:search" component={Landing} />
              <Route exact path="/landing" component={Landing} />
              <Route exact path="/r/:rcode" component={LandingR} />
              <Route exact path="/v5/:signup" component={ProxySignup}></Route>
              <Route exact path="/v6/signin" component={ProxySignIn}></Route>
              <Route exact path="/login" component={Login} />
              <Route exact path="/maintenance" component={Maintenance} />
              <Route exact path="/coinwiki/callback" component={CoinwikiDone} />
              <Route path="/" component={Layout} />
              <Route component={Error} />
            </Switch>
          </Router>
          <PopupLayout />
          <DialogLocaleReceiver></DialogLocaleReceiver>
        </IntlWrapper>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default App
