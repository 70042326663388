import * as _ from 'lodash'
import { rateFixed, rateFixed1000, rateMultiplied, rateMultiplied1000 } from '~/game/rate-util'
import { Schema$SportsOptions } from '~/v2/interface/common'
import {
  Schema$Market,
  Schema$MarketBet,
  Schema$Prematch,
  Schema$PrematchCustomData,
  Schema$PrematchMarket,
} from '~/v2/interface/st-schema'
import { FIXTURE_STATUS } from '~/v2/interface/st-types'

export interface PrematchData {
  prematch: Schema$Prematch
  markets: Schema$Market[]
}

export interface PrematchLeagueGroup {
  leagueId: number
  topDate: Date
  prematches: PrematchData[]
}

export const FETCH_STATE = {
  NONE: 0,
  FETCHING: 1,
}

export interface CartItem {
  cartId: string
  sportId: number
  leagueId: number
  fixtureId: number
  marketId: number
  marketName: { en?: string; ko?: string }
  marketBetName: string

  match: Schema$Prematch
  bet: Schema$MarketBet
}

export interface DataQueue {
  statePrematches: string // none, pending, done
  stateMarkets: string

  initialPrematches: Schema$Prematch[]
  initialMarkets: Schema$PrematchMarket[]

  behindMarkets: Schema$PrematchMarket[]
  behindState: string // none, pending, done

  updatePrematches: Schema$Prematch[]
  updateMarkets: Schema$PrematchMarket[]
}

export function makeBetStateColor(
  betState: string,
  lang: string,
): { state: string; color: string } {
  const data = {
    state: '',
    color: '',
  }
  switch (betState) {
    case 'BET':
      data.state = lang === 'ko' ? '대기' : 'Waiting'
      data.color = '#FBC342'
      break
    case 'WIN':
      data.state = lang === 'ko' ? '당첨' : 'Win'
      data.color = '#3498DB'
      break
    case 'LOSE':
      data.state = lang === 'ko' ? '낙첨' : 'Lose'
      data.color = '#E74C3C'
      break
    case 'CANCEL':
      data.state = lang === 'ko' ? '취소' : 'Canceled'
      data.color = '#27AE60'
      break
  }
  return data
}

export function makeSportsBetStateColor(
  betState: string,
  status: number | null,
  isFold: boolean,
  lang: string,
): { name: string; background: string; foreground: string } {
  const data = {
    name: '',
    background: '',
    foreground: 'white',
  }
  switch (betState) {
    case 'BET':
      const localeBet = { en: 'Waiting', ko: '대기' }
      data.name = localeBet[lang]
      data.background = '#494c4e'
      break
    case 'WIN':
      const localeWin = { en: 'Win', ko: '당첨' }
      const localeWinFold = { en: 'Hit', ko: '적중' }
      data.name = isFold ? localeWinFold[lang] : localeWin[lang]
      data.background = '#3498DB'
      break
    case 'LOSE':
      const localeLose = { en: 'Lose', ko: '낙첨' }
      const localeLoseFold = { en: 'Miss', ko: '미적중' }
      data.name = isFold ? localeLoseFold[lang] : localeLose[lang]
      data.background = '#E74C3C'
      break
    case 'CANCEL':
      const localeCancel = { en: 'Canceled', ko: '취소' }
      const localeFoldCancel = { en: 'Excepted', ko: '적특' }
      data.name = isFold ? localeFoldCancel[lang] : localeCancel[lang]
      data.background = isFold ? '#2a415e' : '#27AE60'
      break
  }
  if (status === FIXTURE_STATUS.InProgress) {
    data.name = '게임중'
    data.background = '#ed6c02'
  }

  return data
}

export function makeMarketControllerName(fixtureId: number, marketId: number | string): string {
  return `${fixtureId}:${marketId}`
}

export function makeOddsControllerName(
  fixtureId: number,
  marketId: number,
  marketBetName: string,
  baseLine: string,
): string {
  return `${fixtureId}:${marketId}:${marketBetName}:${baseLine || null}`
}

export function getExchangeRate(
  options: Schema$SportsOptions,
  sportId: number,
  leagueId: number,
  CustomData: Schema$PrematchCustomData,
): { exchange_rate: number; correction_value: number } {
  let exchange_rate = '100'
  let correction_value = '0'

  if (options) {
    const league = _.find(options.exchange_rate_leagues, o => {
      return o.id === leagueId
    })
    const sport = _.find(options.exchange_rate_sports, o => {
      return o.id === sportId
    })
    exchange_rate =
      CustomData?.ExchangeRate ||
      league?.exchange_rate ||
      sport?.exchange_rate ||
      options.exchange_rate_default.exchange_rate ||
      '100'
    correction_value =
      CustomData?.CorrectionValue ||
      league?.correction_value ||
      sport?.correction_value ||
      options.exchange_rate_default.correction_value ||
      '0'
  }

  return {
    exchange_rate: Number(exchange_rate),
    correction_value: Number(correction_value),
  }
}

function testExchangeRateMarkets(
  markets: Schema$Market[],
  odds_decimal_point: number,
  exchange_rate: number,
  correction_value: number,
) {
  if (!markets) {
    return
  }

  for (const el of markets) {
    for (const bet of el.Bets) {
      if (odds_decimal_point === 2) {
        bet.ClientPrice = rateFixed(bet.Price)

        if (exchange_rate !== 100) {
          bet.ClientPrice = rateMultiplied([bet.Price, (exchange_rate * 0.01).toFixed(2)])
        }
        if (correction_value !== 0) {
          bet.ClientPrice = (Number(bet.ClientPrice) + correction_value).toFixed(2)
        }
      } else {
        bet.ClientPrice = rateFixed1000(bet.Price)

        if (exchange_rate !== 100) {
          bet.ClientPrice = rateMultiplied1000([bet.Price, (exchange_rate * 0.01).toFixed(3)])
        }
        if (correction_value !== 0) {
          bet.ClientPrice = (Number(bet.ClientPrice) + correction_value).toFixed(3)
        }
      }
    }
  }
}

export function testExchangeRate(
  options: Schema$SportsOptions,
  sportId: number,
  leagueId: number,
  market: Schema$PrematchMarket,
): number {
  const { odds_decimal_point } = options.sports_option
  const { exchange_rate, correction_value } = getExchangeRate(
    options,
    sportId,
    leagueId,
    market.CustomData,
  )

  testExchangeRateMarkets(market.Markets, odds_decimal_point, exchange_rate, correction_value)

  return 0
}

export interface CrossUnit {
  sport_id: number
  way3_way2: { way3: 0; way2: 0 } // 무조건 금지
  way_hdp: { way: 0; hdp: 0 } // 무조건 금지
  way_uo: { way: number; uo: number } // 설정에 따라 허용
  hdp_uo: { hdp: number; uo: number } // 설정에 따라 허용
}

export type UPDATE_FIELD_TYPE = 'update' | 'silent' | 'test'

export type FuncUpdateFixtureField = (m: Schema$Prematch, type: UPDATE_FIELD_TYPE) => void

export type FuncUpdateMarketField = (m: Schema$Market, type: UPDATE_FIELD_TYPE) => void

export type FuncUpdateOddsField = (m: Schema$MarketBet, type: UPDATE_FIELD_TYPE) => void

export interface FixtureController {
  updateField: FuncUpdateFixtureField
}

export interface MarketController {
  updateField: FuncUpdateMarketField
}

export interface OddsController {
  updateField: FuncUpdateOddsField
}

export interface TopLeagueItem {
  sportId: number
  leagueId: number
  sportName: { [lang: string]: string }
  locationName: { [lang: string]: string }
  leagueName: { [lang: string]: string }
  locationEmblem: string
  leagueEmblem: string
}

export interface TopLocationItem {
  locationId: number
  name: { [lang: string]: string }
  emblem: string
}

export type SPORT_FLIGHT = 'domestic' | 'europe' | 'prematch' | 'inplay'
