import React from 'react'
import * as _ from 'lodash'
import {
  Schema$FilteredMarket,
  Schema$Market,
  Schema$MarketBet,
  Schema$Prematch,
} from '~/v2/interface/st-schema'
import { BET_STATUS, FIXTURE_STATUS, INTERESTING_SPORT_ID, SPORT_ID } from '~/v2/interface/st-types'
import { SPORT_MARKET_FILTER } from '~/components/PrematchDetailOdds/detail_odds_factory'
import { toJS } from 'mobx'

export const FLIGHT_FILTER_ITEMS = [
  { name: { ko: '라이브', en: 'Live' }, value: 'inplay', color: '#30bed5' },
  { name: { ko: '국내형', en: 'Korean' }, value: 'domestic', color: '#ff8502' },
  { name: { ko: '유럽형', en: 'European' }, value: 'europe', color: '#30bed5' },
]

export const SPORTS_SORT_ITEMS = [
  { name: { ko: '리그순', en: 'League' }, value: 'league' },
  { name: { ko: '날짜순', en: 'Date' }, value: 'date' },
]

// Emoji
export const SPORT_FILTER_ITEMS = [
  {
    value: 'all',
    name: { ko: '🏆전체', en: '🏆All' },
    symbol: '🏆',
  },
  {
    value: SPORT_ID.Football.toString(),
    name: { ko: '⚽축구', en: '⚽Football' },
    symbol: '⚽',
  },
  {
    value: SPORT_ID.Basketball.toString(),
    name: { ko: '🏀농구', en: '🏀Basketball' },
    symbol: '🏀',
  },
  {
    value: SPORT_ID.Baseball.toString(),
    name: { ko: '⚾야구', en: '⚾Baseball' },
    symbol: '⚾',
  },
  {
    value: SPORT_ID.Volleyball.toString(),
    name: { ko: '🏐배구', en: '🏐Volleyball' },
    symbol: '🏐',
  },
  {
    value: SPORT_ID.IceHockey.toString(),
    name: { ko: '🏒아이스하키', en: '🏒IceHockey' },
    symbol: '🏒',
  },
  {
    value: 'etc',
    name: { ko: '🏆기타', en: '🏆Etc' },
    symbol: '🏆',
  },
]

export const FIXTURE_STATUS_ITEMS = [
  {
    value: FIXTURE_STATUS.NotStartedYet.toString(),
    name: { en: 'NotStartedYet', ko: '대기' },
    color: '#fbc342',
  },
  {
    value: FIXTURE_STATUS.InProgress.toString(),
    name: { en: 'InProgress', ko: '게임중' },
    color: '#3498db',
  },
  {
    value: FIXTURE_STATUS.Finished.toString(),
    name: { en: 'Finished', ko: '종료' },
    color: '#999999',
  },
  {
    value: FIXTURE_STATUS.Canceled.toString(),
    name: { en: 'Canceled', ko: '취소' },
    color: '#27ae60',
  },
  {
    value: FIXTURE_STATUS.Postponed.toString(),
    name: { en: 'Postponed', ko: '연기' },
    color: '#e74c3c',
  },
  {
    value: FIXTURE_STATUS.Interrupted.toString(),
    name: { en: 'Interrupted', ko: '중단' },
    color: '#e74c3c',
  },
  {
    value: FIXTURE_STATUS.Abandoned.toString(),
    name: { en: 'Abandoned', ko: '폐기' },
    color: '#e74c3c',
  },
  {
    value: FIXTURE_STATUS.CoverageLost.toString(),
    name: { en: 'CoverageLost', ko: '상실' },
    color: '#e74c3c',
  },
  {
    value: FIXTURE_STATUS.AboutToStart.toString(),
    name: { en: 'AboutToStart', ko: '곧시작' },
    color: '#fbc342',
  },
]

export const SPORTS_LOCALE = {
  odds_more: { name: { ko: '배당 이상', en: '➚' } },
  fold_bonus: { name: { ko: '폴드 보너스 배당', en: 'fold bonus' } },
  betting_records: { name: { ko: '베팅내역', en: 'Betting Records' } },
  sports_betting_records: { name: { ko: '스포츠 베팅내역', en: 'Sports Betting Records' } },
  highlights: { name: { ko: '하이라이트', en: 'Highlights' } },
  league: { name: { ko: '리그', en: 'League' } },
  back: { name: { ko: '돌아가기', en: 'Back' } },
  live_betting: { name: { ko: '라이브 베팅', en: 'Live Betting' } },
  more_betting: { name: { ko: '상세 베팅', en: 'More Betting' } },
}

export function GetInterestingSportFilterItems() {
  const ssItems = INTERESTING_SPORT_ID.map(o => o.toString())
  const items = _.filter(SPORT_FILTER_ITEMS, o => {
    if (o.value === 'all') {
      return true
    }
    if (o.value === 'etc') {
      return false
    }
    const idx = _.findIndex(ssItems, e => {
      return e === o.value
    })
    return idx >= 0
  })
  return items
}

export interface MarketArranged {
  name: string
  top: Schema$FilteredMarket
  addon: Schema$FilteredMarket[]
  all: Schema$FilteredMarket[]
}

export const UNIFIED_MARKET_NAME = {
  '12': ['12', '12 Including Overtime'],
  '1X2': ['1X2', '1X2 Including Overtime'],
  HDP: [
    'Asian Handicap',
    'Asian Handicap Including Overtime',
    'Asian Handicap Sets', // volleyball
  ],
  UO: [
    'Asian Under/Over',
    'Asian Under/Over Including Overtime',
    'Under/Over Sets', // volleyball
    'Under/Over', // basketball, baseball, icehockey
    'Under/Over Including Overtime', // basketball, baseball, icehockey
  ],
  // UO: ['Asian Under/Over', 'Asian Under/Over Including Overtime'],
}

export function withoutTopMarkets(markets: Schema$Market[]): Schema$Market[] {
  let filtered: Schema$Market[] = [...markets]
  for (const el of Object.values(UNIFIED_MARKET_NAME)) {
    filtered = _.filter(filtered, o => {
      return el.indexOf(o.Name) < 0
    })
  }
  return filtered
}

function getMidPoint(markets: Schema$FilteredMarket[]): { offsetList: number[]; mid: number } {
  const offsetList = _.fill(Array(markets.length), null)
  let mid = -1
  for (let i = 0; i < markets.length; i += 1) {
    const [a, b] = markets[i].Bets
    if (a && b) {
      offsetList[i] = Math.abs(Number(a.ClientPrice) - Number(b.ClientPrice))
      if (mid === -1) {
        mid = i
      } else {
        if (offsetList[i] < offsetList[mid]) {
          mid = i
        }
      }
    }
  }

  return { offsetList, mid }
}

export function getBaselineArranged(
  markets: Schema$FilteredMarket[],
  baseline_size: number,
): { top: Schema$FilteredMarket; addon: Schema$FilteredMarket[]; all: Schema$FilteredMarket[] } {
  const { offsetList, mid } = getMidPoint(markets)
  const baseline_limit = baseline_size ? baseline_size : markets.length
  let top = null
  const addon = []
  if (mid >= 0) {
    top = markets[mid]

    const size = markets.length
    const loffset = mid
    const roffset = size - mid - 1
    const radius = Math.max(loffset, roffset)

    for (let i = 1; i <= radius; i += 1) {
      const li = mid - i
      const ri = mid + i

      let ordered = []
      if (li >= 0 && ri < size) {
        const l = offsetList[li]
        const r = offsetList[ri]
        ordered = l < r ? [li, ri] : [ri, li]
      } else {
        if (li >= 0) {
          ordered = [li]
        }
        if (ri < size) {
          ordered = [ri]
        }
      }

      const countLimit = baseline_limit - 1 - addon.length
      if (countLimit > 0) {
        const limited = ordered.slice(0, countLimit)
        for (const el of limited) {
          addon.push(markets[el])
        }
        if (addon.length >= baseline_limit - 1) {
          break
        }
      }
    }
  }

  addon.sort((a: Schema$FilteredMarket, b: Schema$FilteredMarket): number => {
    if (a.ClientBaseLine && b.ClientBaseLine) {
      return Number(a.ClientBaseLine) - Number(b.ClientBaseLine)
    }
    return 0
  })

  const all = []
  if (top) {
    all.push(top)
  }
  all.push(...addon)
  all.sort((a: Schema$FilteredMarket, b: Schema$FilteredMarket): number => {
    if (a.ClientBaseLine && b.ClientBaseLine) {
      return Number(a.ClientBaseLine) - Number(b.ClientBaseLine)
    }
    return 0
  })

  return { top, addon, all }
}

function getBaselineArrangedOld(
  markets: Schema$FilteredMarket[],
  baseline_size: number,
): { top: Schema$FilteredMarket; addon: Schema$FilteredMarket[]; all: Schema$FilteredMarket[] } {
  let top = null
  const addon = []
  const all = []
  const { mid } = getMidPoint(markets)
  if (mid >= 0) {
    const baseline_offset = baseline_size && _.isNumber(baseline_size) ? baseline_size / 2 : mid
    for (let i = 0; i < markets.length; i += 1) {
      const market = markets[i]
      all.push(market)
      if (i === mid) {
        top = market
      } else if (i >= mid - baseline_offset && i <= mid + baseline_offset) {
        addon.push(market)
      }
    }
  }
  return { top, addon, all }
}

export function getMarketArranged(
  market: Schema$Market,
  name: string,
  baseline_size: number,
  enabled_baseline_zero: boolean,
): MarketArranged {
  const arranged: MarketArranged = {
    name: name,
    top: null,
    addon: [],
    all: [],
  }
  if (market) {
    switch (name) {
      case '12':
        if (market.Bets && market.Bets.length > 0) {
          arranged.top = {
            Id: market.Id,
            Name: market.Name,
            BaseLine: null,
            ClientBaseLine: null,
            Bets: market.Bets,
          }
        }
        break
      case '1X2':
        if (market.Bets && market.Bets.length > 0) {
          arranged.top = {
            Id: market.Id,
            Name: market.Name,
            BaseLine: null,
            ClientBaseLine: null,
            Bets: market.Bets,
          }
        }
        break
      case 'HDP':
      case 'UO':
        if (market.Bets && market.Bets.length > 0) {
          const validMarkets = makeFilteredMarkets(market, enabled_baseline_zero)
          const { all, top, addon } = getBaselineArranged(validMarkets, baseline_size)
          arranged.top = top
          arranged.addon = addon
          arranged.all = all
        }
        break
    }
  }

  return arranged
}

export function checkUnifiedMarket(marketName: string): string {
  for (const [k, v] of Object.entries(UNIFIED_MARKET_NAME)) {
    if (v.indexOf(marketName) >= 0) {
      return k
    }
  }
  return ''
}

export function getMarketArrangedFromList(
  markets: Schema$Market[],
  unifiedName: string,
  baseline_size: number,
  enabled_baseline_zero: boolean,
): MarketArranged {
  const found = _.find(markets, o => {
    return UNIFIED_MARKET_NAME[unifiedName].indexOf(o.Name) >= 0
  })
  return getMarketArranged(found, unifiedName, baseline_size, enabled_baseline_zero)
}

export type OddsRateData = { baseLine: string; bets: Schema$MarketBet[] }

export function getOddsRates(
  unifiedName: string,
  baseLine: string | null,
  bets: Schema$MarketBet[],
): OddsRateData {
  const data: OddsRateData = {
    baseLine: null,
    bets: [],
  }
  switch (unifiedName) {
    case '12':
      {
        const home = _.find(bets, o => {
          return o.Name === '1'
        })
        const away = _.find(bets, o => {
          return o.Name === '2'
        })

        data.bets = [home, away]
      }
      break
    case '1X2':
      {
        const home = _.find(bets, o => {
          return o.Name === '1'
        })
        const draw = _.find(bets, o => {
          return o.Name === 'X'
        })
        const away = _.find(bets, o => {
          return o.Name === '2'
        })

        data.bets = [home, draw, away]
      }
      break
    case 'HDP':
      {
        const home = _.find(bets, o => {
          return o.Name === '1'
        })
        const away = _.find(bets, o => {
          return o.Name === '2'
        })

        data.baseLine = baseLine
        data.bets = [home, away]
      }
      break
    case 'UO':
      {
        const under = _.find(bets, o => {
          return o.Name === 'Under'
        })
        const over = _.find(bets, o => {
          return o.Name === 'Over'
        })

        data.baseLine = baseLine
        data.bets = [under, over]
      }
      break
    case 'N12':
      {
        const home = _.find(bets, o => {
          return o.Name === '1'
        })
        const away = _.find(bets, o => {
          return o.Name === '2'
        })

        data.baseLine = baseLine
        data.bets = [home, away]
      }
      break
    case 'NYN':
      {
        const yes = _.find(bets, o => {
          return o.Name === 'Yes'
        })
        const no = _.find(bets, o => {
          return o.Name === 'No'
        })

        data.baseLine = baseLine
        data.bets = [yes, no]
      }
      break
    case 'NUEO':
    case 'N12N':
      //Under/Exactly/Over
      //1,2,Neither
      const exactly = _.find(bets, o => {
        return o.Name === 'Exactly'
      })
      const neither = _.find(bets, o => {
        return o.Name === 'Neither'
      })

      if (exactly) {
        const under = _.find(bets, o => {
          return o.Name === 'Under'
        })
        const over = _.find(bets, o => {
          return o.Name === 'Over'
        })

        data.baseLine = baseLine
        data.bets = [under, exactly, over]
      } else if (neither) {
        const home = _.find(bets, o => {
          return o.Name === '1'
        })
        const away = _.find(bets, o => {
          return o.Name === '2'
        })

        data.baseLine = baseLine
        data.bets = [home, away, neither]
      }
      break
  }
  return data
}

export function getCartTitle(
  match: Schema$Prematch,
  marketId: string,
  marketBetName: string,
  lang: string,
): string {
  let title = getBetTokenName(match.SportId, marketId, marketBetName, lang)
  switch (marketId) {
    case '52': // '12':
    case '226': // '12 Including Overtime':
      {
        const targetName = getTeamName(match, marketBetName, lang)
        if (targetName) {
          title = `${targetName} ${lang === 'ko' ? '승' : 'Win'}`
        }
      }
      break
    case '1': // '1X2':
    case '50': //'1X2 Including Overtime':
      if (marketBetName === 'X') {
        title = lang === 'ko' ? '무승부' : 'Draw'
      } else {
        const targetName = getTeamName(match, marketBetName, lang)
        if (targetName) {
          title = `${targetName} ${lang === 'ko' ? '승' : 'Win'}`
        }
      }
      break
    case '3': // 'Asian Handicap':
    case '342': // 'Asian Handicap Including Overtime':
      {
        const targetName = getTeamName(match, marketBetName, lang)
        if (targetName) {
          title = `${targetName}`
        }
      }
      break
  }
  return title
}

// export function getMarketName(
//   sportId: number,
//   marketId: string,
//   marketName: { en: string; ko: string },
//   lang: string,
// ): string {
//   let name = marketName[lang]
//   const filter = _.find(SPORT_MARKET_FILTER, o => {
//     return o.sportId === sportId
//   })
//   if (filter) {
//     for (const el of filter.marketGroup) {
//       const found = _.find(el.markets, o => {
//         return o.id.toString() === marketId
//       })
//       if (found) {
//         name = found.name[lang]
//         break
//       }
//     }
//   }

//   return name
// }

export function getTeamScore(match: Schema$Prematch, position: string): string {
  if (!match || !match.Scoreboard || match.Scoreboard.Status !== FIXTURE_STATUS.Finished) {
    return ''
  }
  const team = _.find(match.Scoreboard.Results || [], o => {
    return o.Position === position
  })
  if (team) {
    return team.Value
  }
  return ''
}

export function getTeamName(match: Schema$Prematch, position: string, lang: string): string {
  if (!match) {
    return 'unknown'
  }
  const team = _.find(match.Participants, o => {
    return o.Position === position
  })
  if (team) {
    return team.Name[lang] || team.Name['en']
  }
  return 'unknown'
}

export interface BaseLineParsed {
  BaseLine: string // N.N (N-N)

  IsClientBaseLine: boolean
  ClientBaseLine: string // N.N

  IsScoreLiine: boolean // (N-N) 형태
  ScoreLine: string // (N-N)
  HomeScore: number
  AwayScore: number
  SumScore: number
}
export function getBaselineParsed(baseline: string): BaseLineParsed {
  const parsed: BaseLineParsed = {
    BaseLine: baseline,
    IsClientBaseLine: false,
    ClientBaseLine: '',
    IsScoreLiine: false,
    ScoreLine: '',
    HomeScore: 0,
    AwayScore: 0,
    SumScore: 0,
  }

  if (!baseline) {
    return parsed
  }

  const ss = baseline.split(' ')
  if (ss.length !== 2) {
    return parsed
  }

  parsed.IsClientBaseLine = true
  parsed.ClientBaseLine = ss[0]

  const right = ss[1]
  // (N-N) 형태, 최소길이 5
  if (right.length < 5) {
    return parsed
  }

  if (right[0] !== '(' || right[right.length - 1] !== ')') {
    return parsed
  }

  const tt = right.slice(1, right.length - 1)
  const dd = tt.split('-')
  if (dd.length === 2) {
    try {
      const scores: number[] = []
      for (const d of dd) {
        let n = parseInt(d)
        scores.push(n)
      }
      if (scores.length === 2) {
        parsed.IsScoreLiine = true
        parsed.HomeScore = scores[0]
        parsed.AwayScore = scores[1]
        parsed.SumScore = parsed.HomeScore + parsed.AwayScore
      }
    } catch (err) {
      console.log(err.toString())
    }
  }

  return parsed
}

export function filterBaselineDuplication(items: Schema$FilteredMarket[]): Schema$FilteredMarket[] {
  const filtered: Schema$FilteredMarket[] = []
  // 같은 BaseLine (N-N) N 의 합이 높은것을 선택한다.
  const cbGrouped = _.groupBy(items, 'ClientBaseLine')
  for (const [k, v] of Object.entries(cbGrouped)) {
    // {ClientBaseLine: Schema$FilteredMarket}
    const scoreLines: BaseLineParsed[] = []
    for (const el of v) {
      const bp = getBaselineParsed(el.BaseLine)
      if (bp.IsScoreLiine) {
        scoreLines.push(bp)
      }
    }
    if (scoreLines.length === v.length) {
      scoreLines.sort((a: BaseLineParsed, b: BaseLineParsed): number => {
        return a.SumScore - b.SumScore
      })
      const highestScore = scoreLines[scoreLines.length - 1]
      const ff = _.filter(v, (o: Schema$FilteredMarket) => {
        return o.BaseLine === highestScore.BaseLine
      })
      filtered.push(...ff)
    } else {
      filtered.push(...v)
    }
  }
  return filtered
}

export function makeFilteredMarkets(
  market: Schema$Market,
  enabled_baseline_zero: boolean,
): Schema$FilteredMarket[] {
  const filtered: Schema$FilteredMarket[] = []
  const grouped = _.groupBy(market.Bets, 'BaseLine')
  for (const [k, v] of Object.entries(grouped)) {
    let baseline: string = null
    let clientBaseline: string = null
    if (k !== 'null' && k !== 'undefined') {
      baseline = k
      clientBaseline = toClientbaseline(baseline)
    }
    if (clientBaseline && !validBaseline(clientBaseline, enabled_baseline_zero)) {
      continue
    }

    filtered.push({
      Id: market.Id,
      Name: market.Name,
      BaseLine: baseline,
      ClientBaseLine: clientBaseline,
      Bets: v,
    })
  }

  const filteredBaseline: Schema$FilteredMarket[] = filterBaselineDuplication(filtered)

  filteredBaseline.sort((a: Schema$FilteredMarket, b: Schema$FilteredMarket): number => {
    if (a.ClientBaseLine && b.ClientBaseLine) {
      return Number(a.ClientBaseLine) - Number(b.ClientBaseLine)
    }
    return 0
  })
  return filteredBaseline
}

export function toClientbaseline(baseline: string) {
  if (!baseline) {
    return ''
  }
  const bl = baseline.split(' ')[0]
  return bl
}

export function validBaseline(baseline: string, enabled_baseline_zero: boolean) {
  if (!baseline) {
    return false
  }
  const bl = baseline.split(' ')[0]
  const [i, d] = bl.split('.')
  return d === '5' || (d === '0' && enabled_baseline_zero)
}

function getBetTokenName1X2(name: string, lang: string): string {
  let ss = name
  if (name === '1') {
    ss = lang === 'ko' ? '홈 승' : 'Home'
  } else if (name === '2') {
    ss = lang === 'ko' ? '원정 승' : 'Away'
  }
  if (name === 'X') {
    ss = lang === 'ko' ? '무' : 'Draw'
  }
  return ss
}

function getBetTokenNamePosition(name: string, lang: string): string {
  let ss = name
  if (name === '1') {
    ss = lang === 'ko' ? '홈' : 'Home'
  } else if (name === '2') {
    ss = lang === 'ko' ? '원정' : 'Away'
  }
  return ss
}

export function getBetTokenName(
  sportId: number,
  marketId: string,
  name: string,
  lang: string,
): string {
  let ss = name
  switch (name) {
    case '1':
    case '2':
    case 'X':
      const marketFilter = _.find(SPORT_MARKET_FILTER, o => {
        return o.sportId === sportId
      })
      let asItIs = marketFilter.betNameMeansAsItIs.indexOf(marketId) >= 0
      if (!asItIs) {
        const asPosition = marketFilter.betNameMeansPosition.indexOf(marketId) >= 0
        if (asPosition) {
          ss = getBetTokenNamePosition(name, lang)
        } else {
          ss = getBetTokenName1X2(name, lang)
        }
      }
      break
    case 'Neither':
      ss = lang === 'ko' ? '없음' : name
      break
    case 'Exactly':
      ss = lang === 'ko' ? '정확' : name
      break
    case 'Under':
      ss = lang === 'ko' ? '언더' : name
      break
    case 'Over':
      ss = lang === 'ko' ? '오버' : name
      break
    case 'Odd':
      ss = lang === 'ko' ? '홀' : name
      break
    case 'Even':
      ss = lang === 'ko' ? '짝' : name
      break
    case 'Yes':
      ss = lang === 'ko' ? '예' : name
      break
    case 'No':
      ss = lang === 'ko' ? '아니오' : name
      break
    case '12':
      ss = lang === 'ko' ? '홈 또는 원정' : 'Home or Away'
      break
    case '1X':
      ss = lang === 'ko' ? '홈 또는 무승부' : 'Home or Draw'
      break
    case 'X2':
      ss = lang === 'ko' ? '무승부 또는 원정' : 'Draw or Away'
      break
    case 'No Goal':
      ss = lang === 'ko' ? '골 없음' : name
      break
    case '1/1':
    case '1/2':
    case '1/X':
    case '2/1':
    case '2/2':
    case '2/X':
    case 'X/1':
    case 'X/2':
    case 'X/X':
      const arr = name.split('/')
      let arrNames = []
      for (const el of arr) {
        arrNames.push(getBetTokenName1X2(el, lang))
      }
      ss = arrNames.join('/')
      break
  }

  return ss
}

export function getDetailOddsboxName(
  unifiedName: string,
  prematch: Schema$Prematch,
  marketId: number,
  bet: Schema$MarketBet,
  lang: string,
) {
  let name = bet.Name
  switch (unifiedName) {
    case '1X2':
    case '12':
      name = getBetTokenName(prematch.SportId, marketId.toString(), bet.Name, lang)
      break
    case 'HDP':
      name = getBetTokenName(prematch.SportId, marketId.toString(), bet.Name, lang)
      break
    default:
      name = getBetTokenName(prematch.SportId, marketId.toString(), bet.Name, lang)
      break
  }
  return name
}

export function getBetStatusColor(status: number) {
  let color = 'white'
  switch (status) {
    case BET_STATUS.Open:
      color = '#f3c302'
      break
    case BET_STATUS.Suspended:
      color = '#727375'
      break
    case BET_STATUS.Settled:
      color = 'green'
      break
  }
  return color
}

export function adjustMarketNameKo(sportId: number, marketNameKo: string) {
  let marketName = marketNameKo
  switch (sportId) {
    case SPORT_ID.Football:
      marketName = marketNameKo.replace('1피리어드', '전반전')
      break
    case SPORT_ID.Baseball:
      marketName = marketNameKo.replace('전반전', '5이닝')
      break
  }
  return marketName
}

const FOOTBALL_PERIOD_NAMES = [
  { period: 10, name: '전반전' },
  { period: 20, name: '후반전' },
  { period: 25, name: '연장전' },
  { period: 30, name: '연장전(전반)' },
  { period: 35, name: '연장전(후반)' },
  { period: 50, name: '페널티' },
  { period: 80, name: '브레이크타임' },
  { period: 100, name: '풀타임' },
  { period: 101, name: '풀타임(연장)' },
  { period: 102, name: '풀타임(페널티)' },
]

const BASKETBALL_PERIOD_NAMES = [
  { period: 1, name: '1쿼터' },
  { period: 2, name: '2쿼터' },
  { period: 3, name: '3쿼터' },
  { period: 4, name: '4쿼터' },
  { period: 40, name: '연장' },
  { period: 80, name: '브레이크타임' },
  { period: 100, name: '풀타임' },
  { period: 101, name: '풀타임(연장)' },
]

const BASEBALL_PERIOD_NAMES = [
  { period: 1, name: '1이닝' },
  { period: 2, name: '2이닝' },
  { period: 3, name: '3이닝' },
  { period: 4, name: '4이닝' },
  { period: 5, name: '5이닝' },
  { period: 6, name: '6이닝' },
  { period: 7, name: '7이닝' },
  { period: 8, name: '8이닝' },
  { period: 9, name: '9이닝' },
  { period: 40, name: '연장' },
  { period: 62, name: '에러' },
  { period: 80, name: '브레이크타임' },
  { period: 100, name: '풀타임' },
  { period: 101, name: '풀타임(연장)' },
]

const VOLLEYBALL_PERIOD_NAMES = [
  { period: 1, name: '1세트' },
  { period: 2, name: '2세트' },
  { period: 3, name: '3세트' },
  { period: 4, name: '4세트' },
  { period: 5, name: '5세트' },
  { period: 50, name: '골든세트' },
  { period: 80, name: '브레이크타임' },
  { period: 100, name: '풀타임' },
  { period: 102, name: '풀타임(연장)' },
]

const ICEHOCKEY_PERIOD_NAMES = [
  { period: 1, name: '1피리어드' },
  { period: 2, name: '2피리어드' },
  { period: 3, name: '3피리어드' },
  { period: 40, name: '연장' },
  { period: 50, name: '페널티' },
  { period: 100, name: '풀타임' },
  { period: 101, name: '풀타임(연장)' },
  { period: 102, name: '풀타임(페널티)' },
]

export function getPeriodName(sportId: number, period: number) {
  let name = null
  switch (sportId) {
    case SPORT_ID.Football:
      name =
        _.find(FOOTBALL_PERIOD_NAMES, o => {
          return o.period === period
        })?.name || null
      break
    case SPORT_ID.Basketball:
      name =
        _.find(BASKETBALL_PERIOD_NAMES, o => {
          return o.period === period
        })?.name || null
      break
    case SPORT_ID.Baseball:
      name =
        _.find(BASEBALL_PERIOD_NAMES, o => {
          return o.period === period
        })?.name || null
      break
    case SPORT_ID.Volleyball:
      name =
        _.find(VOLLEYBALL_PERIOD_NAMES, o => {
          return o.period === period
        })?.name || null
      break
    case SPORT_ID.IceHockey:
      name =
        _.find(ICEHOCKEY_PERIOD_NAMES, o => {
          return o.period === period
        })?.name || null
      break
  }
  return name
}
