// import { MarketGroup } from './market_group_types'
// export const BASKETBALL_INPLAY_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const BASKETBALL_INPLAY_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      {
        id: 51,
        unifiedName: 'LIST2',
        name: {
          en: 'Odd/Even Including Overtime',
          ko: '홀/짝 총 득점 (연장포함)',
        },
      },
      {
        id: 226,
        unifiedName: '12',
        name: { en: '12 Including Overtime', ko: '승패 (연장포함)' },
      },
      {
        id: 69,
        unifiedName: 'LIST2',
        name: { en: 'Will The Game Go To Overtime?', ko: '연장전?' },
      },
      {
        id: 342,
        unifiedName: 'HDP',
        name: {
          en: 'Asian Handicap Including Overtime',
          ko: '핸디캡 (연장포함) *',
        },
      },
      {
        id: 28,
        unifiedName: 'UO',
        name: { en: 'Under/Over Including Overtime', ko: '총 득점 (연장포함)' },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: 'Team', ko: '팀' },
    markets: [
      {
        id: 221,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over - Home Team Including Overtime',
          ko: '총 득점 - 홈팀 (연장포함)',
        },
      },
      {
        id: 220,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over - Away Team Including Overtime',
          ko: '총 득점 - 원정팀 (연장포함)',
        },
      },
      {
        id: 354,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over Halftime - Home Team',
          ko: '총 득점 - 홈팀 (전반전)',
        },
      },
      {
        id: 355,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over Halftime - Away Team',
          ko: '총 득점 - 원정팀 (전반전)',
        },
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: 'Period', ko: '쿼터' },
    markets: [
      // 1쿼터
      {
        id: 41,
        unifiedName: '1X2',
        name: { en: '1st Period Winner', ko: '승무패 (1쿼터)' },
      },
      {
        id: 21,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period', ko: '총 득점 (1쿼터)' },
      },
      {
        id: 64,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 1st Period', ko: '핸디캡 (1쿼터) *' },
      },
      {
        id: 72,
        unifiedName: 'LIST2',
        name: { en: '1st Period Odd/Even', ko: '홀/짝 총 득점 (1쿼터)' },
      },
      {
        id: 317,
        unifiedName: 'NUEO',
        name: {
          en: 'Under/Exactly/Over - 1st Period',
          ko: '총 득점 3Way (1쿼터)',
        },
      },
      {
        id: 731,
        unifiedName: 'NYN',
        name: {
          en: '1st Period Home Team To Score',
          ko: '홈팀 득점 (1쿼터)',
        },
      },
      {
        id: 735,
        unifiedName: 'NYN',
        name: {
          en: '1st Period Away Team To Score',
          ko: '원정팀 득점 (1쿼터)',
        },
      },
      // 2쿼터
      {
        id: 42,
        unifiedName: '1X2',
        name: { en: '2nd Period Winner', ko: '승무패 (2쿼터)' },
      },
      {
        id: 45,
        unifiedName: 'UO',
        name: { en: 'Under/Over 2nd Period', ko: '총 득점 (2쿼터)' },
      },
      {
        id: 65,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 2nd Period', ko: '핸디캡 (2쿼터) *' },
      },
      {
        id: 73,
        unifiedName: 'LIST2',
        name: { en: '2nd Period Odd/Even', ko: '홀/짝 총 득점 (2쿼터)' },
      },
      {
        id: 318,
        unifiedName: 'NUEO',
        name: {
          en: 'Under/Exactly/Over - 2nd Period',
          ko: '총 득점 3Way (2쿼터)',
        },
      },
      {
        id: 732,
        unifiedName: 'NYN',
        name: {
          en: '2nd Period Home Team To Score',
          ko: '홈팀 득점 (2쿼터)',
        },
      },
      {
        id: 736,
        unifiedName: 'NYN',
        name: {
          en: '2nd Period Away Team To Score',
          ko: '원정팀 득점 (2쿼터)',
        },
      },
      {
        id: 154,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over 2nd Period - Home Team',
          ko: '총 득점 - 홈팀 (2쿼터)',
        },
      },
      {
        id: 156,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over 2nd Period - Away Team',
          ko: '총 득점 - 원정팀 (2쿼터)',
        },
      },
      // 3쿼터
      {
        id: 43,
        unifiedName: '1X2',
        name: { en: '3rd Period Winner', ko: '승무패 (3쿼터)' },
      },
      {
        id: 46,
        unifiedName: 'UO',
        name: { en: 'Under/Over 3rd Period', ko: '총 득점 (3쿼터)' },
      },
      {
        id: 66,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 3rd Period', ko: '핸디캡 (3쿼터) *' },
      },
      {
        id: 74,
        unifiedName: 'LIST2',
        name: { en: '3rd Period Odd/Even', ko: '홀/짝 총 득점 (3쿼터)' },
      },
      {
        id: 319,
        unifiedName: 'NUEO',
        name: {
          en: 'Under/Exactly/Over - 3rd Period',
          ko: '총 득점 3Way (3쿼터)',
        },
      },
      {
        id: 733,
        unifiedName: 'NYN',
        name: {
          en: '3rd Period Home Team To Score',
          ko: '홈팀 득점 (3쿼터)',
        },
      },
      {
        id: 737,
        unifiedName: 'NYN',
        name: {
          en: '3rd Period Away Team To Score',
          ko: '원정팀 득점 (3쿼터)',
        },
      },
      {
        id: 223,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over 3rd Period - Home Team',
          ko: '총 득점 - 홈팀 (3쿼터)',
        },
      },
      {
        id: 222,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over 3rd Period - Away Team',
          ko: '총 득점 - 원정팀 (3쿼터)',
        },
      },
      // 4쿼터
      {
        id: 44,
        unifiedName: '1X2',
        name: { en: '4th Period Winner', ko: '승무패 (4쿼터)' },
      },
      {
        id: 47,
        unifiedName: 'UO',
        name: { en: 'Under/Over 4th Period', ko: '총 득점 (4쿼터)' },
      },
      {
        id: 67,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 4th Period', ko: '핸디캡 (4쿼터) *' },
      },
      {
        id: 75,
        unifiedName: 'LIST2',
        name: { en: '4th Period Odd/Even', ko: '홀/짝 총 득점 (4쿼터)' },
      },
      {
        id: 320,
        unifiedName: 'NUEO',
        name: {
          en: 'Under/Exactly/Over - 4th Period',
          ko: '총 득점 3Way (4쿼터)',
        },
      },
      {
        id: 734,
        unifiedName: 'NYN',
        name: {
          en: '4th Period Home Team To Score',
          ko: '홈팀 득점 (4쿼터)',
        },
      },
      {
        id: 738,
        unifiedName: 'NYN',
        name: {
          en: '4th Period Away Team To Score',
          ko: '원정팀 득점 (4쿼터)',
        },
      },
      {
        id: 287,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over 4th Period - Home Team',
          ko: '총 득점 - 홈팀 (4쿼터)',
        },
      },
      {
        id: 288,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over 4th Period - Away Team',
          ko: '총 득점 - 원정팀 (4쿼터)',
        },
      },
    ],
  },
  {
    groupId: 4,
    groupName: { en: '1st/2nd Halftime', ko: '전/후반전' },
    markets: [
      {
        id: 282,
        unifiedName: '1X2',
        name: { en: '1X2 Halftime', ko: '승무패 (전반전)' },
      },
      {
        id: 62,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even Halftime', ko: '홀/짝 총 득점 (전반전)' },
      },
      {
        id: 53,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap Halftime', ko: '핸디캡 (전반전) *' },
      },
      {
        id: 77,
        unifiedName: 'UO',
        name: { en: 'Under/Over Halftime', ko: '총 득점 (전반전)' },
      },
    ],
  },
]
