export const LiveProtocol = {
  // (client <-> server) sync operation: request and response
  SYNC_SOMETHING: 10000,
  SYNC_ENTER: 10001,
  SYNC_ENTER_ADMIN: 10001,
  SYNC_ENTER_SPACE: 10002,
  SYNC_RELOAD_SPACE: 10003,
  SYNC_LEAVE_SPACE: 10004,
  SYNC_AUTH: 10005,

  // (server -> client) async trnasfer from server(notifying)
  NOTIFY_SOMETHING: 20000,
  NOTIFY_MINIGAME_MATCHUP: 20001,
  NOTIFY_MINIGAME_MATCHUP_LIST: 20002,
  NOTIFY_MINIGAME_ODDS_OPTIONS: 20003,
  NOTIFY_MINIGAME_EVO_RESULT: 20004,

  NOTIFY_USER_PROPERTY_UPDATE: 20020,
  NOTIFY_USER_QNA_UPDATE: 20021,
  NOTIFY_USER_NOTIFICATION: 20022,
  NOTIFY_USER_FORCE_OUT: 20023,
  NOTIFY_NEW_LOGIN_USER_FORCE_OUT: 20024,

  NOTIFY_SERVER_MAINTENANCE: 20050,

  // (client -> server) protocols doesn't need to be responded by server
  REPORT_SOMETHING: 30000,
}
