/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Grid, Divider, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import { QNA_STATE } from '@interface/config'
import { IBankTransferCompRecord } from '@protocol/bank'
import apiUser from '@services/api/user'

//css
import { NoList } from '@styles/modal.style'
import { PaginationStyle } from '~/styles/base.style'
import { numberWithCommas } from '~/utils/utils'

const MobileBoardStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.boards.contentBg,
  borderRadius: '4px',
  color: theme.colors.listTitle,

  '& > .MuiGrid-container': {
    marginTop: 0,
    marginBottom: '16px',
    padding: '8px 16px 16px',
  },
}))

type TrackData = IBankTransferCompRecord.Schema
const ItemPerPage = 10

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

function MobileCompRecord({ location }) {
  const query = queryString.parse(location.search)
  const { page } = query
  const startPage = page ? Number(page) : 1

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)

  const intl = useIntl()
  const history = useHistory()
  const { globalStore, userStore } = useRootStore()
  const { userComp } = userStore

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  const fetch = async (params: IBankTransferCompRecord.Params) => {
    try {
      const ret = await apiUser.transferCompRecord(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    const params: IBankTransferCompRecord.Params = {
      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetch(params)
  }, [tabPage, userComp])

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/mypage/search?main=comp&page=${value}`)
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const listItems = []
  if (track) {
    for (const el of track.items) {
      const dateName = moment(el.time).format('YYYY-MM-DD HH:mm:ss')
      listItems.push(
        <MobileBoardStyle>
          <Grid container spacing={2}>
            <Grid
              item
              xs={6}
              sx={{
                p: 1,
                pl: 2,
                pr: 2,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'left',
              }}
            >
              {dateName}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ pl: 2, pr: 2, display: 'flex', alignItems: 'center', alignContent: 'left' }}
            >
              콤프 전환
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                pl: 2,
                pr: 2,
                pb: 1,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'left',
              }}
            >
              출금: {numberWithCommas(el.amount)}
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                pl: 2,
                pr: 2,
                pb: 1,
                display: 'flex',
                alignItems: 'center',
                alignContent: 'left',
              }}
            >
              잔액: {numberWithCommas(Math.floor(el.own))}
            </Grid>
          </Grid>
        </MobileBoardStyle>,
      )
    }

    pageData = getPageData(track)
  }

  let emptyContents = null
  if (listItems.length === 0) {
    emptyContents = (
      <NoList>
        <p>{intl.formatMessage({ id: 'msg.comp-transfer.no-history' })}</p>
      </NoList>
    )
  }

  return (
    <Box>
      <Typography
        variant="h5"
        component="h3"
        sx={{ mt: 3, py: 3, fontWeight: 'bold', color: '#D1D9E3' }}
      >
        {intl.formatMessage({ id: 'component.comp.history' })}
      </Typography>
      <Box>{listItems}</Box>
      {emptyContents}

      <PaginationStyle
        count={pageData.totalPage}
        variant="outlined"
        page={pageData.currPage}
        onChange={handlePagingChange}
      />
    </Box>
  )
}

export default MobileCompRecord
