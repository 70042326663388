import { useState, useEffect, useRef, ReactNode } from 'react'
import * as _ from 'lodash'
import { Box, Grid, Zoom, Toolbar, Fab, styled } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroller'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { observer } from 'mobx-react'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import CircularProgress from '@mui/material/CircularProgress'
import useRootStore from '~/store/useRootStore'
import PrematchLeagueBox from '~/components/PrematchLeagueBox'

// icon
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { useOnMount } from '~/utils/reactExt'
import { FETCH_STATE } from '~/store/sportsStore/sports-util'
import { timelineLog } from '~/utils'

const TopButtonWrap = styled('div')`
  float: right;
  margin-top: -60px;
  margin-right: 10px;

  .MuiFab-root {
    width: 60px;
    height: 60px;
    border: 1px solid #1e2b3d;
    border-radius: 20px;
    background-color: #1e2b3d;
    box-shadow: 0px 3px 6px #00000059;

    &:hover {
      background-color: #1e2b3d;
    }
  }

  .MuiSvgIcon-root {
    color: #d1d9e3;
    font-size: 24px;
  }
`

const FETCH_SIZE = 10

interface Props {
  desktop: boolean
  virtualDesktop: boolean
}

const PrematchWrap = styled(Box)(({ theme }) => ({
  minHeight: 428,
  maxHeight: `calc(100vh - ${theme.height.header + 80}px)`,
  overflowY: 'auto',

  //scroll custom
  scrollbarWidth: 'thin',
  scrollbarColor: '#182740 transparent',
  '&::-webkit-scrollbar': {
    width: '0.4rem',
    height: '0.4rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#182740',
  },
  '&:hover': {
    scrollbarColor: '#19253a transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#19253a',
  },
}))

interface ScrollTopProps {
  children: React.ReactNode
  refParent: any
}

function ScrollTop({ children, refParent }: ScrollTopProps) {
  // const { children } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })
  //const trigger = props.scrollHeight - props.scrollTop === props.clientHeight;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector('#back-to-game-top')

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }

    setTimeout(() => {
      if (refParent && refParent.current) {
        refParent.current.scrollTo(0, 0)
      }
    }, 250)
  }

  return (
    // <Zoom in={trigger}>
    // <Zoom>
    <TopButtonWrap onClick={handleClick} role="presentation">
      {children}
    </TopButtonWrap>
    // </Zoom>
  )
}

function PrematchPanel({ desktop, virtualDesktop }: Props, props) {
  const [filteredItems, setFilteredItems] = useState([])
  const [loaded, setLoaded] = useState<boolean>(false)
  const [fetching, setFetching] = useState<boolean>(false)
  const [reachedEnd, setReachedEnd] = useState<boolean>(false)
  const [showScrollTop, setShowScrollTop] = useState<boolean>(false)

  const scrollParentRef = useRef(null)
  const lastScrollY = useRef(0)

  const { sportsStore } = useRootStore()

  const { lang, flight, fetchState, prematchUpdatedTimestamp, behindUpdatedTimestamp } = sportsStore
  const lastDesktop = useRef<boolean>(null)
  if (lastDesktop.current === null) {
    lastDesktop.current = virtualDesktop
  }

  const handleScroll = event => {
    if (scrollParentRef && scrollParentRef.current) {
      const newY = scrollParentRef.current.scrollTop
      const offset = newY - lastScrollY.current
      const show = (offset === 0 && showScrollTop) || newY - lastScrollY.current > 0
      if (showScrollTop !== show) {
        setShowScrollTop(show)
      }
      lastScrollY.current = newY
    }
  }

  useEffect(() => {
    // timelineLog('### prematch updated event!')
    setFilteredItems([])
    setLoaded(_.isNumber(prematchUpdatedTimestamp) && prematchUpdatedTimestamp > 0)
  }, [prematchUpdatedTimestamp])

  useEffect(() => {
    // timelineLog('### behind updated event!')
  }, [behindUpdatedTimestamp])

  if (lastDesktop.current !== virtualDesktop) {
    lastDesktop.current = virtualDesktop
    sportsStore.testPrematchUpdatedForce()
    return <></>
  }

  const hasMoreItems = sportsStore.items && sportsStore.items.length > filteredItems.length

  const fetchItems = async () => {
    if (fetching) {
      return
    }

    // timelineLog('fetch items start!')
    setFetching(true)

    try {
      if (sportsStore.items && sportsStore.items.length > 0) {
        const sliceStart = filteredItems.length
        let sliceEnd = sliceStart
        let matchSize = 0
        for (let i = filteredItems.length; i < sportsStore.items.length; i += 1) {
          sliceEnd += 1
          matchSize += sportsStore.items[i].prematches.length
          if (matchSize >= FETCH_SIZE) {
            break
          }
        }
        const newItems = sportsStore.items.slice(sliceStart, sliceEnd)
        if (newItems.length > 0) {
          const matchContents = []
          if (_.isArray(filteredItems)) {
            for (const el of newItems) {
              matchContents.push(
                <Grid key={matchContents.length} item xs={12}>
                  <PrematchLeagueBox
                    desktop={desktop}
                    virtualDesktop={virtualDesktop}
                    lang={lang}
                    flight={flight}
                    items={el.prematches}
                  ></PrematchLeagueBox>
                </Grid>,
              )
            }
          }
          const arrItems = [...filteredItems, ...matchContents]
          setFilteredItems(arrItems)
          setReachedEnd(sportsStore.items.length > 0 && arrItems.length === sportsStore.items.length)
        }

        // timelineLog(`fetch items end! added item size: ${newItems.length}`)
      } else {
        setFilteredItems([])
        // timelineLog(`fetch items end! no items exist.`)
      }
    } finally {
      setFetching(false)
    }
  }

  const loader = (
    <div key="loader" className="loader">
      <Box sx={{ textAlign: 'center', mt: 2 }}>
        <CircularProgress />
      </Box>
    </div>
  )

  const scrollTop = (
    <ScrollTop {...props} refParent={scrollParentRef}>
      <Fab aria-label="scroll back to top">
        <ArrowUpwardIcon />
      </Fab>
    </ScrollTop>
  )

  return (
    <>
      <Toolbar id="back-to-game-top" sx={{ minHeight: '0px !important' }} />
      <Box sx={{ mt: 1, maxWidth: '1140px' }}>
        {!loaded && (
          <div key="loader" className="loader">
            <Box sx={{ textAlign: 'center', mt: 15 }}>
              <CircularProgress />
            </Box>
          </div>
        )}
        <PrematchWrap ref={scrollParentRef} onScroll={handleScroll}>
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchItems}
            hasMore={hasMoreItems}
            loader={loader}
            style={{ width: '100%' }}
            useWindow={false}
            getScrollParent={() => scrollParentRef.current}
          >
            <Grid container spacing={1}>
              {filteredItems}
              {reachedEnd && <Box sx={{ height: '100px' }}>&nbsp;</Box>}
            </Grid>
          </InfiniteScroll>
        </PrematchWrap>
        {showScrollTop && <Box sx={{ position: 'relative; right: 10px; bottom: 26px;' }}>{scrollTop}</Box>}
      </Box>
    </>
  )
}

export default observer(PrematchPanel)
