import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const FooterStyle = styled('div')`
  background-color: ${props=>props.theme.footer.background};
  text-align: center;

  .inner{
    color: ${props=>props.theme.footer.innerColor};
  }
  .copyright {
    color: ${props=>props.theme.footer.copyright};
  }
`