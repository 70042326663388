// image
import {
  img_slot_pragmatic,
  img_slot_cq9,
  img_slot_habanero,
  img_slot_boongo,
  img_slot_micro,
  img_slot_genesis,
  img_slot_pgsoft,
  img_slot_playngo,
  img_slot_isoftbet,
  img_slot_evoplay,
  img_slot_playson,
  img_slot_playstar,
  img_slot_dreamtech,
  img_slot_rtg,
  img_slot_star,
  img_slot_xin,
  img_slot_show,
  img_slot_tpg,
  img_slot_toptrend,
  img_slot_bbin,
  img_slot_bbtech,
  img_slot_gameart,
  gameTitle01,
  gameTitle02,
  gameTitle03,
  gameTitle04,
  gameTitle05,
  gameTitle06,
  gameTitle07,
  gameTitle08,
  gameTitle09,
  gameTitle10,
  gameTitle11,
  gameTitle12,
  gameTitle13,
  gameTitle14,
  gameTitle15,
  gameTitle16,
  gameTitle17,
  gameTitle18,
  gameTitle19,
  gameTitle20,
  gameTitle21,
  img_slot_blueprint,
  img_slot_dragoon,
  img_slot_elk,
  img_slot_elysium,
  img_slot_maverick,
  img_slot_nent,
  img_slot_quickspin,
  img_slot_redrake,
  img_slot_redtiger,
  img_slot_relax,
  img_slot_spearhead,
  img_slot_gmw,
  img_slot_btg,
  img_slot_aspect,
  img_slot_betsoft,
  img_slot_skywind,
  img_slot_funta,
  img_slot_netgaming,
  img_slot_naga,
  img_slot_nspin,
  img_slot_ncl,
  img_slot_hs,
  img_slot_aux,
  img_slot_upg,
  img_slot_4tp,
  img_slot_boomerang,
  img_slot_fantasma,
  img_slot_reelplay,
  img_slot_gpi,
  img_slot_cc88,
  img_slot_playtech,
  img_slot_royal,
  img_slot_spadegaming,
  img_slot_wazdan,
  img_slot_worldmatch,
} from '~/assets/images/layout_1'
import * as imgSlot from '~/assets/images/layout_1/slot'
import * as imgSlotMarine from '~/assets/images/layout_1/slot/marine'
import * as imgSlotMarine2 from '~/assets/images/layout_3/slot/marine2'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

let mergedImgSlot = { ...imgSlot }
let mergedHoverImg = { ...imgSlotMarine }

switch (channelSymbol) {
  case 'marine':
  case 'tiger':
  case 'hilton':
  case 'soft':
  case 'gangnam':
  case 'milky':
  case 'hulk':
    mergedImgSlot = { ...mergedImgSlot, ...imgSlotMarine }
    mergedHoverImg = { ...mergedHoverImg, ...imgSlotMarine }
    break
  case 'marine2':
    mergedImgSlot = { ...mergedImgSlot, ...imgSlotMarine2 }
    break
}

// export const AllGameSlots = [
//   {
//     kind: 'SL8',
//     vendor: 'TPLUS',
//     provider: 'PragmaticPlay',
//     thumbnail: img_slot_pragmatic,
//   },
//   {
//     kind: 'SL23',
//     vendor: 'TPLUS',
//     provider: 'CQ9',
//     thumbnail: img_slot_cq9,
//   },
//   {
//     kind: 'SL24',
//     vendor: 'TPLUS',
//     provider: 'HABANERO',
//     thumbnail: img_slot_habanero,
//   },
//   {
//     kind: 'SL37',
//     vendor: 'TPLUS',
//     provider: 'BNG',
//     thumbnail: img_slot_boongo,
//   },
//   {
//     kind: 'SL21',
//     vendor: 'TPLUS',
//     provider: 'MG_DASHUR_TC',
//     thumbnail: img_slot_micro,
//   },
//   {
//     kind: 'SL33',
//     vendor: 'TPLUS',
//     provider: 'GENESIS',
//     thumbnail: img_slot_genesis,
//   },
//   {
//     kind: 'SL14',
//     vendor: 'TPLUS',
//     provider: 'PGSOFT',
//     thumbnail: img_slot_pgsoft,
//   },
//   {
//     kind: 'SL51',
//     vendor: 'TPLUS',
//     provider: 'PLAYNGO',
//     thumbnail: img_slot_playngo,
//   },
// ]

// export const MoreSlots = [
//   {
//     kind: 'SL13',
//     provider: 'iSOFTBET',
//     thumbnail: img_slot_isoftbet,
//   },
//   {
//     kind: 'SL40',
//     provider: 'EVOPLAY',
//     thumbnail: img_slot_evoplay,
//   },
//   {
//     kind: 'SL36',
//     provider: 'PLAYSON_NEW',
//     thumbnail: img_slot_playson,
//   },
//   {
//     kind: 'SL29',
//     provider: 'PLAYSTAR',
//     thumbnail: img_slot_playstar,
//   },
//   {
//     kind: 'SL41',
//     provider: 'DREAMTECH',
//     thumbnail: img_slot_dreamtech,
//   },
//   {
//     kind: 'SL19',
//     provider: 'RTG',
//     thumbnail: img_slot_rtg,
//   },
//   {
//     kind: '',
//     provider: 'StarGame',
//     thumbnail: img_slot_star,
//   },
//   {
//     kind: '',
//     provider: 'AG_NEW',
//     thumbnail: img_slot_xin,
//   },
//   {
//     kind: 'SL47',
//     provider: 'THE_SHOW',
//     thumbnail: img_slot_show,
//   },
//   {
//     kind: 'SL34',
//     provider: 'TPG',
//     thumbnail: img_slot_tpg,
//   },
//   {
//     kind: 'SL32',
//     provider: 'TTG',
//     thumbnail: img_slot_toptrend,
//   },
//   {
//     kind: 'SL15',
//     provider: 'BBIN',
//     thumbnail: img_slot_bbin,
//   },
//   {
//     kind: 'SL6',
//     provider: 'BBTECH',
//     thumbnail: img_slot_bbtech,
//   },
//   {
//     kind: 'SL30',
//     provider: 'GAMEART',
//     thumbnail: img_slot_gameart,
//   },
// ]

export const AllSlotGames = [
  // kgon
  {
    kind: 'PRAGMATIC_SLOT',
    thumbnail: mergedImgSlot.img_slot_pragmatic,
    banner: gameTitle06,
    hoverimg: mergedHoverImg.img_slot_pragmatic_h,
    localeId: 'trading.SL.PRAGMATIC_SLOT',
  },
  {
    kind: 'REELKINGDOM_SLOT',
    thumbnail: mergedImgSlot.img_slot_reelkingdom,
    banner: gameTitle06,
    hoverimg: mergedHoverImg.img_slot_reelkingdom_h,
    localeId: 'trading.SL.img_slot_reelkingdom_SLOT',
  },
  {
    kind: 'HABANERO_SLOT',
    thumbnail: mergedImgSlot.img_slot_habanero,
    banner: gameTitle19,
    hoverimg: mergedHoverImg.img_slot_habanero_h,
    localeId: 'trading.SL.HABANERO_SLOT',
  },
  {
    kind: 'BOOONGO_SLOT',
    thumbnail: mergedImgSlot.img_slot_boongo,
    banner: gameTitle15,
    hoverimg: mergedHoverImg.img_slot_boongo_h,
    localeId: 'trading.SL.BOOONGO_SLOT',
  },
  {
    kind: 'CQ9_SLOT',
    thumbnail: mergedImgSlot.img_slot_cq9,
    banner: gameTitle16,
    hoverimg: mergedHoverImg.img_slot_cq9_h,
    localeId: 'trading.SL.CQ9_SLOT',
  },
  {
    kind: 'MICROGAMING_SLOT',
    thumbnail: mergedImgSlot.img_slot_micro,
    banner: gameTitle01,
    hoverimg: mergedHoverImg.img_slot_micro_h,
    localeId: 'trading.SL.MICROGAMING_SLOT',
  },
  {
    kind: 'PLAYSON_SLOT',
    thumbnail: mergedImgSlot.img_slot_playson,
    banner: gameTitle04,
    hoverimg: mergedHoverImg.img_slot_playson_h,
    localeId: 'trading.SL.PLAYSON_SLOT',
  },
  {
    kind: 'PLAYNGO_SLOT',
    thumbnail: mergedImgSlot.img_slot_playngo,
    banner: gameTitle03,
    hoverimg: mergedHoverImg.img_slot_playngo_h,
    localeId: 'trading.SL.PLAYNGO_SLOT',
  },
  {
    kind: 'AG_SLOT',
    thumbnail: mergedImgSlot.img_slot_xin,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_xin_h,
    localeId: 'trading.SL.AG_SLOT',
  },
  {
    kind: 'EVOLUTION_REDTIGER',
    thumbnail: mergedImgSlot.img_slot_redtiger,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_redtiger_h,
    localeId: 'trading.SL.EVOLUTION_REDTIGER',
  },
  {
    kind: 'NETENT_SLOT',
    thumbnail: mergedImgSlot.img_slot_nent,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_nent_h,
    localeId: 'trading.SL.NETENT_SLOT',
  },
  {
    kind: 'EXNETENT_SLOT',
    thumbnail: mergedImgSlot.img_slot_nent,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_nent_h,
    localeId: 'trading.SL.EXNETENT_SLOT',
  },
  {
    kind: 'QUICKSPIN_SLOT',
    thumbnail: mergedImgSlot.img_slot_quickspin,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_quickspin_h,
    localeId: 'trading.SL.QUICKSPIN_SLOT',
  },
  {
    kind: 'BLUEPRINT_SLOT',
    thumbnail: mergedImgSlot.img_slot_blueprint,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_blueprint_h,
    localeId: 'trading.SL.BLUEPRINT_SLOT',
  },
  {
    kind: 'ELK_SLOT',
    thumbnail: mergedImgSlot.img_slot_elk,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_elk_h,
    localeId: 'trading.SL.ELK_SLOT',
  },
  {
    kind: 'REDRAKE_SLOT',
    thumbnail: mergedImgSlot.img_slot_redrake,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_redrake_h,
    localeId: 'trading.SL.REDRAKE_SLOT',
  },
  {
    kind: 'RELAX_SLOT',
    thumbnail: mergedImgSlot.img_slot_relax,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_relax_h,
    localeId: 'trading.SL.RELAX_SLOT',
  },
  {
    kind: 'MAVERICK_SLOT',
    thumbnail: mergedImgSlot.img_slot_maverick,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_maverick_h,
    localeId: 'trading.SL.MAVERICK_SLOT',
  },
  {
    kind: 'DRAGOON_SLOT',
    thumbnail: mergedImgSlot.img_slot_dragoon,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_dragoon_h,
    localeId: 'trading.SL.DRAGOON_SLOT',
  },
  {
    kind: 'SPEARHEAD_SLOT',
    thumbnail: mergedImgSlot.img_slot_spearhead,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_spearhead_h,
    localeId: 'trading.SL.SPEARHEAD_SLOT',
  },
  {
    kind: 'ELYSIUM_SLOT',
    thumbnail: mergedImgSlot.img_slot_elysium,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_elysium_h,
    localeId: 'trading.SL.ELYSIUM_SLOT',
  },
  {
    kind: 'GMW_SLOT',
    thumbnail: mergedImgSlot.img_slot_gmw,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_gmw_h,
    localeId: 'trading.SL.GMW_SLOT',
  },
  {
    kind: 'GAMEART_SLOT',
    thumbnail: mergedImgSlot.img_slot_gameart,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_gameart_h,
    localeId: 'trading.SL.GAMEART_SLOT',
  },
  {
    kind: 'BTG_SLOT',
    thumbnail: mergedImgSlot.img_slot_btg,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_btg_h,
    localeId: 'trading.SL.BTG_SLOT',
  },
  {
    kind: 'PGSOFT_SLOT',
    thumbnail: mergedImgSlot.img_slot_pgsoft,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_pgsoft_h,
    localeId: 'trading.SL.PGSOFT_SLOT',
  },
  {
    kind: 'ASPECT_SLOT',
    thumbnail: mergedImgSlot.img_slot_aspect,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_aspect_h,
    localeId: 'trading.SL.ASPECT_SLOT',
  },
  {
    kind: 'BETSOFT_SLOT',
    thumbnail: mergedImgSlot.img_slot_betsoft,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_betsoft_h,
    localeId: 'trading.SL.BETSOFT_SLOT',
  },
  {
    kind: 'SKYWIND_SLOT',
    thumbnail: mergedImgSlot.img_slot_skywind,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_skywind_h,
    localeId: 'trading.SL.SKYWIND_SLOT',
  },
  {
    kind: 'FUNTA_SLOT',
    thumbnail: mergedImgSlot.img_slot_funta,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_funta_h,
    localeId: 'trading.SL.FUNTA_SLOT',
  },
  {
    kind: 'NETGAMING_SLOT',
    thumbnail: mergedImgSlot.img_slot_netgaming,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_netgaming_h,
    localeId: 'trading.SL.NETGAMING_SLOT',
  },
  {
    kind: 'PS_SLOT',
    thumbnail: mergedImgSlot.img_slot_playstar,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_playstar_h,
    localeId: 'trading.SL.PS_SLOT',
  },
  {
    kind: 'NAGA_SLOT',
    thumbnail: mergedImgSlot.img_slot_naga,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_naga_h,
    localeId: 'trading.SL.NAGA_SLOT',
  },
  {
    kind: 'NSPIN_SLOT',
    thumbnail: mergedImgSlot.img_slot_nspin,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_nspin_h,
    localeId: 'trading.SL.NSPIN_SLOT',
  },
  {
    kind: 'NLC_SLOT',
    thumbnail: mergedImgSlot.img_slot_ncl,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_ncl_h,
    localeId: 'trading.SL.NLC_SLOT',
  },
  {
    kind: 'HS_SLOT',
    thumbnail: mergedImgSlot.img_slot_hs,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_hs_h,
    localeId: 'trading.SL.HS_SLOT',
  },
  {
    kind: 'AUX_SLOT',
    thumbnail: mergedImgSlot.img_slot_aux,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_aux_h,
    localeId: 'trading.SL.AUX_SLOT',
  },
  {
    kind: 'EVO_SLOT',
    thumbnail: mergedImgSlot.img_slot_evoplay,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_evoplay_h,
    localeId: 'trading.SL.EVO_SLOT',
  },
  {
    kind: 'UPG_SLOT',
    thumbnail: mergedImgSlot.img_slot_upg,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_upg_h,
    localeId: 'trading.SL.UPG_SLOT',
  },
  {
    kind: '4TP_SLOT',
    thumbnail: mergedImgSlot.img_slot_4tp,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_4tp_h,
    localeId: 'trading.SL.4TP_SLOT',
  },
  {
    kind: 'BOOMERANG_SLOT',
    thumbnail: mergedImgSlot.img_slot_boomerang,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_boomerang_h,
    localeId: 'trading.SL.BOOMERANG_SLOT',
  },
  {
    kind: 'FANTASMA_SLOT',
    thumbnail: mergedImgSlot.img_slot_fantasma,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_fantasma_h,
    localeId: 'trading.SL.FANTASMA_SLOT',
  },
  {
    kind: 'REELPLAY_SLOT',
    thumbnail: mergedImgSlot.img_slot_reelplay,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_reelplay_h,
    localeId: 'trading.SL.REELPLAY_SLOT',
  },
  {
    kind: 'GPI_SLOT',
    thumbnail: mergedImgSlot.img_slot_gpi,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_gpi_h,
    localeId: 'trading.SL.GPI_SLOT',
  },
  {
    kind: 'FUNKYGAMES_SLOT',
    thumbnail: mergedImgSlot.img_slot_funkygames,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_funkygames_h,
    localeId: 'trading.SL.FUNKYGAMES_SLOT',
  },
  {
    kind: 'BGAMING_SLOT',
    thumbnail: mergedImgSlot.img_slot_bgaming,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_bgaming_h,
    localeId: 'trading.SL.BGAMING_SLOT',
  },
  {
    kind: 'BOOMING_SLOT',
    thumbnail: mergedImgSlot.img_slot_booming,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_booming_h,
    localeId: 'trading.SL.BOOMING_SLOT',
  },
  {
    kind: 'EXPANSE_SLOT',
    thumbnail: mergedImgSlot.img_slot_expanse,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_expanse_h,
    localeId: 'trading.SL.EXPANSE_SLOT',
  },
  {
    kind: 'JOKER_SLOT',
    thumbnail: mergedImgSlot.img_slot_joker,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_joker_h,
    localeId: 'trading.SL.JOKER_SLOT',
  },
  {
    kind: 'JILI_SLOT',
    thumbnail: mergedImgSlot.img_slot_jili,
    banner: null,
    hoverimg: mergedHoverImg.img_slot_jili_h,
    localeId: 'trading.SL.JILI_SLOT',
  },
  // amazon
  {
    kind: 'SPADE_SLOT',
    thumbnail: img_slot_spadegaming,
    banner: null,
    localeId: 'trading.SL.SPADE_SLOT',
  },
  {
    kind: 'WORLDMATCH_SLOT',
    thumbnail: img_slot_worldmatch,
    banner: null,
    localeId: 'trading.SL.WORLDMATCH_SLOT',
  },
  {
    kind: 'PLAYTECH_SLOT',
    thumbnail: img_slot_playtech,
    banner: null,
    localeId: 'trading.SL.PLAYTECH_SLOT',
  },
  {
    kind: 'WAZDAN_SLOT',
    thumbnail: img_slot_wazdan,
    banner: null,
    localeId: 'trading.SL.WAZDAN_SLOT',
  },
  {
    kind: 'ROYAL_SLOT',
    thumbnail: img_slot_royal,
    banner: null,
    localeId: 'trading.SL.ROYAL_SLOT',
  },
  {
    kind: 'CC88_SLOT',
    thumbnail: img_slot_cc88,
    banner: null,
    localeId: 'trading.SL.CC88_SLOT',
  },
  {
    kind: 'TPG_SLOT',
    thumbnail: img_slot_tpg,
    banner: null,
    localeId: 'trading.SL.TPG_SLOT',
  },

  // // tplush
  // {
  //   kind: 'SL8',
  //   vendor: 'TPLUS',
  //   provider: 'PragmaticPlay',
  //   thumbnail: img_slot_pragmatic,
  //   localeId: 'trading.SL.SL8',
  // },
  // {
  //   kind: 'SL23',
  //   vendor: 'TPLUS',
  //   provider: 'CQ9',
  //   thumbnail: img_slot_cq9,
  //   localeId: 'trading.SL.SL23',
  // },
  // {
  //   kind: 'SL24',
  //   vendor: 'TPLUS',
  //   provider: 'HABANERO',
  //   thumbnail: img_slot_habanero,
  //   localeId: 'trading.SL.SL24',
  // },
  // {
  //   kind: 'SL37',
  //   vendor: 'TPLUS',
  //   provider: 'BNG',
  //   thumbnail: img_slot_boongo,
  //   localeId: 'trading.SL.SL37',
  // },
  // {
  //   kind: 'SL21',
  //   vendor: 'TPLUS',
  //   provider: 'MG_DASHUR_TC',
  //   thumbnail: img_slot_micro,
  //   localeId: 'trading.SL.SL21',
  // },
  // {
  //   kind: 'SL33',
  //   vendor: 'TPLUS',
  //   provider: 'GENESIS',
  //   thumbnail: img_slot_genesis,
  //   localeId: 'trading.SL.SL33',
  // },
  // {
  //   kind: 'SL14',
  //   vendor: 'TPLUS',
  //   provider: 'PGSOFT',
  //   thumbnail: img_slot_pgsoft,
  //   localeId: 'trading.SL.SL14',
  // },
  // {
  //   kind: 'SL51',
  //   vendor: 'TPLUS',
  //   provider: 'PLAYNGO',
  //   thumbnail: img_slot_playngo,
  //   localeId: 'trading.SL.SL51',
  // },
  // {
  //   kind: 'SL10',
  //   provider: 'iSOFTBET',
  //   thumbnail: img_slot_isoftbet,
  //   localeId: 'trading.SL.SL10',
  // },
  // {
  //   kind: 'SL40',
  //   provider: 'EVOPLAY',
  //   thumbnail: img_slot_evoplay,
  //   localeId: 'trading.SL.SL40',
  // },
  // {
  //   kind: 'SL36',
  //   provider: 'PLAYSON_NEW',
  //   thumbnail: img_slot_playson,
  //   localeId: 'trading.SL.SL36',
  // },
  // {
  //   kind: 'SL29',
  //   provider: 'PLAYSTAR',
  //   thumbnail: img_slot_playstar, //PS_SLOT와 중복
  //   localeId: 'trading.SL.SL29',
  // },
  // {
  //   kind: 'SL41',
  //   provider: 'DREAMTECH',
  //   thumbnail: img_slot_dreamtech,
  //   localeId: 'trading.SL.SL41',
  // },
  // {
  //   kind: 'SL19',
  //   provider: 'RTG',
  //   thumbnail: img_slot_rtg,
  //   localeId: 'trading.SL.SL19',
  // },
  // {
  //   kind: 'sL11',
  //   provider: 'StarGame',
  //   thumbnail: img_slot_star,
  //   localeId: 'trading.SL.SL11',
  // },
  // {
  //   kind: 'SL44',
  //   provider: 'AG_NEW',
  //   thumbnail: img_slot_xin,
  //   localeId: 'trading.SL.SL44',
  // },
  // {
  //   kind: 'SL47',
  //   provider: 'THE_SHOW',
  //   thumbnail: img_slot_show,
  //   localeId: 'trading.SL.SL47',
  // },
  // {
  //   kind: 'SL34',
  //   provider: 'TPG',
  //   thumbnail: img_slot_tpg,
  //   localeId: 'trading.SL.SL34',
  // },
  // {
  //   kind: 'SL32',
  //   provider: 'TTG',
  //   thumbnail: img_slot_toptrend,
  //   localeId: 'trading.SL.SL32',
  // },
  // {
  //   kind: 'SL15',
  //   provider: 'BBIN',
  //   thumbnail: img_slot_bbin,
  //   localeId: 'trading.SL.SL15',
  // },
  // {
  //   kind: 'SL6',
  //   provider: 'BBTECH',
  //   thumbnail: img_slot_bbtech,
  //   localeId: 'trading.SL.SL6',
  // },
  // {
  //   kind: 'SL30',
  //   provider: 'GAMEART',
  //   thumbnail: img_slot_gameart,
  //   localeId: 'trading.SL.SL30',
  // },
]
