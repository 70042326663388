/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import styled from '@emotion/styled'

import { Fragment } from 'react'

export const Button2 = styled.button`
  background: black;
`
const Error = () => (
  <Fragment>
    <div css={{ textAlign: 'center', display: 'block' }}>
      <div css={{ fontSize: '9em', userSelect: 'none' }}>😭</div>
      <p css={{ color: 'grey', fontSize: '3em', textAlign: 'center' }}>Ooooops!</p>
      <p>This page doesn&apos;t exist anymore.</p>
    </div>
  </Fragment>
)

export default Error
