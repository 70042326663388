/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Grid, Typography } from '@mui/material'
import { top_baseball, top_basketball, top_football, top_icehockey, top_volleyball } from '~/assets/images/sports'
import { Schema$Prematch } from '~/v2/interface/st-schema'
import useRootStore from '~/store/useRootStore'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { getPeriodName, getTeamName } from '~/game/st-util'
import { UPDATE_FIELD_TYPE } from '~/store/sportsStore/sports-util'
import { SPORT_ID } from '~/v2/interface/st-types'

interface Props {
  desktop: boolean
  prematch: Schema$Prematch
}

function InplayFixtureTrack({ desktop, prematch }: Props) {
  const [currentPrematch, setCurrentPrematch] = useState<Schema$Prematch>(prematch)

  const { sportsStore } = useRootStore()
  const { lang } = sportsStore

  useOnMount(() => {
    setCurrentPrematch(prematch)

    const { FixtureId } = prematch
    sportsStore.addTrackController(FixtureId, (fixture: Schema$Prematch, type: UPDATE_FIELD_TYPE) => {
      setCurrentPrematch(fixture)
    })
  })

  useOnUnmount(() => {
    const { FixtureId } = prematch
    sportsStore.deleteTrackController(FixtureId)
  })

  const homeTeamName = getTeamName(currentPrematch, '1', lang)
  const awayTeamName = getTeamName(currentPrematch, '2', lang)

  const startDate = new Date(currentPrematch.StartDate)
  const dateOfMatch = startDate.toLocaleString()

  let txtPeriod = ''
  let homeScore = null
  let awayScore = null
  if (currentPrematch.Scoreboard) {
    const { CurrentPeriod } = currentPrematch.Scoreboard
    txtPeriod = getPeriodName(currentPrematch.SportId, CurrentPeriod)

    homeScore =
      _.find(currentPrematch.Scoreboard.Results, o => {
        return o.Position === '1'
      })?.Value || null
    awayScore =
      _.find(currentPrematch.Scoreboard.Results, o => {
        return o.Position === '2'
      })?.Value || null
  }

  let imgAsset = null
  let txtColor = '#d6d6d6'
  let bgColor = '#080f1c'
  switch (prematch.SportId) {
    case SPORT_ID.Football:
      imgAsset = top_football
      bgColor = '#1d4118'
      break
    case SPORT_ID.Basketball:
      imgAsset = top_basketball
      bgColor = '#8a1c1b'
      break
    case SPORT_ID.Baseball:
      imgAsset = top_baseball
      bgColor = '#43582b'
      break
    case SPORT_ID.Volleyball:
      imgAsset = top_volleyball
      bgColor = '#034b6e'
      break
    case SPORT_ID.IceHockey:
      imgAsset = top_icehockey
      bgColor = '#143e61'
      break
  }

  return (
    <Box
      key="date"
      sx={{
        width: '100%',
        height: '310px',
        p: 3,
        background: `#182f49 url(${imgAsset}) center no-repeat`,
        backgroundSize: 'cover',
      }}
    >
      <Grid container sx={{ background: bgColor }}>
        <Grid item xs={5} display="flex" alignItems="center" justifyContent={desktop ? 'right' : 'center'}>
          <Typography sx={{ color: txtColor, fontSize: '16px' }}>{homeTeamName}</Typography>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
          <Typography sx={{ color: '#838485', fontSize: '16px' }}>vs</Typography>
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center" justifyContent={desktop ? 'left' : 'center'}>
          <Typography sx={{ color: txtColor, fontSize: '14px' }}>{awayTeamName}</Typography>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: '14px',
            color: txtColor,
            background: bgColor,
            pl: '6px',
            pr: '6px',
          }}
        >
          {dateOfMatch}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: '14px',
            color: txtColor,
            background: bgColor,
            pl: 1,
            pr: 1,
          }}
        >
          {txtPeriod}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: '14px',
            color: txtColor,
            background: bgColor,
            pl: 1,
            pr: 1,
            mr: 4,
          }}
        >
          {homeScore}
        </Typography>
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: '14px',
            color: txtColor,
            background: bgColor,
            pl: 1,
            pr: 1,
          }}
        >
          {awayScore}
        </Typography>
      </Box>
    </Box>
  )
}

export default observer(InplayFixtureTrack)
