import { useState, useEffect } from 'react'
import * as _ from 'lodash'
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  Paper,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'
import { styled } from '@mui/material/styles'

// icon
import CloseIcon from '@mui/icons-material/Close'

import ImplMarketDetail from './ImplMarketDetail'
import { useMediaQuery } from 'react-responsive'
import useRootStore from '~/store/useRootStore'
import { MEDIA_DESKTOP_WIDTH } from '~/constants'
import { ModalKeys } from '~/store/globalStore'
import { MobileDrawerStyle } from '~/styles/modal.style'
import ContainerImplMarketDetail from './ContainerImplMarketDetail'
import { SPORTS_LOCALE } from '~/game/st-util'

const BetSlip = styled('div')(({ theme }) => ({
  //height: '100%',
  '& .btn_open': {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1200,
    width: '64px',
    height: '42px',
  },
}))

const drawerBleeding = 42

const BetSlipTop = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -drawerBleeding,
  right: 0,
  left: 0,
  visibility: 'visible',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  backgroundColor: '#f1f1f1',
  //backgroundColor: theme.buttons.success,
}))

function MobileMarketDetail() {
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, sportsStore } = useRootStore()

  const { desktop } = globalStore.decideMedia(isDesktop)
  const { currentKey, currentValue } = globalStore
  const { flight, lang } = sportsStore

  const modalKey = ModalKeys.sport_market
  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex > -1
  let visible = visibleOnDemand && !desktop

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  return (
    <Drawer
      anchor="right"
      open={visible}
      onClose={() => {}}
      // onOpen={() => {}}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          height: '100%',
          overflow: 'visible',
          background: '#051120',
        },
      }}
    >
      <Box sx={{ marginTop: '48px' }}>
        <AppBar
          sx={{
            position: 'relative',
            background: '#080f1c',
            padding: '11px 0',
            boxShadow: 'none',
          }}
        >
          <Typography sx={{ textAlign: 'center' }} variant="h6" component="div">
            {flight === 'inplay'
              ? SPORTS_LOCALE.live_betting.name[lang]
              : SPORTS_LOCALE.more_betting.name[lang]}
          </Typography>
          <IconButton
            sx={{ position: 'absolute', top: '5px', right: '12px' }}
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon sx={{ fill: '#d1d9e3' }} />
          </IconButton>
        </AppBar>
      </Box>
      <Box sx={{ height: 'auto', overflowY: 'auto' }}>
        <ContainerImplMarketDetail></ContainerImplMarketDetail>
      </Box>
    </Drawer>
  )
}

export default observer(MobileMarketDetail)
