import { jsx, css, ThemeProvider, Theme, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import {
  Tab,
  Tabs,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  LinearProgress,
} from '@mui/material'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
//import { GlobalStyle } from '@shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import {
  Schema$StatsGameItem,
  Schema$StatsGameTrackData,
  IPartnerStatsGame,
} from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { getGainLossColor, numberWithCommas, sleep } from '~/utils/utils'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { ALL_GAME_INFO } from '@interface/config'
import { a11yProps } from '@shared/Common'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: number
  date: Date
  deposit: number
  withdraw: number
  deposit_withdraw_offset: number
  bet_amount: number
  draw_amount: number
  win_amount: number
  win_lose_offset: number
  less_commission: number
  less_losing: number
  commission: number
  self_losing: number
  status: string
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>일자</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>입금액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>출금액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>입출금 차액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>무승부</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>당첨액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅손익</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>하위롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>하위루징</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>루징</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData }) {
  const { row } = props

  const isSumRow = row.status === 'sum'
  const styleSumRow = isSumRow ? { background: '#d8e4ff' } : {}

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...styleSumRow }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}>
            {isSumRow ? '합' : moment(row.date).format('YYYY-MM-DD')}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>{numberWithCommas(row.deposit)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>{numberWithCommas(row.withdraw)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(row.deposit_withdraw_offset) }}
          >
            {numberWithCommas(row.deposit_withdraw_offset)}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>
            {numberWithCommas(Math.floor(row.bet_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>
            {numberWithCommas(Math.floor(row.draw_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>
            {numberWithCommas(Math.floor(row.win_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.win_lose_offset)) }}
          >
            {numberWithCommas(Math.floor(row.win_lose_offset))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>
            {numberWithCommas(Math.floor(row.less_commission))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.less_losing)) }}
          >
            {numberWithCommas(Math.floor(row.less_losing))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px' }}>
            {numberWithCommas(Math.floor(row.commission))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.self_losing)) }}
          >
            {numberWithCommas(Math.floor(row.self_losing))}
          </Typography>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

type TrackData = Schema$StatsGameTrackData
const ItemPerPage = 10

function PartnerIntergratedStatsGame({ location }) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { main, page } = query

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [sum, setSum] = useState<Schema$StatsGameItem | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchPeriod, setSearchPeriod] = useState('')
  const [searchBegin, setSearchBegin] = useState<Date>(start)
  const [searchEnd, setSearchEnd] = useState<Date>(new Date())

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { option } = globalStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups } = option || {}

  let globalWidth = {}
  if (desktop) {
    globalWidth = {
      minWidth: '1330px',
    }
  }

  const GlobalStyle = (theme: Theme) => css`
    html,
    body {
      background: ${theme.colors.subBg};
    }
    body {
      ${globalWidth}
    }
  `

  const tab_list: {
    group: string
    name: string
    category?: string
    source?: string
  }[] = []
  tab_list.push({ group: 'ALL', name: '전체' })
  for (let i = 0; i < ALL_GAME_INFO.length; i++) {
    const found = _.find(open_trading_groups, o => {
      return o.trading_group === ALL_GAME_INFO[i].group
    })
    if (found) {
      tab_list.push(ALL_GAME_INFO[i])
    }
  }

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  const startTab = Math.max(
    0,
    _.findIndex(tab_list, o => {
      return o.group === main
    }),
  )
  const startPage = page ? Number(page) : 1

  if (startTab !== tabPage.tab || startPage !== tabPage.page) {
    setTabPage({ tab: startTab, page: startPage })
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-STATS-GAME')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.stats_game)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const fetch = async (params: IPartnerStatsGame.Params) => {
    setLoading(true)

    try {
      const { data, sum } = await apiPartner.getStatsGame(params)
      setTrack(data)
      setSum(sum)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetch({
      trading_group: tab_list[tabPage.tab].group,
      search_begin: searchBegin.toString(),
      search_end: searchEnd.toString(),

      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    })
  }, [tabPage])

  const handleChangeTab = (event, newValue) => {
    history.push(`/partner/stats-game/search?main=${tab_list[newValue].group}`)
  }

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
    setSearchPeriod('')
  }

  const onClickSearchPeriod = newValue => {
    setSearchPeriod(searchPeriod === newValue ? '' : newValue)
    const now = new Date()
    switch (newValue) {
      case 'today':
        setSearchBegin(now)
        setSearchEnd(now)
        break
      case '7':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6))
        setSearchEnd(now)
        break
      case '15':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14))
        setSearchEnd(now)
        break
    }
  }
  const onClickSearch = () => {
    fetch({
      trading_group: tab_list[tabPage.tab].group,
      search_begin: searchBegin.toString(),
      search_end: searchEnd.toString(),

      offset: 0,
      limit: ItemPerPage,
    })
  }

  const tabItems = []
  for (let i = 0; i < tab_list.length; i++) {
    tabItems.push(
      <Tab
        label={tab_list[i].name}
        {...a11yProps(i)}
        style={{ fontSize: '14px' }}
        key={tab_list[i].group}
      />,
    )
  }

  const rows = []
  if (track) {
    function addRow(id: number, date: Date, data: Schema$StatsGameItem, isSum: boolean) {
      if (data) {
        const row: RowData = {
          id: id,
          date: date,
          deposit: data.deposit,
          withdraw: data.withdraw,
          deposit_withdraw_offset: data.deposit_withdraw_offset,
          bet_amount: data.bet_amount,
          draw_amount: data.draw_amount,
          win_amount: data.win_amount,
          win_lose_offset: data.win_lose_offset,
          less_commission: data.less_commission,
          less_losing: data.less_losing,
          commission: data.commission,
          self_losing: data.self_losing,
          status: isSum ? 'sum' : 'normal',
        }
        rows.push(<Row key={row.id} row={row}></Row>)
      } else {
        const row: RowData = {
          id: id,
          date: date,
          deposit: 0,
          withdraw: 0,
          deposit_withdraw_offset: 0,
          bet_amount: 0,
          draw_amount: 0,
          win_amount: 0,
          win_lose_offset: 0,
          less_commission: 0,
          less_losing: 0,
          commission: 0,
          self_losing: 0,
          status: 'normal',
        }
        rows.push(<Row key={row.id} row={row}></Row>)
      }
    }
    if (sum) {
      addRow(rows.length, null, sum, true)
    }
    const dateBegin = new Date(track.search_begin)
    const dateEnd = new Date(track.search_end)
    let date_iter = new Date(dateBegin.getFullYear(), dateBegin.getMonth(), dateBegin.getDate())
    const date_end = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate())
    while (date_iter.getTime() <= date_end.getTime()) {
      const found = _.find(track.items, o => {
        return o.date === moment(new Date(date_iter)).format('YYYY-MM-DD')
      })
      addRow(rows.length, new Date(date_iter), found, false)
      date_iter.setDate(date_iter.getDate() + 1)
    }
    if (sum) {
      addRow(rows.length, null, sum, true)
    }
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1330px',
          maxWidth: '1330px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>게임 통계</Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPage.tab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabItems}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '15' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('15')}
                >
                  15일
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '7' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('7')}
                >
                  1주
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === 'today' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('today')}
                >
                  오늘
                </Button>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
              <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
              <Table size="small" aria-label="collapsible table">
                {Head()}
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '4px' }}>
            <Typography sx={{ width: '100%', mt: 1, color: 'red', textAlign: 'right' }}>
              ※. 베팅액은 무승부 금액이 포함된 금액입니다.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedStatsGame)
