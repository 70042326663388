import { css, keyframes, Theme } from '@emotion/react'
//import styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import { Pagination, ImageListItem, Box } from '@mui/material'
const channelSymbol = process.env.REACT_APP_CHANNEL || ''

export const FontXLarge = '2.0rem'
export const FontTitle = '1.6rem'
export const FontLarge = '1.4rem'
export const FontMedium = '1.2rem'
export const FontSmall = '1rem'
export const FontTiny = '0.8rem'

export const Show = css`
  display: block;
`
export const Hide = css`
  display: none;
`
export const opacityAni = keyframes`
  0% {
    opacity:.4;
  }
  100% {
    opacity:0;
  }
`
export const ShowAni = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`
export const HideAni = keyframes`
  0% {
    opacity:1;
  }
  90% {
    opacity:0;
  }
  100% {
    opacity:0;
    display: none;
  }
`
export const ShowPopoverAni = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:.4;
  }
`
export const ModalHeightAni = keyframes`
  0% {
     height: auto;
  }
  100%  {
    height: 100%;
    }
`
export const ShowHidden = keyframes`
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
`

export const ChatArea = css`
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;
`
// 보여지는 글자수 제한
export const TxtLimit = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
export const TxtLimit2 = css`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 2.4rem;
  line-height: 1.2rem;
`
export const TxtLimit3 = css`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.6rem;
  line-height: 1.2rem;
`
// /////////////////////////////////////////// media qurey /////////////////////////////////////////////
const breakpoints = [320, 410, 568, 768, 992, 1200]
const breakpointsH = [568, 730, 810, 1024, 1366]

export const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)
export const mqMin = breakpoints.map(bp => `@media (min-width: ${bp}px)`)

export const mqH = breakpointsH.map(bp => `@media (max-height: ${bp}px)`)
export const mqMinH = breakpointsH.map(bp => `@media (min-height: ${bp}px)`)

// /////////////////////////////////////////// button /////////////////////////////////////////////
// 공통 버튼 스타일
export const ButtonStyles = (props: any) =>
  css`
    z-index: 1;
    position: relative;
    /* height: 45px; */
    padding: 3px 20px;
    line-height: 2.25rem;
    text-align: center;
    border: none;
    border-radius: 4px;
    box-shadow: none;
  `
export const ButtonHoverAni = keyframes`
  0% {
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  }
  35% {
    transform: translate3d(-50%, -50%, 0) scale3d(50, 50, 50);
  }
  100%  {
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  }
`

//////////////////////////////////////// Loading ////////////////////////////////////////
export const LoadSpinWrap = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  text-align: center;
  position: relative;
  height: 4rem;
`
const SpinAni = keyframes`
50% {
    transform: rotate(360deg) scale(0.9) ;
    border-width: 3.5px;
  }
  100% {
    transform: rotate(720deg) scale(1);
    border-width: 3px;
  }
`
export const LoadSpin = styled('div')`
  /* display: block;
  margin: 0 auto; */
  width: 3rem;
  height: 3rem;
  border: 3px solid ${props => props.theme.colors.primary};
  border-top-color: ${props => props.theme.colors.primary};
  border-radius: 50%;
  animation: ${SpinAni} 1s ease-in-out infinite;
`
// export const Loaded = styled('div')`
//   position: relative;
//   width: 100%;
//   height: 35px;
//   display: block;
//   margin: 0 auto;
//   line-height: 3rem;
//   text-align: center;
//   background: ${props => props.theme.colors.mainBg};
//   transform: translate(-50, -50%);
//   &::before {
//     content: '';
//     display: block;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     width: 16px;
//     border: ${props => props.theme.borders.default};
//     transform: translate(-50%, -50%);
//   }
// `

export const TitleDivider = (theme: Theme) => css`
  border-bottom-width: 2px;
  border-color: #1e2b3d;
  opacity: 1;
`

// /////////////////////////////////////////// button /////////////////////////////////////////////
// 공통 탭 버튼 스타일
export const BasicTabs = (theme: Theme) => css`
  .basic_tab {
    border-bottom: 1px solid ${theme.tabs.tabBorder};
    font-size: 1.1rem;
    color: #747d8a;
    background: ${theme.tabs.default};
    padding: 0;

    &.Mui-selected {
      background: ${theme.tabs.tabBg};
      color: ${theme.tabs.tabActive};
    }
  }

  .MuiTabs-indicator {
    background-color: ${theme.tabs.tabActive};
  }
`
// 세로 탭 버튼 스타일
export const VerticalTabs = (theme: Theme) => css`
  .vertical_tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 52px;
    width: 100%;
    background-color: #16202f;
    font-size: 1.1rem;
    transition: all 0.3s linear;
    color: #747d8a;

    &:not(:first-of-type) {
      margin-top: -1px;
    }

    .symbol {
      display: flex;
      align-items: center;
    }
    .symbol svg {
      margin-right: 10px;
    }

    &.Mui-selected {
      background-color: #1e2b3d;
      color: #43c8de;
    }
    &.Mui-selected .symbol svg {
      color: #43c8de;
    }
  }

  .MuiTabs-indicator {
    background-color: #43c8de;
  }
`

export const CheckboxStyle = (theme: Theme) => css`
  color: #d1d9e3;

  &.Mui-checked {
    color: #289caf;
  }
`

export const PaginationStyle = styled(Pagination)(({ theme }) => ({
  margin: '48px 0',
  '& .MuiPagination-ul': {
    justifyContent: 'center',
  },
  '& .MuiButtonBase-root': {
    margin: 0,
    border: theme.pagination.border,
    borderRadius: 0,
    backgroundColor: theme.pagination.default,
    color: '#747D8A',
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: theme.pagination.active,
    color: theme.pagination.txtActive,

    '&:hover': {
      backgroundColor: theme.pagination.hover,
    },
  },
}))

// hover시 이미지 바뀌는 타입
export const ImageListItemStyle = styled(ImageListItem)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'none',

  '& .img_wrap': {
    position: 'relative',
    borderRadius: channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk' ? '10px' : 0,
    [mq[4]]: {
      borderRadius: channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk' ? '6px' : 0,
    },
    overflow: 'hidden',
  },

  '& .img_wrap .border_line': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'none',
    backgroundSize: '100%',
    opacity: 0,
    transition: 'none',
    border: channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk' ? 'none' : '2px solid transparent',
    zIndex: 0,
  },

  '& .MuiImageListItemBar-root': {
    textAlign: 'center',
    backgroundColor: '#050911',
    color: '#FFFFFF',
    fontWeight: 'normal',
    border: theme.borders.itemBar,
  },

  ':hover': {
    transform: 'none',
  },

  ':hover .img_wrap .border_line': {
    borderColor: theme.colors.themeActive,
    opacity: 1,
  },

  ':hover .MuiImageListItemBar-root': {
    color: theme.colors.themeActive,
  },
}))

// hover시 위로 움직이는 타입
export const ImageListItemStyle2 = styled(ImageListItem)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.2s ease-in',

  '& .img_wrap': {
    position: 'relative',
    borderRadius: '8px',
    overflow: 'hidden',
  },

  '& .img_wrap .border_line': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.2s linear',
    border: '2px solid transparent',
    borderRadius: '8px',
    zIndex: 1,
  },

  ':hover': {
    transform: 'translateY(-10px)',
  },

  ':hover .img_wrap .border_line': {
    borderColor: theme.colors.themeActive,
    opacity: 1,
  },
}))

export const DarkenBg = styled(Box)(({ theme }) => ({
  //paddingBottom: 1,
  background: theme.colors.darken,
  //minHeight: 376,
}))
