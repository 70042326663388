import { Theme } from '@mui/material'
import { Schema$FilteredMarket, Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import { getBaselineArranged, makeFilteredMarkets } from '../../game/st-util'
import DetailOddsBox1X2 from './DetailOddsBox1X2'
import DetailOddsBox12 from './DetailOddsBox12'
import DetailOddsBoxBaseline from './DetailOddsBoxBaseline'
import DetailOddsBoxList from './DetailOddsBoxList'
import DetailOddsBoxN2W from './DetailOddsBoxN2W'
import DetailOddsBoxN3W from './DetailOddsBoxN3W'
import { SportMarketFilter } from '~/v2/interface/market_group_types'
import { BASEBALL_PREMATCH_MARKET_GROUP } from '~/v2/interface/prematch_market_group_baseball'
import { BASKETBALL_PREMATCH_MARKET_GROUP } from '~/v2/interface/prematch_market_group_basketball'
import { FOOTBALL_PREMATCH_MARKET_GROUP } from '~/v2/interface/prematch_market_group_football'
import { ICEHOCKEY_PREMATCH_MARKET_GROUP } from '~/v2/interface/prematch_market_group_icehockey'
import { VOLLEYBALL_PREMATCH_MARKET_GROUP } from '~/v2/interface/prematch_market_group_volleyball'
import { FOOTBALL_INPLAY_MARKET_GROUP } from '~/v2/interface/inplay_market_group_football'
import { BASKETBALL_INPLAY_MARKET_GROUP } from '~/v2/interface/inplay_market_group_basketball'
import { BASEBALL_INPLAY_MARKET_GROUP } from '~/v2/interface/inplay_market_group_baseball'
import { VOLLEYBALL_INPLAY_MARKET_GROUP } from '~/v2/interface/inplay_market_group_volleyball'
import { ICEHOCKEY_INPLAY_MARKET_GROUP } from '~/v2/interface/inplay_market_group_icehockey'

export const SPORT_MARKET_FILTER: SportMarketFilter[] = [
  {
    sportId: 6046,
    sportName: { en: 'Football', ko: '축구' },
    prematch: FOOTBALL_PREMATCH_MARKET_GROUP,
    inplay: FOOTBALL_INPLAY_MARKET_GROUP,
    betNameMeansAsItIs: [
      '134',
      '145',
      '146',
      '113',
      '9',
      '72',
      '456',
      '73',
      '149',
      '150',
      '17',
      '23',
      '22',
      '198',
      '199',
      '97',
      '96',
      '4',
      '5',
      '6',
      '7',
      '128',
      '239',
      //inplay
    ],
    betNameMeansPosition: [
      '16',
      // inplay
      '59',
      '56',
      '820',
      '579',
      '81',
    ],
  },
  {
    sportId: 48242,
    sportName: { en: 'Basketball', ko: '농구' },
    prematch: BASKETBALL_PREMATCH_MARKET_GROUP,
    inplay: BASKETBALL_INPLAY_MARKET_GROUP,
    betNameMeansAsItIs: [],
    betNameMeansPosition: ['1899'],
  },
  {
    sportId: 154914,
    sportName: { en: 'Baseball', ko: '야구' },
    prematch: BASEBALL_PREMATCH_MARKET_GROUP,
    inplay: BASEBALL_INPLAY_MARKET_GROUP,
    betNameMeansAsItIs: [],
    betNameMeansPosition: ['563'],
  },
  {
    sportId: 154830,
    sportName: { en: 'Volleyball', ko: '배구' },
    prematch: VOLLEYBALL_PREMATCH_MARKET_GROUP,
    inplay: VOLLEYBALL_INPLAY_MARKET_GROUP,
    betNameMeansAsItIs: [],
    betNameMeansPosition: ['329', '330', '331', '332'],
  },
  {
    sportId: 35232,
    sportName: { en: 'Ice Hockey', ko: '아이스 하키' },
    prematch: ICEHOCKEY_PREMATCH_MARKET_GROUP,
    inplay: ICEHOCKEY_INPLAY_MARKET_GROUP,
    betNameMeansAsItIs: ['128', '149', '150'],
    betNameMeansPosition: ['56', '341', '59'],
  },
]

export interface DetailMarketNodeData {
  market: Schema$FilteredMarket
  node: React.ReactNode
  content?: React.ReactNode
}

export function DetailOddsFactory(
  unifiedName: string,
  theme: Theme,
  prematch: Schema$Prematch,
  market: Schema$Market,
  baseline_size: number,
  enabled_baseline_zero: boolean,
): DetailMarketNodeData[] {
  switch (unifiedName) {
    case '1X2': {
      const filteredMarket = makeFilteredMarkets(market, enabled_baseline_zero)
      const items: DetailMarketNodeData[] = []
      const oddsProps = {
        theme,
        prematch,
        market: market,
      }
      items.push({
        market: filteredMarket[0],
        node: <DetailOddsBox1X2 key={market.Id} {...oddsProps}></DetailOddsBox1X2>,
      })
      return items
    }
    case '12': {
      const filteredMarket = makeFilteredMarkets(market, enabled_baseline_zero)
      const items: DetailMarketNodeData[] = []
      const oddsProps = {
        theme,
        prematch,
        market: market,
      }
      items.push({
        market: filteredMarket[0],
        node: <DetailOddsBox12 key={market.Id} {...oddsProps}></DetailOddsBox12>,
      })
      return items
    }
    case 'HDP':
    case 'UO':
      {
        const filteredMarket = makeFilteredMarkets(market, enabled_baseline_zero)
        const { all } = getBaselineArranged(filteredMarket, baseline_size)
        if (all.length > 0) {
          const items: DetailMarketNodeData[] = []
          for (const el of all) {
            const oddsProps = {
              theme,
              isTop: true,
              type: 'detail',
              unifiedName,
              prematch,
              market: el,
              expand: false,
              expanded: false,
            }
            items.push({
              market: el,
              node: (
                <DetailOddsBoxBaseline key={el.BaseLine} {...oddsProps}></DetailOddsBoxBaseline>
              ),
            })
          }
          return items
        }
      }
      break
    case 'LIST1':
      {
        const filterMarket = makeFilteredMarkets(market, enabled_baseline_zero)
        if (filterMarket.length > 0) {
          const items: DetailMarketNodeData[] = []
          for (const el of filterMarket) {
            const oddsProps = {
              theme,
              column: 1,
              unifiedName,
              prematch,
              market: el,
            }
            items.push({
              market: el,
              node: <DetailOddsBoxList key={el.Id} {...oddsProps}></DetailOddsBoxList>,
            })
          }
          return items
        }
      }
      break
    case 'LIST2':
      {
        const filterMarket = makeFilteredMarkets(market, enabled_baseline_zero)
        if (filterMarket.length > 0) {
          const items: DetailMarketNodeData[] = []
          for (const el of filterMarket) {
            const oddsProps = {
              theme,
              column: 2,
              unifiedName,
              prematch,
              market: el,
            }
            items.push({
              market: el,
              node: <DetailOddsBoxList key={el.Id} {...oddsProps}></DetailOddsBoxList>,
            })
          }
          return items
        }
      }
      break
    case 'LIST3':
      {
        const filterMarket = makeFilteredMarkets(market, enabled_baseline_zero)
        if (filterMarket.length > 0) {
          const items: DetailMarketNodeData[] = []
          for (const el of filterMarket) {
            const oddsProps = {
              theme,
              column: 3,
              unifiedName,
              prematch,
              market: el,
            }
            items.push({
              market: el,
              node: <DetailOddsBoxList key={el.Id} {...oddsProps}></DetailOddsBoxList>,
            })
          }
          return items
        }
      }
      break
    case 'NUEO': // 베이스라인/언더/확실/오버
    case 'N12N': // 베이스라인/홈/원정/없음
      {
        const filterMarket = makeFilteredMarkets(market, true)
        if (filterMarket.length > 0) {
          const items: DetailMarketNodeData[] = []
          for (const el of filterMarket) {
            const oddsProps = {
              theme,
              isTop: true,
              type: 'detail',
              unifiedName,
              prematch,
              market: el,
              expand: false,
              expanded: false,
            }
            items.push({
              market: el,
              node: <DetailOddsBoxN3W {...oddsProps}></DetailOddsBoxN3W>,
            })
          }
          return items
        }
      }
      break
    case 'N12': // 점수/홈/원정
    case 'NYN': // 점수/네/아니오
      {
        const filterMarket = makeFilteredMarkets(market, true)
        if (filterMarket.length > 0) {
          const items: DetailMarketNodeData[] = []
          for (const el of filterMarket) {
            const oddsProps = {
              theme,
              isTop: true,
              type: 'detail',
              unifiedName,
              prematch,
              market: el,
              expand: false,
              expanded: false,
            }
            items.push({
              market: el,
              node: <DetailOddsBoxN2W {...oddsProps}></DetailOddsBoxN2W>,
            })
          }
          return items
        }
      }
      break
  }
  return []
}
