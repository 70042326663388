// import { MarketGroup } from './market_group_types'
// export const FOOTBALL_INPLAY_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const FOOTBALL_INPLAY_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Asia Lines', ko: '아시아 라인' },
    markets: [
      {
        id: 64,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 1st Period', ko: '핸디캡 (전반전) *' },
      },
      {
        id: 836,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over 1st Period', ko: '총 득점 (전반전) *' },
      },
      {
        id: 3,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap', ko: '핸디캡 *' },
      },
      {
        id: 342,
        unifiedName: 'HDP',
        name: {
          en: 'Asian Handicap Including Overtime',
          ko: '핸디캡 (연장포함) *',
        },
      },
      {
        id: 835,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over', ko: '총 득점 *' },
      },
      {
        id: 1061,
        unifiedName: 'UO',
        name: {
          en: 'Asian Under/Over Including Overtime',
          ko: '총 득점 (연장포함) *',
        },
      },
      {
        id: 1552,
        unifiedName: 'UO',
        name: {
          en: 'Asian Under/Over Corners',
          ko: '총 코너킥 *',
        },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: 'Corners/Cards', ko: '코너킥/카드' },
    markets: [
      {
        id: 305,
        unifiedName: 'NUEO',
        name: {
          en: 'Corners - Under/Exactly/Over',
          ko: '총 코너킥 3Way',
        },
      },
      {
        id: 11,
        unifiedName: 'UO',
        name: { en: 'Total Corners', ko: '총 코너킥' },
      },
      {
        id: 880,
        unifiedName: 'NUEO',
        name: {
          en: 'First Half Corners - Under/Exactly/Over',
          ko: '총 코너킥 3Way (전반전)',
        },
      },
      {
        id: 129,
        unifiedName: 'UO',
        name: { en: 'Under/Over Corners - 1st Half', ko: '총 코너킥 (전반전)' },
      },
      {
        id: 820,
        unifiedName: 'N12N',
        name: { en: 'Race To Corners', ko: '선취 코너킥' },
      },
      {
        id: 579,
        unifiedName: 'LIST2',
        name: {
          en: 'Next Corner',
          ko: '다음 코너킥',
        },
      },
      {
        id: 81,
        unifiedName: 'LIST2',
        name: {
          en: 'Last Corner',
          ko: '마지막 코너킥',
        },
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: 'Goals', ko: '골' },
    markets: [
      {
        id: 59,
        unifiedName: 'LIST3',
        name: { en: 'Next Goal', ko: '다음 득점 팀' },
      },
      { id: 2, unifiedName: 'UO', name: { en: 'Under/Over', ko: '총 득점' } },
      {
        id: 56,
        unifiedName: 'LIST3',
        name: { en: 'Last Team To Score', ko: '마지막 득점 팀' },
      },
      {
        id: 5,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even', ko: '홀/짝 총 득점' },
      },
      {
        id: 17,
        unifiedName: 'LIST2',
        name: { en: 'Both Teams To Score', ko: '양팀 득점' },
      },
      {
        id: 6,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score', ko: '정확한 점수' },
      },
    ],
  },
  {
    groupId: 4,
    groupName: { en: '1st/2nd Halftime', ko: '전/후반전' },
    markets: [
      {
        id: 41,
        unifiedName: '1X2',
        name: { en: '1st Period Winner', ko: '승무패 (전반전)' },
      },
      {
        id: 21,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period', ko: '총 득점 (전반전)' },
      },
      {
        id: 9,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score 1st Period', ko: '정확한 점수 (전반전)' },
      },
      {
        id: 42,
        unifiedName: '1X2',
        name: { en: '2nd Period Winner', ko: '승무패 (후반전)' },
      },
    ],
  },
]
