/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import SubMenu from './SubMenu'
import { RouteMenu, ServiceMenus, GuideMenus, BankMenus, NoticeMenus, MyInfoMenus } from './RouteMenu'

interface Props {
  signedin: boolean
  onClick: (RouteMenu) => void
}

const UserSiderMenu: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const { signedin, onClick } = props

  return (
    <Nav className="flex-column">
      <SubMenu title={intl.formatMessage({ id: 'menu.sider.service' })} items={ServiceMenus} onClick={onClick} />
      <SubMenu title={intl.formatMessage({ id: 'menu.sider.guide' })} items={GuideMenus} onClick={onClick} />
      <SubMenu title={intl.formatMessage({ id: 'menu.sider.bank' })} items={BankMenus} onClick={onClick} />
      <SubMenu title={intl.formatMessage({ id: 'menu.sider.notice' })} items={NoticeMenus} onClick={onClick} />
      {signedin === true ? (
        <SubMenu title={intl.formatMessage({ id: 'menu.sider.myinfo' })} items={MyInfoMenus} onClick={onClick} />
      ) : null}
    </Nav>
  )
}

export default UserSiderMenu
