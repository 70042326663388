/* eslint-disable jsx-a11y/anchor-is-valid */
/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState, Fragment, useLayoutEffect } from 'react'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { Grid, Box, List, ListItem, Chip, Stack } from '@mui/material'
import moment from 'moment'

import apiPublic from '@services/api/public'
import { Schema$RealtimeTransferItem } from '@interface/common'
import { IRealtimeTransfer } from '@protocol/public'
import { numberWithCommas, fillZero, difficultUsername } from '@utils/utils'

function RealtimeDepositBox({}) {
  const intl = useIntl()
  const history = useHistory()

  const [transfers, setTransfers] = useState<Schema$RealtimeTransferItem[]>([])

  useEffect(() => {
    fetchRealtimeTransfer()
  }, [])

  const fetchRealtimeTransfer = async () => {
    try {
      const params: IRealtimeTransfer.Params = {
        kind: 'IN',
        limit: 10,
      }
      const { limit, total, items } = await apiPublic.getRealtimeTransfer(params)
      setTransfers([...items])
    } catch (err) {}
  }

  const listItems = []
  for (let i = 0; i < transfers.length && i < 5; i++) {
    const el = transfers[i]
    const username = difficultUsername(el.userId)
    const date = new Date(el.date)
    const dateAt = `${fillZero(2, date.getFullYear().toString())}-${fillZero(
      2,
      (date.getMonth() + 1).toString(),
    )}-${fillZero(2, date.getDate().toString())} ${fillZero(2, date.getHours().toString())}:${fillZero(
      2,
      date.getMinutes().toString(),
    )}`
    listItems.push(
      <ListItem key={i + 1}>
        <Grid container>
          <Grid item xs={5} className="date">
            {dateAt}
          </Grid>
          <Grid item xs={4} className="amount">
            {numberWithCommas(el.volume)}원
          </Grid>
          <Grid item xs={3} className="name">
            <span className="content ellipsis">{username}</span>
          </Grid>
        </Grid>
      </ListItem>,
    )
  }

  return (
    <Box className="main_list">
      <div className="main_title">
        <span>{intl.formatMessage({ id: 'main.realtime-deposit' })}</span>
      </div>

      <List>{listItems}</List>
    </Box>
  )
}

export default RealtimeDepositBox
