import { observable, computed, action, makeObservable } from 'mobx'
import * as _ from 'lodash'
import Swal, { SweetAlertOptions, SweetAlertPosition } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { StoreExt } from '@utils/reactExt'
import { LOCALSTORAGE_KEYS } from '@constants/index'
import {
  Schema$OpenTradingGroup,
  Schema$OpenTradingKind,
  IPublicSettings,
  INotices,
  Schema$PublicOption,
} from '@protocol/public'
import { Schema$NoticeItem } from '@interface/common'
import { authStore, liveStore, sportsLiveStore, sportsStore } from '@store/index'
import { Schema$PartnerMemeberItem } from '@protocol/partner'

import { popupCenterGame } from '~/utils/utils'
import apiGame from '@services/api/game'

import { detect } from 'detect-browser'
import { ALL_GAME_INFO } from '~/v2/interface/config'
import { History } from 'history'
import { getDeviceType } from '~/utils'
import { makeBrowserData } from '~/game/util'
import { IntlShape } from 'react-intl'

const browser = detect()

const MySwal = withReactContent(Swal)

export type MainTheme = 'dark' | 'light'
export type OpenTradingGroup = Schema$OpenTradingGroup
export type OpenTradingKind = Schema$OpenTradingKind

export enum DialogType {
  TIMEOUT,
  WAIT,
  SINGLE,
  CHOICE,
}

type DialogCallback = () => void

export interface DialogParams {
  title?: string
  custom?: React.ReactNode
  text?: string
  html?: string // html 이 설정되면 text 는 무시된다.

  singlePress?: string

  negativePress?: string
  positivePress?: string

  arg1?: string
  arg2?: string

  callbackPositive?: DialogCallback
  callbackNegative?: DialogCallback

  timeout?: number
  position?: SweetAlertPosition
}

export interface DialogData extends DialogParams {
  id: string
  type: DialogType
  callbackClose: () => void
}

export type DialogQueue = DialogData[]

export interface DialogLocaleData {
  type: DialogType
  params: DialogParams
}

export const ModalKeys = {
  none: 'none',
  login: 'login',
  signup: 'signup',
  message: 'message',
  coupon: 'coupon',
  menual_coin: 'menual_coin',
  menual_wonp: 'menual_wonp',
  menual_onepay: 'menual_onepay',
  menual_onepay_coin: 'menual_onepay_coin',
  betting_record: 'betting_record',
  commission: 'commission',
  qna: 'qna',
  alliance: 'alliance',
  myinfo: 'myinfo',
  point: 'point',
  guide: 'guide',

  write_qna_personal: 'write_qna_personal',
  write_qna_partner: 'write_qna_partner',

  slot: 'slot',
  msl: 'msl',
  sport_market: 'sport_market',
  sport_betting_cart: 'sport_betting_cart',
}

export const PartnerModeKeys = {
  none: 0,
  dashboard: 1,
  members: 2,
  members_concurrent: 3,
  betting_record: 4,
  charge_record: 5,
  stats_game: 6,
  balance_account_record: 7,
  balance_account_request: 8,
  partner_coupon: 9,
  coupon_record: 10,
  unknown: 11,
}

export interface NoticeTrackData {
  limit: number
  offset: number
  total: number
  items: Schema$NoticeItem[]
}

export const MEDIA_MODE = {
  RESPONSIVE: 'RESPONSIVE',
  STATIC: 'STATIC',
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
}

export const MEDIA_TYPE = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
}

export interface PopupStorage {
  date?: string
  ids?: []
}

export class GlobalStore extends StoreExt {
  // client data
  @observable siderVisible: boolean = false

  @observable currentKey = ModalKeys.none
  @observable currentValue: any | number | null = null

  @observable somethingPopup: boolean = false

  @observable partnerMode: number = PartnerModeKeys.none

  @observable popupLayoutProps: object = {}

  @observable
  navOpenKeys: string[] = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEYS.NAV_OPEN_KEYS)!) || []

  dialogQueue: DialogQueue = []

  // sync data
  @observable media_mode =
    localStorage.getItem(LOCALSTORAGE_KEYS.MEDIA_MODE) || MEDIA_MODE.RESPONSIVE

  @observable navigate = []

  @observable partnerSelectItems: Schema$PartnerMemeberItem[]

  @observable powerballBettingGuideVisible = false

  @observable option: Schema$PublicOption = null

  @observable noticeTab = ''

  @observable navChange = ''

  constructor() {
    super()

    makeObservable(this)
  }

  @action
  setNoticeTab = (name: string) => {
    this.noticeTab = name
  }

  @action
  setNavChange = (name: string) => {
    console.log(`setNavChange ${this.navChange} => ${name}`)
    this.navChange = name
  }

  decideMedia = (isDesktop: boolean): { mediaType: string; desktop: boolean } => {
    let mediaType = isDesktop ? MEDIA_TYPE.DESKTOP : MEDIA_TYPE.MOBILE
    switch (this.media_mode) {
      case MEDIA_MODE.RESPONSIVE:
        break
      case MEDIA_MODE.STATIC:
        mediaType = getDeviceType() === 'desktop' ? MEDIA_TYPE.DESKTOP : MEDIA_TYPE.MOBILE
        break
      case MEDIA_MODE.DESKTOP:
        mediaType = MEDIA_TYPE.DESKTOP
        break
      case MEDIA_MODE.MOBILE:
        mediaType = MEDIA_TYPE.MOBILE
        break
    }
    return { mediaType, desktop: mediaType === MEDIA_TYPE.DESKTOP }
  }

  isModalIndex = (key: string, data: number) => {
    return this.currentKey === key && data === this.currentValue
  }

  @action
  setPowerballBettingGuideVisible(visible: boolean) {
    this.powerballBettingGuideVisible = visible
  }

  @action
  setPartnerSelectItems(items: Schema$PartnerMemeberItem[]) {
    this.partnerSelectItems = [...items]
  }

  @action
  setMediaMode(mode: string) {
    localStorage.setItem(LOCALSTORAGE_KEYS.MEDIA_MODE, mode.toString())
    this.media_mode = mode
  }

  @action
  cleanup = () => {
    this.currentKey = ModalKeys.none
    this.currentValue = null

    this.siderVisible = true
    this.somethingPopup = false
    this.partnerMode = PartnerModeKeys.none

    this.dialogQueue = []
  }

  @action
  toggleSider = (visible: boolean) => {
    this.siderVisible = visible
    this.updateSomethingPopup()
  }

  @action
  toggleModal = (key: string, data: number) => {
    this.currentKey = key
    this.currentValue = data
    this.updateSomethingPopup()
  }

  @action
  showModal = (key: string, data: any | number) => {
    this.currentKey = key
    this.currentValue = data

    this.siderVisible = false
    this.updateSomethingPopup()
  }

  @action
  hideModal = (key: string) => {
    if (_.isString(key) && key.length > 0) {
      if (key === this.currentKey) {
        this.currentKey = ModalKeys.none
        this.currentValue = null
      }
    }
  }

  @action
  hidePopupAll = () => {
    this.currentKey = ModalKeys.none
    this.currentValue = null

    this.siderVisible = false
  }

  @action
  setOpenKeys = (openKeys: string[]) => {
    this.navOpenKeys = openKeys
    localStorage.setItem(LOCALSTORAGE_KEYS.NAV_OPEN_KEYS, JSON.stringify(openKeys))
  }

  @action
  toMoveWebHomePage = (url: any, target = 'self') => {
    if (target === 'self') {
      window.location.href = url
    } else if (target === 'popup') {
      const opts =
        'width=525, height=648, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no'
      window.open(url, `CS_FAQ_${new Date().getTime()}`, opts)
      window.focus()
    }
  }

  @action
  updateSomethingPopup = () => {
    let counted = this.currentKey != ModalKeys.none && this.currentValue != null
    // this.somethingPopup = this.siderVisible || counted >= 0 || !!this.currentDialog
    this.somethingPopup = this.siderVisible || counted
  }

  @action
  enablePartnerMode = (mode: number) => {
    this.partnerMode = mode
  }

  @action
  pushDialog = (type: DialogType, params: DialogParams): DialogData => {
    let option: SweetAlertOptions = {
      title: params.title || ' ', // need some space if no title
      text: params.text,
      html: params.html,
      scrollbarPadding: false,
    }
    switch (type) {
      case DialogType.TIMEOUT:
        option.position = params.position || 'top'
        option.icon = 'success'
        option.showConfirmButton = false
        option.timer = params.timeout
        break
      case DialogType.WAIT:
        option.position = params.position || 'center'
        option.timerProgressBar = true
        option.timer = params.timeout
        option.didOpen = () => {
          Swal.showLoading()
        }
        break
      case DialogType.SINGLE:
        option.confirmButtonText = params.singlePress
        option.showConfirmButton = true
        option.showDenyButton = false
        option.showCancelButton = false
        option.preConfirm = () => {
          if (params.callbackPositive) {
            params.callbackPositive()
          }
        }
        break
      case DialogType.CHOICE:
        option.confirmButtonText = params.positivePress
        option.denyButtonText = params.negativePress
        option.showConfirmButton = true
        option.showDenyButton = true
        option.showCancelButton = false
        option.preConfirm = () => {
          if (params.callbackPositive) {
            params.callbackPositive()
          }
        }
        option.preDeny = () => {
          if (params.callbackNegative) {
            params.callbackNegative()
          }
        }
        break
    }

    MySwal.fire(option)

    return null
  }

  @action
  pushDialogTimeout = (text: string, position: SweetAlertPosition, timeout: number): DialogData => {
    return this.pushDialog(DialogType.TIMEOUT, {
      text,
      position,
      timeout,
    })
  }

  @action
  pushDialogWait = (params: DialogParams): DialogData => {
    return this.pushDialog(DialogType.WAIT, params)
  }

  @action
  setNavigate = (data: string) => {
    const now = new Date()
    const time = now.getTime().toString()
    this.navigate = [data, time]
  }

  @action
  pushLogin = () => {
    this.setNavigate('/')
    this.showModal(ModalKeys.login, 0)
  }

  @action
  pushErrorObject = (err, intl: IntlShape) => {
    const { error, message, reload } = err
    let text = message

    if (intl) {
      const [contents, addon] = message.split(':@')
      text = intl.formatMessage({ id: contents, defaultMessage: contents })
      // console.log(message)
      // console.log(text)
      if (addon) {
        text = `${text} ${addon}`
      }
    }

    this.pushError(
      error,
      text,
      intl,
      reload
        ? () => {
            window.location.reload()
          }
        : null,
    )
  }

  @action
  pushError = (error: string, message: string, intl, funcCallback: () => void) => {
    if (error === 'HiddenException') {
      // do nothing
    } else if (error === 'MaintenanceException') {
      this.hidePopupAll()
      this.getPublicSettings(intl)
    } else if (error === 'UnAuthorizedException') {
      authStore.setSignedin(false, '')
      this.setNavigate('/')
      this.showModal(ModalKeys.login, 0)
      // this.pushDialogOk({
      //   html: '로그아웃되었습니다.<br/>다시 로그인 하세요.',
      //   callbackPositive: () => {
      //     if (funcCallback) {
      //       funcCallback()
      //     }
      //   },
      // })
    } else if (error === 'UnreadNoteFailureException') {
      this.showModal(ModalKeys.message, 0)
      // show message popup
    } else if (error === 'FixtureMatchFailureException') {
      sportsStore.clearCart()
      sportsStore.clearPrematches()
      sportsLiveStore.reloadSpace()
      this.hidePopupAll()
      this.pushDialogOk({
        text: message,
        callbackPositive: () => {
          if (funcCallback) {
            funcCallback()
          }
        },
      })
    } else if (error === 'OddsMatchFailureException') {
      sportsStore.clearPrematches()
      sportsLiveStore.reloadSpace()
      this.hidePopupAll()
      this.pushDialogOk({
        text: message,
        callbackPositive: () => {
          if (funcCallback) {
            funcCallback()
          }
        },
      })
    } else {
      this.pushDialogOk({
        text: message,
        callbackPositive: () => {
          if (funcCallback) {
            funcCallback()
          }
        },
      })
    }
  }

  @observable dialogLocaleData: DialogLocaleData = null

  @action
  pushDialogLocaleOk = (params: DialogParams) => {
    this.dialogLocaleData = { type: DialogType.SINGLE, params }
  }

  pushDialogLocaleYesNo = (params: DialogParams) => {
    this.dialogLocaleData = { type: DialogType.CHOICE, params }
  }

  pushDialogLocaleWait = (params: DialogParams) => {
    this.dialogLocaleData = { type: DialogType.WAIT, params }
  }

  @action
  pushDialogOk = (params: DialogParams): DialogData => {
    params.singlePress = params.singlePress || 'Ok'
    return this.pushDialog(DialogType.SINGLE, params)
  }

  @action
  pushDialogYesNo = (params: DialogParams): DialogData => {
    params.negativePress = params.negativePress || 'No'
    params.positivePress = params.positivePress || 'Yes'
    return this.pushDialog(DialogType.CHOICE, params)
  }

  @action
  closeDialog = () => {
    MySwal.close()
  }

  @action
  setOption = (option: Schema$PublicOption) => {
    this.option = option
  }

  // public api requests
  getPublicSettings = async (intl: IntlShape) => {
    try {
      const params: IPublicSettings.Params = {}
      const { option } = await this.api.public.getSettings(params)
      this.setOption(option)
    } catch (err) {
      this.pushErrorObject(err, intl)
    }
  }

  timer: NodeJS.Timeout = null

  checkChild = (wnd: Window, kind: string) => {
    //if (wnd) {      // 이게 왜 이렇게 되어 있었는지 확인 필요 - 팝업이 떠 있을때 항상 호출됨. 아래와 같이 변경
    if (wnd && wnd.closed) {
      // this.pushDialogOk({ text: '게임창이 닫혔습니다.' })
      if (kind) {
        try {
          apiGame.withdrawOnDemand({
            trading_kind: kind,
            onDemand: false,
            browserData: makeBrowserData(),
          })
        } catch (err) {
          //
        }
      }

      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }
  }

  signedinNewGame = (group: string, kind: string, signedin: boolean, intl: IntlShape) => {
    if (!signedin) {
      this.showModal(ModalKeys.login, 0)
      return
    }

    this.newGame(group, kind, null, intl)
  }

  leadToGame = (
    group: string,
    kind: string,
    history: History,
    signedin: boolean,
    intl: IntlShape,
  ) => {
    const mglist = _.filter(ALL_GAME_INFO, o => {
      return o.category === 'mg'
    })
    if (
      _.findIndex(mglist, o => {
        return o.group === group
      }) >= 0
    ) {
      if (history) {
        history.push(`/minigame/search?group=${group}&kind=${kind}`)
      }
    } else {
      switch (group) {
        case 'LCA':
        case 'HCA':
          this.signedinNewGame(group, kind, signedin, intl)
          break
        case 'SL':
          this.showModal(ModalKeys.slot, { kind: kind })
          break
        case 'MSL':
          this.signedinNewGame('msl', group, signedin, intl)
          break
      }
    }
  }

  newGame = async (
    trading_group: string,
    trading_kind: string,
    gameId: string,
    intl: IntlShape,
  ) => {
    const allowAllBrowser = this.option.allowAllBrowser ?? false
    if (!allowAllBrowser && browser.name !== 'chrome' && browser.name !== 'crios') {
      this.pushDialogOk({ text: '원활한 게임진행을 위해 크롬 브라우저를 이용해주세요.' })
      return
    }

    this.pushDialogLocaleYesNo({
      text: 'msg.new_game',
      callbackPositive: async () => {
        try {
          this.pushDialogLocaleWait({ text: 'msg.game_is_running' })
          const { provider } = await apiGame.checkPopupGame({ trading_kind: trading_kind })
          await liveStore.enterSpace(provider, trading_group, trading_kind, gameId, false)

          let width = (window.screen.width * 80) / 100
          let height = (window.screen.height * 80) / 100
          if (trading_group === 'REEL') {
            width = 1160
            height = 762
          }
          const proxyUrl = `${window.location.origin}/proxy/loading`
          this.gwnd = popupCenterGame({ url: proxyUrl, title: trading_group, w: width, h: height })
        } catch (err) {
          this.pushErrorObject(err, intl)
        }
      },
    })
  }

  gwnd: Window = null

  popupGame = async (kind: string, gameId: string, url: string) => {
    try {
      // let width = (window.screen.width * 80) / 100
      // let height = (window.screen.height * 80) / 100
      // const wnd = popupCenterGame({ url, title: kind, w: width, h: height })
      if (this.gwnd && !this.gwnd.closed) {
        this.gwnd.location = url

        this.closeDialog()
        if (this.timer) {
          clearInterval(this.timer)
          this.timer = null
        }
        if (this.gwnd) {
          this.timer = setInterval(() => {
            this.checkChild(this.gwnd, kind)
          }, 500)
        }
      }
    } catch (err) {
      this.pushErrorObject(err, null)
    }
  }

  cleanupPopupGame = () => {
    if (this.gwnd && !this.gwnd.closed) {
      this.gwnd.close()
      this.gwnd = null
    }
  }
}

export default new GlobalStore()
