export function rateFixed(n: string) {
  const rate = parseFloat(n)
  const s = (rate * 100).toFixed(2)
  const num = Math.floor(Number(s)) / 100
  return num.toFixed(2)
}

export function rateMultiplied(lst: string[]): string {
  if (lst.length === 0) {
    return '0.0'
  }

  let tmp = undefined
  for (const el of lst) {
    const f = parseFloat(el)
    const s = (f * 100).toFixed(2)
    const a = Math.floor(Number(s))
    if (tmp) {
      tmp = tmp * a
    } else {
      tmp = a
    }
  }

  tmp = tmp / Math.pow(100, lst.length)

  return rateFixed(String(tmp))
}

export function dividendFixed(rate: string, volume: number): number {
  if (rate.length === 0 || volume === 0) {
    return 0
  }
  const a = parseFloat(rate) * 100
  const tmp = (a * volume) / 100
  return Math.floor(tmp)
}

// sports
export function rateFixed1000(n: string) {
  const rate = parseFloat(n)
  const s = (rate * 1000).toFixed(3)
  const num = Math.floor(Number(s)) / 1000
  return num.toFixed(3)
}

export function rateMultiplied1000(lst: string[]): string {
  if (lst.length === 0) {
    return '0.0'
  }

  let tmp = undefined
  for (const el of lst) {
    const f = parseFloat(el)
    const s = (f * 1000).toFixed(3)
    const a = Math.floor(Number(s))
    if (tmp) {
      tmp = tmp * a
    } else {
      tmp = a
    }
  }

  tmp = tmp / Math.pow(1000, lst.length)

  return rateFixed1000(String(tmp))
}

export function dividendFixed1000(rate: string, volume: number): number {
  if (rate.length === 0 || volume === 0) {
    return 0
  }
  const a = parseFloat(rate) * 1000
  const tmp = (a * volume) / 1000
  return Math.floor(tmp)
}
