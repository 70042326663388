/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import { useEffect } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Container, Typography } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { styled } from '@mui/material/styles'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { ModalKeys } from '@store/globalStore'
import asset_channel from '@assets/channel'
import { AllSlotGames } from '~/game/slot_data'

// css
import { ImageListItemStyle, ImageListItemStyle2 } from '@styles/base.style'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const ListTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  color:
    channelSymbol === 'marine' ||
    channelSymbol === 'tiger' ||
    channelSymbol === 'hilton' ||
    channelSymbol === 'soft' ||
    channelSymbol === 'gangnam' ||
    channelSymbol === 'milky' ||
    channelSymbol === 'hulk'
      ? '#fff'
      : theme.colors.listTitle,
  fontWeight: 'bold',
  fontSize: '1.8rem',
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const Slot: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('SL')
    }
  }, [signedin, initialized, serviceState])

  async function onClickSlotCard(trading_kind: string) {
    globalStore.showModal(ModalKeys.slot, { kind: trading_kind })
  }

  const listItems = []
  const moreContents = []

  if (open_trading_kinds) {
    let slotItems = _.filter(open_trading_kinds, o => {
      return o.trading_group === 'SL'
    })
    const ordered = _.orderBy(slotItems, ['order'], ['asc'])

    const popularItems = ordered.slice(0, 8)
    const moreItems = ordered.slice(8)

    for (const trading of popularItems) {
      const el = _.find(AllSlotGames, o => {
        return o.kind === trading.trading_kind
      })
      if (el) {
        if (
          channelSymbol === 'marine' ||
          channelSymbol === 'tiger' ||
          channelSymbol === 'hilton' ||
          channelSymbol === 'soft' ||
          channelSymbol === 'gangnam' ||
          channelSymbol === 'milky' ||
          channelSymbol === 'hulk'
        ) {
          listItems.push(
            <ImageListItemStyle onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
              <div className="img_wrap">
                <img
                  src={el.thumbnail}
                  alt={'beginners image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
                <div className="border_line">
                  <img
                    src={el.hoverimg}
                    alt={'beginners image ' + el.kind}
                    loading="lazy"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </ImageListItemStyle>,
          )
        } else if (channelSymbol === 'marine2') {
          listItems.push(
            <ImageListItemStyle2 onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
              <div className="img_wrap">
                <img
                  src={el.thumbnail}
                  alt={'beginners image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
                <div className="border_line"></div>
              </div>
            </ImageListItemStyle2>,
          )
        } else {
          listItems.push(
            <ImageListItemStyle onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
              <div className="img_wrap">
                <img
                  src={el.thumbnail}
                  alt={'game image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
                <div className="border_line"></div>
              </div>
              <ImageListItemBar
                title={
                  <div style={{ fontSize: '1.1rem' }}>
                    {intl.formatMessage({ id: el.localeId, defaultMessage: trading.name })}
                  </div>
                }
                position="below"
                sx={{
                  border: 'none',
                  '& .MuiImageListItemBar-titleWrap': {
                    padding: '6px 0',
                  },
                }}
              />
            </ImageListItemStyle>,
          )
        }
      } else {
        listItems.push(
          <ImageListItemStyle
            onClick={() => onClickSlotCard(trading.trading_kind)}
            key={trading.trading_kind}
          >
            <div className="img_wrap">
              {/* <img src={el.thumbnail} alt={'game image ' + trading.trading_kind} loading="lazy" style={{ width: '100%' }} /> */}
              <div className="border_line"></div>
            </div>
            <ImageListItemBar
              title={<div style={{ fontSize: '1.1rem' }}>{trading.name}</div>}
              position="below"
              sx={{
                border: 'none',
                '& .MuiImageListItemBar-titleWrap': {
                  padding: '6px 0',
                },
              }}
            />
          </ImageListItemStyle>,
        )
      }
    }

    for (const trading of moreItems) {
      const el = _.find(AllSlotGames, o => {
        return o.kind === trading.trading_kind
      })
      if (el) {
        if (
          channelSymbol === 'marine' ||
          channelSymbol === 'tiger' ||
          channelSymbol === 'hilton' ||
          channelSymbol === 'soft' ||
          channelSymbol === 'gangnam' ||
          channelSymbol === 'milky' ||
          channelSymbol === 'hulk'
        ) {
          moreContents.push(
            <ImageListItemStyle onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
              <div className="img_wrap">
                <img
                  src={el.thumbnail}
                  alt={'game image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
                <div className="border_line">
                  <img
                    src={el.hoverimg}
                    alt={'beginners image ' + el.kind}
                    loading="lazy"
                    style={{ width: '100%' }}
                  />
                </div>
              </div>
            </ImageListItemStyle>,
          )
        } else if (channelSymbol === 'marine2') {
          moreContents.push(
            <ImageListItemStyle2 onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
              <div className="img_wrap">
                <img
                  src={el.thumbnail}
                  alt={'game image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
                <div className="border_line"></div>
              </div>
            </ImageListItemStyle2>,
          )
        } else {
          moreContents.push(
            <ImageListItemStyle onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
              <div className="img_wrap">
                <img
                  src={el.thumbnail}
                  alt={'game image ' + el.kind}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
                <div className="border_line"></div>
              </div>
              <ImageListItemBar
                title={
                  <div style={{ fontSize: '1.1rem' }}>
                    {intl.formatMessage({ id: el.localeId, defaultMessage: trading.name })}
                  </div>
                }
                position="below"
                sx={{
                  border: 'none',
                  '& .MuiImageListItemBar-titleWrap': {
                    padding: '6px 0',
                  },
                }}
              />
            </ImageListItemStyle>,
          )
        }
      } else {
        moreContents.push(
          <ImageListItemStyle
            onClick={() => onClickSlotCard(trading.trading_kind)}
            key={trading.trading_kind}
          >
            <div className="img_wrap">
              {/* <img src={el.thumbnail} alt={'game image ' + trading.trading_kind} loading="lazy" style={{ width: '100%' }} /> */}
              <div className="border_line"></div>
            </div>
            <ImageListItemBar
              title={<div style={{ fontSize: '1.1rem' }}>{trading.name}</div>}
              position="below"
              sx={{
                border: 'none',
                '& .MuiImageListItemBar-titleWrap': {
                  padding: '6px 0',
                },
              }}
            />
          </ImageListItemStyle>,
        )
      }
    }
  }

  let contents = null
  let minWidthDesktop = '1248px'

  if (
    channelSymbol === 'today' ||
    channelSymbol === 'hongyeom' ||
    channelSymbol === 'ace' ||
    channelSymbol === 'ssg' ||
    channelSymbol === 'praha'
  ) {
    minWidthDesktop = '1448px'
  }

  if (desktop) {
    if (
      channelSymbol === 'marine' ||
      channelSymbol === 'tiger' ||
      channelSymbol === 'hilton' ||
      channelSymbol === 'soft' ||
      channelSymbol === 'gangnam' ||
      channelSymbol === 'milky' ||
      channelSymbol === 'hulk'
    ) {
      contents = (
        <Container className="content_wrap" sx={{ minWidth: '1648px', pb: 5, mt: 4 }}>
          <ListTitle sx={{ fontSize: '1.8rem' }}>
            {intl.formatMessage({ id: 'component.title.popular-box' })}
          </ListTitle>
          <ImageList cols={5} gap={12}>
            {listItems}
          </ImageList>

          <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>MORE</ListTitle>
          <ImageList cols={5} gap={12} sx={{ mb: 5 }}>
            {moreContents}
          </ImageList>
        </Container>
      )
    } else if (channelSymbol === 'marine2') {
      contents = (
        <Box className="content_wrap" sx={{ pb: 5 }}>
          <Container sx={{ minWidth: '1368px', mx: '0' }}>
            <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
              {intl.formatMessage({ id: 'component.title.popular-box' })}
            </ListTitle>
            <ImageList cols={4} gap={12}>
              {listItems}
            </ImageList>

            <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>MORE</ListTitle>
            <ImageList cols={4} gap={12} sx={{ mb: 5 }}>
              {moreContents}
            </ImageList>
          </Container>
        </Box>
      )
    } else {
      contents = (
        <Container
          className="content_wrap"
          sx={{ minWidth: minWidthDesktop, pb: 5, backgroundColor: appBackground }}
        >
          <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
            {intl.formatMessage({ id: 'component.title.popular-box' })}
          </ListTitle>
          <ImageList cols={4} gap={12}>
            {listItems}
          </ImageList>

          <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>MORE</ListTitle>
          <ImageList cols={4} gap={12} sx={{ mb: 5 }}>
            {moreContents}
          </ImageList>
        </Container>
      )
    }
  } else {
    contents = (
      <Box
        className="content_wrap_m"
        sx={{ p: 2, backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}
      >
        <ListTitle sx={{ fontSize: '1.2rem' }}>
          {intl.formatMessage({ id: 'component.title.popular-box' })}
        </ListTitle>
        <ImageList cols={2} gap={12} sx={{ mb: 5 }}>
          {listItems}
        </ImageList>

        <ListTitle sx={{ fontSize: '1.2rem' }}>MORE</ListTitle>
        <ImageList cols={2} gap={12} sx={{ mb: 5 }}>
          {moreContents}
        </ImageList>
      </Box>
    )
  }

  return <>{contents}</>
}

export default observer(Slot)
