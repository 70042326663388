/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Container, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import * as _ from 'lodash'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import queryString from 'query-string'

import useRootStore from '@store/useRootStore'
import { getStateString, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { GlobalStore, ModalKeys } from '@store/globalStore'
import { AuthStore } from '@store/authStore'
import { useMediaQuery } from 'react-responsive'
import { LOCALSTORAGE_KEYS, MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { useHistory } from 'react-router-dom'
import { AllMegahitGames } from '~/game/mg_data'

// css
import { ImageListItemStyle } from '@styles/base.style'

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const ListTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  color: theme.colors.listTitle,
  fontWeight: 'bold',
  fontSize: '1.8rem',
}))

const DefaultMegahit: React.FC = props => {
  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, liveStore, userStore, authStore } = useRootStore()
  const { initialized, signedin } = authStore

  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  let { desktop } = globalStore.decideMedia(isDesktop)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  function onClickMegaGameCard(kind: string) {
    globalStore.signedinNewGame('MSL', kind, signedin, intl)
  }

  const mslItems = _.filter(open_trading_kinds, o => {
    return o.trading_group === 'MSL'
  })
  const mslOrdered = _.orderBy(mslItems, ['order'], ['asc'])

  const listItems = []
  for (const tk of mslOrdered) {
    const idx = _.findIndex(AllMegahitGames, o => {
      return o.kind === tk.trading_kind
    })
    if (idx >= 0) {
      const el = AllMegahitGames[idx]
      listItems.push(
        <ImageListItemStyle onClick={() => onClickMegaGameCard(el.kind)} key={el.kind}>
          <div className="img_wrap">
            <img
              src={el.thumbnail}
              alt={'game image ' + el.kind}
              loading="lazy"
              style={{ width: '100%', background: 'black' }}
            />
            <div className="border_line"></div>
          </div>
          <ImageListItemBar
            title={<div style={{ fontSize: '1.1rem' }}>{tk.name}</div>}
            position="below"
            sx={{
              border: 'none',
              '& .MuiImageListItemBar-titleWrap': {
                padding: '6px 0 10px',
              },
            }}
          />
        </ImageListItemStyle>,
      )
    }
  }

  let contents = null
  if (desktop) {
    contents = (
      <Container
        className="content_wrap"
        sx={{ minWidth: '1248px', pb: 5, backgroundColor: appBackground }}
      >
        <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>메가히트슬롯</ListTitle>
        <ImageList cols={4} gap={12}>
          {listItems}
        </ImageList>
      </Container>
    )
  } else {
    contents = (
      <Box sx={{ p: 2, backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}>
        <ListTitle sx={{ fontSize: '1.2rem' }}>메가히트슬롯</ListTitle>
        <ImageList cols={2} gap={12}>
          {listItems}
        </ImageList>
      </Box>
    )
  }

  return <>{contents}</>
}

export default observer(DefaultMegahit)
