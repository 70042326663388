/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { Fragment, useState, useEffect } from 'react'
import { Grid, Box, Chip } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import { INotices } from '@protocol/public'
import NoticeView from './NoticeView'
import apiPublic from '@services/api/public'
import { useHistory } from 'react-router-dom'
import { useOnMount } from '~/utils/reactExt'

//css
import { PaginationStyle } from '~/styles/base.style'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: '16px',
  backgroundColor: '#080F1C',
  borderRadius: '4px',
  overflow: 'hidden',
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: '#16202F',
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiChip-root': {
    marginRight: '26px',
    height: '20px',
    color: '#FFF',
  },
  '& .title': {
    color: '#D1D9E3',
  },
  '& .date': {
    color: '#747D8A',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
}))

interface Props {
  tabKey: string
}

interface PageData {
  currPage: number
  totalPage: number
}

type NoticeTrackData = INotices.Schema

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const getPageData = (track: NoticeTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

function NoticeList({ tabKey }: Props) {
  const intl = useIntl()
  const [kind, setKind] = useState('')
  const [track, setTrack] = useState<NoticeTrackData>(null)
  const { globalStore } = useRootStore()

  if (kind !== tabKey) {
    setKind(tabKey)
  }

  useEffect(() => {
    fetch({ kind: tabKey, offset: 0, limit: ItemPerPage })
  }, [kind])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [track])

  const fetch = async (params: any) => {
    try {
      const { kind, offset, limit, total, items } = await apiPublic.getNotices(params)
      const data: NoticeTrackData = {
        kind,
        offset,
        limit,
        total,
        items,
      }
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const [expanded, setExpanded] = React.useState<string | false>('')

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ kind: tabKey, offset, limit: ItemPerPage })
  }

  const listItems = []

  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    for (const el of track.items) {
      const color = el.kind === 'EVENT' ? '#289CAF' : '#3B4C6E'
      let kindText = ''
      switch (el.kind) {
        case 'NOTICE':
          kindText = intl.formatMessage({ id: 'component.notice.notice' })
          break
        case 'EVENT':
          kindText = intl.formatMessage({ id: 'component.notice.event' })
          break
        case 'POLICY':
          kindText = intl.formatMessage({ id: 'component.notice.policy' })
          break
      }
      const writeAtWithTime = el.write_date
      listItems.push(
        <Accordion
          key={el.id}
          expanded={expanded === `pannel-${el.id}`}
          onChange={handleChange(`pannel-${el.id}`)}
        >
          <AccordionSummary aria-controls={`panel-${el.id}-content`} id={`panel-${el.id}-header`}>
            <Grid container sx={{ py: 2, alignItems: 'center' }}>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 1 / 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {channelSymbol === 'emart' ? (
                  el.kind === 'POLICY' || el.kind === 'EVENT' ? null : (
                    <Chip label={kindText} size="small" sx={{ backgroundColor: color }} />
                  )
                ) : (
                  <Chip label={kindText} size="small" sx={{ backgroundColor: color }} />
                )}
                <span className="date">{writeAtWithTime}</span>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'start' }}>
                <span className="title">{el.title}</span>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <NoticeView content={el.content} />
          </AccordionDetails>
        </Accordion>,
      )
    }
  }

  return (
    <Fragment>
      <Box sx={{ p: 2 }}>{listItems}</Box>

      <PaginationStyle
        count={totalTrackPage}
        variant="outlined"
        page={currTrackPage}
        onChange={handlePagingChange}
      />
    </Fragment>
  )
}

export default NoticeList
