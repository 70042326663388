import * as _ from 'lodash'
import { Context$MetaCommon } from '~/v2/interface/st-context'

export class MetaContainer {
  name: string = '' // bookmaker, sport, location, league, team, market

  metas = new Map<number, Context$MetaCommon>()

  constructor(name: string) {
    this.name = name
  }

  updateMetas(metas: Context$MetaCommon[]) {
    if (!metas || !_.isArray(metas)) {
      return
    }

    try {
      for (const el of metas) {
        this.metas.set(el.Id, el)
      }
    } catch (err) {
      console.log(err)
    }
  }

  updateMeta(meta: Context$MetaCommon) {
    this.metas.set(meta.Id, meta)
  }

  get(id: number): Context$MetaCommon {
    return this.metas.get(id)
  }
}

export interface MetaContainerMapObject {
  [name: string]: MetaContainer
}

export class MetaManager {
  dic: MetaContainerMapObject = {
    bookmaker: new MetaContainer('bookmaker'),
    sport: new MetaContainer('sport'),
    location: new MetaContainer('location'),
    league: new MetaContainer('league'),
    team: new MetaContainer('team'),
    market: new MetaContainer('market'),
  }

  constructor() {
    //
  }

  updateMetas(name: string, metas: Context$MetaCommon[]) {
    if (name in this.dic) {
      this.dic[name].updateMetas(metas)
    }
  }

  updateMeta(name: string, meta: Context$MetaCommon) {
    if (name in this.dic) {
      this.dic[name].updateMeta(meta)
    }
  }

  getMetas(name: string): Context$MetaCommon[] {
    if (name in this.dic) {
      return Array.from(this.dic[name].metas.values())
    }
    return []
  }
}
