import { CHIP_BUTTON_TYPE, ChipAttributes } from './types'

export const CHIP_BUTTONS: ChipAttributes[] = [
  {
    id: 1000,
    title: '1000',
    fontSize: 13,
    type: CHIP_BUTTON_TYPE.GRAY,
    position: { x: 10, y: 10 },
  },
  {
    id: 5000,
    title: '5000',
    fontSize: 13,
    type: CHIP_BUTTON_TYPE.RED,
    position: { x: 23, y: -45 },
  },
  {
    id: 10000,
    title: '1만',
    fontSize: 13,
    type: CHIP_BUTTON_TYPE.GREEN,
    position: { x: 70, y: -80 },
  },
  {
    id: 100000,
    title: '10만',
    fontSize: 13,
    type: CHIP_BUTTON_TYPE.BLACK,
    position: { x: 130, y: -80 },
  },
  {
    id: 500000,
    title: '50만',
    fontSize: 13,
    type: CHIP_BUTTON_TYPE.PURPLE,
    position: { x: 177, y: -45 },
  },
  {
    id: 1000000,
    title: '100만',
    fontSize: 11,
    type: CHIP_BUTTON_TYPE.BROWN,
    position: { x: 190, y: 10 },
  },
]

export function getChipButtonData(volume: number): ChipAttributes {
  const data: ChipAttributes = {
    id: volume,
    title: volume.toString(),
    fontSize: 13,
    type: CHIP_BUTTON_TYPE.GRAY,
  }
  for (let i = 0; i < CHIP_BUTTONS.length; i += 1) {
    const idx = CHIP_BUTTONS.length - i - 1
    const reversed = CHIP_BUTTONS[idx]
    data.type = reversed.type
    if (volume >= reversed.id) {
      break
    }
  }
  // text

  const v1000 = volume % 10000
  const t1000 = Math.floor(v1000 / 1000)
  const t10000 = Math.floor(volume / 10000)
  const has1000 = v1000 > 0

  if (has1000) {
    if (t10000) {
      data.title = `${t10000}.${t1000}만`
    } else {
      data.title = volume.toString()
    }
  } else {
    data.title = `${t10000}만`
  }

  return data
}
