import * as _ from 'lodash'
import * as uuid from 'uuid'

import { Schema$MatchupMarket, Schema$MatchupOdds } from '@interface/matchup'
import { SquidgameMarketMeta, SquidgameOddsMeta } from '@v2/utils/squidgame-meta'
import { Schema$TradingOddsOption } from '@interface/matchup'

import { ODDS_COLOR, MinigameMatchup, MarketMatrixMap } from './common'

export const SQUIDGAME_MARKET_GROUP = {
  ALL: 0,
  MAX: 1,
}

export const SQUIDGAME_MARKET_GROUP_TEXT = ['', 'DIRECTION', 'MASTER', 'SURVIVOR']

// category/marketGroup/matrix
export const MarketMatrix: MarketMatrixMap = {
  SG: {
    DIRECTION: [
      [
        { marketId: 'SGDLEFT_SGDRIGHT', id: 'SGDLEFT', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SGDLEFT_SGDRIGHT', id: 'SGDRIGHT', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
    ],
    MASTER: [
      [
        { marketId: 'SGMODD_SGMEVEN', id: 'SGMODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SGMODD_SGMEVEN', id: 'SGMEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        { marketId: 'SGMUNDER_SGMOVER', id: 'SGMUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SGMUNDER_SGMOVER', id: 'SGMOVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGMODD_SGMEVEN',
          id: 'SGDLEFT+SGMODD',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGMODD_SGMEVEN',
          id: 'SGDLEFT+SGMEVEN',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGMODD_SGMEVEN',
          id: 'SGDRIGHT+SGMODD',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGMODD_SGMEVEN',
          id: 'SGDRIGHT+SGMEVEN',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
      ],
      [
        {
          marketId: 'SGMODD_SGMEVEN+SGMUNDER_SGMOVER',
          id: 'SGMODD+SGMUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'SGMODD_SGMEVEN+SGMUNDER_SGMOVER',
          id: 'SGMODD+SGMOVER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'SGMODD_SGMEVEN+SGMUNDER_SGMOVER',
          id: 'SGMEVEN+SGMUNDER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
        {
          marketId: 'SGMODD_SGMEVEN+SGMUNDER_SGMOVER',
          id: 'SGMEVEN+SGMOVER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
      ],
    ],
    SURVIVOR: [
      [
        { marketId: 'SGSODD_SGSEVEN', id: 'SGSODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SGSODD_SGSEVEN', id: 'SGSEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        { marketId: 'SGSUNDER_SGSOVER', id: 'SGSUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SGSUNDER_SGSOVER', id: 'SGSOVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGSODD_SGSEVEN',
          id: 'SGDLEFT+SGSODD',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGSODD_SGSEVEN',
          id: 'SGDLEFT+SGSEVEN',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGSODD_SGSEVEN',
          id: 'SGDRIGHT+SGSODD',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
        {
          marketId: 'SGDLEFT_SGDRIGHT+SGSODD_SGSEVEN',
          id: 'SGDRIGHT+SGSEVEN',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
      ],
    ],
  },
}

export const GetSquidgameOddsColor = (
  category: string,
  marketGroup: number,
  marketId: string,
  oddsId: string,
): string => {
  if (!_.has(MarketMatrix, category)) {
    return ''
  }
  if (marketGroup >= SQUIDGAME_MARKET_GROUP.MAX) {
    return ''
  }
  const txtMargetGroup = SQUIDGAME_MARKET_GROUP_TEXT[marketGroup]
  if (!_.has(MarketMatrix[category], txtMargetGroup)) {
    return ''
  }
  for (const row of MarketMatrix[category][txtMargetGroup]) {
    const found = _.find(row, { marketId, id: oddsId })
    if (found) {
      return found.acolor
    }
  }
  return ''
}
