/** @jsxImportSource @emotion/react */

import React, { useEffect } from 'react'
import { jsx, css } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import useRootStore from '@store/useRootStore'
import { numberWithCommas } from '@utils/utils'
import asset_channel from '@assets/channel'

// css
import { Box, Button, Container, Stack } from '@mui/material'
import { FooterStyle } from './index.style'

import { MEDIA_MODE } from '~/store/globalStore'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { SUPPORT_LOCALES } from '~/locales/loader'

const APP_NAME = process.env.REACT_APP_NAME || ''

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const APP_LOCALES = process.env.REACT_APP_LOCALES ? JSON.parse(process.env.REACT_APP_LOCALES) : []

function Footer() {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()
  const { partnerMode } = globalStore

  const { desktop } = globalStore.decideMedia(isDesktop)

  // useEffect(() => {
  //   window.scrollTo({top: 0, left: 0})
  //   console.log(localStorage.getItem('locale'))
  // }, [])

  const onClickSwitchDesktopView = (mode: string) => {
    globalStore.setMediaMode(mode)
  }

  const footerStyle =
    APP_NAME === 'Taepeongyang'
      ? {
        '& > img': {
          display: 'none',
        },
      }
      : {
        '& img': {
          maxWidth: '260px',
        },
      }

  let boxStyle = {}
  let switchContents = null

  if (desktop) {
    boxStyle = {
      py: 4,
      minWidth: '1248px',
      '& .MuiContainer-root': {
        maxWidth: 'none',
      },
    }

    if (!isDesktop) {
      switchContents = (
        <Button
          onClick={() => onClickSwitchDesktopView(MEDIA_MODE.RESPONSIVE)}
          sx={{ color: '#cccccc' }}
        >
          <SmartphoneIcon></SmartphoneIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.mobile-view' })}
        </Button>
      )
    }
  } else {
    boxStyle = {
      py: 2,
      '& .MuiContainer-root .inner > img': {
        height: '55px',
      },
    }
    if (!isDesktop) {
      switchContents = (
        <Button
          onClick={() => onClickSwitchDesktopView(MEDIA_MODE.DESKTOP)}
          sx={{ color: '#cccccc' }}
        >
          <DesktopWindowsIcon></DesktopWindowsIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.pc-view' })}
        </Button>
      )
    }
  }

  const handleChangeLanguage = (event, value) => {
    //console.log(value)
    localStorage.setItem('locale', value)
    window.location.reload()
    window.history.scrollRestoration = 'manual'
  }

  const languageItems = []
  for (const el of APP_LOCALES) {
    const found = _.find(SUPPORT_LOCALES, o => {
      return o.value === el
    })
    if (found) {
      languageItems.push(
        <Button
          key={found.value}
          className={found.value === localStorage.getItem('locale') ? 'active' : ''}
          onClick={event => handleChangeLanguage(event, found.value)}
          sx={{
            marginRight: 1,
            color: '#fff',
            '&.active': {
              backgroundColor: '#414c59',
            },
            '&.active:hover': {
              backgroundColor: '#414c59',
            },
            '& > img': {
              marginRight: 1,
            },
          }}
        >
          <img src={found.img} alt={found.name} />
          {found.name}
        </Button>,
      )
    }
  }

  return (
    <FooterStyle sx={boxStyle} className="footer">
      {APP_LOCALES.length > 0 ? (
        <Stack
          direction="row"
          spacing={1}
          sx={{
            mb: 2,
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {languageItems}
        </Stack>
      ) : null}

      <Box>{switchContents}</Box>
      <Container fixed>
        <Box className="inner" sx={footerStyle}>
          {channelSymbol === 'epic' || channelSymbol === 'noah' ? (
            <img src={asset_channel.common.main_logo_gif} alt="" />
          ) : (
            <img src={asset_channel.common.footer_logo} alt="" />
          )}
        </Box>
        <Box className="copyright" sx={{ mt: 2 }}>
          {/* {APP_NAME} Group ⓒ {APP_NAME} Corp. All Rights Reserved. */}
          Copyright All rights Reserved.
        </Box>
      </Container>
    </FooterStyle>
  )
}
export default Footer
