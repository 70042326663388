import * as _ from 'lodash'
import { SPORT_ID } from './st-types'
import { FOOTBALL_PREMATCH_MARKET_GROUP } from './prematch_market_group_football'
import { BASKETBALL_PREMATCH_MARKET_GROUP } from './prematch_market_group_basketball'
import { BASEBALL_PREMATCH_MARKET_GROUP } from './prematch_market_group_baseball'
import { VOLLEYBALL_PREMATCH_MARKET_GROUP } from './prematch_market_group_volleyball'
import { ICEHOCKEY_PREMATCH_MARKET_GROUP } from './prematch_market_group_icehockey'

export const MARKET_COMBIATION_EXCLUSIVE_ITEMS_FOOTBALL = {
  way: [52, 226, 202, 1, 50, 41], // 12, 1X2
  uo: [835, 1061, 2, 28, 836, 153, 155, 101, 102, 221, 220], // UO
  hdp: [3, 342, 64], // HDP
  // uo_corners: [11, 129], // UO with corners
  // hdp_corners: [95], // HDP with corners
}

export const MARKET_COMBIATION_EXCLUSIVE_ITEMS_BASKETBALL = {
  way: [52, 226, 63, 1, 50],
  uo: [835, 1061, 2, 28, 77, 354, 335, 101, 102, 221, 220],
  hdp: [3, 342, 53],
  // uo_corners: [],
  // hdp_corners: [],
}

export const MARKET_COMBIATION_EXCLUSIVE_ITEMS_BASEBALL = {
  way: [52, 226, 1, 50],
  uo: [835, 1061, 2, 28, 236, 2644, 2645, 101, 102, 221, 220],
  hdp: [3, 342, 281],
  // uo_corners: [],
  // hdp_corners: [],
}

export const MARKET_COMBIATION_EXCLUSIVE_ITEMS_VOLLEYBALL = {
  way: [52, 226, 202, 1, 50],
  uo: [165, 21, 153, 155],
  hdp: [866, 64],
  // uo_corners: [],
  // hdp_corners: [],
}

export const MARKET_COMBIATION_EXCLUSIVE_ITEMS_ICEHOCKEY = {
  way: [52, 226, 202],
  uo: [835, 1061, 2, 28, 21, 153, 155, 101, 102, 221, 220],
  hdp: [3, 342, 64],
  // uo_corners: [],
  // hdp_corners: [],
}

export const MARKET_COMBIATION_EXCLUSIVE_SPORTS = [
  {
    sportId: SPORT_ID.Football,
    exclusive: MARKET_COMBIATION_EXCLUSIVE_ITEMS_FOOTBALL,
    group: FOOTBALL_PREMATCH_MARKET_GROUP,
  },
  {
    sportId: SPORT_ID.Basketball,
    exclusive: MARKET_COMBIATION_EXCLUSIVE_ITEMS_BASKETBALL,
    group: BASKETBALL_PREMATCH_MARKET_GROUP,
  },
  {
    sportId: SPORT_ID.Baseball,
    exclusive: MARKET_COMBIATION_EXCLUSIVE_ITEMS_BASEBALL,
    group: BASEBALL_PREMATCH_MARKET_GROUP,
  },
  {
    sportId: SPORT_ID.Volleyball,
    exclusive: MARKET_COMBIATION_EXCLUSIVE_ITEMS_VOLLEYBALL,
    group: VOLLEYBALL_PREMATCH_MARKET_GROUP,
  },
  {
    sportId: SPORT_ID.IceHockey,
    exclusive: MARKET_COMBIATION_EXCLUSIVE_ITEMS_ICEHOCKEY,
    group: ICEHOCKEY_PREMATCH_MARKET_GROUP,
  },
]
