/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Container, Divider, Grid, MenuItem, Pagination, Tab, Tabs, TextField } from '@mui/material'
import { Formik, useFormik } from 'formik'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import {
  getGainLossColor,
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
} from '@utils/utils'
import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps, PageStyle } from '~/containers/shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import {
  Schema$PartnerMemeberItem,
  Schema$PartnerBettingRecordTrackData,
  IPartnerBettingRecord,
  Schema$PartnerUserConfig,
} from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { default as apiUser } from '@services/api/user'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import {
  ICouponConfig,
  ICouponList,
  IIssueCoupon,
  IRecallCoupon,
  Schema$CouponConfigItem,
  Schema$CouponTrackData,
} from '~/v2/protocol/user'
import NumberFormat, { InputAttributes } from 'react-number-format'

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  coupon_id: number
  coupon_name: string
  issuer_uuid: string
  amount: number
  expire_at: string
  used_at: string
  state: string
  request_state: string
  method: string
}

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const NumberFormatCustom = React.forwardRef<NumberFormat<InputAttributes>, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
      <NumberFormat
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator
        isNumericString
        suffix="원"
      />
    )
  },
)

interface Props {
  memberInfo: Schema$PartnerMemeberItem
  ts: string
  onIssued: () => void
}

function ComponentCouponIssue({ memberInfo, onIssued, ts }: Props) {
  const intl = useIntl()
  const [timestamp, setTimestamp] = useState('')
  const [couponConfig, setCouponConfig] = useState<ICouponConfig.Schema | null>(null)
  const [selectedCouponConfig, setSelectedCouponConfig] = useState<Schema$CouponConfigItem | null>(
    null,
  )
  const [expireDate, setExpireDate] = useState<Date>(null)

  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { desktop } = globalStore.decideMedia(isDesktop)

  const { coupon_money, items: coupon_config_items } = couponConfig || {
    coupon_money: 0,
    items: [],
  }

  if (timestamp !== ts) {
    setTimestamp(ts)
  }

  const formik = useFormik({
    initialValues: {
      coupon_name: '',
      coupon_amount: '',
    },
    onSubmit: values => {
      submit(values)
    },
    validationSchema: Yup.object().shape({
      coupon_name: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      coupon_amount: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
    }),
  })

  const submit = async (values: any) => {
    if (selectedCouponConfig) {
      formik.setSubmitting(false)
      const { coupon_name, coupon_amount } = values
      if (!coupon_amount || Number(coupon_amount) === 0) {
        globalStore.pushDialogOk({ text: intl.formatMessage({ id: 'msg.enter_coupon_amount' }) })
        return
      }
      if (!coupon_name) {
        globalStore.pushDialogOk({ text: intl.formatMessage({ id: 'msg.enter_coupon_name' }) })
        return
      }
      if (!expireDate) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.enter_coupon_expiration' }),
        })
        return
      }

      globalStore.pushDialogLocaleYesNo({
        text: 'msg.format.issue_coupon',
        arg1: coupon_name,
        arg2: numberWithCommas(coupon_amount),
        callbackPositive: async () => {
          submitIssueCoupon(values)
        },
      })
    }
  }

  const submitIssueCoupon = async (values: any) => {
    const { coupon_name, coupon_amount } = values

    try {
      const params: IIssueCoupon.Params = {
        target_uuid: memberInfo.uuid,
        coupon_config_id: Number(selectedCouponConfig.coupon_config_id),
        coupon_name: coupon_name,
        coupon_amount: Number(coupon_amount),
        coupon_expire_date: expireDate.toString(),
      }
      await apiUser.issueCoupon(params)
      if (onIssued) {
        onIssued()
      }
      await fetchCouponConfig({})

      globalStore.pushDialogOk({
        text: `"${coupon_name} / ${numberWithCommas(coupon_amount)}원" 쿠폰을 발행하였습니다.`,
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }

    formik.setFieldValue('coupon_name', '')
    formik.setFieldValue('coupon_amount', '')
    setExpireDate(null)
    setSelectedCouponConfig(null)
  }

  useEffect(() => {
    fetchCouponConfig({})
  }, [timestamp])

  const fetchCouponConfig = async (params: ICouponConfig.Params) => {
    try {
      const ret = await apiUser.couponConfig(params)
      setCouponConfig(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickCouponConfig = (coupon_config_id: number) => {
    const found = _.find(couponConfig.items, o => {
      return o.coupon_config_id === coupon_config_id
    })
    setSelectedCouponConfig(found)
    let selected_coupon_name = ''
    let selected_coupon_amount = ''
    let selected_expire_at = null
    if (found) {
      selected_coupon_name = found.coupon_name
      selected_coupon_amount = found.amount.toString()
      const now = new Date()
      const expire_at = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + found.expire_duration,
      )
      selected_expire_at = new Date(expire_at)
    }
    formik.setFieldValue('coupon_name', selected_coupon_name)
    formik.setFieldValue('coupon_amount', selected_coupon_amount)
    setExpireDate(selected_expire_at)
  }

  const handleChangeExpireDate = newValue => {
    setExpireDate(newValue)
  }

  const couponConfigItems = []
  var result = []
  for (let i = 0; i < coupon_config_items.length; i += 4) {
    result.push(coupon_config_items.slice(i, i + 4))
  }

  for (let i = 0; i < result.length; i += 1) {
    const tmps = []
    for (const el of result[i]) {
      const method = el.method === 'IMMEDIATELY' ? '즉시' : '승인'
      tmps.push(
        <Grid xs={3}>
          <Button
            variant="contained"
            size="small"
            color={el.method === 'IMMEDIATELY' ? 'error' : 'primary'}
            sx={{ width: '95%', fontSize: '12px' }}
            onClick={() => onClickCouponConfig(el.coupon_config_id)}
          >{`${el.coupon_name} / ${numberWithCommas(el.amount)}원 / ${method}`}</Button>
        </Grid>,
      )
    }
    couponConfigItems.push(
      <Grid container sx={{ marginTop: '8px' }}>
        {tmps}
      </Grid>,
    )
  }

  const canEditCoupon = selectedCouponConfig && selectedCouponConfig.amount === 0
  const innerContents = (
    <Container
      fixed
      style={{
        minWidth: '1300px',
        maxWidth: '1300px',
        margin: 0,
        padding: 0,
      }}
    >
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Grid container sx={{ marginBottom: 2 }}>
          <Grid item>
            <Stack direction="row">
              <Paper
                variant="elevation"
                square
                elevation={2}
                sx={{ padding: 1, pr: 2, pl: 2, background: '#e9ecef' }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  나의 쿠폰 머니 잔액
                </Typography>
              </Paper>
              <Paper
                variant="elevation"
                square
                elevation={2}
                sx={{ padding: 1, pr: 2, pl: 2, background: '#e9ecef' }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold', pl: '6px' }}>
                  {numberWithCommas(coupon_money)}
                </Typography>{' '}
              </Paper>
            </Stack>
          </Grid>
        </Grid>
        {couponConfigItems}
        <Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
          <TextField
            disabled={!canEditCoupon}
            size="small"
            name="coupon_name"
            placeholder={'쿠폰이름을 입력하세요'}
            value={formik.values.coupon_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant={'outlined'}
            label="쿠폰이름"
            style={{ width: '180px' }}
            inputProps={{ style: { textAlign: 'end' } }}
          ></TextField>
          <TextField
            disabled={!canEditCoupon}
            size="small"
            name="coupon_amount"
            id="formatted-numberformat-input"
            placeholder={'쿠폰금액을 입력하세요'}
            value={formik.values.coupon_amount}
            // onChange={handleChangeAmount}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant={'outlined'}
            label="쿠폰금액"
            style={{ width: '180px' }}
            InputProps={{
              inputComponent: NumberFormatCustom as any,
            }}
            inputProps={{ style: { textAlign: 'end' } }}
          ></TextField>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={!canEditCoupon}
              label="만료일"
              inputFormat="yyyy-MM-dd"
              mask={'____-__-__'}
              value={expireDate}
              onChange={handleChangeExpireDate}
              renderInput={params => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <Button
            type="submit"
            disabled={formik.isSubmitting || !selectedCouponConfig}
            variant="contained"
            color="info"
            sx={{ ml: 2 }}
          >
            쿠폰 발행
          </Button>
        </Stack>
      </form>
    </Container>
  )

  let contents = null
  if (desktop) {
    contents = innerContents
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(ComponentCouponIssue)
