// import { MarketGroup } from './market_group_types'
// export const VOLLEYBALL_PREMATCH_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const VOLLEYBALL_PREMATCH_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      { id: 1, unifiedName: '1X2', name: { en: '1X2', ko: '승무패' } },
      {
        id: 50,
        unifiedName: '1X2',
        name: { en: '1X2 Including Overtime', ko: '승무패 (연장포함)' },
      },
      { id: 52, unifiedName: '12', name: { en: '12', ko: '승패' } },
      { id: 226, unifiedName: '12', name: { en: '12 Including Overtime', ko: '승패 (연장포함)' } },
      { id: 866, unifiedName: 'HDP', name: { en: 'Asian Handicap Sets', ko: '핸디캡 *' } },
      { id: 165, unifiedName: 'UO', name: { en: 'Under/Over Sets', ko: '총 득점' } },
    ],
  },
  {
    groupId: 2,
    groupName: { en: '1st Period', ko: '1세트' },
    markets: [
      {
        id: 202,
        unifiedName: '12',
        name: { en: '1st Period Winner Home/Away', ko: '승패 (1세트)' },
        period: 1,
      },
      {
        id: 64,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 1st Period', ko: '핸디캡 (1세트) *' },
        period: 1,
      },
      {
        id: 21,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period', ko: '총 득점 (1세트)' },
        period: 1,
      },
      {
        id: 153,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period - Home Team', ko: '총 득점 - 홈팀 (1세트)' },
        period: 1,
      },
      {
        id: 155,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period - Away Team', ko: '총 득점 - 원정팀 (1세트)' },
        period: 1,
      },
    ],
  },
]
