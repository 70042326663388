import React, { useState } from 'react'
import * as _ from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DesktopPrematchFixture from '../PrematchFixture/d'
import MobilePrematchFixture from '../PrematchFixture/m'
import { Schema$Prematch } from '~/v2/interface/st-schema'
import urlJoin from 'url-join'
import { useTheme } from '@mui/material/styles'
import DesktopPrematchLeagueBox from './d'
import MobilePrematchLeagueBox from './m'
import { PrematchData, SPORT_FLIGHT } from '~/store/sportsStore/sports-util'

interface Props {
  desktop: boolean
  virtualDesktop: boolean
  flight: SPORT_FLIGHT
  lang: string
  item: PrematchData
  sx?: any
}

function PrematchFixture({ desktop, virtualDesktop, lang, flight, item, sx }: Props) {
  const contents = virtualDesktop ? (
    <DesktopPrematchFixture desktop={desktop} lang={lang} flight={flight} item={item} sx={sx}></DesktopPrematchFixture>
  ) : (
    <MobilePrematchFixture desktop={desktop} lang={lang} flight={flight} item={item} sx={sx}></MobilePrematchFixture>
  )
  return contents
}

export default PrematchFixture
