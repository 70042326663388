export const ERR = {
  OK: 0,
  FAIL: 1,

  SERVER_DOWN: 2,
  NOT_IMPLEMENTED: 3,
  DEPREACTED: 4,
  VERSION_INVALID: 100,
  VERSION_OVER: 101,
  VERSION_UNDER: 102,
  UNKNOWN_PACKET: 1000,
  UNKNOWN_ERROR: 1001,
  MANY_PACKET_IN_TIME: 1002,
  DB_EXCEPTION: 2000,
  CACHE_EXCEPTION: 2001,
  INVALID_SESSION: 2002,
  INVALID_ACCOUNT: 2003,
  INVALID_PASSWORD: 2004,
  INVALID_LAUNCH_AUTHKEY: 2005,
  INVALID_AUTHKEY: 2006,
  NOT_AUTHENTICATED: 2007,
  ALREADY_LOGGEDIN: 2008,
  OVERLAPPED_SESSION_FAIL: 2009,
  PROCESSING_CLOSING_OLDS_SESSION: 2010,
  INVALID_PARAMETER: 2011,
  INVALID_LEADERBOARD_TYPE: 2012,
  YOU_ARE_BLOCKED: 2013,
  YOU_ARE_UNREGISTERED: 2014,
  STAFF_ONLY: 2015,
  RECEIVER_REJECT_MESSAGE: 2016,
  RECEIVER_REJECT_CLUB_MESSAGE: 2017,
  TABLE_NOT_FOUND: 2018,
  TABLE_VERSION_NOT_FOUND: 2019,
  NICKNAME_INVALID: 2020,
  NICKNAME_ALREADY_EXISTS: 2021,
  CHARACTER_ALREADY_EXISTS: 2022,
  DATA_NOT_FOUND: 2023,
  PROMISE_NOT_FOUND: 2024,
  USER_NOT_FOUND: 2025,
  ITEM_NOT_FOUND: 2026,
  ALREADY_IN_SPACE: 2027,
  RETRY_ENTER_SPACE: 2028,
  MULTI_GAME_NOT_ALLOWED: 2028,
  META_NOT_FOUND: 2030,
  MATCH_NOT_FOUND: 2031,
  ODDS_NOT_FOUND: 2033,
  ODDSFIELD_NOT_FOUND: 2034,
  RECORD_NOT_FOUND: 2042,
  ACCOUNT_LOCAL_NOT_FOUND: 2043,
  ACCOUNT_GOOGLE_NOT_FOUND: 2044,
  ALREADY_IN_PROGRESS: 2046,
  GET_GAME_TOKEN_FAIL: 3001,
  GET_GAME_URL_FAIL: 3003,
  GET_GAME_IN_MAINTENANCE: 3004, // 게임사 점검
  VENDOR_DEPOSIT_FAIL: 3005, // 게임 입장을 위해 vendor 사에 egg 입금 실패
  VENDOR_WITHDRAW_FAIL: 3006, // 게임 퇴장을 위해 vendor 사에 egg 출금 실패
  SPACE_IN_PENDING: 3007,
  TRY_AGAIN: 3008,
}
