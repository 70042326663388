/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { IBankState, Schema$BankState } from '@protocol/bank'
import { BANK_MENU } from '~/constants/attrs'
import { numberWithCommas, popupCenter } from '~/utils/utils'
import apiUser from '@services/api/user'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'
import { DepositCoinStyle } from './index.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'

const BankStyle = styled(Box)(({ theme }) => ({
  '& .MuiGrid-item .MuiButton-root': {
    padding: 0,
    height: '41px',
    minWidth: 0,
    width: '100%',
  },
}))

const RequestDepositCoinOXO: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [fetching, setFetching] = useState(false)
  const [coin, setCoin] = useState(0)
  const [krw, setKRW] = useState(0)
  const [bonusChecked, setBonusChecked] = useState(-1)
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const { globalStore, userStore } = useRootStore()

  const refUrl = useRef<string>('')

  let canDeposit = false
  let txtDepositInfo = null
  let canBonus = false

  if (bankState) {
    canDeposit = bankState.can_deposit
    txtDepositInfo = bankState.deposit_coin.deposit_text

    canBonus = bankState.is_bonus_active
    if (!canBonus && bonusChecked !== 0) {
      setBonusChecked(0)
    }
  }

  const fetchBankState = async () => {
    try {
      const { state } = await apiUser.getBankState({})
      setBankState(state)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchCoin = async () => {
    try {
      setFetching(true)
      const { coin, krw, url } = await apiUser.getCoinOXOInfo({})
      refUrl.current = url
      setCoin(coin)
      setKRW(krw)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setFetching(false)
  }

  useEffect(() => {
    fetchBankState()
    fetchCoin()
  }, [])

  const popupBuyCoin = async () => {
    try {
      if (!refUrl.current) {
        throw new Error(intl.formatMessage({ id: 'msg.charging_information_not_found' }))
      }

      const w = 600
      const h = 900

      const url = `${refUrl.current}&id=${userStore.userInfo.id}`
      popupCenter({ url, title: 'popup_id', w, h })
    } catch (err) {
      globalStore.pushDialogOk({
        title: intl.formatMessage({ id: 'error' }),
        text: err.message,
      })
    }
  }

  const fetchDepositCoin = async () => {
    try {
      if (bonusChecked === -1) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.check.recharge_bonus' }),
        })
        return
      }
      await apiUser.depositCoinOXO({
        coin: coin,
        krw: krw,
        getBonus: bonusChecked === 1,
      })
      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-deposit-done' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickChargeCoin = () => {
    popupBuyCoin()
  }

  const onClickCheckCoin = () => {
    fetchCoin()
  }

  const onClickTransferCoin = () => {
    fetchDepositCoin()
  }

  const handleChangeBonus = (v: number) => {
    setBonusChecked(v)
  }

  return (
    <BankStyle sx={{ py: 3 }}>
      <DepositCoinStyle>
        <Typography component="div">※ 코인 충전 안내</Typography>
        <NeatTextField
          multiline
          defaultValue={txtDepositInfo}
          InputProps={{ readOnly: true }}
        ></NeatTextField>
      </DepositCoinStyle>

      <Box sx={{ pt: 6, m: 'auto', width: '240px' }}>
        <FormStyle>
          <Grid container columnSpacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <BasicButton
                disabled={fetching}
                onClick={() => onClickChargeCoin()}
                className="btn_main"
                sx={{ display: 'block', margin: 'auto', width: '240px' }}
              >
                {intl.formatMessage({ id: 'component.bank.deposit.coin' })}
              </BasicButton>
            </Grid>
          </Grid>
        </FormStyle>
      </Box>

      <Box sx={{ pt: 6, m: 'auto', width: '468px' }}>
        <FormStyle>
          <Grid container columnSpacing={1} sx={{ mt: 1 }}>
            <Grid item xs={9}>
              <Typography className="form_control readonly" sx={{ textAlign: 'end' }}>
                {numberWithCommas(coin)} 코인
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <BasicButton
                disabled={fetching}
                onClick={() => onClickCheckCoin()}
                className="btn_outline_main"
              >
                코인조회
              </BasicButton>
            </Grid>
          </Grid>
          <Grid container columnSpacing={1} sx={{ mt: 1 }}>
            <Grid item xs={9}>
              <Typography className="form_control readonly" sx={{ textAlign: 'end' }}>
                {numberWithCommas(krw)} 원
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <BasicButton
                disabled={fetching}
                onClick={() => onClickTransferCoin()}
                className="btn_main"
              >
                신청하기
              </BasicButton>
            </Grid>
          </Grid>
          {canBonus ? (
            <Grid container sx={{ mt: 2, alignItems: 'center', justifyContent: 'center' }}>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bonusChecked === 1}
                        onChange={() => handleChangeBonus(1)}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                      />
                    }
                    sx={{ color: '#D1D9E3', justifyContent: 'center' }}
                    label="첫충(매충) 보너스 받기"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bonusChecked === 0}
                        onChange={() => handleChangeBonus(0)}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                      />
                    }
                    sx={{ color: '#D1D9E3', justifyContent: 'center' }}
                    label="첫충(매충) 보너스 받지 않기"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          ) : null}
        </FormStyle>
      </Box>
    </BankStyle>
  )
}

export default observer(RequestDepositCoinOXO)
