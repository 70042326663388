import * as _ from 'lodash'

import { ODDS_COLOR, MarketMatrixMap } from './common'

export const KINO_MARKET_GROUP = {
  ALL: 0, // 1개만 선택
  KL: 1,
  KS: 2,
  MAX: 3,
}

// PL A 그룹
export const KINO_MARKET_GROUP_TEXT = ['', 'KL', 'KS']

// category/marketGroup/matrix
export const MarketMatrix: MarketMatrixMap = {
  KL: {
    NORMAL: [
      [
        { marketId: 'KLODD_KLEVEN', id: 'KLODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'KLODD_KLEVEN', id: 'KLEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'KLUNDER_KLOVER', id: 'KLUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'KLUNDER_KLOVER', id: 'KLOVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'KLODD_KLEVEN+KLUNDER_KLOVER',
          id: 'KLODD+KLUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'KLODD_KLEVEN+KLUNDER_KLOVER',
          id: 'KLODD+KLOVER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'KLODD_KLEVEN+KLUNDER_KLOVER',
          id: 'KLEVEN+KLUNDER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
        {
          marketId: 'KLODD_KLEVEN+KLUNDER_KLOVER',
          id: 'KLEVEN+KLOVER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
      ],
      // [
      //   { marketId: 'KLABCD', id: 'KLA', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      //   { marketId: 'KLABCD', id: 'KLB', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      //   { marketId: 'KLABCD', id: 'KLC', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      //   { marketId: 'KLABCD', id: 'KLD', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      // ],
    ],
  },
  KS: {
    NORMAL: [
      [
        { marketId: 'KSODD_KSEVEN', id: 'KSODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'KSODD_KSEVEN', id: 'KSEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'KSUNDER_KSOVER', id: 'KSUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'KSUNDER_KSOVER', id: 'KSOVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'KSODD_KSEVEN+KSUNDER_KSOVER',
          id: 'KSODD+KSUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'KSODD_KSEVEN+KSUNDER_KSOVER',
          id: 'KSODD+KSOVER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        {
          marketId: 'KSODD_KSEVEN+KSUNDER_KSOVER',
          id: 'KSEVEN+KSUNDER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
        {
          marketId: 'KSODD_KSEVEN+KSUNDER_KSOVER',
          id: 'KSEVEN+KSOVER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
        },
      ],
      // [
      //   { marketId: 'KSABCD', id: 'KSA', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      //   { marketId: 'KSABCD', id: 'KSB', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      //   { marketId: 'KSABCD', id: 'KSC', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      //   { marketId: 'KSABCD', id: 'KSD', acolor: ODDS_COLOR.brown_a, dcolor: ODDS_COLOR.brown_d, odds: null },
      // ],
    ],
  },
  // COMBO: {
  //   NORMAL: [
  //     [
  //       {
  //         marketId: 'KLODD_KLEVEN+KSODD_KSEVEN',
  //         id: 'KLODD+KSODD',
  //         acolor: ODDS_COLOR.blue_a,
  //         dcolor: ODDS_COLOR.blue_d,
  //       },
  //       {
  //         marketId: 'KLODD_KLEVEN+KSODD_KSEVEN',
  //         id: 'KLODD+KSEVEN',
  //         acolor: ODDS_COLOR.blue_a,
  //         dcolor: ODDS_COLOR.blue_d,
  //       },
  //       {
  //         marketId: 'KLODD_KLEVEN+KSODD_KSEVEN',
  //         id: 'KLEVEN+KSODD',
  //         acolor: ODDS_COLOR.red_a,
  //         dcolor: ODDS_COLOR.red_d,
  //       },
  //       {
  //         marketId: 'KLODD_KLEVEN+KSODD_KSEVEN',
  //         id: 'KLEVEN+KSEVEN',
  //         acolor: ODDS_COLOR.red_a,
  //         dcolor: ODDS_COLOR.red_d,
  //       },
  //     ],
  //   ],
  // },
}

export const GetKinoOddsColor = (category: string, marketGroup: number, marketId: string, oddsId: string): string => {
  if (!_.has(MarketMatrix, category)) {
    return ''
  }
  if (marketGroup >= KINO_MARKET_GROUP.MAX) {
    return ''
  }
  const txtMargetGroup = KINO_MARKET_GROUP_TEXT[marketGroup]
  if (!_.has(MarketMatrix[category], txtMargetGroup)) {
    return ''
  }
  for (const row of MarketMatrix[category][txtMargetGroup]) {
    const found = _.find(row, { marketId, id: oddsId })
    if (found) {
      return found.acolor
    }
  }
  return ''
}
