/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as _ from 'lodash'
import { useHistory } from 'react-router-dom'
//import WhiteTheme from '@themes/white.theme'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { BANK_MENU } from '~/constants/attrs'
import { numberWithCommas, popupCenter } from '~/utils/utils'
import { IBankState, Schema$BankState } from '@protocol/bank'
import apiUser from '@services/api/user'

// css
import { FormStyle } from '@styles/modal.style'
import { BasicButton, ItemButton } from '@styles/button.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'
import { ModalKeys } from '~/store/globalStore'
import { DepositCoinStyle } from '../index.style'

const SITE_ID = process.env.REACT_APP_BITDEX_SITE_ID || ''
const API_KEY = process.env.REACT_APP_BITDEX_API_KEY || ''
const API_BUY_URL = process.env.REACT_APP_BITDEX_API_BUY_URL || ''

const RequestDepositCoinBitdex: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [coin, setCoin] = useState(0)
  const [bonusChecked, setBonusChecked] = useState(-1)
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const { globalStore, userStore } = useRootStore()
  const { userInfo } = userStore

  const krw = coin * 1000

  let canDeposit = false
  let txtDepositInfo = null
  let canBonus = false

  if (bankState) {
    canDeposit = bankState.can_deposit
    txtDepositInfo = bankState.deposit_coin.deposit_text

    canBonus = bankState.is_bonus_active
    if (!canBonus && bonusChecked !== 0) {
      setBonusChecked(0)
    }
  }

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  useEffect(() => {
    fetchBankState({})
    fetchCoin()
  }, [])

  const fetchCoin = async () => {
    try {
      const { balance } = await apiUser.getCoinInfo({})
      setCoin(balance)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }
  const fetchBuyCoin = async () => {
    try {
      // const { userid, password, siteid, apikey } = await apiUser.getCoinBuyInfo({})
      const { userid, password, siteid, apikey, error, message } = {
        userid: userInfo.id,
        password: '',
        siteid: SITE_ID,
        apikey: API_KEY,
        error: null,
        message: null,
      }

      if (error) {
        globalStore.pushDialogOk({
          text: message || error,
        })
      } else {
        const url = API_BUY_URL
        var form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', url)
        form.setAttribute('target', 'popup_id')

        var input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'id'
        input.value = userid
        form.appendChild(input)

        input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'site'
        input.value = siteid
        form.appendChild(input)

        input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'apikey'
        input.value = apikey
        form.appendChild(input)

        document.body.appendChild(form)

        const w = 600
        const h = (window.screen.height * 80) / 100

        popupCenter({ url, title: 'popup_id', w, h })

        form.submit()

        document.body.removeChild(form)
      }
    } catch (err) {
      globalStore.pushDialogOk({
        title: intl.formatMessage({ id: 'error' }),
        text: err.message,
      })
    }
  }

  const fetchDepositCoin = async () => {
    try {
      if (bonusChecked === -1) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.check.recharge_bonus' }),
        })
        return
      }
      const { withdraw, balance } = await apiUser.depositCoin({
        coin: coin,
        getBonus: bonusChecked === 1,
      })
      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-deposit-done' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickChargeCoin = () => {
    fetchBuyCoin()
  }

  const onClickCheckCoin = () => {
    fetchCoin()
  }

  const onClickTransferCoin = () => {
    fetchDepositCoin()
  }

  const onClickManual = (type: string) => {
    globalStore.showModal(ModalKeys.menual_coin, 0)
  }

  const handleChangeBonus = (v: number) => {
    setBonusChecked(v)
  }

  return (
    <>
      <form name="popForm">
        <input type="hidden" name="userid" value="test01" />
        <input type="hidden" name="password" value="14321432" />
        <input type="hidden" name="siteid" value="SITEID" />
        <input type="hidden" name="apikey" value="APIKEY" />
      </form>

      <Grid container sx={{ py: 2, justifyContent: 'center' }}>
        <Grid item xs={10}>
          <Box sx={{ mb: 1, textAlign: 'end' }}>
            <BasicButton
              className="btn_default"
              onClick={() => onClickManual('coin')}
              sx={{ display: 'inline-block' }}
            >
              코인충전 메뉴얼
            </BasicButton>
          </Box>
          <DepositCoinStyle>
            <Typography component="div">※코인 충전 안내</Typography>
            <NeatTextField
              multiline
              defaultValue={txtDepositInfo}
              InputProps={{ readOnly: true }}
            ></NeatTextField>
          </DepositCoinStyle>

          <Box sx={{ pt: 4, pb: 2, m: 'auto', width: '300px' }}>
            <BasicButton
              onClick={() => onClickChargeCoin()}
              className="btn_main"
              sx={{ display: 'block', margin: 'auto', width: '240px' }}
            >
              {intl.formatMessage({ id: 'component.bank.deposit.coin' })}
            </BasicButton>

            <FormStyle>
              <Grid
                container
                spacing={1}
                sx={{
                  pt: 3,
                  '& .MuiGrid-item .MuiButton-root': {
                    padding: 0,
                    height: '41px',
                    minWidth: 0,
                    width: '100%',
                  },
                }}
              >
                <Grid item xs={8}>
                  <Typography className="form_control readonly" sx={{ textAlign: 'end' }}>
                    {numberWithCommas(coin)} 코인
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <BasicButton onClick={() => onClickCheckCoin()} className="btn_outline_main">
                    코인조회
                  </BasicButton>
                </Grid>
                <Grid item xs={8}>
                  <Typography className="form_control readonly" sx={{ textAlign: 'end' }}>
                    {numberWithCommas(krw)} 원
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <BasicButton onClick={() => onClickTransferCoin()} className="btn_main">
                    신청하기
                  </BasicButton>
                </Grid>
              </Grid>
            </FormStyle>

            {canBonus ? (
              <Box sx={{ mt: 2 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bonusChecked === 1}
                        onChange={() => handleChangeBonus(1)}
                        sx={{
                          py: 1 / 2,
                          color: '#3B4A65',
                          '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' },
                        }}
                      />
                    }
                    sx={{ color: '#D1D9E3' }}
                    label="첫충(매충) 보너스 받기"
                  />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={bonusChecked === 0}
                        onChange={() => handleChangeBonus(0)}
                        sx={{
                          py: 1 / 2,
                          color: '#3B4A65',
                          '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' },
                        }}
                      />
                    }
                    sx={{ color: '#D1D9E3' }}
                    label="첫충(매충) 보너스 받지 않기"
                  />
                </FormGroup>
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(RequestDepositCoinBitdex)
