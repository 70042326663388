// import * as _ from 'lodash'

// // texas
// import TexasHeader from './texas/Header'
// import TexasHome from './texas/Home'

// // iphone
// import IphoneHeader from './iphone/Header'
// import IphoneHome from './iphone/Home'

// // sky2
// import Sky2Header from './sky2/Header'
// import Sky2Home from './sky2/Home'

// // marine
// import MarineHeader from './marine/Header'
// import MarineHome from './marine/Home'

// // marine2
// import Marine2Header from './marine2/Header'
// import Marine2Home from './marine2/Home'

// // band
// import BandHeader from './band/Header'
// import BandHome from './band/Home'

// // lounge
// import LoungeHeader from './lounge/Header'
// import LoungeHome from './lounge/Home'

// // round
// import RoundHeader from './round/Header'
// import RoundHome from './round/Home'

// // reve
// import ReveHeader from './reve/Header'
// import ReveHome from './reve/Home'

// // turtle
// import TurtleHeader from './turtle/Header'
// import TurtleHome from './turtle/Home'

// // mega
// import MegaHeader from './mega/Header'
// import MegaHome from './mega/Home'

// // mammoth
// import MammothHeader from './mammoth/Header'
// import MammothHome from './mammoth/Home'

// // ninecasino
// import NinecasinoHeader from './ninecasino/Header'
// import NinecasinoHome from './ninecasino/Home'

// // bolton2
// import Bolton2Header from './bolton2/Header'
// import Bolton2Home from './bolton2/Home'

// // noah
// import NoahHeader from './noah/Header'
// import NoahHome from './noah/Home'

// // star2
// import Star2Header from './star2/Header'
// import Star2Home from './star2/Home'

// // hunterbet
// import HunterbetHeader from './hunterbet/Header'
// import HunterbetHome from './hunterbet/Home'

// // marlboro
// import MarlboroHeader from './marlboro/Header'
// import MarlboroHome from './marlboro/Home'

// // quality
// import QualityHeader from './quality/Header'
// import QualityHome from './quality/Home'

// // blue
// import BlueHeader from './blue/Header'
// import BlueHome from './blue/Home'

// // rokmc
// import RokmcHeader from './rokmc/Header'
// import RokmcHome from './rokmc/Home'

// // star
// import StarHeader from './star/Header'
// import StarHome from './star/Home'

// // taepyeongyang
// import TaepyeongyangHeader from './taepyeongyang/Header'
// import TaepyeongyangHome from './taepyeongyang/Home'

// // hera
// import HeraHeader from './hera/Header'
// import HeraHome from './hera/Home'

// interface IChannelRef {
//   Header: any
//   Home: any
// }

// const ref = {
//   texas: {
//     Header: TexasHeader,
//     Home: TexasHome,
//   },
//   iphone: {
//     Header: IphoneHeader,
//     Home: IphoneHome,
//   },
//   sky2: {
//     Header: Sky2Header,
//     Home: Sky2Home,
//   },
//   marine: {
//     Header: MarineHeader,
//     Home: MarineHome,
//   },
//   marine2: {
//     Header: Marine2Header,
//     Home: Marine2Home,
//   },
//   band: {
//     Header: BandHeader,
//     Home: BandHome,
//   },
//   lounge: {
//     Header: LoungeHeader,
//     Home: LoungeHome,
//   },
//   round: {
//     Header: RoundHeader,
//     Home: RoundHome,
//   },
//   reve: {
//     Header: ReveHeader,
//     Home: ReveHome,
//   },
//   turtle: {
//     Header: TurtleHeader,
//     Home: TurtleHome,
//   },
//   mega: {
//     Header: MegaHeader,
//     Home: MegaHome,
//   },
//   mammoth: {
//     Header: MammothHeader,
//     Home: MammothHome,
//   },
//   ninecasino: {
//     Header: NinecasinoHeader,
//     Home: NinecasinoHome,
//   },
//   bolton2: {
//     Header: Bolton2Header,
//     Home: Bolton2Home,
//   },
//   noah: {
//     Header: NoahHeader,
//     Home: NoahHome,
//   },
//   star2: {
//     Header: Star2Header,
//     Home: Star2Home,
//   },
//   hunterbet: {
//     Header: HunterbetHeader,
//     Home: HunterbetHome,
//   },
//   marlboro: {
//     Header: MarlboroHeader,
//     Home: MarlboroHome,
//   },
//   quality: {
//     Header: QualityHeader,
//     Home: QualityHome,
//   },
//   blue: {
//     Header: BlueHeader,
//     Home: BlueHome,
//   },
//   rokmc: {
//     Header: RokmcHeader,
//     Home: RokmcHome,
//   },
//   star: {
//     Header: StarHeader,
//     Home: StarHome,
//   },
//   taepyeongyang: {
//     Header: TaepyeongyangHeader,
//     Home: TaepyeongyangHome,
//   },
//   hera: {
//     Header: HeraHeader,
//     Home: HeraHome,
//   },
// }

// const channelSymbol = process.env.REACT_APP_CHANNEL || ''
// let found = _.get(ref, channelSymbol)
// if (!found) {
//   found = ref.texas
// }

// export default found as IChannelRef

interface IChannelRef {
  Header: any
  Home: any
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
const ref: IChannelRef = {
  Header: require(`./${channelSymbol}/Header`).default,
  Home: require(`./${channelSymbol}/Home`).default,
}
export default ref
