import { css, Theme } from '@emotion/react'
import '@assets/fonts/font.css'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let bodyMinWidth = '1248px'
let rootPosition = ''
let contentWrapPadding = 'padding: 16px 16px 0'
let contentWrapMargin = 'margin: auto'
let contentWrapMaxWidth = ''
let innerContainer = ''

if (channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk') {
  bodyMinWidth = '1648px'
}

if (channelSymbol === 'marine2') {
  bodyMinWidth = '1658px'
  rootPosition = 'position: relative'
  contentWrapPadding = 'padding: 0'
  contentWrapMargin = 'margin: 0 0 0 290px'
  innerContainer = 'min-width: 1368px'
}

if (channelSymbol === 'band' || channelSymbol === 'world' || channelSymbol === 'water' || channelSymbol === 'emart') {
  bodyMinWidth = '1840px'
  rootPosition = 'position: relative'
  contentWrapPadding = 'padding: 0'
  contentWrapMargin = 'margin: 0 0 0 290px !important'
  contentWrapMaxWidth = 'max-width: 1248px'
  innerContainer = 'min-width: 1248px'
}

if (channelSymbol === 'today' || channelSymbol === 'hongyeom' || channelSymbol === 'ace' || channelSymbol === 'ssg' || channelSymbol === 'praha') {
  bodyMinWidth = '1448px'
  //rootPosition = 'position: relative'
  //contentWrapPadding = 'padding: 0'
  //contentWrapMargin = 'margin: 0 0 0 290px'
  innerContainer = 'min-width: 1448px'
}

export const GlobalStyle = (theme: Theme) => css`
  body {
    font-family: ${theme.typography.fontFamily};
    background: ${theme.colors.darken};
    overflow-y: scroll;
  }

  #root {
    ${rootPosition};
  }

  .swal2-container {
    z-index: 1301;
  }

  .content_wrap {
    min-height: calc(100vh - ${theme.height.header + theme.height.footer}px);
    ${contentWrapPadding};
    ${contentWrapMargin};
    ${contentWrapMaxWidth};
  }
  .content_wrap .MuiContainer-root {
    ${innerContainer}
  }
  .content_wrap .MuiImageList-root {
    overflow: initial;
  }
  .content_wrap_m .MuiImageList-root {
    overflow: initial;
  }

  .content_wrap_marine_partner {
    min-height: calc(100vh - ${theme.height.header + theme.height.footer - 60}px);
    ${contentWrapPadding};
    ${contentWrapMargin};
  }

  @media (min-width: 576px) {
    .swal2-shown .swal2_shown_cart_padding {
      padding-right: 17px;
    }
  }
  @media (min-width: 992px) {
    html,
    body {
      min-width: ${bodyMinWidth};
    }
    body {
      background: ${theme.colors.subBg};
      background-size: 100% auto;
      background-position: 0 98px;
      //padding-right: 0 !important;
    }
    body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
`
