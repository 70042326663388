/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import { Container, Tab, Tabs, Grid, Pagination, Box, Typography } from '@mui/material'

//css
import { BoardStyle } from '@styles/modal.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'

interface Props {
  content: string
}

function MessageView(props: Props) {
  const { content } = props
  return (
    <BoardStyle className="board_view">
      <Box className="board_content" sx={{ py: 2 }}>
        {/* <Box sx={{ width: '90%', m: 'auto' }}>
          <NeatTextField
            multiline
            defaultValue={content}
            InputProps={{ readOnly: true }}
            inputProps={{ style: { color: 'white' } }}
            sx={{ width: '100%' }}
          ></NeatTextField>
        </Box> */}
        <Box className="board_content" sx={{ py: 6 }}>
          <Box sx={{ width: '90%', m: 'auto', color: 'white' }} dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }} />
        </Box>
      </Box>
    </BoardStyle>
  )
}

export default MessageView
