/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Table } from 'react-bootstrap'
import { Chip, Box, IconButton, Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { useOnMount } from '@utils/reactExt'
import useRootStore from '@store/useRootStore'
import { default as userApi } from '@services/api/user'
import { numberWithCommas } from '@utils/utils'
import apiUser from '@services/api/user'
import { IBankHistory } from '~/v2/protocol/bank'
import { BONUS_KIND, BONUS_TYPE } from '~/v2/interface/config'

//css
import { PaginationStyle } from '@styles/base.style'
import { TableStyle, NoList } from '@styles/modal.style'

//icon
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

interface PageData {
  currPage: number
  totalPage: number
}

type BankHistoryTrackData = IBankHistory.Schema

const getPageData = (track: BankHistoryTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

function BankHistory() {
  const intl = useIntl()
  const [track, setTrack] = useState<BankHistoryTrackData | null>(null)
  const { globalStore } = useRootStore()

  useEffect(() => {
    fetch({ offset: 0, limit: ItemPerPage })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [track])

  const fetch = async (params: any) => {
    try {
      const { offset, limit, total, items } = await apiUser.bankHistory(params)
      const data: BankHistoryTrackData = {
        offset,
        limit,
        total,
        items,
      }
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return null
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ offset, limit: ItemPerPage })
  }

  const onDeleteRecord = (_id: number) => {
    async function testDeleteRecord(id: number) {
      try {
        await apiUser.deleteBankHistoryItem({ id: id })
        fetch({ offset: 0, limit: ItemPerPage })
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }
    }
    testDeleteRecord(_id)
  }

  const listItems = []

  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    for (const el of track.items) {
      let txtKind = null
      switch (el.kind) {
        case 'IN':
          // txtKind = intl.formatMessage({ id: 'component.bank.deposit' })
          txtKind = (
            <Chip
              label={intl.formatMessage({ id: 'component.bank.deposit' })}
              sx={{ backgroundColor: '#1d9eb4' }}
            />
          )
          break
        case 'OUT':
          // txtKind = intl.formatMessage({ id: 'component.bank.withdraw' })
          txtKind = (
            <Chip
              label={intl.formatMessage({ id: 'component.bank.withdraw' })}
              sx={{ backgroundColor: '#d22e2e' }}
            />
          )
          break
      }

      const dateFormatted = moment(el.date).format('YYYY-MM-DD HH:mm')
      const txtVolume = numberWithCommas(el.volume)
      let txtBonus = null
      let txtBonusName = null
      if (el.bonus > 0) {
        txtBonus = `${numberWithCommas(el.bonus)} P`
        if (el.bonus_kind !== 'NONE') {
          const objBonusKind = _.find(BONUS_KIND, o => {
            return o.id === el.bonus_kind
          })
          txtBonusName = objBonusKind && objBonusKind.name
        }
      }
      let canDeleteRecord = false
      let txtState = <div></div>
      switch (el.state) {
        case 'REQUEST':
          const requestName =
            el.kind === 'IN'
              ? intl.formatMessage({ id: 'bank.progress.REQUEST_IN' })
              : intl.formatMessage({ id: 'bank.progress.REQUEST_OUT' })
          txtState = <Chip label={requestName} sx={{ backgroundColor: '#FBC342' }} />
          break
        case 'PROCESS':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.PROCESS' })}
              sx={{ backgroundColor: '#3B4C6E' }}
            />
          )
          break
        case 'SUCCESS':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.SUCCESS' })}
              sx={{ backgroundColor: '#2366CB' }}
            />
          )
          canDeleteRecord = true
          break
        case 'CANCEL':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.CANCEL' })}
              sx={{ backgroundColor: '#FE3D3D' }}
            />
          )
          canDeleteRecord = true
          break
        case 'FAIL':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.FAIL' })}
              sx={{ backgroundColor: '#FE3D3D' }}
            />
          )
          canDeleteRecord = true
          break
      }
      let eDeleteRecord = null
      if (canDeleteRecord) {
        eDeleteRecord = (
          <Tooltip title="내역을 삭제합니다.">
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              onClick={() => onDeleteRecord(el.id)}
              aria-label="close"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        )
      }
      listItems.push(
        <tr key={el.id}>
          <td>{txtKind}</td>
          <td className="text-end">
            {txtVolume}&nbsp;{intl.formatMessage({ id: 'money-locale' })}
          </td>
          <td className="text-end">{txtBonus}</td>
          <td>{txtBonusName}</td>
          <td>{dateFormatted}</td>
          <td>
            {txtState}
            {eDeleteRecord}
          </td>
        </tr>,
      )
    }
  }

  let emptyContents = null
  if (listItems.length === 0) {
    emptyContents = (
      <NoList>
        {/* <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} /> */}
        <p>{intl.formatMessage({ id: 'msg.bank.no-history' })}</p>
      </NoList>
    )
  }

  return (
    <Box sx={{ py: 3 }}>
      <TableStyle className="table-responsive">
        <Table striped>
          <thead>
            <tr>
              <th style={{ minWidth: '50px' }}>{intl.formatMessage({ id: 'table.kind' })}</th>
              <th style={{ textAlign: 'right', minWidth: '90px' }}>
                {intl.formatMessage({ id: 'table.req-volume' })}
              </th>
              <th style={{ textAlign: 'right', minWidth: '90px' }}>
                {intl.formatMessage({ id: 'table.bonus' })}
              </th>
              <th style={{ minWidth: '90px' }}>{intl.formatMessage({ id: 'table.bonus_type' })}</th>
              <th style={{ minWidth: '150px' }}>{intl.formatMessage({ id: 'table.date' })}</th>
              <th style={{ minWidth: '100px' }}>{intl.formatMessage({ id: 'table.progress' })}</th>
            </tr>
          </thead>
          <tbody>{listItems}</tbody>
        </Table>
        {emptyContents}
      </TableStyle>

      <PaginationStyle
        count={totalTrackPage}
        variant="outlined"
        page={currTrackPage}
        onChange={handlePagingChange}
      />
    </Box>
  )
}

export default BankHistory
