/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import { Box, Divider, Chip } from '@mui/material'
import PortableText from 'react-portable-text'

//css
import { BoardStyle } from '@styles/modal.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'

const dividerStyle = {
  mb: 1,
  '&:before': {
    display: 'none',
  },
  '&::after': {
    borderTopStyle: 'dashed',
    borderColor: 'rgb(255 255 255 / 30%)',
  },
  '& .MuiDivider-wrapper': {
    paddingLeft: 0,
  },
}

interface Props {
  question: string
  answer: string
  state: string
}

function QnaView(props: Props) {
  const { question, answer, state } = props
  return (
    <BoardStyle className="board_view">
      <Box className="board_content" sx={{ py: 2 }}>
        <Box
          sx={{
            width: '90%',
            m: 'auto',
            '& > pre': {
              mb: 0,
              fontFamily: 'Noto Sans',
              fontSize: '1.1rem',
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            },
          }}
        >
          <pre>{question}</pre>
        </Box>
        {state === 'ANSWER' ? (
          <Box sx={{ px: 3, mt: 3 }}>
            <Divider textAlign="left" sx={dividerStyle}>
              <Chip label="관리자 답변" color="primary" sx={{ height: '20px', backgroundColor: '#2366CB' }} />
            </Divider>
            {/* <NeatTextField
              multiline
              defaultValue={answer}
              InputProps={{ readOnly: true }}
              inputProps={{ style: { color: '#d1d9e3' } }}
              sx={{ width: '100%' }}
            ></NeatTextField> */}
            <div
              dangerouslySetInnerHTML={{ __html: answer.replace(/\n/g, '<br />') }}
              style={{ width: '100%', color: '#D1D9E3' }}
            />
          </Box>
        ) : null}
      </Box>
    </BoardStyle>
  )
}


export default QnaView
