/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect } from 'react'
import { Grid, Box, GlobalStyles, Typography, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CasinoComing } from '@assets/images/layout_1'
import { useHistory } from 'react-router-dom'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'

const TextStyle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: theme.colors.listTitle,
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

function CoinWikiDone(props) {
  return (
    <>
      <GlobalStyles
        styles={{
          html: { backgroundColor: appBackground },
          body: { backgroundColor: appBackground, backgroundPosition: 'top', backgroundSize: 'cover' },
        }}
      />
      <Grid container justifyContent="center" alignItems="center" sx={{ maxWidth: 700, m: 'auto', height: '100vh' }}>
        <Grid item xs={10} sx={{ mt: '-40px', textAlign: 'center' }}>
          <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} />
          <TextStyle>충전이 완료되었습니다.</TextStyle>
          <TextStyle>창을 닫아주세요.</TextStyle>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(CoinWikiDone)
