import * as _ from 'lodash'
import { observable, action, computed, makeObservable } from 'mobx'
import { isString, extend } from 'lodash'

import { StoreExt } from '@utils/reactExt'
import { Schema$UserInfo, Schema$Auth } from '@interface/common'
import { ISignin, ISignup } from '@protocol/auth'
import { globalStore, userStore } from '@store/index'
import { LOCALSTORAGE_KEYS, STORAGE_KEY } from '@constants/index'
import { deleteStorageValue } from '@utils/utils'
import * as GameUtil from '@game/util'
import liveStore from '../liveStore'

import { ModalKeys } from '../globalStore'
import { IntlShape } from 'react-intl'

export type SignupParams = ISignup.Params
export type LoginParams = ISignin.Params
export type UserInfo = Schema$UserInfo
export type AuthInfo = Schema$Auth

export class AuthStore extends StoreExt {
  @observable initialized = false
  @observable sid: string = ''
  @observable signedin: boolean = false

  constructor() {
    super()

    makeObservable(this)
  }

  @computed
  get isSignedIn() {
    return this.signedin === true
  }

  @action
  recoverSession = async (intl: IntlShape) => {
    // console.log('AuthStore.recoverSession')
    try {
      const { sid, user, userMoney, userPoint, userComp } = await this.api.user.recover()
      userStore.setUser(user)
      userStore.setUserMoney(userMoney)
      userStore.setUserPoint(userPoint)
      userStore.setUserComp(userComp)
      userStore.setGameMoney(null)
      this.setSignedin(isString(user.id), sid)
      this.setInitialized(true)
      return true
    } catch (err) {
      globalStore.setNavigate('/')
      await this.reset()
      const { error } = err
      if (error !== 'UnAuthorizedException' && error !== 'DatabaseNotFoundException') {
        globalStore.pushErrorObject(err, intl)
      }
    }
    this.setInitialized(true)
    return false
  }

  @action
  setInitialized = (initialized: boolean) => {
    this.initialized = initialized
  }

  @action
  setSignedin = (signedin: boolean, sid: string) => {
    if (signedin !== this.signedin) {
      this.signedin = signedin
    }
    if (sid !== this.sid) {
      this.sid = sid
    }
    if (signedin) {
      liveStore.enter()
    } else {
      liveStore.cleanup()
    }
  }

  @action
  signup = async (params: SignupParams, intl: IntlShape) => {
    const self = this
    try {
      const browserData = GameUtil.makeBrowserData()
      extend(params, { browserData })
      const { user, userMoney, userState } = await this.api.auth.signup(params)
      globalStore.hidePopupAll()
      if (globalStore.option.useLoginCaptcha) {
        globalStore.pushDialogLocaleOk({ text: 'msg.become-member.please_login' })
        globalStore.showModal(ModalKeys.login, 0)
      } else {
        self.login({ username: params.username, password: params.password }, intl)
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
      await this.reset()
    }
  }

  @action
  login = async (params: LoginParams, intl: IntlShape): Promise<boolean> => {
    try {
      const browserData = GameUtil.makeBrowserData()
      extend(params, { browserData })
      const { sid, user, userMoney, userPoint, userComp } = await this.api.auth.login(params)
      userStore.setUser(user)
      userStore.setUserMoney(userMoney)
      userStore.setUserPoint(userPoint)
      userStore.setUserComp(userComp)
      userStore.setGameMoney(null)
      this.setSignedin(isString(user.id), sid)
      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
      await this.reset()
    }

    return false
  }

  @action
  logout = async (intl: IntlShape) => {
    try {
      // keep order, logout first, live disconnect second
      await this.api.auth.logout({})
      await this.reset()
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  @action
  reset = async () => {
    userStore.cleanup()
    globalStore.toggleSider(false)
    this.setSignedin(false, '')
  }
}

export default new AuthStore()
