import * as _ from 'lodash'

import { ODDS_COLOR, MarketMatrixMap } from './common'

export const WOORI_KENO_POWERBALL_MARKET_GROUP = {
  ALL: 0,
  PPOWER: 1,
  SSUPER: 2,
  COMBO: 3,
  MAX: 4,
}

export const WOORI_KENO_POWERBALL_MARKET_GROUP_TEXT = ['', 'POWER', 'SUPER', 'ODDEVEN']

// category/marketGroup/matrix
export const WooriKenoPowerballMarketMatrix: MarketMatrixMap = {
  PBP: {
    POWER: [
      [
        { marketId: 'PODD_PEVEN', id: 'PODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PODD_PEVEN', id: 'PEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'PUNDER_POVER', id: 'PUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PUNDER_POVER', id: 'POVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'PODD_PEVEN+PUNDER_POVER',
          id: 'PODD+PUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
        },
        { marketId: 'PODD_PEVEN+PUNDER_POVER', id: 'PODD+POVER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PODD_PEVEN+PUNDER_POVER', id: 'PEVEN+PUNDER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'PODD_PEVEN+PUNDER_POVER', id: 'PEVEN+POVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
    ],
  },
  PBS: {
    SUPER: [
      [
        { marketId: 'SODD_SEVEN', id: 'SODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SODD_SEVEN', id: 'SEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
        { marketId: 'SUNDER_SOVER', id: 'SUNDER', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'SUNDER_SOVER', id: 'SOVER', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'SODD_SEVEN+SUNDER_SOVER',
          id: 'SODD+SUNDER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'SODD_SEVEN+SUNDER_SOVER',
          id: 'SODD+SOVER',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'SODD_SEVEN+SUNDER_SOVER',
          id: 'SEVEN+SUNDER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
        {
          marketId: 'SODD_SEVEN+SUNDER_SOVER',
          id: 'SEVEN+SOVER',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
      ],
    ],
  },
  COMBO: {
    ODDEVEN: [
      [
        {
          marketId: 'PODD_PEVEN+SODD_SEVEN',
          id: 'PODD+SODD',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'PODD_PEVEN+SODD_SEVEN',
          id: 'PODD+SEVEN',
          acolor: ODDS_COLOR.blue_a,
          dcolor: ODDS_COLOR.blue_d,
          odds: null,
        },
        {
          marketId: 'PODD_PEVEN+SODD_SEVEN',
          id: 'PEVEN+SODD',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
        {
          marketId: 'PODD_PEVEN+SODD_SEVEN',
          id: 'PEVEN+SEVEN',
          acolor: ODDS_COLOR.red_a,
          dcolor: ODDS_COLOR.red_d,
          odds: null,
        },
      ],
    ],
  },
}

export const GetWooriKenoPowerballOddsColor = (
  category: string,
  marketGroup: number,
  marketId: string,
  oddsId: string,
): string => {
  // console.log(`category: ${category}, marketGroup: ${marketGroup}, marketId: ${marketId}, oddsId: ${oddsId}`)
  if (!_.has(WooriKenoPowerballMarketMatrix, category)) {
    return ''
  }
  if (marketGroup >= WOORI_KENO_POWERBALL_MARKET_GROUP.MAX) {
    return ''
  }
  const txtMargetGroup = WOORI_KENO_POWERBALL_MARKET_GROUP_TEXT[marketGroup]
  if (!_.has(WooriKenoPowerballMarketMatrix[category], txtMargetGroup)) {
    return ''
  }
  for (const row of WooriKenoPowerballMarketMatrix[category][txtMargetGroup]) {
    const found = _.find(row, { marketId, id: oddsId })
    if (found) {
      alert(found.acolor)
      return found.acolor
    }
  }
  return ''
}
