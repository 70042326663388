import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const FooterStyle = styled('div')`
  background-color: ${props=>props.theme.footer.background};
  font-size: ${props => props.theme.fonts.tiny};
  text-align: center;

  & .MuiContainer-root {
    margin: 0;
  }
  .address {
    color: #999;
  }
  .copyright {
    margin-top: 24px;
    color: ${props=>props.theme.footer.copyright};
    & > span {
      color: ${props => props.theme.colors.themeActive};
    }
  }
  .partner_img {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    filter: grayscale(1);
    opacity: .8;
  }
`