import React, { Fragment, useState, useEffect, useRef } from 'react'
import { jsx, css, keyframes } from '@emotion/react'
import * as _ from 'lodash'
import {
  Grid,
  Box,
  AppBar,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Badge from '@mui/material/Badge'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { ModalKeys } from '@store/globalStore'
import PartnerMenu from './PartnerMenu'
import { numberWithCommas } from '~/utils/utils'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import { propsToClassKey } from '@mui/styles'
import asset_channel from '@assets/channel'
import { APP_CHARGES } from '~/utils/utils'

// css
import { styled } from '@mui/material/styles'

// icon
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

// image
import { imgKakaotalk } from '@assets/images/layout_1'
import {
  icon_logout,
  icon_mypage,
  icon_coupon,
  icon_history,
  icon_message,
  icon_myinfo,
  icon_partner,
  icon_policy,
  icon_qna,
  icon_withdraw,
  icon_deposit,
  imgTelegram,
  bgTelegram,
} from '@assets/images/layout_3'

export const twinkle = keyframes`
  0% {
    background: #ff9a9a;
  }
  50% {
    background: #ff1c1c;
  }
  100% {
    background: #ff9a9a;
  }
`

const MobileHeaderStyle = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1201;

  .MuiAppBar-root {
    background: ${props => props.theme.colors.mobileHeaderBg};
    & .menu {
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .login {
      padding: 0;
      color: ${props => props.theme.colors.mobileTxt};
      .MuiTypography-root {
        font-size: 0.9rem;
      }
    }
    & .MuiDivider-root {
      align-self: auto;
      margin-left: 5px;
      height: 16px;
      border-color: #fff;
    }
    & .btn_partner {
      padding: 4px 8px;
      height: 26px;
      background-color: #3054c7;
      border-radius: 2px;
      color: ${props => props.theme.colors.btnTxtLighten};
      & > img {
        width: 15px;
      }
    }
  }

  .MuiGrid-container {
    height: 48px;
    border-bottom: 2px solid #fad028;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
    padding-right: 8px;
    height: 100%;
  }
`

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  top: '48px',

  '.MuiBackdrop-root': {
    top: '48px',
  },
  '& .MuiDrawer-paper': {
    top: '48px',
    width: '100%',
    maxHeight: 'calc(100% - 48px)',
    backgroundColor: theme.colors.drawerBg,
  },
}))

const GnbStyle = styled(Box)(({ theme }) => ({
  '& .myinfo': {
    backgroundColor: theme.colors.drawerTopBg,
    color: theme.colors.drawerSubTxt,
    '& .MuiListItemText-root': {
      flex: '0 1 auto',
    },
    '& .MuiListItemText-root > span': {
      color: '#fff',
    },
    '& .btn_transfer': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: 'linear-gradient(#fc6363 0%, #d50d0d 100%)',
      borderRadius: '4px',
      color: theme.colors.btnTxtLighten,
    },
    '& .btn_refresh': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: 'linear-gradient(#18b8f4 0%, #1372df 100%)',
      borderRadius: '4px',
      color: theme.colors.btnTxtLighten,
    },
  },
  '& .MuiDivider-root': {
    borderColor: theme.colors.drawerBorder,
  },
  '& .category': {
    backgroundColor: 'transparent',
    color: theme.colors.drawerSubTxt,
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  '& .game .MuiButton-root': {
    width: '100%',
    borderBottom: '1px solid #17b2f2',
    borderRadius: 0,
    backgroundColor: '#202631',
    color: theme.colors.drawerMenuTxt,
  },
  '& .service .MuiBadge-root': {
    display: 'block',
    '.MuiBadge-dot': {
      top: '8px',
      right: '8px',
      animation: `${twinkle} 0.5s ease-in infinite`,
    },
  },
  '& .service .MuiButton-root': {
    padding: '8px 5px',
    width: '100%',
    borderRadius: 0,
    backgroundColor: '#2f3239',
    color: theme.colors.drawerMenuTxt,
    '&.partner': {
      backgroundColor: '#3054c7',
    },
    '& > img': {
      marginRight: '8px',
      width: '15px',
      height: '15px',
    },
  },
  '& .game .MuiButton-root:hover, & .game .MuiButton-root.active': {
    color: '#caf0ff',
    textShadow: '#caf0ff 0 0 5px, #caf0ff 0 0 8px',
  },
}))

export const SnsLink = styled(Box)(({ theme }) => ({
  '& .MuiButton-root, & .no_link': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '32px 24px 8px',
    width: '200px',
    height: '64px',
    borderRadius: 0,
    background: `url(${bgTelegram}) center 0 no-repeat`,
    backgroundSize: '100% 100%',

    '& img': {
      marginRight: 8,
      width: 26,
      height: 26,
    },

    '& .id': {
      textTransform: 'none',
      background: 'linear-gradient(#ffde46 0%, #ffbd2b 100%)',
      backgroundClip: 'text',
      color: 'transparent',
      fontSize: theme.fonts.medium,
      fontWeight: 'bold',
    },
  },

  '& .MuiButton-root + .no_link': {
    marginTop: '16px',
  },
}))

let EXCLUDE_PARTNER_MENU_CLASS = []
if (process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) {
  EXCLUDE_PARTNER_MENU_CLASS =
    JSON.parse(process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) || []
}

function getLevelImage(level: number) {
  switch (level) {
    case 1:
      return asset_channel.common.icon_level01
    case 2:
      return asset_channel.common.icon_level02
    case 3:
      return asset_channel.common.icon_level03
    case 4:
      return asset_channel.common.icon_level04
    case 5:
      return asset_channel.common.icon_level05
    case 6:
      return asset_channel.common.icon_level06
    case 7:
      return asset_channel.common.icon_level07
    case 8:
      return asset_channel.common.icon_level08
    case 9:
      return asset_channel.common.icon_level09
    case 10:
      return asset_channel.common.icon_level10
  }
  return null
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let EXCLUDE_TOP_MENU = []
if (process.env.REACT_APP_EXCLUDE_TOP_MENU) {
  EXCLUDE_TOP_MENU = JSON.parse(process.env.REACT_APP_EXCLUDE_TOP_MENU) || []
}
const SHOW_GAME_MONEY = process.env.REACT_APP_SHOW_GAME_MONEY === 'true'

export interface HeaderProps {
  onClickNavPath: (path: string) => void
}

interface MobileHeaderProps {
  Header: React.ComponentType<HeaderProps>
}

export function Header(props: MobileHeaderProps) {
  const { Header } = props
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [listOpen, setListOpen] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const headerRef = useRef<HTMLInputElement>()
  const bannerRef = useRef<HTMLInputElement>()
  const [invisableUnread, setInvisableUnread] = useState(true)
  const [refreshEnabled, setRefreshEnabled] = useState(null)

  const { option, navChange, partnerMode } = globalStore
  const { initialized, signedin } = authStore
  const { notification, userInfo, userMoney, userPoint, userComp, game_money } = userStore
  const { nickname, isPartner, user_level, comp_level, merchant_class } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { balance: balanceComp } = userComp

  const { open_trading_groups, open_trading_kinds, enableComp, contact } = option || {}

  const contents_level = enableComp ? comp_level : user_level

  const couponActive = notification && notification.usableCouponCount > 0 ? 'active' : ''

  const isHome = window.location.pathname === '/'

  useEffect(() => {
    // do not delete!
    // rerender when notice page tab changed, so header nav can changed
  }, [navChange, partnerMode])

  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    if (currentPath === path || currentPathSearch === path) {
      return 'active'
    } else {
      return ''
    }
  }

  function isEnabled(trading_group: string): boolean {
    let foundGroup = null
    let foundKind = null

    if (open_trading_groups) {
      foundGroup = _.find(open_trading_groups, o => {
        return o.trading_group === trading_group
      })
    }

    if (open_trading_kinds) {
      foundKind = _.find(open_trading_kinds, o => {
        return o.trading_group === trading_group
      })
    }

    return foundGroup && foundKind
  }

  const handleList = index => {
    //setListOpen(!listOpen);
    if (listOpen === index) {
      setListOpen('')
    } else {
      setListOpen(index)
    }
  }

  const onClickMenu = () => {
    let location = headerRef.current.getBoundingClientRect().top
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
      if (isHome && location > 0) {
        const bannerHeight = bannerRef.current.getBoundingClientRect().height

        window.scrollTo({ top: bannerHeight })
      }
    }
  }

  const onClickButton = (name: string) => {
    switch (name) {
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'game-money':
        const timer = setTimeout(() => {
          setRefreshEnabled(null)
        }, 5000)
        setRefreshEnabled(timer)
        userStore.loadGameMoney(intl)
        return
      case 'withdraw':
        globalStore.pushDialogYesNo({
          text: intl.formatMessage({ id: 'msg.ask.gamemoney-to-ownmoney' }),
          callbackPositive: async () => {
            const timer = setTimeout(() => {
              setRefreshEnabled(null)
            }, 5000)
            setRefreshEnabled(timer)
            userStore.withdrawGameMoney(intl)
          },
        })
        return
    }

    setOpen(false)
  }

  const onClickMenuItem = (id: string, arg: string | number) => {
    switch (id) {
      case 'live-casino':
        history.push('/live-casino')
        globalStore.setNavChange('/live-casino')
        break
      case 'hotel-casino':
        history.push('/hotel-casino')
        globalStore.setNavChange('/hotel-casino')
        break
      case 'slot':
        history.push('/slot')
        globalStore.setNavChange('/slot')
        break
      case 'sports':
        history.push('/sports')
        globalStore.setNavChange('/sports')
        break
      case 'minigame':
        if (arg) {
          history.push(`/minigame/search?main=${arg}`)
          globalStore.setNavChange(`/minigame/search?main=${arg}`)
        } else {
          history.push('/minigame')
          globalStore.setNavChange('/minigame')
        }
        break
      case 'msl':
        history.push('/msl')
        globalStore.setNavChange('/msl')
        break
      case 'bank':
        history.push(`/bank/search?main=${arg}`)
        globalStore.setNavChange(`/bank/search?main=${arg}`)
        break
      case 'notice':
        history.push(`/notice/search?main=${arg}`)
        globalStore.setNavChange(`/notice/search?main=${arg}`)
        break
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.point.url}`)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.comp.url}`)
        break
      case 'myinfo':
        history.push(MYPAGE_TAB_VALUES.myinfo.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.myinfo.url}`)
        break
      case 'qnaPersonal':
        history.push(MYPAGE_TAB_VALUES.qnaPersonal.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.qnaPersonal.url}`)
        break
      // case 'qnaPartner':
      //   history.push(MYPAGE_TAB_VALUES.qnaPartner.url)
      //   break
      case 'login':
        globalStore.showModal(ModalKeys.login, 0)
        break
      case 'join':
        globalStore.showModal(ModalKeys.login, 1)
        break
      case 'message':
        globalStore.showModal('message', 0)
        break
      case 'coupon':
        globalStore.showModal('coupon', 0)
        break
    }

    setOpen(false)
    window.scrollTo(0, 0)
  }

  const onClickPartner = () => {
    history.push('/partner')
    setAnchorEl(null)
    setOpen(false)
    window.scrollTo(0, 0)
  }
  const onClickLogout = () => {
    history.push('/')
    authStore.logout(intl)
    setAnchorEl(null)
    setOpen(false)
  }

  const onClickLogo = () => {
    history.push('/')
    setOpen(false)
    if (signedin) {
      userStore.loadAsset(intl)
    }
  }

  let kakaotalk = ''
  let kakaotalk_url = ''
  let telegram = ''
  if (contact) {
    kakaotalk = contact.kakaotalk
    kakaotalk_url = contact.kakaotalk_url
    telegram = contact.telegram
  }

  const onClickOpenTelegram = id => {
    // @를 제외한 아이디
    window.open(`https://t.me/${id}`)
  }

  const onClickOpenKakaotalk = url => {
    if (url) {
      window.open(url)
    }
  }

  const showNotice = EXCLUDE_TOP_MENU.indexOf('NOTICE') < 0
  const showEvent = EXCLUDE_TOP_MENU.indexOf('EVENT') < 0
  const showPolicy = EXCLUDE_TOP_MENU.indexOf('POLICY') < 0
  const showQna = EXCLUDE_TOP_MENU.indexOf('QNA') < 0
  const showPartnerMenu = EXCLUDE_PARTNER_MENU_CLASS.indexOf(merchant_class) < 0

  let rightContents = null
  let badgeContents = (
    <Grid item xs={4}>
      <Badge color="error" variant="dot" invisible={invisableUnread}>
        <Button size="large" onClick={() => onClickMenuItem('message', '')}>
          <img src={icon_message} alt="message" />
          {intl.formatMessage({ id: 'component.message' })}
        </Button>
      </Badge>
    </Grid>
  )

  if (initialized) {
    // if (!badgeContents) {
    //   badgeContents=(
    //   <Grid item xs={4}>
    //     <Badge color="error" variant="dot" invisible={invisableUnread}>
    //       <Button
    //         size="large"
    //         onClick={() => onClickMenuItem('message', '')}
    //       >
    //         <img src={icon_message} alt="message" />
    //         {intl.formatMessage({ id: 'component.message' })}
    //       </Button>
    //     </Badge>
    //   </Grid>
    // )}
    if (signedin) {
      if (notification) {
        setInvisableUnread(false)
        // const { unread } = notification
        // if (unread > 0) {
        //   console.log(unread, notification)
        //   badgeContents = (
        //     <Grid item xs={4}>
        //       <Badge color="error" variant="dot" invisible={invisableUnread}>
        //         <Button
        //           size="large"
        //           onClick={() => onClickMenuItem('message', '')}
        //         >
        //           <img src={icon_message} alt="message" />
        //           {unread}
        //         </Button>
        //       </Badge>
        //     </Grid>
        //   )
        // }
      }

      rightContents = (
        <>
          {showPartnerMenu ? (
            <Button size="large" className="btn_partner" onClick={onClickPartner}>
              <img src={icon_partner} alt="partner" />
              {intl.formatMessage({ id: 'component.partner' })}
            </Button>
          ) : null}
        </>
      )
    } else {
      rightContents = (
        <>
          <Button
            className="login"
            onClick={() => onClickMenuItem('join', '0')}
            sx={{ minWidth: '45px' }}
          >
            <Typography>{intl.formatMessage({ id: 'signup' })}</Typography>
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button
            className="login"
            onClick={() => onClickMenuItem('login', '0')}
            sx={{ minWidth: '45px' }}
          >
            <Typography>{intl.formatMessage({ id: 'login' })}</Typography>
          </Button>
        </>
      )
    }
  }

  // Drawer----------
  const chargeContents = []
  if (APP_CHARGES.indexOf('deposit') >= 0) {
    chargeContents.push(
      <Grid item xs={4}>
        <Button
          size="large"
          className={`${navActive('?main=deposit')}`}
          onClick={() => {
            onClickMenuItem('bank', 'deposit')
          }}
        >
          <img src={icon_deposit} alt="deposit" />
          {intl.formatMessage({ id: 'component.bank.deposit.normal' })}
        </Button>
      </Grid>,
    )
  }
  if (APP_CHARGES.indexOf('depositOnepay') >= 0) {
    chargeContents.push(
      <Grid item xs={4}>
        <Button
          size="large"
          className={`${navActive('?main=depositOnepay')}`}
          onClick={() => {
            onClickMenuItem('bank', 'depositOnepay')
          }}
        >
          {intl.formatMessage({ id: 'component.bank.deposit.onepay' })}
        </Button>
      </Grid>,
    )
  }
  if (APP_CHARGES.indexOf('depositCoin') >= 0) {
    chargeContents.push(
      <Grid item xs={4}>
        <Button
          size="large"
          className={`${navActive('?main=depositCoin')}`}
          onClick={() => {
            onClickMenuItem('bank', 'depositCoin')
          }}
        >
          {intl.formatMessage({ id: 'component.bank.deposit.coin' })}
        </Button>
      </Grid>,
    )
  }
  if (APP_CHARGES.indexOf('depositCoinJun200') >= 0) {
    chargeContents.push(
      <Grid item xs={4}>
        <Button
          size="large"
          className={`${navActive('?main=depositCoinJun200')}`}
          onClick={() => {
            onClickMenuItem('bank', 'depositCoinJun200')
          }}
        >
          {intl.formatMessage({ id: 'component.bank.deposit.coin' })}
        </Button>
      </Grid>,
    )
  }
  if (APP_CHARGES.indexOf('deposit5Koin') >= 0) {
    chargeContents.push(
      <Grid item xs={4}>
        <Button
          size="large"
          className={`${navActive('?main=deposit5Koin')}`}
          onClick={() => {
            onClickMenuItem('bank', 'deposit5Koin')
          }}
        >
          {intl.formatMessage({ id: 'component.bank.deposit.5koin' })}
        </Button>
      </Grid>,
    )
  }
  if (APP_CHARGES.indexOf('depositWonp') >= 0) {
    chargeContents.push(
      <Grid item xs={4}>
        <Button
          size="large"
          className={`${navActive('?main=depositWonp')}`}
          onClick={() => {
            onClickMenuItem('bank', 'depositWonp')
          }}
        >
          {intl.formatMessage({ id: 'component.bank.deposit.wonp' })}
        </Button>
      </Grid>,
    )
  }

  const list =
    signedin === true ? (
      <Box className="myinfo">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
          <Button
            className="nickname"
            sx={{ display: 'flex', alignItems: 'center', pl: 0, py: 0, color: 'inherit' }}
            onClick={() => onClickMenuItem('myinfo', '')}
          >
            <Box sx={{ '& > img': { padding: '6px', width: '36px' } }}>
              <img src={getLevelImage(contents_level)} alt="" />
            </Box>
            <Box component="span" sx={{ textTransform: 'initial', fontSize: '1rem' }}>
              {nickname}
            </Box>
          </Button>
          <Box>
            <Button
              size="small"
              onClick={() => onClickMenuItem('myinfo', '')}
              sx={{ minWidth: '20px' }}
            >
              <img src={icon_mypage} alt="mypage" style={{ width: '20px' }} />
            </Button>
            <Button size="small" onClick={onClickLogout} sx={{ minWidth: '20px' }}>
              <img src={icon_logout} alt="logout" style={{ width: '20px' }} />
            </Button>
          </Box>
        </Box>

        <List component="div" sx={{ p: '0 8px 8px' }}>
          <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="money">
            <ListItemText>
              <span>{intl.formatMessage({ id: 'own-money' })}</span>
            </ListItemText>
            <ListItemText
              sx={{
                color: '#fff',
                '.account': {
                  color: '#fdd92b',
                },
              }}
            >
              <span className="account">{numberWithCommas(Math.floor(balanceMoney))}</span>
              <span>{intl.formatMessage({ id: 'money-locale' })}</span>
            </ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="point">
            <ListItemText>
              <span>{intl.formatMessage({ id: 'point' })}</span>
            </ListItemText>
            <ListItemText
              sx={{
                color: '#fff',
                '.account': {
                  color: '#fdd92b',
                },
              }}
            >
              <span className="account">{numberWithCommas(Math.floor(balancePoint))}</span>
              <span>P</span>
              <Button className="btn_transfer" onClick={() => onClickButton('point')}>
                전환
              </Button>
            </ListItemText>
          </ListItem>
          {enableComp ? (
            <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="comp">
              <ListItemText>
                <span>{intl.formatMessage({ id: 'comp' })}</span>
              </ListItemText>
              <ListItemText
                sx={{
                  color: '#fdd92b',
                }}
              >
                <span>{numberWithCommas(Math.floor(balanceComp))}</span>
                <Button className="btn_transfer" onClick={() => onClickButton('comp')}>
                  전환
                </Button>
              </ListItemText>
            </ListItem>
          ) : null}
          {SHOW_GAME_MONEY && !globalStore.option?.solution?.seamless && (
            <>
              <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="game-money">
                <ListItemText>
                  <span>{intl.formatMessage({ id: 'game-money' })}</span>
                </ListItemText>
                <ListItemText
                  sx={{
                    color: '#fff',
                    '.account': {
                      color: '#fdd92b',
                    },
                  }}
                >
                  <span className="account">
                    {_.isNumber(game_money) ? numberWithCommas(Math.floor(game_money)) : '??'}
                  </span>
                  <Button className="btn_refresh" disabled={!!refreshEnabled}>
                    <RefreshIcon
                      sx={{ width: '16px' }}
                      onClick={() => onClickButton('game-money')}
                    />
                  </Button>
                  <Button
                    className="btn_transfer"
                    disabled={!!refreshEnabled}
                    onClick={() => onClickButton('withdraw')}
                    sx={{
                      '&:disabled': {
                        backgroundColor: '#931d1d',
                        color: '#cccccc',
                      },
                    }}
                  >
                    전환
                  </Button>
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </Box>
    ) : null
  // -----------Drawer

  return (
    <>
      <MobileHeaderStyle>
        <AppBar position="static" ref={headerRef}>
          <Grid container>
            <Grid item xs={4}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                className="menu"
                sx={{ p: '6px', ml: '6px' }}
                onClick={() => onClickMenu()}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                className={`btn_coupon ${couponActive}`}
                onClick={() => onClickMenuItem('coupon', '')}
                sx={{ p: '6px', '& > img': { width: '22px' } }}
              >
                <img src={icon_coupon} alt="coupon" />
              </IconButton>
              <IconButton
                className="btn_message"
                onClick={() => onClickMenuItem('message', '')}
                sx={{ p: '6px', '& > img': { width: '20px' } }}
              >
                <img src={icon_message} alt="message" />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ justifyContent: 'center', '& > img': { cursor: 'pointer', height: '100%' } }}
            >
              <img src={asset_channel.common.main_logo_m} alt="" onClick={() => onClickLogo()} />
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: 'end' }}>
              {rightContents}
            </Grid>
          </Grid>
        </AppBar>

        <DrawerStyle anchor="top" open={open} onClose={() => setOpen(false)}>
          <GnbStyle component="nav" role="presentation">
            {/* myinfo */}
            {list}

            <Box className="menu" sx={{ p: 2 }}>
              <Box className="category">게임</Box>
              <Grid container spacing={1} className="game">
                <Grid item xs={6}>
                  <Button
                    hidden={!isEnabled('LCA')}
                    size="large"
                    className={`${navActive('/live-casino')}`}
                    onClick={() => {
                      onClickMenuItem('live-casino', 'evolution')
                    }}
                  >
                    {intl.formatMessage({ id: 'menu.header.live-casino' })}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    hidden={!isEnabled('HCA')}
                    size="large"
                    className={`${navActive('/hotel-casino')}`}
                    onClick={() => {
                      onClickMenuItem('hotel-casino', 'uig')
                    }}
                  >
                    {intl.formatMessage({ id: 'menu.header.hotel-casino' })}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    hidden={!isEnabled('SL')}
                    size="large"
                    className={`${navActive('/slot')}`}
                    onClick={() => {
                      onClickMenuItem('slot', '...')
                    }}
                  >
                    {intl.formatMessage({ id: 'menu.header.slot' })}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    hidden={
                      !isEnabled('HPB') &&
                      !isEnabled('NPB') &&
                      !isEnabled('PB') &&
                      !isEnabled('PL') &&
                      !isEnabled('EOS') &&
                      !isEnabled('PBG') &&
                      !isEnabled('CPB') &&
                      !isEnabled('CL') &&
                      !isEnabled('SG') &&
                      !isEnabled('WRBTCPB') &&
                      !isEnabled('WRBTCPL') &&
                      !isEnabled('WRBEXPB') &&
                      !isEnabled('WRKENOPB') &&
                      !isEnabled('WRKENOPL') &&
                      !isEnabled('WRKENO')
                    }
                    size="large"
                    className={`${navActive('/minigame')}`}
                    onClick={() => {
                      onClickMenuItem('minigame', '')
                    }}
                  >
                    {intl.formatMessage({ id: 'menu.header.minigame' })}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    hidden={!isEnabled('SP')}
                    size="large"
                    className={`${navActive('/sports')}`}
                    onClick={() => {
                      onClickMenuItem('sports', '')
                    }}
                  >
                    {intl.formatMessage({ id: 'menu.header.sports' })}
                  </Button>
                </Grid>
              </Grid>

              <Box className="category" sx={{ pt: 2 }}>
                편의
              </Box>
              <Grid container spacing={0.5} className="service">
                {chargeContents}
                <Grid item xs={4}>
                  <Button
                    size="large"
                    className={`${navActive('?main=withdraw')}`}
                    onClick={() => onClickMenuItem('bank', 'withdraw')}
                  >
                    <img src={icon_withdraw} alt="withdraw" />
                    {intl.formatMessage({ id: 'component.bank.withdraw' })}
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    size="large"
                    className={`${navActive('?main=history')}`}
                    onClick={() => onClickMenuItem('bank', 'history')}
                  >
                    <img src={icon_history} alt="history" />
                    {intl.formatMessage({ id: 'bank-history-short' })}
                  </Button>
                </Grid>
                {badgeContents}
                <Grid item xs={4}>
                  <Button size="large" onClick={() => onClickMenuItem('coupon', '')}>
                    <img src={icon_coupon} alt="coupon" />
                    {intl.formatMessage({ id: 'component.coupon' })}
                  </Button>
                </Grid>
                {showQna && signedin ? (
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      className={`${navActive('?main=qnaPersonal')}`}
                      onClick={() => onClickMenuItem('qnaPersonal', '')}
                    >
                      <img src={icon_qna} alt="qna" />
                      {intl.formatMessage({ id: 'qna' })}
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Button
                    size="large"
                    className={`${navActive('?main=myinfo')}`}
                    onClick={() => onClickMenuItem('myinfo', '')}
                  >
                    <img src={icon_myinfo} alt="myinfo" />
                    {intl.formatMessage({ id: 'component.title.myinfo' })}
                  </Button>
                </Grid>
                {showPolicy ? (
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      className={`${navActive('?main=POLICY')}`}
                      onClick={() => onClickMenuItem('notice', 'POLICY')}
                    >
                      <img src={icon_policy} alt="policy" />
                      {intl.formatMessage({ id: 'component.notice.policy' })}
                    </Button>
                  </Grid>
                ) : null}

                {showPartnerMenu ? (
                  <Grid item xs={4}>
                    <Button size="large" className="partner" onClick={onClickPartner}>
                      <img src={icon_partner} alt="partner" />
                      {intl.formatMessage({ id: 'component.partner' })}
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </GnbStyle>

          <SnsLink sx={{ p: 2 }}>
            {telegram ? (
              <Button onClick={() => onClickOpenTelegram(telegram)}>
                <img src={imgTelegram} />
                <Typography className="id">{telegram}</Typography>
              </Button>
            ) : null}

            {kakaotalk ? (
              <div className="no_link">
                <img src={imgKakaotalk} />
                <Typography className="id">{kakaotalk}</Typography>
              </div>
            ) : null}
          </SnsLink>
        </DrawerStyle>
      </MobileHeaderStyle>
      <PartnerMenu></PartnerMenu>
    </>
  )
}

export default observer(Header)
