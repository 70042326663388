/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { MarketGroupData } from '~/v2/interface/market_group_types'
import { Box, Divider, Theme, Typography } from '@mui/material'
import { DetailMarketNodeData, DetailOddsFactory } from './detail_odds_factory'
import { Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import useRootStore from '~/store/useRootStore'
import { UPDATE_FIELD_TYPE, makeOddsControllerName } from '~/store/sportsStore/sports-util'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { SportsConfigItem } from '~/v2/protocol/admin/admin'

interface Props {
  theme: Theme
  groupData: MarketGroupData
  prematch: Schema$Prematch
  market: Schema$Market
  baseLineConfig: SportsConfigItem
}

function DetailMarketbox({ theme, groupData, prematch, market, baseLineConfig }: Props) {
  const [currentMarket, setCurrentMarket] = useState<Schema$Market>(market)
  const [nodeData, setNodeData] = useState<DetailMarketNodeData[]>([])

  const { sportsStore } = useRootStore()
  const { lang } = sportsStore

  const { FixtureId } = prematch
  const { id: marketId } = groupData

  const updateField = (updateMarket: Schema$Market, type: UPDATE_FIELD_TYPE) => {
    // console.log(
    //   `Market updated! fixtureId: ${FixtureId} marketName: ${JSON.stringify(
    //     groupData.name,
    //   )}`,
    // )
    // console.log(
    //   `$$$$ Market updated! fixtureId: ${FixtureId} marketName: ${groupData.name[lang]}`,
    // )

    setCurrentMarket(updateMarket)
  }

  useOnMount(() => {
    // console.log(
    //   `+ mount Marketbox fixtureId: ${FixtureId} marketName: ${groupData.name[lang]}`,
    // )
    sportsStore.addMarketController(FixtureId, marketId, updateField)
  })

  useOnUnmount(() => {
    // console.log(
    //   `- unmount Marketbox fixtureId: ${FixtureId} marketName: ${groupData.name[lang]}`,
    // )
    sportsStore.deleteMarketController(FixtureId, marketId)
  })

  useEffect(() => {
    setCurrentMarket(market)
  }, [market])

  useEffect(() => {
    if (currentMarket) {
      let baseline_size = null
      let enabled_baseline_zero = false
      if (baseLineConfig) {
        if (groupData.unifiedName === 'HDP') {
          baseline_size = baseLineConfig?.baseline_size_hdp
          enabled_baseline_zero = baseLineConfig?.enabled_hdp_baseline_zero
        } else if (groupData.unifiedName === 'UO') {
          baseline_size = baseLineConfig?.baseline_size_uo
          enabled_baseline_zero = baseLineConfig?.enabled_uo_baseline_zero
        }
      }

      const data = DetailOddsFactory(
        groupData.unifiedName,
        theme,
        prematch,
        currentMarket,
        baseline_size,
        enabled_baseline_zero,
      )
      if (data && data.length > 0) {
        for (const el of data) {
          el.content = (
            <Box
              key={makeOddsControllerName(
                FixtureId,
                marketId,
                el.market.Name,
                el.market.ClientBaseLine,
              )}
              sx={{ mt: '4px', mb: '4pt' }}
            >
              {el.node}
            </Box>
          )
        }
      }
      setNodeData(data)
    } else {
      setNodeData([])
    }
  }, [currentMarket])

  const nodeContents = nodeData.map(o => o.content)
  if (nodeContents.length === 0) {
    return <></>
  }

  return (
    <Box key={groupData.id} sx={{ width: '100%', mt: 1 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2, height: '36px', background: '#08192e' }}
      >
        <Typography sx={{ color: 'white' }}>{groupData.name[lang]}</Typography>
        {/* <Typography sx={{ color: 'white' }}>{groupData.name[lang]}</Typography> */}
      </Box>
      <Divider sx={{ background: '#82a6d2' }}></Divider>
      <Box alignItems="center" sx={{ p: 2, background: '#08192e' }}>
        {nodeContents}
      </Box>
    </Box>
  )
}

export default DetailMarketbox
