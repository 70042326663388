import { jsx, css, ThemeProvider, Theme, Global } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react'
import {
  TextField,
  Container,
  Box,
  Grid,
  Divider,
  Typography,
  Stack,
  Button,
  MenuItem,
  Chip,
} from '@mui/material'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { useHistory } from 'react-router-dom'

import WhiteTheme from '@themes/white.theme'
//import { GlobalStyle } from '@shared/Common'
import useRootStore from '@store/useRootStore'
import { USER_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import PartnerIntergratedMemberTable from './PartnerIntergratedMemberTable'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { popupCenter } from '~/utils/utils'
import { Schema$ConcurrentUserItem, Schema$PartnerUserConfig } from '~/v2/protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const SEARCH_STATE_NAMES = [{ id: 'ALL', name: '모두' }]
for (const el of USER_STATE) {
  SEARCH_STATE_NAMES.push({ id: el.id, name: el.name })
}

const SEARCH_KEY_NAMES = [
  { id: 'ID', name: '아이디' },
  { id: 'NICK', name: '닉네임' },
]

const end = new Date()
// const begin = new Date()
const begin = new Date(end.getFullYear(), end.getMonth(), 1)

// const ItemPerPage = 10

function PartnerIntergratedMembers({ location }) {
  const intl = useIntl()
  const [searchBegin, setSearchBegin] = useState<Date>(begin)
  const [searchEnd, setSearchEnd] = useState<Date>(end)
  const [searchKey, setSearchKey] = useState('ID')
  const [searchPeriod, setSearchPeriod] = useState('')
  const [concurrentUsers, setConcurrentUsers] = useState<Schema$ConcurrentUserItem[]>([])
  const [partnerUserConfig, setPartnerUserConfig] = useState<Schema$PartnerUserConfig | null>(null)
  const refSearchValue = useRef<string>('')

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { isPartner, merchant_class } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)
  const { option } = globalStore
  const { enableCoupon, enableMerchantClass5Recommend } = option || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  let globalWidth = ''
  if (desktop) {
    if (channelSymbol === 'marine2' || channelSymbol === 'band' || channelSymbol === 'world') {
      globalWidth = '2090px'
    } else if (
      channelSymbol === 'today' ||
      channelSymbol === 'hongyeom' ||
      channelSymbol === 'ace' ||
      channelSymbol === 'ssg' ||
      channelSymbol === 'praha'
    ) {
      globalWidth = '1832px'
    } else {
      globalWidth = '1800px'
    }
  }

  const GlobalStyle = (theme: Theme) => css`
    html,
    body {
      background: ${theme.colors.subBg};
    }
    body {
      min-width: ${globalWidth};
    }
  `

  const { is_active_issue_coupon, is_active_give_money, is_active_take_money } =
    partnerUserConfig || {}
  const is_active_money_give_and_take = is_active_give_money || is_active_take_money
  const showUserCreate = merchant_class === 5 ? enableMerchantClass5Recommend : true

  const fetchConcurrentUsers = async () => {
    //
    try {
      const { items } = await apiPartner.getConcurrentUsers({})
      setConcurrentUsers(items)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchPartnerUserConfig = async () => {
    try {
      const { config } = await apiPartner.getUserConfig({})
      setPartnerUserConfig(config)
    } catch (err) { }
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-MEMBERS')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.members)
    fetchConcurrentUsers()
    fetchPartnerUserConfig()
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchKey = event => {
    setSearchKey(event.target.value)
  }

  const handleChangeSearchValue = event => {
    refSearchValue.current = event.target.value
  }

  const onClickSearchPeriod = newValue => {
    setSearchPeriod(searchPeriod === newValue ? '' : newValue)
    const now = new Date()
    switch (newValue) {
      case 'today':
        setSearchBegin(now)
        setSearchEnd(now)
        break
      case '7':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6))
        setSearchEnd(now)
        break
      case '15':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14))
        setSearchEnd(now)
        break
    }
  }

  const onClickSearch = () => {
    let date_begin = new Date(searchBegin)
    let date_end = new Date(searchEnd)
    let q = `begin=${moment(date_begin).format('YYYY-MM-DD')}&end=${moment(date_end).format(
      'YYYY-MM-DD',
    )}&key=${searchKey}&value=${refSearchValue.current}&ts=${new Date().getTime()}`
    let encoded = encodeURI(q)
    history.push(`/partner/members/search?${encoded}`)
  }

  const onClickMoneyTransferRecord = () => {
    const titlePrefix = 'MoneyTransferRecord'
    const url = `/partner/money-transfer-record`
    const w = 1220
    const h = Math.min(650, (window.screen.height * 80) / 100)
    popupCenter({ url, title: `${titlePrefix}`, w, h })
  }

  const onClickCreateMember = () => {
    const url = '/partner/create-member'
    popupCenter({ url, title: '회원 생성', w: 800, h: 680 })
  }

  const onClickCouponRecord = () => {
    const titlePrefix = 'CouponRecord'
    const url = `/partner/coupon-record`
    const w = 1400
    const h = (window.screen.height * 80) / 100
    popupCenter({ url, title: titlePrefix, w, h })
  }

  const onClickChip = (userId: string) => {
    //
    let date_begin = new Date(searchBegin)
    let date_end = new Date(searchEnd)
    let q = `begin=${moment(date_begin).format('YYYY-MM-DD')}&end=${moment(date_end).format(
      'YYYY-MM-DD',
    )}&key=ID&value=${userId}&ts=${new Date().getTime()}`
    let encoded = encodeURI(q)
    history.push(`/partner/members/search?${encoded}`)
  }

  const filterKeyItems = []
  for (const el of SEARCH_KEY_NAMES) {
    filterKeyItems.push(
      <MenuItem key={el.id} value={el.id}>
        {el.name}
      </MenuItem>,
    )
  }

  let concurrentUserContents = null
  if (concurrentUsers && concurrentUsers.length > 0) {
    concurrentUserContents = (
      <Grid item xs={12} sx={{ marginTop: '15px' }}>
        <Typography
          sx={{
            position: 'absolute',
            marginLeft: '8px',
            marginTop: '-14px',
            paddingLeft: '8px',
            paddingRight: '8px',
            background: 'white',
          }}
        >
          접속자
        </Typography>
        <Box
          sx={{
            width: '100%',
            p: 1,
            border: 1,
            borderColor: 'grey.300',
            borderRadius: '5px',
            scroll: 'auto',
          }}
        >
          {concurrentUsers.map((child, i) => (
            <Chip
              label={child.user_id}
              sx={{ margin: 0.2, fontSize: '12px' }}
              onClick={() => onClickChip(child.user_id)}
            ></Chip>
          ))}
        </Box>
      </Grid>
    )
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1800px',
          maxWidth: '1800px',
        }}
      >
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>회원리스트</Divider>
          </Grid>

          <Grid item xs={3}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row" spacing={1}>
                <Button
                  hidden={!is_active_money_give_and_take}
                  onClick={() => onClickMoneyTransferRecord()}
                  variant="outlined"
                  size="small"
                  color="primary"
                  style={{
                    maxHeight: '50px',
                    minHeight: '30px',
                    marginLeft: '6px',
                  }}
                >
                  <Typography>머니 지급/회수 내역</Typography>
                </Button>
                {showUserCreate ? (
                  <Button
                    onClick={() => onClickCreateMember()}
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{
                      maxHeight: '50px',
                      minHeight: '30px',
                      marginLeft: '6px',
                    }}
                  >
                    <Typography>회원 생성</Typography>
                  </Button>
                ) : null}

                {enableCoupon ? (
                  <Button
                    hidden={!is_active_issue_coupon}
                    onClick={() => onClickCouponRecord()}
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{
                      maxHeight: '50px',
                      minHeight: '30px',
                      marginLeft: '6px',
                    }}
                  >
                    <Typography>쿠폰 내역</Typography>
                  </Button>
                ) : null}
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '15' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('15')}
                >
                  15일
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '7' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('7')}
                >
                  1주
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === 'today' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('today')}
                >
                  오늘
                </Button>
                <TextField
                  onChange={handleChangeSearchValue}
                  size="small"
                  variant={'outlined'}
                  label="검색값"
                  style={{ width: '180px' }}
                ></TextField>
                <TextField
                  value={searchKey}
                  onChange={handleChangeSearchKey}
                  select
                  size="small"
                  variant={'outlined'}
                  label="검색키"
                  style={{ width: '100px' }}
                >
                  {filterKeyItems}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>

          {concurrentUserContents}

          <Grid item xs={12} sx={{ marginTop: '10px' }}>
            <PartnerIntergratedMemberTable
              location={location}
              default_begin={searchBegin}
              default_end={searchEnd}
            ></PartnerIntergratedMemberTable>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '4px' }}>
            <Typography sx={{ width: '100%', mt: 1, color: 'red', textAlign: 'right' }}>
              보유머니, 보유포인트, 보유콤프는 해당 회원의 잔액이며 그 외 정보는 해당 회원과 하위
              회원의 총합입니다.
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '0px' }}>
            <Typography sx={{ width: '100%', mt: 1, color: 'blue', textAlign: 'right' }}>
              이름, 닉네임, 베팅액 등 각 항목의 타이틀을 누르면 오름/내림 차순으로 정렬이 됩니다.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedMembers)
