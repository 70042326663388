import { observable, action, makeObservable, runInAction } from 'mobx'
import * as _ from 'lodash'
import cookies from 'react-cookies'

import { StoreExt } from '@utils/reactExt'
import { delay } from '@utils/utils'
import { authStore, globalStore, miniGameStore, userStore } from '@store/index'
import { SocketStore } from '@store/socketStore'
import { Message, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { ERR } from '@interface/error'
import { LiveProtocol } from '@live/live-protocol'
import {
  PacketStructure,
  INotifyMatchupList,
  INotifyTradingOddsOptions,
  ISyncEnterSpace,
  Schema$PushUserPropertyUpdate,
  INotifyMatchup,
  ISyncReloadSpace,
  INotifyNotification,
  ISyncAuth,
  ISyncWatch,
  INotifyPrematches,
  INotifyMarkets,
  INotifyPrematch,
  INotifyMarket,
  ISyncLeaveSpace,
  INotifySportsOptions,
  INotifyMetas,
  INotifyEvoResult,
} from '@live/live'
import { ISyncEnter } from '@live/live'

import { LIST_FLAG } from '~/v2/interface/types'
import { makeBrowserData } from '~/game/util'
import EventManager from '~/containers/views/Canvas/helpers/event-manager'
import { ResultEventData } from '~/containers/views/Canvas/evo/entries/entry-panel'
import { timelineLog } from '~/utils'

export interface BettingCartItem {
  marketGroup: number

  matchupId: string
  marketId: string
  oddsId: string

  round: number
  uniqueId: number
  market: string
  marketColor: string
  odds: string
  rate: string
}

export class LiveStore extends SocketStore {
  targetSpace = '' // provider
  targetTradingGroup = ''
  targetTradingKind = ''
  targetGameId = ''
  targetWatch = ''

  @observable
  inSpace = ''

  @observable
  inTradingGroup = ''

  @observable
  inTradingKind = ''

  @observable
  inGameId = ''

  @observable
  inWatch = ''

  @observable
  updatedQna = 0

  @observable
  spaceReloadedTimestamp = 0

  constructor() {
    super()

    makeObservable(this)

    this.url = process.env.REACT_APP_LIVE_URL
    if (process.env.NODE_ENV === 'production') {
      const protocol = process.env.REACT_APP_LIVE_PROTOCOL
      const port = process.env.REACT_APP_LIVE_PORT
      const { hostname } = window.location
      this.url = `${protocol}://${hostname}:${port}`
    }

    this.dispatchMessage = this.handleMessage

    const intervaleSec = 10
    setInterval(() => {
      // 10초마다 핑 테스트 session id 로 핑 테스트
      if (authStore.sid && this.serviceState === SOCKET_SERVICE_STATE.ENTERED) {
        const data: ISyncAuth.Params = {
          sid: authStore.sid,
        }
        this.sendPacket(LiveProtocol.SYNC_AUTH, data)
      }
    }, intervaleSec * 1000)
  }

  @action
  spaceReloaded = () => {
    this.spaceReloadedTimestamp = new Date().getTime()
  }

  @action
  setInSpace = (space: string) => {
    if (this.inSpace !== space) {
      this.inSpace = space
    }
  }

  @action
  setInTrading = (group: string, kind: string) => {
    if (this.inTradingGroup !== group) {
      this.inTradingGroup = group
    }
    if (this.inTradingKind !== kind) {
      this.inTradingKind = kind
    }
  }

  @action
  setInGameId = (gameId: string) => {
    if (this.inGameId !== gameId) {
      this.inGameId = gameId
    }
  }

  @action
  setInWatch = (watch: string) => {
    if (this.inWatch !== watch) {
      this.inWatch = watch
    }
  }

  @action
  cleanup = () => {
    this.targetSpace = ''
    this.targetTradingGroup = ''
    this.targetTradingKind = ''
    this.targetGameId = ''
    this.targetWatch = ''

    this.inSpace = ''
    this.inTradingGroup = ''
    this.inTradingKind = ''
    this.inGameId = ''
    this.inWatch = ''

    this.close()
  }

  @action
  setUpdatedQna = () => {
    this.updatedQna = new Date().getTime()
  }

  @action
  enter = async () => {
    // timelineLog(`enter space: ${space} gameId: ${gameId}`)
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.DISCONNECTED) {
        return
      }

      if (!authStore.sid) {
        timelineLog('sid is not set')
        return
      }

      this.targetSpace = ''
      this.targetTradingGroup = ''
      this.targetTradingKind = ''
      this.targetGameId = ''
      this.targetWatch = ''

      this.setInSpace('')
      this.setInTrading('', '')
      this.setInGameId('')
      this.setInWatch('')

      timelineLog('LiveStore try connect')
      await this.connect()

      // 이미 odds 서버의 서비스에 입장하였어도 입장을 요청한다.(서버쪽에서 같은 인스턴스에 대한 중복 처리)
      this.setServiceState(SOCKET_SERVICE_STATE.ENTERING)

      // const data: ISyncEnter.Params = {
      //   uuid: userStore.userInfo.uuid,
      //   authKey: 'b',
      //   overlapLogin: true,
      // }
      // this.sendPacket(LiveProtocol.SYNC_ENTER, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  enterSpace = async (
    spaceName: string,
    trading_group: string,
    trading_kind: string,
    gameId: string,
    retry: boolean,
  ) => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      timelineLog('LiveStore enter space')

      this.targetSpace = spaceName
      this.targetTradingGroup = trading_group
      this.targetTradingKind = trading_kind
      this.targetGameId = gameId
      this.targetWatch = ''

      this.setInSpace('')
      this.setInTrading('', '')
      this.setInGameId('')
      this.setInWatch('')

      const data: ISyncEnterSpace.Params = {
        spaceName,
        trading_group,
        trading_kind,
        gameId,
        retry,
        browserData: makeBrowserData(),
      }
      this.sendPacket(LiveProtocol.SYNC_ENTER_SPACE, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  leaveSpace = async () => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      timelineLog('LiveStore leave space')

      this.targetSpace = ''
      this.targetTradingGroup = ''
      this.targetTradingKind = ''
      this.targetGameId = ''
      this.targetWatch = ''

      this.setInSpace('')
      this.setInTrading('', '')
      this.setInGameId('')
      this.setInWatch('')

      const data: ISyncLeaveSpace.Params = {}
      this.sendPacket(LiveProtocol.SYNC_LEAVE_SPACE, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  @action
  reloadSpace = async () => {
    try {
      if (this.serviceState !== SOCKET_SERVICE_STATE.ENTERED) {
        return
      }

      // timelineLog('LiveStore reload space')

      const data: ISyncReloadSpace.Params = {
        spaceName: this.targetSpace,
        trading_group: this.targetTradingGroup,
        trading_kind: this.targetTradingKind,
        gameId: this.targetGameId,
      }
      this.sendPacket(LiveProtocol.SYNC_RELOAD_SPACE, data)
    } catch (err) {
      console.error(err.message)
    }
  }

  handleMessage = (msg: Message) => {
    const self = this
    if (msg.from !== 'server') {
      const { event } = msg
      switch (event) {
        case 'connect':
          // timelineLog('liveStore.connect msg')
          userStore.fetchedPosition = ''
          this.setServiceState(SOCKET_SERVICE_STATE.CONNECTED)
          const data: ISyncEnter.Params = {
            userType: 'user',
            uuid: userStore.userInfo.uuid,
            sid: authStore.sid,
            overlapLogin: false,
          }
          this.sendPacket(LiveProtocol.SYNC_ENTER, data)
          break
        case 'close':
          // timelineLog('liveStore.close msg')
          userStore.fetchedPosition = ''
          this.setServiceState(SOCKET_SERVICE_STATE.DISCONNECTED)
          this.setInSpace('')
          this.setInTrading('', '')
          this.setInGameId('')
          this.setInWatch('')
          break
      }
      return
    }
    if (msg.data === 'SR') {
      return
    }

    try {
      const packet = JSON.parse(msg.data) as PacketStructure
      const { data } = packet
      // const raw = data as Schema$Result
      // if (raw.errcode !== ERR.OK) {
      //   alert(`MiniGameStore errcode = ${raw.errcode}`)
      //   return
      // }
      // timelineLog(`[${new Date().toLocaleString()}] receivePacket < protocol: ${packet.protocol}`)
      switch (packet.protocol) {
        case LiveProtocol.SYNC_ENTER:
          {
            // timelineLog(`SYNC_ENTER: ${JSON.stringify(packet.data)}`)
            const { errcode } = data as ISyncEnter.Schema
            if (errcode === ERR.OK) {
              this.setServiceState(SOCKET_SERVICE_STATE.ENTERED)
              // if (this.targetSpace.length > 0) {
              //   this.enterSpace(this.targetSpace, this.targetGameId)
              // }
            } else {
              this.cleanup()
              if (errcode === ERR.INVALID_AUTHKEY) {
                //
              } else {
                globalStore.pushDialogLocaleOk({
                  title: 'error',
                  text: 'msg.failed.user_authentication',
                })
              }
            }
          }
          break
        case LiveProtocol.NOTIFY_SERVER_MAINTENANCE:
          {
            async function inline() {
              const { maintenance } = data as any

              if (maintenance === true) {
                await authStore.logout(null)
                self.cleanup()
              }
              await globalStore.getPublicSettings(null)
            }
            inline()
          }
          break
        case LiveProtocol.SYNC_ENTER_SPACE:
          {
            // timelineLog(`SYNC_ENTER_SPACE: ${JSON.stringify(packet.data)}`)
            const {
              spaceName,
              trading_group,
              trading_kind,
              gameId,
              url,
              key,
              errcode,
            } = data as ISyncEnterSpace.Schema
            console.log(data)
            if (errcode === ERR.OK) {
              this.setInSpace(spaceName)
              this.setInTrading(trading_group, trading_kind)
              this.setInGameId(gameId)

              let turl = url
              if (key) {
                const subdomain = process.env.REACT_APP_REEL_SEASTORY_CLIENT_SUBDOMAIN
                const port = process.env.REACT_APP_REEL_SEASTORY_CLIENT_PORT

                if (subdomain) {
                  turl = `${window.location.protocol}//${subdomain}.${window.location.hostname}`
                } else {
                  turl = `${window.location.protocol}//${window.location.hostname}`
                }

                if (port) {
                  turl = `${turl}:${port}?key=${key}`
                } else {
                  turl = `${turl}?key=${key}`
                }
              }

              userStore.setPositionPage(`${trading_group}-${trading_kind}`)
              if (turl) {
                globalStore.popupGame(trading_kind, gameId, turl)
              } else {
                globalStore.cleanupPopupGame()
              }
            } else {
              if (errcode === ERR.ALREADY_IN_SPACE) {
                // maybe do something
              } else if (errcode === ERR.TRY_AGAIN) {
                globalStore.cleanupPopupGame()
                globalStore.pushDialogLocaleOk({ text: 'msg.try_again_later' })
              } else if (errcode === ERR.RETRY_ENTER_SPACE) {
                globalStore.cleanupPopupGame()
                globalStore.pushDialogLocaleOk({ text: 'msg.try_again_later' })
              } else if (errcode === ERR.GET_GAME_IN_MAINTENANCE) {
                globalStore.cleanupPopupGame()
                globalStore.pushDialogLocaleOk({ text: 'msg.game_company.maintenance' })
              } else {
                globalStore.cleanupPopupGame()
                globalStore.pushDialogLocaleOk({
                  title: 'error',
                  text: 'msg.failed.enter_game',
                  arg1: errcode.toString(),
                })
              }
            }
          }
          break
        case LiveProtocol.SYNC_RELOAD_SPACE:
          this.spaceReloaded()
          break
        case LiveProtocol.SYNC_AUTH:
          const { errcode } = data as INotifyNotification.Schema
          if (errcode) {
            authStore.logout(null)
            this.cleanup()
            globalStore.pushDialogLocaleOk({
              text: 'msg.logged_out.inactivity',
            })
          }
          break
        case LiveProtocol.NOTIFY_MINIGAME_MATCHUP:
          {
            const { hostTime, command, info, item } = data as INotifyMatchup.Schema
            const now = new Date()
            // timelineLog(`[${now.toLocaleString()}] command:${command} info:${JSON.stringify(info)}`)
            switch (command) {
              case 'open':
              case 'bet:start':
              case 'bet:end':
                miniGameStore.updateMatchup(command, item)
                break
              case 'close':
                break
              case 'update':
                // MinigameStore.updateMatchup(command, item)
                break
              case 'outcome':
                miniGameStore.setLastMatchupOutcome(info)
                break
              case 'cancel':
                break
              case 'balanced':
                break
            }
          }
          break
        case LiveProtocol.NOTIFY_MINIGAME_MATCHUP_LIST:
          {
            timelineLog('NOTIFY_MINIGAME_MATCHUP_LIST')
            const { hostTime, items } = data as INotifyMatchupList.Schema
            miniGameStore.updateMatchupList(items)
          }
          break
        case LiveProtocol.NOTIFY_MINIGAME_ODDS_OPTIONS:
          {
            timelineLog('NOTIFY_MINIGAME_ODDS_OPTIONS')
            const { items } = data as INotifyTradingOddsOptions.Schema
            miniGameStore.updateOddsOptions(items)
          }
          break
        case LiveProtocol.NOTIFY_MINIGAME_EVO_RESULT:
          {
            const { matchup, evoResult } = data as INotifyEvoResult.Schema
            EventManager.getInstance().emit('evo:result', {
              matchup,
              result: evoResult,
            } as ResultEventData)
          }
          break
        case LiveProtocol.NOTIFY_USER_PROPERTY_UPDATE:
          {
            const dic = data as Schema$PushUserPropertyUpdate
            for (const [key, value] of Object.entries(dic.attr)) {
              if (key === 'money' && _.isNumber(value)) {
                userStore.setUserMoney({ balance: value })
              } else if (key === 'point' && _.isNumber(value)) {
                userStore.setUserPoint({ balance: value })
              } else if (key === 'comp' && _.isNumber(value)) {
                userStore.setUserComp({ balance: value })
              } else if (key === 'game_money' && _.isNumber(value)) {
                userStore.setGameMoney(value)
              }
            }
          }
          break
        case LiveProtocol.NOTIFY_USER_QNA_UPDATE:
          this.setUpdatedQna()
          break
        case LiveProtocol.NOTIFY_USER_NOTIFICATION:
          const { item } = data as INotifyNotification.Schema
          userStore.setNotification(item)
          break
        case LiveProtocol.NOTIFY_USER_FORCE_OUT:
          authStore.logout(null)
          this.cleanup()
          globalStore.pushDialogLocaleOk({
            text: 'msg.logged_out',
          })
          break
        case LiveProtocol.NOTIFY_NEW_LOGIN_USER_FORCE_OUT:
          authStore.logout(null)
          this.cleanup()
          globalStore.pushDialogLocaleOk({
            text: 'msg.logged_out.duplicated',
          })
          break
      }
    } catch (err) {
      console.error(err.message)
      console.error(err.stack)
    }
  }
}

export default new LiveStore()
