import { Schema$BrowserData } from '@interface/common'
import * as platform from 'platform'
import * as _ from 'lodash'
import { Schema$OpenTradingGroup, Schema$OpenTradingKind } from '~/v2/protocol/public'
import { SUPPORT_MINIGAME_GROUP } from '~/v2/interface/config'
import { getDeviceType, getMobileType } from '~/utils'

export function makeBrowserData() {
  const deviceType = getDeviceType()
  const mobileType = getMobileType()
  const name = platform.name || ''
  const platform_name = navigator.platform || ''
  const data: Schema$BrowserData = {
    app: 'browser',
    device: deviceType,
    browser: name,
    platform: platform_name,
    remote_addr: '',
    agent_string: platform.ua,
    browser_version: platform.version,
    origin: window.location.origin,
    is_ios: /iPad|iPhone|iPod/.test(navigator.userAgent),
    is_phone: false,
    is_mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ),
    is_tablet: deviceType === 'tablet',
    is_android: mobileType === 'android',
    is_desktop: deviceType === 'desktop',
    is_webview: false,
    is_official_app: false,
  }

  return data
}

export const filterTradingKinds = (
  tradingGroups: Schema$OpenTradingGroup[],
  tradingKinds: Schema$OpenTradingKind[],
  tradingGroupName: string,
): Schema$OpenTradingKind[] => {
  const filtered = _.filter(tradingKinds, k => {
    const a = k.trading_group === tradingGroupName
    const b =
      _.findIndex(tradingGroups, g => {
        return g.trading_group === k.trading_group
      }) >= 0
    return a && b
  })
  return _.orderBy(filtered, ['order'], ['asc'])
}

export const filterMinigameTradingKinds = (
  tradingGroups: Schema$OpenTradingGroup[],
  tradingKinds: Schema$OpenTradingKind[],
): Schema$OpenTradingKind[] => {
  const filtered = _.filter(tradingKinds, k => {
    const a = SUPPORT_MINIGAME_GROUP.indexOf(k.trading_group) >= 0
    const b =
      _.findIndex(tradingGroups, g => {
        return g.trading_group === k.trading_group
      }) >= 0
    return a && b
  })
  return _.orderBy(filtered, ['order'], ['asc'])
}

export const splitOpenTradingKinds = (
  open_trading_kinds: Schema$OpenTradingKind[],
  filter_kind: string[] | null,
): {
  lca: Schema$OpenTradingKind[]
  hca: Schema$OpenTradingKind[]
  sl: Schema$OpenTradingKind[]
  reel: Schema$OpenTradingKind[]
  mg: Schema$OpenTradingKind[]
  sp: Schema$OpenTradingKind[]
} => {
  const filtered = filter_kind
    ? _.filter(open_trading_kinds, o => {
        return filter_kind.indexOf(o.trading_kind) < 0
      })
    : open_trading_kinds
  // lca
  const lcaItems = _.filter(filtered || [], o => {
    return o.trading_group === 'LCA'
  })
  const lca = _.orderBy(lcaItems, ['order'], ['asc'])

  // hca
  const hcaItems = _.filter(filtered || [], o => {
    return o.trading_group === 'HCA'
  })
  const hca = _.orderBy(hcaItems, ['order'], ['asc'])

  // slot
  const slItems = _.filter(filtered || [], o => {
    return o.trading_group === 'SL'
  })
  const sl = _.orderBy(slItems, ['order'], ['asc'])

  // reel
  const reelItems = _.filter(filtered || [], o => {
    return o.trading_group === 'REEL'
  })
  const reel = _.orderBy(reelItems, ['order'], ['asc'])

  // minigame
  const mgItems = _.filter(filtered || [], o => {
    return SUPPORT_MINIGAME_GROUP.indexOf(o.trading_group) >= 0
  })
  const mg = _.orderBy(mgItems, ['order'], ['asc'])

  // sports
  const spItems = _.filter(filtered || [], o => {
    return o.trading_group === 'SP'
  })
  const sp = _.orderBy(spItems, ['order'], ['asc'])

  return { lca, hca, sl, reel, mg, sp }
}
