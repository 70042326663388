/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react'
import { Tab, Tabs, Box, Typography, Dialog, AppBar, IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import CloseIcon from '@mui/icons-material/Close'

import useRootStore from '@store/useRootStore'
import { MobileModalStyle, ModalStyle } from '@styles/modal.style'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import SignupComponent from '~/containers/shared/PopupLogin/SignupComponent'
import { ModalKeys } from '~/store/globalStore'

// interface Props {
//   visible: boolean
//   onClose: () => void
// }

const SignupDlg: React.FC<{}> = () => {
  const { globalStore } = useRootStore()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const { desktop } = globalStore.decideMedia(isDesktop)
  const { currentKey, currentValue } = globalStore
  const modalKey = ModalKeys.signup

  const tabIndex = currentKey === modalKey ? currentValue : -1
  let visible = tabIndex >= 0

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const dlgStyle = desktop
    ? {
        '& .MuiDialog-paper': {
          minWidth: '500px',
          maxWidth: '500px',
        },
      }
    : null

  const style = desktop ? ModalStyle : MobileModalStyle

  return (
    <Dialog open={visible} onClose={onClose} css={style} sx={dlgStyle}>
      <Box className="modal_content">
        <AppBar sx={{ position: 'relative' }}>
          <Typography className="modal_title" variant="h6" component="div">
            회원가입
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Box>
          <SignupComponent></SignupComponent>
        </Box>
      </Box>
    </Dialog>
  )
}

export default observer(SignupDlg)
