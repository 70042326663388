/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css, Theme } from '@emotion/react'
import React, { Fragment, useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Container, Tab, Tabs, Grid, Box } from '@mui/material'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import queryString from 'query-string'

import useRootStore from '@store/useRootStore'
import { getStateString, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { GlobalStore } from '@store/globalStore'
import { AuthStore } from '@store/authStore'
import { numberWithCommas } from '@utils/utils'

import MarketPanelEvolutionPowerball from './MarketPanelEvolutionPowerball'
import MarketPanelPowerball from './MarketPanelPowerball'
import MarketPanelLadder from './MarketPanelPowerladder'
import MarketPanelSquidgame from './MarketPanelSquidgame'
import MarketPanelWooriKenoPowerball from './MarketPanelWooriKenoPowerball'
import BettingCart from './BettingCart'

// image
import { betStart, betEnd } from '~/assets/images/layout_1'

// css
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import MarketPanelKino from './MarketPanelKino'
import {
  POWERBALL_MINIGAME_GROUP,
  POWERLADDER_MINIGAME_GROUP,
  SUPPORT_MINIGAME_GROUP,
  WOORI_POWERBALL_MINIGAME_GROUP,
} from '~/v2/interface/config'
import { AllMiniGames } from '~/game/mg_data'

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const BasicTabs = (theme: Theme) => css`
  .MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
  .MuiTab-root {
    min-width: 33.3%;
    border-bottom: 1px solid ${theme.tabs.tabBorder};
    font-size: 1rem;
    color: #747d8a;
    background: ${theme.tabs.default};

    &.Mui-selected {
      background: ${theme.tabs.tabBg};
      color: ${theme.tabs.tabActive};
      border-bottom: 1px solid ${theme.tabs.tabActive};
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
`

const NotiStyle = styled('div')`
  position: fixed;
  bottom: calc(100vh - 50px - 36vh);
  //top: 330px;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1;

    & > img {
      width: 100%;
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgb(0 0 0 / 50%);
  }
`

// 탭 관련 ----------
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
// ----------

interface Props {
  group: string
  kind: string
}

const Minigame: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const history = useHistory()

  const { globalStore, liveStore, miniGameStore, authStore } = useRootStore()
  const { signedin } = authStore
  const { serviceState } = liveStore
  const { message, cartCollapsed } = miniGameStore
  const [noti, setNoti] = useState(null)
  const boundaryRef = useRef(null)

  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  const { group, kind } = props

  let width = undefined
  if (boundaryRef && boundaryRef.current) {
    // console.log(`width: ${boundaryRef.current.offsetWidth}`)
    width = boundaryRef.current.offsetWidth
  }

  let marketContents = null
  if (group === 'EVOPB') {
    marketContents = (
      <MarketPanelEvolutionPowerball kind={kind} width={width} key={kind}></MarketPanelEvolutionPowerball>
    )
  } else if (POWERBALL_MINIGAME_GROUP.indexOf(group) >= 0) {
    marketContents = <MarketPanelPowerball kind={kind} width={width} key={kind}></MarketPanelPowerball>
  } else if (POWERLADDER_MINIGAME_GROUP.indexOf(group) >= 0) {
    marketContents = <MarketPanelLadder kind={kind} width={width} key={kind}></MarketPanelLadder>
  } else if (WOORI_POWERBALL_MINIGAME_GROUP.indexOf(group) >= 0) {
    marketContents = <MarketPanelWooriKenoPowerball kind={kind}></MarketPanelWooriKenoPowerball>
  } else if (group === 'SG') {
    marketContents = <MarketPanelSquidgame kind={kind} width={width} key={kind}></MarketPanelSquidgame>
  } else if (group === 'KINO') {
    marketContents = <MarketPanelKino kind={kind} width={width} key={kind}></MarketPanelKino>
  }

  let tabItemList = _.filter(open_trading_kinds, o => {
    return SUPPORT_MINIGAME_GROUP.indexOf(o.trading_group) >= 0
  })
  tabItemList = _.orderBy(tabItemList, ['order'], ['asc'])

  let tabIndex = _.findIndex(tabItemList, o => {
    return o.trading_kind === kind
  })

  const showMessage = (message: string) => {
    const tmp = message.split(':')
    const backDrop = <div className="backdrop"></div>
    if (_.isArray(tmp)) {
      const [command, bet, type, trading_kind] = tmp
      if (command === 'command' && bet === 'bet' && kind === trading_kind) {
        if (type === 'start') {
          // show start ui
          setNoti(
            <>
              <img src={betStart} alt="베팅 시작" />
              {backDrop}
            </>,
          )
          setTimeout(() => {
            setNoti(null)
          }, 3000)
        } else if (type === 'end') {
          // show end ui
          setNoti(
            <>
              <img src={betEnd} alt="베팅 끝" />
              {backDrop}
            </>,
          )
          setTimeout(() => {
            setNoti(null)
          }, 3000)
        }
      }
    }
  }

  useEffect(() => {
    showMessage(message)
  }, [message])

  useEffect(() => {
    async function inline() {
      try {
        await liveStore.enterSpace('mg', 'MG', kind, '', false)
      } catch (err) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: err.message,
          callbackPositive: () => {},
        })
      }
    }

    if (serviceState === SOCKET_SERVICE_STATE.ENTERED && signedin) {
      inline()
    }
    // eslint-disable-next-line
  }, [serviceState])

  const tabContents = []
  for (let i = 0; i < tabItemList.length; i++) {
    const data = _.find(AllMiniGames, o => {
      return o.kind === tabItemList[i].trading_kind
    })
    if (data) {
      const name = intl.formatMessage({ id: data.localeId, defaultMessage: tabItemList[i].name })
      tabContents.push(<Tab key={i} className="basic_tab" label={name} {...a11yProps(i)} />)
    }
  }

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    const sel = tabItemList[newValue]
    history.push(`/minigame/search?group=${sel.trading_group}&kind=${sel.trading_kind}`)
    miniGameStore.setGameTab(tabItemList[newValue].trading_kind)
  }

  return (
    <Fragment>
      <Box sx={{ background: '#16202F' }}>
        <Container sx={{ p: 0 }}>
          <Tabs value={tabIndex} onChange={handleChangeTab} css={BasicTabs} variant="fullWidth">
            {tabContents}
          </Tabs>
        </Container>
      </Box>
      <Container fixed sx={{ backgroundColor: appBackground, p: 0, maxWidth: 'none !important' }}>
        <Grid container>
          <Grid item xs={12} ref={boundaryRef}>
            <NotiStyle>{noti}</NotiStyle>
            <div>{marketContents}</div>
          </Grid>
          <BettingCart></BettingCart>
        </Grid>
      </Container>
    </Fragment>
  )
}

export default observer(Minigame)
