/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react'
import {
  Grid,
  Box,
  Typography,
  Dialog,
  AppBar,
  IconButton,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'

import useRootStore from '@store/useRootStore'
import { MakeHistoryTrack } from '@store/minigameStore'
import { numberWithCommas } from '@utils/utils'
import apiGame from '@services/api/game'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { IMinigameBetRecords } from '~/v2/protocol/game'

// icon
import CloseIcon from '@mui/icons-material/Close'

//css
import { MobileModalStyle, ModalStyle, MobileTableStyle, TableStyle } from '@styles/modal.style'
import { PaginationStyle } from '~/styles/base.style'

function timestamp(date: Date) {
  var time = new Date(date)
  time.setHours(time.getHours() + 9)
  return time
    .toISOString()
    .replace('T', ' ')
    .substring(0, 19)
}

interface PageData {
  currPage: number
  totalPage: number
}

type BettingRecordTrackData = IMinigameBetRecords.Schema

const getPageData = (track: BettingRecordTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

const PopupBettingRecord: React.FC<{}> = ({}) => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const [track, setTrack] = useState<BettingRecordTrackData>(null)

  const { globalStore, authStore } = useRootStore()
  const { currentKey, currentValue } = globalStore
  const { signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.betting_record

  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex >= 0
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin) {
    // popup login
    globalStore.showModal('login', 0)
  }

  let bgSize = ''
  const GlobalStyle = () => css`
    body {
      ${isDesktop ? (bgSize = 'background-size: calc(100% - 17px) auto;') : ''}
    }
  `

  const fetch = async (params: IMinigameBetRecords.Params) => {
    try {
      const { offset, limit, total, items } = await apiGame.minigameRecords(params)
      const data: BettingRecordTrackData = {
        offset,
        limit,
        total,
        items,
      }
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    fetch({ offset: 0, limit: ItemPerPage })
  }, [])

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ offset, limit: ItemPerPage })
  }

  // bet record
  let recordContents = []
  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    const tracks = MakeHistoryTrack(track.items)

    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    for (let i = 0; i < tracks.length; i++) {
      const el = tracks[i]
      const round = el.round
      const id = el.uniqueId
      const kindName = el.kindName
      const betName = el.txtMarket
      const betVolume = numberWithCommas(el.betAmount)
      const rate = el.rate
      const winMoney = numberWithCommas(el.dividendAmount)
      const hitState = el.state
      const hitStateColor = el.stateColor
      const bettingTime = moment(new Date(el.bettingTime)).format('MM/DD hh:mm:ss')
      if (desktop) {
        recordContents.push(
          <TableRow key={`${el.betId}`}>
            <TableCell scope="row" align="left">
              [{round}회] <br />
              {bettingTime}
            </TableCell>
            <TableCell scope="row" align="left">
              {kindName}
            </TableCell>
            <TableCell align="center">
              {betName} ({rate})
            </TableCell>
            <TableCell align="right">
              {betVolume}
              {intl.formatMessage({ id: 'money-locale' })}
            </TableCell>
            <TableCell align="right">
              {winMoney}
              {intl.formatMessage({ id: 'money-locale' })}
            </TableCell>
            <TableCell align="center">
              <Chip
                label={hitState}
                size="small"
                sx={{ width: '60px', background: hitStateColor }}
              />
            </TableCell>
          </TableRow>,
        )
      } else {
        recordContents.push(
          <Grid container>
            <Grid item xs={4}>
              [{round}회]
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <Chip
                label={hitState}
                size="small"
                sx={{ width: '60px', background: hitStateColor }}
              />
            </Grid>
            <Grid item xs={4} className="date">
              {bettingTime}
            </Grid>
            <Grid xs={5}>
              <Box className="title">
                {intl.formatMessage({ id: 'minigame.power.table.bet.game' })}
              </Box>
              <Box>{kindName}</Box>
            </Grid>
            <Grid xs={7}>
              <Box className="title">
                {intl.formatMessage({ id: 'minigame.power.table.bet.choice' })}
              </Box>
              <Box className="choice">
                {betName} ({rate})
              </Box>
            </Grid>
            <Grid xs={5}>
              <Box className="title">
                {intl.formatMessage({ id: 'minigame.power.table.bet.volume' })}
              </Box>
              <Box>
                {betVolume}
                {intl.formatMessage({ id: 'money-locale' })}
              </Box>
            </Grid>
            <Grid xs={7}>
              <Box className="title">
                {intl.formatMessage({ id: 'minigame.power.table.bet.winmoney' })}
              </Box>
              <Box>
                {winMoney}
                {intl.formatMessage({ id: 'money-locale' })}
              </Box>
            </Grid>
          </Grid>,
        )
      }
    }
  }

  const pagination = (
    <PaginationStyle
      count={totalTrackPage}
      variant="outlined"
      page={currTrackPage}
      onChange={handlePagingChange}
    />
  )

  let innerContents = null
  if (desktop) {
    innerContents = (
      <Box sx={{ p: 2 }}>
        <TableStyle>
          <Table aria-label="betting history">
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ width: '140px' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.round' })},
                  {intl.formatMessage({ id: 'minigame.power.table.bet.time' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.game' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '180px' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.choice' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.volume' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.winmoney' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '70px' }}>
                  {intl.formatMessage({ id: 'minigame.power.table.bet.state' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{recordContents}</TableBody>
          </Table>
        </TableStyle>
        {pagination}
      </Box>
    )
  } else {
    innerContents = (
      <>
        <Box sx={{ p: 2 }}>
          <MobileTableStyle>{recordContents}</MobileTableStyle>
          {pagination}
        </Box>
      </>
    )
  }

  const titleContents = (
    <AppBar sx={{ position: 'relative' }}>
      <Typography className="modal_title" variant="h6" component="div">
        {intl.formatMessage({ id: 'component.betting-record' })}
      </Typography>
      <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </AppBar>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Dialog
        open={visible}
        onClose={onClose}
        css={ModalStyle}
        maxWidth={'md'}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '1000px',
          },
        }}
      >
        {titleContents}
        {innerContents}
      </Dialog>
    )
  } else {
    contents = (
      <Dialog open={visible} onClose={onClose} css={MobileModalStyle}>
        {titleContents}
        <Box component="div" sx={{ overflow: 'auto' }}>
          {innerContents}
        </Box>
      </Dialog>
    )
  }

  return (
    <>
      <Global styles={GlobalStyle} />
      {contents}
    </>
  )
}

export default observer(PopupBettingRecord)
