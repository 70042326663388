/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import queryString from 'query-string'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { styled } from '@mui/material/styles'

import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { IPartnerChargeSummary, Schema$PartnerChargeSummaryItem } from '~/v2/protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import useRootStore from '~/store/useRootStore'
import { PartnerModeKeys } from '~/store/globalStore'
import { useIntl } from 'react-intl'

const MyHeadCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: '6px',
  paddingRight: '6px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

const MyRowCell = styled(TableCell)(({ theme }) => ({
  padding: '6px',
  paddingRight: '6px',
  paddingTop: '10px',
  paddingBottom: '10px',
}))

interface Props {
  location: any
  default_begin: Date
  default_end: Date
}

function ComponentChargeSummary({ location, default_begin, default_end }: Props) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { uuid, main, begin, end, key, value, ts } = query

  const [timestamp, setTimestamp] = useState('')
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState<Schema$PartnerChargeSummaryItem | null>(null)

  const { globalStore } = useRootStore()

  if (timestamp !== (ts as string)) {
    setTimestamp(ts as string)
  }

  const fetch = async (params: IPartnerChargeSummary.Params) => {
    setLoading(true)
    try {
      const { item } = await apiPartner.getChargeSummary(params)
      setItem(item)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    let date_begin = begin ? (begin as string) : default_begin.toString()
    let date_end = end ? (end as string) : default_end.toString()
    const params: IPartnerChargeSummary.Params = {
      date_begin: date_begin,
      date_end: date_end,
      searchKey: key as string,
      searchValue: value as string,
      target_uuid: uuid as string,
    }

    fetch(params)
  }, [timestamp])

  const charge_amount = item ? item.charge_amount : 0
  const bonus_point_amount = item ? item.bonus_point_amount : 0
  const exchange_amount = item ? item.exchange_amount : 0
  const charge_exchange_offset = item ? item.charge_exchange_offset : 0
  const money = item ? item.money : 0
  const point = item ? item.point : 0
  const comp = item ? item.comp : 0

  return (
    <TableContainer component={Paper} style={{}}>
      <Table size="small" aria-label="collapsible table">
        <TableHead sx={{ bgcolor: '#007f93' }}>
          <TableRow>
            <MyHeadCell align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>충전 금액</Typography>
            </MyHeadCell>
            <MyHeadCell align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>보너스 포인트</Typography>
            </MyHeadCell>
            <MyHeadCell align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>환전 금액</Typography>
            </MyHeadCell>
            <MyHeadCell align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>충환 손익</Typography>
            </MyHeadCell>
            <MyHeadCell align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>현재 총 보유 머니</Typography>
            </MyHeadCell>
            <MyHeadCell sx={{ paddingRight: '16px' }} align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>현재 총 보유 포인트</Typography>
            </MyHeadCell>
            <MyHeadCell sx={{ paddingRight: '16px' }} align="right">
              <Typography sx={{ fontSize: '12px', color: 'white' }}>현재 총 보유 콤프</Typography>
            </MyHeadCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <MyRowCell align="right">
              <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(charge_amount)}</Typography>
            </MyRowCell>
            <MyRowCell align="right">
              <Typography sx={{ fontSize: '12x' }}>
                {numberWithCommas(bonus_point_amount)}
              </Typography>
            </MyRowCell>
            <MyRowCell align="right">
              <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(exchange_amount)}</Typography>
            </MyRowCell>
            <MyRowCell align="right">
              <Typography
                sx={{ fontSize: '12px', color: getGainLossColor(charge_exchange_offset) }}
              >
                {numberWithCommas(charge_exchange_offset)}
              </Typography>
            </MyRowCell>
            <MyRowCell align="right">
              <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(money)}</Typography>
            </MyRowCell>
            <MyRowCell sx={{ paddingRight: '16px' }} align="right">
              <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(point)}</Typography>
            </MyRowCell>
            <MyRowCell sx={{ paddingRight: '16px' }} align="right">
              <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(Math.floor(comp))}</Typography>
            </MyRowCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default observer(ComponentChargeSummary)
