import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { Box, Grid } from '@mui/material'
import asset_channel from '@assets/channel'

export const HomeContent = styled('div')`
  /* Casino, Slot, Minigame Title */
  .game_title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding-top: 24px;

    & > p.MuiTypography-root {
      color: #ffffff;
      font-weight: bold;
      font-size: ${props => props.theme.fonts.large};
    }

    .more {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      background: none;
      color: ${props => props.theme.colors.themeActive};
      font-size: ${props => props.theme.fonts.small};
    }
  }

  /* News */
  .main_list {
    border: 1px solid #404040;

    .main_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      background: url(${asset_channel.common.bg_main_title}) top center no-repeat;
      background-size: cover;
      color: #ffffff;
      font-size: ${props => props.theme.fonts.medium};
      & > span {
        padding: 0 18px;
      }

      .more {
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        background: none;
        color: ${props => props.theme.colors.themeActive};
        font-size: ${props => props.theme.fonts.small};
        padding-right: 16px;
      }
    }

    .MuiList-root {
      padding: 0 20px;
      background-color: #000;
      height: 180px;
      overflow: hidden;
    }
    .MuiListItem-root {
      padding: 0.47rem 0;
      justify-content: space-between;
      border-bottom: 1px dashed #404040;
      font-size: ${props => props.theme.fonts.small};
    }
    .MuiListItem-root .MuiGrid-container {
      align-items: center;
    }
    .MuiListItem-root .cate {
      display: inline-block;
      padding-top: 2px;
      width: 53px;
      border-radius: 10px;
      text-align: center;
      font-size: ${props => props.theme.fonts.tiny};
    }
    .MuiListItem-root .title {
      width: 70%;
      overflow: hidden;
    }
    .MuiListItem-root .title,
    .MuiListItem-root .title a {
      color: #ffffff;
      font-size: ${props => props.theme.fonts.medium};
    }
    .MuiListItem-root .date {
      color: #808080;
    }
    .MuiListItem-root .amount {
      text-align: end;
      color: ${props => props.theme.colors.themeActive};
    }
    .MuiListItem-root .state {
      text-align: end;
    }
    .MuiListItem-root .name {
      text-align: end;
      color: #808080;
    }
    .MuiListItem-root .content:hover a {
      color: ${props => props.theme.colors.themeActive};
    }
  }

  /* 카지노, 슬롯, 미니게임 */
  .marketing_banner a {
    cursor: pointer;
    display: block;
    overflow: hidden;
    border: 2px solid #ebbc1b;
    transition: transform 0.2s ease-in;
  }
  .marketing_banner_text {
    padding-top: 8px;
    min-height: 40px;
    margin-bottom: 0;
    border: 1px solid #1e2b3d;
    border-top-width: 0;
    border-radius: 0 0 8px 8px;
    background-color: #000;
    color: #808080;
    transition: background-color 0.2s ease-in;
    font-size: ${props => props.theme.fonts.default};
    text-align: center;
  }

  /* Hover Effect */
  .marketing_banner a:hover {
    border-color: ${props => props.theme.colors.themeActive};
    transform: translateY(-10px);
  }
`
export const QuickButtons = (theme: Theme) => css`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 64px;
  border-radius: 0.25rem;
  background: #fff;

  & > img {
    height: 30px;
  }

  .bar {
    height: 20px;
    border-left: 2px solid #dee2e6;
  }

  .title {
    color: #212529;
    font-weight: bold;
    font-size: ${theme.fonts.medium};
  }

  .title.small {
    font-weight: normal;
    font-size: ${theme.fonts.tiny};
  }

  .title > span {
    color: #212529;
    font-weight: bold;
    font-size: ${theme.fonts.medium};
  }
`

export const SnsLink = styled(Grid)(({ theme }) => ({
  '& .MuiGrid-item .main_list .main_title': {
    background: '#000',
  },
  '& .title_wrap': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .title_wrap img': {
    width: 26,
    height: 26,
    marginRight: 10,
  },
  '& .MuiButton-root, & .no_link': {
    color: theme.colors.themeTxtLighten,
  },
  '& .no_link': {
    padding: '6px 18px 6px 8px',
  },
}))


export const PopupWrapStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 10,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  alignContent: 'baseline',
  background: 'rgb(0 0 0 / 30%)'
}))