/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Chip, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

import { useOnMount } from '~/utils/reactExt'
import { IBetPendings, Schema$MatchupBetRecord } from '~/v2/protocol/game'
import useRootStore from '@store/useRootStore'
import apiGame from '@services/api/game'
import { MakeRecordTrack } from '@store/minigameStore'
import { CartListStyle } from './index.style'
import { numberWithCommas } from '~/utils/utils'

const BettingPendings: React.FC = props => {
  const intl = useIntl()
  const [items, setItems] = useState<Schema$MatchupBetRecord[]>([])
  const { authStore, miniGameStore, globalStore } = useRootStore()
  const { matchup, matchupSource, lastBetRecord, lastMatchupOutcome } = miniGameStore

  const { initialized, signedin } = authStore

  const fetch = async (params: IBetPendings.Params) => {
    if (!initialized || !signedin) {
      return
    }
    try {
      const { trading_kind, items } = await apiGame.pendings(params)
      setItems([...items])
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    if (matchupSource) {
      const outcome = _.get(lastMatchupOutcome, matchupSource.trading_kind)
      const { tradingId } = outcome || {}
      fetch({ trading_kind: matchupSource.trading_kind, trading_id: tradingId })
    } else {
      setItems([])
    }
  }, [matchupSource, lastBetRecord, lastMatchupOutcome])

  const listItems = []
  if (items.length > 0) {
    const tracks = MakeRecordTrack(matchup, items)
    for (const el of tracks) {
      const betVolume = `${numberWithCommas(el.betAmount)}${intl.formatMessage({
        id: 'money-locale',
      })} (${el.rate})`
      listItems.push(
        <li key={el.uniqueId}>
          <Box sx={{ display: 'flex', alignItems: 'center', flex: '1 1 0' }}>
            <div style={{ width: '60px' }}>
              <span>{el.round}회</span>
            </div>
            <div style={{ marginLeft: '12px' }}>
              <div>{el.txtMarket}</div>
              <div>{betVolume}</div>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Chip
                color="warning"
                size="small"
                label={el.state}
                sx={{ marginLeft: '8px', backgroundColor: el.stateColor }}
              />
            </div>
          </Box>
        </li>,
      )
    }
  }

  return (
    <Box className="bet_wrap" sx={{ mt: 2 }}>
      <Box columnGap={1} className="bet_title">
        <Typography component="h3">현재 베팅 내역</Typography>
      </Box>
      <CartListStyle>{listItems}</CartListStyle>
    </Box>
  )
}

export default observer(BettingPendings)
