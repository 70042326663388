/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Grid, Box, Typography, Button, GlobalStyles } from '@mui/material'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH, SPORT_SORT } from '@constants/index'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import ListIcon from '@mui/icons-material/List'
import { useHistory } from 'react-router-dom'

import SportsBettingCart from './BettingCart'
import BettingMenu from './BettingMenu'
import PrematchPanel from './PrematchPanel'
import { sleep } from '~/utils/utils'
import {
  SPORT_FILTER_ITEMS,
  GetInterestingSportFilterItems,
  FLIGHT_FILTER_ITEMS,
  SPORTS_SORT_ITEMS,
  SPORTS_LOCALE,
} from '~/game/st-util'
import { INTERESTING_SPORT_ID } from '~/v2/interface/st-types'
import { live, live2 } from '~/assets/images/sports'
import { Schema$OpenTradingKind } from '~/v2/protocol/public'
import { FETCH_STATE, SPORT_FLIGHT } from '~/store/sportsStore/sports-util'
import { toJS } from 'mobx'
import { ModalKeys } from '~/store/globalStore'

const BetInfoList = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'left',

  '& .btn_menu': {
    padding: '10px 0 2px 10px',
    color: '#D6D6D6',
  },
}))

// Tabs
export const SportsTabs = styled(Box)(({ theme }) => ({
  '& .MuiTab-root': {
    fontSize: '1.1rem',
    color: '#fff',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#43C8DE',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#43C8DE',
  },
}))

export const SortTabs = styled(Box)(({ theme }) => ({
  '& .MuiTab-root': {
    fontSize: '1.0rem',
    color: '#fff',
  },
  '& .MuiTab-root.Mui-selected': {
    color: '#43C8DE',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#43C8DE',
  },
}))

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const INTERESTING_SPORT_FILTER_ITEMS = GetInterestingSportFilterItems()

interface Props {
  id: string
  onChangeBegin: (sportId: string) => void
  onChangeEnd: (sportId: string) => void
}

const SportsHeader: React.FC<Props> = ({ id, onChangeBegin, onChangeEnd }) => {
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, sportsStore } = useRootStore()

  const { desktop } = globalStore.decideMedia(isDesktop)
  const { flight, sort, watch, fetchState, options, lang } = sportsStore
  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  const flightTabIndex = _.findIndex(FLIGHT_FILTER_ITEMS, o => {
    return o.value === flight
  })

  const sortTabIndex = _.findIndex(SPORTS_SORT_ITEMS, o => {
    return o.value === sort
  })

  const [sportTab, setSportTab] = useState<number>(0)
  const [flightTab, setFlightTab] = useState<number>(flightTabIndex)
  const [sortTab, setSortTab] = useState<number>(sortTabIndex)

  useEffect(() => {
    if (sportTab >= 0) {
      const td = INTERESTING_SPORT_FILTER_ITEMS[sportTab]
      onChangeBegin(td.value)
      onChangeEnd(td.value)
    }
  }, [])

  useEffect(() => {
    const { leagueId, locationId } = watch
    if (leagueId || locationId) {
      // setCurrentTab(
      //   _.findIndex(INTERESTING_SPORT_FILTER_ITEMS, o => {
      //     return o.value === 'etc'
      //   }),
      // )
      setSportTab(-1)
    }
  }, [watch])

  useEffect(() => {
    const forceFlight = (ff: SPORT_FLIGHT) => {
      if (flight !== ff) {
        const fi = _.findIndex(FLIGHT_FILTER_ITEMS, o => {
          return o.value === ff
        })
        setFlightTab(fi)
        sportsStore.setFlight(ff)
      }
      // clear bet slip
      sportsStore.clearCart()
      globalStore.hideModal(ModalKeys.sport_market)
    }
    // console.log(_.isObject(options?.sports_option) ? toJS(options.sports_option) : null)
    switch (options?.sports_option?.prematch_flight) {
      case 'all':
        break
      case 'domestic':
        forceFlight('domestic')
        break
      case 'europe':
        forceFlight('europe')
        break
    }
  }, [options])

  const handleSportTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSportTab(newValue)

    const td = INTERESTING_SPORT_FILTER_ITEMS[newValue]
    onChangeBegin(td.value)

    const dist = Math.abs(newValue - sportTab) * 30
    setTimeout(() => {
      onChangeEnd(td.value)
    }, 300 + dist)
  }

  const handleFlightTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setFlightTab(newValue)

    const newFlight = FLIGHT_FILTER_ITEMS[newValue].value as SPORT_FLIGHT
    sportsStore.setFlight(newFlight)
  }

  const handleSortTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSortTab(newValue)

    const newSort = SPORTS_SORT_ITEMS[newValue].value as SPORT_SORT
    sportsStore.setSort(newSort)
  }

  const onClickBettingRecord = () => {
    history.push('/sports/record')
  }

  // sport tabs
  const sportTabContents = []
  for (let i = 0; i < INTERESTING_SPORT_FILTER_ITEMS.length; i++) {
    const el = INTERESTING_SPORT_FILTER_ITEMS[i]
    sportTabContents.push(<Tab key={i} label={el.name[lang]} {...a11yProps(i)} />)
  }

  const fetching = fetchState === FETCH_STATE.FETCHING

  // flight tabs
  const inplayAvailable =
    _.find(open_trading_kinds, (o: Schema$OpenTradingKind) => {
      return o.trading_kind.indexOf('INPLAY') >= 0
    }) != null
  const flightTabContents = []
  for (let i = 0; i < FLIGHT_FILTER_ITEMS.length; i += 1) {
    const el = FLIGHT_FILTER_ITEMS[i]
    if (el.value === 'inplay') {
      flightTabContents.push(
        <Tab
          disabled={fetching && flight !== el.value}
          hidden={!inplayAvailable}
          key={i}
          {...a11yProps(i)}
          iconPosition="start"
          icon={<img src={live} style={{ height: '24px' }}></img>}
        />,
      )
    } else {
      const available =
        options?.sports_option?.prematch_flight === 'all' ||
        options?.sports_option?.prematch_flight === el.value

      flightTabContents.push(
        <Tab
          disabled={fetching && flight !== el.value}
          hidden={!available}
          key={i}
          label={el.name[lang]}
          {...a11yProps(i)}
        />,
      )
    }
  }

  const flightContents = (
    <SportsTabs id={id} sx={{ borderBottom: '1px solid #3B3B3B' }}>
      <Tabs
        value={flightTab}
        onChange={handleFlightTabChange}
        aria-label="basic tabs example"
        variant="scrollable"
        scrollButtons={false}
      >
        {flightTabContents}
      </Tabs>
    </SportsTabs>
  )
  // const flightContents = (
  //   <Box display="flex" alignItems="center">
  //     // <img src={live} style={{ height: '24px' }}></img>
  //     <SportsTabs id={id} sx={{ borderBottom: '1px solid #3B3B3B' }}>
  //       <Tabs
  //         value={flightTab}
  //         onChange={handleFlightTabChange}
  //         aria-label="basic tabs example"
  //         variant="scrollable"
  //         scrollButtons={false}
  //       >
  //         {flightTabContents}
  //       </Tabs>
  //     </SportsTabs>
  //   </Box>
  // )

  // sort tabs
  // const sortTabContents = []
  // for (let i = 0; i < SPORTS_SORT_ITEMS.length; i += 1) {
  //   const el = SPORTS_SORT_ITEMS[i]
  //   sortTabContents.push(<Tab key={i} label={el.name[lang]} {...a11yProps(i)} />)
  // }

  return (
    <Box>
      {!desktop && (
        <Box display="flex" justifyContent={'space-between'}>
          <BetInfoList>
            <Button
              endIcon={<ListIcon />}
              className="btn_menu"
              onClick={() => onClickBettingRecord()}
            >
              {SPORTS_LOCALE.betting_records.name[lang]}
            </Button>
          </BetInfoList>
          {flightContents}
        </Box>
      )}
      <Box display="flex" justifyContent={'space-between'}>
        <SportsTabs id={id} sx={{ borderBottom: '1px solid #3B3B3B', width: '100%' }}>
          <Tabs
            value={sportTab}
            onChange={handleSportTabChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons={false}
          >
            {sportTabContents}
          </Tabs>
        </SportsTabs>
        {desktop && flightContents}
      </Box>
    </Box>
  )
}

export default observer(SportsHeader)
