import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  LinearProgress,
} from '@mui/material'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle } from '@shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import {
  Schema$StateRevenueTrackData,
  Schema$StatsRevenueItem,
  IPartnerStatsRevenue,
} from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: number
  date: Date
  deposit: number
  withdraw: number
  offset: number
  LCA: number
  HCA: number
  SL: number
  SP: number
  HPB: number
  NPB: number
  PB: number
  PL: number
  EOS: number
  PBG: number
  CPB: number
  CL: number
  SG: number
  WRBTCPB: number
  WRBTCPL: number
  WRBEXPB: number
  WRKENOPB: number
  WRKENOPL: number
  WRKENO: number
  sum: number
  status: string
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>일자</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>입금액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>출금액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>입출금 차액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>라이브 카지노</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>호텔 카지노</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>슬롯</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>스포츠</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>동행파워볼</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>동행사다리</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>코인파워볼</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>코인사다리</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>오징어게임</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>합</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData }) {
  const { row } = props

  const isSumRow = row.status === 'sum'
  const styleSumRow = isSumRow ? { background: '#d8e4ff' } : {}

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...styleSumRow }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>
            {isSumRow ? '합' : moment(row.date).format('YYYY-MM-DD')}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.deposit)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.withdraw)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(row.offset) }}>
            {numberWithCommas(row.offset)}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.LCA)) }}>
            {numberWithCommas(Math.floor(row.LCA))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.HCA)) }}>
            {numberWithCommas(Math.floor(row.HCA))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.SL)) }}>
            {numberWithCommas(Math.floor(row.SL))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.SP)) }}>
            {numberWithCommas(Math.floor(row.SP))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.HPB)) }}>
            {numberWithCommas(Math.floor(row.HPB))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.NPB)) }}>
            {numberWithCommas(Math.floor(row.NPB))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.PB)) }}>
            {numberWithCommas(Math.floor(row.PB))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.PL)) }}>
            {numberWithCommas(Math.floor(row.PL))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.CPB)) }}>
            {numberWithCommas(Math.floor(row.CPB))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.CL)) }}>
            {numberWithCommas(Math.floor(row.CL))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.SG)) }}>
            {numberWithCommas(Math.floor(row.SG))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.sum)) }}>
            {numberWithCommas(Math.floor(row.sum))}
          </Typography>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

type TrackData = Schema$StateRevenueTrackData
const ItemPerPage = 10

function PartnerIntergratedStatsRevenue({ location }) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { page } = query
  const startPage = page ? Number(page) : 1

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [sum, setSum] = useState<Schema$StatsRevenueItem | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchBegin, setSearchBegin] = useState<Date>(start)
  const [searchEnd, setSearchEnd] = useState<Date>(new Date())

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { option } = globalStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups } = option || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-STATS-REVENUE')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.unknown)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const fetch = async (params: IPartnerStatsRevenue.Params) => {
    setLoading(true)

    try {
      const { data, sum } = await apiPartner.getStatsRevenue(params)
      setTrack(data)
      setSum(sum)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetch({
      search_begin: searchBegin.toString(),
      search_end: searchEnd.toString(),

      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    })
  }, [])

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
  }

  const onClickSearch = () => {
    fetch({
      search_begin: searchBegin.toString(),
      search_end: searchEnd.toString(),

      offset: 0,
      limit: ItemPerPage,
    })
  }

  const tradingGroups = []
  if (open_trading_groups) {
    for (let i = 0; i < open_trading_groups.length; i++) {
      tradingGroups.push(open_trading_groups[i].trading_group)
    }
  }

  const rows = []
  if (track) {
    function addRow(id: number, date: Date, data: Schema$StatsRevenueItem, isSum: boolean) {
      if (data) {
        const row: RowData = {
          id: id,
          date: date,
          deposit: data.deposit,
          withdraw: data.withdraw,
          offset: data.offset,
          LCA: _.get(data.dic, 'LCA') || 0,
          HCA: _.get(data.dic, 'HCA') || 0,
          SL: _.get(data.dic, 'SL') || 0,
          SP: _.get(data.dic, 'SP') || 0,
          HPB: _.get(data.dic, 'HPB') || 0,
          NPB: _.get(data.dic, 'NPB') || 0,
          PB: _.get(data.dic, 'PB') || 0,
          PL: _.get(data.dic, 'PL') || 0,
          EOS: _.get(data.dic, 'EOS') || 0,
          PBG: _.get(data.dic, 'PBG') || 0,
          CPB: _.get(data.dic, 'CPB') || 0,
          CL: _.get(data.dic, 'CL') || 0,
          SG: _.get(data.dic, 'SG') || 0,
          WRBTCPB: _.get(data.dic, 'WRBTCPB') || 0,
          WRBTCPL: _.get(data.dic, 'WRBTCPL') || 0,
          WRBEXPB: _.get(data.dic, 'WRBEXPB') || 0,
          WRKENOPB: _.get(data.dic, 'WRKENOPB') || 0,
          WRKENOPL: _.get(data.dic, 'WRKENOPL') || 0,
          WRKENO: _.get(data.dic, 'WRKENO') || 0,
          sum: data.sum,
          status: isSum ? 'sum' : 'normal',
        }
        rows.push(<Row key={row.id} row={row}></Row>)
      } else {
        const row: RowData = {
          id: id,
          date: date,
          deposit: 0,
          withdraw: 0,
          offset: 0,
          LCA: 0,
          HCA: 0,
          SL: 0,
          SP: 0,
          HPB: 0,
          NPB: 0,
          PB: 0,
          PL: 0,
          EOS: 0,
          PBG: 0,
          CPB: 0,
          CL: 0,
          SG: 0,
          WRBTCPB: 0,
          WRBTCPL: 0,
          WRBEXPB: 0,
          WRKENOPB: 0,
          WRKENOPL: 0,
          WRKENO: 0,
          sum: 0,
          status: 'normal',
        }
        rows.push(<Row key={row.id} row={row}></Row>)
      }
    }
    if (sum) {
      addRow(rows.length, null, sum, true)
    }
    const dateBegin = new Date(track.search_begin)
    const dateEnd = new Date(track.search_end)
    let date_iter = new Date(dateBegin.getFullYear(), dateBegin.getMonth(), dateBegin.getDate())
    const date_end = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate())
    while (date_iter.getTime() <= date_end.getTime()) {
      const found = _.find(track.items, o => {
        return o.date === moment(new Date(date_iter)).format('YYYY-MM-DD')
      })
      addRow(rows.length, new Date(date_iter), found, false)
      date_iter.setDate(date_iter.getDate() + 1)
    }
    if (sum) {
      addRow(rows.length, null, sum, true)
    }
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1600px',
          maxWidth: '1600px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>수익 통계</Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                  본인이 획득한 루징값 입니다.
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
            <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
            <Table size="small" aria-label="collapsible table">
              {Head()}
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedStatsRevenue)
