/** @jsxImportSource @emotion/react */
import React, { useRef, useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import Badge from '@mui/material/Badge'

import useRootStore from '@store/useRootStore'
import { numberWithCommas } from '@utils/utils'

// icon
import RefreshIcon from '@mui/icons-material/Refresh'
import MailIcon from '@mui/icons-material/Mail'
import { ReactComponent as IconCoupon } from '@assets/images/layout_1/icon_coupon.svg'
import { ReactComponent as IconPartner } from '@assets/images/layout_1/icon_partner.svg'
import asset_channel from '@assets/channel'

// css
import {
  Grid,
  Container,
  ButtonGroup,
  Button,
  IconButton,
  Box,
  Tooltip,
  Typography,
} from '@mui/material'
import { MainHeaderStyle, TopHeaderStyle } from './index.style'
import { Logo, putTopMenuService, putTopMenuPartner } from './index.component'
import { getLevelImage, HeaderProps } from '../../common/HeaderWrapper'

const outerContainerWidth = css`
  @media (min-width: 1200px) {
    max-width: 1248px;
  }
`

const btn_login = {
  minWidth: '80px',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  color: '#000',
  border: '1px solid #ebbd1c',
  background: 'linear-gradient(180deg,#eabb19,#ffd554)',
  '&:hover': {
    background: 'linear-gradient(180deg,#c09a17,#d8b447)',
  },
}
const btn_join = {
  ml: 1,
  minWidth: '80px',
  fontSize: '0.9rem',
  fontWeight: 'bold',
  color: '#ebbd1c',
  border: '1px solid #333',
  backgroundColor: '#1a1a1a',
  '&:hover': {
    backgroundColor: '#2b2b2b',
  },
}

const SHOW_GAME_MONEY = process.env.REACT_APP_SHOW_GAME_MONEY === 'true'

function Header(props: HeaderProps) {
  const intl = useIntl()

  const [subOpen, setSubOpen] = useState(false)
  const [refreshEnabled, setRefreshEnabled] = useState(null)

  const { globalStore, userStore } = useRootStore()
  const { navChange } = globalStore

  const {
    signedin,
    notification,
    game_money,
    option,
    userInfo,
    userMoney,
    userPoint,
    userComp,
    partnerMode,
    onClickNavPath,
    onClickHeaderButton,
    onClickHeaderMenu,
    onClickHeaderPartnerMenu,
    onClickHeaderLogo,
  } = props

  const { nickname, user_level, comp_level, merchant_class } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { balance: balanceComp } = userComp

  const {
    open_trading_groups,
    open_trading_kinds,
    enableComp,
    enableCoupon,
    excludePartnerMenuClass,
  } = option || {}

  const contents_level = enableComp ? comp_level : user_level

  const couponActive = notification && notification.usableCouponCount > 0 ? 'active' : ''

  useEffect(() => {
    // detect changes from external event!
  }, [navChange])

  const handleOpen = (event: Event | React.SyntheticEvent) => {
    setSubOpen(true)
  }
  const handleClose = (event: Event | React.SyntheticEvent) => {
    setSubOpen(false)
  }

  const onClickButton = (name: string) => {
    if (name === 'game-money') {
      const timer = setTimeout(() => {
        setRefreshEnabled(null)
      }, 5000)
      setRefreshEnabled(timer)
    } else if (name === 'withdraw') {
      globalStore.pushDialogYesNo({
        text: intl.formatMessage({ id: 'msg.ask.gamemoney-to-ownmoney' }),
        callbackPositive: async () => {
          const timer = setTimeout(() => {
            setRefreshEnabled(null)
          }, 5000)
          setRefreshEnabled(timer)
          await userStore.withdrawGameMoney(intl)
        },
      })
      return
    }
    onClickHeaderButton(name)
  }

  const onClickMenu = (name: string, arg: string) => {
    onClickHeaderMenu(name, arg)
    handleClose(null)
  }

  const onClickPartnerMenu = (name: string, arg: string) => {
    onClickHeaderPartnerMenu(name, arg)
    handleClose(null)
  }

  const onClickLogo = () => {
    onClickHeaderLogo()
  }

  const navActive = (path: string) => {
    return onClickNavPath(path)
  }

  let rightContent
  let menuContents
  if (signedin === true) {
    const showPartnerMenu = !excludePartnerMenuClass.includes(merchant_class.toString())
    let badgeContents
    if (notification) {
      const { unread } = notification
      if (unread > 0) {
        badgeContents = (
          <Badge
            badgeContent={unread}
            color="primary"
            sx={{ '& > span': { minWidth: '15px', height: '15px', fontSize: '0.85rem' } }}
          >
            <MailIcon fontSize="medium" />
          </Badge>
        )
      }
    }

    if (!badgeContents) {
      badgeContents = <MailIcon fontSize="medium" />
    }

    rightContent = (
      <div css={TopHeaderStyle} className="top_header">
        <Container css={outerContainerWidth}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Button
                onClick={() => onClickButton('myinfo')}
                className="btn_myinfo"
                sx={{ p: '0 8px 0 0', color: '#747D8A' }}
              >
                <img src={getLevelImage(contents_level)} alt="" />
                <span className="nickname">{nickname}</span>
                {/* <span style={{ paddingLeft: '10px', fontSize: '1.1rem' }}>레벨.{contents_level}</span> */}
              </Button>
              <IconButton onClick={() => onClickButton('message')} sx={{ color: '#747D8A' }}>
                {badgeContents}
              </IconButton>
              {enableCoupon ? (
                <IconButton
                  onClick={() => onClickButton('coupon')}
                  sx={{ color: '#747D8A' }}
                  className={couponActive}
                >
                  <IconCoupon />
                </IconButton>
              ) : null}
              {/* <Button
                onClick={() => onClickButton('qna')}
                color="warning"
                variant="contained"
                size="small"
                sx={{ minWidth: 0, ml: 1 }}
              >
                {intl.formatMessage({ id: 'qna' })}
              </Button> */}

              <ButtonGroup
                size="small"
                variant="contained"
                aria-label="small inherit button group"
                className="group_money"
                sx={{ ml: 1 }}
              >
                <Button onClick={() => onClickButton('bank/deposit')} className="deposit">
                  {intl.formatMessage({ id: 'deposit' })}
                </Button>
                <Button onClick={() => onClickButton('bank/withdraw')} className="withdraw">
                  {intl.formatMessage({ id: 'withdraw' })}
                </Button>
                <Button onClick={() => onClickButton('bank/history')} className="history">
                  {intl.formatMessage({ id: 'bank-history-short' })}
                </Button>
              </ButtonGroup>

              {showPartnerMenu ? (
                <Button
                  onClick={() => onClickButton('partner')}
                  variant="contained"
                  size="small"
                  sx={{
                    ml: 1,
                    p: '0 2px',
                    minWidth: 0,
                    backgroundColor: '#2366CB',
                    '& > svg': {
                      width: 26,
                      height: 26,
                    },
                  }}
                >
                  <IconPartner />
                </Button>
              ) : null}
            </Box>

            <Box sx={{ textAlign: 'end' }}>
              {SHOW_GAME_MONEY && !globalStore.option?.solution?.seamless && (
                <>
                  <Button
                    onClick={() => onClickButton('game-money')}
                    className="account"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_my_account"
                    data-id="my_wallet"
                    sx={{ ml: 1, fontSize: '0.9rem', color: '#FFFFFF', minWidth: 0 }}
                  >
                    {intl.formatMessage({ id: 'game-money' })}
                    <span style={{ color: '#43C8DE' }}>
                      {_.isNumber(game_money) ? numberWithCommas(Math.floor(game_money)) : '??'}
                    </span>
                    {intl.formatMessage({ id: 'money-locale' })}
                  </Button>
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '14px' }}>게임중머니를 갱신합니다.</Typography>
                    }
                  >
                    <Button
                      disabled={!!refreshEnabled}
                      onClick={() => onClickButton('game-money')}
                      color="primary"
                      variant="contained"
                      size="small"
                      sx={{
                        p: '1px 8px',
                        minWidth: '0',
                        height: '20px',
                        '&:disabled': {
                          backgroundColor: '#0f4a8d',
                          color: '#cccccc',
                        },
                      }}
                    >
                      <RefreshIcon />
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title={
                      <Typography sx={{ fontSize: '14px' }}>
                        게임중머니를 보유머니로 이동합니다.
                      </Typography>
                    }
                  >
                    <Button
                      disabled={!!refreshEnabled}
                      onClick={() => onClickButton('withdraw')}
                      color="error"
                      variant="contained"
                      size="small"
                      sx={{
                        p: '1px 8px',
                        minWidth: '0',
                        height: '20px',
                        ml: '4px',
                        '&:disabled': {
                          backgroundColor: '#931d1d',
                          color: '#cccccc',
                        },
                      }}
                    >
                      이동
                    </Button>
                  </Tooltip>
                </>
              )}
              <Button
                onClick={() => onClickButton('bank/depositCoin')}
                className="account"
                data-bs-toggle="modal"
                data-bs-target="#modal_my_account"
                data-id="my_wallet"
                sx={{ ml: 1, fontSize: '0.9rem', color: '#FFFFFF' }}
              >
                {intl.formatMessage({ id: 'own-money' })}
                <span style={{ color: '#43C8DE' }}>
                  {numberWithCommas(Math.floor(balanceMoney))}
                </span>
                {intl.formatMessage({ id: 'money-locale' })}
              </Button>
              <Button
                onClick={() => onClickButton('point')}
                className="account"
                data-bs-toggle="modal"
                data-bs-target="#modal_my_account"
                data-id="my_wallet"
                sx={{ fontSize: '0.9rem', color: '#FFFFFF' }}
              >
                {intl.formatMessage({ id: 'point' })}
                <span style={{ color: '#43C8DE' }}>
                  {numberWithCommas(Math.floor(balancePoint))}
                </span>
                P
              </Button>
              <Button
                onClick={() => onClickButton('point')}
                color="error"
                variant="contained"
                size="small"
                sx={{ p: '1px 8px', minWidth: '0' }}
              >
                {intl.formatMessage({ id: 'transfer' })}
              </Button>
              {enableComp ? (
                <>
                  <Button
                    onClick={() => onClickButton('comp')}
                    className="account"
                    data-bs-toggle="modal"
                    data-bs-target="#modal_my_account"
                    data-id="my_wallet"
                    sx={{ ml: 1, fontSize: '0.9rem', color: '#FFFFFF', minWidth: 0 }}
                  >
                    {intl.formatMessage({ id: 'comp' })}
                    <span style={{ color: '#43C8DE' }}>
                      {numberWithCommas(Math.floor(balanceComp))}
                    </span>
                    P
                  </Button>
                  <Button
                    onClick={() => onClickButton('comp')}
                    color="error"
                    variant="contained"
                    size="small"
                    sx={{ p: '1px 8px', minWidth: '0' }}
                  >
                    {intl.formatMessage({ id: 'transfer' })}
                  </Button>
                </>
              ) : null}

              {/* <Button onClick={() => onClickButton('myinfo')} sx={{ ml: 2, fontSize: '0.9rem', color: '#FFFFFF' }}>
                {intl.formatMessage({ id: 'myinfo-change' })}
              </Button> */}
              <Button
                onClick={() => onClickButton('logout')}
                sx={{ ml: 1, fontSize: '0.9rem', color: '#FFFFFF' }}
              >
                {intl.formatMessage({ id: 'logout' })}
              </Button>
            </Box>
          </Box>
        </Container>
      </div>
    )

    menuContents = (
      <div
        css={MainHeaderStyle}
        className={`secondary_header ${subOpen ? 'open' : 'close'}`}
        onMouseLeave={handleClose}
      >
        {partnerMode ? <div className={`dimmed_layer ${subOpen ? 'open' : 'close'}`}></div> : null}
        <Container css={outerContainerWidth}>
          <Grid container sx={{ height: '100%', alignItems: 'center' }}>
            <Grid item xs={12} className="main_menu_wrap">
              {Logo(() => onClickLogo())}
              {partnerMode
                ? putTopMenuPartner(intl, onClickPartnerMenu, handleOpen)
                : putTopMenuService(
                    intl,
                    open_trading_groups,
                    open_trading_kinds,
                    onClickMenu,
                    handleOpen,
                    navActive,
                  )}
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  } else {
    rightContent = (
      <>
        <div css={TopHeaderStyle} className="top_header">
          <Container css={outerContainerWidth}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Box>
                <Button size="small" onClick={() => onClickButton('login')} sx={btn_login}>
                  {intl.formatMessage({ id: 'login' })}
                </Button>
                <Button size="small" onClick={() => onClickButton('signup')} sx={btn_join}>
                  {intl.formatMessage({ id: 'signup' })}
                </Button>
              </Box>
            </Box>
          </Container>
        </div>
      </>
    )

    menuContents = (
      <div
        css={MainHeaderStyle}
        className={`secondary_header ${subOpen ? 'open' : 'close'}`}
        onMouseLeave={handleClose}
      >
        <Container css={outerContainerWidth}>
          <Grid container sx={{ height: '100%', alignItems: 'center' }}>
            <Grid item xs={12} className="main_menu_wrap">
              {Logo(() => onClickLogo())}
              {putTopMenuService(
                intl,
                open_trading_groups,
                open_trading_kinds,
                onClickMenu,
                handleOpen,
                navActive,
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
    )
  }

  return (
    <>
      {rightContent}
      {menuContents}
    </>
  )
}

export default observer(Header)
