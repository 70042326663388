import * as _ from 'lodash'

// export const POWERLADDER_OUTCOME_TEST = [
//   { id: 'PLLEFT_PLRIGHT', value: '1.95', odds: [] },
//   { id: 'PLLINE', value: '1.95', odds: [] },
//   { id: 'PLODD_PLEVEN', value: '1.95', odds: [] },
//   {
//     id: 'PLLEFT_PLRIGHT+PLLINE',
//     value: '3.60',
//     odds: [],
//   },
// ]

// 파워볼-일반볼 첫번째 숫자
// 홀 = 좌출발
// 짝 = 우출발
// 1~14 = 3줄
// 15~28 = 4줄
// 조합은 출발점+줄갯수

// 결론: 오즈는
// 좌출발/우출발
// 3줄/4줄
// 파워볼 플레이에 익숙한 유저를 위한 "홀/짝"은 추가된것으로 볼수 있음

// category
// PL - Power Ladder
export const PowerladderMarketMeta = [
  // 파워사다리
  {
    id: 'PLLEFT_PLRIGHT',
    category: 'PL',
    locale: { name: '출발', fullname: '파워사다리 출발' },
    metaOdds: ['PLLEFT', 'PLRIGHT'],
  },
  {
    id: 'PLLINE',
    category: 'PL',
    locale: { name: '줄', fullname: '파워사다리 줄' },
    metaOdds: ['PLLINE_3', 'PLLINE_4'],
  },
  {
    id: 'PLODD_PLEVEN',
    category: 'PL',
    locale: { name: '홀짝', fullname: '파워사다리 홀짝' },
    metaOdds: ['PLODD', 'PLEVEN'],
  },
  {
    id: 'PLLEFT_PLRIGHT+PLLINE',
    category: 'PL',
    locale: { name: '조합', fullname: '파워사다리 조합' },
    metaOdds: ['PLLEFT+PLLINE_4', 'PLLEFT+PLLINE_3', 'PLRIGHT+PLLINE_3', 'PLRIGHT+PLLINE_4'],
  },
]

export const PowerladderOddsMeta = [
  { id: 'PLLEFT', locale: { name: '좌출발' } },
  { id: 'PLRIGHT', locale: { name: '우출발' } },

  { id: 'PLLINE_3', locale: { name: '3줄' } },
  { id: 'PLLINE_4', locale: { name: '4줄' } },

  // additional odds for people who get used to playing powerball
  { id: 'PLODD', locale: { name: '홀' } },
  { id: 'PLEVEN', locale: { name: '짝' } },

  { id: 'PLLEFT+PLLINE_4', locale: { name: '좌 4 홀' } },
  { id: 'PLLEFT+PLLINE_3', locale: { name: '좌 3 짝' } },
  { id: 'PLRIGHT+PLLINE_3', locale: { name: '우 3 홀' } },
  { id: 'PLRIGHT+PLLINE_4', locale: { name: '우 4 짝' } },
]
