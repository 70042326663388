import React, { useState } from 'react'
import * as _ from 'lodash'
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PrematchFixture from '../PrematchFixture'
import urlJoin from 'url-join'
import { useTheme } from '@mui/material/styles'
import useRootStore from '~/store/useRootStore'
import { Schema$Prematch } from '~/v2/interface/st-schema'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { SPORT_FILTER_ITEMS } from '~/game/st-util'
import { PrematchData, SPORT_FLIGHT, UPDATE_FIELD_TYPE } from '~/store/sportsStore/sports-util'

interface Props {
  lang: string
  flight: SPORT_FLIGHT
  items: PrematchData[]
}

function DesktopPrematchLeagueBox({ lang, flight, items }: Props) {
  const theme = useTheme()
  const [expanded, setExpanded] = useState<boolean>(true)
  const [timestamp, setTimestamp] = useState<number>(0)
  const [locationEmblemError, setLocationEmblemError] = useState<boolean>(false)
  const [leagueEmblemError, setLeagueEmblemError] = useState<boolean>(false)

  const { sportsStore } = useRootStore()
  const { options } = sportsStore
  const { emblem_path } = options || {}
  const [top] = items

  useOnMount(() => {
    const { LeagueId } = top.prematch
    sportsStore.addLeagueController(LeagueId, (fixture: Schema$Prematch, type: UPDATE_FIELD_TYPE) => {
      setTimestamp(new Date().getTime())
    })
  })

  useOnUnmount(() => {
    const { LeagueId } = top.prematch
    sportsStore.deleteLeagueController(LeagueId)
  })

  const handleExpanded = (expand: boolean) => {
    setExpanded(expand)
  }

  const onLocationEmblemError = () => {
    setLocationEmblemError(true)
  }

  const onLeagueEmblemError = () => {
    setLeagueEmblemError(true)
  }

  const title1X2 = _.find(top.markets, o => {
    return o.Name === '1X2'
  })
    ? '승무패'
    : '승패'
  let sportName = null
  let locationName = null
  let locationEmblem = null
  let leagueName = null
  let leagueEmblem = null
  const contents = []
  if (top) {
    // sportName = top.prematch.SportName[lang] || top.prematch.SportName['en']
    sportName = _.find(SPORT_FILTER_ITEMS, o => {
      return o.value === top.prematch.SportId.toString()
    })?.symbol

    locationName = top.prematch.LocationName[lang] || top.prematch.LocationName['en']
    if (!locationEmblemError) {
      locationEmblem =
        emblem_path && top.prematch.LocationEmblem ? urlJoin(emblem_path, top.prematch.LocationEmblem) : null
    }

    leagueName = top.prematch.LeagueName[lang] || top.prematch.LeagueName['en']
    if (!leagueEmblemError) {
      leagueEmblem = emblem_path && top.prematch.LeagueEmblem ? urlJoin(emblem_path, top.prematch.LeagueEmblem) : null
    }

    for (const el of items) {
      const sx = contents.length > 0 ? { mt: '4px' } : {}
      if (contents.length > 0) {
        contents.push(
          <Divider
            key={contents.length}
            variant="fullWidth"
            sx={{ mt: '4px', borderBottomWidth: '3px', color: theme.sportsMatchup.fixtureBg }}
          ></Divider>,
        )
      }
      contents.push(
        <PrematchFixture
          key={contents.length}
          desktop={true}
          virtualDesktop={true}
          flight={flight}
          lang={lang}
          item={el}
          sx={sx}
        ></PrematchFixture>,
      )
    }
  }

  const title = [
    <Typography key={0} sx={{ color: '#d6d6d6', fontSize: '14px', ml: '6px' }}>
      {sportName}
    </Typography>,
  ]

  if (locationEmblem) {
    title.push(
      <img
        key={title.length}
        alt={null}
        src={locationEmblem}
        style={{ height: 18, marginLeft: '10px' }}
        onError={onLocationEmblemError}
      ></img>,
    )
  }
  title.push(
    <Typography key={title.length} sx={{ color: '#d6d6d6', fontSize: '14px', ml: locationEmblem ? '4px' : '10px' }}>
      {locationName}
    </Typography>,
  )

  if (leagueEmblem) {
    title.push(
      <img
        key={title.length}
        alt={null}
        src={leagueEmblem}
        style={{ height: 18, marginLeft: '10px' }}
        onError={onLeagueEmblemError}
      ></img>,
    )
  }
  title.push(
    <Typography key={title.length} sx={{ color: '#d6d6d6', fontSize: '14px', ml: leagueEmblem ? '4px' : '10px' }}>
      {leagueName}
    </Typography>,
  )

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          minHeight: '36px',
          cursor: 'pointer',
          background: theme.sportsMatchup.leagueTitle,
          pl: 1,
          pr: 1,
        }}
        onClick={() => handleExpanded(!expanded)}
      >
        <Box display="flex" alignItems="center" sx={{ width: 'calc(100% - 60px)' }}>
          {title}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="end" sx={{ width: '60px' }}>
          <IconButton size="medium" color="primary">
            {expanded ? (
              <ExpandLessIcon fontSize="medium" sx={{ color: '#d6d6d6' }} />
            ) : (
              <ExpandMoreIcon fontSize="medium" sx={{ color: '#d6d6d6' }} />
            )}
          </IconButton>
        </Box>
      </Box>
      <Box
        hidden={!expanded}
        sx={{
          background: theme.sportsMatchup.leagueContents,
          p: 1,
          '&:last-child': {
            paddingBlockStart: 1,
          },
        }}
      >
        {contents}
      </Box>
    </>
  )
}

export default DesktopPrematchLeagueBox
