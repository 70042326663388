/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Grid, Typography } from '@mui/material'
import { Schema$Prematch } from '~/v2/interface/st-schema'
import useRootStore from '~/store/useRootStore'
import { getTeamName } from '~/game/st-util'

interface Props {
  desktop: boolean
  prematch: Schema$Prematch
}

function PrematchFixtureTrack({ desktop, prematch }: Props) {
  const { sportsStore } = useRootStore()
  const { lang } = sportsStore

  const homeTeamName = getTeamName(prematch, '1', lang)
  const awayTeamName = getTeamName(prematch, '2', lang)

  const startDate = new Date(prematch.StartDate)
  const dateOfMatch = startDate.toLocaleString()

  return (
    <Box key="date" sx={{ width: '100%', background: '#182f49', p: 3 }}>
      <Grid container>
        <Grid item xs={5} display="flex" alignItems="center" justifyContent={desktop ? 'right' : 'center'}>
          <Typography sx={{ color: '#d6d6d6', fontSize: '16px' }}>{homeTeamName}</Typography>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center" justifyContent="center">
          <Typography sx={{ color: '#838485', fontSize: '16px' }}>vs</Typography>
        </Grid>
        <Grid item xs={5} display="flex" alignItems="center" justifyContent={desktop ? 'left' : 'center'}>
          <Typography sx={{ color: '#d6d6d6', fontSize: '14px' }}>{awayTeamName}</Typography>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
        <Typography
          sx={{
            marginTop: '10px',
            fontSize: '14px',
            color: '#838485',
          }}
        >
          {dateOfMatch}
        </Typography>
      </Box>
    </Box>
  )
}

export default observer(PrematchFixtureTrack)
