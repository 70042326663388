/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
//import { Table } from 'react-bootstrap'
import { Box, Button, AppBar, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'

import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

//css
import { MobileModalStyle, ModalStyle } from './index.style'
import { IconClose } from '~/assets/images/layout_1'

interface Props {
  id: number
  title: string
  content: string
  width: number
  height: number
  x: number
  y: number
  popupType: string
  popupImage?: string
  onClose: (id: number, nomore: boolean) => void
}

const PopupType1: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()

  const [nomore, setNomore] = useState(false)

  const { desktop } = globalStore.decideMedia(isDesktop)

  const { id, title, content, x, y, onClose } = props

  const DialogTypeBox = desktop
    ? {
        flex: '0 0 350px',
        position: 'relative',
        left: x,
        top: y,
        zIndex: '1201',
        margin: '0 16px 16px',
        minHeight: 0,
        width: '350px',
        height: '560px',
        overflowY: 'hidden !important',
      }
    : {
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: '1201',
        margin: 0,
        minHeight: 0,
        width: '100%',
        height: '100vh',
        background: 'rgb(0 0 0 / 50%)',
        overflowY: 'hidden !important',
      }
      
  const DialogTypeBoxInner = desktop
    ? {
        height: '100%',
        background: '#0A1424',
      }
    : {
      background: '#0A1424',
    }

  const onClosePopup = () => {
    onClose(id, nomore)
  }

  const onClickCheckbox = () => {
    onClose(id, true)
  }

  const style = desktop ? ModalStyle : MobileModalStyle

  let contents = null

  contents = (
    <Box css={style} sx={DialogTypeBox}>
      <Box sx={DialogTypeBoxInner}>
        <AppBar sx={{ position: 'relative' }}>
          {/* <Typography className="modal_title" variant="h6" component="div">
            {title}
          </Typography> */}
          <IconButton color="inherit" onClick={onClosePopup} aria-label="close" sx={{ top: 6, right: 6 }}>
            <IconClose />
          </IconButton>
        </AppBar>

        <Box component="div" 
          sx={ desktop ? { height: 'calc(100% - 39px)' } : { maxHeight: 'calc(100vh - 39px)' }}
          style={{ overflow: 'auto' }}
        >
          {props.popupType === 'TEXT' ? (
            <Box
              sx={{
                padding: '16px',
                color: '#fff',
                '& p': {
                  marginBottom: 0,
                },
                '& img': {
                  width: '100%',
                },
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Box>
          ): null}

          {props.popupType === 'IMAGE' ? (
            <Box
              sx={{
                '& img': {
                  width: '100%',
                },
              }}
            >
              <img src={props.popupImage} alt="" />
            </Box>
          ): null}
        </Box>
        <Box>
          <Button
            onClick={() => onClickCheckbox()}
            sx={{
              padding: '8px',
              width: '100%',
              background: 'linear-gradient(#2a2a2a 0%, #171717 100%)',
              color: 'white',
              textAlign: 'start',
              fontSize: '1rem',
              borderRadius: 0,
              textTransform: 'unset',
            }}
          >
            {intl.formatMessage({ id: 'stop-watching-today' })}
          </Button>
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      {contents}
    </>
  )
}

export default observer(PopupType1)
