import React, { useState } from 'react'
import * as _ from 'lodash'
import { Box } from '@mui/material'
import { OddsBox12, OddsBox1X2, OddsBoxBaseline } from '../PrematchOdds'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { useTheme } from '@mui/material/styles'
import { Schema$FilteredMarket, Schema$Prematch } from '~/v2/interface/st-schema'
import { SPORT_FLIGHT } from '~/store/sportsStore/sports-util'

interface Props {
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  prematch: Schema$Prematch
  market: Schema$FilteredMarket
  unifiedName: string
  sx?: any
}

function MarketBoxMoneyline({ desktop, lang, flight, prematch, market, unifiedName, sx }: Props) {
  const theme = useTheme()

  let marketContents = null
  if (unifiedName === '1X2') {
    const oddsProps = {
      theme,
      desktop,
      lang,
      flight,
      prematch,
      market,
    }
    marketContents = <OddsBox1X2 key={market.Id} {...oddsProps}></OddsBox1X2>
  } else if (unifiedName === '12') {
    const oddsProps = {
      theme,
      desktop,
      lang,
      flight,
      prematch,
      market,
    }
    marketContents = <OddsBox12 key={market.Id} {...oddsProps}></OddsBox12>
  }

  return <Box sx={{ ...sx }}>{marketContents}</Box>
}

export default observer(MarketBoxMoneyline)
