import * as _ from 'lodash'
import moment from 'moment'
// import { HOME_OR_AWAY_SCORE } from '@constants/index'
import { TRANSFER_KIND, TRANSFER_STATE } from '../game/config'
import { MERCHANT_CLASS } from '@interface/config'

let APP_CHARGES = JSON.parse(process.env.REACT_APP_CHARGES) || []
let withdrawSize = 0
for (const el of APP_CHARGES) {
  withdrawSize += el.indexOf('withdraw') >= 0 ? 1 : 0
}
if (withdrawSize === 0) {
  APP_CHARGES = APP_CHARGES.concat(['withdraw'])
}
APP_CHARGES = APP_CHARGES.concat(['history'])
export { APP_CHARGES }

export function formatNumber(src: number | string | undefined) {
  const value = String(src || 0)
  const list = value.split('.')
  const prefix = list[0].charAt(0) === '-' ? '-' : ''
  let num = prefix ? list[0].slice(1) : list[0]
  let result = ''
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`
}

export function formatSimpleNumber(src: number | string | undefined) {
  return formatNumber(src)
}

const timeout = ms => new Promise(res => setTimeout(res, ms))

export async function delay(ms) {
  await timeout(ms)
}

export function findString(value: string, searchValue: string) {
  if (value.indexOf(searchValue) !== -1) {
    return `<BettorName>${value}</BettorName>`
  }
  return `${value}`
}

export function titleTextPatternCheck(text: string): boolean {
  return /^[가-힣a-zA-Z\d]+$/.test(text)
}

export class Profiler {
  date: Date

  name: string

  begin = (n: string) => {
    this.name = n
    this.date = new Date()
  }

  end = () => {
    const tmp = new Date()
    const offset = tmp.valueOf() - this.date.valueOf()
    console.error(`profiler: ${this.name} offset= ${offset} millisec`)
  }
}

export function saveValueToStorage<T>(key: string, value: T): void {
  if (value && typeof value !== 'function') {
    sessionStorage.setItem(key, JSON.stringify(value))
  }
}

export function getValueFromStorage<T>(key: string): T {
  const value = sessionStorage.getItem(key)
  if (value) {
    return JSON.parse(value)
  }
  return null
}

export function deleteStorageValue(key: string): void {
  sessionStorage.removeItem(key)
}

export function numberWithCommas(x: number | bigint | string): string {
  let ss: string = ''

  if (_.isString(x)) {
    ss = x
  } else if (_.isNumber(x)) {
    ss = x.toString()
  } else if (_.isObject(x)) {
    ss = (x as bigint).toString()
  }

  return ss.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function fillZero(width, str) {
  return str.length >= width ? str : new Array(width - str.length + 1).join('0') + str //남는 길이만큼 0으로 채움
}

export function difficultUsername(username: string) {
  let tmp = username.slice(username.length - 2).padStart(username.length, '*')
  return tmp
}

export const testAndPopupCenterGame = ({ url, title, w, h }): { wnd: Window; exist: boolean } => {
  const child = popupCenter({ url: '', title: '8a6d662c', w, h })
  if (!child) {
    return null
  }
  if (child.location.href === 'about:blank') {
    child.location.href = url
  }
  return { wnd: child, exist: child.location.href !== 'about:blank' }
}

export const popupCenterGame = ({ url, title, w, h }): Window => {
  return popupCenter({ url, title: '8a6d662c', w, h })
}

export const popupCenter = ({ url, title, w, h }): Window => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  // const width = window.innerWidth
  //   ? window.innerWidth
  //   : document.documentElement.clientWidth
  //   ? document.documentElement.clientWidth
  //   : window.screen.width
  // const height = window.innerHeight
  //   ? window.innerHeight
  //   : document.documentElement.clientHeight
  //   ? document.documentElement.clientHeight
  //   : window.screen.height
  const width = window.screen.width
  const height = window.screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop - 30
  const newWindow = window.open(
    url,
    title,
    `
		scrollbars=no,
		width=${w / systemZoom}, 
		height=${h / systemZoom}, 
		top=${top}, 
		left=${left}
		`,
  )

  if (window.focus) {
    if (newWindow) {
      newWindow.focus()
    }
  }

  return newWindow
}

export function popupPartnerMember(uuid: string, key: string) {
  const titlePrefix = 'Member'
  const url = `/partner/member/search?uuid=${uuid}&main=${key}`

  const w = 1400
  const h = 800
  popupCenter({ url, title: `${titlePrefix}-${uuid}`, w, h })
}

export function popupPartnerMemberWithPeriod(uuid: string, key: string, begin: Date, end: Date) {
  const titlePrefix = 'Member'
  const tbegin = moment(begin).format('YYYY-MM-DD')
  const tend = moment(end).format('YYYY-MM-DD')
  const url = `/partner/member/search?uuid=${uuid}&main=${key}&begin=${tbegin}&end=${tend}`

  const w = 1400
  const h = 800
  popupCenter({ url, title: `${titlePrefix}-${uuid}`, w, h })
}

export function getMerchantClass(index) {
  return 0 <= index && index < MERCHANT_CLASS.length ? MERCHANT_CLASS[index] : ''
}

export function getTransferKind(kind) {
  return typeof TRANSFER_KIND[kind] !== 'undefined' ? TRANSFER_KIND[kind] : ''
}

export function getTransferState(state) {
  return typeof TRANSFER_STATE[state] !== 'undefined' ? TRANSFER_STATE[state] : ''
}

export function viewSimpleDateTime(date) {
  return typeof date !== 'undefined'
    ? date.substr(5, 5).replace('-', '/') + ' ' + date.substr(11, 5)
    : ''
}

export function viewDateMin(date) {
  return typeof date !== 'undefined' ? date.substr(0, 16) : ''
}

export function viewDateTime(date) {
  return typeof date !== 'undefined' ? date.substr(0, 19) : ''
}

export function partnerMemberPopup(uuid: string, tab = 'member_info') {
  var url = '#/partner/member/' + uuid + '/' + tab
  var title = 'member_' + uuid
  popupCenter({ url: url, title: title, w: '850px', h: '800px' })
}

export function getTransferStateClassName(transfer_state: string) {
  switch (transfer_state) {
    case 'REQUEST':
      return 'primary'
    case 'SUCCESS':
      return 'success'
    case 'PROCESS':
      return 'warning'
    case 'CANCEL':
    case 'FAIL':
      return 'error'
  }
  return null
}

export function getBetStateClassName(bet_state) {
  let btnClass
  switch (bet_state) {
    case 'BET':
      btnClass = 'btn-primary'
      break
    case 'WIN':
      btnClass = 'btn-success'
      break
    case 'LOSE':
      btnClass = 'btn-secondary'
      break
    default:
      btnClass = 'btn-warning'
      break
  }
  return btnClass
}

export function getGainLossColor(n: number) {
  if (n > 0) {
    return 'blue'
  } else if (n < 0) {
    return 'red'
  }
  return ''
}

export function isDateSame(a: Date, b: Date): boolean {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
  )
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function invalidateNumber(v: string | number) {
  let test = v
  if (_.isString(test)) {
    test = test.replace(/[^0-9]/g, '') // 입력값이 숫자가 아니면 공백
    test = test.replace(/,/gi, '') // ,값 공백처리
    test = Number(test)
  } else if (!_.isNumber(test)) {
    test = 0
  }
  return test
}

export const stringWithComma = (x: string) => {
  x = x.replace(/[^0-9]/g, '') // 입력값이 숫자가 아니면 공백
  x = x.replace(/,/g, '') // ,값 공백처리
  x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return x
}

export function randomBetween(low, high) {
  return Math.random() * (high - low) + low
}

export function makeDateOnly(d: Date) {
  return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

export function getDatePasses(startDate: Date, endDate: Date) {
  const begin = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
  const offset = end.getTime() - begin.getTime()
  const diff = offset / (1000 * 60 * 60 * 24)
  return diff
}

export function chunkArray(arr: any, n: number) {
  if (!_.isArray(arr)) {
    return null
  }

  return [...Array(Math.ceil(arr.length / n))].map((_, i) => arr.slice(n * i, n + n * i))
}

export function setScreenSize() {
  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
