/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Button } from '@mui/material'

import useRootStore from '@store/useRootStore'

// icon
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// css
import { CartListStyle } from './index.style'
import { styled } from '@mui/material/styles'
import SportsBettingCartItem from './BettingCartItem'
import BettingCartControlBox from './BettingCartControlBox'
import SportsBettingCartBonusItem from './BettingCartIBonustem'

const BettingControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 8px',
  borderTop: '1px solid #CFCFCF',
  fontSize: '0.9rem',

  '& .badge': {
    backgroundColor: '#0F0F0F',
    borderRadius: '50%',
    color: '#fff',
    padding: '3px 0',
    width: '16px',
  },
}))

const ImplSportsBettingCart: React.FC<{}> = props => {
  const { sportsStore } = useRootStore()

  const { lang, cartItems, foldBonusItem } = sportsStore

  const onClickDeleteAll = () => {
    sportsStore.clearCart()
  }

  let numberOfFold = 0
  const bettingItemContents = []
  if (cartItems) {
    numberOfFold = cartItems.length
    for (const el of cartItems) {
      bettingItemContents.push(
        <li>
          <SportsBettingCartItem lang={lang} item={el}></SportsBettingCartItem>
        </li>,
      )
    }
  }

  if (foldBonusItem) {
    bettingItemContents.push(
      <li>
        <SportsBettingCartBonusItem lang={lang} item={foldBonusItem}></SportsBettingCartBonusItem>
      </li>,
    )
  }

  return (
    <Box className="bet_wrap" sx={{ height: '100%' }}>
      <BettingControl>
        <Box>
          <span className="badge" style={{ marginRight: '2px' }}>
            {numberOfFold}
          </span>
          {lang === 'ko' ? '폴드' : 'Folds'}
        </Box>
        <Box onClick={() => onClickDeleteAll()} sx={{ cursor: 'pointer' }}>
          <HighlightOffIcon />
          {lang === 'ko' ? '전체삭제' : 'Delete All'}
        </Box>
      </BettingControl>

      <Box sx={{ overflowY: 'auto', height: 'calc(100% - 360px)', background: '#dedede' }}>
        <CartListStyle>{bettingItemContents}</CartListStyle>
      </Box>

      <BettingCartControlBox></BettingCartControlBox>
    </Box>
  )
}

export default observer(ImplSportsBettingCart)
