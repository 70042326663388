import * as _ from 'lodash'
import { BANK_CODES } from '~/v2/interface/bank'

export { BANK_CODES }

const common_coin_name = process.env.REACT_APP_COMMON_COIN_NAME || '코인충전'
const common_virtual_name = process.env.REACT_APP_COMMON_VIRTUAL_NAME || '가상계좌충전'

// ui 관련된 상수 오브젝트 enum 등의 값을 적는다
export const BANK_MENU = {
  id: 0,
  query: 'bank',
  url: '/bank',
  title: '충전/환전',
  localeId: 'component.bank-transaction',
  sub: {
    deposit: {
      query: 'deposit',
      url: '/bank/search?main=deposit',
      title: '충전',
      localeId: 'component.bank.deposit',
    },
    depositDC: {
      query: 'depositDC',
      url: '/bank/search?main=depositDC',
      title: '가상계좌충전',
      localeId: 'component.bank.deposit.dc',
    },
    depositOnepay: {
      query: 'depositOnepay',
      url: '/bank/search?main=depositOnepay',
      title: '원페이충전',
      localeId: 'component.bank.deposit.onepay',
    },
    depositCoinOXO: {
      query: 'depositCoinOXO',
      url: '/bank/search?main=depositCoinOXO',
      title: '코인충전',
      localeId: 'component.bank.deposit.coin',
    },
    depositCoin: {
      query: 'depositCoin',
      url: '/bank/search?main=depositCoin',
      title: '덱스코인충전',
      localeId: 'component.bank.deposit.dexcoin',
    },
    depositCoinJun200: {
      query: 'depositCoinJun200',
      url: '/bank/search?main=depositCoinJun200',
      title: '준코인충전',
      localeId: 'component.bank.deposit.juncoin',
    },
    deposit5Koin: {
      query: 'deposit5Koin',
      url: '/bank/search?main=deposit5Koin',
      title: '케이코인충전',
      localeId: 'component.bank.deposit.5koin',
    },
    depositWonp: {
      query: 'depositWonp',
      url: '/bank/search?main=depositWonp',
      title: '원피충전',
      localeId: 'component.bank.deposit.wonp',
    },
    depositCoinWiki: {
      query: 'depositCoinWiki',
      url: '/bank/search?main=depositCoinWiki',
      title: '코인위키충전',
      localeId: 'component.bank.deposit.coinwiki',
    },
    depositVENICN: {
      query: 'depositVENICN',
      url: '/bank/search?main=depositVENICN',
      title: '가상계좌충전',
      localeId: 'component.bank.deposit.venicin',
    },
    depositOnePayCoin: {
      query: 'depositOnePayCoin',
      url: '/bank/search?main=depositOnepayCoin',
      title: '코인충전',
      localeId: 'component.bank.deposit.coin',
    },
    depositCommonCoin: {
      query: 'depositCommonCoin',
      url: '/bank/search?main=depositCommonCoin',
      title: common_coin_name,
      localeId: 'component.bank.deposit.coin',
    },
    depositCommonVirtual: {
      query: 'depositCommonVirtual',
      url: '/bank/search?main=depositCommonVirtual',
      title: common_virtual_name,
      localeId: 'component.bank.deposit.commonvirtual',
    },
    depositAuthVirtual: {
      query: 'depositAuthVirtual',
      url: '/bank/search?main=depositAuthVirtual',
      title: common_virtual_name,
      localeId: 'component.bank.deposit.authvirtual',
    },
    depositMCI: {
      query: 'depositMCI',
      url: '/bank/search?main=depositMCI',
      title: common_virtual_name,
      localeId: 'component.bank.deposit.mci',
    },
    depositCodepay: {
      query: 'depositCodepay',
      url: '/bank/search?main=depositCodepay',
      title: common_virtual_name,
      localeId: 'component.bank.deposit.codepay',
    },
    withdrawCodepay: {
      query: 'withdrawCodepay',
      url: '/bank/search?main=withdrawCodepay',
      title: '코드페이환전',
      localeId: 'component.bank.withdraw.codepay',
    },
    withdraw: {
      query: 'withdraw',
      url: '/bank/search?main=withdraw',
      title: '환전',
      localeId: 'component.bank.withdraw',
    },
    history: {
      query: 'history',
      url: '/bank/search?main=history',
      title: '충/환전 내역',
      localeId: 'bank-history',
    },
  },
}

export const MYPAGE_TAB_VALUES = {
  point: {
    id: 0,
    query: 'point',
    url: '/mypage/search?main=point',
    title: '포인트',
    localeId: 'component.point',
  },
  comp: {
    id: 1,
    query: 'comp',
    url: '/mypage/search?main=comp',
    title: '콤프',
    localeId: 'component.comp',
  },
  myinfo: {
    id: 2,
    query: 'myinfo',
    url: '/mypage/search?main=myinfo',
    title: '내정보 변경',
    localeId: 'component.modify-myinfo',
  },
  qnaPersonal: {
    id: 3,
    query: 'qnaPersonal',
    url: '/mypage/search?main=qnaPersonal',
    title: '1:1 문의',
    localeId: 'component.private-qna',
    sub: {
      list: {
        id: 0,
        query: 'list',
        url: '/mypage/search?main=qnaPersonal&sub=list',
        title: '리스트',
        localeId: 'component.list',
      },
      write: {
        id: 1,
        query: 'write',
        url: '/mypage/search?main=qnaPersonal&sub=write',
        title: '쓰기',
        localeId: 'component.write',
      },
    },
  },
  // qnaPartner: {
  //   id: 3,
  //   query: 'qna-partner',
  //   url: '/mypage/search?main=qnaPartner',
  //   title: '제휴 문의',
  //   sub: {
  //     list: {
  //       id: 0,
  //       query: 'list',
  //       url: '/mypage/search?main=qnaPartner&sub=list',
  //       title: '리스트',
  //     },
  //     write: {
  //       id: 1,
  //       query: 'write',
  //       url: '/mypage/search?main=qnaPartner&sub=write',
  //       title: '쓰기',
  //     },
  //   },
  // },
}

const app_bank_codes = process.env.REACT_APP_BANK_CODES

export function GetFilteredBankCode() {
  try {
    const parsed = JSON.parse(app_bank_codes)
    if (_.isArray(parsed) && parsed.length > 0) {
      const filtered = _.filter(BANK_CODES, o => {
        return parsed.indexOf(o.code) >= 0
      })
      return filtered
    }
  } catch (err) {}

  return BANK_CODES
}
