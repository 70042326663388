import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'


// 충전 안내 폼
export const DepositCoinStyle = styled(Box)(({ theme }) => ({
    border: theme.borders.default,
    backgroundColor: theme.boards.titleBg,
    '& .MuiTypography-root': {
        borderBottom: `2px solid ${theme.tabs.tabActive}`,
        backgroundColor: '#16202F',
        padding: '13px 16px',
        color: '#747D8A',
        fontSize: '1.2rem',
    },
    '& .MuiFormControl-root': {
        width: '100%', 
        padding: '20px',
    },
    '& .MuiBox-root': {
        padding: '20px',
        color: '#D1D9E3',
    },
    '& .MuiBox-root.danger': {
        color: '#FE3D3D',// 경고문구 시 빨간색
    },
    '& .MuiInputBase-root': {
        color: '#D1D9E3',
    },
}))


export const InfoBoxBandStyle = styled(Box)(({ theme }) => ({
    border: theme.borders.default,
    backgroundColor: theme.boards.titleBg,
    '& .title': {
        backgroundColor: '#253144',
        padding: '13px 16px',
        color: '#D1D9E3',
        textAlign: 'center',
        fontSize: '1rem',
    },
    '& .inner_text': {
        width: '100%', 
        padding: '30px',
        backgroundColor: '#16202F',
        color: '#D1D9E3',
    },
    '& .inner_text > p': {
        marginBottom: '8px',
    },
    '& .inner_text > p.center': {
        textAlign: 'center',
    },
    '& .inner_text span.danger': {
        color: '#FF7E7E',// 경고문구 시 빨간색
    },
}))
