// global store
import { observable, action, runInAction, makeObservable } from 'mobx'

// text

import { StoreExt } from '@utils/reactExt'
import { globalStore } from '@store/index'

// api request protocol interface
import {
  IPartnerMemberCommission,
  IPartnerMemberList,
  IPartnerMemberInfo,
  ISetPartnerMemberCommission,
} from '~/v2/protocol/partner/member'
import { IPartnerBalanceAccount, IPartnerBankTransaction } from '~/v2/protocol/partner/bank'
import { IPartnerTransactionHistory } from '~/v2/protocol/partner/trading'

// api response protocol interface
import { Schema$UserInfo, Schema$UserMoney } from '@interface/common'
import {
  Schema$PartnerMemberCommission,
  Schema$PartnerMemberList,
  Schema$PartnerBankTransaction,
  Schema$PartnerBalanceAccount,
  Schema$PartnerMemberInfo,
  Schema$PartnerTransactionHistory,
} from '@interface/partner'

import { Schema$UserItem } from '@protocol/user'
import { IntlShape } from 'react-intl'

export type UserInfo = Schema$UserInfo
export type UserMoney = Schema$UserMoney
export type UserItem = Schema$UserItem
export type UserItems = UserItem[]

export interface PartnerMemberCommissionData {
  commission_configs: Schema$PartnerMemberCommission[]
}

export interface PartnerMemberListData {
  offset: number
  limit: number
  total: number
  member_list: Schema$PartnerMemberList[]
}

export interface PartnerMemberInfoData {
  error?: string
  detail?: string
  member_info: Schema$PartnerMemberInfo
}

export interface PartnerBankTransactionData {
  offset: number
  limit: number
  total: number
  list: Schema$PartnerBankTransaction[]
  error?: string
  detail?: string
}

export interface PartnerBalanceAccountData {
  offset: number
  limit: number
  total: number
  list: Schema$PartnerBalanceAccount[]
  error?: string
  detail?: string
}

export interface PartnerTransactionHistoryData {
  offset: number
  limit: number
  total: number
  list: Schema$PartnerTransactionHistory[]
  error?: string
  detail?: string
}

export class PartnerStore extends StoreExt {
  @observable orderUuid: string = null

  @observable userInfo: UserInfo = { nickname: '' }

  constructor() {
    super()
    makeObservable(this)
  }

  // 파트너 회원 변경
  @action
  setOrderUuid = uuid => {
    if (this.orderUuid !== uuid) {
      this.orderUuid = uuid
    }
  }

  // 파트너 회원의 롤링P 정보를 가져온다
  @action
  getPartnerMemberCommission = async (
    params: IPartnerMemberCommission.Params,
    intl: IntlShape,
  ): Promise<PartnerMemberCommissionData> => {
    try {
      const { commission_configs } = await this.api.partner.getMemberCommission(params)
      const data: PartnerMemberCommissionData = {
        commission_configs,
      }
      return data
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return null
  }

  // 파트너 회원 리스트를 가져온다
  @action
  getPartnerMemberList = async (
    params: IPartnerMemberList.Params,
  ): Promise<PartnerMemberListData> => {
    const data: PartnerMemberListData = {
      offset: 10,
      limit: 10,
      total: 50,
      member_list: [
        {
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          user_id: 'test01',
          nickname: 'test01',
          merchant_class: 5,
          join_at: '2021-12-22 08:25:11',
          login_at: '2021-12-23 08:25:11',
          point: 5230000,
          balance: 10000,
          commission_config_state: 'NONE',
        },
        {
          uuid: 'b4e3ad05-236c-4ed9-99c9-05fb72027e34',
          user_id: 'test02',
          nickname: 'test02',
          merchant_class: 5,
          join_at: '2021-12-21 08:25:11',
          login_at: '2021-12-24 08:25:11',
          point: 5230000,
          balance: 5230000,
          commission_config_state: 'NORMAL',
        },
        {
          uuid: 'b4e3ad05-236c-4ed9-99c9-05fb72027e34',
          user_id: 'test02',
          nickname: 'test02',
          merchant_class: 5,
          join_at: '2021-12-21 08:25:11',
          login_at: '2021-12-24 08:25:11',
          point: 5230000,
          balance: 5230000,
          commission_config_state: 'NORMAL',
        },
        {
          uuid: 'b4e3ad05-236c-4ed9-99c9-05fb72027e34',
          user_id: 'test02',
          nickname: 'test02',
          merchant_class: 5,
          join_at: '2021-12-21 08:25:11',
          login_at: '2021-12-24 08:25:11',
          point: 5230000,
          balance: 5230000,
          commission_config_state: 'NORMAL',
        },
        {
          uuid: 'b4e3ad05-236c-4ed9-99c9-05fb72027e34',
          user_id: 'test02',
          nickname: 'test02',
          merchant_class: 5,
          join_at: '2021-12-21 08:25:11',
          login_at: '2021-12-24 08:25:11',
          point: 5230000,
          balance: 5230000,
          commission_config_state: 'NORMAL',
        },
        {
          uuid: 'b4e3ad05-236c-4ed9-99c9-05fb72027e34',
          user_id: 'test02',
          nickname: 'test02',
          merchant_class: 5,
          join_at: '2021-12-21 08:25:11',
          login_at: '2021-12-24 08:25:11',
          point: 5230000,
          balance: 5230000,
          commission_config_state: 'NORMAL',
        },
        {
          uuid: 'b4e3ad05-236c-4ed9-99c9-05fb72027e34',
          user_id: 'test02',
          nickname: 'test02',
          merchant_class: 5,
          join_at: '2021-12-21 08:25:11',
          login_at: '2021-12-24 08:25:11',
          point: 5230000,
          balance: 5230000,
          commission_config_state: 'NORMAL',
        },
      ],
    }
    return data
  }

  // 파트너의 회원정보를 가져온다
  @action
  getPartnerMemberInfo = async (
    params: IPartnerMemberInfo.Params,
    intl: IntlShape,
  ): Promise<PartnerMemberInfoData> => {
    try {
      const { member_info } = await this.api.partner.getMemberInfo(params)
      const data: PartnerMemberInfoData = {
        member_info,
      }
      return data
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return null
  }

  // 파트너 회원의 입출금내역 가져오기
  @action
  getPartnerBankTransaction = async (
    params: IPartnerBankTransaction.Params,
  ): Promise<PartnerBankTransactionData> => {
    const data: PartnerBankTransactionData = {
      offset: 10,
      limit: 10,
      total: 23,
      list: [
        {
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          user_id: 'test05',
          nickname: 'test05',
          merchant_class: 5,
          amount: 1000000,
          transfer_kind: 'IN',
          transfer_state: 'REQUEST',
          request_at: '2021-12-31 08:25:11',
          result_at: '2021-12-31 08:25:11',
          request_at_balance: 100000,
          result_at_balance: 50000,
        },
        {
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          user_id: 'test01',
          nickname: 'test01',
          merchant_class: 5,
          amount: 5000,
          transfer_kind: 'IN',
          transfer_state: 'PROCESS',
          request_at: '2021-12-22 08:25:13',
          result_at: '2021-12-23 08:25:11',
          request_at_balance: 100000,
          result_at_balance: 50000,
        },
      ],
    }
    return data
  }

  // 정산 리스트
  @action
  getPartnerBalanceAccount = async (
    params: IPartnerBalanceAccount.Params,
  ): Promise<PartnerBalanceAccountData> => {
    const data: PartnerBalanceAccountData = {
      offset: 0,
      limit: 10,
      total: 60,
      list: [
        {
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          base_date: '2021-12-30',
          LCA: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          PB: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          SL: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          SP: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          ISP: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },

          merchant_class: 5,
          user_id: 'test001',
          nickname: '테스트001',
        },
        {
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          base_date: '2021-12-30',
          LCA: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          PB: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          SL: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          SP: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },
          ISP: {
            bet_amount: 500000,
            result_amount: 1000000,
            losing_amount: 300000,
            commission_amount: 200000,
            share_amount: 5000,
          },

          merchant_class: 5,
          user_id: 'test001',
          nickname: '테스트001',
        },
      ],
    }
    return data
  }

  // 베팅리스트
  @action
  getPartnerTransactionHistory = async (
    params: IPartnerTransactionHistory.Params,
  ): Promise<PartnerTransactionHistoryData> => {
    var data: PartnerTransactionHistoryData = {
      offset: 10,
      limit: 10,
      total: 100,
      list: [
        {
          bet_id: 1322,
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          trading_group: 'LCA',
          trading_kind: 'EV',
          base_date: '2022-01-07',
          bet_at: '2022-01-07 11:33:40',
          bet_state: 'WIN',
          bet_amount: 15000,
          result_at: '2022-01-07 14:33:40',
          result_amount: 30000,
          commission_amount: 3000,
          losing_amount: 4500,
          share_amount: 4000,
          merchant_class: 5,
          user_id: 'test01',
          nickname: '테스트001',
        },
        {
          bet_id: 1322,
          uuid: '062dceb4-5541-4da8-ba13-37046e498202',
          trading_group: 'PB',
          trading_kind: 'PB1',
          base_date: '2022-01-07',
          bet_at: '2022-01-07 11:33:40',
          bet_state: 'LOSE',
          bet_amount: 15000,
          result_at: '2022-01-07 14:33:40',
          result_amount: 30000,
          commission_amount: 3000,
          losing_amount: 4500,
          share_amount: 4000,
          merchant_class: 5,
          user_id: 'test01',
          nickname: '테스트001',
        },
      ],
    }
    return data
  }

  @action
  setPartnerMemberCommission = async (
    params: ISetPartnerMemberCommission.Params,
    intl: IntlShape,
  ): Promise<PartnerMemberCommissionData> => {
    try {
      const { commission_configs } = await this.api.partner.setMemberCommission(params)
      const data: PartnerMemberCommissionData = {
        commission_configs,
      }
      return data
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return null
  }
}

export default new PartnerStore()
