import { observable, action, makeObservable } from 'mobx'
import { StoreExt } from '@utils/reactExt'
import { globalStore } from '@store/index'
import {
  Schema$UserInfo,
  Schema$UserMoney,
  Schema$UserPoint,
  Schema$BankHistoryItem,
  Schema$Notification,
  Schema$UserComp,
} from '@interface/common'
import {
  IChangePassword,
  IChangePassword2,
  IChangeNick,
  Schema$UserItem,
  // Schema$doCheckDuplicateLogin,
} from '@protocol/user'
import { IBankDeposit, IBankWithdraw, IBankTransferPoint } from '@protocol/bank'

import { ModalKeys } from '@store/globalStore'
import { makeBrowserData } from '~/game/util'
import { IntlShape } from 'react-intl'

export type UserInfo = Schema$UserInfo
export type UserMoney = Schema$UserMoney
export type UserPoint = Schema$UserPoint
export type UserComp = Schema$UserComp
export type UserItem = Schema$UserItem
export type UserItems = UserItem[]
export type UserChangeNick = IChangeNick.Schema
export type UserChangePassword = IChangePassword.Schema
export type BankHistoryItem = Schema$BankHistoryItem
export interface BankHistoryTrackData {
  offset: number
  limit: number
  total: number
  items: Schema$BankHistoryItem[]
}

export interface BettingCoupon {
  item: UserItem
  volume: number
}

export const BankServiceVolumes = [10000, 50000, 100000, 500000, 1000000, 5000000, 10000000]

export class UserStore extends StoreExt {
  @observable getUsersloading = false

  @observable userInfo: UserInfo = { nickname: '' }

  @observable userMoney: UserMoney = { balance: 0 }

  @observable userPoint: UserPoint = { balance: 0 }

  @observable userComp: UserComp = { balance: 0 }

  @observable game_money = null

  @observable notification: Schema$Notification = null

  @observable track

  position: { page: string; popup: string } = { page: '', popup: '' }
  fetchedPosition = ''

  constructor() {
    super()

    makeObservable(this)
  }

  fetchPosition = async () => {
    let position = ''
    if (this.position.popup) {
      position = this.position.popup
    } else if (this.position.page) {
      position = this.position.page
    }

    if (this.fetchedPosition === position) {
      return
    }

    // console.log(`fetchPosition: ${position}`)

    this.fetchedPosition = position

    try {
      await this.api.user.setPosition({ position })
    } catch (err) {
      console.log(err.message)
    }
  }

  setPositionPage = (v: string) => {
    this.position.page = v

    this.fetchPosition()
  }

  setPositionPopup = (v: string) => {
    this.position.popup = v

    this.fetchPosition()
  }

  @action
  cleanup = () => {
    this.getUsersloading = false
    this.userInfo = { nickname: '' }
    this.userMoney = { balance: 0 }
    this.userPoint = { balance: 0 }
    this.userComp = { balance: 0 }
    this.notification = null
    this.fetchedPosition = ''
  }

  @action
  loadAsset = async (intl: IntlShape) => {
    try {
      const { money, point, comp } = await this.api.user.getAsset({})
      this.setUserMoney(money)
      this.setUserPoint(point)
      this.setUserComp(comp)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  @action
  loadGameMoney = async (intl: IntlShape) => {
    try {
      const { game_money } = await this.api.game.getGameMoney({})
      this.setGameMoney(game_money)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  @action
  withdrawGameMoney = async (intl: IntlShape) => {
    try {
      await this.api.game.withdrawOnDemand({ onDemand: true, browserData: makeBrowserData() })
      globalStore.pushDialogLocaleOk({ text: 'msg.money_transfer_completed' })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  @action
  setUser = (userInfo: UserInfo) => {
    this.userInfo = userInfo
  }

  @action
  setUserMoney = (userMoney: UserMoney) => {
    this.userMoney = userMoney
  }

  @action
  setUserPoint = (userPoint: UserPoint) => {
    this.userPoint = userPoint
  }

  @action
  setUserComp = (userComp: UserComp) => {
    this.userComp = userComp
  }

  @action
  setGameMoney = (game_money: number) => {
    this.game_money = game_money
  }

  @action
  setNotification = (data: Schema$Notification) => {
    this.notification = data
    if (data.read_required > 0) {
      globalStore.showModal(ModalKeys.message, 0)
    }
  }

  @action
  transferPoint = async (volume, intl: IntlShape): Promise<boolean> => {
    try {
      const params: IBankTransferPoint.Params = {
        volume: volume,
      }
      const { userMoney, userPoint } = await this.api.user.transferPoint(params)
      this.setUserMoney(userMoney)
      this.setUserPoint(userPoint)
      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  @action
  transferComp = async (volume, intl: IntlShape): Promise<boolean> => {
    try {
      const params: IBankTransferPoint.Params = {
        volume: volume,
      }
      const { userMoney, userComp } = await this.api.user.transferComp(params)
      this.setUserMoney(userMoney)
      this.setUserComp(userComp)
      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  changePassword = async (params: IChangePassword.Params, intl: IntlShape): Promise<boolean> => {
    try {
      await this.api.user.changePassword(params)
      globalStore.hidePopupAll()
      globalStore.pushDialogLocaleOk({
        text: 'msg.change-password-succeed',
      })
      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  changePassword2 = async (params: IChangePassword2.Params, intl: IntlShape): Promise<boolean> => {
    try {
      await this.api.user.changePassword2(params)
      globalStore.hidePopupAll()
      globalStore.pushDialogLocaleOk({
        text: 'msg.change-password-succeed',
      })
      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }
}

export default new UserStore()
