import * as _ from 'lodash'
import { Schema$MatchupMarket, Schema$MatchupOdds } from '@interface/matchup'
import { Schema$TradingOddsOption } from '@interface/matchup'
import { toJS } from 'mobx'

export const ODDS_COLOR = {
  blue_a: '#19468b',
  blue_d: '#346abd',

  red_a: '#80160b',
  red_d: '#c13526',

  green_a: '#0A6423',
  green_d: '#0e9f36',

  brown_a: '#855000',
  brown_d: '#d98e1c',
}

export interface MinigameMatchup {
  id: number // trading id
  round: number
  uniqueId: number
  markets: { [key: string]: Schema$MatchupMarket }
}

export interface MarketMatrixData {
  marketId: string
  id: string // meta odds id
  acolor: string // active color
  dcolor: string // disabled color
  odds?: Schema$MatchupOdds
}

export type MarketMatrixDataMap = { [key: string]: MarketMatrixData[][] }
export type MarketMatrixMap = { [key: string]: MarketMatrixDataMap }

export const MakeTestMatchup = (
  trading_group: string,
  id: number,
  round: number,
  uniqueId: number,
  oddsOptions: Schema$TradingOddsOption[],
  marketMeta: any, // PowerballMarketMeta
  oddsMeta: any, // PowerballOddsMeta
) => {
  const matchup: MinigameMatchup = {
    id,
    round,
    uniqueId,
    markets: {},
  }

  for (let i = 0; i < marketMeta.length; i++) {
    // find odds and
    const market: Schema$MatchupMarket = _.pick(marketMeta[i], ['id', 'category', 'locale'])
    market.odds = []

    const { metaOdds } = marketMeta[i]
    for (const oddsId of metaOdds) {
      const metaOdds = _.find(oddsMeta, function(el) {
        return el.id === oddsId
      })
      if (metaOdds) {
        const odds: Schema$MatchupOdds = _.pick(metaOdds, ['id', 'locale'])
        const oddsOverrideOption = _.find(oddsOptions, {
          trading_group: trading_group,
          market: market.id,
          odds: odds.id,
        })
        if (oddsOverrideOption) {
          odds.rate = oddsOverrideOption.odds_rate
          odds.disabled = oddsOverrideOption.disabled
          market.odds.push(odds)
        }
      }
    }

    matchup.markets[market.id] = market
  }
  return matchup
}

export const MakeTestMatchupMatrix = (marketMatrix: MarketMatrixMap, matchup: MinigameMatchup) => {
  let matrix = _.cloneDeep(marketMatrix)
  if (matchup) {
    _.forEach(matrix, function(value, key) {
      _.forEach(value, function(block, blockKey) {
        for (const row of block) {
          for (const col of row) {
            if (col.marketId in matchup.markets) {
              const odds = _.find(matchup.markets[col.marketId].odds, { id: col.id })
              if (odds) {
                col.odds = _.cloneDeep(odds)
              }
            }
          }
        }
      })
    })
  }

  return matrix
}
