/** @jsxImportSource @emotion/react */
import { jsx, css, ThemeProvider, Theme } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { Box, Grid, Tabs, Tab, Typography, Divider, Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import useRootStore from '@store/useRootStore'
//import WhiteTheme from '@themes/white.theme'

// css
import { TitleDivider, BasicTabs, DarkenBg } from '@styles/base.style'
import { BasicButton } from '@styles/button.style'

import BankHistory from './BankHistory'
import RequestWithdraw from './RequestWithdraw'
import RequestWithdrawCodepay from './RequestWithdrawCodepay'
import RequestDeposit from './RequestDeposit'
import RequestDepositDC from './RequestDepositDC'
import RequestDepositOnepay from './RequestDepositOnepay'
import RequestDepositCoinBitdex from './RequestDepositCoinBitdex'
import RequestDepositCoinJun200 from './RequestDepositCoinJun200'
import RequestDepositWonp from './RequestDepositWonp'
import RequestDeposit5Koin from './RequestDeposit5Koin'
import RequestDepositCoinWiki from './RequestDepositCoinWiki'
import RequestDepositVENICN from './RequestDepositVENICN'
import RequestDepositCoinOXO from './RequestDepositCoinOXO'
import RequestDepositOnePayCoin from './RequestDepositOnePayCoin'
import RequestDepositCommonCoin from './RequestDepositCommonCoin'
import RequestDepositCommonVirtual from './RequestDepositCommonVirtual'
import RequestDepositAuthVirtual from './RequestDepositAuthVirtual'
import RequestDepositMCI from './RequestDepositMCI'
import RequestDepositCodepay from './RequestDepositCodepay'

import MobileBankHistory from './m/BankHistory'
import MobileRequestWithdraw from './m/RequestWithdraw'
import MobileRequestWithdrawCodepay from './m/RequestWithdrawCodepay'
import MobileRequestDeposit from './m/RequestDeposit'
import MobileRequestDepositDC from './m/RequestDepositDC'
import MobileRequestDepositOnepay from './m/RequestDepositOnepay'
import MobileRequestDepositCoinBitdex from './m/RequestDepositCoinBitdex'
import MobileRequestDepositCoinJun200 from './m/RequestDepositCoinJun200'
import MobileRequestDepositWonp from './m/RequestDepositWonp'
import MobileRequestDeposit5Koin from './m/RequestDeposit5Koin'
import MobileRequestDepositCoinWiki from './m/RequestDepositCoinWiki'
import MobileRequestDepositVENICN from './m/RequestDepositVENICN'
import MobileRequestDepositCoinOXO from './m/RequestDepositCoinOXO'
import MobileRequestDepositOnePayCoin from './m/RequestDepositOnePayCoin'
import MobileRequestDepositCommonCoin from './m/RequestDepositCommonCoin'
import MobileRequestDepositCommonVirtaul from './m/RequestDepositCommonVirtual'
import MobileRequestDepositAuthVirtaul from './m/RequestDepositAuthVirtual'
import MobileRequestDepositMCI from './m/RequestDepositMCI'
import MobileRequestDepositCodepay from './m/RequestDepositCodepay'

import { BANK_MENU, MYPAGE_TAB_VALUES } from '~/constants/attrs'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { ModalKeys } from '~/store/globalStore'
import { APP_CHARGES } from '~/utils/utils'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    key: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface Props {
  main?: string
  sub?: string
  type?: string
}

function Bank(props: Props) {
  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { authStore, globalStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  if (!signedin && initialized) {
    history.push('/')
    globalStore.showModal('login', 0)
    return <></>
  }

  const handleChange = (event, idx) => {
    history.push(`/bank/search?main=${APP_CHARGES[idx]}`)
    globalStore.setNavChange(`/bank/search?main=${APP_CHARGES[idx]}`)
  }

  const onClickManual = (type: string) => {
    if (type === 'normal') {
      history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=deposit&kind=normal`)
    } else if (type === 'virtual') {
      history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=deposit&kind=virtual`)
    } else if (type === 'onepay') {
      globalStore.showModal(ModalKeys.menual_onepay, 0)
    } else if (type === 'onepay_coin') {
      globalStore.showModal(ModalKeys.menual_onepay_coin, 0)
    } else if (type === 'coin') {
      globalStore.showModal(ModalKeys.menual_coin, 0)
    } else if (type === 'wonp') {
      globalStore.showModal(ModalKeys.menual_wonp, 0)
    }
  }

  const { location } = props as any
  const query = queryString.parse(location.search)
  const { main } = query
  const tabValue = APP_CHARGES.indexOf(main || 'history')

  if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
    switch (main) {
      case 'deposit':
        userStore.setPositionPage('BANK-DEPOSIT-NORMAL')
        break
      case 'depositDC':
        userStore.setPositionPage('BANK-DEPOSIT-DC')
        break
      case 'depositOnepay':
        userStore.setPositionPage('BANK-DEPOSIT-ONEPAY')
        break
      case 'depositCoinOXO':
        userStore.setPositionPage('BANK-DEPOSIT-COIN-OXO')
        break
      case 'depositCoin':
        userStore.setPositionPage('BANK-DEPOSIT-COIN')
        break
      case 'depositCoinJun200':
        userStore.setPositionPage('BANK-DEPOSIT-COIN-JUN200')
        break
      case 'deposit5Koin':
        userStore.setPositionPage('BANK-DEPOSIT-5KOIN')
        break
      case 'depositWonp':
        userStore.setPositionPage('BANK-DEPOSIT-WONP')
        break
      case 'depositCoinWiki':
        userStore.setPositionPage('BANK-DEPOSIT-COINWIKI')
        break
      case 'depositOnePayCoin':
        userStore.setPositionPage('BANK-DEPOSIT-ONEPAYCOIN')
        break
      case 'depositCommonCoin':
        userStore.setPositionPage('BANK-DEPOSIT-COMMON-COIN')
        break
      case 'depositCommonVirtual':
        userStore.setPositionPage('BANK-DEPOSIT-COMMON-VIRTUAL')
        break
      case 'depositAuthVirtual':
        userStore.setPositionPage('BANK-DEPOSIT-AUTH-VIRTUAL')
        break
      case 'depositMCI':
        userStore.setPositionPage('BANK-DEPOSIT-MCI')
        break
      case 'depositCodepay':
        userStore.setPositionPage('BANK-DEPOSIT-CODEPAY')
        break
      case 'withdrawCodepay':
        userStore.setPositionPage('BANK-WITHDRAW-CODEPAY')
        break
      case 'withdraw':
        userStore.setPositionPage('BANK-WITHDRAW')
        break
      case 'history':
        userStore.setPositionPage('BANK-RECORD')
        break
    }
  }

  let tabSize = 0
  for (let i = 0; i < APP_CHARGES.length; i++) {
    const found = _.get(BANK_MENU.sub, APP_CHARGES[i])
    if (found) {
      tabSize += 1
    }
  }

  let tabWidth = Math.max(25, Math.floor(100 / tabSize))

  const tabContents = []
  const panelContents = []
  for (let i = 0; i < APP_CHARGES.length; i++) {
    const found = _.get(BANK_MENU.sub, APP_CHARGES[i])
    if (found) {
      tabContents.push(
        <Tab
          className="basic_tab"
          label={intl.formatMessage({ id: found.localeId })}
          {...a11yProps(i)}
          sx={{ width: `${tabWidth}%` }}
        />,
      )

      switch (APP_CHARGES[i]) {
        case 'deposit':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDeposit></RequestDeposit>
              ) : (
                <MobileRequestDeposit></MobileRequestDeposit>
              )}
            </TabPanel>,
          )
          break
        case 'depositDC':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositDC></RequestDepositDC>
              ) : (
                <MobileRequestDepositDC></MobileRequestDepositDC>
              )}
            </TabPanel>,
          )
          break
        case 'depositOnepay':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositOnepay></RequestDepositOnepay>
              ) : (
                <MobileRequestDepositOnepay></MobileRequestDepositOnepay>
              )}
            </TabPanel>,
          )
          break
        case 'depositCoinOXO':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCoinOXO></RequestDepositCoinOXO>
              ) : (
                <MobileRequestDepositCoinOXO></MobileRequestDepositCoinOXO>
              )}
            </TabPanel>,
          )
          break
        case 'depositCoin':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCoinBitdex></RequestDepositCoinBitdex>
              ) : (
                <MobileRequestDepositCoinBitdex></MobileRequestDepositCoinBitdex>
              )}
            </TabPanel>,
          )
          break
        case 'depositCoinJun200':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCoinJun200></RequestDepositCoinJun200>
              ) : (
                <MobileRequestDepositCoinJun200></MobileRequestDepositCoinJun200>
              )}
            </TabPanel>,
          )
          break
        case 'deposit5Koin':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDeposit5Koin></RequestDeposit5Koin>
              ) : (
                <MobileRequestDeposit5Koin></MobileRequestDeposit5Koin>
              )}
            </TabPanel>,
          )
          break
        case 'depositWonp':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositWonp></RequestDepositWonp>
              ) : (
                <MobileRequestDepositWonp></MobileRequestDepositWonp>
              )}
            </TabPanel>,
          )
          break
        case 'depositCoinWiki':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCoinWiki></RequestDepositCoinWiki>
              ) : (
                <MobileRequestDepositCoinWiki></MobileRequestDepositCoinWiki>
              )}
            </TabPanel>,
          )
          break
        case 'depositVENICN':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositVENICN></RequestDepositVENICN>
              ) : (
                <MobileRequestDepositVENICN></MobileRequestDepositVENICN>
              )}
            </TabPanel>,
          )
          break
        case 'depositOnePayCoin':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositOnePayCoin></RequestDepositOnePayCoin>
              ) : (
                <MobileRequestDepositOnePayCoin></MobileRequestDepositOnePayCoin>
              )}
            </TabPanel>,
          )
          break
        case 'depositCommonCoin':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCommonCoin></RequestDepositCommonCoin>
              ) : (
                <MobileRequestDepositCommonCoin></MobileRequestDepositCommonCoin>
              )}
            </TabPanel>,
          )
          break
        case 'depositCommonVirtual':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCommonVirtual></RequestDepositCommonVirtual>
              ) : (
                <MobileRequestDepositCommonVirtaul></MobileRequestDepositCommonVirtaul>
              )}
            </TabPanel>,
          )
          break
        case 'depositAuthVirtual':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositAuthVirtual></RequestDepositAuthVirtual>
              ) : (
                <MobileRequestDepositAuthVirtaul></MobileRequestDepositAuthVirtaul>
              )}
            </TabPanel>,
          )
          break
        case 'depositMCI':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositMCI></RequestDepositMCI>
              ) : (
                <MobileRequestDepositMCI></MobileRequestDepositMCI>
              )}
            </TabPanel>,
          )
          break
        case 'depositCodepay':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestDepositCodepay></RequestDepositCodepay>
              ) : (
                <MobileRequestDepositCodepay></MobileRequestDepositCodepay>
              )}
            </TabPanel>,
          )
          break
        case 'withdrawCodepay':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestWithdrawCodepay></RequestWithdrawCodepay>
              ) : (
                <MobileRequestWithdrawCodepay></MobileRequestWithdrawCodepay>
              )}
            </TabPanel>,
          )
          break
        case 'withdraw':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? (
                <RequestWithdraw></RequestWithdraw>
              ) : (
                <MobileRequestWithdraw></MobileRequestWithdraw>
              )}
            </TabPanel>,
          )
          break
        case 'history':
          panelContents.push(
            <TabPanel value={tabValue} index={i} key={i}>
              {desktop ? <BankHistory></BankHistory> : <MobileBankHistory></MobileBankHistory>}
            </TabPanel>,
          )
          break
      }
    }
  }

  const canDepositNormal = APP_CHARGES.indexOf('deposit') >= 0
  const canDepositDC = APP_CHARGES.indexOf('depositDC') >= 0
  const canDepositOnepay = APP_CHARGES.indexOf('depositOnepay') >= 0
  const canDepositCoin = APP_CHARGES.indexOf('depositCoin') >= 0
  const canDepositWonp = APP_CHARGES.indexOf('depositWonp') >= 0
  const canDepositMCI = APP_CHARGES.indexOf('depositMCI') >= 0

  let contents = null
  if (desktop) {
    contents = (
      <DarkenBg>
        <Box className="content_wrap">
          <Container fixed sx={{ pb: 6, minWidth: '1248px' }}>
            <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Grid item>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ py: 3, fontWeight: 'bold', color: '#D1D9E3' }}
                >
                  {intl.formatMessage({ id: 'component.bank-transaction' })}
                </Typography>
              </Grid>
              <Grid item sx={{ display: 'flex' }}>
                {canDepositMCI ? (
                  <BasicButton
                    className="btn_main"
                    onClick={() => onClickManual('virtual')}
                    sx={{ ml: 1 }}
                  >
                    {intl.formatMessage({ id: 'component.private-qna.deposit.virtual' })}
                  </BasicButton>
                ) : null}
                <BasicButton
                  className="btn_main"
                  onClick={() => onClickManual('normal')}
                  sx={{ ml: 1 }}
                >
                  {intl.formatMessage({
                    id: canDepositMCI
                      ? 'component.private-qna.deposit.normal'
                      : 'component.private-qna.deposit',
                  })}
                </BasicButton>
                {canDepositOnepay ? (
                  <BasicButton
                    className="btn_default"
                    onClick={() => onClickManual('onepay')}
                    sx={{ ml: 1 }}
                  >
                    {intl.formatMessage({ id: 'onepay-manual' })}
                  </BasicButton>
                ) : null}
                {canDepositCoin ? (
                  <BasicButton
                    className="btn_default"
                    onClick={() => onClickManual('coin')}
                    sx={{ ml: 1 }}
                  >
                    {intl.formatMessage({ id: 'coin-manual' })}
                  </BasicButton>
                ) : null}
                {canDepositWonp ? (
                  <BasicButton
                    className="btn_default"
                    onClick={() => onClickManual('wonp')}
                    sx={{ ml: 1 }}
                  >
                    {intl.formatMessage({ id: 'wonp-manual' })}
                  </BasicButton>
                ) : null}
              </Grid>
            </Grid>
            <Divider css={TitleDivider}></Divider>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              css={BasicTabs}
              sx={{ mt: 1 }}
              variant="fullWidth"
            >
              {tabContents}
            </Tabs>
            {panelContents}
            <Divider />
          </Container>
        </Box>
      </DarkenBg>
    )
  } else {
    contents = (
      <DarkenBg sx={{ minHeight: 'calc(100vh - 202px)' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          css={BasicTabs}
          variant="scrollable"
          scrollButtons={false}
        >
          {tabContents}
        </Tabs>
        {panelContents}
      </DarkenBg>
    )
  }

  return <>{contents}</>
}

export default observer(Bank)
