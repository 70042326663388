import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

export const LogoStyle = styled.div`
  text-align: center;

  & > .logo {
    color: #b737fe;
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;
  }
  & > .text {
    color: #fff;
    font-size: 1.1rem;
  }
`
