/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Grid, Box } from '@mui/material'
import * as _ from 'lodash'

import useRootStore from '@store/useRootStore'

import { MakeTestMatchupMatrix, MarketMatrixData } from '~/game/common'
import { POWERBALL_MARKET_GROUP, MarketMatrix } from '~/game/powerball'
import { BettingCartItem } from '~/store/minigameStore'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'

// css
import { BasicButton } from '~/styles/button.style'
import { SelectBox, ScrollableStyle } from './index.style'
import { WooriKenoPowerballMarketMatrix, WOORI_KENO_POWERBALL_MARKET_GROUP } from '~/game/woori-keno-powerball'

// const loadableText = 'N/A'
const loadableText = '. . .'

export const makeMarketBlock = (
  tradingKind: string,
  marketGroup: number,
  srcMatrix: MarketMatrixData[][],
  selectedId: string,
  onClick: (tradingKind: string, marketGroup: number, marketId: string, oddsId: string) => void,
  colType: string,
  exclude: string[],
) => {
  const contents = []
  for (const row of srcMatrix) {
    for (const col of row) {
      const innerItems = []
      const disabled = selectedId === '0' || !col.odds || exclude.indexOf(col.id) >= 0
      const active = selectedId === col.id ? 'selected' : ''
      let bgColor = col.dcolor
      if (selectedId === col.id) {
        bgColor = col.acolor
      }
      let oddsName = <div></div>
      if (col.odds) {
        if (col.odds.disabled) {
          continue
        }
        oddsName = <div>{col.odds ? col.odds.locale.name : loadableText}</div>
      }

      innerItems.push(
        <BasicButton
          key={col.id}
          className={'btn_bet ' + active}
          disabled={disabled}
          onClick={() => onClick(tradingKind, marketGroup, col.marketId, col.id)}
          style={{ backgroundColor: bgColor, minWidth: 'auto' }}
        >
          {oddsName}
          <div>{col.odds ? col.odds.rate : loadableText}</div>
        </BasicButton>,
      )
      if (colType === 'type1') {
        contents.push(
          <Grid item xs={6} key={col.id}>
            {innerItems}
          </Grid>,
        )
      } else if (colType === 'type2') {
        contents.push(
          <Grid item xs={6} key={col.id}>
            {innerItems}
          </Grid>,
        )
      } else if (colType === 'type3') {
        contents.push(
          <Grid item xs={6} key={col.id}>
            {innerItems}
          </Grid>,
        )
      }
    }
  }
  return contents
}

const getSelectedId = (items: BettingCartItem[], marketGroup: number) => {
  const powerItem = _.find(items, function(o) {
    return o.marketGroup === marketGroup
  })
  return powerItem ? powerItem.oddsId : '1'
}

interface Props {
  kind: string
  width?: number
}

const MarketPanelWooriKenoPowerball: React.FC<Props> = ({ kind, width }) => {
  // '0': disabled, '1': interfactable, 'xxxx': selected
  const { miniGameStore, globalStore } = useRootStore()
  const [mounted, setMounted] = useState<boolean>(false)
  const { matchup, cartItems } = miniGameStore
  const { option } = globalStore
  const { usePowerballCombination } = option

  useOnMount(() => {
    miniGameStore.setGameTab(kind)
    setTimeout(() => {
      setMounted(true)
    }, 150)
  })

  let ppowerGroup = WOORI_KENO_POWERBALL_MARKET_GROUP.ALL
  let ssuperGroup = WOORI_KENO_POWERBALL_MARKET_GROUP.ALL
  let comboGroup = WOORI_KENO_POWERBALL_MARKET_GROUP.ALL

  let powerSelected = '0'
  let superSelected = '0'
  let comboSelected = '0'

  let powerExclude = []
  let superExclude = []

  if (!usePowerballCombination) {
    let allSelected = getSelectedId(cartItems, WOORI_KENO_POWERBALL_MARKET_GROUP.ALL)
    powerSelected = allSelected
    superSelected = allSelected
    comboSelected = allSelected
  } else {
    ppowerGroup = WOORI_KENO_POWERBALL_MARKET_GROUP.PPOWER
    ssuperGroup = WOORI_KENO_POWERBALL_MARKET_GROUP.SSUPER
    comboGroup = WOORI_KENO_POWERBALL_MARKET_GROUP.COMBO

    powerSelected = getSelectedId(cartItems, WOORI_KENO_POWERBALL_MARKET_GROUP.PPOWER)
    superSelected = getSelectedId(cartItems, WOORI_KENO_POWERBALL_MARKET_GROUP.SSUPER)
    comboSelected = getSelectedId(cartItems, WOORI_KENO_POWERBALL_MARKET_GROUP.COMBO)

    let powerBasic = ['PODD', 'PEVEN', 'PUNDER', 'POVER']
    let superBasic = ['SODD', 'SEVEN', 'SUNDER', 'SOVER']

    if (powerSelected.length > 1) {
      comboSelected = '0'

      if (powerBasic.indexOf(powerSelected) >= 0) {
        superExclude = superBasic
      }
    }
    if (superSelected.length > 1) {
      comboSelected = '0'

      if (superBasic.indexOf(superSelected) >= 0) {
        powerExclude = powerBasic
      }
    }
    if (comboSelected.length > 1) {
      powerSelected = '0'
      superSelected = '0'
    }
  }

  const matchupMatrix = MakeTestMatchupMatrix(WooriKenoPowerballMarketMatrix, matchup)

  const powerContents = makeMarketBlock(
    kind,
    ppowerGroup,
    matchupMatrix['PBP']['POWER'],
    powerSelected,
    miniGameStore.toggleCartItem,
    'type1',
    powerExclude,
  )
  const superContents = makeMarketBlock(
    kind,
    ssuperGroup,
    matchupMatrix['PBS']['SUPER'],
    superSelected,
    miniGameStore.toggleCartItem,
    'type3',
    superExclude,
  )
  const comboContents = makeMarketBlock(
    kind,
    comboGroup,
    matchupMatrix['COMBO']['ODDEVEN'],
    comboSelected,
    miniGameStore.toggleCartItem,
    'type1',
    [],
  )

  let url = ''
  let urlResult = ''
  let origin = { w: 873, h: 710 }

  switch (kind) {
    case 'WRKENOPB1':
      url = 'https://funken-2023.wooriball.com/rdplay_info/rdkn_play/rdkn_together_play.php'
      urlResult = 'https://www.wooriball.com/game_randomkeno.php'
      origin = { w: 873, h: 706 }
      break
    case 'WRKENO1':
      url = 'https://funken-2023.wooriball.com/funplay_info/funtogether_knpowerball_play_2023.php'
      urlResult = 'https://www.wooriball.com/game_keno_date.php'
      origin = { w: 873, h: 706 }
      break
  }

  const onClickPowerballGuide = () => {
    globalStore.setPowerballBettingGuideVisible(true)
  }

  const onClickResult = () => {
    window.open(urlResult, '_blank')
  }

  const sclX = (width || window.innerWidth) / origin.w
  const height = origin.h * sclX

  return (
    <Fragment>
      <ScrollableStyle style={{ height: `${height}px` }}>
        {mounted && (
          <iframe
            id="contentFrame"
            className="game"
            scrolling="no"
            src={url}
            style={{
              transform: `scale(${sclX})`,
              transformOrigin: '0 0',
              width: `${origin.w}px`,
              height: `${origin.h}px`,
            }}
          ></iframe>
        )}
      </ScrollableStyle>
      <Card css={SelectBox}>
        <Box className="card_header" sx={{ textAlign: 'end' }}>
          <BasicButton
            className="btn_sub"
            sx={{ display: 'inline-block', padding: '0.4rem 0.55rem', minWidth: 120 }}
            onClick={() => onClickResult()}
          >
            지난 회차 보기
          </BasicButton>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2 }}>
        <Box className="card_header">파워볼 베팅</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {powerContents}
          </Grid>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2 }}>
        <Box className="card_header">슈퍼볼 베팅</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {superContents}
          </Grid>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2, pb: 7 }}>
        <Box className="card_header">파워,슈퍼 홀짝조합 베팅</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {comboContents}
          </Grid>
        </Box>
      </Card>
    </Fragment>
  )
}

export default observer(MarketPanelWooriKenoPowerball)
