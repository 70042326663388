import { css, Theme, ThemeProps } from '@emotion/react'
//import styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import { icon_select_arrow } from '~/assets/images/layout_4'

export const ModalStyleBand = (theme: Theme) => css`
  .MuiDialog-container {
    height: 100%;
  }

  .MuiDialog-paper {
    margin: 80px auto;
    background-color: ${theme.colors.modalBg};
    box-shadow: 0px 0px 15px 0px ${theme.colors.themeActive};
  }
  
  &.login_dialog .MuiDialog-paper {
    min-width: 400px;
    max-width: 400px;
    background-color: ${theme.colors.loginFormBg};
  }

  .MuiAppBar-root {
    position: relative;
    box-shadow: none;

    .MuiButtonBase-root {
      position: absolute;
      top: 16px;
      right: 28px;
      width: 30px;
      height: 30px;
      background: #17191a;
      border-radius: 50%;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
    .MuiButtonBase-root:hover {
      box-shadow: 0 0 15px ${theme.colors.themeActive};
    }
    .MuiButtonBase-root:hover .MuiSvgIcon-root {
      fill: ${theme.colors.themeActive};
    }
  }
`

// form style
export const FormStyleBand = styled('div')`
  .form_table {
    width: 100%;
    border-top: 1px solid #1e2b3d;

    & th,
    & td {
      border-bottom: 1px solid #1e2b3d;
    }
    & th {
      background-color: #16202f;
      padding: 8px 24px;
      background-color: #16202f;
      font-weight: normal;
    }
    & td {
      padding: 8px 16px;
    }
  }

  .form_label {
    margin-top: 16px;
    margin-bottom: 8px;
    color: ${props => props.theme.colors.themeTxt};
    font-size: ${props => props.theme.fonts.small};
  }
  .form_control {
    width: 100%;
    padding: 0.75rem;
    background-color: ${props => props.theme.forms.formBg};
    border: 1px solid ${props => props.theme.forms.formLine};
    border-radius: 0;
    color: ${props => props.theme.colors.themeTxt};

    &:focus-visible {
      outline: 1px solid ${props => props.theme.colors.themeActive};
    }
    &::placeholder {
      color: ${props => props.theme.forms.placeholderColor};
    }

    &.disabled {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      background-color: ${props => props.theme.forms.disabledBg};
    }
    &:disabled {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      backgroundcolor: ${props => props.theme.forms.disabledBg};
    }

    &.readonly {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      background-color: ${props => props.theme.forms.disabledBg};
    }
    &:readonly {
      border: 1px solid ${props => props.theme.forms.disabledBorder};
      backgroundcolor: ${props => props.theme.forms.disabledBg};
    }
  }
  select.form_control {
    background: ${props => props.theme.forms.formBg} url('${icon_select_arrow}') right center no-repeat;
  }
  .required:after {
    color: #ff2e2e;
    content: '*';
    font-size: inherit;
    font-weight: 700;
    padding-left: 0.25rem;
    position: relative;
  }

  .MuiIconButton-root {
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: ${props => props.theme.colors.themeActive};
    opacity: 0.7;
    & > svg {
      color: #fff;
    }
    &:hover {
      background-color: ${props => props.theme.colors.themeActive};
      opacity: 1;
    }
    &:hover > svg {
      color: #fff;
    }
  }
`

export const MobileModalStyleBand = (theme: Theme) => css`
  .MuiDialog-paper {
    margin: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    max-height: none;
    background-color: ${theme.colors.modalBg};
  }
  .MuiAppBar-root {
    position: fixed;
    background-color: #080f1c;
    box-shadow: none;

    .modal_title {
      padding-left: 17px;
      text-align: center;
    }
    .MuiButtonBase-root {
      position: absolute;
      top: 16px;
      right: 28px;
      width: 30px;
      height: 30px;
      background: #17191a;
      border-radius: 50%;
    }
    .MuiSvgIcon-root {
      fill: #d1d9e3;
      width: 1.7rem;
      height: 1.7rem;
    }
    .MuiButtonBase-root:hover {
      box-shadow: 0 0 15px ${theme.colors.themeActive};
    }
    .MuiButtonBase-root:hover .MuiSvgIcon-root {
      fill: ${theme.colors.themeActive};
    }
  }
`