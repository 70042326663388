/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Container, Stack, Chip } from '@mui/material'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { LOCALSTORAGE_KEYS, MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { isDateSame } from '~/utils/utils'

// css
import { MobileModalStyle, ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// images
import { imgMinigameNotice01 } from '~/assets/images/layout_1'

// icon
import CloseIcon from '@mui/icons-material/Close'

const ChipColor = {
  minWidth: 26,
  height: 26,
  backgroundColor: '#2E3A4D',
  color: '#fff',
  fontSize: 14,
}

interface Props {
  kind: string
}

function PopupPowerballBettingGuide({ kind }: Props) {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()
  // const [onceClosed, setOnceClosed] = useState(false)

  const { desktop } = globalStore.decideMedia(isDesktop)
  const { powerballBettingGuideVisible } = globalStore

  function onClosePopup() {
    // const now = new Date()
    // localStorage.setItem(LOCALSTORAGE_KEYS.POPUP_PB_GUIDE_DATE, now.toString())
    // setOnceClosed(true)
    globalStore.setPowerballBettingGuideVisible(false)
  }

  let dailyPopupPowerballBettingGuide = false
  // if (!onceClosed) {
  //   const now = new Date()
  //   popupPowerballBettingGuide = kind.indexOf('PB') >= 0
  //   if (popupPowerballBettingGuide) {
  //     try {
  //       let sdate = localStorage.getItem(LOCALSTORAGE_KEYS.POPUP_PB_GUIDE_DATE)
  //       if (sdate) {
  //         const popupGuideDate = new Date(sdate)
  //         if (isDateSame(popupGuideDate, now)) {
  //           popupPowerballBettingGuide = false
  //         }
  //       }
  //     } catch (err) {}
  //   }
  // }

  const open = dailyPopupPowerballBettingGuide || powerballBettingGuideVisible

  let contents = null
  if (desktop) {
    contents = (
      <Dialog
        open={open}
        onClose={onClosePopup}
        css={ModalStyle}
        fullWidth={true}
        sx={{
          '& .MuiDialog-container': {
            height: '100% !important',
          },
          '& .MuiDialog-paper': {
            maxWidth: 500,
            minHeight: '0 !important',
          },
        }}
      >
        <Box className="modal_content">
          <AppBar sx={{ position: 'relative' }}>
            <Typography className="modal_title" variant="h6" component="div">
              파워볼 조합 안내
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClosePopup} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <Box>
            <Container sx={{ p: '0 !important' }}>
              <Box sx={{ px: 2 }}>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="1" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 18, '& > span': { color: '#9AFF00' } }}>
                      파워볼 단폴 + 일반볼 <span>조합 가능</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) 짝 1.95 x 홀 + 오버 3.72</span>
                      <span>배당률 7.25</span>
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="2" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 18, '& > span': { color: '#9AFF00' } }}>
                      파워볼 조합 + 일반볼 단폴 <span>조합 가능</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) 홀 + 언더 3.15 x 짝 1.95</span>
                      <span>배당률 6.14</span>
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="3" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 18, '& > span': { color: '#9AFF00' } }}>
                      파워볼 조합 + 일반볼 조합 <span>조합 가능</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) B-4, 5 4.75 x 홀 + 오버 3.72</span>
                      <span>배당률 17.67</span>
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="4" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 18, '& > span': { color: '#FE3D3D' } }}>
                      파워볼 단폴 + 일반볼 단폴 <span>조합 불가</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 16,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) 짝 1.95 x 짝 1.95</span>
                      <span style={{ color: '#FE3D3D' }}>조합불가</span>
                    </Box>
                  </Box>
                </Stack>

                <Box sx={{ color: '#747D8A', textAlign: 'center', fontSize: 16, mt: 4 }}>
                  모든 배당률은 예시입니다. 실제와 다를 수 있습니다.
                </Box>
              </Box>
              <Box sx={{ mt: 5, mb: 2, display: 'flex', justifyContent: 'center' }}>
                <BasicButton className="btn_sub" onClick={() => onClosePopup()}>
                  닫기
                </BasicButton>
              </Box>
            </Container>
          </Box>
        </Box>
      </Dialog>
    )
  } else {
    contents = (
      <Dialog open={open} onClose={onClosePopup} css={MobileModalStyle} sx={{ zIndex: '1301' }}>
        <Box className="modal_content">
          <AppBar sx={{ position: 'relative' }}>
            <Typography className="modal_title" variant="h6" component="div">
              파워볼 조합 안내
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClosePopup} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <Box>
            <Container sx={{ p: '0 !important' }}>
              <Box sx={{ px: 2 }}>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="1" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 16, '& > span': { color: '#9AFF00' } }}>
                      파워볼 단폴 + 일반볼 <span>조합 가능</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 14,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) 짝 1.95 x 홀 + 오버 3.72</span>
                      <span>배당률 7.25</span>
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="2" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 16, '& > span': { color: '#9AFF00' } }}>
                      파워볼 조합 + 일반볼 단폴 <span>조합 가능</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 14,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) 홀 + 언더 3.15 x 짝 1.95</span>
                      <span>배당률 6.14</span>
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="3" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 16, '& > span': { color: '#9AFF00' } }}>
                      파워볼 조합 + 일반볼 조합 <span>조합 가능</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 14,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) B-4, 5 4.75 x 홀 + 오버 3.72</span>
                      <span>배당률 17.67</span>
                    </Box>
                  </Box>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ mt: 3, alignItems: 'baseline' }}>
                  <Chip label="4" size="small" sx={ChipColor} />
                  <Box sx={{ width: '100%', color: '#fff' }}>
                    <Box sx={{ fontSize: 16, '& > span': { color: '#FE3D3D' } }}>
                      파워볼 단폴 + 일반볼 단폴 <span>조합 불가</span>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 14,
                        color: '#D1D9E3',
                      }}
                    >
                      <span>ex) 짝 1.95 x 짝 1.95</span>
                      <span style={{ color: '#FE3D3D' }}>조합불가</span>
                    </Box>
                  </Box>
                </Stack>

                <Box sx={{ color: '#747D8A', textAlign: 'center', fontSize: 14, mt: 4 }}>
                  모든 배당률은 예시입니다. 실제와 다를 수 있습니다.
                </Box>
              </Box>
              <Box sx={{ mt: 5, mb: 2, display: 'flex', justifyContent: 'center' }}>
                <BasicButton className="btn_sub" onClick={() => onClosePopup()}>
                  닫기
                </BasicButton>
              </Box>
            </Container>
          </Box>
        </Box>
      </Dialog>
    )
  }

  return <>{contents}</>
}

export default observer(PopupPowerballBettingGuide)
