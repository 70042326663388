import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const FooterStyle = styled('div')`
  background-color: ${props=>props.theme.footer.background};
  text-align: center;

  & .MuiContainer-root {
    margin: 0;
  }
  .copyright {
    color: ${props=>props.theme.footer.copyright};
    text-transform: uppercase;
    text-align: left;
    & > span {
      color: #14a3df;
    }
  }
  .partner_img {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    filter: grayscale(1);
    opacity: .8;
  }

  @media (min-width: 600px) {
    & .MuiContainer-root {
      max-width: none
    }
  }
`