/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Grid, Container } from '@mui/material'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import apiGame from '@services/api/game'

// css
import { MobileModalStyle, ModalStyle } from '@styles/modal.style'

// icon
import CloseIcon from '@mui/icons-material/Close'
import { DEFAULT_LOCALE, LOCALES_KEYS } from '~/locales/loader'

//image for test
//import { LCA } from '@assets/images/layout_1'

const PopupSL: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, casinoStore, liveStore } = useRootStore()

  const [state, setState] = useState(false)
  const { currentKey, currentValue } = globalStore
  const { slItems } = casinoStore
  const { initialized, signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.slot
  const tabIndex = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = tabIndex !== ''

  const { kind: trading_kind } = currentValue || {}

  let bgSize = ''
  const GlobalStyle = () => css`
    body {
      ${isDesktop ? (bgSize = 'background-size: calc(100% - 17px) auto;') : ''}
    }
  `

  useEffect(() => {
    if (state && trading_kind) {
      casinoStore.fetchSlotGames(trading_kind, intl)
    }
  }, [state])

  let visible = visibleOnDemand && signedin
  if (!signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
    return null
  }

  if (visible !== state) {
    setState(visible)
    return null
  }

  function onClose() {
    globalStore.hideModal(modalKey)
  }

  function onClickSlotGameCard(gameId: string) {
    globalStore.newGame('SL', trading_kind, gameId, intl)
  }

  const data = _.get(slItems, trading_kind)

  const locale = localStorage.getItem('locale') ?? DEFAULT_LOCALE
  const isDefaultLocale = locale === LOCALES_KEYS.KO_KR

  let title = intl.formatMessage({
    id: `trading.SL.${trading_kind}`,
    defaultMessage: data?.title || '',
  })

  const ITEMS_PER_ROW = desktop ? 6 : 2
  const xsValue = 12 / ITEMS_PER_ROW
  const itemContents = []
  if (data && data.items.length > 0) {
    let rawSize = data.items.length / ITEMS_PER_ROW
    if (data.items.length % ITEMS_PER_ROW > 0) {
      rawSize++
    }
    let count = 0
    for (let i = 0; i < rawSize; i++) {
      const children = []
      for (let j = 0; j < ITEMS_PER_ROW && count < data.items.length; j++) {
        const el = data.items[count]
        children.push(
          <Grid item xs={xsValue}>
            <img
              style={{ width: '100%', cursor: 'pointer' }}
              onClick={() => onClickSlotGameCard(el.gameId)}
              src={el.thumbnail}
            />
            {isDefaultLocale && (
              <div style={{ color: 'white', textAlign: 'center' }}>{el.title}</div>
            )}
          </Grid>,
        )
        count++
      }
      let rowStyle = null
      itemContents.push(
        <Grid container spacing={2} sx={{ p: 3 }}>
          {children}
        </Grid>,
      )
    }
  }

  let innerContents = (
    <Box className="modal_content">
      <AppBar sx={{ position: 'relative' }}>
        <Typography className="modal_title" variant="h6" component="div">
          {title}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </AppBar>
      <Box>
        <Container>{itemContents}</Container>
      </Box>
    </Box>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Dialog
        open={visible}
        onClose={onClose}
        css={ModalStyle}
        maxWidth={'md'}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '1248px',
          },
        }}
      >
        {innerContents}
      </Dialog>
    )
  } else {
    contents = (
      <Dialog open={visible} onClose={onClose} css={MobileModalStyle}>
        {innerContents}
      </Dialog>
    )
  }

  return (
    <>
      <Global styles={GlobalStyle} />
      {contents}
    </>
  )
}

export default observer(PopupSL)
