/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import * as _ from 'lodash'

import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'

import { ALL_GAME_INFO } from '@interface/config'
import { IPartnerBettingSummary, Schema$PartnerBettingSummaryItem } from '~/v2/protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import useRootStore from '@store/useRootStore'
import { useIntl } from 'react-intl'

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: string
  trading_group: string
  trading_kind: string
  fold_type: string
  name: string
  betting_amount: number
  win_amount: number
  betting_win_offset: number
  less_commission_amount: number
  less_losing_amount: number
  my_commision_amount: number
  my_losing_amount: number
  status: string
  children: any[]
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>세부</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>게임</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>총베팅</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>총당첨</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅손익</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>하위 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>하위 루징</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>회원 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>회원 루징</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData }) {
  const { row } = props
  const [open, setOpen] = useState(false)

  const hasChildren = row.children.length > 0

  const isSum = row.status === 'sum'
  let name = row.name
  let rowStyle = {}
  if (isSum) {
    name = '합'
    rowStyle = { background: '#d8e4ff' }
  }

  const singleFoldGames = ['LCA', 'HCA', 'MSL', 'SL']
  const isSingFoldGame = singleFoldGames.indexOf(row.trading_group) >= 0
  // console.log(row.trading_group)
  let foldTypeName = ''
  if (!isSingFoldGame && !isSum) {
    foldTypeName = row.fold_type === 'SINGLE' ? '단폴' : '다폴'
  }

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...rowStyle }}>
        {hasChildren ? (
          <MyTableCell sx={{ maxWidth: '100px' }}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </MyTableCell>
        ) : (
          <MyTableCell sx={{ maxWidth: '100px' }} align="center">
            <Typography sx={{ fontSize: '12x' }}>{foldTypeName}</Typography>
          </MyTableCell>
        )}
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{name}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.betting_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.win_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.betting_win_offset)) }}
          >
            {numberWithCommas(Math.floor(row.betting_win_offset))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.less_commission_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.less_losing_amount)) }}
          >
            {numberWithCommas(Math.floor(row.less_losing_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.my_commision_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.my_losing_amount)) }}
          >
            {numberWithCommas(Math.floor(row.my_losing_amount))}
          </Typography>
        </MyTableCell>
      </TableRow>
      {hasChildren ? (
        <TableRow sx={{ background: '#a4a4a4' }}>
          <TableCell style={{ padding: 0 }} colSpan={16}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer component={Paper} sx={{ margin: '3px', width: 'calc(100% - 6px)' }}>
                <Table size="small" aria-label="collapsible table">
                  {Head()}
                  <TableBody>
                    {row.children.map(row => (
                      <Row key={row.id} row={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </Fragment>
  )
}

interface Props {
  location: any
  default_begin: Date
  default_end: Date
}

function ComponentBettingSummary({ location, default_begin, default_end }: Props) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { uuid, main, begin, end, key, value, ts } = query

  const [timestamp, setTimestamp] = useState('')
  const [loading, setLoading] = useState(false)
  const [track, setTrack] = useState<IPartnerBettingSummary.Schema | null>(null)

  const { globalStore } = useRootStore()

  if (timestamp !== (ts as string)) {
    setTimestamp(ts as string)
  }

  const fetch = async (params: IPartnerBettingSummary.Params) => {
    setLoading(true)
    try {
      const ret = await apiPartner.getBettingSummary(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    let date_begin = begin ? (begin as string) : default_begin.toString()
    let date_end = end ? (end as string) : default_end.toString()
    const params: IPartnerBettingSummary.Params = {
      date_begin: date_begin,
      date_end: date_end,
      searchKey: key as string,
      searchValue: value as string,
      target_uuid: uuid as string,
    }

    fetch(params)
  }, [timestamp])

  const onClickCollapseAll = (collapse: boolean) => {
    //
  }

  const rows = []
  if (track && track.items && track.items.length > 0) {
    const grouped = _.groupBy(track.items, 'trading_group')

    for (const el of ALL_GAME_INFO) {
      if (el.group in grouped) {
        const groupItems = _.get(grouped, el.group)
        const [group] = _.filter(groupItems, o => {
          return o.trading_group === el.group && o.trading_kind === ''
        })
        if (group) {
          const rowGroup: RowData = {
            id: group.trading_group,
            trading_group: group.trading_group,
            trading_kind: group.trading_kind,
            fold_type: group.fold_type,
            name: group.name,
            betting_amount: group.betting_amount,
            win_amount: group.win_amount,
            betting_win_offset: group.betting_win_offset,
            less_commission_amount: group.less_commission_amount,
            less_losing_amount: group.less_losing_amount,
            my_commision_amount: group.my_commision_amount,
            my_losing_amount: group.my_losing_amount,
            status: 'normal',
            children: [],
          }

          let kinds = _.filter(groupItems, o => {
            return o.trading_group === el.group && o.trading_kind !== ''
          })
          kinds = _.orderBy(kinds, ['fold_type'], ['desc'])

          for (const dt of kinds) {
            const rowKind: RowData = {
              id: dt.trading_kind,
              trading_group: dt.trading_group,
              trading_kind: dt.trading_kind,
              fold_type: dt.fold_type,
              name: dt.name,
              betting_amount: dt.betting_amount,
              win_amount: dt.win_amount,
              betting_win_offset: dt.betting_win_offset,
              less_commission_amount: dt.less_commission_amount,
              less_losing_amount: dt.less_losing_amount,
              my_commision_amount: dt.my_commision_amount,
              my_losing_amount: dt.my_losing_amount,
              status: 'normal',
              children: [],
            }
            rowGroup.children.push(rowKind)
          }

          rows.push(rowGroup)
        }
      }
    }

    if (track.sum) {
      rows.push({
        id: '',
        trading_group: '',
        trading_kind: '',
        name: '합',
        betting_amount: track.sum.betting_amount,
        win_amount: track.sum.win_amount,
        betting_win_offset: track.sum.betting_win_offset,
        less_commission_amount: track.sum.less_commission_amount,
        less_losing_amount: track.sum.less_losing_amount,
        my_commision_amount: track.sum.my_commision_amount,
        my_losing_amount: track.sum.my_losing_amount,
        status: 'sum',
        children: [],
      })
    }
  }

  return (
    <>
      {/* <Button
        variant="contained"
        sx={{ minHeight: '24px', maxHeight: '24px', background: 'grey' }}
        onClick={() => onClickCollapseAll(true)}
      >
        모두접기
      </Button>
      <Button
        variant="contained"
        sx={{ minHeight: '24px', maxHeight: '24px', background: 'grey', marginLeft: '4px' }}
        onClick={() => onClickCollapseAll(false)}
      >
        모두펼치기
      </Button> */}
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          {Head()}
          <TableBody>
            {rows.map(row => (
              <Row key={row.id} row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default observer(ComponentBettingSummary)
