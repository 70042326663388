/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import { Box, Divider, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

//css
import { BoardStyle } from '@styles/modal.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'

const DividerStyle = styled(Divider)(({ theme }) => ({
  marginBottom: '8px',
  '&:before': {
    display: 'none',
  },
  '&::after': {
    borderTop: theme.borders.default,
    borderTopStyle: 'dashed',
  },
  '& .MuiDivider-wrapper': {
    paddingLeft: 0,
  },
}))

interface Props {
  question: string
  answer: string
  state: string
}

function QnaPersonalView(props: Props) {
  const { question, answer, state } = props
  return (
    <BoardStyle className="board_view">
      <Box className="board_content" sx={{ py: 4 }}>
        <Box
          sx={{
            px: 3,
            '& > pre': {
              mb: 0,
              fontFamily: 'Noto Sans',
              fontSize: '1.1rem',
              wordBreak: 'break-all',
              whiteSpace: 'pre-wrap',
            },
          }}
        >
          <pre>{question}</pre>
        </Box>
        {state === 'ANSWER' ? (
          <Box sx={{ px: 3, mt: 3 }}>
            <DividerStyle textAlign="left">
              <Chip label="관리자 답변" color="primary" sx={{ height: '26px', backgroundColor: '#2366CB' }} />
            </DividerStyle>
            {/* <NeatTextField
              multiline
              defaultValue={answer}
              InputProps={{ readOnly: true }}
              sx={{ width: '100%', '& textarea': { color: '#D1D9E3' } }}
            ></NeatTextField> */}
            <div
              dangerouslySetInnerHTML={{ __html: answer.replace(/\n/g, '<br />') }}
              style={{ width: '100%', color: '#D1D9E3' }}
            />
          </Box>
        ) : null}
      </Box>
    </BoardStyle>
  )
}

export default QnaPersonalView
