/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React from 'react'
import { Box } from '@mui/material'
import * as _ from 'lodash'

import { useIntl } from 'react-intl'
import { Schema$FoldBonusItem } from '~/v2/interface/common'

interface Props {
  lang: string
  item: Schema$FoldBonusItem
}

const SportsBettingCartBonusItem: React.FC<Props> = ({ lang, item }) => {
  const intl = useIntl()
  const { size, rate } = item

  const title = `${size} ${intl.formatMessage({ id: 'fold-bonus' })}`
  const oddsRate = rate

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 0',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '1.1rem',
        }}
      >
        <span style={{ color: '#2b937f' }}>{title}</span>
        <span style={{ color: '#2b937f' }}>{oddsRate}</span>
      </Box>
    </Box>
  )
}

export default SportsBettingCartBonusItem
