import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Box, Chip, Divider, Typography } from '@mui/material'
import { FIXTURE_STATUS_ITEMS, getMarketArrangedFromList, getTeamName } from '~/game/st-util'
import moment from 'moment'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { useTheme } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ModalKeys } from '~/store/globalStore'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
  PrematchData,
  SPORT_FLIGHT,
  UPDATE_FIELD_TYPE,
  makeSportsBetStateColor,
} from '~/store/sportsStore/sports-util'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { Schema$Prematch } from '~/v2/interface/st-schema'

interface Props {
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  item: PrematchData
  sx?: any
}

function InplayFixture({ desktop, lang, flight, item, sx }: Props) {
  const theme = useTheme()
  const { globalStore, sportsStore } = useRootStore()
  const [timestamp, setTimestamp] = useState<number>(0)
  const { prematch, markets } = item

  useOnMount(() => {
    const { FixtureId } = item.prematch
    sportsStore.addFixtureController(
      FixtureId,
      (fixture: Schema$Prematch, type: UPDATE_FIELD_TYPE) => {
        setTimestamp(new Date().getTime())
      },
    )
  })

  useOnUnmount(() => {
    const { FixtureId } = item.prematch
    sportsStore.deleteFixtureController(FixtureId)
  })

  const handleMoreOdds = () => {
    sportsStore.setSelectedFixture(item.prematch.FixtureId)
    globalStore.showModal(ModalKeys.sport_market, 0)
  }

  const homeName = getTeamName(prematch, '1', lang)
  const awayName = getTeamName(prematch, '2', lang)

  const startDate = new Date(prematch.StartDate)
  let dateOfMatch = moment(startDate).format('MM-DD')
  const timeOfMatch = moment(startDate).format('HH:mm')

  let statusContent = null
  const statusItem = _.find(FIXTURE_STATUS_ITEMS, o => {
    return o.value === prematch.Status.toString()
  })
  if (statusItem) {
    statusContent = (
      <Chip
        label={statusItem.name[lang]}
        size="small"
        sx={{
          ml: '12px',
          borderRadius: '6px',
          width: '60px',
          height: '21px',
          color: 'white',
          background: statusItem.color,
        }}
      />
    )
  }

  let addonSize = markets.length

  return (
    <Box
      textAlign={{ xs: 'center', md: 'left' }}
      justifyContent="space-between"
      sx={{ background: theme.sportsMatchup.fixtureBg, borderRadius: '5px', ...sx, p: 1 }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              fontSize: '14px',
              color: '#838485',
              fontWeight: 'bold',
            }}
          >
            {dateOfMatch}
          </Typography>
          <Typography
            sx={{
              marginLeft: '6px',
              fontSize: '14px',
              color: '#d6d6d6',
              fontWeight: 'bold',
            }}
          >
            {timeOfMatch}
          </Typography>
          {statusContent}
        </Box>
        <Box display="flex" justifyContent="end" sx={{ width: '60px' }}>
          (
          <Box
            onClick={() => handleMoreOdds()}
            display="flex"
            sx={{
              cursor: 'pointer',
              ':hover .child': {
                color: '#f85300',
              },
            }}
          >
            <Typography
              className="child"
              sx={{
                color: 'white',
                fontSize: '14px',
                fontWeight: 'bold',
                mt: '4px',
              }}
            >
              +{addonSize}
            </Typography>
            <ChevronRightIcon
              className="child"
              sx={{
                color: 'white',
                fontSize: '24px',
                mt: '3px',
                ml: '-3px',
              }}
            ></ChevronRightIcon>
          </Box>
          )
        </Box>
      </Box>
      <Divider variant="fullWidth" sx={{ mt: '8px', mb: '8px', color: '#888888' }}></Divider>
      <CopyToClipboard text={prematch.FixtureId}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ color: '#d6d6d6', fontSize: '14px' }}>{homeName}</Typography>
          <Typography sx={{ color: '#838485', fontSize: '14px' }}>&nbsp;vs&nbsp;</Typography>
          <Typography sx={{ color: '#d6d6d6', fontSize: '14px' }}>{awayName}</Typography>
        </Box>
      </CopyToClipboard>
    </Box>
  )
}

export default observer(InplayFixture)
