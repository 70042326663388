/** @jsxImportSource @emotion/react */
import { css, Global, Theme } from '@emotion/react'
import { observer } from 'mobx-react'
import { Grid, Box, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'

import useRootStore from '@store/useRootStore'
import SigninComponent from '~/containers/shared/PopupLogin/SigninComponent'
import SigninComponent2 from '~/containers/shared/PopupLogin/SigninComponent2'
import SignupDlg from './SignupDlg'
import { ModalKeys } from '~/store/globalStore'
import SigninComponentAce from '~/containers/shared/PopupLogin/SigninComponentAce'
import SigninComponentBand from '~/containers/shared/PopupLogin/SigninComponentBand'

// css
import { BasicButton } from '~/styles/button.style'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

let loginWrapStyle = {}
if (
  channelSymbol === 'marine' ||
  channelSymbol === 'tiger' ||
  channelSymbol === 'hilton' ||
  channelSymbol === 'soft' ||
  channelSymbol === 'gangnam' ||
  channelSymbol === 'milky' ||
  channelSymbol === 'hulk'
) {
  loginWrapStyle = {
    border: '1px solid #41424c',
  }
} else if (
  channelSymbol === 'today' ||
  channelSymbol === 'hongyeom' ||
  channelSymbol === 'ace' ||
  channelSymbol === 'ssg' ||
  channelSymbol === 'praha'
) {
  loginWrapStyle = {
    margin: '80px auto',
    boxShadow: '0px 0px 15px 0px #ff3140',
  }
} else {
  loginWrapStyle = {
    borderRadius: '10px',
  }
}

const GlobalStyle = (theme: Theme) => css`
  html,
  body {
    background: ${theme.colors.loginBg};
  }
  .login_wrap {
    min-width: 100%;
    background: ${theme.colors.loginFormBg};
    ${loginWrapStyle};
  }
  @media (min-width: 992px) {
    html,
    body {
      background-size: cover;
    }
  }
`

const Login: React.FC = () => {
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const { initialized, signedin } = authStore
  const { option } = globalStore
  const { contact } = option || {}
  const intl = useIntl()

  const initializeSession = async () => {
    await authStore.recoverSession(intl)
  }

  useEffect(() => {
    initializeSession()
    globalStore.getPublicSettings(intl)
  }, [])

  const onClickSignup = () => {
    globalStore.showModal(ModalKeys.signup, 1)
  }

  if (initialized && signedin) {
    history.push('/')
    return <></>
  }

  let signinContents = {}

  if (
    channelSymbol === 'marine' ||
    channelSymbol === 'tiger' ||
    channelSymbol === 'hilton' ||
    channelSymbol === 'soft' ||
    channelSymbol === 'gangnam' ||
    channelSymbol === 'milky' ||
    channelSymbol === 'hulk'
  ) {
    signinContents = <SigninComponent2 />
  } else if (
    channelSymbol === 'today' ||
    channelSymbol === 'hongyeom' ||
    channelSymbol === 'ace' ||
    channelSymbol === 'ssg' ||
    channelSymbol === 'praha'
  ) {
    signinContents = <SigninComponentAce />
  } else if (channelSymbol === 'band' || channelSymbol === 'water' || channelSymbol === 'emart' || channelSymbol === 'cigar') {
    signinContents = <SigninComponentBand />
  } else if (channelSymbol === 'ping') {
    signinContents = (
      <>
        <SigninComponent />
        <Grid container sx={{ pb: 6, justifyContent: 'center' }}>
          <Grid item xs={9}>
            <Box sx={{ display: 'flex', jsutifyContent: 'center' }}>
              <BasicButton
                type="button"
                className="btn_default"
                onClick={() => onClickSignup()}
                sx={{ width: '100%' }}
              >
                {intl.formatMessage({ id: 'signup' })}
              </BasicButton>
            </Box>
          </Grid>
        </Grid>
      </>
    )
  } else {
    signinContents = (
      <>
        <SigninComponent />
        <Grid container sx={{ pb: 6, justifyContent: 'center', textAlign: 'right' }}>
          <Grid item xs={9}>
            <Typography
              component="span"
              onClick={() => onClickSignup()}
              sx={{
                cursor: 'pointer',
                color: '#747d8a',
                fontSize: '15px',
                '&:hover': { color: '#289CAF' },
              }}
            >
              회원가입
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Global styles={GlobalStyle} />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ p: '5%', maxWidth: 700, m: 'auto', minHeight: '100vh', height: '100%' }}
      >
        <Grid item xs={8} className="login_wrap">
          {signinContents}
        </Grid>
      </Grid>
      <SignupDlg></SignupDlg>
    </>
  )
}

export default observer(Login)
