import http from '../http'
import {
  IRecover,
  IUserInfo,
  IChangePassword,
  IChangePassword2,
  IChangeNick,
  IQnaRecord,
  IWriteQna,
  Schema$doCheckDuplicateLogin,
  IMessages,
  ISetMessageRead,
  IDeleteMessages,
  ISetPosition,
  IUserAsset,
  IUserAccPoint,
  IUserAccComp,
  ICouponList,
  IUseCoupon,
  ICouponConfig,
  IIssueCoupon,
  IRecallCoupon,
  IIssuerCouponList,
  IPossibleOddsTypes,
} from '@protocol/user'

import {
  IBankDeposit,
  IBankDepositDC,
  IBankWithdraw,
  IBankWithdrawCodepay,
  IBankHistory,
  IMyBankInfo,
  IBankTransferPoint,
  IBankState,
  ICoinBuyInfo,
  ICoinInfo,
  IBankDepositCoin,
  IBankCancelDeposit,
  IBankDepositWonp,
  IBankConfirmTransferPassword,
  IBankTransferMoney,
  IMoneyTransferRecord,
  IBankTransferComp,
  IBankDepositCoinJun200,
  IBankDepositOnepay,
  IBankDeposit5Koin,
  IBankTransferPointRecord,
  IBankTransferCompRecord,
  IDeleteBankHistoryItem,
  IBankDepositCoinWiki,
  IBankDepositVenicn,
  IVenicnInfo,
  ICoinOXOInfo,
  IBankDepositCoinOXO,
  IBankDepositOnePayCoin,
  IBankDepositCommonCoin,
  IBankDepositCommonVirtual,
  IBankRequestAuthVirtual,
  IBankDepositMCI,
  IBankDepositCodepay,
} from '@protocol/bank'
import { INotices } from '~/v2/protocol/public'

const defs = {
  recover(): Promise<IRecover.Schema> {
    return http.get('api/user/recover', {})
  },

  getUserInfo(data: IUserInfo.Params): Promise<IUserInfo.Schema> {
    return http.get('api/user/info', data || {})
  },

  getAsset(data: IUserAsset.Params): Promise<IUserAsset.Schema> {
    return http.get('api/user/asset', data || {})
  },

  getAccPoint(data: IUserAccPoint.Params): Promise<IUserAccPoint.Schema> {
    return http.get('api/user/acc-point', data || {})
  },

  getAccComp(data: IUserAccComp.Params): Promise<IUserAccComp.Schema> {
    return http.get('api/user/acc-comp', data || {})
  },

  changePassword(data: IChangePassword.Params): Promise<IChangePassword.Schema> {
    return http.post('api/user/change-password', data || {})
  },

  changePassword2(data: IChangePassword2.Params): Promise<IChangePassword2.Schema> {
    return http.post('api/user/change-password2', data || {})
  },

  changeNick(data: IChangeNick.Params): Promise<IChangeNick.Schema> {
    return http.post('api/user/change-nick', data || {})
  },

  getPossibleOddsTypes(data: IPossibleOddsTypes.Params): Promise<IPossibleOddsTypes.Schema> {
    return http.post('api/user/possible-odds-types', data || {})
  },

  // ------- 아이디 중복체크 인지 체크 -------
  doCheckDuplicateLogin(data: Schema$doCheckDuplicateLogin): Promise<{}> {
    return http.post('api/user/do_check_duplicate_login', data)
  },

  // bank
  getBankState(data: IBankState.Params): Promise<IBankState.Schema> {
    return http.post('api/bank/state', data || {})
  },

  getBankInfo(data: IMyBankInfo.Params): Promise<IMyBankInfo.Schema> {
    return http.post('api/bank/my-info', data || {})
  },

  deposit(data: IBankDeposit.Params): Promise<IBankDeposit.Schema> {
    return http.post('api/bank/deposit', data || {})
  },

  withdraw(data: IBankWithdraw.Params): Promise<IBankWithdraw.Schema> {
    return http.post('api/bank/withdraw', data || {})
  },

  withdrawCodepay(data: IBankWithdrawCodepay.Params): Promise<IBankWithdrawCodepay.Schema> {
    return http.post('api/bank/withdraw-codepay', data || {})
  },

  confirmTransferPassword(
    data: IBankConfirmTransferPassword.Params,
  ): Promise<IBankConfirmTransferPassword.Schema> {
    return http.post('api/bank/confirm-transfer-password', data || {})
  },

  bankHistory(data: IBankHistory.Params): Promise<IBankHistory.Schema> {
    return http.post('api/bank/history', data || {})
  },

  deleteBankHistoryItem(
    data: IDeleteBankHistoryItem.Params,
  ): Promise<IDeleteBankHistoryItem.Schema> {
    return http.post('api/bank/delete-history-item', data || {})
  },

  cancelDeposit(data: IBankCancelDeposit.Params): Promise<IBankCancelDeposit.Schema> {
    return http.post('api/bank/cancel-deposit', data || {})
  },

  transferPointRecord(
    data: IBankTransferPointRecord.Params,
  ): Promise<IBankTransferPointRecord.Schema> {
    return http.post('api/bank/transfer-point-record', data || {})
  },

  transferCompRecord(
    data: IBankTransferCompRecord.Params,
  ): Promise<IBankTransferCompRecord.Schema> {
    return http.post('api/bank/transfer-comp-record', data || {})
  },

  transferPoint(data: IBankTransferPoint.Params): Promise<IBankTransferPoint.Schema> {
    return http.post('api/bank/transfer-point', data || {})
  },

  transferComp(data: IBankTransferComp.Params): Promise<IBankTransferComp.Schema> {
    return http.post('api/bank/transfer-comp', data || {})
  },

  transferMoney(data: IBankTransferMoney.Params): Promise<IBankTransferMoney.Schema> {
    return http.post('api/bank/transfer-money', data || {})
  },

  getMoneyTransferRecord(data: IMoneyTransferRecord.Params): Promise<IMoneyTransferRecord.Schema> {
    return http.post('api/bank/money-transfer-record', data || {})
  },

  // qna
  getQnaRecord(data: IQnaRecord.Params): Promise<IQnaRecord.Schema> {
    return http.post('api/user/qna-record', data || {})
  },

  writeQna(data: IWriteQna.Params): Promise<IWriteQna.Schema> {
    return http.post('api/user/write-qna', data || {})
  },

  // messages
  getMessages(data: IMessages.Params): Promise<IMessages.Schema> {
    return http.post('api/user/messages', data || {})
  },

  setMessageRead(data: ISetMessageRead.Params): Promise<ISetMessageRead.Schema> {
    return http.post('api/user/set-message-read', data || {})
  },

  deleteMessages(data: IDeleteMessages.Params): Promise<IDeleteMessages.Schema> {
    return http.post('api/user/delete-messages', data || {})
  },

  // DC(Deposit Checker)
  depositDC(data: IBankDepositDC.Params): Promise<IBankDepositDC.Schema> {
    return http.post('api/bank/deposit-dc', data || {})
  },

  // onepay
  depositOnepay(data: IBankDepositOnepay.Params): Promise<IBankDepositOnepay.Schema> {
    return http.post('api/bank/deposit-onepay', data || {})
  },

  // 5koin
  deposit5Koin(data: IBankDeposit5Koin.Params): Promise<IBankDeposit5Koin.Schema> {
    return http.post('api/bank/deposit-5koin', data || {})
  },

  // coin
  getCoinInfo(data: ICoinInfo.Params): Promise<ICoinInfo.Schema> {
    return http.post('api/bank/coin-info', data || {})
  },

  getCoinBuyInfo(data: ICoinBuyInfo.Params): Promise<ICoinBuyInfo.Schema> {
    return http.post('api/bank/coin-buy-info', data || {})
  },

  depositCoin(data: IBankDepositCoin.Params): Promise<IBankDepositCoin.Schema> {
    return http.post('api/bank/deposit-coin', data || {})
  },

  // oxo
  getCoinOXOInfo(data: ICoinOXOInfo.Params): Promise<ICoinOXOInfo.Schema> {
    return http.post('api/bank/coin-oxo-info', data || {})
  },

  depositCoinOXO(data: IBankDepositCoinOXO.Params): Promise<IBankDepositCoinOXO.Schema> {
    return http.post('api/bank/deposit-coin-oxo', data || {})
  },

  // wonp
  depositWonp(data: IBankDepositWonp.Params): Promise<IBankDepositWonp.Schema> {
    return http.post('api/bank/deposit-wonp', data || {})
  },

  // coin jun200
  depositCoinJun200(data: IBankDepositCoinJun200.Params): Promise<IBankDepositCoinJun200.Schema> {
    return http.post('api/bank/deposit-coin-jun200', data || {})
  },

  // CoinWiki
  depositCoinWiki(data: IBankDepositCoinWiki.Params): Promise<IBankDepositCoinWiki.Schema> {
    return http.post('api/bank/deposit-coin-wiki', data || {})
  },

  // OnePayCoin
  depositOnePayCoin(data: IBankDepositOnePayCoin.Params): Promise<IBankDepositOnePayCoin.Schema> {
    return http.post('api/bank/deposit-onepay-coin', data || {})
  },

  // CommonCoin
  depositCommonCoin(data: IBankDepositCommonCoin.Params): Promise<IBankDepositCommonCoin.Schema> {
    return http.post('api/bank/deposit-common-coin', data || {})
  },

  // CommonVirtual
  depositCommonVirtual(
    data: IBankDepositCommonVirtual.Params,
  ): Promise<IBankDepositCommonVirtual.Schema> {
    return http.post('api/bank/deposit-common-virtual', data || {})
  },

  // VENICN
  depositVenicn(data: IBankDepositVenicn.Params): Promise<IBankDepositVenicn.Schema> {
    return http.post('api/bank/deposit-venicn', data || {})
  },

  // coin
  getVenicnInfo(data: IVenicnInfo.Params): Promise<IVenicnInfo.Schema> {
    return http.post('api/bank/venicn-info', data || {})
  },

  // authVirtual
  requestAuthVirtualAccount(
    data: IBankRequestAuthVirtual.Params,
  ): Promise<IBankRequestAuthVirtual.Schema> {
    return http.post('api/bank/request-auth-virtual-account', data || {})
  },

  // authVirtual
  confirmAuthVirtualAccount(
    data: IBankRequestAuthVirtual.Params,
  ): Promise<IBankRequestAuthVirtual.Schema> {
    return http.post('api/bank/confirm-auth-virtual-account', data || {})
  },

  depositMCI(data: IBankDepositMCI.Params): Promise<IBankDepositMCI.Schema> {
    return http.post('api/bank/deposit-mci', data || {})
  },

  depositCodepay(data: IBankDepositCodepay.Params): Promise<IBankDepositCodepay.Schema> {
    return http.post('api/bank/deposit-codepay', data || {})
  },

  //
  getNotices(data: INotices.Params): Promise<INotices.Schema> {
    return http.post('api/user/notices', data || {})
  },

  //
  setPosition(data: ISetPosition.Params): Promise<ISetPosition.Schema> {
    return http.post('api/user/set-position', data || {})
  },

  // coupon
  couponList(data: ICouponList.Params): Promise<ICouponList.Schema> {
    return http.post('api/user/coupon-list', data || {})
  },

  useCoupon(data: IUseCoupon.Params): Promise<IUseCoupon.Schema> {
    return http.post('api/user/use-coupon', data || {})
  },

  couponConfig(data: ICouponConfig.Params): Promise<ICouponConfig.Schema> {
    return http.post('api/user/coupon-config', data || {})
  },

  issueCoupon(data: IIssueCoupon.Params): Promise<IIssueCoupon.Schema> {
    return http.post('api/user/issue-coupon', data || {})
  },

  recallCoupon(data: IRecallCoupon.Params): Promise<IRecallCoupon.Schema> {
    return http.post('api/user/recall-coupon', data || {})
  },

  issuerCouponList(data: IIssuerCouponList.Params): Promise<IIssuerCouponList.Schema> {
    return http.post('api/user/issuer-coupon-list', data || {})
  },
}

export default defs
