/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'

//css
import PopupType1 from './PopupType1'
import PopupType2 from './PopupType2'
import PopupType3 from './PopupType3'
import PopupDefault from './PopupDefault'

interface Props {
  id: number
  title: string
  content: string
  width: number
  height: number
  x: number
  y: number
  popupStyle?: number
  popupType?: string
  popupImage?: string
  onClose: (id: number, nomore: boolean) => void
}

const PopupBanner: React.FC<Props> = (props: Props) => {

  const { id, title, content, width, height, x, y, popupStyle, popupType, popupImage, onClose } = props

  let contents = null

  if (popupStyle === undefined || popupType === undefined) {
    contents = (
      <PopupDefault
        id={id}
        title={title}
        content={content}
        width={width}
        height={height}
        x={x}
        y={y}
        onClose={onClose}
      />
    )
  } else if (popupStyle === 1) {
    contents = (
      <PopupType1 
        id={id}
        title={title}
        content={content}
        width={width}
        height={height}
        x={x}
        y={y}
        popupType={popupType}
        popupImage={popupImage}
        onClose={onClose}
      />
    )
  } else if (popupStyle === 2){
    contents = (
      <PopupType2 
        id={id}
        title={title}
        content={content}
        width={width}
        height={height}
        x={x}
        y={y}
        popupType={popupType}
        popupImage={popupImage}
        onClose={onClose}
      />
    )
  } else if (popupStyle === 3){
    contents = (
      <PopupType3
        id={id}
        title={title}
        content={content}
        width={width}
        height={height}
        x={x}
        y={y}
        popupType={popupType}
        popupImage={popupImage}
        onClose={onClose}
      />
    )
  }

  return (
    <>
      {contents}
    </>
  )
}

export default observer(PopupBanner)
