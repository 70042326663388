import * as _ from 'lodash'
import * as uuid from 'uuid'
import { StoreExt } from '@utils/reactExt'
import { observable, action, makeObservable, runInAction, toJS } from 'mobx'
import {
  Schema$FilteredMarket,
  Schema$Market,
  Schema$MarketBet,
  Schema$Prematch,
  Schema$PrematchCustomData,
  Schema$PrematchMarket,
} from '~/v2/interface/st-schema'
import { LIST_FLAG } from '~/v2/interface/types'
import { SPORT_FILTER_ITEMS, UNIFIED_MARKET_NAME, checkUnifiedMarket } from '~/game/st-util'
import globalStore from '../globalStore'
import userStore from '../userStore'
import {
  dividendFixed,
  dividendFixed1000,
  rateFixed,
  rateFixed1000,
  rateMultiplied,
  rateMultiplied1000,
} from '@game/rate-util'
import { sleep } from '~/utils/utils'
import { IBet, Schema$MatchupBetFold } from '~/v2/protocol/game'
import { Schema$FoldBonusItem, Schema$SportsOptions } from '~/v2/interface/common'
import { BET_STATUS, FIXTURE_STATUS } from '~/v2/interface/st-types'
import { MetaManager } from './meta-manager'
import { SPORT_SORT } from '~/constants'
import {
  CartItem,
  CrossUnit,
  DataQueue,
  FETCH_STATE,
  FixtureController,
  FuncUpdateFixtureField,
  FuncUpdateMarketField,
  MarketController,
  PrematchData,
  PrematchLeagueGroup,
  SPORT_FLIGHT,
  TopLeagueItem,
  UPDATE_FIELD_TYPE,
  makeMarketControllerName,
  testExchangeRate,
} from './sports-util'
import { Schema$OpenTradingKind } from '~/v2/protocol/public'
import { getVerifiedLocale } from '~/locales/loader'
import { IntlShape } from 'react-intl'
import { MARKET_COMBIATION_EXCLUSIVE_SPORTS } from '~/v2/interface/prematch_market_types'

const APP_LOCALES = process.env.REACT_APP_LOCALES ? JSON.parse(process.env.REACT_APP_LOCALES) : []

export class SportsStore extends StoreExt {
  @observable
  lang = 'ko' // en, ko

  @observable
  flight: SPORT_FLIGHT = 'domestic'

  @observable
  flightConflict: number = 0

  @observable
  sort: SPORT_SORT = 'league'

  @observable
  options: Schema$SportsOptions

  @observable
  prematchUpdatedTimestamp: number // time

  @observable
  behindUpdatedTimestamp: number // time

  items: PrematchLeagueGroup[] = []

  prematches: Schema$Prematch[] = []

  markets: Schema$PrematchMarket[] = []

  dataQueue: DataQueue = {
    statePrematches: 'none',
    stateMarkets: 'none',

    initialPrematches: [],
    initialMarkets: [],
    behindMarkets: [],
    behindState: 'none',

    updatePrematches: [],
    updateMarkets: [],
  }

  @observable
  selectedFixtureId: number = 0

  @observable
  fetchState = FETCH_STATE.NONE

  @observable
  cartCollapsed = true

  @observable
  cartItems: CartItem[] = []

  @observable
  foldBonusItem: Schema$FoldBonusItem = null

  @observable
  dividendRate = '0.0'

  @observable
  dividend = 0

  @observable
  betMoney = 0

  leagueControllers: {
    [league: string]: FixtureController
  } = {}

  fixtureControllers: {
    [fixtureId: string]: FixtureController
  } = {}

  unifiedControllers: {
    [fixtureId: string]: { [vcname: string]: MarketController }
  } = {}

  marketControllers: {
    [fixtureId: string]: { [vcname: string]: MarketController }
  } = {}

  trackControllers: {
    [fixtureId: string]: FixtureController
  } = {}

  timer: NodeJS.Timeout = null

  @observable
  watch: any = {} // all or sportId

  @observable
  highlight_leagues: TopLeagueItem[] = []

  @observable
  normal_leagues: TopLeagueItem[] = []

  meta_manager: MetaManager = new MetaManager()

  constructor() {
    super()

    const locale = getVerifiedLocale()
    if (locale && locale !== 'ko-KR') {
      this.lang = 'en'
    }

    makeObservable(this)
  }

  @action
  setLang = (lang: string) => {
    this.lang = lang
  }

  isFlightPrematch = (flight: SPORT_FLIGHT) => {
    return flight === 'prematch' || flight === 'domestic' || flight === 'europe'
  }

  @action
  setFlight = (flight: SPORT_FLIGHT) => {
    const changed =
      (this.isFlightPrematch(this.flight) && !this.isFlightPrematch(flight)) ||
      (!this.isFlightPrematch(this.flight) && this.isFlightPrematch(flight))

    this.flight = flight

    this.clearPrematches()

    if (changed) {
      this.setFlightConflict()
    } else {
      this.coverPrematches()
    }
  }

  @action
  setFlightConflict = () => {
    this.flightConflict = new Date().getTime()
  }

  @action
  setSort = (sort: SPORT_SORT) => {
    this.sort = sort
    this.coverPrematches()
  }

  @action
  setSelectedFixture = (fixtureId: number) => {
    this.selectedFixtureId = fixtureId
  }

  getSelectedFixture = (): PrematchData => {
    const prematch = _.find(this.prematches, o => {
      return o.FixtureId === this.selectedFixtureId
    })
    const market = _.find(this.markets, o => {
      return o.FixtureId === this.selectedFixtureId
    })

    return { prematch, markets: market?.Markets || [] }
  }

  updateDataQueue = () => {
    const lastStatePrematches = this.dataQueue.statePrematches
    const lastStateMarkets = this.dataQueue.stateMarkets

    if (lastStatePrematches === 'pending' && lastStateMarkets === 'pending') {
      this.prematches = this.dataQueue.initialPrematches
      this.dataQueue.initialPrematches = []
      this.dataQueue.statePrematches = 'done'

      this.markets = this.dataQueue.initialMarkets
      this.dataQueue.initialMarkets = []
      this.dataQueue.stateMarkets = 'done'
    }

    if (lastStatePrematches === 'pending' && lastStateMarkets === 'pending') {
      for (const el of this.markets) {
        testExchangeRate(this.options, el.SportId, el.LeagueId, el)
      }
      this.coverPrematches()
      this.updateTops()
      this.validateCartItems()
    }

    if (this.dataQueue.statePrematches !== 'done' || this.dataQueue.stateMarkets !== 'done') {
      return
    }

    const lastItemCount = this.items.length
    let coverPrematchesAgain = false

    if (this.dataQueue.behindMarkets.length > 0 && this.dataQueue.behindState === 'pending') {
      this.coverBehindMatches()
      this.dataQueue.behindState = 'done'
      return
    }

    if (this.dataQueue.updatePrematches.length > 0) {
      for (let i = 0; i < 100; i += 1) {
        const prematch = this.dataQueue.updatePrematches.shift()
        const ret = this.prematchUpdated(prematch)
        coverPrematchesAgain = ret !== 0
        if (this.dataQueue.updatePrematches.length === 0) {
          break
        }
      }
    }

    if (this.dataQueue.updateMarkets.length > 0) {
      for (let i = 0; i < 100; i += 1) {
        const market = this.dataQueue.updateMarkets.shift()
        this.marketUpdated(market, 'update')
        if (this.dataQueue.updateMarkets.length === 0) {
          break
        }
      }
    }

    const newItemCount = this.items.length

    if (lastItemCount !== newItemCount) {
      // TODO
      console.log(
        `sport item size changed! lastItemCount: ${lastItemCount} newItemCount: ${newItemCount} `,
      )
    }

    if (coverPrematchesAgain) {
      this.coverPrematches()
      this.updateTops()
    }
  }

  addLeagueController = (leagueId: number, updateField: FuncUpdateFixtureField) => {
    const ssLeagueId = leagueId.toString()
    if (!(ssLeagueId in this.leagueControllers)) {
      this.leagueControllers[ssLeagueId] = {
        updateField,
      }
    }
  }

  deleteLeagueController = (leagueId: number) => {
    const ssLeagueId = leagueId.toString()
    if (ssLeagueId in this.leagueControllers) {
      delete this.leagueControllers[ssLeagueId]
    }
  }

  addFixtureController = (fixtureId: number, updateField: FuncUpdateFixtureField) => {
    const ssFixtureId = fixtureId.toString()
    if (!(ssFixtureId in this.fixtureControllers)) {
      this.fixtureControllers[ssFixtureId] = {
        updateField,
      }
    }
  }

  deleteFixtureController = (fixtureId: number) => {
    const ssFixtureId = fixtureId.toString()
    if (ssFixtureId in this.fixtureControllers) {
      delete this.fixtureControllers[ssFixtureId]
    }
  }

  addUnifiedController = (
    fixtureId: number,
    unifiedName: string,
    updateField: FuncUpdateMarketField,
  ) => {
    const ssFixtureId = fixtureId.toString()
    if (!(ssFixtureId in this.unifiedControllers)) {
      this.unifiedControllers[ssFixtureId] = {}
    }

    const vcname = makeMarketControllerName(fixtureId, unifiedName)
    this.unifiedControllers[ssFixtureId][vcname] = {
      updateField,
    }
  }

  deleteUnifiedController = (fixtureId: number, unifiedName: string) => {
    const ssFixtureId = fixtureId.toString()
    if (ssFixtureId in this.unifiedControllers) {
      const vcname = makeMarketControllerName(fixtureId, unifiedName)
      if (vcname in this.unifiedControllers[ssFixtureId]) {
        delete this.unifiedControllers[ssFixtureId][vcname]
      }

      if (Object.values(this.unifiedControllers[ssFixtureId]).length === 0) {
        delete this.unifiedControllers[ssFixtureId]
      }
    }
  }

  addMarketController = (
    fixtureId: number,
    marketId: number,
    updateField: FuncUpdateMarketField,
  ) => {
    const ssFixtureId = fixtureId.toString()
    if (!(ssFixtureId in this.marketControllers)) {
      this.marketControllers[ssFixtureId] = {}
    }

    const vcname = makeMarketControllerName(fixtureId, marketId)
    this.marketControllers[ssFixtureId][vcname] = {
      updateField,
    }
  }

  deleteMarketController = (fixtureId: number, marketId: number) => {
    const ssFixtureId = fixtureId.toString()
    if (ssFixtureId in this.marketControllers) {
      const vcname = makeMarketControllerName(fixtureId, marketId)
      if (vcname in this.marketControllers[ssFixtureId]) {
        delete this.marketControllers[ssFixtureId][vcname]
      }

      if (Object.values(this.marketControllers[ssFixtureId]).length === 0) {
        delete this.marketControllers[ssFixtureId]
      }
    }
  }

  addTrackController = (fixtureId: number, updateField: FuncUpdateFixtureField) => {
    const ssFixtureId = fixtureId.toString()
    if (!(ssFixtureId in this.trackControllers)) {
      this.trackControllers[ssFixtureId] = {
        updateField,
      }
    }
  }

  deleteTrackController = (fixtureId: number) => {
    const ssFixtureId = fixtureId.toString()
    if (ssFixtureId in this.trackControllers) {
      delete this.trackControllers[ssFixtureId]
    }
  }

  clearViewControllers = () => {
    this.marketControllers = {}
  }

  // // ui 를 변경하는 테스트 함수
  // @action
  // test = (fixtureId: number, marketId: number, marketBetName: string, baseLine: string, type: UPDATE_FIELD_TYPE) => {
  //   const vcname = makeViewControllerName(fixtureId, marketId, marketBetName, baseLine)
  //   const found = _.find(this.markets, o => {
  //     return o.FixtureId === fixtureId
  //   })
  //   if (found) {
  //     const market = _.find(found.Markets, o => {
  //       return o.Id === marketId
  //     })
  //     if (market) {
  //       const marketBet = _.find(market.Bets, o => {
  //         return o.BaseLine === baseLine && o.Name === marketBetName
  //       })
  //       if (marketBet) {
  //         if (type === 'test') {
  //           let n = Number(marketBet.Price)
  //           n += marketBet.Name === '1' || marketBet.Name === 'Under' ? 1 : -1
  //           n = parseFloat(n.toFixed(this.options.sports_option.odds_decimal_point))
  //           marketBet.Price = n.toFixed(this.options.sports_option.odds_decimal_point)

  //           let m = Number(marketBet.ClientPrice)
  //           m += marketBet.Name === '1' || marketBet.Name === 'Under' ? 1 : -1
  //           m = parseFloat(m.toFixed(this.options.sports_option.odds_decimal_point))
  //           marketBet.ClientPrice = m.toFixed(this.options.sports_option.odds_decimal_point)
  //         }

  //         const vc = this.marketControllers[fixtureId.toString()]
  //         if (vc) {
  //           if (vcname in vc) {
  //             vc[vcname].updateField(marketBet, type)
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  start() {
    const self = this
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    this.timer = setInterval(() => {
      self.updateDataQueue()
    }, 500)
  }

  @action
  cleanup = () => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    this.items = []
    this.prematches = []
    this.markets = []

    this.fetchState = FETCH_STATE.NONE

    this.leagueControllers = {}
    this.fixtureControllers = {}
    this.unifiedControllers = {}
    this.marketControllers = {}
    this.trackControllers = {}

    this.flightConflict = 0

    this.clearCart()
    this.setSelectedFixture(0)
  }

  @action
  clearPrematches = () => {
    this.items = []

    // this.dataQueue = {
    //   statePrematches: 'none',
    //   stateMarkets: 'none',

    //   initialPrematches: [],
    //   initialMarkets: [],

    //   updatePrematches: [],
    //   updateMarkets: [],
    // }

    this.prematchUpdatedTimestamp = 0
    this.behindUpdatedTimestamp = 0
  }

  @action
  testPrematchUpdatedForce = () => {
    this.prematchUpdatedTimestamp = new Date().getTime()
  }

  @action
  setSportsOptions = (options: Schema$SportsOptions) => {
    let disabledMarketUpdated = false
    if (this.options) {
      const last = []
      const curr = []
      for (const el of this.options.disabled_markets) {
        last.push(`${el.sportId}_${el.marketId}`)
      }
      for (const el of options.disabled_markets) {
        curr.push(`${el.sportId}_${el.marketId}`)
      }

      disabledMarketUpdated = !_.isEqual(last.sort(), curr.sort())
    }

    this.options = options
    if (this.cartItems.length > 0) {
      if (disabledMarketUpdated) {
        this.clearCart()
      } else {
        this.updateCartValues()
      }
    }
  }

  @action
  setFetching = (fetching: boolean) => {
    this.fetchState = fetching ? FETCH_STATE.FETCHING : FETCH_STATE.NONE
  }

  @action
  setWatch = (watch: any) => {
    this.watch = watch
    this.coverPrematches()
  }

  @action
  updateTops = () => {
    const srcPrematches = _.filter(this.prematches, o => {
      const market = _.find(this.markets, m => {
        return m.FixtureId === o.FixtureId
      })
      if (!market) {
        return false
      }
      const found = _.filter(market.Markets, e => {
        return (
          UNIFIED_MARKET_NAME['12'].indexOf(e.Name) >= 0 ||
          UNIFIED_MARKET_NAME['1X2'].indexOf(e.Name) >= 0
        )
      })
      return found.length > 0 && found[0].Bets && found[0].Bets.length > 0
    })

    // top leagues
    let newHighlightLeagues: TopLeagueItem[] = []
    let newNormalLeagues: TopLeagueItem[] = []
    const leagueGrouped = _.groupBy(srcPrematches, 'LeagueId')
    for (const [k, v] of Object.entries(leagueGrouped)) {
      const highlightIndex = _.findIndex(this.options.highlight_leagues, o => {
        return o.leagueId.toString() === k
      })
      const normalIndex = _.findIndex(this.options.normal_leagues, o => {
        return o.leagueId.toString() === k
      })
      const [top] = v
      const obj: TopLeagueItem = {
        sportId: top.SportId,
        leagueId: top.LeagueId,
        sportName: top.SportName,
        locationName: top.LocationName,
        leagueName: top.LeagueName,
        locationEmblem: top.LocationEmblem,
        leagueEmblem: top.LeagueEmblem,
      }
      if (highlightIndex >= 0) {
        newHighlightLeagues.push(obj)
      } else if (normalIndex >= 0) {
        newNormalLeagues.push(obj)
      }
    }

    const orderedHighlightLeagues: TopLeagueItem[] = []
    for (let i = 1; i < SPORT_FILTER_ITEMS.length; i += 1) {
      let filtered = _.filter(newHighlightLeagues, o => {
        return o.sportId.toString() === SPORT_FILTER_ITEMS[i].value
      })
      filtered = filtered.sort((a, b) => {
        const aname = a.leagueName[this.lang] || a.leagueName.en
        const bname = b.leagueName[this.lang] || b.leagueName.en
        return aname.localeCompare(bname)
      })
      orderedHighlightLeagues.push(...filtered)
    }

    const orderedNormalLeagues: TopLeagueItem[] = []
    for (let i = 1; i < SPORT_FILTER_ITEMS.length; i += 1) {
      let filtered = _.filter(newNormalLeagues, o => {
        return o.sportId.toString() === SPORT_FILTER_ITEMS[i].value
      })
      filtered = filtered.sort((a, b) => {
        const aname = a.leagueName[this.lang] || a.leagueName.en
        const bname = b.leagueName[this.lang] || b.leagueName.en
        return aname.localeCompare(bname)
      })
      orderedNormalLeagues.push(...filtered)
    }

    this.highlight_leagues = orderedHighlightLeagues
    this.normal_leagues = orderedNormalLeagues
  }

  generateMatches = () => {
    const items: PrematchLeagueGroup[] = []
    let srcPrematches: Schema$Prematch[] = []
    const { sportId, locationId, leagueId } = this.watch
    if (sportId) {
      srcPrematches = _.filter(this.prematches, o => {
        return o.SportId === sportId
      })
    } else if (locationId) {
      srcPrematches = _.filter(this.prematches, o => {
        return o.LocationId === locationId
      })
    } else if (leagueId) {
      srcPrematches = _.filter(this.prematches, o => {
        return o.LeagueId === leagueId
      })
    } else {
      srcPrematches = this.prematches
    }

    // league sort
    // const grouped = _.groupBy(srcPrematches, 'LeagueId')
    // for (const [k, v] of Object.entries(grouped)) {
    //   const data: PrematchLeagueGroup = {
    //     leagueId: Number(k),
    //     topDate: null,
    //     prematches: [],
    //   }
    //   for (const el of v) {
    //     const market = _.find(this.markets, o => {
    //       return o.FixtureId === el.FixtureId
    //     })
    //     if (market) {
    //       const found = _.filter(market.Markets, o => {
    //         return UNIFIED_MARKET_NAME['12'].indexOf(o.Name) >= 0 || UNIFIED_MARKET_NAME['1X2'].indexOf(o.Name) >= 0
    //       })
    //       if (found.length > 0 && found[0].Bets && found[0].Bets.length > 0) {
    //         const pd: PrematchData = {
    //           prematch: el,
    //           markets: market.Markets,
    //         }
    //         data.prematches.push(pd)
    //       }
    //     }
    //   }
    //   if (data.prematches.length > 0) {
    //     data.prematches.sort((a, b) => {
    //       return new Date(a.prematch.StartDate).getTime() - new Date(b.prematch.StartDate).getTime()
    //     })
    //     data.topDate = new Date(data.prematches[0].prematch.StartDate)
    //     items.push(data)
    //   }
    // }

    // date sort
    const grouped = _.groupBy(srcPrematches, 'LeagueId')
    const newGrouped: Schema$Prematch[][] = []
    if (leagueId) {
      for (const [k, v] of Object.entries(grouped)) {
        newGrouped.push(v)
      }
    } else {
      for (const [k, v] of Object.entries(grouped)) {
        const dateGrouped = _.groupBy(v, 'StartDate')
        for (const [k2, v2] of Object.entries(dateGrouped)) {
          newGrouped.push(v2)
        }
      }
    }
    for (const lst of newGrouped) {
      const [top] = lst
      const data: PrematchLeagueGroup = {
        leagueId: Number(top.LeagueId),
        topDate: null,
        prematches: [],
      }
      for (const el of lst) {
        const market = _.find(this.markets, o => {
          return o.FixtureId === el.FixtureId
        })
        if (market) {
          if (this.flight === 'inplay') {
            const pd: PrematchData = {
              prematch: el,
              markets: market.Markets,
            }
            data.prematches.push(pd)
          } else {
            const found = _.filter(market.Markets, o => {
              return (
                UNIFIED_MARKET_NAME['12'].indexOf(o.Name) >= 0 ||
                UNIFIED_MARKET_NAME['1X2'].indexOf(o.Name) >= 0
              )
            })
            if (found.length > 0 && found[0].Bets && found[0].Bets.length > 0) {
              const pd: PrematchData = {
                prematch: el,
                markets: market.Markets,
              }
              data.prematches.push(pd)
            }
          }
        }
      }
      if (data.prematches.length > 0) {
        data.prematches.sort((a, b) => {
          return new Date(a.prematch.StartDate).getTime() - new Date(b.prematch.StartDate).getTime()
        })
        data.topDate = new Date(data.prematches[0].prematch.StartDate)
        items.push(data)
      }
    }

    items.sort((a, b) => {
      return a.topDate.getTime() - b.topDate.getTime()
    })
    this.items = items
  }

  @action
  coverPrematches = () => {
    this.generateMatches()

    this.prematchUpdatedTimestamp = new Date().getTime()
    this.setFetching(false)

    return this.items
  }

  @action
  coverBehindMatches = () => {
    for (const el of this.dataQueue.behindMarkets) {
      testExchangeRate(this.options, el.SportId, el.LeagueId, el)
    }

    this.markets.push(...this.dataQueue.behindMarkets)
    this.dataQueue.behindMarkets = []

    this.generateMatches()

    this.updateTops()

    this.behindUpdatedTimestamp = new Date().getTime()

    return this.items
  }

  @action
  prematchUpdated = (prematch: Schema$Prematch): number => {
    const { LeagueId, FixtureId } = prematch

    const found = _.find(this.prematches, o => {
      return o.FixtureId === FixtureId
    })
    if (found) {
      _.assign(found, prematch)

      const fc = this.fixtureControllers[FixtureId.toString()]
      if (fc) {
        fc.updateField(prematch, 'update')
      }

      const tc = this.trackControllers[FixtureId.toString()]
      if (tc) {
        tc.updateField(prematch, 'update')
      }
    }

    const lc = this.leagueControllers[LeagueId.toString()]
    if (lc) {
      lc.updateField(prematch, 'update')
    }

    return 0
    // -1: deleted, 0: updated, 1: added
    // const idx = _.findIndex(this.tmpMarkets, o => {
    //   return o.FixtureId === prematch.FixtureId
    // })
    // if (idx >= 0) {
    //   // TODO: update to items
    //   // this.tmpPrematches[idx] = prematch
    //   // this.updatedFixtureId = prematch.FixtureId
    // }
  }

  @action
  marketUpdated = (market: Schema$PrematchMarket, type: UPDATE_FIELD_TYPE) => {
    // update market to this.markets
    // check updated object is applied to items element

    const found = _.find(this.markets, o => {
      return o.FixtureId === market.FixtureId
    })
    if (found) {
      const addMarkets: Schema$Market[] = []
      for (const el of market.Markets) {
        const market = _.find(found.Markets, o => {
          return o.Id === el.Id
        })
        if (market) {
          market.Bets = el.Bets
        } else {
          addMarkets.push(el)
        }
      }
      found.Markets.push(...addMarkets)
      testExchangeRate(this.options, market.SportId, market.LeagueId, found)

      for (const el of market.Markets) {
        const mcname = makeMarketControllerName(market.FixtureId, el.Id)
        const mc = this.marketControllers[market.FixtureId.toString()]
        if (mc) {
          if (mcname in mc) {
            mc[mcname].updateField(el, type)
          }
        }
        const unifiedName = checkUnifiedMarket(el.Name)
        if (unifiedName) {
          const rmc = this.unifiedControllers[market.FixtureId.toString()]
          if (rmc) {
            const rmcname = makeMarketControllerName(market.FixtureId, unifiedName)
            if (rmcname in rmc) {
              rmc[rmcname].updateField(el, type)
            }
          }
        }
      }
    } else {
      testExchangeRate(this.options, market.SportId, market.LeagueId, market)
      this.markets.push(market)
    }
  }

  @action
  clearCart = () => {
    this.cartItems = []
    this.foldBonusItem = null
    this.cartCollapsed = true
    this.setBetMoney(0)
  }

  @action
  setCartCollapsed = (collapsed: boolean) => {
    this.cartCollapsed = collapsed
  }

  @action
  setBetMoney = (betMoney: number) => {
    this.betMoney = betMoney
    this.updateCartValues()
  }

  @action
  validateCartItems = () => {
    //
  }

  @action
  toggleCartItem = (
    fixtureId: number,
    marketId: number,
    marketBetName: string,
    baseLine: string,
  ): CartItem => {
    const prematch = _.find(this.prematches, o => {
      return o.FixtureId === fixtureId
    })
    const markets = _.find(this.markets, o => {
      return o.FixtureId === fixtureId
    })
    if (!prematch) {
      globalStore.pushDialogLocaleOk({ text: 'msg.no_match_found' })
      return
    }
    if (!markets) {
      globalStore.pushDialogLocaleOk({ text: 'msg.market_list_not_found' })
      return
    }
    const market = _.find(markets.Markets, o => {
      return o.Id === marketId
    })
    if (!market) {
      globalStore.pushDialogLocaleOk({ text: 'msg.market_not_found' })
      return
    }
    let marketBet: Schema$MarketBet = null
    if (baseLine) {
      marketBet = _.find(market.Bets, o => {
        return o.Name === marketBetName && o.BaseLine === baseLine
      })
    } else {
      marketBet = _.find(market.Bets, o => {
        return o.Name === marketBetName
      })
    }

    if (!marketBet) {
      globalStore.pushDialogLocaleOk({ text: 'msg.no_bet_found' })
      return
    }

    const foundIdx = _.findIndex(this.cartItems, o => {
      return o.fixtureId === fixtureId && o.marketId === marketId
    })

    if (this.flight === 'inplay' && this.cartItems.length > 0 && foundIdx < 0) {
      globalStore.pushDialogLocaleOk({ text: 'msg.live_betting_only_single_fold' })
      return
    }

    const lastCount = this.cartItems.length

    if (foundIdx >= 0) {
      if (
        this.cartItems[foundIdx].marketBetName !== marketBetName ||
        this.cartItems[foundIdx].bet.BaseLine !== baseLine
      ) {
        this.cartItems[foundIdx] = {
          cartId: uuid.v4(),
          sportId: prematch.SportId,
          leagueId: prematch.LeagueId,
          fixtureId: fixtureId,
          marketId: market.Id,
          marketName: { en: market.Name },
          marketBetName: marketBet.Name,
          match: _.cloneDeep(prematch),
          bet: _.cloneDeep(marketBet),
        }

        const meta_market = this.meta_manager.dic.market.get(market.Id)
        if (meta_market) {
          if (this.lang in meta_market.Name) {
            _.extend(this.cartItems[foundIdx].marketName, {
              ko: meta_market.Name.ko,
              en: meta_market.Name.en,
            })
          }
        }
      } else {
        const filtered = _.reject(this.cartItems, {
          fixtureId,
          marketId,
        }) as CartItem[]

        this.cartItems = filtered
      }
    } else {
      const {
        max_fold_size,
        max_fold_size_in_match,
        mc_way_enabled,
        mc_uo_enabled,
        mc_hdp_enabled,
      } = this.options.sports_option

      const filtered = _.reject(this.cartItems, {
        fixtureId,
        marketId,
      }) as CartItem[]

      const cartItem: CartItem = {
        cartId: uuid.v4(),
        sportId: prematch.SportId,
        leagueId: prematch.LeagueId,
        fixtureId: fixtureId,
        marketId: market.Id,
        marketName: { en: market.Name },
        marketBetName: marketBet.Name,
        match: _.cloneDeep(prematch),
        bet: _.cloneDeep(marketBet),
      }

      const tmpItems = [...this.cartItems]
      tmpItems.push(cartItem)

      if (max_fold_size > 0) {
        if (tmpItems.length > max_fold_size) {
          globalStore.pushDialogLocaleOk({
            text: 'msg.format.no_more_fold',
            arg1: max_fold_size.toString(),
          })
          return
        }
      }

      if (max_fold_size_in_match > 0) {
        const fixtureIdGrouped = _.groupBy(tmpItems, 'fixtureId')
        for (const [k, v] of Object.entries(fixtureIdGrouped)) {
          if (v.length > max_fold_size_in_match) {
            globalStore.pushDialogLocaleOk({
              text: 'msg.no_more_bet_same_match',
            })
            return
          }
        }
      }

      // check market type
      const fixtureIdGrouped = _.groupBy(tmpItems, 'fixtureId')
      for (const v of Object.values(fixtureIdGrouped)) {
        const { sportId } = v[0]
        const marketIds = _.map(v, o => {
          return Number(o.marketId)
        })
        const sp = _.find(MARKET_COMBIATION_EXCLUSIVE_SPORTS, o => {
          return o.sportId === sportId
        })
        if (sp) {
          const way = _.intersection(marketIds, sp.exclusive.way)
          const uo = _.intersection(marketIds, sp.exclusive.uo)
          const hdp = _.intersection(marketIds, sp.exclusive.hdp)
          try {
            if (way.length > 1 && !mc_way_enabled) {
              throw new Error('조합이 불가능한 베팅입니다.')
            }
            if (uo.length > 1 && !mc_uo_enabled) {
              throw new Error('조합이 불가능한 베팅입니다.')
            }
            if (hdp.length > 1 && !mc_hdp_enabled) {
              throw new Error('조합이 불가능한 베팅입니다.')
            }
          } catch (err) {
            globalStore.pushDialogOk({ text: err.message })
            return
          }
        }
      }

      // check cross bettings
      if (tmpItems.length > 1) {
        const target: { [fixtureId: string]: CrossUnit } = {}
        const grouped_fixtureId = _.groupBy(tmpItems, 'fixtureId')

        for (const [fixtureId, folds] of Object.entries(grouped_fixtureId)) {
          target[fixtureId] = {
            sport_id: 0,
            way3_way2: { way3: 0, way2: 0 },
            way_hdp: { way: 0, hdp: 0 },
            way_uo: { way: 0, uo: 0 },
            hdp_uo: { hdp: 0, uo: 0 },
          }

          for (const fold of folds) {
            target[fixtureId].sport_id = fold.sportId

            if (
              UNIFIED_MARKET_NAME['12'].indexOf(fold.marketName.en) >= 0 ||
              UNIFIED_MARKET_NAME['1X2'].indexOf(fold.marketName.en) >= 0
            ) {
              if (UNIFIED_MARKET_NAME['1X2'].indexOf(fold.marketName.en) >= 0) {
                target[fixtureId].way3_way2.way3 += 1
              }
              if (UNIFIED_MARKET_NAME['12'].indexOf(fold.marketName.en) >= 0) {
                target[fixtureId].way3_way2.way2 += 1
              }
              target[fixtureId].way_hdp.way += 1
              target[fixtureId].way_uo.way += 1
            }
            if (UNIFIED_MARKET_NAME.HDP.indexOf(fold.marketName.en) >= 0) {
              target[fixtureId].way_hdp.hdp += 1
              target[fixtureId].hdp_uo.hdp += 1
            }
            if (UNIFIED_MARKET_NAME.UO.indexOf(fold.marketName.en) >= 0) {
              target[fixtureId].way_uo.uo += 1
              target[fixtureId].hdp_uo.uo += 1
            }
          }
        }

        try {
          for (const el of Object.values(target)) {
            if (el.way3_way2.way3 && el.way3_way2.way2) {
              throw new Error('동일경기의 승무패와 승패 조합은 허용되지 않습니다.')
            }
            if (el.way_hdp.way && el.way_hdp.hdp) {
              throw new Error('동일경기의 승무패와 핸디캡 조합은 허용되지 않습니다.')
            }
            if (el.way_uo.way && el.way_uo.uo) {
              const cfg = _.find(this.options.sports_configs, o => {
                return o.sport_id === el.sport_id
              })
              if (!cfg || !cfg.enabled_multi_way_uo) {
                throw new Error('동일경기의 승무패와 언더오버 조합은 허용되지 않습니다.')
              }
            }
            if (el.hdp_uo.hdp && el.hdp_uo.uo) {
              const cfg = _.find(this.options.sports_configs, o => {
                return o.sport_id === el.sport_id
              })
              if (!cfg || !cfg.enabled_multi_hdp_uo) {
                throw new Error('동일경기의 핸디캡과 언더오버 조합은 허용되지 않습니다.')
              }
            }
          }
        } catch (err) {
          globalStore.pushDialogOk({ text: err.message })
          return
        }
      }

      const meta_market = this.meta_manager.dic.market.get(market.Id)
      if (meta_market) {
        if (this.lang in meta_market.Name) {
          _.extend(cartItem.marketName, { ko: meta_market.Name.ko, en: meta_market.Name.en })
        }
      }

      const newCartItems = filtered.concat([cartItem])
      this.cartItems = newCartItems
    }

    if (this.cartItems.length > 0 && this.cartItems.length !== lastCount) {
      this.cartCollapsed = false
    }

    this.updateCartValues()
  }

  @action
  removeCartItem = (fixtureId: number, marketId: number, marketBetId: string): boolean => {
    const lastLength = this.cartItems.length
    const filtered = _.reject(this.cartItems, {
      fixtureId,
      marketId,
      marketBetId,
    }) as CartItem[]

    if (filtered.length === 0) {
      this.clearCart()
      return filtered.length < lastLength
    }

    this.cartItems = filtered

    this.updateCartValues()

    return filtered.length < lastLength
  }

  @action
  removeCartItemByCartId = (cartId: string): boolean => {
    const lastLength = this.cartItems.length
    const filtered = _.reject(this.cartItems, {
      cartId,
    }) as CartItem[]

    if (filtered.length === 0) {
      this.clearCart()
      return filtered.length < lastLength
    }

    this.cartItems = filtered

    this.updateCartValues()

    return filtered.length < lastLength
  }

  @action
  bet = async (intl: IntlShape) => {
    try {
      if (this.cartItems.length === 0) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.choose_bet',
        })
        return false
      }
      if (this.betMoney === 0) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.enter_bet_amount',
        })
        return false
      }
      if (this.flight === 'inplay' && this.cartItems.length > 1) {
        globalStore.pushDialogLocaleOk({ text: 'msg.live_betting_only_single_fold' })
        return
      }

      // TODO: 베팅 최소/최대 금액 검사
      if (this.betMoney < 5000) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.format.min_bet_amount',
          arg1: '5000',
        })
        return false
      }

      globalStore.pushDialogLocaleWait({
        text: 'msg.betting_ing',
      })

      let tradingKind = ''
      const provider = this.options.sports_option.bookmaker

      const params: IBet.Params = {
        tradingId: 0,
        tradingGroup: 'SP',
        tradingKind: '',
        folds: [],
        dividendRate: this.dividendRate,
        dividend: this.dividend,
        betMoney: this.betMoney,
        flight: this.flight,
        foldBonus: this.foldBonusItem ? toJS(this.foldBonusItem) : null,
      }
      for (const el of this.cartItems) {
        const fold: Schema$MatchupBetFold = {
          tradingId: 0,
          matchupId: '',
          marketId: el.marketId.toString(),
          oddsId: el.bet.Id.toString(),
          oddsRate: el.bet.ClientPrice,

          provider: provider,
          baseLine: el.bet.BaseLine,
          sportId: el.sportId,
          leagueId: el.leagueId,
          fixtureId: el.fixtureId,
          marketName: el.marketName.en,
          oddsName: el.bet.Name,
        }
        params.folds.push(fold)

        let filteredKinds = []
        if (this.flight === 'inplay') {
          filteredKinds = _.filter(
            globalStore.option.open_trading_kinds,
            (o: Schema$OpenTradingKind) => {
              return o.trading_kind.indexOf('INPLAY') >= 0
            },
          )
        } else {
          filteredKinds = _.filter(
            globalStore.option.open_trading_kinds,
            (o: Schema$OpenTradingKind) => {
              return o.trading_kind.indexOf('INPLAY') < 0
            },
          )
        }
        const trading_kind = _.find(filteredKinds, o => {
          return (
            o.trading_group === 'SP' &&
            o.provider === provider &&
            o.vendor_id === el.sportId.toString()
          )
        })
        if (!trading_kind) {
          break
        }
        if (tradingKind === '') {
          tradingKind = trading_kind.trading_kind
        } else if (tradingKind !== trading_kind.trading_kind) {
          tradingKind = 'MIX'
        }
      }

      if (!tradingKind) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.category_not_activated',
        })
        return
      }

      params.tradingKind = tradingKind

      const { record, userMoney } = await this.api.game.betSports(params)

      await sleep(1000)

      globalStore.pushDialogLocaleOk({
        text: 'msg.betting_done',
      })

      userStore.setUserMoney(userMoney)
      this.clearCart()

      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return false
  }

  @action
  updateCartValues = () => {
    this.foldBonusItem = null

    const rateList: string[] = []

    for (const el of this.cartItems) {
      rateList.push(el.bet.ClientPrice)
    }

    const preRate =
      this.options?.sports_option?.odds_decimal_point === 2
        ? rateMultiplied(rateList)
        : rateMultiplied1000(rateList)
    const preDividend =
      this.options?.sports_option?.odds_decimal_point === 2
        ? dividendFixed(preRate, this.betMoney)
        : dividendFixed1000(preRate, this.betMoney)

    // bonusItem 을 순환하면서 fold.length >= bonus.size 큰것을 만나면 적용
    const { sports_bonus } = this.options || {}
    const { fold } = sports_bonus || {}
    const sizeOrderedDesc = _.orderBy(fold, ['size'], ['desc'])
    for (const el of sizeOrderedDesc) {
      const filtered = _.filter(rateList, (o: string) => {
        return Number(o) >= Number(el.base)
      })
      if (el.enabled && filtered.length >= el.size) {
        this.foldBonusItem = _.cloneDeep(el)
        break
      }
    }

    if (this.foldBonusItem) {
      rateList.push(this.foldBonusItem.rate)
    }

    const rate =
      this.options?.sports_option?.odds_decimal_point === 2
        ? rateMultiplied(rateList)
        : rateMultiplied1000(rateList)
    const dividend =
      this.options?.sports_option?.odds_decimal_point === 2
        ? dividendFixed(rate, this.betMoney)
        : dividendFixed1000(rate, this.betMoney)

    this.dividendRate = rate
    this.dividend = dividend

    console.log(`preRate: ${preRate} preDividend: ${preDividend}`)
    console.log(`rate: ${rate} dividend: ${dividend}`)
  }
}

export default new SportsStore()
