import http from '../http'

// api request protocol 규약
import {
  IPartnerMemberCommission,
  IPartnerMemberInfo,
  ISetPartnerMemberCommission,
} from '~/v2/protocol/partner/member'
// import { IPartnerBalanceAccount, IPartnerBankTransaction } from '~/v2/protocol/partner/bank'
// import { IPartnerTransactionHistory } from '~/v2/protocol/partner/trading'

import {
  IPartnerMember,
  IPartnerMemberList,
  IPartnerAccountBalanceRecord,
  IPartnerAccountBalanceSource,
  IPartnerAccountBalanceRequest,
  IPartnerBettingRecord,
  IPartnerStatsGame,
  IPartnerStatsRevenue,
  IPartnerStatsCommission,
  IPartnerCommissionInfo,
  IPartnerSaveCommissionInfo,
  IPartnerCreateMember,
  IPartnerStatsDay,
  IPartnerTransferSummary,
  IPartnerChargeRecord,
  IPartnerChargeSummary,
  IPartnerBettingSummary,
  IPartnerMemberSummary,
  IPartnerConcurrentUsers,
  IPartnerUserConfig,
  IPartnerMembersConcurrent,
  IPartnerSportsRecord,
  IPartnerMinigameRecord,
} from '@protocol/partner'

const defs = {
  getUserConfig(data: IPartnerUserConfig.Params): Promise<IPartnerUserConfig.Schema> {
    return http.post('api/partner/user-config', data || {})
  },
  getConcurrentUsers(
    data: IPartnerConcurrentUsers.Params,
  ): Promise<IPartnerConcurrentUsers.Schema> {
    return http.post('api/partner/concurrent-users', data || {})
  },
  getChargeSummary(data: IPartnerChargeSummary.Params): Promise<IPartnerChargeSummary.Schema> {
    return http.post('api/partner/charge-summary', data || {})
  },
  getBettingSummary(data: IPartnerBettingSummary.Params): Promise<IPartnerBettingSummary.Schema> {
    return http.post('api/partner/betting-summary', data || {})
  },
  getMemberSummary(data: IPartnerMemberSummary.Params): Promise<IPartnerMemberSummary.Schema> {
    return http.post('api/partner/member-summary', data || {})
  },
  getMember(data: IPartnerMember.Params): Promise<IPartnerMember.Schema> {
    return http.post('api/partner/member', data || {})
  },
  getMemberList(data: IPartnerMemberList.Params): Promise<IPartnerMemberList.Schema> {
    return http.post('api/partner/members', data || {})
  },
  getConcurrentMembers(
    data: IPartnerMembersConcurrent.Params,
  ): Promise<IPartnerMembersConcurrent.Schema> {
    return http.post('api/partner/members-concurrent', data || {})
  },
  getMemberCommission(
    data: IPartnerMemberCommission.Params,
  ): Promise<IPartnerMemberCommission.Schema> {
    return http.post('api/partner/member-commission', data || {})
  },
  getMemberTransferSummary(
    data: IPartnerTransferSummary.Params,
  ): Promise<IPartnerTransferSummary.Schema> {
    return http.post('api/partner/member-transfer-summary', data || {})
  },
  getBalanceAccountRecord(
    data: IPartnerAccountBalanceRecord.Params,
  ): Promise<IPartnerAccountBalanceRecord.Schema> {
    return http.post('api/partner/balance-account/record', data || {})
  },
  getBalanceAccountSource(
    data: IPartnerAccountBalanceSource.Params,
  ): Promise<IPartnerAccountBalanceSource.Schema> {
    return http.post('api/partner/balance-account/source', data || {})
  },
  reqBalanceAccount(
    data: IPartnerAccountBalanceRequest.Params,
  ): Promise<IPartnerAccountBalanceRequest.Schema> {
    return http.post('api/partner/balance-account/request', data || {})
  },
  getStatsRevenue(data: IPartnerStatsRevenue.Params): Promise<IPartnerStatsRevenue.Schema> {
    return http.post('api/partner/stats-revenue', data || {})
  },
  getStatsCommission(
    data: IPartnerStatsCommission.Params,
  ): Promise<IPartnerStatsCommission.Schema> {
    return http.post('api/partner/stats-commission', data || {})
  },
  getStatsGame(data: IPartnerStatsGame.Params): Promise<IPartnerStatsGame.Schema> {
    return http.post('api/partner/stats-game', data || {})
  },
  getBettingRecord(data: IPartnerBettingRecord.Params): Promise<IPartnerBettingRecord.Schema> {
    return http.post('api/partner/betting-record', data || {})
  },
  getChargeRecord(data: IPartnerChargeRecord.Params): Promise<IPartnerChargeRecord.Schema> {
    return http.post('api/partner/charge-record', data || {})
  },
  getCommissionInfo(data: IPartnerCommissionInfo.Params): Promise<IPartnerCommissionInfo.Schema> {
    return http.post('api/partner/commission-info', data || {})
  },
  saveCommissionInfo(
    data: IPartnerSaveCommissionInfo.Params,
  ): Promise<IPartnerSaveCommissionInfo.Schema> {
    return http.post('api/partner/save-commission-info', data || {})
  },
  createMember(data: IPartnerCreateMember.Params): Promise<IPartnerCreateMember.Schema> {
    return http.post('api/partner/create-member', data || {})
  },
  getStatsDay(data: IPartnerStatsDay.Params): Promise<IPartnerStatsDay.Schema> {
    return http.post('api/partner/stats-day', data || {})
  },
  // unkonwn
  getMemberInfo(data: IPartnerMemberInfo.Params): Promise<IPartnerMemberInfo.Schema> {
    return http.post('api/partner/member-info', data || {})
  },
  setMemberCommission(
    data: ISetPartnerMemberCommission.Params,
  ): Promise<IPartnerMemberCommission.Schema> {
    return http.post('api/partner/set_member_commission', data || {})
  },

  getSportsRecord(data: IPartnerSportsRecord.Params): Promise<IPartnerSportsRecord.Schema> {
    return http.post('api/partner/sports-record', data || {})
  },

  getMinigameRecord(data: IPartnerMinigameRecord.Params): Promise<IPartnerMinigameRecord.Schema> {
    return http.post('api/partner/minigame-record', data || {})
  },
}

export default defs
