const path = process.env.REACT_APP_CHANNEL_PATH || ''

const channel = {
  common: {
    bg_mainbg: `${path}/bg_mainbg.png`,
    main_banner: `${path}/main_banner.png`,
    m_main_banner: `${path}/m_main_banner.png`,
    main_logo: `${path}/main_logo.png`,
    main_logo_m: `${path}/main_logo_m.png`,
    main_logo_gif: `${path}/main_logo.gif`, // only praha
    main_logo_gif_m: `${path}/main_logo_m.gif`, // only praha
    login_img: `${path}/login_img.png`,
    main_banner_casino: `${path}/main_banner_casino.png`,
    main_banner_slot: `${path}/main_banner_slot.png`,
    main_banner_reel: `${path}/main_banner_reel.png`,
    main_banner_hotel_casino: `${path}/main_banner_hotel_casino.png`,
    main_banner_minigame: `${path}/main_banner_minigame.png`,
    main_banner_sports: `${path}/main_banner_sports.png`,
    bg_menu_on: `${path}/bg_menu_on.png`, // only marine2
    bg_submenu_on: `${path}/bg_submenu_on.png`,
    bg_subbg: `${path}/bg_subbg.png`,
    bg_bottombg: `${path}/bg_bottombg.png`,
    bg_subbg_m: `${path}/bg_subbg_m.png`,
    lock_bg: `${path}/lock_bg.png`,
    bg_main_title: `${path}/bg_main_title.png`,
    bg_notice: `${path}/bg_notice.png`,
    bg_main_link: `${path}/bg_main_link.png`,
    banner_s01: `${path}/banner_s01.gif`,
    m_banner_s01: `${path}/m_banner_s01.gif`,
    icon_level01: `${path}/icon_level01.gif`,
    icon_level02: `${path}/icon_level02.gif`,
    icon_level03: `${path}/icon_level03.gif`,
    icon_level04: `${path}/icon_level04.gif`,
    icon_level05: `${path}/icon_level05.gif`,
    icon_level06: `${path}/icon_level06.gif`,
    icon_level07: `${path}/icon_level07.gif`,
    icon_level08: `${path}/icon_level08.gif`,
    icon_level09: `${path}/icon_level09.gif`,
    icon_level10: `${path}/icon_level10.gif`,
    footer_logo: `${path}/main_logo_m.png`,
    icon_qna: `${path}/icon_qna.svg`,
    icon_event: `${path}/icon_event.svg`,
    icon_policy: `${path}/icon_policy.svg`,

    // only sky2
    sp_banner: `${path}/sp_banner.png`,

    // only play
    title_line_l: `${path}/title_line_l.png`,
    title_line_r: `${path}/title_line_r.png`,
    btn_h: `${path}/btn_h.png`,

    // only band, world, water, emart
    icon_user: `${path}/icon_user.png`,
    main_banner01: `${path}/main_banner01.png`,
    main_banner02: `${path}/main_banner02.png`,
    m_main_banner_casino: `${path}/m_main_banner_casino.png`,
    m_main_banner_slot: `${path}/m_main_banner_slot.png`,
    m_main_banner_hotel_casino: `${path}/m_main_banner_hotel_casino.png`,
    m_main_banner_minigame: `${path}/m_main_banner_minigame.png`,
    m_main_banner_sports: `${path}/m_main_banner_sports.png`,
    main_banner_casino_on: `${path}/main_banner_casino_on.png`,
    main_banner_slot_on: `${path}/main_banner_slot_on.png`,
    main_banner_hotel_casino_on: `${path}/main_banner_hotel_casino_on.png`,
    main_banner_minigame_on: `${path}/main_banner_minigame_on.png`,
    main_banner_sports_on: `${path}/main_banner_sports_on.png`,
    m_main_banner_casino_on: `${path}/m_main_banner_casino_on.png`,
    m_main_banner_slot_on: `${path}/m_main_banner_slot_on.png`,
    m_main_banner_hotel_casino_on: `${path}/m_main_banner_hotel_casino_on.png`,
    m_main_banner_minigame_on: `${path}/m_main_banner_minigame_on.png`,
    m_main_banner_sports_on: `${path}/m_main_banner_sports_on.png`,

    // only ace
    main_banner_top_bg: `${path}/main_banner_top_bg.png`,
    m_main_banner_reel: `${path}/m_main_banner_reel.png`,

    // only rokmc
    img_sample_main_banner01: `${path}/sample_main_banner01.png`,
    img_sample_main_banner01_m: `${path}/sample_main_banner01_m.png`,
    img_sample_main_banner02: `${path}/sample_main_banner02.png`,
    img_sample_main_banner02_m: `${path}/sample_main_banner02_m.png`,
    img_sample_main_banner03: `${path}/sample_main_banner03.png`,
    img_sample_main_banner03_m: `${path}/sample_main_banner03_m.png`,
    img_sample_main_banner04: `${path}/sample_main_banner04.png`,
    img_sample_main_banner04_m: `${path}/sample_main_banner04_m.png`,

    // only ninecasino
    img_game_title: `${path}/img_game_title.png`,
    img_game_title_m: `${path}/img_game_title_m.png`,
    banner_evolution: `${path}/banner_evolution.png`,
    banner_evolution_hover: `${path}/banner_evolution_hover.png`,
    banner_evolution_m: `${path}/banner_evolution_m.png`,
    banner_evolution_m_hover: `${path}/banner_evolution_m_hover.png`,
    title_evolution: `${path}/title_evolution.png`,
    title_best_slot: `${path}/title_best_slot.png`,
    title_all_slot: `${path}/title_all_slot.png`,
    title_casino: `${path}/title_casino.png`,

    // only shark
    main_banner_reel_pc: `${path}/main_banner_reel_pc.png`,

    // only gentlemen
    icon_chrome: `${path}/icon_chrome.png`,

    // only agc
    img_whatsapp: `${path}/img_whatsapp.png`
  },
}

export default channel
