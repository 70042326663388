import React, { useState } from 'react'
import * as _ from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getDetailOddsboxName, getOddsRates, getTeamName } from '~/game/st-util'
import { Schema$FilteredMarket, Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import { observer } from 'mobx-react'
import useRootStore from '~/store/useRootStore'
import { Theme } from '@mui/material/styles'
import DetailOddsBoxAtom from '../PrematchDetailOdds/DetailOddsBoxAtom'
import { SPORT_FLIGHT } from '~/store/sportsStore/sports-util'

interface OddsProps {
  theme: Theme
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  prematch: Schema$Prematch
  market: Schema$FilteredMarket
  isTop: boolean
  unifiedName: string // market name

  expand: boolean
  expanded: boolean
  onToggleExpanded?: () => void
}

function OddsBoxBaseline({
  theme,
  desktop,
  lang,
  flight,
  prematch,
  market,
  isTop,
  unifiedName,
  expand,
  expanded,
  onToggleExpanded,
}: OddsProps) {
  const { sportsStore } = useRootStore()
  const { cartItems } = sportsStore

  const { FixtureId } = prematch

  const handleExpanded = () => {
    if (expand && onToggleExpanded) {
      onToggleExpanded()
    }
  }

  const { baseLine, bets } = getOddsRates(unifiedName, market.BaseLine, market.Bets)
  const [a, b] = bets

  if (!a || !b || !baseLine) {
    return <></>
  }

  const base = baseLine.split(' ')[0]

  const homeName = desktop
    ? getTeamName(prematch, '1', lang)
    : getDetailOddsboxName(unifiedName, prematch, market.Id, a, lang)
  const awayName = desktop
    ? getTeamName(prematch, '2', lang)
    : getDetailOddsboxName(unifiedName, prematch, market.Id, b, lang)

  const foundCartItem = _.find(cartItems, o => {
    return (
      o.fixtureId === FixtureId &&
      o.marketId === market.Id &&
      o.bet.BaseLine === market.Bets[0].BaseLine
    )
  })
  const aSelected = foundCartItem && a?.Id === foundCartItem.bet.Id
  const bSelected = foundCartItem && b?.Id === foundCartItem.bet.Id

  const sxBaseline = isTop ? { border: '1px solid #182f49' } : null

  const height = '30px'
  const midWidth = 120
  const sideWidth = `calc(50% - ${midWidth * 0.5}px)`

  return (
    <Grid container justifyContent="space-between" columnSpacing={'3px'}>
      <Grid item sx={{ width: sideWidth, height: height }}>
        {a ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={homeName}
            unifiedName={unifiedName}
            atomName="a"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={a}
            selected={aSelected}
            drawSymbol={true}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
      <Grid item sx={{ width: `${midWidth}px`, height: height }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: '100%',
            ...sxBaseline,
          }}
        >
          <Grid container onClick={() => handleExpanded()}>
            <Grid item xs={3} display="flex" alignItems="center" justifyContent="center"></Grid>
            <Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
              <Typography sx={{ color: '#62E2ED', fontWeight: 'bold' }}>{base}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Box display="flex" alignItems="center" justifyContent="center">
                {expand ? (
                  <IconButton sx={{}} color="primary">
                    {expanded ? (
                      <ExpandLessIcon sx={{ color: 'white' }} />
                    ) : (
                      <ExpandMoreIcon sx={{ color: 'white' }} />
                    )}
                  </IconButton>
                ) : null}{' '}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item sx={{ width: sideWidth, height: height }}>
        {b ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={awayName}
            unifiedName={unifiedName}
            atomName="b"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={b}
            selected={bSelected}
            drawSymbol={true}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default observer(OddsBoxBaseline)
