/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Container, Divider, Grid, LinearProgress } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import {
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
} from '@utils/utils'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle } from '~/containers/shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import {
  Schema$PartnerChargeRecordTrackData,
  IPartnerChargeRecord,
  Schema$PartnerMemeberItem,
  Schema$PartnerConcurrentMemeberItem,
} from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { BET_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'
import { Schema$OpenTradingKind } from '~/v2/protocol/public'
import { getPositionPageName } from '~/game/position-map'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

function Head() {
  const topStyle = {}
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell sx={{ width: '120px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>아이디</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>닉네임</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '60px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>레벨</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>로그인 시간</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px', ...topStyle }} align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>게임중머니</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px', ...topStyle }} align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>머니</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px', ...topStyle }} align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>포인트</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px', ...topStyle }} align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>콤프</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '200px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>위치</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: {
  tradingKinds: Schema$OpenTradingKind[]
  row: Schema$PartnerConcurrentMemeberItem
  onClick: (uuid: string, field: string) => void
}) {
  const { tradingKinds, row, onClick } = props
  // const txtJoinDate = row.date_join ? moment(row.date_join).format('MM/DD') : '-'
  // const txtLoginDate = row.date_login ? moment(row.date_login).format('MM/DD') : '-'
  // let txtDate = `${txtJoinDate}, ${txtLoginDate}`
  let txtDate = moment(row.date_login).format('MM-DD HH:mm:ss')
  // console.log(row)
  const txtPosition = getPositionPageName(row.position, tradingKinds)

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <MyTableCell sx={{ width: '120px' }} align="center">
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                textDecorationLine: 'underline',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => onClick(row.uuid, 'detail')}
            >
              {row.userId}
            </Typography>
          </Box>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px' }} align="center">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              textDecorationLine: 'underline',
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onClick(row.uuid, 'detail')}
          >
            {row.nick}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '60px' }} align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.level}</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px' }} align="center">
          <Typography sx={{ fontSize: '12x' }}>{txtDate}</Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="right">
          <Typography sx={{ fontSize: '12x', color: '#bb2d3b' }}>
            {numberWithCommas(Math.floor(row.game_money))}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.money))}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.point))}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(Math.floor(row.comp))}</Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="center">
          <Typography sx={{ fontSize: '12x' }}>{txtPosition}</Typography>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

let timer: NodeJS.Timeout = null

function PartnerIntergratedMembersConcurrent({ location }) {
  const query = queryString.parse(location.search)

  const [items, setItems] = useState<Schema$PartnerConcurrentMemeberItem[]>([])
  const [loading, setLoading] = useState(false)

  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { option } = globalStore
  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups, open_trading_kinds } = option || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  const fetch = async () => {
    setLoading(true)

    try {
      const { items } = await apiPartner.getConcurrentMembers({})
      setItems(items)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-MEMBERS-CONCURRENT')
      fetch()
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.members_concurrent)
    fetch()

    timer = setTimeout(() => {
      fetch()
    }, 10000)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)

    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  })

  const onClickCell = (uuid: string, field: string) => {
    const found = _.find(items, o => {
      return o.uuid === uuid
    })
    if (found) {
      switch (field) {
        case 'account':
          break
        case 'detail':
          const now = new Date()
          popupPartnerMemberWithPeriod(found.uuid, 'detail', now, now)
          break
      }
    }
  }

  const rows = []
  for (const row of items) {
    rows.push(
      <Row tradingKinds={open_trading_kinds} key={row.uuid} row={row} onClick={onClickCell}></Row>,
    )
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1248px',
          maxWidth: '1248px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>
              {intl.formatMessage({ id: 'menu.partner.members.concurrent' })}
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
              <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
              <Table size="small" aria-label="collapsible table">
                {Head()}
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sx={{ marginTop: '14px' }}>
            <Typography sx={{ width: '100%', mt: 1, color: 'red', textAlign: 'right' }}>
              현재접속자 정보는 10초에 한번씩 갱신되므로 실제 정보와 차이가 있을 수 있습니다. 참고
              용도로 봐 주세요.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedMembersConcurrent)
