import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import {
  Stack,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4'
import GradeIcon from '@mui/icons-material/Grade'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PaidIcon from '@mui/icons-material/Paid'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

import { numberWithCommas } from '@utils/utils'
import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps, TabPanel } from '@shared/Common'
import PanelCommissionSetting from './PanelCommissionSetting'
import apiPartner from '@services/api/partner'
import { Schema$PartnerMemeberItem, Schema$PartnerTransferSummary } from '@protocol/partner'
import { MERCHANT_CLASS, USER_STATE } from '~/v2/interface/config'
import 'react-tabs/style/react-tabs.css'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import PanelDetail from './PanelDetail'
import PanelBettingRecord from './PanelBettingRecord'
import { useIntl } from 'react-intl'

interface Props {
  memberInfo: Schema$PartnerMemeberItem
}

function ComponentMemberInfo({ memberInfo }: Props) {
  const intl = useIntl()
  const [transferSummary, setTransferSummary] = useState<Schema$PartnerTransferSummary | null>(null)
  const [superMemberInfo, setSuperMemberInfo] = useState<Schema$PartnerMemeberItem | null>(null)
  const { userStore, globalStore } = useRootStore()
  const { option } = globalStore
  const { privacy, partner_option } = option || {}
  const { userInfo } = userStore
  const { is_disclose_phone, is_disclose_name } = privacy || {}
  const { hide_super_name } = partner_option || {}

  useEffect(() => {
    // fetch user data
    async function inline() {
      try {
        const { summary } = await apiPartner.getMemberTransferSummary({ uuid: memberInfo.uuid })
        setTransferSummary(summary)

        const { item: superMember } = await apiPartner.getMember({ uuid: memberInfo.merchant_uuid })
        setSuperMemberInfo(superMember)
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }
    }
    inline()
  }, [])

  let className = ''
  let accountName = ''
  let stateName = ''
  let money = 0
  let point = 0
  let comp = 0
  let totalDeposit = 0
  let totalWithdraw = 0
  let inoutOffset = 0
  if (memberInfo) {
    className = MERCHANT_CLASS[memberInfo.class]
    accountName = `${memberInfo.userId} / ${memberInfo.nick}`
    stateName = _.find(USER_STATE, o => {
      return o.id === memberInfo.state
    })?.name
    money = memberInfo.money
    point = memberInfo.point
    comp = memberInfo.comp
  }

  if (transferSummary) {
    totalDeposit = transferSummary.deposit
    totalWithdraw = transferSummary.withdraw
    inoutOffset = totalDeposit - totalWithdraw
  }

  let superAccountName = ''
  if (superMemberInfo) {
    superAccountName = `${superMemberInfo.userId} / ${superMemberInfo.nick}`
  }

  const privacyInfo = []

  if (userInfo && memberInfo) {
    let memberName = ''
    let memberPhone = ''

    if (is_disclose_name) {
      if (memberInfo.name) {
        memberName = memberInfo.name
      }
    } else {
      memberName = '비공개'
    }
    if (is_disclose_phone) {
      if (memberInfo.phone) {
        memberPhone = memberInfo.phone
      }
    } else {
      memberPhone = '비공개'
    }

    //
    privacyInfo.push(
      <Grid item xs={1.5}>
        <Stack direction="row" spacing={1}>
          <SignalWifiStatusbarConnectedNoInternet4Icon />
          <Typography sx={{ fontSize: '14px' }}>이름</Typography>
        </Stack>
      </Grid>,
    )

    privacyInfo.push(
      <Grid item xs={2.5}>
        <Typography sx={{ fontSize: '14px' }}>{memberName}</Typography>
      </Grid>,
    )

    privacyInfo.push(
      <Grid item xs={1.5}>
        <Stack direction="row" spacing={1}>
          <SignalWifiStatusbarConnectedNoInternet4Icon />
          <Typography sx={{ fontSize: '14px' }}>전화번호</Typography>
        </Stack>
      </Grid>,
    )

    privacyInfo.push(
      <Grid item xs={2.5}>
        <Typography sx={{ fontSize: '14px' }}>{memberPhone}</Typography>
      </Grid>,
    )
  }

  return (
    <Card sx={{ marginTop: '10px' }}>
      <CardHeader
        sx={{ background: '#007f93' }}
        subheader={
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2}>
                <PersonIcon fontSize="large" sx={{ color: 'white' }}></PersonIcon>
                <Typography sx={{ fontSize: '19px', color: 'white' }}>[{className}]</Typography>
                <Typography sx={{ color: 'white', fontSize: '19px' }}>{accountName}</Typography>
              </Stack>
            </Grid>
          </Grid>
        }
      ></CardHeader>
      <CardContent sx={{ paddingBottom: '16px !important' }}>
        <Grid container spacing={1}>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <SignalWifiStatusbarConnectedNoInternet4Icon />
              <Typography sx={{ fontSize: '14px' }}>상태</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{stateName}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <GradeIcon />
              <Typography sx={{ fontSize: '14px' }}>등급</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{className}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            {!hide_super_name && (
              <Stack direction="row" spacing={1}>
                <PersonAddIcon />
                <Typography sx={{ fontSize: '14px' }}>상위</Typography>
              </Stack>
            )}
          </Grid>
          <Grid item xs={2.5}>
            {!hide_super_name && (
              <Typography sx={{ fontSize: '14px' }}>{superAccountName}</Typography>
            )}
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <PaidIcon />
              <Typography sx={{ fontSize: '14px' }}>머니</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{numberWithCommas(Math.floor(money))}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <ControlPointIcon />
              <Typography sx={{ fontSize: '14px' }}>포인트</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{numberWithCommas(Math.floor(point))}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <ControlPointIcon />
              <Typography sx={{ fontSize: '14px' }}>콤프</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{numberWithCommas(Math.floor(comp))}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <LoginIcon />
              <Typography sx={{ fontSize: '14px' }}>총 입금액</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{numberWithCommas(totalDeposit)}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <LogoutIcon />
              <Typography sx={{ fontSize: '14px' }}>총 출금액</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{numberWithCommas(totalWithdraw)}</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Stack direction="row" spacing={1}>
              <OpenInFullIcon />
              <Typography sx={{ fontSize: '14px' }}>입출금 차액</Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Typography sx={{ fontSize: '14px' }}>{numberWithCommas(inoutOffset)}</Typography>
          </Grid>
          {privacyInfo}
        </Grid>
      </CardContent>
    </Card>
  )
}

export default observer(ComponentMemberInfo)
