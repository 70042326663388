/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Container, Divider, Grid, MenuItem, Pagination, Tab, Tabs, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import {
  getGainLossColor,
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
} from '@utils/utils'
import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps, PageStyle } from '~/containers/shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import {
  Schema$PartnerMemeberItem,
  Schema$PartnerBettingRecordTrackData,
  IPartnerBettingRecord,
  Schema$PartnerUserConfig,
} from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { default as apiUser } from '@services/api/user'
import { BET_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import {
  ICouponConfig,
  ICouponList,
  IIssueCoupon,
  IRecallCoupon,
  Schema$CouponConfigItem,
  Schema$CouponTrackData,
} from '~/v2/protocol/user'
import ComponentCouponIssue from './ComponentCouponIssue'

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  coupon_id: number
  coupon_name: string
  issuer_uuid: string
  amount: number
  expire_at: string
  used_at: string
  state: string
  request_state: string
  method: string
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>쿠폰이름</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>금액</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>만료일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>사용일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>사용방식</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>요청상태</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>상태</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function RowSum(props: { sum: number }) {
  const { sum } = props

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', background: '#cfe2f3' } }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>합계</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(sum)}</Typography>
        </MyTableCell>
        <MyTableCell align="center"></MyTableCell>
        <MyTableCell align="center"></MyTableCell>
        <MyTableCell align="center"></MyTableCell>
        <MyTableCell align="center"></MyTableCell>
        <MyTableCell align="center"></MyTableCell>
      </TableRow>
    </Fragment>
  )
}

function Row(props: { my_uuid: string; row: RowData; onClickRecall: (coupon_id: number) => void }) {
  const intl = useIntl()
  const { my_uuid, row, onClickRecall } = props

  const txt_expire = row.expire_at ? moment(new Date(row.expire_at)).format('YYYY-MM-DD') : null
  const txt_used_at = row.used_at ? moment(new Date(row.used_at)).format('YYYY-MM-DD') : null
  let txt_method = ''
  switch (row.method) {
    case 'CONFIRMATION':
      txt_method = '승인후 사용'
      break
    case 'IMMEDIATELY':
      txt_method = '즉시 사용'
      break
  }
  let inRecallState = false
  let requestState = null
  switch (row.request_state) {
    case 'REQUEST':
      requestState = (
        <Typography sx={{ fontSize: '12px', color: '#FBC342' }}>
          {intl.formatMessage({ id: 'coupon.REQUEST' })}
        </Typography>
      )
      break
    case 'REJECT':
      requestState = (
        <Typography sx={{ fontSize: '12px', color: '#FE3D3D' }}>
          {intl.formatMessage({ id: 'coupon.REJECT' })}
        </Typography>
      )
      break
    case 'DONE':
      requestState = (
        <Typography sx={{ fontSize: '12px', color: '#2A73E1' }}>
          {intl.formatMessage({ id: 'coupon.DONE' })}
        </Typography>
      )
      break
    case 'NORMAL':
      break
  }
  let couponState = null
  switch (row.state) {
    case 'NORMAL':
      inRecallState = true
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#000000' }}>
          {intl.formatMessage({ id: 'coupon.NORMAL' })}
        </Typography>
      )
      break
    case 'USED':
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#2A73E1' }}>
          {intl.formatMessage({ id: 'coupon.USED' })}
        </Typography>
      )
      break
    case 'EXPIRED':
      inRecallState = true
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#FE3D3D' }}>
          {intl.formatMessage({ id: 'coupon.EXPIRED' })}
        </Typography>
      )
      break
    case 'RECALLED':
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#FE3D3D' }}>
          {intl.formatMessage({ id: 'coupon.RECALLED' })}
        </Typography>
      )
      break
  }

  const canRecall = inRecallState && row.issuer_uuid === my_uuid

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.coupon_name}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.amount)}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}>{txt_expire}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}>{txt_used_at}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{txt_method}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{requestState}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          {canRecall ? (
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    fontSize: '12x',
                  }}
                >
                  {couponState}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    pl: '8px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    fontSize: '12x',
                    color: 'red',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => onClickRecall(row.coupon_id)}
                >
                  [회수]
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography sx={{ fontSize: '12x' }}>{couponState}</Typography>
          )}
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

type TrackData = Schema$CouponTrackData
const ItemPerPage = 15

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const SEARCH_KEY_NAMES = [
  { id: 'ALL', name: '전체' },
  { id: 'NORMAL', name: '사용가능' },
  { id: 'USED', name: '사용완료' },
  { id: 'EXPIRED', name: '기간만료' },
  { id: 'RECALLED', name: '회수' },
]

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

interface Props {
  memberInfo: Schema$PartnerMemeberItem
  location: any
}

function PanelCoupon({ memberInfo, location }: Props) {
  const query = queryString.parse(location.search)
  const { uuid, main, filter, tab, page, ts } = query

  const intl = useIntl()
  const [tsIssue, setTsIssue] = useState('')
  const [timestamp, setTimestamp] = useState('')
  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)
  const [partnerUserConfig, setPartnerUserConfig] = useState<Schema$PartnerUserConfig | null>(null)
  const [selectedCouponConfig, setSelectedCouponConfig] = useState<Schema$CouponConfigItem | null>(
    null,
  )
  const [searchFilter, setSearchFilter] = useState('ALL')

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { uuid: my_uuid, isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { is_active_issue_coupon } = partnerUserConfig || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  const startPage = page ? Number(page) : 1

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  if (timestamp !== (ts as string)) {
    setTimestamp(ts as string)
  }

  const fetchPartnerUserConfig = async () => {
    try {
      const { config } = await apiPartner.getUserConfig({})
      setPartnerUserConfig(config)
    } catch (err) {}
  }

  const fetchCouponList = async (params: ICouponList.Params) => {
    setLoading(true)

    try {
      const { data } = await apiUser.couponList(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  const fetchRecall = async (params: IRecallCoupon.Params) => {
    try {
      await apiUser.recallCoupon(params)

      await fetchCouponList({
        target_uuid: memberInfo.uuid,
        coupon_state: searchFilter,
        offset: 0,
        limit: ItemPerPage,
      })

      setTsIssue(new Date().getTime().toString())
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }

    setSelectedCouponConfig(null)
    setLoading(false)
  }

  const onIssued = () => {
    const fetchIssuedCoupon = async () => {
      try {
        await fetchCouponList({
          target_uuid: memberInfo.uuid,
          coupon_state: searchFilter,
          offset: 0,
          limit: ItemPerPage,
        })
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }

      setSelectedCouponConfig(null)
      setLoading(false)
    }
    fetchIssuedCoupon()
  }

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.partner_coupon)
    setSearchFilter(_.isString(filter) ? filter : 'ALL')
    fetchPartnerUserConfig()
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  useEffect(() => {
    const newFilter = _.isString(filter) ? filter : 'ALL'
    setSearchFilter(newFilter)
    const params: ICouponList.Params = {
      target_uuid: memberInfo.uuid,
      coupon_state: newFilter,
      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetchCouponList(params)
  }, [timestamp])

  const pushNewUrl = (_tab: number, _page: number) => {
    let q = `uuid=${uuid}&main=coupon&ts=${new Date().getTime()}&page=${_page}&filter=${searchFilter}`
    let encoded = encodeURI(q)
    history.push(`/partner/member/search?${encoded}`)
  }

  const handleChangeSearchFilter = event => {
    setSearchFilter(event.target.value)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    pushNewUrl(tabPage.tab, value)
  }

  const onClickSearch = () => {
    pushNewUrl(tabPage.tab, 1)
  }

  const onClickRecall = (coupon_id: number) => {
    if (track) {
      const coupon = _.find(track.items, o => {
        return o.coupon_id === coupon_id
      })

      if (coupon) {
        globalStore.pushDialogLocaleYesNo({
          text: 'msg.format.retrieve_coupon',
          arg1: coupon.coupon_name,
          arg2: numberWithCommas(coupon.amount),
          callbackPositive: async () => {
            fetchRecall({ coupon_id: coupon_id })
          },
        })
      }
    }
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const rows = []
  if (track) {
    rows.push(<RowSum sum={track.sum}></RowSum>)
    for (const el of track.items) {
      const row: RowData = {
        coupon_id: el.coupon_id,
        coupon_name: el.coupon_name,
        issuer_uuid: el.issuer_uuid,
        amount: el.amount,
        expire_at: el.expire_at,
        used_at: el.used_at,
        state: el.state,
        request_state: el.request_state,
        method: el.method,
      }

      rows.push(
        <Row my_uuid={my_uuid} key={row.coupon_id} row={row} onClickRecall={onClickRecall}></Row>,
      )
    }

    pageData = getPageData(track)
  }

  const filterIdItems = []
  for (const el of SEARCH_KEY_NAMES) {
    filterIdItems.push(<MenuItem value={el.id}>{el.name}</MenuItem>)
  }

  const innerContents = (
    <Container
      fixed
      style={{
        minWidth: '1300px',
        maxWidth: '1300px',
        margin: 0,
        padding: 0,
      }}
    >
      {is_active_issue_coupon ? (
        <ComponentCouponIssue
          memberInfo={memberInfo}
          ts={tsIssue}
          onIssued={() => onIssued()}
        ></ComponentCouponIssue>
      ) : null}

      <Grid container sx={{ marginTop: '10px' }}>
        <Grid item xs={12}>
          <Box sx={{ marginTop: '14px' }}>
            <Stack direction="row-reverse" spacing={1}>
              <Button
                variant="contained"
                sx={{ background: '#007f93' }}
                onClick={() => onClickSearch()}
              >
                검색
              </Button>
              <TextField
                value={searchFilter}
                onChange={handleChangeSearchFilter}
                select
                size="small"
                variant={'outlined'}
                label="검색필터"
                style={{ width: '100px' }}
              >
                {filterIdItems}
              </TextField>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
          <Table size="small" aria-label="collapsible table">
            {Head()}
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <PageStyle
            count={pageData.totalPage}
            variant="outlined"
            page={pageData.currPage}
            onChange={handlePagingChange}
          />
        </Box>
      </Grid>
    </Container>
  )

  let contents = null
  if (desktop) {
    contents = innerContents
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PanelCoupon)
