/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import {
  Dialog,
  AppBar,
  IconButton,
  Tab,
  Tabs,
  Grid,
  Box,
  Typography,
  Button,
  Checkbox,
  Chip,
} from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import { BasicTabs, CheckboxStyle } from '@styles/base.style'
import { useIntl } from 'react-intl'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import MessageView from './MessageView'
import { ModalKeys } from '@store/globalStore'
import { MobileModalStyle } from '@styles/modal.style'
import apiUser from '@services/api/user'
import { Schema$MessageTrackData, IMessages, ISetMessageRead } from '~/v2/protocol/user'

// css
import { PaginationStyle } from '@styles/base.style'

// icon
import CloseIcon from '@mui/icons-material/Close'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: '16px',
  backgroundColor: '#080F1C',
  borderRadius: '4px',
  overflow: 'hidden',
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  padding: 0,
  backgroundColor: '#16202F',
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiChip-root': {
    marginRight: '26px',
    height: '20px',
    color: '#FFF',
  },
  '& .title': {
    color: '#D1D9E3',
  },
  '& .date': {
    color: '#747D8A',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
}))

// 탭 관련 ----------
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
// ----------

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected } = props

  return (
    <Box padding="checkbox">
      <Checkbox
        css={CheckboxStyle}
        color="primary"
        indeterminate={numSelected > 0}
        checked={numSelected > 0}
        onChange={onSelectAllClick}
        inputProps={{
          'aria-label': 'select all desserts',
        }}
        sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
      />
    </Box>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
}

const EnhancedTableToolbar = props => {
  const intl = useIntl()
  const { numSelected, size, onDelete } = props

  return (
    <>
      {numSelected > 0 ? (
        <>
          <Typography
            sx={{ py: 1, flex: '1 1 0', fontSize: '1.2rem' }}
            color="#fff"
            component="div"
          >
            {intl.formatMessage({ id: 'component.check-all' })} ({numSelected}/{size})
          </Typography>
          <Button onClick={() => onDelete()} sx={{ color: '#747D8A', fontSize: '1.2rem' }}>
            {intl.formatMessage({ id: 'component.delete-check' })}
          </Button>
        </>
      ) : (
        <Typography sx={{ py: 1, flex: '1 1 0', fontSize: '1.2rem' }} color="#fff" component="div">
          {intl.formatMessage({ id: 'component.check-all' })} (0/{size})
        </Typography>
      )}
    </>
  )
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
}

interface PageData {
  currPage: number
  totalPage: number
}

type MessageTrackData = Schema$MessageTrackData

const getPageData = (track: MessageTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

const PopupMessage: React.FC<{}> = () => {
  const intl = useIntl()

  const [track, setTrack] = useState<MessageTrackData>(null)
  const [expanded, setExpanded] = useState<string>('')
  const { globalStore, authStore } = useRootStore()
  const [selected, setSelected] = useState([])
  const [tab, setTab] = useState(0)
  const { currentKey, currentValue } = globalStore
  const { initialized, signedin } = authStore

  const modalKey = ModalKeys.message
  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex > -1
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin && initialized) {
    globalStore.showModal('login', 0)
  }

  const fetch = async (params: IMessages.Params) => {
    try {
      setExpanded('')
      setSelected([])

      const { data } = await apiUser.getMessages(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    fetch({ offset: 0, limit: ItemPerPage, is_all: tab === 0 })
  }, [tab])

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : '')
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ offset, limit: ItemPerPage, is_all: tab === 0 })
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const onClickDelete = () => {
    async function test() {
      try {
        await apiUser.deleteMessages({ ids: selected })
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }

      fetch({ offset: 0, limit: ItemPerPage, is_all: tab === 0 })
    }
    test()
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = track.items!.map(el => el.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }

    setSelected(newSelected)
  }

  const isSelected = id => selected.indexOf(id) !== -1

  let rowCount = 0
  let read_required_count = 0

  const listItems = []
  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    rowCount = track.items.length
    read_required_count = track.read_required_count

    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    for (const el of track.items) {
      const title = el.is_read_required ? `[중요] ${el.title}` : el.title
      const state = el.read ? '읽음' : '안읽음'
      const color = el.read ? '#FE3D3D' : '#2A73E1'
      const created_at = moment(el.created_at).format('YYYY-MM-DD HH:mm')
      const isItemSelected = isSelected(el.id)
      const labelId = `enhanced-table-checkbox-${el.id}`
      const isExpanded = expanded === `pannel-${el.id}`

      if (isExpanded && !el.read) {
        el.read = true
        apiUser.setMessageRead({ id: el.id })
      }

      listItems.push(
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          key={el.id}
          expanded={isExpanded}
          onChange={handleChange(`pannel-${el.id}`)}
        >
          <AccordionSummary
            id={`panel-${el.id}-header`}
            aria-label="Expand"
            aria-controls={`panel-${el.id}-content`}
          >
            <Grid container sx={{ py: 1, pl: 1, pr: 2, alignItems: 'center' }}>
              <Grid item xs={1.5}>
                <Box
                  onClick={(event: any) => handleClick(event, el.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={el.id}
                >
                  <Checkbox
                    css={CheckboxStyle}
                    checked={isItemSelected}
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                  />
                </Box>
              </Grid>
              <Grid item xs={10.5} sx={{ pl: 1 }}>
                <Box
                  sx={{
                    mb: 1 / 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Chip label={state} size="small" sx={{ backgroundColor: color }} />
                  <span className="date">{created_at}</span>
                </Box>
                <Box className="title" sx={{ textAlign: 'start' }}>
                  {title}
                </Box>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <MessageView content={el.content} />
          </AccordionDetails>
        </Accordion>,
      )
    }
  }

  let alertContents = null
  if (read_required_count > 0) {
    alertContents = (
      <Typography
        sx={{
          marginTop: '10px',
          fontSize: '15px',
          width: '100%',
          textAlign: 'center',
          color: '#fe3d3d',
          fontWeight: 'bold',
        }}
      >
        읽지 않은 중요 쪽지가 있습니다.
        <br />
        원활한 플레이를 위해 중요한 쪽지는 모두 읽어주세요.
      </Typography>
    )
  }

  return (
    <Dialog open={visible} onClose={onClose} css={MobileModalStyle}>
      <Box className="modal_content">
        <AppBar sx={{ position: 'relative' }}>
          <Typography className="modal_title" variant="h6" component="div">
            {intl.formatMessage({ id: 'component.message' })}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Box>
          <Tabs value={tab} onChange={handleTabChange} css={BasicTabs} variant="fullWidth">
            <Tab
              className="basic_tab"
              label={intl.formatMessage({ id: 'component.message.all' })}
              {...a11yProps(0)}
            />
            <Tab
              className="basic_tab"
              label={intl.formatMessage({ id: 'component.message.unread' })}
              {...a11yProps(1)}
            />
          </Tabs>

          {alertContents}

          <Box sx={{ px: 2, mt: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
              />
              <EnhancedTableToolbar
                numSelected={selected.length}
                size={rowCount}
                onDelete={onClickDelete}
              />
            </Box>
            {listItems}
            <PaginationStyle
              count={totalTrackPage}
              variant="outlined"
              page={currTrackPage}
              onChange={handlePagingChange}
            />
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default observer(PopupMessage)
