export const SpoLiveProtocol = {
  // (client <-> server) sync operation: request and response
  SOMETHING: 10000,
  ENTER: 10001,
  ENTER_ADMIN: 10001,
  ENTER_SPACE: 10002,
  RELOAD_SPACE: 10003,
  LEAVE_SPACE: 10004,
  AUTH: 10005,
  WATCH: 10006,
  SYNC_LEAGUE_METAS: 10007,
  UPDATE_MAJOR_LEAGUES: 10008,

  // (server -> client) async trnasfer from server(notifying)
  NOTIFY_SOMETHING: 20000,

  NOTIFY_SPORTS_OPTIONS: 20060,
  NOTIFY_PREMATCHES: 20061,
  NOTIFY_MARKETS: 20062,
  NOTIFY_PREMATCH: 20063,
  NOTIFY_MARKET: 20064,
  NOTIFY_METAS: 20065,
  NOTIFY_AHEAD_MARKETS: 20066,
  NOTIFY_BEHIND_MARKETS: 20067,

  // alarm
  NOTIFY_LEAGUE_METAS_UPDATED: 21001,

  // (client -> server) protocols doesn't need to be responded by server
  REPORT_SOMETHING: 30000,
}
