import Phaser from 'phaser'
import LoaderScene from '../scenes/loader'

export default class EntryLoaderScene extends LoaderScene {
  public preload() {
    super.preload()
  }

  public create() {
    super.create()
  }
}
