import * as _ from 'lodash'
import axios, { AxiosRequestConfig as _AxiosRequestConfig, Method } from 'axios'
import normalizeHeaderName from 'axios/lib/helpers/normalizeHeaderName'
import utils from 'axios/lib/utils'
import JSONbig from 'json-bigint'
import { isObject } from 'lodash'
import { globalStore } from '~/store'

const JSONbigNative = JSONbig({ useNativeBigInt: true })

const setContentTypeIfUnset = (headers, value) => {
  if (!utils.isUndefined(headers) && utils.isUndefined(headers['Content-Type'])) {
    headers['Content-Type'] = value
  }
}

export interface AxiosRequestConfig extends _AxiosRequestConfig {
  startTime?: Date
}

export interface HttpResquest {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get?(url: string, data: object, baseUrl?: string): Promise<any>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  post?(url: string, data: object, baseUrl?: string): Promise<any>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete?(url: string, data: object, baseUrl?: string): Promise<any>

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  put?(url: string, data: object, baseUrl?: string): Promise<any>
}

enum HTTP_ERROR {
  LOGIC_ERROR,
  TIMEOUT_ERROR,
  NETWORK_ERROR,
  SERVICE_ERROR,
}

class CustomError extends Error {
  type: HTTP_ERROR

  config: _AxiosRequestConfig

  reload: boolean

  constructor(message: string, type: HTTP_ERROR, config: _AxiosRequestConfig, reload: boolean) {
    super(message)
    this.type = type
    this.config = config
    this.reload = reload
  }
}

const TOKEN_ERROR = [401, 402, 403]

const DEFAULT_CONFIG = {
  xsrfCookieName: '_csrf',
  xsrfHeaderName: 'x-csrf-token',
  baseURL: process.env.REACT_APP_API_BASEURL || 'https://localhost',
  transformRequest: [
    (data, headers) => {
      normalizeHeaderName(headers, 'Accept')
      normalizeHeaderName(headers, 'Content-Type')
      if (
        utils.isFormData(data) ||
        utils.isArrayBuffer(data) ||
        utils.isBuffer(data) ||
        utils.isStream(data) ||
        utils.isFile(data) ||
        utils.isBlob(data)
      ) {
        return data
      }
      if (utils.isArrayBufferView(data)) {
        return data.buffer
      }
      if (utils.isURLSearchParams(data)) {
        setContentTypeIfUnset(headers, 'application/x-www-form-urlencoded;charset=utf-8')
        return data.toString()
      }
      if (utils.isObject(data)) {
        setContentTypeIfUnset(headers, 'application/json;charset=utf-8')
        // return JSONbigNative.stringify(data)
        return JSON.stringify(data)
      }
      if (data === undefined) {
        setContentTypeIfUnset(headers, 'application/json;charset=utf-8')
      }
      return data
    },
  ],
  transformResponse: [
    data => {
      if (typeof data === 'string') {
        try {
          // data = JSONbigNative.parse(data)
          data = JSON.parse(data)
        } catch (e) {
          /* Ignore */
        } // Added this Ignore as it's the same in the Axios
      }
      return data
    },
  ],
}

const http: HttpResquest = {}
const methods: string[] = ['get', 'post', 'put', 'delete']

let authTimer: NodeJS.Timer | null = null

// const isSuccess = res => res.errCode === 0
const isSuccess = (res: any) => isObject(res)
// const resFormat = res => res.response || res.data || {}

methods.forEach(v => {
  http[v] = async (url: string, data: object, baseUrl?: string) => {
    let validBaseUrl = baseUrl || DEFAULT_CONFIG.baseURL
    const env = process.env.NODE_ENV
    if (env === 'production') {
      validBaseUrl = window.location.origin
    }

    const axiosConfig: AxiosRequestConfig = {}
    _.extend(axiosConfig, DEFAULT_CONFIG)
    axiosConfig.method = v as Method
    axiosConfig.url = url
    axiosConfig.baseURL = validBaseUrl
    axiosConfig.withCredentials = true

    // const axiosConfig: AxiosRequestConfig = {
    //   method: v as Method,
    //   url,
    //   baseURL: validBaseUrl,
    //   // headers: { Authorization: `Bearer ${userInfo.token}` }
    //   withCredentials: true,
    // }

    const instance = axios.create(axiosConfig)

    if (v === 'post') {
      const { token } = await http.get('api/public/token', {})
      instance.defaults.headers.post['csrf-token'] = token
    }

    // Add a request interceptor
    instance.interceptors.request.use(
      cfg => {
        cfg.params = { ...cfg.params, ts: Date.now() / 1000 }
        return cfg
      },
      error => Promise.reject(error),
    )

    instance.interceptors.response.use(
      response => {
        let rdata = response.data

        // RETURN 데이터가 하나도 없을때.
        if (!isSuccess(rdata)) {
          if (_.isString(rdata)) {
            try {
              rdata = JSON.parse(rdata)
            } catch (err) {
              return Promise.reject(
                new CustomError('data not found!', HTTP_ERROR.LOGIC_ERROR, response.config, false),
              )
            }
          } else {
            return Promise.reject(
              new CustomError('data not found!', HTTP_ERROR.LOGIC_ERROR, response.config, false),
            )
          }
        }

        if (rdata.errcode != null) {
          console.error(`error url : ${url} , errcode : ${rdata.errcode}`)
        }

        if (rdata.error) {
          throw rdata
        }
        return Promise.resolve(rdata)
      },
      error => {
        if (error.response && TOKEN_ERROR.includes(error.response.status)) {
          // Message.destroy()
          // Message.error('Authentication failure, Please relogin!')
          clearTimeout(authTimer)
          authTimer = setTimeout(() => {
            // window.location.replace('/login')
          }, 300)
          throw new CustomError(
            '다시 시도해주세요.',
            /^timeout of/.test(error.message) ? HTTP_ERROR.TIMEOUT_ERROR : HTTP_ERROR.NETWORK_ERROR,
            error.config,
            true,
          )
        }
        const msg = error.response ? error.response.statusText : error.message || 'network error'
        throw new CustomError(
          msg,
          /^timeout of/.test(error.message) ? HTTP_ERROR.TIMEOUT_ERROR : HTTP_ERROR.NETWORK_ERROR,
          error.config,
          false,
        )
      },
    )
    if (v === 'get') {
      axiosConfig.params = data
    } else if (data instanceof FormData) {
      axiosConfig.data = data
    } else {
      axiosConfig.data = data
    }
    axiosConfig.startTime = new Date()

    // console.log(`${v} : ${validBaseUrl}/${url}`)

    // const profiler = url === 'game/cross' ? new Profiler() : null
    // if (profiler) {
    //   profiler.begin('inline')
    // }

    try {
      const res = await instance.request(axiosConfig)
      return res
    } catch (err) {
      console.log(err)
      console.log(err.message)
      return Promise.reject(axiosConfig.url.includes('autoScript.set') ? { err } : err)
    }

    // return instance
    //   .request(axiosConfig)
    //   .then(res => {
    //     // if (profiler) {
    //     //   profiler.end()
    //     // }
    //     return res
    //   })
    //   .catch(err => {
    //     // Message.destroy()
    //     // Message.error(err.response || err.message || err.stack || 'unknown error')
    //     console.log(err)
    //     console.log(err.message)
    //     return Promise.reject(axiosConfig.url.includes('autoScript.set') ? { err } : err)
    //   })
  }
})

export default http
