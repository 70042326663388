/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Container, Divider, Grid, MenuItem, Pagination, Tab, Tabs, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import {
  getGainLossColor,
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
} from '@utils/utils'
import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps, PageStyle } from '~/containers/shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import {
  Schema$PartnerMemeberItem,
  Schema$PartnerBettingRecordTrackData,
  IPartnerBettingRecord,
} from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { default as apiUser } from '@services/api/user'
import { BET_STATE, COUPON_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import {
  ICouponConfig,
  IIssuerCouponList,
  IRecallCoupon,
  Schema$CouponTrackData,
} from '~/v2/protocol/user'
import { useIntl } from 'react-intl'

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  coupon_id: number
  coupon_name: string
  user_id: string
  nickname: string
  issuer_uuid: string
  amount: number
  issued_at: string
  expire_at: string
  used_at: string
  state: string
  request_state: string
  method: string
  name: string
}

function Head(is_disclose_name: boolean) {
  let name = is_disclose_name ? '이름' : ''

  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>쿠폰이름</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>아이디</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>닉네임</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>{name}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>금액</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>발행일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>만료일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>사용일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>사용방식</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>요청상태</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>상태</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function RowHead(props: { sum: number }) {
  const { sum } = props

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset', background: '#CFE2F3' } }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>합계</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}></Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(sum)}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}></Typography>
        </MyTableCell>
        <MyTableCell align="center"></MyTableCell>
      </TableRow>
    </Fragment>
  )
}

function Row(props: { my_uuid: string; row: RowData; onClickRecall: (coupon_id: number) => void }) {
  const intl = useIntl()
  const { my_uuid, row, onClickRecall } = props

  const txt_issued_at = row.issued_at ? moment(new Date(row.issued_at)).format('YYYY-MM-DD') : null
  const txt_expire = row.expire_at ? moment(new Date(row.expire_at)).format('YYYY-MM-DD') : null
  const txt_used_at = row.used_at ? moment(new Date(row.used_at)).format('YYYY-MM-DD') : null
  let txt_method = ''
  switch (row.method) {
    case 'CONFIRMATION':
      txt_method = '승인후 사용'
      break
    case 'IMMEDIATELY':
      txt_method = '즉시 사용'
      break
  }
  let inRecallState = false
  let requestState = null
  let couponState = null
  switch (row.request_state) {
    case 'REQUEST':
      requestState = (
        <Typography sx={{ fontSize: '12px', color: '#FBC342' }}>
          {intl.formatMessage({ id: 'coupon.REQUEST' })}
        </Typography>
      )
      break
    case 'REJECT':
      requestState = (
        <Typography sx={{ fontSize: '12px', color: '#FE3D3D' }}>
          {intl.formatMessage({ id: 'coupon.REJECT' })}
        </Typography>
      )
      break
    case 'DONE':
      requestState = (
        <Typography sx={{ fontSize: '12px', color: '#2A73E1' }}>
          {intl.formatMessage({ id: 'coupon.DONE' })}
        </Typography>
      )
      break
    case 'NORMAL':
      break
  }
  switch (row.state) {
    case 'NORMAL':
      inRecallState = true
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#000000' }}>
          {intl.formatMessage({ id: 'coupon.NORMAL' })}
        </Typography>
      )
      break
    case 'USED':
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#2A73E1' }}>
          {intl.formatMessage({ id: 'coupon.USED' })}
        </Typography>
      )
      break
    case 'EXPIRED':
      inRecallState = true
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#FE3D3D' }}>
          {intl.formatMessage({ id: 'coupon.EXPIRED' })}
        </Typography>
      )
      break
    case 'RECALLED':
      couponState = (
        <Typography sx={{ fontSize: '12px', color: '#FE3D3D' }}>
          {intl.formatMessage({ id: 'coupon.RECALLED' })}
        </Typography>
      )
      break
  }

  const canRecall = inRecallState && row.issuer_uuid === my_uuid

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.coupon_name}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.user_id}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.nickname}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.name}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.amount)}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}>{txt_issued_at}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}>{txt_expire}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px' }}>{txt_used_at}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{txt_method}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{requestState}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          {canRecall ? (
            <Grid container>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    fontSize: '12x',
                  }}
                >
                  {couponState}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    pl: '8px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    fontSize: '12x',
                    color: 'red',
                    ':hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => onClickRecall(row.coupon_id)}
                >
                  [회수]
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography sx={{ fontSize: '12x' }}>{couponState}</Typography>
          )}
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

type TrackData = Schema$CouponTrackData
const ItemPerPage = 15

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const SEARCH_FILTER_NAMES = [{ id: 'ALL', name: '전체' }, ...COUPON_STATE]

const SEARCH_KEY_NAMES = [
  { id: 'ID', name: '아이디' },
  { id: 'NICK', name: '닉네임' },
]

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

interface Props {
  location: any
}

function PartnerCouponRecord({ location }: Props) {
  const query = queryString.parse(location.search)
  const { filter, key, value, page, ts } = query

  const [timestamp, setTimestamp] = useState('')
  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [couponConfig, setCouponConfig] = useState<ICouponConfig.Schema | null>(null)
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchFilter, setSearchFilter] = useState('ALL')
  const [searchKey, setSearchKey] = useState('ID')
  const refSearchValue = useRef<string>('')

  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { uuid: my_uuid, isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { option } = globalStore
  const { privacy } = option || {}
  const { is_disclose_phone, is_disclose_name } = privacy || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  const startPage = page ? Number(page) : 1

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  if (timestamp !== (ts as string)) {
    setTimestamp(ts as string)
  }

  const { coupon_money } = couponConfig || { coupon_money: 0, items: [] }

  const fetchCouponConfig = async (params: ICouponConfig.Params) => {
    try {
      const ret = await apiUser.couponConfig(params)
      setCouponConfig(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchIssuerCouponList = async (params: IIssuerCouponList.Params) => {
    setLoading(true)

    try {
      const { data } = await apiUser.issuerCouponList(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  const fetchRecall = async (params: IRecallCoupon.Params) => {
    try {
      await apiUser.recallCoupon(params)

      await fetchCouponConfig({})
      await fetchIssuerCouponList({
        coupon_state: searchFilter,
        search_key: searchKey,
        search_value: refSearchValue.current,
        offset: 0,
        limit: ItemPerPage,
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-COUPON')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    // fetch user data
    async function inline() {
      await globalStore.getPublicSettings(intl)
      const recovered = await authStore.recoverSession(intl)
      if (!recovered) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: intl.formatMessage({ id: 'msg.no_login_info' }),
          callbackPositive: () => {
            window.close()
          },
        })
      } else {
        // get user info
        try {
          await fetchCouponConfig({})
          globalStore.enablePartnerMode(PartnerModeKeys.partner_coupon)
        } catch (err) {
          globalStore.pushErrorObject(err, intl)
        }
      }
    }
    inline()
    setSearchFilter(_.isString(filter) ? filter : 'ALL')
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  useEffect(() => {
    const newFilter = _.isString(filter) ? filter : 'ALL'
    const newKey = _.isString(key) ? key : 'ID'
    const newValue = _.isString(value) ? value : ''
    setSearchFilter(newFilter)
    setSearchKey(newKey)
    refSearchValue.current = newValue
    fetchIssuerCouponList({
      coupon_state: newFilter,
      search_key: newKey,
      search_value: newValue,
      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    })
  }, [timestamp])

  // useEffect(() => {
  //   const params: IIssuerCouponList.Params = {
  //     coupon_state: searchFilter,
  //     offset: (tabPage.page - 1) * ItemPerPage,
  //     limit: ItemPerPage,
  //   }

  //   fetchIssuerCouponList(params)
  // }, [tabPage])ㄹ

  const pushNewUrl = (_tab: number, _page: number) => {
    let q = `ts=${new Date().getTime()}&filter=${searchFilter}&key=${searchKey}&value=${
      refSearchValue.current
    }&page=${_page}`
    let encoded = encodeURI(q)
    history.push(`/partner/coupon-record/search?${encoded}`)
  }

  const handleChangeSearchFilter = event => {
    setSearchFilter(event.target.value)
  }

  const handleChangeSearchKey = event => {
    setSearchKey(event.target.value)
  }

  const handleChangeSearchValue = event => {
    refSearchValue.current = event.target.value
  }
  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    pushNewUrl(tabPage.tab, value)
  }

  const onClickSearch = () => {
    pushNewUrl(tabPage.tab, tabPage.page)
  }

  const onClickRecall = (coupon_id: number) => {
    if (track) {
      const coupon = _.find(track.items, o => {
        return o.coupon_id === coupon_id
      })

      if (coupon) {
        globalStore.pushDialogLocaleYesNo({
          text: 'msg.format.retrieve_coupon',
          arg1: coupon.coupon_name,
          arg2: numberWithCommas(coupon.amount),
          callbackPositive: async () => {
            fetchRecall({ coupon_id: coupon_id })
          },
        })
      }
    }
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const rows = []
  if (track) {
    rows.push(<RowHead sum={track.sum}></RowHead>)
    for (const el of track.items) {
      const row: RowData = {
        coupon_id: el.coupon_id,
        coupon_name: el.coupon_name,
        user_id: el.user_id,
        nickname: el.nickname,
        issuer_uuid: el.issuer_uuid,
        amount: el.amount,
        issued_at: el.issued_at,
        expire_at: el.expire_at,
        used_at: el.used_at,
        state: el.state,
        request_state: el.request_state,
        method: el.method,
        name: is_disclose_name ? el.name : '',
      }

      rows.push(
        <Row my_uuid={my_uuid} key={row.coupon_id} row={row} onClickRecall={onClickRecall}></Row>,
      )
    }

    pageData = getPageData(track)
  }

  const filterItems = []
  for (const el of SEARCH_FILTER_NAMES) {
    filterItems.push(<MenuItem value={el.id}>{el.name}</MenuItem>)
  }

  const searchKeyItems = []
  for (const el of SEARCH_KEY_NAMES) {
    searchKeyItems.push(<MenuItem value={el.id}>{el.name}</MenuItem>)
  }

  return (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1380px',
          maxWidth: '1380px',
        }}
      >
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>쿠폰 내역</Divider>
          </Grid>
        </Grid>
        <Grid container sx={{ marginTop: '16px' }}>
          <Grid item xs={6}>
            <Box>
              <Stack direction="row">
                <Paper
                  variant="elevation"
                  square
                  elevation={2}
                  sx={{ padding: 1, pr: 2, pl: 2, background: '#e9ecef' }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                    나의 쿠폰 머니 잔액
                  </Typography>
                </Paper>
                <Paper
                  variant="elevation"
                  square
                  elevation={2}
                  sx={{ padding: 1, pr: 2, pl: 2, background: '#e9ecef' }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 'bold', pl: '6px' }}>
                    {numberWithCommas(coupon_money)}
                  </Typography>
                </Paper>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <TextField
                  onChange={handleChangeSearchValue}
                  size="small"
                  variant={'outlined'}
                  label="검색값"
                  style={{ width: '180px' }}
                ></TextField>
                <TextField
                  value={searchKey}
                  onChange={handleChangeSearchKey}
                  select
                  size="small"
                  variant={'outlined'}
                  label="아이디/닉네임"
                  style={{ width: '100px' }}
                >
                  {searchKeyItems}
                </TextField>
                <TextField
                  value={searchFilter}
                  onChange={handleChangeSearchFilter}
                  select
                  size="small"
                  variant={'outlined'}
                  label="검색필터"
                  style={{ width: '100px' }}
                >
                  {filterItems}
                </TextField>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: '16px' }}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="collapsible table">
                {Head(is_disclose_name)}
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <PageStyle
                count={pageData.totalPage}
                variant="outlined"
                page={pageData.currPage}
                onChange={handlePagingChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

export default observer(PartnerCouponRecord)
