/** @jsxImportSource @emotion/react */

import React from 'react'
import { jsx, css } from '@emotion/react'
import { useMediaQuery } from 'react-responsive'

import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import useRootStore from '@store/useRootStore'
import asset_channel from '@assets/channel'

//img
import { imgTelegram } from '@assets/images/layout_3'

// css
import { Box, Button, Container, Grid, Typography } from '@mui/material'
import { FooterStyle, SnsLink } from './index.style'

import { MEDIA_MODE } from '~/store/globalStore'
import { useIntl } from 'react-intl'

const APP_NAME = process.env.REACT_APP_NAME || ''
const channelSymbol = process.env.REACT_APP_CHANNEL || ''

function Footer() {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()
  const { option } = globalStore
  const { contact } = option || {}

  const { desktop } = globalStore.decideMedia(isDesktop)

  let kakaotalk = ''
  let telegram = ''
  if (contact) {
    kakaotalk = contact.kakaotalk
    telegram = contact.telegram
  }

  const onClickOpenTelegram = id => {
    // @를 제외한 아이디
    window.open(`https://t.me/${id}`)
  }

  const onClickSwitchDesktopView = (mode: string) => {
    globalStore.setMediaMode(mode)
  }

  let boxStyle = {}
  let switchContents = null

  if (desktop) {
    boxStyle = {
      minWidth: '1448px',
      '& .top_inner': {
        padding: '40px 0',
      },
      '& .top_inner .MuiGrid-item > .MuiBox-root > .MuiButton-root': {
        margin: '0 0 0 16px',
      },
      '& .bottom_inner': {
        padding: '40px 0',
      },
      '& .MuiContainer-root': {
        maxWidth: '1448px',
      },
      '& .logo_wrap > img': {
        height: 150,
      },
    }

    if (!isDesktop) {
      switchContents = (
        <Button onClick={() => onClickSwitchDesktopView(MEDIA_MODE.RESPONSIVE)} sx={{ color: '#cccccc' }}>
          <SmartphoneIcon></SmartphoneIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.mobile-view' })}
        </Button>
      )
    }
  } else {
    boxStyle = {
      '& .top_inner': {
        padding: '16px 0',
      },
      '& .top_inner .MuiGrid-container': {
        flexDirection: 'column',
      },
      '& .top_inner .MuiGrid-item > .MuiBox-root': {
        flexDirection: 'column',
      },
      '& .top_inner .MuiGrid-item > .MuiBox-root > .MuiButton-root': {
        margin: '8px 0',
      },
      '& .bottom_inner': {
        padding: '16px 0',
      },
      '& .logo_wrap > img': {
        height: 90,
      },
    }
    if (!isDesktop) {
      switchContents = (
        <Button onClick={() => onClickSwitchDesktopView(MEDIA_MODE.DESKTOP)} sx={{ color: '#cccccc', mt: 2 }}>
          <DesktopWindowsIcon></DesktopWindowsIcon>
          &nbsp;&nbsp;{intl.formatMessage({ id: 'msg.pc-view' })}
        </Button>
      )
    }
  }

  return (
    <FooterStyle sx={boxStyle} className="footer">
      <Box className="top_inner">
        <Box>{switchContents}</Box>
        <Container fixed>
          <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid item className="logo_wrap">
              {channelSymbol === 'praha' ? (
                <img src={asset_channel.common.main_logo_gif} alt="" />
              ) : (
                <img src={asset_channel.common.main_logo} alt="" />
              )}
            </Grid>
            <Grid item>
              <SnsLink>
                {telegram ?
                  channelSymbol === 'today' || channelSymbol === 'ace' || channelSymbol === 'praha' ? (
                    <>
                      <Button onClick={() => onClickOpenTelegram(telegram)}>
                        <Box className="title">텔레그램 고객센터</Box>
                        <Box className="id">
                          <img src={imgTelegram} />
                          <Typography>{telegram}</Typography>
                        </Box>
                        <Box className="tip">* 해당아이디를 제외한 나머지는 모두 사칭입니다.</Box>
                      </Button>

                      <Button>
                        <Box className="title">텔레그램 공지방</Box>
                        <Box className="id">
                          <img src={imgTelegram} />
                          <Typography>바로가기 CLICK</Typography>
                        </Box>
                        <Box className="tip">* 클릭 시 바로연결</Box>
                      </Button>

                      <Button>
                        <Box className="title">평생 도메인 안내</Box>
                        <Box className="id">
                          <Typography className="id">평생 도메인 바로가기</Typography>
                        </Box>
                        <Box className="tip">* 클릭 시 바로연결</Box>
                      </Button>
                    </>
                  ) : null
                  : null}
                {telegram ?
                  channelSymbol === 'hongyeom' ? (
                    <>
                      <Button onClick={() => onClickOpenTelegram(telegram)}>
                        <Box className="title">텔레그램 고객센터</Box>
                        <Box className="id">
                          <img src={imgTelegram} />
                          <Typography>{telegram}</Typography>
                        </Box>
                        <Box className="tip">* 해당아이디를 제외한 나머지는 모두 사칭입니다.</Box>
                      </Button>
                    </>
                  ) : null
                  : null}
              </SnsLink>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box className="bottom_inner" sx={{ borderTop: '1px solid #531419' }}>
        <Container>
          <Box>
            <div className="address">
              By accessing. continuing to use or navigating throughout this site you accept thata we wall use certain
              browser cookies to improve your customer experience with us
              <br />
              ace casino only uses cookies which wall improve your experience with us wall not interfere with your
              privacy
            </div>
            <div className="copyright">
              Copyright ⓒ<span>{APP_NAME}</span> All rights Reserved.
            </div>
          </Box>
        </Container>
      </Box>
    </FooterStyle>
  )
}
export default Footer
