/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Typography, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import * as _ from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import { numberWithCommas, fillZero, invalidateNumber } from '~/utils/utils'
import useRootStore from '@store/useRootStore'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import NumberFormat from 'react-number-format'
import { ModalKeys } from '@store/globalStore'

// icon
import { ReactComponent as IconTrash } from '@assets/images/layout_1/icon_trash.svg'
import { ReactComponent as IconBetting } from '@assets/images/layout_1/icon_betting.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// css
import { BasicButton } from '~/styles/button.style'
import { BettingCartStyle, CartListStyle, AmountStyle } from './index.style'
import { string } from 'yup/lib/locale'
import { styled } from '@mui/material/styles'
import { Formik } from 'formik'
import { useOnUnmount } from '~/utils/reactExt'
import { getCartTitle, getTeamName, toClientbaseline } from '~/game/st-util'
import { CartItem } from '~/store/sportsStore/sports-util'

interface Props {
  lang: string
  item: CartItem
}

const SportsBettingCartItem: React.FC<Props> = ({ lang, item }) => {
  const { sportsStore } = useRootStore()
  const { match, marketId, marketName, bet } = item

  const homeName = getTeamName(match, '1', lang)
  const awayName = getTeamName(match, '2', lang)

  const oddsRate = bet.ClientPrice
  const marketNameLang = marketName[lang] || marketName['en']

  const title = getCartTitle(match, marketId.toString(), bet.Name, lang)
  let subTitle = `${marketNameLang}`
  if (bet.BaseLine) {
    subTitle = `${subTitle} [${toClientbaseline(bet.BaseLine)}]`
  }
  const vsTitle = `${homeName} vs ${awayName}`

  const onClickDelete = () => {
    sportsStore.removeCartItemByCartId(item.cartId)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flex: '1 1 0',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: '1.1rem',
        }}
      >
        <span style={{ color: '#181818' }}>{title}</span>
        <span style={{ color: '#2E3A4D' }}>{oddsRate}</span>
      </Box>
      <Box sx={{ fontSize: '0.9rem', color: '#707070', mt: 1 }}>{subTitle}</Box>
      <Box sx={{ display: 'flex', fontSize: '0.9rem', color: '#707070' }}>
        <Box className="ellipsis">{vsTitle}</Box>
        <BasicButton icon style={{ marginLeft: '8px' }} onClick={() => onClickDelete()}>
          <IconTrash width="24" fill="#BBBDC1" />
        </BasicButton>
      </Box>
    </Box>
  )
}

export default SportsBettingCartItem
