import * as _ from 'lodash'

import { ODDS_COLOR, MarketMatrixMap } from './common'

export const POWERLADDER_MARKET_GROUP = {
  ALL: 0, // 1개만 선택
  MAX: 1,
}

// PL A 그룹
export const POWERLADDER_MARKET_GROUP_TEXT = ['', 'PL']

// category/marketGroup/matrix
export const MarketMatrix: MarketMatrixMap = {
  PL: {
    PL1: [
      [
        { marketId: 'PLLEFT_PLRIGHT', id: 'PLLEFT', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PLLEFT_PLRIGHT', id: 'PLRIGHT', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        { marketId: 'PLLINE', id: 'PLLINE_3', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PLLINE', id: 'PLLINE_4', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        { marketId: 'PLODD_PLEVEN', id: 'PLODD', acolor: ODDS_COLOR.blue_a, dcolor: ODDS_COLOR.blue_d },
        { marketId: 'PLODD_PLEVEN', id: 'PLEVEN', acolor: ODDS_COLOR.red_a, dcolor: ODDS_COLOR.red_d },
      ],
      [
        {
          marketId: 'PLLEFT_PLRIGHT+PLLINE',
          id: 'PLLEFT+PLLINE_4',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
        },
        {
          marketId: 'PLLEFT_PLRIGHT+PLLINE',
          id: 'PLLEFT+PLLINE_3',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
        },
        {
          marketId: 'PLLEFT_PLRIGHT+PLLINE',
          id: 'PLRIGHT+PLLINE_3',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
        },
        {
          marketId: 'PLLEFT_PLRIGHT+PLLINE',
          id: 'PLRIGHT+PLLINE_4',
          acolor: ODDS_COLOR.brown_a,
          dcolor: ODDS_COLOR.brown_d,
        },
      ],
    ],
  },
}

export const GetPowerladderOddsColor = (
  category: string,
  marketGroup: number,
  marketId: string,
  oddsId: string,
): string => {
  if (!_.has(MarketMatrix, category)) {
    return ''
  }
  if (marketGroup >= POWERLADDER_MARKET_GROUP.MAX) {
    return ''
  }
  const txtMargetGroup = POWERLADDER_MARKET_GROUP_TEXT[marketGroup]
  if (!_.has(MarketMatrix[category], txtMargetGroup)) {
    return ''
  }
  for (const row of MarketMatrix[category][txtMargetGroup]) {
    const found = _.find(row, { marketId, id: oddsId })
    if (found) {
      return found.acolor
    }
  }
  return ''
}
