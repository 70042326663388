/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import React from 'react'
import { observer } from 'mobx-react'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import PopupMessageDefault from './d'
import PopupMessageMobile from './m'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'

const PopupMessage: React.FC<{}> = ({}) => {
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, userStore } = useRootStore()

  const { desktop } = globalStore.decideMedia(isDesktop)

  useOnMount(() => {
    userStore.setPositionPopup('MESSAGE')
  })

  useOnUnmount(() => {
    userStore.setPositionPopup('')
  })

  let bgSize = ''
  const GlobalStyle = () => css`
    body {
      ${isDesktop ? bgSize = 'background-size: calc(100% - 17px) auto;' : ''}
    }
  `

  let contents = null
  if (desktop) {
    contents = <PopupMessageDefault></PopupMessageDefault>
  } else {
    contents = <PopupMessageMobile></PopupMessageMobile>
  }
  return (
    <>
      <Global styles={GlobalStyle} />
      {contents}
    </>
  )
}

export default observer(PopupMessage)
