/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Container, ListGroup, Tabs, Tab } from 'react-bootstrap'
import { ILCAGetGameUrl } from '@protocol/casino'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import queryString from 'query-string'

import useRootStore from '@store/useRootStore'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { setScreenSize } from '~/utils/utils'
import { getUrlParameter } from '~/utils'

const ProxyMSL: React.FC<{}> = props => {
  const intl = useIntl()
  const { globalStore, casinoStore, authStore, userStore, liveStore } = useRootStore()
  const { signedin } = authStore
  const { serviceState } = liveStore

  useEffect(() => {
    setScreenSize()
    window.addEventListener('resize', setScreenSize)

    async function inline() {
      const recovered = await authStore.recoverSession(intl)
      if (!recovered) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: intl.formatMessage({ id: 'msg.no_login_info' }),
          callbackPositive: () => {
            window.close()
          },
        })
      }
    }
    inline()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function inline() {
      // const kind = getUrlParameter(window.location.href, 'kind') || ''
      // const id = getUrlParameter(window.location.href, 'id') || ''
      // const params: ILCAGetGameUrl.Params = {
      //   trading_kind: kind,
      // }
      // try {
      //   const { provider } = await casinoStore.api.casino.getGameUrl(params)
      //   userStore.setPositionPage(`MSL-${kind}`)
      //   // enter to live server
      //   await liveStore.enterSpace(provider, kind, id, false)
      // } catch (err) {
      //   const { error, message } = err
      //   globalStore.pushError(error, message, intl, () => {
      //     window.close()
      //   })
      // }
    }

    if (serviceState === SOCKET_SERVICE_STATE.ENTERED && signedin) {
      inline()
    }
    // eslint-disable-next-line
  }, [serviceState])

  // useEffect(() => {
  //   if (sessionUrl) {
  //     //window.location.href = sessionUrl.replace('https', 'http')
  //     window.location.href = sessionUrl
  //   }
  // }, [sessionUrl])

  let contents = null
  // if (sessionUrl) {
  //   contents = (
  //     <iframe
  //       title="game"
  //       id="contentFrame"
  //       style={{ display: 'block', border: 'none', width: '100%', height: '100vh' }}
  //       scrolling="no"
  //       src={sessionUrl}
  //     ></iframe>
  //   )
  // }

  return <Fragment>{contents}</Fragment>
}

export default observer(ProxyMSL)
