export const MEDIA_DESKTOP_WIDTH = 992

export enum COOKIE_KEYS {
  LANG = 'lang',
}

export enum LOCALSTORAGE_KEYS {
  SIGN = 'sign',
  USERID = 'userId',
  USERINFO = 'userInfo',
  NAV_OPEN_KEYS = 'navOpenKeys',
  THEME = 'theme',
  SIDE_BAR_COLLAPSED = 'collapsedSidebar',
  // about socket
  SOCKET_URL = 'socketUrl',
  SOCKET_TYPE = 'socketType',
  SOCKET_IO_EVENTS = 'socketIOEvents',
  DATA_FORMAT = 'dataFormat',
  NOT_SUPPORT_POLLING = 'notSupportPolling',
  LIVE_MATCH = 'liveMatch',
  AUTH_KEY = 'auth_key',
  MEDIA_MODE = 'media_mode',
  POPUP_NOTICE = 'popup_notice',
  POPUP_PB_GUIDE_DATE = 'popup_pb_guide',
}

export const LOGIN_CATEGORY = ['user', 'admin']

export const GITHUB_LINK = 'https://github.com/YDJ-FE'

// 어드민의 글로벌 관리의 키
export const G_KEY_DATA_URL = 'url_datacenter'

export const ITEM_IMG_EXT = '.png'

export enum PRICE_TYPE {
  HEART = 5,
  S_CASH = 1,
  MILEAGE = 2,
  POINT = 3,
  NONE = 0,
}

export enum CASH_TAB {
  ALL,
  HEART,
  S_CASH,
  MILEAGE,
  POINT,
  INAPP,
}

export const PRICE_TYPE_CODE_TO_STR = {
  '1': 'cash',
  '2': 'mileage',
  '3': 'point',
  '5': 'heart',
}

export const HOME_OR_AWAY_SCORE = {
  HOME: 'homeScore',
  AWAY: 'awayScore',
}

export const BET_TICKET_CODE = {
  ONE_MAN: '72',
  FIVE_MAN: '73',
  TEN_MAN: '74',
}

export const STORAGE_KEY = {
  LAST_CLUB_ID: 'adv_v2_club_id',
}

export type SPORT_SORT = 'league' | 'date'
