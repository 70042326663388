import React, { Fragment, useState, useEffect, useRef } from 'react'
import { jsx, css, keyframes } from '@emotion/react'
import * as _ from 'lodash'
import {
  Grid,
  Box,
  AppBar,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListSubheader,
  ListItemButton,
  Collapse,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Badge from '@mui/material/Badge'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { ModalKeys } from '@store/globalStore'
import PartnerMenu from './PartnerMenu'
import { numberWithCommas } from '~/utils/utils'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import asset_channel from '@assets/channel'
import { APP_CHARGES } from '~/utils/utils'

// css
import { styled } from '@mui/material/styles'

// icon
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

// image
import { icon_logout, icon_message } from '~/assets/images/layout_5'

let headerHeight = 114

export const twinkle = keyframes`
  0% {
    background: #ff9a9a;
  }
  50% {
    background: #ff1c1c;
  }
  100% {
    background: #ff9a9a;
  }
`

const MobileHeaderStyle = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1201;

  .MuiAppBar-root {
    background: ${props => props.theme.colors.mobileHeaderBg};
    & .MuiDivider-root {
      border-color: ${props => props.theme.colors.themeActive};
      opacity: 1;
    }
    .MuiGrid-item {
      display: flex;
      align-items: center;
      height: 100%;
    }
    & .btn_partner {
      margin-left: 12px;
      padding: 4px 10px;
      min-width: 0;
      background-color: ${props => props.theme.colors.drawerTxtHover};
      color: #000;
    }
    & .logo {
      height: 100%;
      & > img {
        cursor: pointer;
        height: 100%;
      }
    }
    & .menu {
      margin-right: 0;
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .login_wrap {
      display: flex;
      justify-content: center;
      height: 48px;
      padding: 8px;
      & > button {
        margin: 0 4px;
        padding: 8px 16px;
        width: 40%;
        border-radius: 0;
        background-color: ${props => props.theme.colors.drawerTxtHover};
        color: #000;
        .MuiTypography-root {
          font-size: 0.9rem;
        }
      }
    }
  }
`

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  top: headerHeight,

  '.MuiBackdrop-root': {
    top: headerHeight,
  },
  '& .MuiDrawer-paper': {
    top: headerHeight,
    width: '70%',
    maxHeight: `calc(100% - ${headerHeight}px)`,
    backgroundColor: theme.colors.drawerBg,
  },
}))

const GnbStyle = styled(Box)(({ theme }) => ({
  '& .myinfo': {
    padding: '0 0 16px',
    borderBottom: `1px solid ${theme.colors.drawerBorder}`,
    backgroundColor: theme.colors.drawerTopBg,
    color: theme.colors.drawerSubTxt,
    '& .login > .MuiButton-root': {
      color: theme.colors.drawerTxtLogin,
    },
    '& .nickname': {
      color: theme.colors.drawerTxtNick,
      textTransform: 'initial',
      fontSize: '1rem',
    },
    '& .money': {
      color: theme.colors.drawerTxtMoney,
    },
    '& .point': {
      color: theme.colors.drawerTxtPoint,
    },
    '& .MuiListItemText-root': {
      flex: '0 1 auto',
    },
    '& .MuiListItemText-root > span': {
      color: '#fff',
    },
    '& .btn_transfer': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: theme.colors.drawerTxtHover,
      borderRadius: '4px',
      color: '#000',
      '&:disabled': {
        backgroundColor: '#931d1d',
        color: '#cccccc',
      },
    },
    '& .btn_refresh': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: theme.colors.drawerTxtHover,
      borderRadius: '4px',
      color: '#000',
      '&:disabled': {
        backgroundColor: '#931d1d',
        color: '#cccccc',
      },
    },
  },

  '& .category': {
    backgroundColor: 'transparent',
    borderBottom: `1px solid ${theme.colors.drawerBorder}`,
    color: theme.colors.drawerSubTxt,
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  '& .MuiListSubheader-root': {
    position: 'static',
    backgroundColor: theme.colors.drawerSubBg,
    color: '#a6a6a6',
    lineHeight: '32px',
  },
  '& .MuiListItemButton-root.outer': {
    padding: '6.5px 16px 5px',
    backgroundColor: theme.colors.drawerMenuBg,
  },
  '& .MuiListItemButton-root.outer.Mui-selected': {
    backgroundColor: theme.colors.drawerMenuBg,
    '& .MuiListItemText-root': {
      color: theme.colors.drawerTxtHover,
    },
  },
  '& .MuiCollapse-root': {
    paddingLeft: '16px',
    '& .MuiList-root': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  '& .MuiListItemButton-root.nested': {
    flex: '1 0 50%',
    padding: '0 16px',
  },
  '& .MuiListItemButton-root.outer.active, & .MuiListItemButton-root.nested.active .MuiListItemText-root .MuiTypography-root': {
    color: theme.colors.drawerTxtHover,
  },
  '& .nested .MuiListItemText-root': {
    margin: 0,
    padding: '9.75px 0',
    '& .MuiTypography-root': {
      color: theme.colors.drawerSubTxt,
    },
  },
}))

let EXCLUDE_PARTNER_MENU_CLASS = []
if (process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) {
  EXCLUDE_PARTNER_MENU_CLASS =
    JSON.parse(process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) || []
}

function getLevelImage(level: number) {
  switch (level) {
    case 1:
      return asset_channel.common.icon_level01
    case 2:
      return asset_channel.common.icon_level02
    case 3:
      return asset_channel.common.icon_level03
    case 4:
      return asset_channel.common.icon_level04
    case 5:
      return asset_channel.common.icon_level05
    case 6:
      return asset_channel.common.icon_level06
    case 7:
      return asset_channel.common.icon_level07
    case 8:
      return asset_channel.common.icon_level08
    case 9:
      return asset_channel.common.icon_level09
    case 10:
      return asset_channel.common.icon_level10
  }
  return null
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let EXCLUDE_TOP_MENU = []
if (process.env.REACT_APP_EXCLUDE_TOP_MENU) {
  EXCLUDE_TOP_MENU = JSON.parse(process.env.REACT_APP_EXCLUDE_TOP_MENU) || []
}
const SHOW_GAME_MONEY = process.env.REACT_APP_SHOW_GAME_MONEY === 'true'

export interface HeaderProps {
  onClickNavPath: (path: string) => void
}

interface MobileHeaderProps {
  Header: React.ComponentType<HeaderProps>
}

export function Header(props: MobileHeaderProps) {
  const { Header } = props
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [listOpen, setListOpen] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [invisibleUnread, setInvisibleUnread] = useState(true)
  const [refreshEnabled, setRefreshEnabled] = useState(null)

  const { option, navChange, partnerMode } = globalStore
  const { initialized, signedin } = authStore
  const { notification, userInfo, userMoney, userPoint, userComp, game_money } = userStore
  const { nickname, isPartner, user_level, comp_level, merchant_class } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { balance: balanceComp } = userComp

  const {
    open_trading_groups,
    open_trading_kinds,
    enableComp,
    enableCoupon,
    excludePartnerMenuClass,
  } = option || {}

  const contents_level = enableComp ? comp_level : user_level

  const couponActive = notification && notification.usableCouponCount > 0 ? 'active' : ''

  const isHome = window.location.pathname === '/'

  useEffect(() => {
    // do not delete!
    // rerender when notice page tab changed, so header nav can changed
  }, [navChange, partnerMode])

  useEffect(() => {
    let visibleUnread = signedin && notification && notification.unread > 0
    const newInvisibleUnread = !visibleUnread
    if (newInvisibleUnread !== invisibleUnread) {
      setInvisibleUnread(newInvisibleUnread)
    }
  }, [notification])

  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    if (currentPath === path || currentPathSearch === path) {
      return 'active'
    } else {
      return ''
    }
  }

  function isEnabled(trading_group: string): boolean {
    let foundGroup = null
    let foundKind = null

    if (open_trading_groups) {
      foundGroup = _.find(open_trading_groups, o => {
        return o.trading_group === trading_group
      })
    }

    if (open_trading_kinds) {
      foundKind = _.find(open_trading_kinds, o => {
        return o.trading_group === trading_group
      })
    }

    return foundGroup && foundKind
  }

  const handleList = index => {
    //setListOpen(!listOpen);
    if (listOpen === index) {
      setListOpen('')
    } else {
      setListOpen(index)
    }
  }

  const onClickMenu = () => {
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const onClickButton = (name: string) => {
    switch (name) {
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'game-money':
        const timer = setTimeout(() => {
          setRefreshEnabled(null)
        }, 5000)
        setRefreshEnabled(timer)
        userStore.loadGameMoney(intl)
        return
      case 'withdraw':
        globalStore.pushDialogYesNo({
          text: intl.formatMessage({ id: 'msg.ask.gamemoney-to-ownmoney' }),
          callbackPositive: async () => {
            const timer = setTimeout(() => {
              setRefreshEnabled(null)
            }, 5000)
            setRefreshEnabled(timer)
            userStore.withdrawGameMoney(intl)
          },
        })
        return
    }

    setOpen(false)
  }

  const onClickMenuItem = (id: string, arg: string | number) => {
    switch (id) {
      case 'live-casino':
        history.push('/live-casino')
        globalStore.setNavChange('/live-casino')
        break
      case 'hotel-casino':
        history.push('/hotel-casino')
        globalStore.setNavChange('/hotel-casino')
        break
      case 'slot':
        history.push('/slot')
        globalStore.setNavChange('/slot')
        break
      case 'reel':
        history.push('/reel')
        globalStore.setNavChange('/reel')
        break
      case 'sports':
        history.push('/sports')
        globalStore.setNavChange('/sports')
        break
      case 'minigame':
        if (arg) {
          history.push(`/minigame/search?main=${arg}`)
          globalStore.setNavChange(`/minigame/search?main=${arg}`)
        } else {
          history.push('/minigame')
          globalStore.setNavChange('/minigame')
        }
        break
      case 'partner':
        history.push('/partner')
        globalStore.setNavChange('partner')
        break
      case 'bank':
        history.push(`/bank/search?main=${arg}`)
        globalStore.setNavChange(`/bank/search?main=${arg}`)
        break
      case 'notice':
        history.push(`/notice/search?main=${arg}`)
        globalStore.setNavChange(`/notice/search?main=${arg}`)
        break
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.point.url}`)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.comp.url}`)
        break
      case 'myinfo':
        history.push(MYPAGE_TAB_VALUES.myinfo.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.myinfo.url}`)
        break
      case 'qnaPersonal':
        history.push(MYPAGE_TAB_VALUES.qnaPersonal.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.qnaPersonal.url}`)
        break
      // case 'qnaPartner':
      //   history.push(MYPAGE_TAB_VALUES.qnaPartner.url)
      //   break
      case 'login':
        globalStore.showModal(ModalKeys.login, 0)
        break
      case 'join':
        globalStore.showModal(ModalKeys.login, 1)
        break
      case 'message':
        globalStore.showModal('message', 0)
        break
      case 'coupon':
        globalStore.showModal('coupon', 0)
        break
    }

    setOpen(false)
    window.scrollTo(0, 0)
  }

  const onClickLogout = () => {
    history.push('/')
    authStore.logout(intl)
    setAnchorEl(null)
    setOpen(false)
  }

  const onClickLogo = () => {
    history.push('/')
    setOpen(false)
    if (signedin) {
      userStore.loadAsset(intl)
    }
  }

  const showNotice = EXCLUDE_TOP_MENU.indexOf('NOTICE') < 0
  const showEvent = EXCLUDE_TOP_MENU.indexOf('EVENT') < 0
  const showPolicy = EXCLUDE_TOP_MENU.indexOf('POLICY') < 0
  const showQna = EXCLUDE_TOP_MENU.indexOf('QNA') < 0

  let servicePartner = null
  let badgeContents = (
    <Grid item xs={4}>
      <Button size="large" onClick={() => onClickMenuItem('message', '')}>
        <img src={icon_message} alt="message" />
        {intl.formatMessage({ id: 'component.message' })}
      </Button>
    </Grid>
  )

  let showPartnerMenu = false

  if (initialized) {
    if (signedin) {
      showPartnerMenu = !excludePartnerMenuClass.includes(merchant_class.toString())
      headerHeight = 65

      if (notification) {
        const { unread } = notification

        if (unread > 0) {
          badgeContents = (
            <Grid item xs={4}>
              <Badge badgeContent={unread} invisible={invisibleUnread} color="error">
                <Button size="large" onClick={() => onClickMenuItem('message', '')}>
                  <img src={icon_message} alt="message" />
                  {intl.formatMessage({ id: 'component.message' })}
                </Button>
              </Badge>
            </Grid>
          )
        }
      }

      if (showPartnerMenu) {
        servicePartner = (
          <ListItemButton
            className={`outer ${navActive('/partner')}`}
            onClick={() => onClickMenuItem('partner', '')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.partner' })} />
          </ListItemButton>
        )
      }
    } else {
      headerHeight = 114
    }
  }

  // Drawer----------
  const chargeContents = []
  if (APP_CHARGES.indexOf('deposit') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=deposit')}`}
        key="deposit"
        onClick={() => onClickMenuItem('bank', 'deposit')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.normal' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositOnepay') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositOnepay')}`}
        key="depositOnepay"
        onClick={() => onClickMenuItem('bank', 'depositOnepay')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.onepay' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositCoin') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositCoin')}`}
        key="depositCoin"
        onClick={() => onClickMenuItem('bank', 'depositCoin')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.coin' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositCoinJun200') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositCoinJun200')}`}
        key="depositCoinJun200"
        onClick={() => onClickMenuItem('bank', 'depositCoinJun200')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.coin' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('deposit5Koin') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=deposit5Koin')}`}
        key="deposit5Koin"
        onClick={() => onClickMenuItem('bank', 'deposit5Koin')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.5koin' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositWonp') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositWonp')}`}
        key="depositWonp"
        onClick={() => onClickMenuItem('bank', 'depositWonp')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.wonp' })} />
      </ListItemButton>,
    )
  }

  const list =
    signedin === true ? (
      <Box className="myinfo">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
          <Button
            className="nickname"
            sx={{ display: 'flex', alignItems: 'center', pl: 0, py: 0, color: 'inherit' }}
            onClick={() => onClickMenuItem('myinfo', '')}
          >
            <Box sx={{ '& > img': { padding: '6px', width: '36px' } }}>
              <img src={getLevelImage(contents_level)} alt="" />
            </Box>
            <Box component="span">{nickname}</Box>
          </Button>
          <Box>
            <Button
              size="small"
              onClick={() => onClickMenuItem('myinfo', '')}
              sx={{ minWidth: '20px' }}
            >
              <SettingsRoundedIcon sx={{ color: '#a6a6a6', fontSize: '1.539rem' }} />
            </Button>
            <Button size="small" onClick={onClickLogout} sx={{ minWidth: '20px' }}>
              <img src={icon_logout} alt="logout" style={{ width: '20px' }} />
            </Button>
          </Box>
        </Box>

        <List component="div" sx={{ p: '0 8px 8px' }}>
          <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }}>
            <ListItemText>
              <span>{intl.formatMessage({ id: 'own-money' })}</span>
            </ListItemText>
            <ListItemText>
              <span className="money">{numberWithCommas(Math.floor(balanceMoney))}</span>
              <span>{intl.formatMessage({ id: 'money-locale' })}</span>
            </ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }}>
            <ListItemText>
              <span>{intl.formatMessage({ id: 'point' })}</span>
            </ListItemText>
            <ListItemText>
              <span className="point">{numberWithCommas(Math.floor(balancePoint))}</span>
              <span>P</span>
              <Button className="btn_transfer" onClick={() => onClickButton('point')}>
                전환
              </Button>
            </ListItemText>
          </ListItem>
          {enableComp ? (
            <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }}>
              <ListItemText>
                <span>{intl.formatMessage({ id: 'comp' })}</span>
              </ListItemText>
              <ListItemText>
                <span className="point">{numberWithCommas(Math.floor(balanceComp))}</span>
                <Button className="btn_transfer" onClick={() => onClickButton('comp')}>
                  전환
                </Button>
              </ListItemText>
            </ListItem>
          ) : null}
          {SHOW_GAME_MONEY && !globalStore.option?.solution?.seamless && (
            <>
              <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="game-money">
                <ListItemText>
                  <span>{intl.formatMessage({ id: 'game-money' })}</span>
                </ListItemText>
                <ListItemText>
                  <span className="money">
                    {_.isNumber(game_money) ? numberWithCommas(Math.floor(game_money)) : '??'}
                  </span>
                  <Button
                    className="btn_refresh"
                    disabled={!!refreshEnabled}
                    onClick={() => onClickButton('game-money')}
                  >
                    <RefreshIcon sx={{ width: '16px' }} />
                  </Button>
                  <Button
                    className="btn_transfer"
                    disabled={!!refreshEnabled}
                    onClick={() => onClickButton('withdraw')}
                  >
                    전환
                  </Button>
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </Box>
    ) : null
  // -----------Drawer

  return (
    <>
      <MobileHeaderStyle>
        <AppBar position="static">
          <Grid container sx={{ height: '65px' }}>
            <Grid item xs={4}>
              {signedin && showPartnerMenu ? (
                <>
                  <Button
                    size="large"
                    className="btn_partner"
                    onClick={() => onClickMenuItem('partner', '')}
                  >
                    {intl.formatMessage({ id: 'component.partner' })}
                  </Button>
                </>
              ) : null}
            </Grid>
            <Grid item xs={4} justifyContent="center">
              <Box className="logo">
                {channelSymbol === 'praha' ? (
                  <img
                    src={asset_channel.common.main_logo_gif_m}
                    alt=""
                    onClick={() => onClickLogo()}
                  />
                ) : (
                  <img
                    src={asset_channel.common.main_logo_m}
                    alt=""
                    onClick={() => onClickLogo()}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={4} justifyContent="end">
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                className="menu"
                onClick={() => onClickMenu()}
              >
                <MenuIcon sx={{ fontSize: '2.1rem' }} />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          {signedin ? null : (
            <Box className="login_wrap">
              <Button onClick={() => onClickMenuItem('join', '0')}>
                <Typography>{intl.formatMessage({ id: 'signup' })}</Typography>
              </Button>
              <Button onClick={() => onClickMenuItem('login', '0')}>
                <Typography>{intl.formatMessage({ id: 'login' })}</Typography>
              </Button>
            </Box>
          )}
        </AppBar>

        {/* 메뉴 드롭다운 */}
        <DrawerStyle anchor="right" open={open} onClose={() => setOpen(false)}>
          <GnbStyle component="nav" role="presentation">
            {list}

            <List
              component="div"
              className="category"
              subheader={<ListSubheader component="div">게임</ListSubheader>}
              sx={{ pb: 0 }}
            >
              <ListItemButton
                hidden={!isEnabled('LCA')}
                className={`outer ${navActive('/live-casino')}`}
                key="live-casino"
                selected={listOpen === 'live-casino'}
                onClick={() => {
                  onClickMenuItem('live-casino', 'evolution')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.live-casino' })} />
              </ListItemButton>

              <ListItemButton
                hidden={!isEnabled('HCA')}
                className={`outer ${navActive('/hotel-casino')}`}
                key="hotel-casino"
                selected={listOpen === 'hotel-casino'}
                onClick={() => {
                  // handleList('hotel-casino')
                  onClickMenuItem('hotel-casino', 'uig')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.hotel-casino' })} />
              </ListItemButton>

              <ListItemButton
                hidden={!isEnabled('MSL')}
                className={`outer ${navActive('/msl')}`}
                key="msl"
                onClick={() => {
                  onClickMenuItem('msl', '')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.msl' })} />
              </ListItemButton>

              <ListItemButton
                hidden={!isEnabled('SL')}
                className={`outer ${navActive('/slot')}`}
                key="slot"
                onClick={() => {
                  onClickMenuItem('slot', '...')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.slot' })} />
              </ListItemButton>

              <ListItemButton
                hidden={!isEnabled('REEL')}
                className={`outer ${navActive('/reel')}`}
                key="reel"
                onClick={() => {
                  onClickMenuItem('reel', '...')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.reel' })} />
              </ListItemButton>

              <ListItemButton
                hidden={
                  !isEnabled('HPB') &&
                  !isEnabled('NPB') &&
                  !isEnabled('PB') &&
                  !isEnabled('PL') &&
                  !isEnabled('EOS') &&
                  !isEnabled('PBG') &&
                  !isEnabled('CPB') &&
                  !isEnabled('CL') &&
                  !isEnabled('SG') &&
                  !isEnabled('WRBTCPB') &&
                  !isEnabled('WRBTCPL') &&
                  !isEnabled('WRBEXPB') &&
                  !isEnabled('WRKENOPB') &&
                  !isEnabled('WRKENOPL') &&
                  !isEnabled('WRKENO')
                }
                className={`outer ${navActive('/minigame')}`}
                key="minigame"
                onClick={() => {
                  onClickMenuItem('minigame', '')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.minigame' })} />
              </ListItemButton>

              <ListItemButton
                hidden={!isEnabled('SP')}
                className={`outer ${navActive('/sports')}`}
                key="sports"
                onClick={() => {
                  onClickMenuItem('sports', '')
                }}
              >
                <ListItemText primary={intl.formatMessage({ id: 'menu.header.sports' })} />
              </ListItemButton>
            </List>
            <List
              component="div"
              className="category"
              subheader={<ListSubheader component="div">서비스</ListSubheader>}
              sx={{ pb: 0 }}
            >
              {signedin ? (
                <Fragment>
                  <ListItemButton className="outer" onClick={() => onClickMenuItem('message', '')}>
                    <ListItemText primary="쪽지" />
                  </ListItemButton>

                  {enableCoupon ? (
                    <ListItemButton className="outer" onClick={() => onClickMenuItem('coupon', '')}>
                      <ListItemText primary="쿠폰" />
                    </ListItemButton>
                  ) : null}

                  <ListItemButton
                    className="outer"
                    key="account"
                    selected={listOpen === 'account'}
                    onClick={() => {
                      handleList('account')
                    }}
                  >
                    <ListItemText
                      primary={intl.formatMessage({ id: 'component.bank-transaction' })}
                    />
                  </ListItemButton>
                  <Collapse in={'account' === listOpen} unmountOnExit>
                    <List component="div" disablePadding>
                      {chargeContents}
                      <ListItemButton
                        className={`nested ${navActive('?main=withdraw')}`}
                        onClick={() => onClickMenuItem('bank', 'withdraw')}
                      >
                        <ListItemText
                          primary={intl.formatMessage({ id: 'component.bank.withdraw' })}
                        />
                      </ListItemButton>
                      <ListItemButton
                        className={`nested ${navActive('?main=history')}`}
                        onClick={() => onClickMenuItem('bank', 'history')}
                      >
                        <ListItemText
                          primary={intl.formatMessage({ id: 'component.bank.history' })}
                        />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </Fragment>
              ) : null}

              <ListItemButton
                className={`outer ${navActive('?main=point')}`}
                onClick={() => onClickMenuItem('point', '')}
              >
                <ListItemText primary={intl.formatMessage({ id: 'component.point' })} />
              </ListItemButton>

              {enableComp ? (
                <ListItemButton
                  className={`outer ${navActive('?main=comp')}`}
                  onClick={() => onClickMenuItem('comp', '')}
                >
                  <ListItemText primary={intl.formatMessage({ id: 'component.comp' })} />
                </ListItemButton>
              ) : null}

              {servicePartner}

              {showNotice ? (
                <ListItemButton
                  className={`outer ${navActive('?main=NOTICE')}`}
                  onClick={() => onClickMenuItem('notice', 'NOTICE')}
                >
                  <ListItemText primary={intl.formatMessage({ id: 'component.notice.notice' })} />
                </ListItemButton>
              ) : null}

              {showQna ? (
                <ListItemButton
                  className={`outer ${navActive('?main=qnaPersonal')}`}
                  onClick={() => onClickMenuItem('qnaPersonal', '')}
                >
                  <ListItemText primary={intl.formatMessage({ id: 'menu.header.qna' })} />
                </ListItemButton>
              ) : null}

              {showEvent ? (
                <ListItemButton
                  className={`outer ${navActive('?main=EVENT')}`}
                  onClick={() => onClickMenuItem('notice', 'EVENT')}
                >
                  <ListItemText primary={intl.formatMessage({ id: 'component.notice.event' })} />
                </ListItemButton>
              ) : null}

              {showPolicy ? (
                <ListItemButton
                  className={`outer ${navActive('?main=POLICY')}`}
                  onClick={() => onClickMenuItem('notice', 'POLICY')}
                >
                  <ListItemText primary={intl.formatMessage({ id: 'component.notice.policy' })} />
                </ListItemButton>
              ) : null}
            </List>
          </GnbStyle>
        </DrawerStyle>
      </MobileHeaderStyle>
      <PartnerMenu></PartnerMenu>
    </>
  )
}

export default observer(Header)
