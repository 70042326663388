// image
import { img_wild_web1, img_wild_web2, img_wild_web3 } from '~/assets/images/layout_1'

export const AllBoardGames = [
  {
    kind: 'WILD_HOLDEM1',
    thumbnail: img_wild_web1,
    banner: img_wild_web1,
    hoverimg: null,
    localeId: 'trading.BOARDGAME.WILD_HOLDEM1',
  },
  {
    kind: 'WILD_HOLDEM2',
    thumbnail: img_wild_web2,
    banner: img_wild_web2,
    hoverimg: null,
    localeId: 'trading.BOARDGAME.WILD_HOLDEM2',
  },
  {
    kind: 'WILD_BADUKI',
    thumbnail: img_wild_web3,
    banner: img_wild_web3,
    hoverimg: null,
    localeId: 'trading.BOARDGAME.WILD_BADUKI',
  },
]
