// image
import {
  homeBannerCa_EV,
  homeBannerCa_VI,
  homeBannerCa_EZ,
  homeBannerCa_PT,
  homeBannerCa_VT,
  homeBannerCa_UIG,
  homeBannerCa_PR,
  homeBannerCa_DG,
  homeBannerCa_OG,
  homeBannerCa_MG,
  homeBannerCa_AG,
  homeBannerCa_CQ9,
  homeBannerCa_SG,
  imgLiveCasino_biggame,
  homeBannerCa_WM,
} from '@assets/images/layout_1'
import asset_channel from '@assets/channel'
const channelSymbol = process.env.REACT_APP_CHANNEL || ''

export const AllCaGames = [
  {
    kind: 'EV',
    thumbnail: homeBannerCa_EV,
    title: '에볼루션',
    locale: 'trading.LCA.EV',
  },
  {
    kind: 'EZ',
    thumbnail: homeBannerCa_EZ,
    title: '이주기',
    locale: 'trading.LCA.EZ',
  },
  {
    kind: 'VI',
    thumbnail: homeBannerCa_VI,
    title: '비보',
    locale: 'trading.LCA.VI',
  },
  {
    kind: 'PT',
    thumbnail: homeBannerCa_PT,
    title: 'Portomaso',
    locale: 'trading.LCA.PT',
  },
  {
    kind: 'AG',
    thumbnail: homeBannerCa_AG,
    title: '아시안게이밍',
    locale: 'trading.LCA.AG',
  },
  {
    kind: 'PR',
    thumbnail: homeBannerCa_PR,
    title: '프라그마틱',
    locale: 'trading.LCA.PR',
  },
  {
    kind: 'MG',
    thumbnail: homeBannerCa_MG,
    title: '마이크로게이밍',
    locale: 'trading.LCA.MG',
  },
  {
    kind: 'DG',
    thumbnail: homeBannerCa_DG,
    title: '드림게이밍',
    locale: 'trading.LCA.DG',
  },
  {
    kind: 'OG',
    thumbnail: homeBannerCa_OG,
    title: '오리엔탈게이밍',
    locale: 'trading.LCA.OG',
  },
  {
    kind: 'CQ9',
    thumbnail: homeBannerCa_CQ9,
    title: 'CQ9',
    locale: 'trading.LCA.CQ9',
  },
  {
    kind: 'VT',
    thumbnail: homeBannerCa_VT,
    title: '보타',
    locale: 'trading.LCA.VT',
  },
  {
    kind: 'UIG',
    thumbnail: homeBannerCa_UIG,
    title: 'UIG',
    locale: 'trading.LCA.UIG',
  },
  {
    kind: 'WM',
    thumbnail: homeBannerCa_WM,
    title: 'WM카지노',
    locale: 'trading.LCA.WM',
  },
  {
    kind: 'SG',
    thumbnail: homeBannerCa_SG,
    title: '섹시 게이밍',
    locale: 'trading.LCA.SG',
  },
  {
    kind: 'BG',
    thumbnail: imgLiveCasino_biggame,
    title: '빅 게이밍',
    locale: 'trading.LCA.BG',
  },
  {
    kind: 'PLT',
    thumbnail: imgLiveCasino_biggame,
    title: '플레이텍',
    locale: 'trading.LCA.PLT',
  },
  {
    kind: 'SA',
    thumbnail: imgLiveCasino_biggame,
    title: '사게이밍',
    locale: 'trading.LCA.SA',
  },
]
