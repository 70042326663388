import React, { useState } from 'react'
import * as _ from 'lodash'
import { Box, Typography } from '@mui/material'
import { getMarketArrangedFromList } from '~/game/st-util'
import moment from 'moment'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { useTheme } from '@mui/material/styles'
import MarketBoxMoneyline from '~/components/PrematchOdds/MarketBoxMoneyline'
import MarketBoxBaseline from '~/components/PrematchOdds/MarketBoxBaseline'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { PrematchData, SPORT_FLIGHT, UPDATE_FIELD_TYPE } from '~/store/sportsStore/sports-util'
import { Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'

interface Props {
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  item: PrematchData
  sx?: any
}

function DesktopPrematchFixture({ desktop, lang, flight, item, sx }: Props) {
  const theme = useTheme()
  const { sportsStore } = useRootStore()
  const [timestamp, setTimestamp] = useState<number>(0)

  const { prematch, markets } = item

  const updatePrematch = (updateMarket: Schema$Prematch, type: UPDATE_FIELD_TYPE) => {
    setTimestamp(new Date().getTime())
  }

  const updateMarket = (updateMarket: Schema$Market, type: UPDATE_FIELD_TYPE) => {
    setTimestamp(new Date().getTime())
  }

  useOnMount(() => {
    const { FixtureId } = item.prematch
    sportsStore.addFixtureController(FixtureId, updatePrematch)
    sportsStore.addUnifiedController(FixtureId, '1X2', updateMarket)
    sportsStore.addUnifiedController(FixtureId, '12', updateMarket)
    sportsStore.addUnifiedController(FixtureId, 'HDP', updateMarket)
    sportsStore.addUnifiedController(FixtureId, 'UO', updateMarket)
  })

  useOnUnmount(() => {
    const { FixtureId } = item.prematch
    sportsStore.deleteFixtureController(FixtureId)
    sportsStore.deleteUnifiedController(FixtureId, '1X2')
    sportsStore.deleteUnifiedController(FixtureId, '12')
    sportsStore.deleteUnifiedController(FixtureId, 'HDP')
    sportsStore.deleteUnifiedController(FixtureId, 'UO')
  })

  const { options } = sportsStore
  const { sports_configs } = options || {}
  const baseline_config = _.find(sports_configs, o => {
    return o.sport_id === prematch.SportId
  })

  const startDate = new Date(prematch.StartDate)
  let dateOfMatch = moment(startDate).format('MM-DD')
  const timeOfMatch = moment(startDate).format('HH:mm')

  let bet1X2 = null
  let betHdp = null
  let betUnderOver = null

  if (markets) {
    const disabledMarkets = _.filter(options.disabled_markets, o => {
      return o.sportId === prematch.SportId
    })
    const filteredMarket = _.filter(markets, o => {
      return (
        _.findIndex(disabledMarkets, dm => {
          return dm.marketId === o.Id
        }) < 0
      )
    })

    const unifiedNames = ['1X2', '12']
    for (const el of unifiedNames) {
      const market = getMarketArrangedFromList(filteredMarket, el, 0, false)
      if (market.top) {
        const oddsProps = {
          theme,
          desktop: true,
          lang,
          flight,
          prematch,
          market: market.top,
          unifiedName: el,
        }
        bet1X2 = <MarketBoxMoneyline {...oddsProps}></MarketBoxMoneyline>
        break
      }
    }

    const marketHdp = getMarketArrangedFromList(
      filteredMarket,
      'HDP',
      baseline_config?.baseline_size_hdp,
      baseline_config?.enabled_hdp_baseline_zero,
    )
    if (marketHdp.top) {
      const oddsProps = {
        theme,
        desktop: true,
        lang,
        flight,
        prematch: prematch,
        arranged: marketHdp,
        unifiedName: 'HDP',
      }
      betHdp = <MarketBoxBaseline {...oddsProps}></MarketBoxBaseline>
    }

    const marketUnderOver = getMarketArrangedFromList(
      filteredMarket,
      'UO',
      baseline_config?.baseline_size_uo,
      baseline_config?.enabled_uo_baseline_zero,
    )
    if (marketUnderOver.top) {
      const oddsProps = {
        theme,
        desktop: true,
        lang,
        flight,
        prematch: prematch,
        arranged: marketUnderOver,
        unifiedName: 'UO',
      }
      betUnderOver = <MarketBoxBaseline {...oddsProps}></MarketBoxBaseline>
    }
  }

  return (
    <Box display="flex" alignItems="center" sx={{ ...sx }}>
      <CopyToClipboard text={prematch.FixtureId}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ minWidth: '140px', width: '140px' }}
        >
          <Typography
            sx={{
              fontSize: '14px',
              color: '#838485',
              fontWeight: 'bold',
            }}
          >
            {dateOfMatch}
          </Typography>
          <Typography
            sx={{
              marginLeft: '6px',
              fontSize: '14px',
              color: '#d6d6d6',
              fontWeight: 'bold',
            }}
          >
            {timeOfMatch}
          </Typography>
        </Box>
      </CopyToClipboard>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ ml: '4px' }}>
          <Box>{bet1X2}</Box>
          <Box sx={{ mt: '4px' }}>{betHdp}</Box>
          <Box sx={{ mt: '4px' }}>{betUnderOver}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default observer(DesktopPrematchFixture)
