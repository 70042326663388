import React, { useState } from 'react'
import * as _ from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { getDetailOddsboxName, getOddsRates, getTeamName } from '~/game/st-util'
import { Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import { observer } from 'mobx-react'
import useRootStore from '~/store/useRootStore'
import { Theme } from '@mui/material/styles'
import DetailOddsBoxAtom from '../PrematchDetailOdds/DetailOddsBoxAtom'
import { SPORT_FLIGHT } from '~/store/sportsStore/sports-util'

interface OddsProps {
  theme: Theme
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  prematch: Schema$Prematch
  market: Schema$Market
}

function OddsBox12({ theme, desktop, lang, flight, prematch, market }: OddsProps) {
  const { sportsStore } = useRootStore()
  const { cartItems } = sportsStore

  const { FixtureId } = prematch

  const unifiedName = '12'
  const { bets } = getOddsRates(unifiedName, null, market.Bets)
  const [home, away] = bets

  if (!home || !away) {
    return <></>
  }
  const homeName = desktop
    ? getTeamName(prematch, '1', lang)
    : getDetailOddsboxName(unifiedName, prematch, market.Id, home, lang)
  const awayName = desktop
    ? getTeamName(prematch, '2', lang)
    : getDetailOddsboxName(unifiedName, prematch, market.Id, away, lang)

  const foundCartItem = _.find(cartItems, o => {
    return o.fixtureId === FixtureId && o.marketId === market.Id
  })
  const homeSelected = foundCartItem && home?.Id === foundCartItem.bet.Id
  const awaySelected = foundCartItem && away?.Id === foundCartItem.bet.Id

  const height = '30px'
  const midWidth = 120
  const sideWidth = `calc(50% - ${midWidth * 0.5}px)`

  return (
    <Grid container justifyContent="space-between" columnSpacing={'3px'}>
      <Grid item sx={{ width: sideWidth, height: height }}>
        {home ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={homeName}
            unifiedName={unifiedName}
            atomName="home"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={home}
            selected={homeSelected}
            drawSymbol={true}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
      <Grid item sx={{ width: `${midWidth}px`, height: height }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            width: '100%',
            height: '100%',
            border: '1px solid #182f49',
          }}
        >
          <Typography sx={{ color: '#838485', fontWeight: 'bold' }}>VS</Typography>
        </Box>
      </Grid>
      <Grid item sx={{ width: sideWidth, height: height }}>
        {away ? (
          <DetailOddsBoxAtom
            theme={theme}
            name={awayName}
            unifiedName={unifiedName}
            atomName="away"
            fixtureId={FixtureId}
            marketId={market.Id}
            marketName={market.Name}
            market={market}
            bet={away}
            selected={awaySelected}
            drawSymbol={true}
          ></DetailOddsBoxAtom>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default observer(OddsBox12)
