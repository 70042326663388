import Loadable from 'react-loadable'
import { filter } from 'lodash'

import PageLoading from '@components/PageLoading'

const loadComponent = (loader: () => Promise<any>) => Loadable({ loader, loading: PageLoading })

export const asynchronousComponents = {
  SocketDebugger: loadComponent(() =>
    import(/* webpackChunkName: "socket-debugger" */ 'containers/views/SocketDebugger'),
  ),
}

// all routers key
export type AsynchronousComponentKeys = keyof typeof asynchronousComponents

export interface RouteMenu {
  id: string
  exact: boolean
  signedin?: boolean
  path?: string
  name?: string
  popup?: string
  popupIndex?: number
  icon?: () => JSX.Element
  component?: AsynchronousComponentKeys
  locale?: string
}

export const ServiceMenus: RouteMenu[] = [
  {
    id: '1',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: '',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.service.cpa',
  },
  {
    id: '2',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: '',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.service.cpc',
  },
  {
    id: '3',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: '',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.service.cps',
  },
  {
    id: '4',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: '',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.service.cpt',
  },
]

export const GuideMenus: RouteMenu[] = [
  {
    id: '11',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'guide',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.guide.beginners',
  },
  {
    id: '12',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'guide',
    popupIndex: 1,
    icon: null,
    component: null,
    locale: 'menu.sider.guide.faq',
  },
]

export const BankMenus: RouteMenu[] = [
  {
    id: '21',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'bank',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.bank.req-deposit',
  },
  {
    id: '22',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'bank',
    popupIndex: 1,
    icon: null,
    component: null,
    locale: 'menu.sider.bank.req-withdraw',
  },
  {
    id: '23',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'bank',
    popupIndex: 2,
    icon: null,
    component: null,
    locale: 'menu.sider.bank.history',
  },
]

export const NoticeMenus: RouteMenu[] = [
  {
    id: '31',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'notice',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.notice.event',
  },
  {
    id: '32',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'notice',
    popupIndex: 1,
    icon: null,
    component: null,
    locale: 'menu.sider.notice.private-qna',
  },
  {
    id: '33',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'notice',
    popupIndex: 2,
    icon: null,
    component: null,
    locale: 'menu.sider.notice.alliance-qna',
  },
]

export const MyInfoMenus: RouteMenu[] = [
  {
    id: '41',
    exact: true,
    signedin: true,
    path: '',
    name: '',
    popup: 'myinfo',
    popupIndex: 0,
    icon: null,
    component: null,
    locale: 'menu.sider.myinfo.change',
  },
  {
    id: '42',
    exact: true,
    signedin: true,
    path: '',
    name: 'logout',
    icon: null,
    component: null,
    locale: 'menu.sider.myinfo.logout',
  },
]

export const PartnerMembersMenus: RouteMenu[] = [
  {
    id: '101',
    exact: true,
    signedin: true,
    path: '/partner/members',
    name: '',
    icon: null,
    component: null,
    locale: 'menu.sider.partner-members.list',
  },
]

export const PartnerBankTransactionMenus: RouteMenu[] = [
  {
    id: '111',
    exact: true,
    signedin: true,
    path: '/partner/bank-transaction',
    name: '',
    icon: null,
    component: null,
    locale: 'menu.sider.partner-bank-transaction.list',
  },
]

export const PartnerBalanceAccountMenus: RouteMenu[] = [
  {
    id: '121',
    exact: true,
    signedin: true,
    path: '/partner/balance-account',
    name: '',
    icon: null,
    component: null,
    locale: 'menu.sider.partner-balance-account.list',
  },
]

export const PartnerTransactionHistoryMenus: RouteMenu[] = [
  {
    id: '131',
    exact: true,
    signedin: true,
    path: '/partner/transaction-history',
    name: '',
    icon: null,
    component: null,
    locale: 'menu.sider.partner-transaction-history.list',
  },
]
