/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, css, ThemeProvider, Theme, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import { Container, Divider, Grid, MenuItem, Tab, Tabs, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { LinearProgress } from '@mui/material'
import {
  getGainLossColor,
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
} from '@utils/utils'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import {
  //GlobalStyle,
  a11yProps,
  PageStyle,
} from '~/containers/shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { Schema$PartnerBettingRecordTrackData, IPartnerBettingRecord } from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { BET_STATE, ALL_GAME_INFO } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: number
  uuid: string
  bet_at: string
  result_at: string
  user_id: string
  nickname: string
  kind_name: string
  game_name: string
  bet_state: string
  bet_volume: number
  win_volume: number
  win_lose_offset: number
  // less_commission: number
  // less_losing: number
  // member_commission: number
  // member_losing: number
  // my_commission: number
  // my_losing: number
  status: string
}

function Head(kind_name_hidden: boolean) {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅시간</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>결과시간</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>아이디</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>닉네임</Typography>
        </MyTableCell>
        <MyTableCell hidden={kind_name_hidden} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>종류</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>이름</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>상태</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>당첨</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>베팅손익</Typography>
        </MyTableCell>
        {/* <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>총 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>총 루징</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>회원 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>회원 루징</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>내 롤링P</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>내 루징</Typography>
        </MyTableCell> */}
      </TableRow>
    </TableHead>
  )
}

function Row(props: {
  row: RowData
  kind_name_hidden: boolean
  onClick: (field: string, id: number) => void
}) {
  const { row, kind_name_hidden, onClick } = props

  const isSum = row.status === 'sum'
  let rowStyle = isSum ? { background: '#d8e4ff' } : {}

  const betStateColor = row.bet_state === 'WIN' ? 'blue' : row.bet_state === 'LOSE' ? 'red' : ''
  const betStateName =
    _.find(BET_STATE, o => {
      return o.id === row.bet_state
    })?.name || ''

  const betting_time = row.bet_at ? moment(row.bet_at).format('YYYY-MM-DD HH:mm:ss') : ''
  const outcome_time = row.result_at ? moment(row.result_at).format('YYYY-MM-DD HH:mm:ss') : ''

  const colorCancel = row.bet_state === 'CANCEL' ? '#b9b9b9' : ''

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...rowStyle }}>
        {isSum ? (
          <MyTableCell align="center" colSpan={kind_name_hidden ? 6 : 7}>
            <Typography sx={{ fontSize: '12x' }}>합계</Typography>
          </MyTableCell>
        ) : (
          <>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12x' }}>{betting_time}</Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12x' }}>{outcome_time}</Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => onClick('account', row.id)}
              >
                {row.user_id}
              </Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => onClick('account', row.id)}
              >
                {row.nickname}
              </Typography>
            </MyTableCell>
            <MyTableCell hidden={kind_name_hidden} align="center">
              <Typography sx={{ fontSize: '12x' }}>{row.kind_name}</Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12x' }}>{row.game_name}</Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12px', color: betStateColor }}>
                {betStateName}
              </Typography>
            </MyTableCell>
          </>
        )}
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x', color: colorCancel }}>
            {numberWithCommas(Math.floor(row.bet_volume))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x', color: colorCancel }}>
            {numberWithCommas(Math.floor(row.win_volume))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{
              fontSize: '12px',
              color: getGainLossColor(Math.floor(row.win_lose_offset)) || colorCancel,
            }}
          >
            {numberWithCommas(Math.floor(row.win_lose_offset))}
          </Typography>
        </MyTableCell>
        {/* <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.less_commission)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(row.less_losing) }}>
            {numberWithCommas(row.less_losing)}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.member_commission)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(row.member_losing) }}>
            {numberWithCommas(row.member_losing)}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>{numberWithCommas(row.my_commission)}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(row.my_losing) }}>
            {numberWithCommas(row.my_losing)}
          </Typography>
        </MyTableCell> */}
      </TableRow>
    </Fragment>
  )
}

type TrackData = Schema$PartnerBettingRecordTrackData
const ItemPerPage = 15

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const SEARCH_KEY_NAMES = [
  { id: 'ID', name: '아이디' },
  { id: 'NICK', name: '닉네임' },
]

const end = new Date()
const begin = new Date()
//const begin = new Date(end.getFullYear(), end.getMonth(), 1)

function PartnerIntergratedBettingRecord({ location }) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { main, page } = query

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchBegin, setSearchBegin] = useState<Date>(begin)
  const [searchEnd, setSearchEnd] = useState<Date>(end)
  const [searchKey, setSearchKey] = useState('ID')
  const [searchPeriod, setSearchPeriod] = useState('')
  const [includeLess, setIncludeLess] = useState(false)
  const refSearchValue = useRef<string>('')

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { option } = globalStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups, open_trading_kinds } = option || {}

  let globalWidth = {}
  if (desktop) {
    globalWidth = {
      minWidth: '1400px',
    }
  }

  const GlobalStyle = (theme: Theme) => css`
    html,
    body {
      background: ${theme.colors.subBg};
    }
    body {
      ${globalWidth}
    }
  `

  const tab_list: {
    group: string
    name: string
    category?: string
    source?: string
  }[] = []
  tab_list.push({ group: 'ALL', name: '전체' })
  for (let i = 0; i < ALL_GAME_INFO.length; i++) {
    const found = _.find(open_trading_groups, o => {
      return o.trading_group === ALL_GAME_INFO[i].group
    })
    if (found) {
      tab_list.push(ALL_GAME_INFO[i])
    }
  }

  const startTab = Math.max(
    0,
    _.findIndex(tab_list, o => {
      return o.group === main
    }),
  )
  const startPage = page ? Number(page) : 1

  if (startTab !== tabPage.tab || startPage !== tabPage.page) {
    setTabPage({ tab: startTab, page: startPage })
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-BETTING')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.betting_record)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const fetch = async (params: IPartnerBettingRecord.Params) => {
    setLoading(true)

    try {
      const { data } = await apiPartner.getBettingRecord(params)
      setTrack(data)
    } catch (err) {
      console.log(err)
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    const params: IPartnerBettingRecord.Params = {
      target_uuid: '',
      trading_group: tab_list[tabPage.tab].group,
      start_date: searchBegin.toString(),
      end_date: searchEnd.toString(),
      searchKey: searchKey,
      searchValue: refSearchValue.current,
      includeLess: includeLess,

      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetch(params)
  }, [tabPage])

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
    globalStore.showModal('login', 0)
    return <></>
  }

  const handleChangeTab = (event, newValue) => {
    history.push(`/partner/betting-record/search?main=${tab_list[newValue].group}`)
  }

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
    setSearchPeriod('')
  }

  const handleChangeSearchKey = event => {
    setSearchKey(event.target.value)
  }

  const handleChangeSearchValue = event => {
    refSearchValue.current = event.target.value
  }

  const handleChangeIncludeLess = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeLess(event.target.checked)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/partner/betting-record/search?main=${tab_list[tabPage.tab].group}&page=${value}`)
  }

  const onClickSearchPeriod = newValue => {
    setSearchPeriod(searchPeriod === newValue ? '' : newValue)
    const now = new Date()
    switch (newValue) {
      case 'today':
        setSearchBegin(now)
        setSearchEnd(now)
        break
      case '7':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 6))
        setSearchEnd(now)
        break
      case '15':
        setSearchBegin(new Date(now.getFullYear(), now.getMonth(), now.getDate() - 14))
        setSearchEnd(now)
        break
    }
  }

  const onClickSearch = () => {
    const params: IPartnerBettingRecord.Params = {
      target_uuid: '',
      trading_group: tab_list[tabPage.tab].group,
      start_date: searchBegin.toString(),
      end_date: searchEnd.toString(),
      searchKey: searchKey,
      searchValue: refSearchValue.current.trim(),
      includeLess: includeLess,

      offset: 0,
      limit: ItemPerPage,
    }

    fetch(params)
  }

  const onClickCell = (field: string, id: number) => {
    if (track) {
      const found = _.find(track.items, o => {
        return o.bet_id === id
      })
      if (found) {
        let url = ''
        switch (field) {
          case 'account':
            popupPartnerMemberWithPeriod(found.uuid, 'betting_record', searchBegin, searchEnd)
            break
          case 'detail':
            break
        }
      }
    }
  }

  const tabItems = []
  for (let i = 0; i < tab_list.length; i++) {
    tabItems.push(
      <Tab
        label={tab_list[i].name}
        {...a11yProps(i)}
        style={{ fontSize: '14px' }}
        key={tab_list[i].group}
      />,
    )
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const rows = []
  if (track) {
    for (const el of track.items) {
      const trading = _.find(tab_list, o => {
        return o.group === el.trading_group
      })
      const trading_kind = _.find(open_trading_kinds, o => {
        return o.trading_kind === el.trading_kind
      })
      const tradingName = trading?.name || ''

      const row: RowData = {
        id: el.bet_id,
        uuid: el.uuid,
        bet_at: el.bet_at,
        result_at: el.result_at,
        user_id: el.user_id,
        nickname: el.nickname,
        kind_name: tradingName,
        game_name: trading_kind
          ? `${trading_kind.name} - ${el.game_name}`
          : tradingName
            ? `${tradingName} - ${el.game_name}`
            : el.game_name,
        bet_state: el.bet_state,
        bet_volume: el.bet_amount,
        win_volume: el.win_amount,
        win_lose_offset: el.win_lose_offset,
        // less_commission: el.less_commission,
        // less_losing: el.less_losing,
        // member_commission: el.member_commission,
        // member_losing: el.member_losing,
        // my_commission: el.my_commission,
        // my_losing: el.my_losing,
        status: 'normal',
      }
      rows.push(
        <Row key={row.id} row={row} kind_name_hidden={tabPage.tab > 0} onClick={onClickCell}></Row>,
      )
    }

    // if (rows.length > 0 && track.sum) {
    if (track.sum) {
      const sumData: RowData = {
        id: 0,
        uuid: '',
        bet_at: '',
        result_at: '',
        user_id: '',
        nickname: '',
        kind_name: '',
        game_name: '',
        bet_state: '',
        bet_volume: track.sum.bet_amount,
        win_volume: track.sum.win_amount,
        win_lose_offset: track.sum.win_lose_offset,
        // less_commission: track.sum.less_commission,
        // less_losing: track.sum.less_losing,
        // member_commission: track.sum.member_commission,
        // member_losing: track.sum.member_losing,
        // my_commission: track.sum.my_commission,
        // my_losing: track.sum.my_losing,
        status: 'sum',
      }
      rows.unshift(
        <Row
          key={'sum_top'}
          row={sumData}
          kind_name_hidden={tabPage.tab > 0}
          onClick={onClickCell}
        ></Row>,
      )
      rows.push(
        <Row
          key={'sum_bottom'}
          row={sumData}
          kind_name_hidden={tabPage.tab > 0}
          onClick={onClickCell}
        ></Row>,
      )
    }

    pageData = getPageData(track)
  }

  const filterIdItems = []
  for (const el of SEARCH_KEY_NAMES) {
    filterIdItems.push(
      <MenuItem key={el.id} value={el.id}>
        {el.name}
      </MenuItem>,
    )
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1400px',
          maxWidth: '1400px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>베팅 내역</Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tabPage.tab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabItems}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ height: '36px', background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '15' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('15')}
                >
                  15일
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === '7' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('7')}
                >
                  1주
                </Button>
                <Button
                  variant="contained"
                  sx={{ background: searchPeriod === 'today' ? '#fdb721' : 'grey' }}
                  onClick={() => onClickSearchPeriod('today')}
                >
                  오늘
                </Button>
                {/* <FormGroup>
                  <FormControlLabel
                    sx={{ marginTop: '-6px' }}
                    control={
                      <Checkbox
                        checked={includeLess}
                        onChange={handleChangeIncludeLess}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                      />
                    }
                    label="하위포함"
                  />
                </FormGroup> */}
                <TextField
                  onChange={handleChangeSearchValue}
                  size="small"
                  variant={'outlined'}
                  label="검색값"
                  style={{ width: '180px' }}
                ></TextField>
                <TextField
                  value={searchKey}
                  onChange={handleChangeSearchKey}
                  select
                  size="small"
                  variant={'outlined'}
                  label="검색키"
                  style={{ width: '100px' }}
                >
                  {filterIdItems}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
              <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
              <Table size="small" aria-label="collapsible table">
                {Head(tabPage.tab > 0)}

                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <PageStyle
                count={pageData.totalPage}
                variant="outlined"
                page={pageData.currPage}
                onChange={handlePagingChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedBettingRecord)
