/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Grid, FormGroup, FormControlLabel, Checkbox, Typography, Stack } from '@mui/material'
import { Formik } from 'formik'
import * as Yup from 'yup'
import * as _ from 'lodash'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import { useHistory } from 'react-router-dom'
//import WhiteTheme from '@themes/white.theme'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { MYPAGE_TAB_VALUES } from '~/constants/attrs'
import { IBankState, Schema$BankState } from '@protocol/bank'
import apiUser from '@services/api/user'
import { invalidateNumber, numberWithCommas, popupCenter } from '@utils/utils'
import { BANK_MENU } from '~/constants/attrs'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'
import NumberFormat from 'react-number-format'

// css
import { FormStyle } from '@styles/modal.style'
import { BasicButton, ItemButton } from '@styles/button.style'
import { DepositCoinStyle } from '../index.style'

const REPRESENTIVE_SITE = process.env.REACT_APP_REPRESENTIVE_SITE || ''
const JUN200_URL = process.env.REACT_APP_JUN200_URL || ''

const RequestDepositCoinJun200: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [bonusChecked, setBonusChecked] = useState(-1)
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const { globalStore, userStore } = useRootStore()
  const refBlockSubmitting = useRef<boolean>(false)

  const { userInfo } = userStore

  let canDeposit = false
  let txtDepositInfo = null
  let canBonus = false

  if (bankState) {
    canDeposit = bankState.can_deposit
    txtDepositInfo = bankState.deposit_coin.deposit_text

    canBonus = bankState.is_bonus_active
    if (!canBonus && bonusChecked !== 0) {
      setBonusChecked(0)
    }
  }

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  useEffect(() => {
    fetchBankState({})
  }, [])

  const submit = async (values: any) => {
    if (refBlockSubmitting.current) {
      return
    }
    if (!canDeposit || !JUN200_URL) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.cannot.request.recharge' }),
      })
      return
    }
    const { name, volume } = values
    if (!name) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.deposit-name-require' })}`,
      })
      return
    }

    let amount = invalidateNumber(volume)
    try {
      if (bonusChecked === -1) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.check.recharge_bonus' }),
        })
        return
      }

      await apiUser.depositCoinJun200({
        name: name.trim(),
        volume: amount,
        getBonus: bonusChecked === 1,
      })
      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-deposit-done' }),
      })

      // popup
      const userid = userInfo.id
      const domain = REPRESENTIVE_SITE
      const coinNumber = amount / 10000
      const ownerName = name.trim()

      const url = `${JUN200_URL}?uid=${userid}&site=${domain}&p=${coinNumber}&rec_name=${ownerName}`

      const w = 490
      const h = 750
      popupCenter({ url, title: 'popup_id', w, h })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const handleChangeBonus = (v: number) => {
    setBonusChecked(v)
  }

  const onClickAskAccount = (type: string) => {
    history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=deposit`)
  }

  const min_amount = bankState ? bankState.transaction.min_deposit_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_deposit_unit : 1000
  const transaction_text = `※ 충전은 ${numberWithCommas(min_amount)}원 이상부터 ${numberWithCommas(
    min_unit,
  )}원 단위로 신청 가능`

  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Grid item xs={10} sx={{ py: 2 }}>
        {/* <Box sx={{ display: 'flex', justifyContent: 'end' }}>
          <BasicButton className="btn_main" onClick={() => onClickAskAccount('wonp')} sx={{ ml: 1 }}>
            {intl.formatMessage({ id: 'component.private-qna.deposit' })}
          </BasicButton>
        </Box> */}
        <Formik initialValues={{ name: '', volume: 0 }} onSubmit={submit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setSubmitting,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <FormStyle>
                <Grid container sx={{ mt: 1, mb: 2, alignItems: 'center' }}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <label className="form_label">{intl.formatMessage({ id: 'component.bank.deposit-name' })}</label>
                  </Grid>
                  <Grid item xs={12}>
                    <input
                      className="form_control"
                      name="name"
                      placeholder={intl.formatMessage({ id: 'component.bank.deposit-placeholder' })}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ fontSize: '1.3rem', textAlign: 'end' }}
                    />
                    {touched.name && errors.name && <div>{errors.name}</div>}
                  </Grid>
                </Grid>

                <Grid container sx={{ mb: 2, alignItems: 'baseline' }}>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <label className="form_label required">
                      {intl.formatMessage({ id: 'component.bank.deposit-volume' })}
                    </label>
                  </Grid>
                  <Grid item xs={12}>
                    <NumberFormat
                      className="form_control"
                      name="volume"
                      placeholder={intl.formatMessage({ id: 'component.bank.volume-placeholder' })}
                      value={values.volume}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      thousandSeparator={true}
                      style={{ fontSize: '1.3rem', textAlign: 'end' }}
                    ></NumberFormat>
                    {touched.volume && errors.volume && <div>{errors.volume}</div>}
                    <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
                      <Typography sx={{ fontSize: '12px', color: 'red' }}>{transaction_text}</Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 2,
                        '& > button': {
                          width: 'calc(25% - 4px)',
                        },
                      }}
                    >
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[0])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume0' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[1])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume1' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[2])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume2' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[3])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume3' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[4])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume4' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[5])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume5' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          let tmp = invalidateNumber(values.volume)
                          setFieldValue('volume', tmp + BankServiceVolumes[6])
                        }}
                      >
                        {intl.formatMessage({ id: 'component.bank.volume6' })}
                      </ItemButton>
                      <ItemButton
                        onClick={() => {
                          refBlockSubmitting.current = true
                          setFieldValue('volume', 0)
                        }}
                        className="clear"
                      >
                        {intl.formatMessage({ id: 'component.bank.volume-clear' })}
                      </ItemButton>
                    </Box>
                  </Grid>
                </Grid>

                <DepositCoinStyle>
                  <NeatTextField
                    multiline
                    defaultValue={txtDepositInfo}
                    InputProps={{ readOnly: true }}
                  ></NeatTextField>
                </DepositCoinStyle>
              </FormStyle>

              {canBonus ? (
                <Box sx={{ mt: 2, mx: 'auto', width: '300px' }}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bonusChecked === 1}
                          onChange={() => handleChangeBonus(1)}
                          sx={{ py: 1 / 2, color: '#3B4A65', '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                        />
                      }
                      sx={{ color: '#D1D9E3' }}
                      label="첫충(매충) 보너스 받기"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={bonusChecked === 0}
                          onChange={() => handleChangeBonus(0)}
                          sx={{ py: 1 / 2, color: '#3B4A65', '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                        />
                      }
                      sx={{ color: '#D1D9E3' }}
                      label="첫충(매충) 보너스 받지 않기"
                    />
                  </FormGroup>
                </Box>
              ) : null}

              <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 3 }}>
                <BasicButton
                  className="btn_main"
                  type="submit"
                  onClick={() => {
                    refBlockSubmitting.current = false
                    setFieldValue('volume', values.volume)
                  }}
                  disabled={isSubmitting}
                >
                  {intl.formatMessage({ id: 'component.bank.req-deposit' })}
                </BasicButton>
              </Stack>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default observer(RequestDepositCoinJun200)
