import React, { Fragment, useEffect, useRef, useState } from 'react'
import * as _ from 'lodash'
import Phaser from 'phaser'
import { getDeviceType, getMobileType } from '~/utils'
import EventManager from '../helpers/event-manager'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { Schema$Matchup } from '~/v2/interface/matchup'
import { toJS } from 'mobx'
import EntryLoaderScene from './entries/entry-loader'
import EntryPanelScene, {
  BetDoneEventData,
  BetEventData,
  BetRecordsEventData,
  InitEventData,
  OddsEventData,
} from './entries/entry-panel'
import { BetAction, BetDoneData } from './scenes/panel'
import apiGame from '@services/api/game'
import { Schema$BetEvo } from '~/v2/protocol/game'
import { MinigameMatchup } from '~/game/common'
import MobileEntryPanelScene from './entries/m-entry-panel'
import { useIntl } from 'react-intl'

EventManager.getInstance()

function Game() {
  const intl = useIntl()
  const game = useRef(null)
  const matchup = useRef<Schema$Matchup>(null)

  const { globalStore, userStore, miniGameStore } = useRootStore()
  const { message, matchupSource, oddsOptions } = miniGameStore

  const kind = 'EVOPB1'

  const phaserConfig: Phaser.Types.Core.GameConfig = {
    type: Phaser.AUTO,
    parent: 'phaser-game',
    scene: [EntryLoaderScene, EntryPanelScene, MobileEntryPanelScene],

    width: 1200,
    height: 360,
    // backgroundColor: '#4488aa',
    transparent: true,
    zoom: 1,
    input: {
      mouse: {
        preventDefaultWheel: false,
      },
      touch: false,
    },
    physics: {
      default: 'arcade',
      arcade: {
        debug: false,
      },
    },
    render: {
      pixelArt: true,
      antialias: true,
      antialiasGL: true,
    },
  }

  const fetchAction = async (actionName: string, data: BetAction) => {
    try {
      const betData: Schema$BetEvo[] = []
      if (data) {
        for (const el of data.data) {
          betData.push({
            marketId: el.marketId,
            oddsId: el.oddsId,
            oddsRate: el.oddsRate,
            betMoney: el.volume,
          })
        }
      }
      const { tradingId, userMoney } = await apiGame.betEvo({
        tradingGroup: 'EVOPB',
        tradingKind: 'EVOPB1',
        tradingId: matchup.current.id,
        action: actionName,
        actionKey: data?.actionKey || '',
        betData,
      })

      EventManager.getInstance().emit('evo:bet', { betDoneData: { tradingId } } as BetDoneEventData)

      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.betting_done' }),
      })

      userStore.setUserMoney(userMoney)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchTradingBetRecord = async (requestTradingId: number) => {
    try {
      const { tradingId, items } = await apiGame.tradingBetRecords({ tradingId: requestTradingId })
      EventManager.getInstance().emit('evo:records', { tradingId, items } as BetRecordsEventData)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  function resize() {
    const canvas = document.querySelector('canvas')
    if (canvas) {
      const configWidth = Number(phaserConfig.width)
      const configHeight = Number(phaserConfig.height)
      const isMobile = getDeviceType() === 'mobile'
      const windowWidth = isMobile ? window.outerWidth : window.innerWidth
      const windowHeight = isMobile ? window.outerHeight : window.innerHeight
      console.log(windowWidth, windowHeight)
      if (windowWidth < configWidth || windowHeight < configHeight) {
        const windowRatio = windowWidth / windowHeight
        const gameRatio = configWidth / configHeight
        if (windowRatio < gameRatio) {
          // width is smaller(center y position)
          canvas.style.width = windowWidth + 'px'
          canvas.style.height = windowWidth / gameRatio + 'px'
        } else {
          // height is smaller
          canvas.style.width = windowHeight * gameRatio + 'px'
          canvas.style.height = windowHeight + 'px'
        }
      } else {
        if (getDeviceType() === 'mobile') {
          const windowRatio = windowWidth / windowHeight
          const gameRatio = configWidth / configHeight
          if (windowRatio < gameRatio) {
            // width is smaller(center y position)
            canvas.style.width = windowWidth + 'px'
            canvas.style.height = windowWidth / gameRatio + 'px'
          } else {
            // height is smaller
            canvas.style.width = windowHeight * gameRatio + 'px'
            canvas.style.height = windowHeight + 'px'
          }
        } else {
          canvas.style.width = configWidth + 'px'
          canvas.style.height = configHeight + 'px'
        }
      }
      console.log(canvas.style.width, canvas.style.height)

      // canvas.style.position = 'absolute'
      // canvas.style.top = `${windowHeight} - ${canvas.style.height}`
      // canvas.style.top = `${windowHeight}`
      // canvas.style.left = '50%'
      // canvas.style.transform = 'translate(-50%, -50%)'
    }
  }

  useEffect(() => {
    if (getDeviceType() === 'mobile') {
      phaserConfig.width = 490
      if (getMobileType() === 'ios') {
        phaserConfig.height = 540
      } else {
        phaserConfig.height = 460
      }
    }

    if (!game.current) {
      game.current = new Phaser.Game(phaserConfig)

      if (getDeviceType() === 'mobile') {
        const wnd = globalThis || window
        if (wnd) {
          wnd.addEventListener('resize', resize, false)
        }

        resize()
      }

      EventManager.getInstance().on('bet', (data: BetAction) => {
        fetchAction('bet', data)
      })
    }
  }, [])

  useEffect(() => {
    const data: OddsEventData = {
      oddsOptions,
    }
    EventManager.getInstance().emit('evo:odds', data)
  }, [oddsOptions])

  useEffect(() => {
    // console.log('$$$$$$$$$$$ matchupSource updated!')
    const newMatchup = toJS(matchupSource)

    if (!matchup.current) {
      if (newMatchup && newMatchup.trading_kind === kind) {
        const eventId = 'evo:init'
        console.log(`$$ init matchup_id ${newMatchup.id} ${eventId}`)
        const data: InitEventData = {
          matchup: newMatchup,
        }
        EventManager.getInstance().emit(eventId, data)

        fetchTradingBetRecord(newMatchup.id)
      } else {
        const eventId = 'evo:init'
        console.log(`$$ init matchup_id null ${eventId}`)
        EventManager.getInstance().emit(eventId, null)
      }
    }

    matchup.current = newMatchup
  }, [matchupSource])

  useEffect(() => {
    const tmp = message.split(':')
    if (_.isArray(tmp)) {
      const [command, bet, type, trading_kind] = tmp

      if (command === 'command' && bet === 'bet' && kind === trading_kind) {
        if (matchup.current) {
          const data: BetEventData = {
            matchup: matchup.current,
          }
          if (type === 'start') {
            EventManager.getInstance().emit('evo:bet:start', data)
          } else if (type === 'end') {
            EventManager.getInstance().emit('evo:bet:end', data)
          }
        }
      }
    }
  }, [message])

  return <div id="phaser-game" />
}

export default observer(Game)
