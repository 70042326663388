// //
// // generated from "GameCommon.pd"
// //

// export enum NET_SYNC_STATE {
//   NONE = 0,
//   REQUESTED = 1,
//   RECEIVED = 2,
//   PROCESSED = 3,
// }

// export enum NETGAMETYPE {
//   UNKNOWN = 0,
//   ADVENTURER = 1,
// }

// export enum ACCOUNT_STATE {
//   REGISTERED = 0,
//   UNREGISTERED = 1,
//   BLOCKED = 2,
// }

// export enum ACCOUNT_ROLE {
//   NORMAL = 0,
//   OPERATOR = 1,
//   DEVELOPER = 2,
//   DUMMY = 3,
// }

// export enum BLOCK_REASON {
//   NONE = 0,
//   ADMIN_CONTROL = 1,
// }

// export enum SEX_TYPE {
//   NO_SET = 0,
//   MALE = 1,
//   FEMALE = 2,
//   UNISEX = 3,
// }

// export enum MSG_TYPE {
//   MSG_NORMAL = 1,
//   MSG_NOTICES = 1000,
//   MSG_LOGS = 2000,
//   MSG_BREAKNEWS = 3000,
// }

// export enum EVENT_KIND {
//   NON_EVENT_TYPE = 0,
//   EVENT_TYPE_PERIOD = 1,
//   EVENT_TYPE_RESTRICT = 2,
// }

// export enum EVENT_SERVER_STATUS {
//   EVENT_STATUS_CLOSED = 0,
//   EVENT_STATUS_WAITTING = 1,
//   EVENT_STATUS_PROGRESSING = 2,
//   EVENT_STATUS_EXPIRED = 3,
// }

// export enum SORT_TYPE {
//   SORT_NONE = 0,
//   SORT_BIGGER = 1,
//   SORT_HIGHER = 2,
// }

// export enum ITEM_APPLY_TYPE {
//   ITEM_APPLY_INSTANT = 1,
//   ITEM_APPLY_PERIOD = 2,
//   ITEM_APPLY_MIXED = 3,
// }

// export enum LIST_FLAG {
//   LIST_BEGIN = 0,
//   LIST_CONTINUE = 1,
//   LIST_END = 2,
// }

// export enum EVENT_MAINTAIN_TYPE {
//   EVENT_MAINTAIN_NONE = 0,
//   EVENT_MAINTAIN_DAILY = 1,
//   EVENT_MAINTAIN_PERIOD = 2,
//   EVENT_MAINTAIN_REPEAT = 3,
// }

// export enum ITEM_ACTIVATION {
//   UNKNOWN = 0,
//   UNIQUE = 1,
//   MULTI = 2,
// }

// export enum KICK_REASON {
//   UNKNOWN = 0,
//   JUST_KICK = 1,
//   ANOTHER_LOGIN = 2,
//   BLOCK = 3,
//   GAMEMONEY_LOSE_LIMIT = 4,
//   USER_LOGOUT = 5,
//   NETWORK_STOP = 6,
//   USER_UNREGISTER = 7,
// }

// export enum ITEM_CONTAINER {
//   UNKNOWN = 0,
//   SYSTEM = 1,
//   BAG = 2,
//   MAIL = 3,
// }

// export enum ITEM_STATE {
//   HOLD = 0,
//   NORMAL = 1,
//   ACTIVATED = 2,
//   USED = 3,
//   EXPIRED = 4,
//   OVERLAPPED = 5,
//   RECOVER = 6,
// }

// export enum USAGE_LIMIT {
//   UNLIMITED = 0,
//   LEVEL_LIMITED = 1,
//   TIME_LIMITED = 2,
//   SYSTEM_LIMITED = 3,
// }

// export enum EVENT_TYPE {
//   UNKNOWN = 0,
//   LOGIN = 1,
//   COUPON = 2,
//   IAB = 3,
//   GAMERESULT = 4,
//   FIRST_SALE = 5,
// }

// export enum MISSION_PERIOD_TYPE {
//   DAILY = 0,
//   WEEKLY = 1,
//   CHALLENGE = 2,
// }

// export enum BET_MONEYINPUT_TYPE {
//   SINGLE = 0,
//   SINGLE_ALL = 1,
//   MULTI = 2,
// }

// export enum DEMOGRAPHIC_LOG_TYPE {
//   UNKNOWN = 0,
//   REGISTER = 1,
//   LOGIN = 2,
//   LOGOUT = 3,
//   DROPOUT = 4,
// }

// export enum IAB_STORE_CODE {
//   UNKNOWN = 0,
//   GOOGLE = 1,
//   NAVER = 2,
//   TSTORE = 3,
// }

// export enum REGULATION_TYPE {
//   NO_ERR = 0,
//   MUST_IDENTIFICATION_IN_1_MONTH = 1,
//   MUST_IDENTIFICATION_IN_2_MONTH = 2,
//   MUST_IDENTIFICATION_IN_3_MONTH = 3,
//   MUST_IDENTIFICATION_NOW = 4,
//   CASH_CHARGE_MONTH_OVER = 5,
//   CASH_LOSS_DAILY_OVER = 6,
//   CASH_GAME_ROUND_OVER = 7,
//   CASH_LUCKY_CHANCE_EXIPREDATE_OVER = 8,
// }

// export enum SALE_TAG {
//   NO = 0,
//   EVENT = 1,
//   NEW = 2,
//   HOT = 3,
//   BONUS = 4,
// }

// export enum LEADERBOARD_TYPE {
//   NONE = 0,
//   BETTOR_RP = 1,
//   PICKSTER_RP = 2,
//   CLUB_RP = 3,
//   EVENT_PROFIT = 4,
// }

// export enum LEADERBOARD_ACTOR {
//   USER = 0,
//   CLUB = 1,
// }

// export enum COSTUME_ID {
//   NONE = 0,
//   HEAD = 1,
//   FACEPAINTING = 2,
//   GLASSES = 3,
//   EARRING = 4,
// }

// export enum LICENSE_ID {
//   NONE = 0,
//   FREE = 1,
//   COURT = 2,
//   ARENA = 3,
//   STADIUM = 4,
// }

// export enum CLUB_MEMBERSHIP_ID {
//   NONE = 0,
//   FREE = 1,
//   COURT = 2,
//   ARENA = 3,
//   STADIUM = 4,
// }

// export enum ITEM_CONSUME_TYPE {
//   IMMORTAL = 0,
//   QUANTITY = 1,
//   ACTIVE_PERIOD = 2,
//   PASSIVE_PERIOD = 3,
// }

// export enum ITEM_USAGE_TYPE {
//   INSTANT = 0,
//   NORMAL = 1,
//   ACTIVE = 2,
//   PASSIVE = 3,
//   ACTIVE_AUTO = 4,
// }

// export enum GAME_STATUS {
//   AVAILABLE = 0,
//   RESERVED = 1,
//   PLAYING = 2,
// }

// export enum FRIEND_STATE {
//   FRIEND = 0,
//   REQUEST_SENT = 1,
//   REQUEST_RECEIVED = 2,
//   REQUEST_REFUSED = 3,
// }

// export enum JOB_TYPE {
//   NONE = 0,
//   BETTOR = 1,
//   ODDSMAKER = 2,
// }

// export enum CLUB_STATE {
//   NONE = 0,
//   SEEK_APPROVAL = 1,
//   APPROVAL_DENIED = 2,
//   APPROVED = 3,
//   AGREED = 4,
//   PAUSED = 5,
//   RUNNING = 6,
//   CLOSING = 7,
//   CLOSED = 8,
// }

// export enum CLUB_CLOSING_REASON {
//   NONE = 0,
//   SEEK_CLOSE = 1,
//   EXPIRED = 2,
//   ADMIN = 3,
// }

// export enum CLUB_PAUSE_REASON {
//   NONE = 0,
//   SEEK_PAUSE = 1,
//   EXPIRED = 2,
//   ADMIN = 3,
// }

// export enum CLUB_MEMBER_STATE {
//   NONE = 0,
//   SEEK_MEMBER = 1,
//   DENIED = 2,
//   APPROVED = 3,
//   MEMBER = 4,
//   SELF_OUT = 5,
// }

// export enum CLUB_CANCEL_OPTION {
//   WITHIN_5 = 0,
//   WITHIN_30 = 1,
//   WITHIN_60 = 2,
// }

// export enum CLUB_EVENT_TYPE {
//   NONE = 0,
//   PROFIT = 1,
//   YOUTUBE_CHANNEL = 2,
// }

// export enum SEEK_TYPE {
//   NONE = 0,
//   JOIN_CLUB = 1,
// }

// export enum BET_CANCEL_TYPE {
//   NONE = 0,
//   BY_BETTOR = 1,
//   BY_CLUB = 2,
// }

// export enum MATCHUP_CATEGORY {
//   CROSS = 0,
//   WIN_DRAW_LOSE = 1,
//   HANDICAP = 2,
//   LIVE = 3,
//   MATCH_DETAIL = 4,
//   LIVE_DETAIL = 5,
//   SPECIAL = 6,
// }

// export enum MATCHUP_TAB_CATEGORY {
//   CROSS = 0,
//   WIN_DRAW_LOSE = 1,
//   HANDICAP = 2,
//   LIVE = 3,
//   SPECIAL = 4,
//   MATCHUP_RESULT = 5,
// }

// export enum MATCHUP_WAY {
//   UNKNOWN = 0,
//   WIN = 1,
//   DRAW = 2,
//   LOSE = 3,
// }

// export enum MATCHUP_CATEGORY_FOR_UI_MOBILE {
//   CROSS = 0,
//   WIN_DRAW_LOSE = 1,
//   HANDICAP = 2,
//   LIVE = 3,
//   MATCH_DETAIL = 4,
//   LIVE_DETAIL = 5,
//   SPECIAL = 6,
//   CROSS_SINGLE_ON = 7,
// }

// export enum CLUB_NOTICE_TYPE {
//   NOTICE = 0,
//   EVENT = 1,
// }

// export enum TERM {
//   NONE = 0,
//   DAY = 1,
//   WEEK = 2,
//   MONTH = 3,
//   YEAR = 4,
//   TOTAL = 5,
// }

// export enum SEEK_APPROVE_TYPE {
//   APPROVAL = 0,
//   AUTO = 1,
//   DENY = 2,
// }

// export enum TERM_SEEK {
//   TODAY = 0,
//   WEEK = 1,
//   MONTH = 2,
//   TWOMONTH = 3,
//   BETWEEN = 4,
// }

// export enum PICK_TYPE {
//   PUBLISH = 0,
//   PURCHASE = 1,
//   DATA = 2,
//   MARKET = 3,
// }

// export enum MY_PICK_REQUEST {
//   ALL = 0,
//   ONGOING = 1,
//   CLOSED = 2,
//   HIT = 3,
//   FAIL = 4,
// }

// export enum MY_PICK_FILTER {
//   DATE_REGISTER = 0,
//   DATE_BETTING = 1,
//   SALE_COUNT = 2,
//   PRICE = 3,
//   DIVIDEND_RATE = 4,
//   FOLD_COUNT = 5,
// }

// export enum MARKET_PICK_FILTER {
//   DATE_REGISTER = 0,
//   DATE_BETTING = 1,
//   SALE_COUNT = 2,
//   PRICE = 3,
//   DIVIDEND_RATE = 4,
//   FOLD_COUNT = 5,
// }

// export enum LIST_ORDER {
//   ASCENDING = 0,
//   DESCENDING = 1,
// }

// export enum CHARGE_TYPE {
//   ALL = 0,
//   FREE = 1,
//   CHARGE = 2,
// }

// export enum SEARCH_BETTOR_FILTER {
//   LEVEL = 0,
//   HIT_RATIO = 1,
// }

// export enum SEARCH_CLUB_TYPE {
//   ALL = 0,
//   MONEY = 1,
//   HEART = 2,
// }

// export enum SEARCH_CLUB_FILTER {
//   DATE_OPEN = 0,
//   VISITOR = 1,
//   ASSET = 2,
//   SOLUBLE = 3,
// }

// export enum SEARCH_BET_LIST_STATE {
//   ALL = 0,
//   ONGOING = 1,
//   CLOSED = 2,
// }

// export enum SEARCH_BET_LIST_FILTER {
//   DATE_BET = 0,
//   DIVIDEND_RATE = 1,
//   FOLD_COUNT = 2,
//   DIVIDEND_MONEY = 3,
//   BET_MONEY = 4,
//   DIVIDEND_HEART = 5,
//   BET_HEART = 6,
// }

// export enum MESSAGE_TYPE {
//   SYSTEM = 0,
//   NORMAL = 1,
//   CLUB_INVITE = 2,
//   GIFT = 3,
//   REWARD = 4,
//   NOTICE = 5,
//   PICKSTER_INVITE = 6,
//   GAME_REWARD = 7,
//   MEMBER_TO_CLUB = 8,
//   CLUB_TO_MEMBER = 9,
//   CLUB_TO_ALL_MEMBER = 10,
//   OTHER_TO_CLUB = 11,
//   MSG_ONLY_RECV = 12,
//   AD_MSG_SEND = 13,
//   AD_MSG_RECV = 14,
//   PICK_ADD = 15,
// }

// export enum MESSAGE_DIR {
//   SEND = 0,
//   RECV = 1,
// }

// export enum PROPERTY_MOVE_TYPE {
//   CONSUME = 0,
//   CHARGE = 1,
// }

// export enum PROPERTY_CONSUME_TYPE {
//   DEFAULT = 0,
//   BUY_PRODUCT = 1,
//   PRESENT_PRODUCT = 2,
//   BUY_PICK = 3,
//   BETTOR_BETTING = 4,
//   BETTOR_BETTING_COMMISION = 5,
//   CLUB_DEPOSIT = 6,
//   BETTOR_BETTING_ROLLBACK = 7,
//   PRESENT = 8,
//   DONATION = 9,
//   ADMIN = 10,
//   NONMEMBER_CLUB_MESSAGE = 11,
//   BIN_BETTING = 12,
//   PICK_ADD_MSG_PAY = 13,
//   BINARY_ACTIVE_PAY = 14,
//   BET_TICKET_ROLLBACK = 15,
// }

// export enum CLUB_ASSET_RECORD {
//   DEPOSIT = 0,
//   WITHDRAW = 1,
//   BETTING_HIT = 2,
//   BETTING_FAIL = 3,
//   BETTING_ROLLBACK = 4,
// }

// export enum COUPON_USAGE {
//   PRIVATE_SINGLE = 0,
//   PRIVATE_MULTI = 1,
//   PUBLIC = 2,
//   PRIVATE_BUNDLE = 3,
//   TARGET_BUNDLE = 4,
// }

// export enum SERVER_STATUS {
//   NONE = 0,
//   NORMAL = 1,
//   QA = 2,
//   CHECK = 3,
//   CLOSING = 4,
// }

// export enum TARGET_DEVICE {
//   ALL = 0,
//   PC = 1,
//   MOBILE = 2,
// }

// export enum SYSTEM_EVENT_TYPE {
//   NONE = 0,
//   TIME_CONDITION = 1,
//   TERM_PROFIT = 2,
//   DAILY_PROFIT = 3,
//   JOIN_CLUB = 4,
// }

// export enum CLUB_PROVIDE_ACT_TYPE {
//   NONE = 0,
//   ADD_ACT = 1,
//   DEL_ACT = 2,
// }

// export enum CLUB_PROVIDE_TARGET_TYPE {
//   NONE = 0,
//   TARGET_SPORT = 1,
//   TARGET_CATEGORY = 2,
//   TARGET_TOURNAMENT = 3,
// }

// export enum SIGNUP_CHANNEL {
//   UNKNOWN = 0,
//   MEMBERSHIP_PC = 1,
//   MEMBERSHIP_MOBILE = 2,
//   GOOGLE = 3,
//   NAVER = 4,
//   FACEBOOK = 5,
// }

// export enum BINARY_BET_LOW_HIGH {
//   NONE = 0,
//   BET_LOW = 1,
//   BET_HIGH = 2,
//   BET_EQUAL = 3,
// }

// export enum BINARY_BET_OPEN_UNDER_OVER {
//   NONE = 0,
//   UNDER = 1,
//   OVER = 2,
//   EQUAL = 3,
// }

// export enum BINARY_BET_ODD_EVEN {
//   NONE = 0,
//   ODD = 1,
//   EVEN = 2,
// }

// export enum BINARY_BET_DECIMAL_UNDER_OVER {
//   NONE = 0,
//   DECIMAL_UNDER = 1,
//   DECIMAL_OVER = 2,
// }

// export enum BINARY_GAME_PROCESS_STATE {
//   START = 0,
//   SUCCESS_RESULT = 1,
//   INVALID_RESULT = 2,
// }

// export enum BINARY_GAME_HIST_TYPE {
//   PAST_HOURS = 0,
//   A_DAY = 1,
// }

// export enum TEAM_TYPE {
//   NONE = 0,
//   HOME = 1,
//   AWAY = 2,
// }

// export enum WINNER_STATE {
//   NONE = 0,
//   HOME = 1,
//   AWAY = 2,
//   TIE = 3,
// }

// export enum BINARY_RESULT {
//   NONE = 0,
//   BIN_HIT = 1,
//   BIN_FAIL = 2,
//   BIN_EXCEPT = 3,
// }

// export enum TUTORIAL_MAX {
//   MAX = 10,
// }

// export enum NOTICE_ANIMATION_TYPE {
//   NONE = 0,
//   RIGHT_TO_LEFT = 1,
//   BLINK = 2,
// }

// export enum CLUB_BOARD_CATEGORY_TYPE {
//   NONE = 0,
//   GAME_ANAL = 1,
//   PICK_PUB = 2,
//   PROUD = 3,
//   ANY_TOK = 4,
// }

// export enum INAPP_CONSUMPTION_STATE {
//   PURCHASED = 0,
//   CONSUMED = 1,
//   UNKNOWN = 64,
// }

// export enum INAPP_PURCHASE_STATE {
//   PURCHASED = 0,
//   CANCELED = 1,
//   UNKNOWN = 64,
// }

// export enum INAPP_PURCHASE_TYPE {
//   TEST = 0,
//   PROMO = 1,
//   STANDARD = 32,
//   UNKNOWN = 64,
// }

// export enum RANK_CATEGORY {
//   NONE = 0,
//   BETTOR_RANK_ALL = 1,
//   BETTOR_RANK_SOCCER = 2,
//   BETTOR_RANK_BASEBALL = 3,
//   BETTOR_RANK_BASKET = 4,
//   BETTOR_RANK_VOLLEY = 5,
//   BETTOR_RANK_ETC = 6,
//   BETTOR_RANK_WIN_DRAW_LOSE = 7,
//   BETTOR_RANK_HANDICAP = 8,
//   BETTOR_RANK_LIVE = 9,
//   BETTOR_RANK_SPECIAL = 10,
//   BETTOR_RANK_DETAIL = 11,
//   PICKSTER_RANK_PROFIT = 12,
//   PICKSTER_RANK_HIT = 13,
//   CLUB_RANK_BETNUM = 14,
//   CLUB_RANK_VISIT = 15,
//   CLUB_RANK_HAVE_MONEY = 16,
//   CLUB_RANK_HAVE_HEART = 17,
// }

// export enum REF_SPORT_ID {
//   SP_SOCCER = 1,
//   SP_BASKET = 2,
//   SP_BASEBALL = 3,
//   SP_VOLLEY = 23,
// }

// export enum BINARY_GRAPH_TYPE {
//   NONE = 0,
//   ONE = 1,
//   TWO = 2,
//   THREE = 3,
//   AVERAGE = 4,
// }

// export enum BINARY_GAME_TYPE {
//   NONE = 0,
//   BITCOIN = 100000001,
//   BIT_THERMO = 100000002,
//   BIT_RABBIT = 100000003,
// }

// export enum BINARY_SPORT_INFO {
//   SPORT_ID = 100000001,
//   CATEGORY_ID = 1,
//   TOURNAMENT_ID = 1,
// }

// export enum BINARY_THERMO_INFO {
//   SPORT_ID = 100000002,
//   CATEGORY_ID = 1,
//   TOURNAMENT_ID = 1,
// }

// export enum BINARY_RABBIT_INFO {
//   SPORT_ID = 100000003,
//   CATEGORY_ID = 1,
//   TOURNAMENT_ID = 1,
// }

// export enum GAME_TYPE {
//   SPORTS = 0,
//   BINARY = 1,
// }

// export enum SAS_ROLE {
//   NONE = 0,
//   DISTRIBUTOR = 1,
//   WORKER = 2,
//   DUAL = 3,
// }

// export enum SAS_DISTRIBUTOR_STATE {
//   NORMAL = 0,
//   PAUSED = 1,
//   DROPPED = 2,
// }

// export enum SAS_DISTRIBUTOR_LEVEL {
//   UNKNOWN = 0,
//   ADMIN = 1,
//   LARGE = 2,
//   MEDIUM = 3,
//   SMALL = 4,
//   MIN = 1,
//   MAX = 4,
// }

// export enum CHAT_TYPE {
//   USER_CHAT = 0,
//   USER_SHARE_1 = 1,
//   USER_SHARE_2 = 2,
//   SYS_INFO_1 = 3,
//   SYS_INFO_2 = 4,
//   SYS_INFO_3 = 5,
//   SYS_ADMIN_1 = 6,
//   SYS_ADMIN_2 = 7,
//   SYS_ADMIN_3 = 8,
//   NOTICE_REFRESH_1 = 9,
// }

// export enum CHAT_BLOCK_TYPE {
//   CHAT_FREE = 0,
//   CHAT_BLOCK_TIME = 1,
//   CHAT_BLOCK_TIME_5M = 2,
//   CHAT_BLOCK_TIME_10M = 3,
//   CHAT_BLOCK_TIME_30M = 4,
//   CHAT_BLOCK_TIME_60M = 5,
//   CHAT_BLOCK_EVER = 6,
// }

// export enum BANNER_UPDATE_ACT {
//   ADD = 0,
//   MODIFY = 1,
// }

// export enum BANNER_TYPE {
//   JUST_IMAGE = 0,
//   SPECAIL_BINARY = 1,
//   SPORT_MATCH = 2,
// }

// export enum PRODUCT_EDITION_TYPE {
//   NORMAL_FREE = 0,
//   TIME_CONDITION = 1,
// }

export const BET_TYPE_ON = {
  MATCH: 0,
  LIVE: 1,
  SPECIAL: 2,
  UNIFIED_MATCH: 3,
  UNIFIED_LIVE: 4,
}

export const BET_HIT_STATE = {
  ONGOING: 0,
  SUCCESS: 1,
  FAIL: 2,
  CANCEL: 3,
}

export const BET_CALL_TYPE = {
  SINGLE: 0,
  MULTI: 1,
}

export const SERVER_STATUS = {
  NONE: 0,
  NORMAL: 1,
  QA: 2,
  CHECK: 3,
  CLOSING: 4,
}

export const LIST_FLAG = {
  LIST_BEGIN: 0,
  LIST_CONTINUE: 1,
  LIST_END: 2,
}

export const PROPERTY_ID = {
  NONE: 0,
  CASH: 1,
  MILEAGE: 2,
  POINT: 3,
  MONEY: 4,
  HEART: 5,
  JOB: 6,
  EXP: 7,
  LEVEL: 8,
  CLUB_PENALTY: 9,
  CLUB_OPEN_BLOCK: 10,
  BETTING_COMMISION: 50,
  MESSAGE: 51,
  AVATAR: 52,
  BET_TICKET_1: 72,
  BET_TICKET_2: 73,
  BET_TICKET_3: 74,
  LICENSE: 100,
  CLUB_MEMBERSHIP: 101,
  COUPON_OPEN_PICKSTER_ROOM: 102,
  COUPON_CHANGE_NICKNAME: 103,
  COUPON_CHANGE_CLUBNAME: 104,
  COUPON_CLUB_MESSAGE: 105,
  COUPON_OPEN_TRAINING_CENTER: 106,
  COUPON_BETTOR_BETTING_COMMISION: 107,
  COUPON_CLUB_BETTING_COMMISION: 108,
  EXP_BOOST: 109,
  PACKAGE: 110,
  COSTUM: 111,
  COUPON_CANCEL_BETTING: 112,
}

export const STAR_GAME_ID = {
  NONE: 0,
  SEASTORY: 1,
}

export const STAR_GAME_INFO = [{ gameId: STAR_GAME_ID.SEASTORY, title: '스타 바다이야기' }]
