/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Grid, Divider, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import QnaPersonalView from './QnaPersonalView'
import { Schema$QnaTrackData } from '@protocol/user'
import { QNA_STATE } from '@interface/config'
import { IQnaRecord } from '@protocol/user'
import apiUser from '@services/api/user'

//css
import { BoardStyle, NoList } from '@styles/modal.style'
import { PaginationStyle } from '@styles/base.style'
import { observer } from 'mobx-react'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: theme.boards.titleBg,
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  padding: 0,
  backgroundColor: '#16202F',
  borderBottom: '1px solid #1E2B3D',
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiGrid-container': {
    alignItems: 'center',
  },
  '& .type': {
    background: '#3B4C6E',
    color: '#FFF',
    fontSize: '0.9rem',
    borderRadius: '17px',
    width: '70px',
    height: '26px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .title': {
    color: '#D1D9E3',
  },
  '& .date': {
    color: '#747D8A',
    justifyContent: 'center',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
}))

type TrackData = Schema$QnaTrackData
const ItemPerPage = 10

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

function QnaPersonal({ location }) {
  const query = queryString.parse(location.search)
  const { page } = query
  const startPage = page ? Number(page) : 1

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = React.useState<string | false>('')

  const intl = useIntl()
  const history = useHistory()
  const { globalStore, liveStore } = useRootStore()
  const { updatedQna } = liveStore

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  const fetch = async (params: IQnaRecord.Params) => {
    try {
      const { data } = await apiUser.getQnaRecord(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    const params: IQnaRecord.Params = {
      kind: 'NORMAL',
      offset: 0,
      limit: ItemPerPage,
    }
    fetch(params)
  }, [updatedQna])

  useEffect(() => {
    const params: IQnaRecord.Params = {
      kind: 'NORMAL',
      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }
    fetch(params)
  }, [tabPage])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [track])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/mypage/search?main=qnaPersonal&page=${value}`)
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const listItems = []
  if (track) {
    for (const el of track.items) {
      const stateObj = _.find(QNA_STATE, o => {
        return o.id === el.state
      })
      const stateName = stateObj ? stateObj.name : ''
      const dateName = moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')
      const color = stateObj && stateObj.id === 'ANSWER' ? '#2366CB' : '#FBC342'
      const viewProps = {
        question: el.content,
        answer: el.answer,
        state: el.state,
      }
      listItems.push(
        <Accordion
          expanded={expanded === `pannel-${el.id}`}
          onChange={handleChange(`pannel-${el.id}`)}
          key={el.id}
        >
          <AccordionSummary aria-controls={`panel-${el.id}-content`} id={`panel-${el.id}-header`}>
            <Grid container sx={{ py: 1 }}>
              <Grid item xs={8} sx={{ px: 2, textAlign: 'start' }}>
                <span className="title">{el.title}</span>
              </Grid>
              <Grid item xs={2}>
                <span className="date">{dateName}</span>
              </Grid>
              <Grid item xs={2}>
                <span className="type" style={{ backgroundColor: color }}>
                  {stateName}
                </span>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <QnaPersonalView {...viewProps} />
          </AccordionDetails>
        </Accordion>,
      )
    }

    pageData = getPageData(track)
  }

  let emptyContents = null
  if (listItems.length === 0) {
    emptyContents = (
      <NoList>
        {/* <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} /> */}
        <p>{intl.formatMessage({ id: 'component.no-private-qna' })}</p>
      </NoList>
    )
  }

  // for (const el of noticeItems) {
  //   const color = el.type === '답변완료' ? '#2366CB' : '#FBC342'
  //   listItems.push(
  //     <Accordion expanded={expanded === `pannel-${el.id}`} onChange={handleChange(`pannel-${el.id}`)}>
  //       <AccordionSummary aria-controls={`panel-${el.id}-content`} id={`panel-${el.id}-header`}>
  //         <Grid container sx={{ py: 1 }}>
  //           <Grid item xs={8} sx={{ textAlign: 'start' }}>
  //             <span className="title">{el.title}</span>
  //           </Grid>
  //           <Grid item xs={2}>
  //             {el.date}
  //           </Grid>
  //           <Grid item xs={2}>
  //             <span className="type" style={{ backgroundColor: color }}>
  //               {el.type}
  //             </span>
  //           </Grid>
  //         </Grid>
  //       </AccordionSummary>
  //       <AccordionDetails>
  //         <QnaView />
  //       </AccordionDetails>
  //     </Accordion>,
  //   )
  // }

  return (
    <Box pt={3}>
      <BoardStyle className="board_list">
        <Grid container className="list_head" sx={{ py: 1 }}>
          <Grid item xs={8}>
            {intl.formatMessage({ id: 'title' })}
          </Grid>
          <Grid item xs={2}>
            {intl.formatMessage({ id: 'date' })}
          </Grid>
          <Grid item xs={2}>
            {intl.formatMessage({ id: 'progress' })}
          </Grid>
        </Grid>
        {listItems}
      </BoardStyle>
      {emptyContents}

      <PaginationStyle
        count={pageData.totalPage}
        variant="outlined"
        page={pageData.currPage}
        onChange={handlePagingChange}
      />
    </Box>
  )
}

export default observer(QnaPersonal)
