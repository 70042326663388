import React, { Fragment } from 'react'
import { jsx, css } from '@emotion/react'
import * as _ from 'lodash'
import { Box, Tabs, Tab } from '@mui/material'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { ModalKeys } from '@store/globalStore'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export function PartnerMenu() {
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [listOpen, setListOpen] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const { partnerMode } = globalStore
  const { signedin } = authStore
  const { userInfo, userMoney, userPoint } = userStore
  const { nickname } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint

  const PARTNER_TABS = [
    'dashboard',
    'members',
    'members-concurrent',
    'betting-record',
    'charge-record',
    'stats-game',
    'balance-record',
    'balance-request',
  ]
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabKey = PARTNER_TABS[newValue]
    history.push(`/partner/${tabKey}`)
  }

  const tabIndex = 0
  let partnerMenu = null
  if (!partnerMode) {
    return null
  }

  return (
    <Box>
      <Tabs
        value={partnerMode - 1}
        scrollButtons
        variant="scrollable"
        allowScrollButtonsMobile
        onChange={handleChange}
        sx={{ mt: 1, background: '#f2f2f2', marginTop: '0px' }}
      >
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.home.dashboard' })}
          {...a11yProps(0)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.members.list' })}
          {...a11yProps(1)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.members.concurrent' })}
          {...a11yProps(2)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.betting-record.list' })}
          {...a11yProps(3)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.charge-record.list' })}
          {...a11yProps(4)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.stats.game' })}
          {...a11yProps(5)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.balance-account.list' })}
          {...a11yProps(6)}
        />
        <Tab
          className="basic_tab"
          sx={{ fontSize: '15px' }}
          label={intl.formatMessage({ id: 'menu.partner.balance-account.request' })}
          {...a11yProps(7)}
        />
      </Tabs>
    </Box>
  )
}

export default observer(PartnerMenu)
