/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import * as _ from 'lodash'
import { Box, Container, Tab, Tabs, Typography, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import useRootStore from '@store/useRootStore'
//import WhiteTheme from '@themes/white.theme'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'

// css
import { TitleDivider, BasicTabs, DarkenBg } from '@styles/base.style'

import NoticeList from './NoticeList'
import MobileNoticeList from './m/NoticeList'

// 탭 관련 ----------
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}


const channelSymbol = process.env.REACT_APP_CHANNEL || ''

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

// ----------

const EXCLUDE_MENU = process.env.REACT_APP_EXCLUDE_MENU || []
let tabKeys = ['NOTICE', 'EVENT', 'POLICY']
tabKeys = _.filter(tabKeys, o => {
  return EXCLUDE_MENU.indexOf(o) < 0
})

const Notice: React.FC<{}> = props => {
  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { authStore, globalStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  useOnUnmount(() => {
    globalStore.setNoticeTab('')
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tabKey = tabKeys[newValue]
    history.push(`/notice/search?main=${tabKey}`)
    globalStore.setNoticeTab(tabKey)
  }

  const { location } = props as any
  const query = queryString.parse(location.search)
  const { main, page } = query
  const tabKey = (main as string) || tabKeys[0]
  const tabIndex = tabKeys.indexOf(tabKey)

  const activeProps = {
    ...props,
    tabKey,
  }

  if (!signedin && initialized) {
    globalStore.pushLogin()
    return <></>
  }

  if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
    userStore.setPositionPage(`NOTICE-${tabKey}`)
  }

  const showEvent = tabKeys.indexOf('EVENT') >= 0
  const showPolicy = tabKeys.indexOf('POLICY') >= 0

  let contents = null
  if (desktop) {
    contents = (
      <DarkenBg>
        <Box className="content_wrap">
          <Container fixed style={{ paddingBottom: '1px' }}>
            <Typography variant="h5" component="h3" sx={{ py: 3, fontWeight: 'bold', color: '#D1D9E3' }}>
              {intl.formatMessage({ id: 'component.notice' })}
            </Typography>
            <Divider css={TitleDivider}></Divider>
            {channelSymbol === 'emart' ? (
              <Tabs value={tabIndex} onChange={handleChange} css={BasicTabs} sx={{ mt: 1 }} variant="fullWidth">
                <Tab
                  className="basic_tab"
                  label={intl.formatMessage({ id: 'component.notice.notice' })}
                  {...a11yProps(1)}
                />
                {showPolicy && (
                  <Tab
                    className="basic_tab"
                    label='카지노 베팅규정'
                    {...a11yProps(2)}
                  />
                )}
                {showEvent && (
                  <Tab
                    className="basic_tab"
                    label='슬롯 베팅규정'
                    {...a11yProps(3)}
                  />
                )}
              </Tabs>
            ) : (
              <Tabs value={tabIndex} onChange={handleChange} css={BasicTabs} sx={{ mt: 1 }} variant="fullWidth">
                <Tab
                  className="basic_tab"
                  label={intl.formatMessage({ id: 'component.notice.notice' })}
                  {...a11yProps(1)}
                />
                {showEvent && (
                  <Tab
                    className="basic_tab"
                    label={intl.formatMessage({ id: 'component.notice.event' })}
                    {...a11yProps(2)}
                  />
                )}
                {showPolicy && (
                  <Tab
                    className="basic_tab"
                    label={intl.formatMessage({ id: 'component.notice.policy' })}
                    {...a11yProps(3)}
                  />
                )}
              </Tabs>
            )}
            <NoticeList {...activeProps}></NoticeList>
          </Container>
        </Box>
      </DarkenBg>
    )
  } else {
    contents = (
      <DarkenBg sx={{ minHeight: 'calc(100vh - 202px)' }}>
        {channelSymbol === 'emart' ? (
          <Tabs value={tabIndex} onChange={handleChange} css={BasicTabs} variant="fullWidth">
            <Tab className="basic_tab" label={intl.formatMessage({ id: 'component.notice.notice' })} {...a11yProps(1)} />
            {showEvent && (
              <Tab className="basic_tab" label='카지노 베팅규정' {...a11yProps(2)} />
            )}
            {showPolicy && (
              <Tab className="basic_tab" label='슬롯 베팅규정' {...a11yProps(3)} />
            )}
          </Tabs>
        ) : (
          <Tabs value={tabIndex} onChange={handleChange} css={BasicTabs} variant="fullWidth">
            <Tab className="basic_tab" label={intl.formatMessage({ id: 'component.notice.notice' })} {...a11yProps(1)} />
            {showEvent && (
              <Tab className="basic_tab" label={intl.formatMessage({ id: 'component.notice.event' })} {...a11yProps(2)} />
            )}
            {showPolicy && (
              <Tab
                className="basic_tab"
                label={intl.formatMessage({ id: 'component.notice.policy' })}
                {...a11yProps(3)}
              />
            )}
          </Tabs>
        )}
        <MobileNoticeList {...activeProps}></MobileNoticeList>
      </DarkenBg>
    )
  }

  return <>{contents}</>
}

export default observer(Notice)
