/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Chip } from '@mui/material'
import { popupCenter } from '@utils/utils'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'

// css
import { ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// icon
import CloseIcon from '@mui/icons-material/Close'

// image
import { imgMenual01, imgMenual02, imgMenual03, imgMenual04, imgMenual05 } from '@assets/images/layout_1'

export const DefaultImages = [imgMenual01, imgMenual02, imgMenual03, imgMenual04, imgMenual05]

interface Props {
  visible: boolean
  onClose: () => void
  innerContents: any
}

const DefaultContainer: React.FC<Props> = ({ visible, onClose, innerContents }) => {
  return (
    <Dialog
      open={visible}
      onClose={onClose}
      css={ModalStyle}
      maxWidth={'md'}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '900px',
        },
      }}
    >
      <Box className="modal_content">
        <AppBar sx={{ position: 'relative' }}>
          <Typography className="modal_title" variant="h6" component="div">
            코인충전 메뉴얼
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Box sx={{ p: 4, backgroundColor: '#fff' }}>{innerContents}</Box>
      </Box>
    </Dialog>
  )
}

export default observer(DefaultContainer)
