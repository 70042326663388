/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, Fragment } from 'react'
import moment from 'moment'
import * as _ from 'lodash'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Box, Chip, Divider, Grid, Theme, Typography } from '@mui/material'
import { numberWithCommas } from '~/utils/utils'
import { Schema$SportsBetFold, Schema$SportsBetRecord } from '~/v2/protocol/game'
import { IntlShape } from 'react-intl'
import {
  adjustMarketNameKo,
  SPORTS_LOCALE,
  FLIGHT_FILTER_ITEMS,
  getBetTokenName,
  getCartTitle,
  getTeamName,
  getTeamScore,
  SPORT_FILTER_ITEMS,
  toClientbaseline,
} from '~/game/st-util'
import useRootStore from '~/store/useRootStore'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@mui/styles'
import apiGame from '@services/api/game'
import { makeSportsBetStateColor } from '~/store/sportsStore/sports-util'

interface Props {
  item: Schema$SportsBetRecord
  theme: Theme
  intl: IntlShape
  lang: string
}

function BettingRecordItem({ item, theme, intl, lang }: Props) {
  const { globalStore } = useRootStore()
  const { enqueueSnackbar } = useSnackbar()
  const [updated, setUpdated] = useState<number>(new Date().getTime())

  const fetchCancel = async () => {
    try {
      const { notice, record } = await apiGame.cancelBet({ betId: item.id })
      if (record) {
        item.dividend = record.dividend
        item.rate = record.rate
        item.state = record.state
        item.folds = record.folds
        item.canCancel = record.canCancel
        item.cancelDueDate = record.cancelDueDate
        setUpdated(new Date().getTime())
      }

      if (notice) {
        globalStore.pushDialogOk({ text: notice })
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchHide = async () => {
    try {
      const { user_field } = await apiGame.hideSportsRecord({ id: item.id })
      item.user_field = user_field
      setUpdated(new Date().getTime())
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickCopyLeagueId = (secret: string) => {
    enqueueSnackbar('리그ID 를 클립보드에 복사하였습니다.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    })
  }

  const onClickCopyFixtureId = (secret: string) => {
    enqueueSnackbar('경기ID 를 클립보드에 복사하였습니다.', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
    })
  }

  const onClickCancel = () => {
    globalStore.pushDialogYesNo({
      text: '베팅을 취소하시겠습니까?',
      callbackPositive: async () => {
        fetchCancel()
      },
    })
  }

  const onClickHide = () => {
    globalStore.pushDialogYesNo({
      text: '해당 내역을 삭제하시겠습니까?',
      callbackPositive: () => {
        fetchHide()
      },
    })
  }

  const classes = makeStyles(theme => ({
    deleteIcon: {
      '&.MuiChip-deleteIcon': {
        color: '#dee1e6',
        '&:hover': {
          color: '#dee1e6',
        },
      },
    },
  }))()

  const {
    name: betStateName,
    background: betBackground,
    foreground: betForeground,
  } = makeSportsBetStateColor(item.state, null, false, lang)
  const foldCount = item.folds.length
  const betVolume = numberWithCommas(item.betMoney)
  const rate = item.rate
  const winMoney = numberWithCommas(item.dividend)
  const bettingTime = moment(new Date(item.bettingTime)).format('MM/DD HH:mm:ss')

  const canCancel = item.canCancel
  let ssCancelDueDate = null
  if (canCancel && item.cancelDueDate) {
    const dateName = moment(item.cancelDueDate).format('MM/DD HH:mm:ss')
    ssCancelDueDate = lang === 'ko' ? `${dateName} 까지 취소 가능` : `Can cancel until ${dateName}`
  }

  const canHide = item.state !== 'BET'

  const gridWidth = [1.5, 4, 2.5, 2, 1, 1]

  const contents = []
  const betContents = []
  betContents.push(
    <Box sx={{ background: theme.sportsRecord.betTitle, color: 'white' }}>
      <Grid container alignItems="center" sx={{ height: '36px' }}>
        <Grid item xs={gridWidth[0]}>
          {intl.formatMessage({ id: 'table.bet_datetime' })}
        </Grid>
        <Grid item xs={gridWidth[1]}>
          {intl.formatMessage({ id: 'table.bet_fold_size' })}
        </Grid>
        <Grid item xs={gridWidth[2]}>
          {intl.formatMessage({ id: 'minigame.power.table.bet.volume' })}
        </Grid>
        <Grid item xs={gridWidth[3]}>
          {intl.formatMessage({ id: 'minigame.power.table.bet.winmoney' })}
        </Grid>
        <Grid item xs={gridWidth[4]}>
          {intl.formatMessage({ id: 'table.odds' })}
        </Grid>
        <Grid item xs={gridWidth[5]}>
          {intl.formatMessage({ id: 'minigame.power.table.bet.state' })}
        </Grid>
      </Grid>
    </Box>,
  )

  betContents.push(
    <Box sx={{ background: theme.sportsRecord.betContents, color: 'white' }}>
      <Grid container alignItems="center" sx={{ height: '42px' }}>
        <Grid item xs={gridWidth[0]}>
          {bettingTime}
        </Grid>
        <Grid item xs={gridWidth[1]}>
          {foldCount}
        </Grid>
        <Grid item xs={gridWidth[2]}>
          {betVolume}
          {intl.formatMessage({ id: 'money-locale' })}
        </Grid>
        <Grid item xs={gridWidth[3]}>
          {winMoney}
          {intl.formatMessage({ id: 'money-locale' })}
        </Grid>
        <Grid item xs={gridWidth[4]}>
          {rate}
        </Grid>
        <Grid item xs={gridWidth[5]}>
          {canCancel ? (
            <Chip
              label={lang === 'ko' ? '취소' : 'Cancel'}
              size="small"
              variant="outlined"
              onClick={() => onClickCancel()}
              onDelete={onClickCancel}
              classes={{ deleteIcon: classes.deleteIcon }}
              style={{
                minWidth: '60px',
                color: 'white',
              }}
            />
          ) : (
            <Chip
              label={betStateName}
              size="small"
              style={{ minWidth: '60px', background: betBackground, color: betForeground }}
            />
          )}
        </Grid>
      </Grid>
    </Box>,
  )

  if (ssCancelDueDate) {
    betContents.push(
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{ background: theme.sportsRecord.betContents, color: 'red', pl: 2, pr: 2, pb: '3px' }}
      >
        <Typography>{ssCancelDueDate}</Typography>
      </Box>,
    )
  }

  //
  const foldContents = []
  foldContents.push(
    <Box sx={{ background: theme.sportsRecord.foldTitle, color: 'white' }}>
      <Grid alignItems="center" container sx={{ height: '36px' }}>
        <Grid item xs={gridWidth[0]}>
          {intl.formatMessage({ id: 'table.date_of_match' })}
        </Grid>
        <Grid item xs={gridWidth[1]}>
          {intl.formatMessage({ id: 'table.match' })}
        </Grid>
        <Grid item xs={gridWidth[2]}>
          {intl.formatMessage({ id: 'table.betting' })}
        </Grid>
        <Grid item xs={gridWidth[3]}>
          {intl.formatMessage({ id: 'table.betting_choice' })}
        </Grid>
        <Grid item xs={gridWidth[4]}>
          {intl.formatMessage({ id: 'table.odds' })}
        </Grid>
        <Grid item xs={gridWidth[5]}>
          {intl.formatMessage({ id: 'sports.table.bet.state' })}
        </Grid>
      </Grid>
    </Box>,
  )

  const validFolds = _.filter(item.folds, (o: Schema$SportsBetFold) => {
    return o.marketName?.en !== 'Fold Bonus'
  })

  const bonusFolds = _.filter(item.folds, (o: Schema$SportsBetFold) => {
    return o.marketName?.en === 'Fold Bonus'
  })

  const orderedFolds = _.orderBy(
    validFolds,
    o => {
      return o.prematch ? new Date(o.prematch.StartDate).getTime() : 0
    },
    ['desc'],
  )

  for (const fold of orderedFolds) {
    const homeName = getTeamName(fold.prematch, '1', lang)
    const awayName = getTeamName(fold.prematch, '2', lang)
    const title = fold.prematch
      ? getCartTitle(fold.prematch, fold.marketId, fold.marketBetName, lang)
      : getBetTokenName(fold.prematch.SportId, fold.marketId, fold.marketBetName, lang)
    let marketName: string = fold.marketName[lang] || fold.marketName['en']
    if (fold.baseLine) {
      marketName = `${marketName} [${toClientbaseline(fold.baseLine)}]`
    }

    marketName = adjustMarketNameKo(fold.prematch?.SportId, marketName)

    const leagueName = fold.prematch?.LeagueName[lang] || fold.prematch?.LeagueName.en
    const vsTitle = `${homeName} vs ${awayName}`
    const homeScore = getTeamScore(fold.prematch, '1')
    const awayScore = getTeamScore(fold.prematch, '2')

    const {
      name: foldStateName,
      background: foldBackground,
      foreground: foldForeground,
    } = makeSportsBetStateColor(fold.state, fold.prematch?.Status, true, lang)

    const startTime = fold.prematch
      ? moment(new Date(fold.prematch.StartDate)).format('MM/DD HH:mm:ss')
      : 'Unknown'
    const objSymbol = _.find(SPORT_FILTER_ITEMS, o => {
      return o.value === fold.prematch?.SportId.toString()
    })
    const symbol = objSymbol && objSymbol.symbol
    foldContents.push(
      <Box sx={{ background: theme.sportsRecord.foldContents, color: 'white' }}>
        {foldContents.length > 1 ? <Divider></Divider> : null}
        <Grid container alignItems="center">
          <Grid item xs={gridWidth[0]}>
            {`${symbol} ${startTime}`}
          </Grid>
          <Grid item xs={gridWidth[1]} sx={{ pt: '4px', pb: '4px' }}>
            <CopyToClipboard
              text={fold.prematch?.FixtureId}
              onCopy={() => onClickCopyLeagueId(fold.prematch?.LeagueId.toString())}
            >
              <Typography sx={{ cursor: 'pointer', color: '#999999' }}>{leagueName}</Typography>
            </CopyToClipboard>
            <CopyToClipboard
              text={fold.prematch?.FixtureId}
              onCopy={() => onClickCopyFixtureId(fold.prematch?.FixtureId.toString())}
            >
              <Typography sx={{ cursor: 'pointer' }}>{vsTitle}</Typography>
            </CopyToClipboard>
            {homeScore && (
              <Grid container>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ border: 1, borderColor: '#2d4464' }}
                >
                  {homeScore}
                </Grid>
                <Grid
                  item
                  xs={6}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ border: 1, borderColor: '#2d4464' }}
                >
                  {awayScore}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={gridWidth[2]}>
            {marketName}
          </Grid>
          <Grid item xs={gridWidth[3]}>
            {title}
          </Grid>
          <Grid item xs={gridWidth[4]}>
            {fold.oddsRate}
          </Grid>
          <Grid item xs={gridWidth[5]}>
            <Chip
              label={foldStateName}
              size="small"
              style={{
                fontSize: '11px',
                width: '56px',
                height: '24px',
                background: foldBackground,
                color: foldForeground,
              }}
            />
          </Grid>
        </Grid>
      </Box>,
    )
  }

  for (const fold of bonusFolds) {
    let condition = null
    const { sportsFoldBonus } = item.bonusResult || {}
    if (sportsFoldBonus) {
      condition = (
        <>
          <Typography sx={{ color: '#e6a739' }}>{sportsFoldBonus.base}</Typography>
          <Typography sx={{ color: '#999999', ml: 1 }}>
            {SPORTS_LOCALE.odds_more.name[lang]}
          </Typography>
        </>
      )
    }
    const title = `${fold.marketBetName} ${intl.formatMessage({ id: 'fold-bonus' })}`
    foldContents.push(
      <Box sx={{ background: theme.sportsRecord.foldContents }}>
        <Divider></Divider>
        <Grid container alignItems="center" sx={{ minHeight: '34px' }}>
          <Grid item xs={gridWidth[0]}></Grid>
          <Grid item xs={gridWidth[1]}>
            <Box display="flex" justifyContent="center">
              {condition}
              <Typography sx={{ color: '#ffdc1e', ml: 1 }}>{title}</Typography>
            </Box>
          </Grid>
          <Grid item xs={gridWidth[2]}></Grid>
          <Grid item xs={gridWidth[3]}></Grid>
          <Grid item xs={gridWidth[4]}>
            <Typography sx={{ color: '#ffdc1e' }}>{fold.oddsRate}</Typography>
          </Grid>
          <Grid item xs={gridWidth[5]}></Grid>
        </Grid>
      </Box>,
    )
  }

  betContents.push(
    <Box sx={{ background: theme.sportsRecord.foldBg, p: '6px' }}>{foldContents}</Box>,
  )

  const flightItem = _.find(FLIGHT_FILTER_ITEMS, o => {
    return o.value === item.flight
  })
  contents.push(
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" justifyContent="begin">
        <Typography
          sx={{ ml: '2px', mb: '2px', fontSize: '13px', color: flightItem?.color || 'white' }}
        >
          {flightItem?.name[lang] || ''}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="end">
        {item.user_field === 'HIDDEN' ? (
          <Typography
            sx={{ mr: '2px', mb: '2px', fontSize: '13px', color: '#ff3333', cursor: 'pointer' }}
          >
            {lang === 'ko' ? '삭제됨' : 'deleted'}
          </Typography>
        ) : (
          canHide && (
            <Typography
              onClick={() => onClickHide()}
              sx={{ mr: '2px', mb: '2px', fontSize: '13px', color: '#cccccc', cursor: 'pointer' }}
            >
              {lang === 'ko' ? '삭제' : 'delete'}
            </Typography>
          )
        )}
      </Box>
    </Box>,
  )
  contents.push(
    <Box sx={{ border: 2, borderColor: theme.sportsRecord.betTitle }}>{betContents}</Box>,
  )

  return <Fragment>{contents}</Fragment>
}

export default BettingRecordItem
