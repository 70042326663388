import { ChipAttributes } from './types'
import { v4 } from 'uuid'

export default class FixedChip extends Phaser.GameObjects.Container {
  instanceId = ''
  attributes: ChipAttributes = null

  normal: Phaser.GameObjects.Image = null
  title: Phaser.GameObjects.Text = null

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    attributes: ChipAttributes,
  ) {
    super(scene, x, y)

    this.scene.add.existing(this)

    this.instanceId = v4()
    this.attributes = attributes

    this.normal = scene.add.image(0, 0, 'ui', attributes.type)
    this.add(this.normal)

    this.setSize(this.normal.width, this.normal.height)

    // add text
    this.title = this.makeTitle()
    this.add(this.title)
  }

  makeTitle() {
    const fontSize = `${this.attributes.fontSize}px`
    // let fontSize = '13px'
    // if (data.title.length === 3) {
    //   fontSize = '15px'
    // } else if (data.title.length < 3) {
    //   fontSize = '19px'
    // }
    const title = this.scene.add
      .text(0, 0, this.attributes.title, {
        fontSize,
        color: '#ffffff',
        fontFamily: 'PT Serif',
        fontStyle: 'bold',
        // stroke: '#6b6b6b',
        // strokeThickness: 1,
        shadow: {
          offsetX: 1,
          offsetY: 1,
          color: '#2c280c',
          stroke: true,
          fill: true,
        },
      })
      .setOrigin(0.5, 0.5)
    return title
  }
}
