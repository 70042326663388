import { css, Theme, ThemeProps } from '@emotion/react'
//import styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import bgTimeGauge from '@assets/images/layout_1/bg_time_gauge.png'
import { imgBall } from '@assets/images/layout_1'

export const NotiStyle = styled('div')`
  position: sticky;
  top: 250px;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1;

  .inner {
    width: 1150px;
    margin: auto;
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgb(0 0 0 / 50%);
  }
`

export const EvoNotiStyle = styled('div')`
  position: sticky;
  top: 250px;
  left: 0;
  width: 100%;
  height: 0;
  z-index: 1;

  .inner {
    width: 1150px;
    margin: auto;
  }

  .backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: rgb(0 0 0 / 50%);
  }
`

export const BettingCartStyle = styled('div')`
  position: sticky;
  top: 114px;

  .bet_wrap {
    border-radius: 0.3rem;
    overflow: hidden;
    background-color: #f1f1f1;
  }
  .bet_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;

    & > h3 {
      font-weight: bold;
      font-size: 1rem;
    }
  }
`
export const SelectBox = (theme: Theme) => css`
  border: none;
  border-radius: 0;
  background: none;
  box-shadow: none;

  .card_header {
    padding: 0.6rem 0;
    border-radius: 0;
    color: ${theme.colors.themeTxt};
    font-size: ${theme.fonts.medium};
  }
  .card-body {
    padding: 0;
  }
  .col_lg_25 {
    flex: 0 0 auto;
    width: 20%;
  }

  .btn_bet {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7rem 1rem;
    width: 100%;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 0.3rem;
    border: 3px solid transparent;

    &.Mui-disabled {
      color: #fff;
    }
    &.selected {
      border-color: #9eed09;
      color: #9eed09;
    }

    .ball {
      display: flex;
      gap: 5px;
      align-items: center;

      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: url(${imgBall}) center no-repeat;
        background-size: cover;
        color: #fff;
        font-size: 1rem;
      }
    }
  }
  .btn_bet.type1 {
    color: #fff;
    border-color: #60a9d9;
    background-color: #3498db;
    &:hover {
      background-color: #60a9d9;
    }
  }
  .btn_bet.type2 {
    color: #fff;
    border-color: #f3cc61;
    background-color: #d8a724;
    &:hover {
      background-color: #f3cc61;
    }
  }
  .btn_bet.type3 {
    color: #fff;
    background-color: #16202f;
    &:hover {
      background-color: #136f3a;
    }
  }
  .btn_bet.type4 {
    color: #fff;
    border-color: #f3cc61;
    background-color: #d8a724;
    &:hover {
      background-color: #f3cc61;
    }
  }
`

export const MyBettings = (theme: Theme) => css`
  //position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: transparent;
`

export const ScrollableStyle = styled('div')`
  position: relative;
  background-color: #000;

  .wrapping {
    position: absolute;
    top: 0;
    left: 0;
    width: 78%;
    height: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
`
export const Timebar = styled('div')`
  position: relative;

  .gauge {
    height: 22px;
    background-color: #E9E9E9;
  }
  .gauge .inner {
    height: 100%;
    background: #fbcc00 url("${bgTimeGauge}");
    animation: animate-waiting-gauge .5s linear infinite;
    transition: width 1s ease-in-out;
  }
    @keyframes animate-waiting-gauge{
      from{background-position: 25px 0}
      to{background-position:0 0}
    }
  }
  .round_time {
    position: absolute;
    top: 2px;
    left: 16px;
    color: #134148;
    font-size: 0.9rem;
    font-weight: bold;
  }
`

export const CartListStyle = styled('ul')`
  margin-bottom: 0;
  padding: 0.5rem 0;
  min-height: 90px;
  max-height: calc(100vh - 530px);
  over-flow: auto;
  background: #dedede;
  border-top: 1px solid #cfcfcf;

  & > li {
    margin: 3px 16px;
    padding: 4px 15px;
    display: flex;
    flex: 1 1 0;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #cfcfcf;
    border-radius: 4px;
    background-color: #f1f1f1;
    font-size: 1rem;
    font-weight: bold;
  }
`

export const AmountStyle = styled('div')`
  border-top: 1px solid #cfcfcf;
  background: #f2f2f2;
  font-size: ${props => props.theme.fonts.default};

  & > p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.2rem;
  }

  .highlight {
    font-weight: bold;
    font-size: 1.1rem;
    color: #2a73e1;
  }

  .my_money {
    color: #747d8a;
  }
  .betting_input {
    display: flex;
    align-items: center;

    .form_label {
      min-width: 100px;
      font-size: 1.1rem;
      color: #0a1424;
      font-weight: bold;
    }
    .form_control {
      width: 100%;
      padding: 0.75rem;
      background-color: #ffffff;
      border: 1px solid #c7cad8;
      border-radius: 0.3rem;
      color: #2e3a4d;
      font-weight: bold;
      text-align: end;
    }
  }
`
export const TopButtonWrap = styled('div')`
  position: fixed;
  bottom: 40px;
  right: 50%;
  margin-right: -560px;

  .MuiFab-root {
    width: 60px;
    height: 60px;
    border: 1px solid #1e2b3d;
    border-radius: 20px;
    background-color: #1e2b3d;
    box-shadow: 0px 3px 6px #00000059;

    &:hover {
      background-color: #1e2b3d;
    }
  }

  .MuiSvgIcon-root {
    color: #d1d9e3;
    font-size: 24px;
  }
`
