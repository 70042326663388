/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import React from 'react'
import { observer } from 'mobx-react'
import { Tab, Tabs, Box, Typography, Dialog, AppBar, IconButton } from '@mui/material'
import { BasicTabs } from '@styles/base.style'

import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import useRootStore from '@store/useRootStore'

import SigninComponent from './SigninComponent'
import SignupComponent from './SignupComponent'
import SigninComponent2 from './SigninComponent2'
import SignupComponent2 from './SignupComponent2'
import SigninComponentBand from './SigninComponentBand'
import SignupComponentBand from './SignupComponentBand'
import SigninComponentAce from './SigninComponentAce'
import SignupComponentAce from './SignupComponentAce'
import { ModalKeys } from '@store/globalStore'
import { MobileModalStyle, MobileModalStyle2, ModalStyle, ModalStyle2, GlobalStyle } from '@styles/modal.style'
import { MobileModalStyleBand, ModalStyleBand } from '~/styles/modalBand.style'
import { MobileModalStyleAce, ModalStyleAce } from '~/styles/modalAce.style'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

// icon
import CloseIcon from '@mui/icons-material/Close'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

// 탭 관련 ----------
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const PopupLogin: React.FC<{}> = () => {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const { desktop } = globalStore.decideMedia(isDesktop)
  const { currentKey, currentValue } = globalStore
  const { signedin } = authStore
  const modalKey = ModalKeys.login

  const tabIndex = currentKey === modalKey ? currentValue : -1

  let visible = tabIndex >= 0 && !signedin
  if (signedin) {
    globalStore.hideModal(modalKey)
  }

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    globalStore.showModal('login', newValue)
  }

  const style = desktop ? ModalStyle : MobileModalStyle
  const style2 = desktop ? ModalStyle2 : MobileModalStyle2
  const bandStyle = desktop ? ModalStyleBand : MobileModalStyleBand
  const aceStyle = desktop ? ModalStyleAce : MobileModalStyleAce

  let contents = null

  if (channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk') {
    contents = (
      <Dialog open={visible} onClose={onClose} css={style2} className="login_dialog">
        <Box className="modal_content">
          <AppBar sx={{ position: 'relative' }}>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
            <SigninComponent2 />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <SignupComponent2 />
          </TabPanel>
        </Box>
      </Dialog>
    )
  } else if (channelSymbol === 'band' || channelSymbol === 'water' || channelSymbol === 'emart' || channelSymbol === 'cigar') {
    contents = (
      <Dialog open={visible} onClose={onClose} css={bandStyle} className="login_dialog">
        <Box className="modal_content">
          <AppBar>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
            <SigninComponentBand />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <SignupComponentBand />
          </TabPanel>
        </Box>
      </Dialog>
    )
  } else if (channelSymbol === 'today' || channelSymbol === 'hongyeom' || channelSymbol === 'ace' || channelSymbol === 'ssg' || channelSymbol === 'praha') {
    contents = (
      <Dialog open={visible} onClose={onClose} css={aceStyle} className="login_dialog">
        <Box className="modal_content">
          <AppBar>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <TabPanel value={tabIndex} index={0}>
            <SigninComponentAce />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <SignupComponentAce />
          </TabPanel>
        </Box>
      </Dialog>
    )
  } else {
    contents = (
      <Dialog open={visible} onClose={onClose} css={style} className="login_dialog">
        <Box className="modal_content">
          <AppBar sx={{ position: 'relative' }}>
            <Typography className="modal_title" variant="h6" component="div">
              {intl.formatMessage({ id: 'signin' })}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <Box>
            <Tabs value={tabIndex} onChange={handleChange} css={BasicTabs} variant="fullWidth">
              <Tab className="basic_tab" label={intl.formatMessage({ id: 'login' })} {...a11yProps(0)} />
              <Tab className="basic_tab" label={intl.formatMessage({ id: 'signup' })} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <SigninComponent />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <SignupComponent />
            </TabPanel>
          </Box>
        </Box>
      </Dialog>
    )
  }

  return (
    <>
      <Global styles={GlobalStyle} />
      {contents}
    </>
  )
}

export default observer(PopupLogin)
