/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useMediaQuery } from 'react-responsive'
import useRootStore from '~/store/useRootStore'
import { Box, Grid, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { MEDIA_DESKTOP_WIDTH } from '~/constants'
import { SPORTS_LOCALE } from '~/game/st-util'

interface Props {}

const SportsBonusPanel: React.FC<Props> = () => {
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, sportsStore } = useRootStore()

  const { desktop } = globalStore.decideMedia(isDesktop)
  const { options, lang } = sportsStore

  if (!options) {
    return <></>
  }

  const { sports_bonus } = options
  const { fold } = sports_bonus

  const items = []

  for (const el of fold) {
    if (el.enabled) {
      items.push(
        <Grid key={el.size} item xs={desktop ? 4 : 6}>
          <Box sx={{ background: '#FEF6D5', p: '2px' }}>
            <Box display="flex" justifyContent="space-between" sx={{ background: '#37435a', p: 1 }}>
              <Box>
                <Box display="flex">
                  <Typography sx={{ color: '#e6a739', fontSoze: '12px' }}>{el.base}</Typography>
                  <Typography sx={{ color: '#d3d3d3', fontSoze: '12px', ml: 1 }}>
                    {SPORTS_LOCALE.odds_more.name[lang]}
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography sx={{ color: '#EDDC9B', fontWeight: 'bold' }}>{el.size}</Typography>
                  <Typography sx={{ color: '#d3d3d3', ml: 1 }}>
                    {SPORTS_LOCALE.fold_bonus.name[lang]}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '12px' }}>&nbsp;</Typography>
                <Typography sx={{ color: '#e6a739', fontWeight: 'bold', ml: 3 }}>
                  {el.rate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>,
      )
    }
  }

  return (
    <Box>
      <Grid container>{items}</Grid>
    </Box>
  )
}

export default observer(SportsBonusPanel)
