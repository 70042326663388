// image
import { img_reel_seastory } from '~/assets/images/layout_1'

export const AllReelGames = [
  {
    kind: 'STAR_SEASTORY100',
    thumbnail: img_reel_seastory,
    banner: img_reel_seastory,
    hoverimg: null,
    localeId: 'trading.REEL.STAR_SEASTORY100',
  },
  {
    kind: 'STAR_SEASTORY200',
    thumbnail: img_reel_seastory,
    banner: img_reel_seastory,
    hoverimg: null,
    localeId: 'trading.REEL.STAR_SEASTORY200',
  },
  {
    kind: 'STAR_SEASTORY300',
    thumbnail: img_reel_seastory,
    banner: img_reel_seastory,
    hoverimg: null,
    localeId: 'trading.REEL.STAR_SEASTORY300',
  },
  {
    kind: 'STAR_SEASTORY500',
    thumbnail: img_reel_seastory,
    banner: img_reel_seastory,
    hoverimg: null,
    localeId: 'trading.REEL.STAR_SEASTORY500',
  },
  {
    kind: 'STAR_SEASTORY1000',
    thumbnail: img_reel_seastory,
    banner: img_reel_seastory,
    hoverimg: null,
    localeId: 'trading.REEL.STAR_SEASTORY1000',
  },
]
