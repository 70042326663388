import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import {
  Stack,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import SignalWifiStatusbarConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4'
import GradeIcon from '@mui/icons-material/Grade'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import PaidIcon from '@mui/icons-material/Paid'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import LoginIcon from '@mui/icons-material/Login'
import LogoutIcon from '@mui/icons-material/Logout'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'

import { numberWithCommas } from '@utils/utils'
import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps, TabPanel } from '@shared/Common'
import PanelCommissionSetting from './PanelCommissionSetting'
import apiPartner from '@services/api/partner'
import {
  Schema$PartnerMemeberItem,
  Schema$PartnerTransferSummary,
  Schema$PartnerUserConfig,
} from '@protocol/partner'
import { MERCHANT_CLASS, USER_STATE } from '~/v2/interface/config'
import 'react-tabs/style/react-tabs.css'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import PanelDetail from './PanelDetail'
import PanelBettingRecord from './PanelBettingRecord'
import ComponentMemberInfo from './ComponentMemberInfo'
import PanelCoupon from './PanelCoupon'
import PanelChargeRecord from './PanelChargeRecord'
import { useIntl } from 'react-intl'

export const TAB_NAMES = [
  { id: 'detail', name: '상세보기' },
  { id: 'betting_record', name: '베팅내역' },
  { id: 'commission', name: '요율설정' },
  { id: 'coupon', name: '쿠폰' },
  { id: 'charge_record', name: '충/환전 내역' },
]

function PartnerIntergratedMember({ location }) {
  const intl = useIntl()
  const history = useHistory()

  const query = queryString.parse(location.search)
  const { uuid, main } = query
  const member_id = uuid as string
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const [memberInfo, setMemberInfo] = useState<Schema$PartnerMemeberItem | null>(null)
  const { authStore, userStore, globalStore } = useRootStore()
  const { userInfo } = userStore

  const possibleTabs: { id: string; name }[] = []
  for (let i = 0; i < TAB_NAMES.length; i++) {
    let canUse = true
    const tabId = TAB_NAMES[i].id
    if (tabId === 'press') {
      if (!(userInfo.merchant_class === 1 && userInfo.uuid !== member_id)) {
        canUse = false
      }
    }
    if (canUse) {
      possibleTabs.push(TAB_NAMES[i])
    }
  }

  const startTab = Math.max(
    _.findIndex(possibleTabs, o => {
      return main === o.id
    }),
    0,
  )

  const [tab, setTab] = useState(startTab)

  useEffect(() => {
    // fetch user data
    async function inline() {
      await globalStore.getPublicSettings(intl)
      const recovered = await authStore.recoverSession(intl)
      if (!recovered) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: intl.formatMessage({ id: 'msg.no_login_info' }),
          callbackPositive: () => {
            window.close()
          },
        })
      } else {
        // get user info
        try {
          const { item } = await apiPartner.getMember({ uuid: member_id })
          setMemberInfo(item)
        } catch (err) {
          globalStore.pushErrorObject(err, intl)
        }
      }
    }
    inline()
  }, [])

  const handleChangeTab = (event, newValue) => {
    history.push(`/partner/member/search?uuid=${member_id}&main=${possibleTabs[newValue].id}`)
    setTab(newValue)
  }

  const tabItems = []
  const tabPanels = []
  if (memberInfo) {
    for (let i = 0; i < possibleTabs.length; i++) {
      const tabId = possibleTabs[i].id
      tabItems.push(
        <Tab key={i} label={possibleTabs[i].name} {...a11yProps(i)} style={{ fontSize: '14px' }} />,
      )
      if (i === tab) {
        if (tabId === 'detail') {
          tabPanels.push(
            <TabPanel value={tab} index={i} spacing={0}>
              <Grid container sx={{ marginTop: '10px', marginBottom: '64px', width: '100%' }}>
                <PanelDetail location={location}></PanelDetail>
              </Grid>
            </TabPanel>,
          )
        } else if (tabId === 'betting_record') {
          tabPanels.push(
            <TabPanel value={tab} index={i} spacing={0}>
              <Box style={{ width: '100%', marginTop: '16px' }}>
                <PanelBettingRecord
                  memberInfo={memberInfo}
                  location={location}
                ></PanelBettingRecord>
              </Box>
            </TabPanel>,
          )
        } else if (tabId === 'commission') {
          tabPanels.push(
            <TabPanel value={tab} index={i} spacing={0}>
              <Box style={{ width: '100%', marginTop: '16px', marginBottom: '64px' }}>
                <PanelCommissionSetting memberInfo={memberInfo}></PanelCommissionSetting>
              </Box>
            </TabPanel>,
          )
        } else if (tabId === 'coupon') {
          tabPanels.push(
            <TabPanel value={tab} index={i} spacing={0}>
              <Box style={{ width: '100%', marginTop: '16px', marginBottom: '64px' }}>
                <PanelCoupon memberInfo={memberInfo} location={location}></PanelCoupon>
              </Box>
            </TabPanel>,
          )
        } else if (tabId === 'charge_record') {
          tabPanels.push(
            <TabPanel value={tab} index={i} spacing={0}>
              <Box style={{ width: '100%', marginTop: '16px', marginBottom: '64px' }}>
                <PanelChargeRecord memberInfo={memberInfo} location={location}></PanelChargeRecord>
              </Box>
            </TabPanel>,
          )
        }
      }
    }
  }

  return (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1300px',
          maxWidth: '1800px',
          marginTop: isDesktop ? '0px' : '0',
        }}
      >
        {memberInfo ? <ComponentMemberInfo memberInfo={memberInfo}></ComponentMemberInfo> : null}
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid item xs={12}>
            <Box sx={{ borderWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab} onChange={handleChangeTab} aria-label="basic tabs example">
                {tabItems}
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {tabPanels}
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

export default observer(PartnerIntergratedMember)
