import { Theme, createTheme } from '@mui/material/styles'
import asset_channel from '@assets/channel'

let DarkTheme: Theme = createTheme({
  colors: {
    mainBg: '#0f1111', // 기본 바탕색
    subBg: `#03080c url(${asset_channel.common.bg_subbg}) center 48px no-repeat`, // 보조 바탕색 Darken
    headerBg: 'linear-gradient(180deg,#1d476f,#1d3c58)', // pc헤더의 색
    primary: '#C349FF', // 하이라이트 컬러
    secondary: '#16202F', // 보조 컬러 Lighten
    themeTxt: '#fff', // 텍스트 기본 색
    themeTxtLighten: '#D1D9E3', // 텍스트 기본 색
    listTitle: '#D1D9E3',
    themeActive: '#f39700',
    disableTxt: '#6f777e',
    btnCoupon: '#8397ae',
    btnClear: '#3d3e3e',
    btnTxtLighten: '#fff',
    modalBg: '#0A1424',
    mobileHeaderBg: 'linear-gradient(180deg,#1d476f,#1d3c58)',
    mobileTxt: '#ffffff',
    modalLine: '#1E2B3D',
    darken: '#0a1424',
    drawerBg: '#16202F',
    drawerTopBg: '#131313',
    drawerMenuBg: '#16202F',
    drawerMenuTxt: '#747D8A',
    drawerTxtHover: '#43C8DE',
    drawerTxtLogin: '#D1D9E3',
    drawerTxtNick: '#FFFFFF',
    drawerTxtMoney: '#43C8DE',
    drawerTxtPoint: '#747d8a',
    drawerSubBg: '#080F1C',
    drawerSubTxt: '#747d8a',
    drawerBorder: '#1E2B3D',
    loginBg: '#050911',
    loginFormBg: '#16202F',
  },
  borders: {
    default: '1px solid #1E2B3D',
    itemBar: '1px solid transparent',
  },
  buttons: {
    default: '#3B4C6E',
    defaultHover: '#2A3957',
    primary: '#289CAF',
    primaryHover: '#177D8E',
    warning: '#D4952A',
    warningHover: '#AE771C',
    danger: '#C92E2E',
    dangerHover: '#AB1818',
    success: '#2366CB',
    successHover: '#1A50A1',
    disable: '#252F40',
  },
  sportsMatchup: {
    leagueTitle: '#203046',
    leagueContents: '#141d2b',
    fixtureBg: '#080f1c',
    oddsBoxBg: '#182F49',
    oddsBoxSelected: '#2a8dcc',
  },
  sportsRecord: {
    betTitle: '#314a6c',
    betContents: '#16202f',
    foldTitle: '#203046',
    foldContents: '#16202f',
    foldBg: '#080f1c',
    spacing: '#0a1424',
  },
  sportsButtons: {
    largeMenu: '#232323',
    largeMenuHover: '#232323',
    accordianTop: '#3b3b3b',
    accordianSub: '#181818',
  },
  boards: {
    titleBg: '#080F1C',
    contentBg: '#16202F',
    txtHover: '#C349FF',
  },
  pagination: {
    default: '#000',
    hover: '#ebbd1c',
    active: '#ebbd1c',
    txtActive: '#fff',
    border: '1px solid #1E2B3D',
    txtDisabled: '#3d3e3e',
  },
  fonts: {
    xlarge: '2rem',
    title: '1.6rem',
    large: '1.4rem',
    medium: '1.2rem',
    small: '1.1rem',
    tiny: '0.9rem',
    default: '1rem',
  },
  forms: {
    formBg: '#252F41',
    formLine: '#3B4A65',
    placeholderColor: '#747D8A',
    disabledBg: '#0F151E',
    disabledBorder: '#3B4A65',
  },
  tabs: {
    default: '#16202F',
    tabBorder: '#1E2B3D',
    tabBg: '#16202F',
    tabActive: '#ebbd1c',
  },
  typography: {
    fontFamily: ['Noto Sans'].join(','),
  },
  footer: {
    border: '1px solid #ebbd1c',
    background: '#050402',
    innerColor: '#808080',
    copyright: '#808080',
  },
  height: {
    header: 98,
    footer: 180,
  },
})

export default DarkTheme
