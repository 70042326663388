/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { jsx, css, Global } from '@emotion/react'
import { Box, Typography, Button } from '@mui/material'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import * as _ from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import { numberWithCommas, fillZero, invalidateNumber } from '~/utils/utils'
import useRootStore from '@store/useRootStore'
import { GlobalStore, ModalKeys } from '@store/globalStore'
import BettingTimerbar from './BettingTimerbar'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import NumberFormat from 'react-number-format'

// icon
import { ReactComponent as IconTrash } from '@assets/images/layout_1/icon_trash.svg'
import { ReactComponent as IconBetting } from '@assets/images/layout_1/icon_betting.svg'
import { Formik } from 'formik'

// css
import { BasicButton } from '~/styles/button.style'
import { MyBettings, CartListStyle, AmountStyle, CartFormStyle } from './index.style'
import { string } from 'yup/lib/locale'
import { styled } from '@mui/material/styles'
import { borderLeft } from '@mui/system'
import { useOnUnmount } from '~/utils/reactExt'

const BetSlip = styled('div')(({ theme }) => ({
  //height: '100%',
  '& .btn_open': {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1200,
    width: '64px',
    height: '42px',
  },
}))

const BetSlipTop = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -drawerBleeding,
  right: 0,
  left: 0,
  visibility: 'visible',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  backgroundColor: '#f1f1f1',
  //backgroundColor: theme.buttons.success,
}))

const ItemButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.buttons.success,
  border: '1px solid transparent',
  borderRadius: '0.3rem',
  color: '#fff',
  fontSize: '1rem',
  minWidth: '31.5%',
  padding: '0.5rem 0',

  '&:hover': {
    backgroundColor: theme.buttons.successHover,
    color: '#fff',
  },
  '&:disabled': {
    color: '#c0cad8',
  },
  '&.clear': {
    minWidth: '48.8%',
    backgroundColor: '#252f40',
  },
  '&.clear:hover': {
    backgroundColor: '#1a1e25',
  },
  '&.clear:disabled': {
    color: '#959595',
  },
}))

const drawerBleeding = 42

let timer

const s = 1000
const m = s * 60
const h = m * 60
const d = h * 24

const BettingCart: React.FC = props => {
  const intl = useIntl()
  const { globalStore, userStore, miniGameStore } = useRootStore()

  const [canBet, setCanBet] = useState(false)
  const [open, setOpen] = useState(false)

  const refBlockSubmitting = useRef<boolean>(false)

  const { userMoney } = userStore
  const { matchupSource, cartItems, dividendRate, dividend, volume } = miniGameStore

  const { balance } = userMoney

  const setNextOpenTimeFunc = (dateFrom, dateTo) => {
    const now = new Date()

    const can_bet = dateFrom.getTime() < now.getTime() && now.getTime() < dateTo.getTime()
    if (canBet !== can_bet) {
      setCanBet(can_bet)
    }
  }

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    let from = new Date()
    let to = new Date()
    if (matchupSource) {
      from = new Date(matchupSource.bet_start_time)
      to = new Date(matchupSource.bet_end_time)
    }
    timer = setInterval(() => {
      setNextOpenTimeFunc(from, to)
    }, 500)
  }, [matchupSource])

  useOnUnmount(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
  })

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen)
  }

  const lookupColor = ['[파]', '[행]']
  const bettingItemContents = []
  for (const el of cartItems) {
    let categoryColor = lookupColor.indexOf(el.market) >= 0 ? '#5b9cfb' : '#ff5678'
    let style = {}
    _.extend(style, {
      paddingTop: bettingItemContents.length > 0 ? '4px' : '',
    })

    bettingItemContents.push(
      <li key={el.marketGroup}>
        <Box sx={{ display: 'flex', flex: '1 1 0', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: '1 1 0',
              fontSize: '1.1rem',
            }}
          >
            <div>
              <span style={{ color: categoryColor }}>{el.market}</span>
              <span style={{ color: el.marketColor, marginLeft: '4px' }}>{el.odds}</span>
            </div>
            <div>
              <span style={{ color: '#2E3A4D' }}>{el.rate}</span>
            </div>
          </Box>
          <BasicButton
            icon
            style={{ marginLeft: '8px' }}
            onClick={() => miniGameStore.removeCartItem(el.marketGroup, el.marketId, el.oddsId)}
          >
            <IconTrash width="24" fill="#BBBDC1" />
          </BasicButton>
        </Box>
      </li>,
    )
  }

  let oneItem = null
  if (cartItems.length > 0) {
    const el = cartItems[0]
    let categoryColor = lookupColor.indexOf(el.market) >= 0 ? '#5b9cfb' : '#ff5678'
    oneItem = (
      <Box sx={{ display: 'flex', flex: '1 1 0', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: '1 1 0',
            fontSize: '1.1rem',
          }}
        >
          <div>
            <span style={{ color: categoryColor }}>{el.market}</span>
            <span style={{ color: el.marketColor, marginLeft: '4px' }}>{el.odds}</span>
            <span style={{ marginLeft: '4px', color: '#747D8A' }}>
              {cartItems.length > 1 ? '외' + (cartItems.length - 1) : ''}
            </span>
          </div>
          <div>
            <span style={{ color: '#2A73E1', fontWeight: 'bold' }}>{el.rate}</span>
          </div>
        </Box>
        <BasicButton
          icon
          style={{ marginLeft: '8px' }}
          onClick={() => miniGameStore.removeCartItem(el.marketGroup, el.marketId, el.oddsId)}
        >
          <IconTrash width="24" fill="#BBBDC1" />
        </BasicButton>
      </Box>
    )
  }

  const onClickBettingRecord = () => {
    globalStore.showModal(ModalKeys.betting_record, 0)
  }

  const submit = async (values: any, { setSubmitting, setErrors, setStatus, resetForm }) => {
    if (refBlockSubmitting.current) {
      return
    }
    const { volume } = values
    let amount = invalidateNumber(volume)

    miniGameStore.setBetMoney(amount)

    const ret = await miniGameStore.bet(intl)
    if (ret) {
      resetForm()
    }
  }

  return (
    <BetSlip>
      <Button className="btn_open" onClick={toggleDrawer(true)}>
        open
      </Button>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '.MuiPaper-root': {
            // height: `calc(100% - ${drawerBleeding}px)`,
            height: '530px',
            overflow: 'visible',
          },
        }}
      >
        <BetSlipTop>
          <Typography
            variant="h6"
            component="h3"
            sx={{
              px: 2,
              py: 1,
              fontWeight: 'bold',
            }}
          >
            BET SLIP
          </Typography>
          {oneItem}
          <Button onClick={toggleDrawer(false)} sx={{ color: '#747D8A' }}>
            {!open ? <ExpandLess /> : <ExpandMore />}
          </Button>
        </BetSlipTop>

        <Box css={MyBettings} className="cart">
          <BettingTimerbar></BettingTimerbar>
          <CartListStyle sx={{ minHeight: '112px' }}>{bettingItemContents}</CartListStyle>
          <Formik
            initialValues={{
              volume: 0,
            }}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              // volume: Yup.number().required(intl.formatMessage({ id: 'msg.field-require' })),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <AmountStyle style={{ padding: '8px 16px' }}>
                  <p>
                    <span>총 배당률</span>
                    <span className="highlight">{dividendRate}</span>
                  </p>
                  <p>
                    <span>예상 배당금액</span>
                    <span>
                      <span className="highlight">{numberWithCommas(dividend)}</span>
                      {intl.formatMessage({ id: 'money-locale' })}
                    </span>
                  </p>
                  <p>
                    <span className="my_money">내 보유금액</span>
                    <span>
                      <span className="my_money">{numberWithCommas(balance)}</span>
                      {intl.formatMessage({ id: 'money-locale' })}
                    </span>
                  </p>
                  <div className="betting_input">
                    <div className="form_label">베팅</div>
                    <NumberFormat
                      disabled={!canBet}
                      className="form_control"
                      name="volume"
                      // value={values.volume}
                      value={volume}
                      onChange={e => {
                        handleChange(e)
                        try {
                          const { value } = e.target
                          let tmp = invalidateNumber(value)
                          miniGameStore.setBetMoney(tmp)
                        } catch (err) {
                          console.log(err)
                        }
                      }}
                      onBlur={handleBlur}
                      thousandSeparator={true}
                      suffix="원"
                      style={{ padding: '0.35rem' }}
                    ></NumberFormat>
                  </div>
                </AmountStyle>

                <CartFormStyle style={{ padding: '8px 16px' }}>
                  <Box className="item_buttons" sx={{ gap: 1 }}>
                    <ItemButton
                      disabled={!canBet}
                      onClick={() => {
                        refBlockSubmitting.current = true
                        let tmp = invalidateNumber(volume)
                        tmp += 5000
                        tmp = Math.min(balance, tmp)
                        setFieldValue('volume', tmp)
                        miniGameStore.setBetMoney(tmp)
                      }}
                    >
                      5천
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      onClick={() => {
                        refBlockSubmitting.current = true
                        let tmp = invalidateNumber(volume)
                        tmp += 10000
                        tmp = Math.min(balance, tmp)
                        setFieldValue('volume', tmp)
                        miniGameStore.setBetMoney(tmp)
                      }}
                    >
                      1만
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      onClick={() => {
                        refBlockSubmitting.current = true
                        let tmp = invalidateNumber(volume)
                        tmp += 50000
                        tmp = Math.min(balance, tmp)
                        setFieldValue('volume', tmp)
                        miniGameStore.setBetMoney(tmp)
                      }}
                    >
                      5만
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      onClick={() => {
                        refBlockSubmitting.current = true
                        let tmp = invalidateNumber(volume)
                        tmp += 100000
                        tmp = Math.min(balance, tmp)
                        setFieldValue('volume', tmp)
                        miniGameStore.setBetMoney(tmp)
                      }}
                    >
                      10만
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      onClick={() => {
                        refBlockSubmitting.current = true
                        let tmp = invalidateNumber(volume)
                        tmp += 500000
                        tmp = Math.min(balance, tmp)
                        setFieldValue('volume', tmp)
                        miniGameStore.setBetMoney(tmp)
                      }}
                    >
                      50만
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      onClick={() => {
                        refBlockSubmitting.current = true
                        let tmp = invalidateNumber(volume)
                        tmp += 1000000
                        tmp = Math.min(balance, tmp)
                        setFieldValue('volume', tmp)
                        miniGameStore.setBetMoney(tmp)
                      }}
                    >
                      100만
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      className="clear"
                      onClick={() => {
                        refBlockSubmitting.current = true
                        setFieldValue('volume', balance)
                        miniGameStore.setBetMoney(balance)
                      }}
                    >
                      MAX
                    </ItemButton>
                    <ItemButton
                      disabled={!canBet}
                      className="clear"
                      onClick={() => {
                        refBlockSubmitting.current = true
                        setFieldValue('volume', 0)
                        miniGameStore.setBetMoney(0)
                      }}
                    >
                      초기화
                    </ItemButton>
                  </Box>

                  <Box sx={{ textAlign: 'center' }}>
                    <BasicButton
                      disabled={!canBet || isSubmitting}
                      type="submit"
                      className="btn_betting"
                      onClick={() => {
                        refBlockSubmitting.current = false
                        let tmp = invalidateNumber(values.volume)
                        setFieldValue('volume', tmp)
                      }}
                      sx={{ mt: 1 }}
                    >
                      <IconBetting width="24" />
                      베팅하기
                    </BasicButton>

                    <Button
                      onClick={onClickBettingRecord}
                      size="large"
                      sx={{
                        mt: 1,
                        mx: 'auto',
                        py: 0,
                        color: '#2366CB',
                        fontSize: '1.1rem',
                        textDecoration: 'underline',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      베팅 내역 확인
                    </Button>
                  </Box>
                </CartFormStyle>
              </form>
            )}
          </Formik>
        </Box>
      </SwipeableDrawer>
    </BetSlip>
  )
}

export default observer(BettingCart)
