import { ODDS_BUTTON_COLOR, OddsAttributes, OddsHeaderData, OddsTransform } from '../components/types'

const buttonOffsetY: number[] = [70, 177, 284]

export const ODDS_BUTTON_SIZE = {
  XSMALL: { cx: 58, cy: 72 },
  SMALL: { cx: 62, cy: 72 },
  MEDIUM: { cx: 70, cy: 72 },
  LARGE: { cx: 78, cy: 72 },
  HUGE: { cx: 117, cy: 124 },
}

export const FRIST_ROW_A_BUTTONS: OddsAttributes[] = [
  {
    id: 'NODD',
    marketId: 'NODD_NEVEN',
    title: '홀',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 0, y: buttonOffsetY[0] },
  },
  {
    id: 'NEVEN',
    marketId: 'NODD_NEVEN',
    title: '짝',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 60, y: buttonOffsetY[0] },
  },
  {
    id: 'PODD',
    marketId: 'PODD_PEVEN',
    title: 'P홀',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 120, y: buttonOffsetY[0] },
  },
  {
    id: 'PEVEN',
    marketId: 'PODD_PEVEN',
    title: 'P짝',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 180, y: buttonOffsetY[0] },
  },
]

export const FRIST_ROW_B_BUTTONS: OddsAttributes[] = [
  {
    id: 'NUNDER',
    marketId: 'NUNDER_NOVER',
    title: '언더',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 240, y: buttonOffsetY[0] },
    headText: '15-72',
  },
  {
    id: 'NOVER',
    marketId: 'NUNDER_NOVER',
    title: '오버',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 300, y: buttonOffsetY[0] },
    headText: '73-130',
  },
  {
    id: 'PUNDER',
    marketId: 'PUNDER_POVER',
    title: 'P언더',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 360, y: buttonOffsetY[0] },
    headText: '0-4',
  },
  {
    id: 'POVER',
    marketId: 'PUNDER_POVER',
    title: 'P오버',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.XSMALL,
    position: { x: 420, y: buttonOffsetY[0] },
    headText: '5-9',
  },
]

export const SECOND_ROW_BUTTONS: OddsAttributes[] = [
  {
    id: 'NLARGE',
    marketId: 'NLMS',
    title: '대',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.YELLOW,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 2, y: buttonOffsetY[1] },
    headText: '81-130',
  },
  {
    id: 'NMEDIUM',
    marketId: 'NLMS',
    title: '중',
    rate: '2.4',
    color: ODDS_BUTTON_COLOR.YELLOW,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 66, y: buttonOffsetY[1] },
    headText: '65-80',
  },
  {
    id: 'NSMALL',
    marketId: 'NLMS',
    title: '소',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.YELLOW,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 130, y: buttonOffsetY[1] },
    headText: '15-64',
  },
  {
    id: 'NODD+NUNDER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '홀언더',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 198, y: buttonOffsetY[1] },
  },
  {
    id: 'NODD+NOVER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '홀오버',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 270, y: buttonOffsetY[1] },
  },
  {
    id: 'NEVEN+NUNDER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '짝언더',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 342, y: buttonOffsetY[1] },
  },
  {
    id: 'NEVEN+NOVER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '짝오버',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 414, y: buttonOffsetY[1] },
  },
]

export const THIRD_ROW_BUTTONS: OddsAttributes[] = [
  {
    id: 'NODD+NLARGE',
    marketId: 'NODD_NEVEN+NLMS',
    title: '홀대',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 10, y: buttonOffsetY[2] },
  },
  {
    id: 'NODD+NMEDIUM',
    marketId: 'NODD_NEVEN+NLMS',
    title: '홀중',
    rate: '2.4',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 90, y: buttonOffsetY[2] },
  },
  {
    id: 'NODD+NSMALL',
    marketId: 'NODD_NEVEN+NLMS',
    title: '홀소',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 170, y: buttonOffsetY[2] },
  },
  {
    id: 'NEVEN+NLARGE',
    marketId: 'NODD_NEVEN+NLMS',
    title: '짝대',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 250, y: buttonOffsetY[2] },
  },
  {
    id: 'NEVEN+NMEDIUM',
    marketId: 'NODD_NEVEN+NLMS',
    title: '짝중',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 330, y: buttonOffsetY[2] },
  },
  {
    id: 'NEVEN+NSMALL',
    marketId: 'NODD_NEVEN+NLMS',
    title: '짝소',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 410, y: buttonOffsetY[2] },
  },
]

// headers
export const FIRST_ROW_A_HEADERS: OddsHeaderData[] = [
  {
    id: 'NODD_NEVEN',
    title: '일반볼 홀짝',
    size: { cx: 116, cy: 24 },
    position: { x: 30, y: 19 },
  },
  {
    id: 'PODD_PEVEN',
    title: '파워볼 홀짝',
    size: { cx: 116, cy: 24 },
    position: { x: 150, y: 19 },
  },
]

export const FIRST_ROW_B_HEADERS: OddsHeaderData[] = [
  {
    id: 'NUNDER_NOVER',
    title: '일반볼 언더오버',
    size: { cx: 116, cy: 24 },
    position: { x: 270, y: 19 },
  },
  {
    id: 'PUNDER_POVER',
    title: '파워볼 언더오버',
    size: { cx: 116, cy: 24 },
    position: { x: 390, y: 19 },
  },
]

export const SECOND_ROW_HEADERS: OddsHeaderData[] = [
  {
    id: 'LMS',
    title: '일반볼 대/중/소',
    size: { cx: 189, cy: 24 },
    position: { x: 65, y: 126 },
  },
  {
    id: 'NODD_NEVEN+NUNDER_NOVER',
    title: '일반볼 조합 (홀짝+언더오버)',
    size: { cx: 284, cy: 24 },
    position: { x: 306, y: 126 },
  },
]

export const THIRD_ROW_HEADERS: OddsHeaderData[] = [
  {
    id: 'NODD_NEVEN+NLMS',
    title: '일반볼 조합 (홀짝+대중소)',
    size: { cx: 475, cy: 24 },
    position: { x: 209, y: 233 },
  },
]

const enlargeOffsetY: number[] = [96, 257]

// enlarge
export const ENLARGED_FRIST_ROW_A_BUTTONS: OddsTransform[] = [
  {
    id: 'NODD',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 29, y: enlargeOffsetY[0] },
  },
  {
    id: 'NEVEN',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 149, y: enlargeOffsetY[0] },
  },
  {
    id: 'PODD',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 269, y: enlargeOffsetY[0] },
  },
  {
    id: 'PEVEN',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 390, y: enlargeOffsetY[0] },
  },
]

export const ENLARGED_SECOND_ROW_B_BUTTONS: OddsTransform[] = [
  {
    id: 'NUNDER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 29, y: enlargeOffsetY[1] },
  },
  {
    id: 'NOVER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 149, y: enlargeOffsetY[1] },
  },
  {
    id: 'PUNDER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 269, y: enlargeOffsetY[1] },
  },
  {
    id: 'POVER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 390, y: enlargeOffsetY[1] },
  },
]

export const ENLARGED_FRIST_ROW_HEADERS: OddsTransform[] = [
  {
    id: 'NODD_NEVEN',
    size: { cx: 235, cy: 24 },
    position: { x: 89, y: 19 },
  },
  {
    id: 'PODD_PEVEN',
    size: { cx: 235, cy: 24 },
    position: { x: 328, y: 19 },
  },
]

export const ENLARGED_SECCOND_ROW_HEADERS: OddsTransform[] = [
  {
    id: 'NUNDER_NOVER',
    size: { cx: 235, cy: 24 },
    position: { x: 89, y: 180 },
  },
  {
    id: 'PUNDER_POVER',
    size: { cx: 235, cy: 24 },
    position: { x: 328, y: 180 },
  },
]
