/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import { useState, useEffect, Fragment } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { popupCenter } from '@utils/utils'
import { Box, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { ModalKeys } from '@store/globalStore'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

import { AllReelGames } from '~/game/reel_data'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { ThemeConsumer } from 'react-bootstrap/esm/ThemeProvider'
import asset_channel from '@assets/channel'

// css
import { ImageListItemStyle } from '@styles/base.style'
import { STAR_GAME_ID, STAR_GAME_INFO } from '~/v2/interface/types'

const SEASTORY_INFO = _.find(STAR_GAME_INFO, o => {
  return o.gameId === STAR_GAME_ID.SEASTORY
})

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const ListTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  color: theme.colors.listTitle,
  fontWeight: 'bold',
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const Reel: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('REEL')
    }
  }, [signedin, initialized, serviceState])

  async function onClickSlotCard(trading_kind: string) {
    globalStore.signedinNewGame('REEL', trading_kind, signedin, intl)
  }

  let colsValue = desktop ? 4 : 2

  const listItems = []

  if (open_trading_kinds) {
    let slotItems = _.filter(open_trading_kinds, o => {
      return o.trading_group === 'REEL'
    })
    const ordered = _.orderBy(slotItems, ['order'], ['asc'])

    const popularItems = ordered.slice(0, 1)

    for (const trading of popularItems) {
      const el = _.find(AllReelGames, o => {
        return o.kind === trading.trading_kind
      })
      if (el) {
        let name = trading.name
        if (trading.trading_kind.indexOf('STAR_SEASTORY') >= 0) {
          name = intl.formatMessage({
            id: 'trading.REEL.STAR_SEASTORY',
            defaultMessage: SEASTORY_INFO?.title || name,
          })
        }

        listItems.push(
          <ImageListItemStyle onClick={() => onClickSlotCard(el.kind)} key={el.kind}>
            <div className="img_wrap">
              <img
                src={el.thumbnail}
                alt={'game image ' + el.kind}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line"></div>
            </div>
            <ImageListItemBar
              title={<div style={{ fontSize: '1.1rem' }}>{name}</div>}
              position="below"
              sx={{
                border: 'none',
                '& .MuiImageListItemBar-titleWrap': {
                  padding: '6px 0',
                },
              }}
            />
          </ImageListItemStyle>,
        )
      }
    }
  }

  let contents = null
  if (desktop) {
    contents = (
      <Container
        className="content_wrap"
        sx={{ minWidth: '1248px', pb: 5, backgroundColor: appBackground }}
      >
        <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
          {intl.formatMessage({ id: 'component.title.popular-box' })}
        </ListTitle>
        <ImageList cols={colsValue} gap={12}>
          {listItems}
        </ImageList>
      </Container>
    )
  } else {
    contents = (
      <Box
        className="content_wrap_m"
        sx={{ backgroundColor: appBackground, p: 2, minHeight: 'calc(100vh - 202px)' }}
      >
        <ListTitle sx={{ fontSize: '1.2rem' }}>
          {intl.formatMessage({ id: 'component.title.popular-box' })}
        </ListTitle>
        <ImageList cols={colsValue} gap={12} sx={{ mb: 5 }}>
          {listItems}
        </ImageList>
      </Box>
    )
  }

  return <>{contents}</>
}

export default observer(Reel)
