import * as _ from 'lodash'

// category
// KINO - Squidgame
export const KinoMarketMeta = [
  // 행운
  {
    id: 'KLODD_KLEVEN',
    category: 'KL',
    locale: { name: '행운 홀짝', fullname: '행운 홀짝' },
    metaOdds: ['KLODD', 'KLEVEN'],
  },
  {
    id: 'KLUNDER_KLOVER',
    category: 'KL',
    locale: { name: '행운 언오버', fullname: '행운 언오버' },
    metaOdds: ['KLUNDER', 'KLOVER'],
  },
  {
    id: 'KLODD_KLEVEN+KLUNDER_KLOVER',
    category: 'KL',
    locale: { name: '행운 조합', fullname: '행운 조합' },
    metaOdds: ['KLODD+KLUNDER', 'KLODD+KLOVER', 'KLEVEN+KLUNDER', 'KLEVEN+KLOVER'],
  },
  {
    id: 'KLABCD',
    category: 'KL',
    locale: { name: '구간', fullname: '행운 구간' },
    metaOdds: ['KLA', 'KLB', 'KLC', 'KLD'],
  },
  // 숫자합
  {
    id: 'KSODD_KSEVEN',
    category: 'KS',
    locale: { name: '숫자합 홀짝', fullname: '숫자합 홀짝' },
    metaOdds: ['KSODD', 'KSEVEN'],
  },
  {
    id: 'KSUNDER_KSOVER',
    category: 'KS',
    locale: { name: '숫자합 언오버', fullname: '숫자합 언오버' },
    metaOdds: ['KSUNDER', 'KSOVER'],
  },
  {
    id: 'KSODD_KSEVEN+KSUNDER_KSOVER',
    category: 'KS',
    locale: { name: '숫자합 조합', fullname: '숫자합 조합' },
    metaOdds: ['KSODD+KSUNDER', 'KSODD+KSOVER', 'KSEVEN+KSUNDER', 'KSEVEN+KSOVER'],
  },
  {
    id: 'KSABCD',
    category: 'KS',
    locale: { name: '구간', fullname: '행운 구간' },
    metaOdds: ['KSA', 'KSB', 'KSC', 'KSD'],
  },
]

export const KinoOddsMeta = [
  { id: 'KLODD', locale: { name: '홀' } },
  { id: 'KLEVEN', locale: { name: '짝' } },
  { id: 'KLUNDER', locale: { name: '언더' } },
  { id: 'KLOVER', locale: { name: '오버' } },

  { id: 'KLODD+KLUNDER', locale: { name: '홀+언더' } },
  { id: 'KLODD+KLOVER', locale: { name: '홀+오버' } },
  { id: 'KLEVEN+KLUNDER', locale: { name: '짝+언더' } },
  { id: 'KLEVEN+KLOVER', locale: { name: '짝+오버' } },

  { id: 'KLA', locale: { name: 'A-0,1,2' }, data: [0, 1, 2] },
  { id: 'KLB', locale: { name: 'B-3,4' }, data: [3, 4] },
  { id: 'KLC', locale: { name: 'C-5,6' }, data: [5, 6] },
  { id: 'KLD', locale: { name: 'D-7,8,9' }, data: [7, 8, 9] },

  { id: 'KSODD', locale: { name: '홀' } },
  { id: 'KSEVEN', locale: { name: '짝' } },
  { id: 'KSUNDER', locale: { name: '언더' } },
  { id: 'KSOVER', locale: { name: '오버' } },

  { id: 'KSODD+KSUNDER', locale: { name: '홀+언더' } },
  { id: 'KSODD+KSOVER', locale: { name: '홀+오버' } },
  { id: 'KSEVEN+KSUNDER', locale: { name: '짝+언더' } },
  { id: 'KSEVEN+KSOVER', locale: { name: '짝+오버' } },

  { id: 'KSA', locale: { name: 'A-0,1,2' }, data: [0, 1, 2] },
  { id: 'KSB', locale: { name: 'B-3,4' }, data: [3, 4] },
  { id: 'KSC', locale: { name: 'C-5,6' }, data: [5, 6] },
  { id: 'KSD', locale: { name: 'D-7,8,9' }, data: [7, 8, 9] },
]
