/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import NumberFormat from 'react-number-format'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { BANK_MENU, BANK_CODES } from '@constants/attrs'
import apiUser from '@services/api/user'
import { IBankState, IMyBankInfo, Schema$BankState, Schema$MyBankInfo } from '@protocol/bank'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton, ItemButton } from '@styles/button.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'
import { invalidateNumber, numberWithCommas, popupCenter } from '@utils/utils'
import { DepositCoinStyle } from './index.style'
import { IUserInfo } from '~/v2/protocol/user'
import { Schema$UserInfoDetails } from '~/v2/interface/common'

const RequestWithdrawCodepay: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [confirmedPassword, setConfirmedPassword] = useState('') // set password
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const [bankInfo, setBankInfo] = useState<Schema$MyBankInfo | null>(null)
  const [details, setDetails] = useState<Schema$UserInfoDetails | null>(null)
  const { globalStore, userStore } = useRootStore()
  const { userMoney } = userStore
  const { balance } = userMoney
  const refBlockSubmitting = useRef<boolean>(false)

  let canWithdraw = false
  let txtWithdrawInfo = null
  let canModifyAccountHolder = true

  if (bankState) {
    canModifyAccountHolder = !bankState.transaction.is_readonly_depositor
    canWithdraw = bankState.can_withdraw
    txtWithdrawInfo = bankState.withdraw_virtual?.withdraw_text
  }

  if (bankInfo) {
    if (!bankInfo.accountHolder) {
      canModifyAccountHolder = true
    }
  }

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  const fetchBankInfo = async (params: IMyBankInfo.Params) => {
    try {
      const { info } = await apiUser.getBankInfo(params)
      const sliceLength = info.accountNumber.length / 4
      const hideStart = sliceLength
      const hideEnd = info.accountNumber.length - sliceLength - 1
      const newAccountNumber = []
      for (let i = 0; i < info.accountNumber.length; i++) {
        if (hideStart <= i && i <= hideEnd) {
          newAccountNumber.push('*')
        } else {
          newAccountNumber.push(info.accountNumber[i])
        }
      }
      info.accountNumber = newAccountNumber.join('')
      setBankInfo(info)
      formik.setFieldValue('name', info.accountHolder)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchUserInfo = async (params: IUserInfo.Params) => {
    try {
      const { user, details } = await apiUser.getUserInfo(params)
      setDetails(details)
      formik.setFieldValue('phone', details.phone)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [bankState])

  useEffect(() => {
    fetchBankState({})
    fetchBankInfo({})
    fetchUserInfo({})
  }, [confirmedPassword])

  const submitTransferPassword = async (values: any) => {
    const { transferPassword } = values
    try {
      const { password2 } = await apiUser.confirmTransferPassword({
        password2: transferPassword,
      })
      setConfirmedPassword(password2)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const submit = async (values: any) => {
    if (refBlockSubmitting.current) {
      return
    }
    if (!canWithdraw) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.cannot.request.exchange' }),
      })
      return
    }
    const { name, phone, volume } = values

    let amount = invalidateNumber(volume)
    try {
      const { url } = await apiUser.withdrawCodepay({
        password2: confirmedPassword,
        name: name.trim(),
        phone: phone.trim(),
        volume: amount,
      })

      if (url) {
        popupCenter({ url, title: 'popup_codepay', w: 800, h: 1000 })
      }

      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-withdraw-done.virtual' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const formik = useFormik({
    initialValues: { name: '', phone: '', volume: 0 },
    onSubmit: values => {
      submit(values)
    },
  })

  const min_amount = bankState ? bankState.transaction.min_withdraw_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_withdraw_unit : 1000
  const txtFormat = intl.formatMessage({ id: 'format.exchange-min-unit' })
  const transaction_text = txtFormat
    .replace('{0}', numberWithCommas(min_amount))
    .replace('{1}', numberWithCommas(min_unit))

  let contents = null
  if (confirmedPassword) {
    contents = (
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <FormStyle>
          <Grid container sx={{ mb: 2, alignItems: 'center' }}>
            <Grid item xs={3}>
              <label className="form_label">
                {intl.formatMessage({ id: 'component.bank.withdraw-account-holder' })}
              </label>
            </Grid>
            <Grid item xs={9}>
              <input
                readOnly={!canModifyAccountHolder}
                className="form_control"
                name="name"
                placeholder={intl.formatMessage({
                  id: 'msg.required.field-account-holder',
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ fontSize: '1.3rem', textAlign: 'end' }}
              />
              {formik.touched.name && formik.errors.name && <div>{formik.errors.name}</div>}
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 2, alignItems: 'center' }}>
            <Grid item xs={3}>
              <label className="form_label">{intl.formatMessage({ id: 'phone-number' })}</label>
            </Grid>
            <Grid item xs={9}>
              <input
                readOnly={!canModifyAccountHolder}
                className="form_control"
                name="phone"
                placeholder={intl.formatMessage({ id: 'msg.required.field-phone' })}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ fontSize: '1.3rem', textAlign: 'end' }}
              />
              {formik.touched.phone && formik.errors.phone && <div>{formik.errors.phone}</div>}
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 2, alignItems: 'baseline' }}>
            <Grid item xs={3}>
              <label className="form_label required">
                {intl.formatMessage({ id: 'component.bank.withdraw-volume' })}
              </label>
            </Grid>
            <Grid item xs={9}>
              <NumberFormat
                className="form_control"
                name="volume"
                placeholder={intl.formatMessage({
                  id: 'component.bank.volume-placeholder',
                })}
                value={formik.values.volume}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                thousandSeparator={true}
                style={{ fontSize: '1.3rem', textAlign: 'end' }}
              ></NumberFormat>
              <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
                <Typography sx={{ fontSize: '12px', color: 'red' }}>{transaction_text}</Typography>
              </Box>
              <Box
                sx={{
                  mt: 2,
                  '& > button': {
                    width: 'calc(25% - 4px)',
                  },
                }}
              >
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[0])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume0' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[1])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume1' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[2])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume2' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[3])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume3' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[4])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume4' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[5])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume5' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    let tmp = invalidateNumber(formik.values.volume)
                    formik.setFieldValue('volume', tmp + BankServiceVolumes[6])
                  }}
                >
                  {intl.formatMessage({ id: 'component.bank.volume6' })}
                </ItemButton>
                <ItemButton
                  onClick={() => {
                    refBlockSubmitting.current = true
                    formik.setFieldValue('volume', 0)
                  }}
                  className="clear"
                >
                  {intl.formatMessage({ id: 'component.bank.volume-clear' })}
                </ItemButton>
              </Box>
            </Grid>
          </Grid>

          <DepositCoinStyle>
            <NeatTextField
              multiline
              defaultValue={txtWithdrawInfo}
              InputProps={{ readOnly: true }}
            ></NeatTextField>
          </DepositCoinStyle>
        </FormStyle>

        <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 4 }}>
          <BasicButton
            className="btn_main"
            type="submit"
            onClick={() => {
              refBlockSubmitting.current = false
              formik.setFieldValue('volume', formik.values.volume)
            }}
          >
            {intl.formatMessage({ id: 'component.bank.req-withdraw' })}
          </BasicButton>
        </Stack>
      </form>
    )
  } else {
    contents = (
      <Formik
        initialValues={{
          transferPassword: '',
        }}
        onSubmit={submitTransferPassword}
        validationSchema={Yup.object().shape({
          transferPassword: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
        })}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setSubmitting,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} autoComplete="off">
            <FormStyle>
              <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                <Grid item xs={3}>
                  <label className="form_label required">
                    {intl.formatMessage({ id: 'component.bank.trasnfer-password' })}
                  </label>
                </Grid>
                <Grid item xs={9}>
                  <input
                    name="transferPassword"
                    className="form_control"
                    type="password"
                    style={{ fontSize: '1.3rem' }}
                    value={values.transferPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>

              <DepositCoinStyle>
                <Box className="danger">
                  {intl.formatMessage({ id: 'msg.exchange-confirm-need' })}
                </Box>
              </DepositCoinStyle>
            </FormStyle>

            <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 4 }}>
              <BasicButton className="btn_main" type="submit" disabled={isSubmitting}>
                {intl.formatMessage({ id: 'ok' })}
              </BasicButton>
            </Stack>
          </form>
        )}
      </Formik>
    )
  }

  return (
    <Grid container sx={{ py: 3, justifyContent: 'center' }}>
      <Grid item xs={7}>
        {contents}
      </Grid>
    </Grid>
  )
}

export default RequestWithdrawCodepay
