/** @jsxImportSource @emotion/react */
import { LogoStyle } from './index.style'
import { IntlShape } from 'react-intl'
import { MouseEventHandler } from 'react'
import * as _ from 'lodash'
import asset_channel from '@assets/channel'
import { Schema$OpenTradingGroup, Schema$OpenTradingKind } from '~/v2/protocol/public'
import { filterMinigameTradingKinds, filterTradingKinds } from '~/game/util'

export const Logo = (onClickLogo: () => void) => (
  <LogoStyle className="logo">
    <a onClick={() => onClickLogo()}>
      <img src={asset_channel.common.main_logo} alt="LOGO" />
    </a>
  </LogoStyle>
)

let EXCLUDE_TOP_MENU = []
if (process.env.REACT_APP_EXCLUDE_TOP_MENU) {
  EXCLUDE_TOP_MENU = JSON.parse(process.env.REACT_APP_EXCLUDE_TOP_MENU) || []
}

export const putTopMenuService = (
  is: IntlShape,
  tradingGroups: Schema$OpenTradingGroup[],
  tradingKinds: Schema$OpenTradingKind[],
  onClick: (name: string, arg: string) => void,
  handleOpen: MouseEventHandler | undefined,
  navActive: (path: string) => void,
) => {
  const lcaContents = filterTradingKinds(tradingGroups, tradingKinds, 'LCA')
  const hcaContents = filterTradingKinds(tradingGroups, tradingKinds, 'HCA')
  const slContents = filterTradingKinds(tradingGroups, tradingKinds, 'SL')
  const reelContents = filterTradingKinds(tradingGroups, tradingKinds, 'REEL')
  const spContents = filterTradingKinds(tradingGroups, tradingKinds, 'SP')
  const mgContents = filterMinigameTradingKinds(tradingGroups, tradingKinds)

  const showNotice = EXCLUDE_TOP_MENU.indexOf('NOTICE') < 0
  const showEvent = EXCLUDE_TOP_MENU.indexOf('EVENT') < 0
  const showPolicy = EXCLUDE_TOP_MENU.indexOf('POLICY') < 0
  const showQna = EXCLUDE_TOP_MENU.indexOf('QNA') < 0

  return (
    <>
      <ul className="main_menu">
        {lcaContents.length > 0 ? (
          <li>
            <a
              className={`nav-link ${navActive('/live-casino')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('live-casino', '')
              }}
            >
              {is.formatMessage({ id: 'menu.header.live-casino' })}
            </a>
          </li>
        ) : null}
        {hcaContents.length > 0 ? (
          <li>
            <a
              className={`nav-link ${navActive('/hotel-casino')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('hotel-casino', '')
              }}
            >
              {is.formatMessage({ id: 'menu.header.hotel-casino' })}
            </a>
          </li>
        ) : null}
        {slContents.length > 0 ? (
          <li>
            <a
              className={`nav-link ${navActive('/slot')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('slot', '')
              }}
            >
              {is.formatMessage({ id: 'menu.header.slot' })}
            </a>
          </li>
        ) : null}
        {reelContents.length > 0 ? (
          <li>
            <a
              className={`nav-link ${navActive('/reel')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('reel', '')
              }}
            >
              {is.formatMessage({ id: 'menu.header.reel' })}
            </a>
          </li>
        ) : null}
        {spContents.length > 0 ? (
          <li>
            <a
              className={`nav-link ${navActive('/sports')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('sports', '')
              }}
            >
              {is.formatMessage({ id: 'menu.header.sports' })}
            </a>
          </li>
        ) : null}
      </ul>
      <ul className="main_menu">
        {mgContents.length > 0 ? (
          <li>
            <a
              className={`nav-link ${navActive('/minigame')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('minigame', '')
              }}
            >
              {is.formatMessage({ id: 'menu.header.minigame' })}
            </a>
          </li>
        ) : null}
        {showQna && (
          <li>
            <a
              className={`nav-link ${navActive('/mypage/search?main=qnaPersonal')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('mypage', 'qnaPersonal')
              }}
            >
              {is.formatMessage({ id: 'menu.header.qna' })}
            </a>
          </li>
        )}
        {showNotice ? (
          <li>
            <a
              className={`nav-link ${navActive('/notice/search?main=NOTICE')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('news', 'NOTICE')
              }}
            >
              {is.formatMessage({ id: 'menu.header.notice' })}
            </a>
          </li>
        ) : null}
        {showEvent ? (
          <li>
            <a
              className={`nav-link ${navActive('/notice/search?main=EVENT')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('news', 'EVENT')
              }}
            >
              {is.formatMessage({ id: 'menu.header.event' })}
            </a>
          </li>
        ) : null}
        {showPolicy ? (
          <li>
            <a
              className={`nav-link ${navActive('/notice/search?main=POLICY')}`}
              onMouseEnter={handleOpen}
              onClick={() => {
                onClick('news', 'POLICY')
              }}
            >
              {is.formatMessage({ id: 'menu.header.policy' })}
            </a>
          </li>
        ) : null}
      </ul>
    </>
  )
}

export const putTopMenuPartner = (
  is: IntlShape,
  onClick: (name: string, args: string) => void,
  handleOpen: MouseEventHandler | undefined,
) => (
  <>
    <ul className="main_menu">
      <li>
        <a
          className="nav-link"
          onMouseEnter={handleOpen}
          onClick={() => {
            onClick('dashboard', '')
          }}
        >
          {is.formatMessage({ id: 'menu.partner.home' })}
        </a>

        <ul className="sub_menu">
          <li className="sub_menu_link" onClick={() => onClick('dashboard', '')}>
            {is.formatMessage({ id: 'menu.partner.home.dashboard' })}
          </li>
        </ul>
      </li>
      <li>
        <a
          className="nav-link"
          onMouseEnter={handleOpen}
          onClick={() => {
            onClick('members.list', '')
          }}
        >
          {is.formatMessage({ id: 'menu.partner.members' })}
        </a>

        <ul className="sub_menu">
          <li className="sub_menu_link" onClick={() => onClick('members.list', '')}>
            {is.formatMessage({ id: 'menu.partner.members.list' })}
          </li>
          <li className="sub_menu_link" onClick={() => onClick('members.concurrent', '')}>
            {is.formatMessage({ id: 'menu.partner.members.concurrent' })}
          </li>
        </ul>
      </li>
      <li>
        <a
          className="nav-link"
          onMouseEnter={handleOpen}
          onClick={() => {
            onClick('betting-record', '')
          }}
        >
          {is.formatMessage({ id: 'menu.partner.betting-record' })}
        </a>
        <ul className="sub_menu">
          <li className="sub_menu_link" onClick={() => onClick('betting-record', '')}>
            {is.formatMessage({ id: 'menu.partner.betting-record.list' })}
          </li>
        </ul>
      </li>
    </ul>
    <ul className="main_menu">
      <li>
        <a
          className="nav-link"
          onMouseEnter={handleOpen}
          onClick={() => {
            onClick('charge-record', '')
          }}
        >
          {is.formatMessage({ id: 'menu.partner.charge-record' })}
        </a>

        <ul className="sub_menu">
          <li className="sub_menu_link" onClick={() => onClick('charge-record', '')}>
            {is.formatMessage({ id: 'menu.partner.charge-record.list' })}
          </li>
        </ul>
      </li>
      <li>
        <a
          className="nav-link"
          onMouseEnter={handleOpen}
          onClick={() => {
            onClick('stats-game', '')
          }}
        >
          {is.formatMessage({ id: 'menu.partner.stats' })}
        </a>

        <ul className="sub_menu">
          <li className="sub_menu_link" onClick={() => onClick('stats-game', '')}>
            {is.formatMessage({ id: 'menu.partner.stats.game' })}
          </li>
        </ul>
      </li>
      <li>
        <a
          className="nav-link"
          onMouseEnter={handleOpen}
          onClick={() => {
            onClick('balance-record', '')
          }}
        >
          {is.formatMessage({ id: 'menu.partner.balance-account' })}
        </a>

        <ul className="sub_menu">
          <li className="sub_menu_link" onClick={() => onClick('balance-record', '')}>
            {is.formatMessage({ id: 'menu.partner.balance-account.list' })}
          </li>
          <li className="sub_menu_link" onClick={() => onClick('balance-request', '')}>
            {is.formatMessage({ id: 'menu.partner.balance-account.request' })}
          </li>
        </ul>
      </li>
    </ul>
  </>
)
