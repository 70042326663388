/** @jsxImportSource @emotion/react */
import { jsx, ThemeProvider, Global } from '@emotion/react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { DialogType } from '~/store/globalStore'
import useRootStore from '~/store/useRootStore'

function DialogLocaleReceiver() {
  const intl = useIntl()
  const { globalStore } = useRootStore()
  const { dialogLocaleData } = globalStore

  useEffect(() => {
    if (dialogLocaleData) {
      const { type, params } = dialogLocaleData
      const { title, text } = params
      if (title) {
        params.title = intl.formatMessage({ id: title, defaultMessage: title })
      }
      if (text) {
        params.text = intl.formatMessage({ id: text, defaultMessage: text })
        if (params.arg1) {
          params.text = params.text.replace('{0}', params.arg1)
        }
        if (params.arg2) {
          params.text = params.text.replace('{1}', params.arg2)
        }
      }
      params.singlePress = intl.formatMessage({ id: 'ok' })
      params.negativePress = intl.formatMessage({ id: 'no' })
      params.positivePress = intl.formatMessage({ id: 'yes' })

      globalStore.pushDialog(type, params)
    }
  }, [dialogLocaleData])

  return <></>
}

export default observer(DialogLocaleReceiver)
