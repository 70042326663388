/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
//import { Table } from 'react-bootstrap'
import { Box, Typography, AppBar, IconButton, FormControlLabel, Checkbox, Link } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'

import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

// icon
import CloseIcon from '@mui/icons-material/Close'

//css
import { MobileModalStyle, MobileModalStyle2, ModalStyle, ModalStyle2 } from '@styles/modal.style'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

interface Props {
  id: number
  title: string
  content: string
  width: number
  height: number
  x: number
  y: number
  onClose: (id: number, nomore: boolean) => void
}

const PopupDefault: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()

  const [nomore, setNomore] = useState(false)

  const { desktop } = globalStore.decideMedia(isDesktop)

  const { id, title, content, width, height, x, y, onClose } = props

  const DialogTypeBox = desktop
    ? {
      flex: `0 0 ${width === 0 ? 350 : width}px`,
      position: 'relative',
      left: x,
      top: y,
      zIndex: '1201',
      margin: '0 16px 16px',
      minHeight: 0,
      width: `${width === 0 ? 350 : width}px`,
      height: `${height + 86}px`,
      overflowY: 'hidden !important',
    }
    : {
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: '1201',
      margin: 0,
      minHeight: 0,
      width: '100%',
      height: '100vh',
      background: 'rgb(0 0 0 / 50%)',
      overflowY: 'hidden !important',
    }

  const DialogTypeBoxInner = desktop
    ? {
      height: '100%',
      background: '#0A1424',
    }
    : {
      background: '#0A1424',
    }

  const DialogTypeBoxInner2 = desktop
    ? {
      height: `${height + 86}px`,
      background: '#101118',
    }
    : {
      background: '#101118',
    }
  const onClosePopup = () => {
    onClose(id, nomore)
  }

  const onClickCheckbox = () => {
    onClose(id, true)
  }

  const style = desktop ? ModalStyle : MobileModalStyle
  const style2 = desktop ? ModalStyle2 : MobileModalStyle2

  let contents = null

  if (channelSymbol === 'marine' || channelSymbol === 'tiger' || channelSymbol === 'hilton' || channelSymbol === 'marine2' || channelSymbol === 'soft' || channelSymbol === 'gangnam' || channelSymbol === 'milky' || channelSymbol === 'hulk') {
    contents = (
      <Box css={style2} sx={DialogTypeBox}>
        <Box sx={DialogTypeBoxInner2}>
          <AppBar sx={{ position: 'relative' }}>
            <IconButton color="inherit" onClick={onClosePopup} aria-label="close" sx={{ right: 0 }}>
              <CloseIcon />
            </IconButton>
          </AppBar>

          <Box
            component="div"
            sx={desktop ? { height: 'calc(100% - 39px)' } : { minHeight: '45px', maxHeight: 'calc(100vh - 39px)' }}
            style={{ overflow: 'auto' }}
          >
            <Box
              sx={{ color: '#fff', '& p': { marginBottom: 0, padding: '8px 16px' }, '& img': { width: '100%' } }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Box>
          </Box>
          <Box
            sx={{
              px: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              background: '#000',
              width: '100%',
              height: '39px',
              borderTop: '1px solid #41424c',
              '& > a': {
                fontWeight: 'bold',
                background: 'linear-gradient(#9597a4 0%, #9597a4 100%)',
                backgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              },
              '& > a:hover': {
                background: 'linear-gradient(#ffdf2d 0%, #e59a16 100%)',
                backgroundClip: 'text',
              },
            }}
          >
            <Link onClick={onClickCheckbox}>다시 보지 않기</Link>
            <Link onClick={onClosePopup}>닫기</Link>
          </Box>
        </Box>
      </Box>
    )
  } else {
    contents = (
      <Box css={style} sx={DialogTypeBox}>
        <Box sx={DialogTypeBoxInner}>
          <AppBar sx={{ position: 'relative' }}>
            <Typography className="modal_title" variant="h6" component="div">
              {title}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClosePopup} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>

          <Box
            component="div"
            sx={desktop ? { height: 'calc(100% - 86px)' } : { maxHeight: 'calc(100vh - 86px)' }}
            style={{ overflow: 'auto' }}
          >
            <Box
              sx={{
                color: '#fff',
                '& p': {
                  marginBottom: 0,
                  padding: '0 11px',
                },
                '& img': {
                  width: '100%',
                },
              }}
              dangerouslySetInnerHTML={{ __html: content }}
            ></Box>
          </Box>
          <Box
            sx={{
              px: 2,
              background: 'black',
              color: 'white',
              textAlign: 'start',
              width: '100%',
              borderTop: '1px solid #182740',
            }}
          >
            <FormControlLabel
              control={<Checkbox sx={{ color: 'white' }} onChange={() => onClickCheckbox()} checked={nomore} />}
              label={intl.formatMessage({ id: 'stop-watching-today' })}
              sx={{ mr: 0 }}
            />
          </Box>
        </Box>
      </Box>
    )
  }

  return <>{contents}</>
}

export default observer(PopupDefault)
