import * as _ from 'lodash'

// category
// PBP - Powerball
// PBS - Superball
export const WooriKenoPowerballMarketMeta = [
  // 파워볼
  {
    id: 'PODD_PEVEN',
    category: 'PBP',
    locale: { name: '홀짝', fullname: '파워볼 홀짝' },
    metaOdds: ['PODD', 'PEVEN'],
  },
  {
    id: 'PUNDER_POVER',
    category: 'PBP',
    locale: { name: '언오버', fullname: '파워볼 언오버' },
    metaOdds: ['PUNDER', 'POVER'],
  },
  {
    id: 'PODD_PEVEN+PUNDER_POVER',
    category: 'PBP',
    locale: { name: '홀짝+언오버', fullname: '파워볼 홀짝+언오버' },
    metaOdds: ['PODD+PUNDER', 'PODD+POVER', 'PEVEN+PUNDER', 'PEVEN+POVER'],
  },
  // 슈퍼볼
  {
    id: 'SODD_SEVEN',
    category: 'PBS',
    locale: { name: '홀짝', fullname: '슈퍼볼 홀짝' },
    metaOdds: ['SODD', 'SEVEN'],
  },
  {
    id: 'SUNDER_SOVER',
    category: 'PBS',
    locale: { name: '언오버', fullname: '슈퍼볼 언오버' },
    metaOdds: ['SUNDER', 'SOVER'],
  },
  {
    id: 'SODD_SEVEN+SUNDER_SOVER',
    category: 'PBS',
    locale: { name: '홀짝+언오버', fullname: '슈퍼볼 홀짝+언오버' },
    metaOdds: ['SODD+SUNDER', 'SODD+SOVER', 'SEVEN+SUNDER', 'SEVEN+SOVER'],
  },
  // 조합
  {
    id: 'PODD_PEVEN+SODD_SEVEN',
    category: 'COMBO',
    locale: { name: '파워,슈퍼 홀짝조합', fullname: '파워볼 홀짝+언오버' },
    metaOdds: ['PODD+SODD', 'PODD+SEVEN', 'PEVEN+SODD', 'PEVEN+SEVEN'],
  },
]

export const WooriKenoPowerballOddsMeta = [
  { id: 'PODD', locale: { name: '홀' } },
  { id: 'PEVEN', locale: { name: '짝' } },
  { id: 'PUNDER', locale: { name: '언더' } },
  { id: 'POVER', locale: { name: '오버' } },
  { id: 'PODD+PUNDER', locale: { name: '홀+언더' } },
  { id: 'PODD+POVER', locale: { name: '홀+오버' } },
  { id: 'PEVEN+PUNDER', locale: { name: '짝+언더' } },
  { id: 'PEVEN+POVER', locale: { name: '짝+오버' } },
  { id: 'SODD', locale: { name: '홀' } },
  { id: 'SEVEN', locale: { name: '짝' } },
  { id: 'SUNDER', locale: { name: '언더' } },
  { id: 'SOVER', locale: { name: '오버' } },
  { id: 'SODD+SUNDER', locale: { name: '홀+언더' } },
  { id: 'SODD+SOVER', locale: { name: '홀+오버' } },
  { id: 'SEVEN+SUNDER', locale: { name: '짝+언더' } },
  { id: 'SEVEN+SOVER', locale: { name: '짝+오버' } },
  { id: 'PODD+SODD', locale: { name: '파홀+슈홀' } },
  { id: 'PODD+SEVEN', locale: { name: '파홀+슈짝' } },
  { id: 'PEVEN+SODD', locale: { name: '파짝+슈홀' } },
  { id: 'PEVEN+SEVEN', locale: { name: '파짝+슈짝' } },
]
