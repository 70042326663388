/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Grid, Box, IconButton, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import apiPublic from '@services/api/public'
import { Schema$Captcha } from '@protocol/public'
import RefreshIcon from '@mui/icons-material/Refresh'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { BasicButton } from '@styles/button.style'

//image
import asset_channel from '~/assets/channel'

//css
import { LogoStyle } from './index.style'
import { FormStyleBand } from '@styles/modalBand.style'

const LoginButton = styled(Button)(({ theme }) => ({
  padding: '0.795rem 0.75rem',
  width: '100%',
  borderRadius: 0,
  backgroundColor: theme.colors.themeActive,
  opacity: 0.7,
  color: '#fff',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: theme.colors.themeActive,
    opacity: 1,
    color: '#fff',
    textShadow: '0px 0px 5px white',
  },
}))

const JoinButton = styled(Button)(({ theme }) => ({
  padding: '0.795rem 0.75rem',
  width: '100%',
  borderRadius: 0,
  backgroundColor: theme.colors.themeActive,
  opacity: 0.7,
  color: '#fff',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: theme.colors.themeActive,
    opacity: 1,
    color: '#fff',
    textShadow: '0px 0px 5px white',
  },
}))

const Logo = (
  <LogoStyle
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '40px',
    }}
  >
    <img src={asset_channel.common.main_logo_m} alt="" style={{ width: '60%' }} />
  </LogoStyle>
)

const SigninComponentBand: React.FC = () => {
  const intl = useIntl()
  const { globalStore, authStore } = useRootStore()
  const [captcha, setCaptcha] = useState<Schema$Captcha | null>(null)

  const { initialized } = authStore
  const { option } = globalStore // watch media_mode to get event
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const fetchCaptcha = async () => {
    try {
      const { captcha: c } = await apiPublic.getCaptcha({ type: 'signin' })
      if (c) {
        setCaptcha(c)
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (option && option.useLoginCaptcha) {
      fetchCaptcha()
    }
  }, [option])

  const onClickRefreshCaptcha = () => {
    fetchCaptcha()
  }

  const submit = async (values: any) => {
    const { username, password, captchaText } = values

    if (captcha) {
      if (!captchaText) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'input-captcha' }),
        })
        return
      }
    }

    let usernameVerified = username.trim()
    const succeed = await authStore.login(
      { username: usernameVerified, password, captchaText },
      intl,
    )
    if (!succeed) {
      fetchCaptcha()
    }
  }

  const onClickJoin = () => {
    globalStore.showModal('login', 1)
  }

  return (
    <>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={9} sx={{ py: 4 }}>
          {Logo}
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
              password: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <FormStyleBand>
                  <Grid container rowSpacing={1} sx={{ mt: 3 }}>
                    <Grid item xs={12}>
                      <label className="form_label">아이디</label>
                      <input
                        className="form_control"
                        type="text"
                        name="username"
                        placeholder={intl.formatMessage({ id: 'username' })}
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className="form_label">비밀번호</label>
                      <input
                        className="form_control"
                        type="password"
                        name="password"
                        placeholder={intl.formatMessage({ id: 'password' })}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                      />
                    </Grid>
                  </Grid>
                  {captcha ? (
                    <>
                      <Grid container sx={{ justifyContent: 'center', mt: 3 }}>
                        <Grid item xs={10} sx={{ justifyContent: 'center', background: 'white' }}>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <div
                              dangerouslySetInnerHTML={{ __html: captcha ? captcha.data : '' }}
                            ></div>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            color="primary"
                            size="large"
                            onClick={onClickRefreshCaptcha}
                            aria-label="close"
                          >
                            <RefreshIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid
                          item
                          xs={12}
                          sx={{
                            '& input::placeholder': {
                              color: '#999 !important',
                            },
                          }}
                        >
                          <input
                            id="captchaInput"
                            className="form_control"
                            type="text"
                            name="captchaText"
                            placeholder={intl.formatMessage({
                              id: captcha.isMath ? 'input-captcha-math' : 'input-captcha',
                            })}
                            value={values.captchaText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={16}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </FormStyleBand>

                <Box sx={{ display: 'flex', jsutifyContent: 'center', marginTop: 3 }}>
                  <LoginButton type="submit" size="large" disabled={isSubmitting}>
                    {intl.formatMessage({ id: 'login' })}
                  </LoginButton>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <JoinButton
                    type="button"
                    size="large"
                    onClick={onClickJoin}
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage({ id: 'signup' })}
                  </JoinButton>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(SigninComponentBand)
