/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import useRootStore from '@store/useRootStore'
import ChangePassword from './ChangePassword'
import { ModalKeys } from '@store/globalStore'

function MyInfo(props) {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const { currentKey, currentValue, option } = globalStore
  const { signedin } = authStore
  const { enableChangePassword } = option
  const modalKey = ModalKeys.myinfo

  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex >= 0
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin) {
    // popup login
    globalStore.showModal('login', 0)
  }

  if (!enableChangePassword) {
    history.push(`/mypage/search?main=point`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <ChangePassword></ChangePassword>
    </>
  )
}

export default observer(MyInfo)
