import { RouterStore } from 'mobx-react-router'

export const routerStore = new RouterStore()

export { default as socketStore } from './socketStore'
export { default as globalStore } from './globalStore'
export { default as authStore } from './authStore'
export { default as userStore } from './userStore'
export { default as liveStore } from './liveStore'
export { default as miniGameStore } from './minigameStore'
export { default as casinoStore } from './casinoStore'
export { default as partnerStore } from './partnerStore'
export { default as sportsStore } from './sportsStore'
export { default as sportsLiveStore } from './sportsLiveStore'
