import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Box, Grid, Divider, Typography } from '@mui/material'
import { FormStyle } from '@styles/modal.style'
import { useForm, SubmitHandler } from 'react-hook-form'
import { PointStyle } from './index.style'
import { useIntl } from 'react-intl'
import * as _ from 'lodash'

// icon
import { ReactComponent as DoubleArrow } from '@assets/images/layout_1/double_arrow.svg'

import useRootStore from '@store/useRootStore'
import { BasicButton } from '@styles/button.style'
import { invalidateNumber, numberWithCommas, stringWithComma } from '~/utils/utils'
import apiUser from '@services/api/user'
import { IBankState, Schema$BankState } from '~/v2/protocol/bank'
import CompRecord from './CompRecord'

interface IFormInput {
  volume: string
}

function Comp({ location }) {
  const intl = useIntl()
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const [accComp, setAccComp] = useState(0)
  const [targetComp, setTargetComp] = useState(0)
  const [targetMoney, setTargetMoney] = useState(0)
  const { globalStore, authStore, userStore } = useRootStore()
  const { userMoney, userComp } = userStore
  let { balance: balanceMoney } = userMoney
  let { balance: balanceComp } = userComp
  const { initialized } = authStore

  balanceMoney = Math.floor(balanceMoney)
  balanceComp = Math.floor(balanceComp)
  const maxComp = balanceComp
  // let maxComp = 0
  // if (balanceComp) {
  //   balanceComp = Math.floor(balanceComp)
  //   maxComp = Math.floor(balanceComp / 1000) * 1000
  // }

  const {
    resetField,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>()

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  useEffect(() => {
    async function inline() {
      try {
        const { comp } = await apiUser.getAccComp({})
        setAccComp(comp)
      } catch (err) {
        //
      }
    }
    inline()
    fetchBankState({})
  }, [])

  useEffect(() => {
    resetField('volume')
    volumeUpdated(0)
  }, [initialized, balanceMoney, balanceComp])

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const volume = invalidateNumber(data.volume)
    if (volume <= 0) {
      return
    }
    const ret = await userStore.transferComp(volume, intl)
    if (ret) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.transfer-comp-done' }),
      })
    }
  }

  const volumeUpdated = (v: number) => {
    setTargetComp(balanceComp - v)
    setTargetMoney(balanceMoney + v)
  }

  const onClickMax = () => {
    setValue('volume', stringWithComma(maxComp.toString()))
    volumeUpdated(maxComp)
  }

  const onChangeVolume = e => {
    const snumber = e.target.value.toString()
    let newVolume = 0
    if (_.isString(snumber) && snumber.length > 0) {
      newVolume = invalidateNumber(e.target.value)
      if (newVolume < 0) {
        newVolume = 0
      }
      if (newVolume > maxComp) {
        newVolume = maxComp
      }

      setValue('volume', stringWithComma(newVolume.toString()))
      volumeUpdated(newVolume)
    } else {
      resetField('volume')
      volumeUpdated(0)
    }
  }

  const min_amount = bankState ? bankState.transaction.min_to_comp_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_to_comp_unit : 1000
  const txtFormat = intl.formatMessage({ id: 'format.transfer-min-unit' })
  const transaction_text = txtFormat
    .replace('{0}', numberWithCommas(min_amount))
    .replace('{1}', numberWithCommas(min_unit))

  return (
    <>
      <Box py={6}>
        <PointStyle>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Grid container columnSpacing={10} sx={{ px: 3, position: 'relative' }}>
              <Grid item xs={6}>
                <Box className="point_title" sx={{ mb: 2, color: '#D1D9E3' }}>
                  {intl.formatMessage({ id: 'component.comp.current' })}
                </Box>
                <FormStyle className="point_box">
                  <Grid container alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={4}>
                      <label className="form_label">
                        {intl.formatMessage({ id: 'component.comp' })}
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className="form_control readonly">
                        {numberWithCommas(balanceComp)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <label className="form_label">{intl.formatMessage({ id: 'money' })}</label>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className="form_control readonly">
                        {numberWithCommas(balanceMoney)}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormStyle>
              </Grid>

              <div className="center_arrow">
                <DoubleArrow width="23" />
              </div>

              <Grid item xs={6}>
                <Box className="point_title" sx={{ mb: 2, color: '#2A73E1' }}>
                  {intl.formatMessage({ id: 'component.comp.transfered' })}
                </Box>
                <FormStyle className="point_box">
                  <Grid container alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={4}>
                      <label className="form_label">
                        {intl.formatMessage({ id: 'component.comp' })}
                      </label>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className="form_control readonly">
                        {numberWithCommas(targetComp)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container alignItems="center">
                    <Grid item xs={4}>
                      <label className="form_label">{intl.formatMessage({ id: 'money' })}</label>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className="form_control readonly">
                        {numberWithCommas(targetMoney)}
                      </Typography>
                    </Grid>
                  </Grid>
                </FormStyle>
              </Grid>
            </Grid>
            <Grid container alignItems="center" sx={{ maxWidth: '500px', m: '0 auto', pt: 4 }}>
              <Grid item xs={3} sx={{ color: '#D1D9E3' }}>
                {intl.formatMessage({ id: 'component.comp.req-comp' })}
              </Grid>
              <Grid item xs={9}>
                <FormStyle>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={8}>
                      <input
                        {...register('volume', {
                          required: true,
                          onChange: e => onChangeVolume(e),
                        })}
                        className="form_control point"
                        placeholder={intl.formatMessage({ id: 'component.comp.input-comp' })}
                        type="text"
                        style={{ height: '100%' }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <BasicButton
                        onClick={() => onClickMax()}
                        className="btn_outline_main"
                        sx={{ minWidth: 0, width: '100%' }}
                      >
                        {intl.formatMessage({ id: 'component.comp.maximum' })}
                      </BasicButton>
                    </Grid>
                  </Grid>
                </FormStyle>
              </Grid>
              {errors.volume && (
                <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
                  <span style={{ color: 'red' }}>
                    {intl.formatMessage({ id: 'msg.enter-comp' })}
                  </span>
                </Grid>
              )}
            </Grid>

            <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ fontSize: '12px', color: 'red' }}>{transaction_text}</Typography>
            </Box>

            <Box sx={{ pt: 4, display: 'flex', justifyContent: 'center' }}>
              <BasicButton type="submit" className="btn_main" style={{ minWidth: '240px' }}>
                {intl.formatMessage({ id: 'component.comp.request-ok' })}
              </BasicButton>
            </Box>
          </form>
        </PointStyle>
      </Box>
      <Grid container alignItems="center" justifyContent="end" sx={{ mt: 1, mb: 3 }}>
        <Grid item>
          <Typography sx={{ fontSize: '1.2rem', color: '#fff', mr: 1 }}>
            {intl.formatMessage({ id: 'component.comp.accumulated' })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: '1.2rem', color: '#fff' }}>
            {numberWithCommas(Math.floor(accComp))}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: 1, opacity: 1, borderColor: '#1E2B3D' }} />
      <CompRecord location={location}></CompRecord>
    </>
  )
}

export default observer(Comp)
