/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import * as _ from 'lodash'
import moment from 'moment'
import { observer } from 'mobx-react'
import {
  Grid,
  Box,
  Typography,
  Dialog,
  AppBar,
  IconButton,
  Chip,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'

import useRootStore from '@store/useRootStore'
import { MakeHistoryTrack } from '@store/minigameStore'
import { numberWithCommas } from '@utils/utils'
import apiUser from '@services/api/user'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { IMinigameBetRecords } from '~/v2/protocol/game'

// icon
import CloseIcon from '@mui/icons-material/Close'

//css
import { MobileModalStyle, ModalStyle, MobileTableStyle, TableStyle } from '@styles/modal.style'
import { PaginationStyle } from '~/styles/base.style'
import { ICouponList, Schema$CouponTrackData } from '~/v2/protocol/user'
import { useOnUnmount } from '~/utils/reactExt'

function timestamp(date: Date) {
  var time = new Date(date)
  time.setHours(time.getHours() + 9)
  return time
    .toISOString()
    .replace('T', ' ')
    .substring(0, 19)
}

interface PageData {
  currPage: number
  totalPage: number
}

type CouponListTrackData = Schema$CouponTrackData

const getPageData = (track: CouponListTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

const PopupCoupon: React.FC<{}> = ({}) => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const [track, setTrack] = useState<CouponListTrackData>(null)

  const { globalStore, authStore, userStore } = useRootStore()
  const { currentKey, currentValue } = globalStore
  const { signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.coupon

  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex >= 0
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin) {
    // popup login
    globalStore.showModal('login', 0)
  }

  const fetch = async (params: ICouponList.Params) => {
    try {
      const { data } = await apiUser.couponList(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    userStore.setPositionPopup('COUPON')
    fetch({ offset: 0, limit: ItemPerPage })
  }, [])

  useOnUnmount(() => {
    userStore.setPositionPopup('')
  })

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ offset, limit: ItemPerPage })
  }

  const fetchUseCoupon = async (coupon_id: number) => {
    try {
      await apiUser.useCoupon({ coupon_id: coupon_id })
      await fetch({ offset: track.offset, limit: ItemPerPage })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickUseCoupon = async (coupon_id: number) => {
    const coupon = _.find(track.items, o => {
      return o.coupon_id === coupon_id
    })
    if (!coupon) {
      globalStore.pushDialogOk({ text: intl.formatMessage({ id: 'msg.coupon_cannot_use' }) })
      return
    }
    globalStore.pushDialogLocaleYesNo({
      text: 'msg.format.ask.use_coupon',
      arg1: coupon.coupon_name,
      arg2: numberWithCommas(coupon.amount),
      callbackPositive: async () => {
        fetchUseCoupon(coupon_id)
      },
    })
  }

  let bgSize = ''
  const GlobalStyle = () => css`
    body {
      ${isDesktop ? (bgSize = 'background-size: calc(100% - 17px) auto;') : ''}
    }
  `

  // bet record
  let recordContents = []
  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    for (let i = 0; i < track.items.length; i++) {
      const el = track.items[i]
      const expire_at = el.expire_at ? moment(el.expire_at).format('YYYY-MM-DD') : null
      const used_at = el.used_at ? moment(el.used_at).format('YYYY-MM-DD HH:mm') : null
      let couponState = null
      let requestState = null
      switch (el.state) {
        case 'NORMAL':
          couponState = (
            <Box sx={{ py: '3.5px', color: '#FFFFFF' }}>
              {intl.formatMessage({ id: 'coupon.NORMAL' })}
            </Box>
          )
          break
        case 'USED':
          couponState = (
            <Box sx={{ py: '3.5px', color: '#2A73E1' }}>
              {intl.formatMessage({ id: 'coupon.USED' })}
            </Box>
          )
          break
        case 'EXPIRED':
          couponState = (
            <Box sx={{ py: '3.5px', color: '#FE3D3D' }}>
              {intl.formatMessage({ id: 'coupon.EXPIRED' })}
            </Box>
          )
          break
        case 'RECALLED':
          couponState = (
            <Box sx={{ py: '3.5px', color: '#FE3D3D' }}>
              {intl.formatMessage({ id: 'coupon.RECALLED' })}
            </Box>
          )
          break
      }
      switch (el.request_state) {
        case 'REQUEST':
          requestState = (
            <Box sx={{ py: '4.6px', color: '#FBC342' }}>
              {intl.formatMessage({ id: 'coupon.REQUEST' })}
            </Box>
          )
          break
        case 'REJECT':
          requestState = (
            <Box sx={{ py: '4.6px', color: '#FE3D3D' }}>
              {intl.formatMessage({ id: 'coupon.REJECT' })}
            </Box>
          )
          break
        case 'DONE':
          break
        case 'NORMAL':
          if (el.state === 'NORMAL') {
            if (el.method === 'IMMEDIATELY') {
              requestState = (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => onClickUseCoupon(el.coupon_id)}
                >
                  {intl.formatMessage({ id: 'coupon.USE' })}
                </Button>
              )
            } else if (el.method === 'CONFIRMATION') {
              requestState = (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() => onClickUseCoupon(el.coupon_id)}
                >
                  {intl.formatMessage({ id: 'coupon.USE_CONFIRMATION' })}
                </Button>
              )
            }
          }
          break
      }
      if (desktop) {
        recordContents.push(
          <TableRow key="1">
            <TableCell scope="row" align="left">
              {el.coupon_name}
            </TableCell>
            <TableCell scope="row" align="right">
              {`${numberWithCommas(el.amount)} ${intl.formatMessage({ id: 'money-locale' })}`}
            </TableCell>
            <TableCell align="center">{expire_at}</TableCell>
            <TableCell align="right">{used_at}</TableCell>
            <TableCell align="center">{couponState}</TableCell>
            <TableCell align="center">{requestState}</TableCell>
          </TableRow>,
        )
      } else {
        recordContents.push(
          <Grid container>
            <Grid item xs={12}>
              {el.coupon_name}
            </Grid>
            <Grid item xs={4.8}>
              <Box className="title">{intl.formatMessage({ id: 'table.amount' })}</Box>
              <Box>
                {numberWithCommas(el.amount)}&nbsp;{intl.formatMessage({ id: 'money-locale' })}
              </Box>
            </Grid>
            <Grid item xs={4.8}>
              <Box className="title">{intl.formatMessage({ id: 'table.state' })}</Box>
              {couponState}
            </Grid>
            <Grid item xs={2.4}>
              <Box className="title">{intl.formatMessage({ id: 'table.request-for-use' })}</Box>
              {requestState}
            </Grid>
            <Grid item xs={4.8}>
              <Box className="title">{intl.formatMessage({ id: 'table.end-date' })}</Box>
              <Box>{expire_at}</Box>
            </Grid>
            <Grid item xs={7.2}>
              <Box className="title">{intl.formatMessage({ id: 'table.use-date' })}</Box>
              <Box>{used_at}</Box>
            </Grid>
          </Grid>,
        )
      }
    }
  }

  const pagination = (
    <PaginationStyle
      count={totalTrackPage}
      variant="outlined"
      page={currTrackPage}
      onChange={handlePagingChange}
    />
  )

  let innerContents = null
  if (desktop) {
    innerContents = (
      <Box sx={{ p: 2 }}>
        <TableStyle>
          <Table aria-label="betting history">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'component.coupon.name' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '110px' }}>
                  {intl.formatMessage({ id: 'table.amount' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '180px' }}>
                  {intl.formatMessage({ id: 'table.end-date' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'table.use-date' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'table.state' })}
                </TableCell>
                <TableCell align="center" sx={{ width: '100px' }}>
                  {intl.formatMessage({ id: 'table.request-for-use' })}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{recordContents}</TableBody>
          </Table>
        </TableStyle>
        {pagination}
      </Box>
    )
  } else {
    innerContents = (
      <>
        <Box sx={{ p: 2 }}>
          <MobileTableStyle>{recordContents}</MobileTableStyle>
          {pagination}
        </Box>
      </>
    )
  }

  const titleContents = (
    <AppBar sx={{ position: 'relative' }}>
      <Typography className="modal_title" variant="h6" component="div">
        {intl.formatMessage({ id: 'component.coupon' })}
      </Typography>
      <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </AppBar>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Dialog
        open={visible}
        onClose={onClose}
        css={ModalStyle}
        maxWidth={'md'}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '1000px',
          },
        }}
      >
        {titleContents}
        {innerContents}
      </Dialog>
    )
  } else {
    contents = (
      <Dialog open={visible} onClose={onClose} css={MobileModalStyle}>
        {titleContents}
        <Box component="div" sx={{ overflow: 'auto' }}>
          {innerContents}
        </Box>
      </Dialog>
    )
  }

  return (
    <>
      <Global styles={GlobalStyle} />
      {contents}
    </>
  )
}

export default observer(PopupCoupon)
