/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Tab, Tabs, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'

import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { getStateString, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { AuthStore } from '@store/authStore'
import { GlobalStore } from '@store/globalStore'
import { ModalKeys } from '@store/globalStore'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'

// css
import { BasicTabs, DarkenBg } from '@styles/base.style'

import Point from './Point'
import Comp from './Comp'
import MyInfo from './MyInfo'
import QnaPersonal from './QnaPersonal'

// 탭 관련 ----------
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
// ----------

interface Props {
  main: string
  sub: string
  type: string
  location: any
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

function MobileMyPage(props: Props) {
  const intl = useIntl()
  const history = useHistory()

  const { globalStore, authStore } = useRootStore()
  const { initialized, signedin } = authStore
  const { option } = globalStore
  const { enableComp, enableCoupon, enableChangePassword } = option || {}

  const { main, sub, type, location } = props
  const { id: tabValue, title } = MYPAGE_TAB_VALUES[(main as string) || 'bank']

  if (!signedin && initialized) {
    history.push('/')
    globalStore.showModal('login', 0)
    return null
  }

  const handleChange = (event, idx) => {
    for (const [key, value] of Object.entries(MYPAGE_TAB_VALUES)) {
      if (value.id === idx) {
        const { url } = value
        history.push(url)
        break
      }
    }
  }

  return (
    <DarkenBg sx={{ minHeight: 'calc(100vh - 202px)' }}>
      <Box>
        {/* <Tabs
          value={tabValue}
          onChange={handleChange}
          css={BasicTabs}
          variant="scrollable"
          scrollButtons={false}
          sx={{ '& .basic_tab': { flex: '1 1 0' } }}
        >
          <Tab className="basic_tab" label={intl.formatMessage({ id: 'component.point' })} {...a11yProps(0)} />
          <Tab
            hidden={!enableComp}
            className="basic_tab"
            label={intl.formatMessage({ id: 'component.comp' })}
            {...a11yProps(1)}
          />
          <Tab
            hidden={!enableChangePassword}
            className="basic_tab"
            label={intl.formatMessage({ id: 'component.modify-myinfo' })}
            {...a11yProps(2)}
            sx={{ minWidth: '105px' }}
          />
          <Tab className="basic_tab" label={intl.formatMessage({ id: 'component.private-qna' })} {...a11yProps(3)} />
        </Tabs> */}
        <TabPanel value={tabValue} index={0}>
          <Point location={location}></Point>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Comp location={location}></Comp>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <MyInfo></MyInfo>
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <QnaPersonal location={location}></QnaPersonal>
        </TabPanel>
        {/* <TabPanel value={tabValue} index={3}>
          <QnaPartner location={location}></QnaPartner>
        </TabPanel> */}
      </Box>
      <Divider sx={{ mt: 1, mb: 11, color: 'white', opacity: 0.2 }} />
    </DarkenBg>
  )
}

export default observer(MobileMyPage)
