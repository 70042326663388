/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import * as _ from 'lodash'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import queryString from 'query-string'

import useRootStore from '@store/useRootStore'
import { getStateString, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { GlobalStore } from '@store/globalStore'
import { AuthStore } from '@store/authStore'
import { useMediaQuery } from 'react-responsive'
import { LOCALSTORAGE_KEYS, MEDIA_DESKTOP_WIDTH } from '@constants/index'

import PopupPowerballAlarm from '@views/Popup/PopupPowerballAlarm'
import PopupPowerballBettingGuide from '@views/Popup/PopupPowerballBettingGuide'
import { useHistory } from 'react-router-dom'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'

import DefaultMinigame from './d'
import DefaultMinigameEvo from './d-evo'
import MobileMinigame from './m'
import MobileMinigameEvo from './m/m-evo'
import { splitOpenTradingKinds } from '~/game/util'

const Minigame: React.FC = props => {
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, liveStore, userStore, authStore } = useRootStore()

  const { initialized, signedin } = authStore
  const { serviceState } = liveStore
  const { option } = globalStore // watch media_mode to get event
  const { open_trading_kinds } = option
  let { desktop } = globalStore.decideMedia(isDesktop)

  const { location } = props as any
  const query = queryString.parse(location.search)
  const { group, kind } = query

  const { mg } = splitOpenTradingKinds(open_trading_kinds, null)
  const targetGroup = (group as string) || (mg.length > 0 ? mg[0].trading_group : '')
  const targetKind = (kind as string) || (mg.length > 0 ? mg[0].trading_kind : '')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useOnUnmount(() => {
    globalStore.setPowerballBettingGuideVisible(false)
  })

  if (!targetGroup || !targetKind) {
    history.push('/')
  }

  if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
    userStore.setPositionPage(`${targetGroup}-${targetKind}`)
  }

  let contents = null
  if (desktop) {
    if (group === 'EVOPB') {
      contents = <DefaultMinigameEvo group={targetGroup} kind={targetKind}></DefaultMinigameEvo>
    } else {
      contents = <DefaultMinigame group={targetGroup} kind={targetKind}></DefaultMinigame>
    }
  } else {
    if (group === 'EVOPB') {
      contents = <MobileMinigameEvo group={targetGroup} kind={targetKind}></MobileMinigameEvo>
    } else {
      contents = <MobileMinigame group={targetGroup} kind={targetKind}></MobileMinigame>
    }
  }

  return (
    <>
      {contents}
      <PopupPowerballAlarm kind={targetKind}></PopupPowerballAlarm>
      <PopupPowerballBettingGuide kind={targetKind}></PopupPowerballBettingGuide>
    </>
  )
}

export default observer(Minigame)
