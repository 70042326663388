import { getChipButtonData } from './chip-data'
import FixedChip from './fixed-chip'
import { CHIP_BUTTON_TYPE, ChipAttributes, OddsAttributes } from './types'

export default class OddsButton extends Phaser.GameObjects.Container {
  normal: Phaser.GameObjects.NineSlice = null
  over: Phaser.GameObjects.NineSlice = null
  push: Phaser.GameObjects.NineSlice = null

  highlight: Phaser.GameObjects.NineSlice = null

  title: Phaser.GameObjects.Text = null
  rate: Phaser.GameObjects.Text = null

  headBox: Phaser.GameObjects.NineSlice = null
  headText: Phaser.GameObjects.Text = null

  hitArea: Phaser.GameObjects.Container = null

  chip: FixedChip = null

  captured = false

  enabled = true

  attributes: OddsAttributes = null

  onClick: (btn: OddsButton) => void = null

  disabledAlpha = 0.6

  oddsConfirmed = false

  constructor(
    scene: Phaser.Scene,
    data: OddsAttributes,
    onClick: (btn: OddsButton) => void,
  ) {
    super(scene, data.position.x, data.position.y)

    this.scene.add.existing(this)

    this.attributes = data
    this.onClick = onClick

    this.normal = scene.add.nineslice(
      0,
      0,
      'ui',
      data.color.normal,
      62,
      72,
      21,
      21,
      36,
      36,
    )
    this.add(this.normal)

    this.push = scene.add.nineslice(
      0,
      0,
      'ui',
      data.color.push,
      62,
      72,
      21,
      21,
      36,
      36,
    )
    this.add(this.push)

    this.highlight = scene.add.nineslice(
      0,
      0,
      'ui',
      data.color.highlight,
      62,
      72,
      21,
      21,
      36,
      36,
    )
    this.add(this.highlight)

    this.push.setVisible(false)
    this.highlight.setVisible(false)

    // sizing
    this.transformImmediate(data.size, data.position)

    // add text
    this.title = this.scene.add
      .text(0, -2, data.title, {
        fontSize: '22px',
        color: '#ffffff',
        fontFamily: 'PT Serif',
        fontStyle: 'bold',
        stroke: '#6b6b6b',
        strokeThickness: 1,
        shadow: {
          offsetX: 1,
          offsetY: 1,
          color: '#2c280c',
          stroke: true,
          fill: true,
        },
      })
      .setOrigin(0.5, 0.5)
    this.add(this.title)

    this.rate = this.scene.add
      .text(0, 22, `${data.rate}x`, {
        fontSize: '14px',
        color: '#ffffff',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(this.rate)

    if (data.headText) {
      this.attachHeadText(data.headText)
    }
  }

  attachHeadText(headText: string) {
    const posY = -this.height * 0.5 + 10
    this.headBox = this.scene.add.nineslice(
      0,
      posY,
      'ui',
      'black_14.png',
      14,
      14,
      7,
      7,
      7,
      7,
    )
    this.headBox.setAlpha(0.7)
    this.add(this.headBox)

    this.headText = this.scene.add
      .text(0, posY, headText, {
        fontSize: '11px',
        color: '#ffffff',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)

    this.add(this.headText)

    this.headBox.setSize(this.headText.width + 10, 14)
  }

  clear() {
    this.clearBet()
    this.setHighlight(false)
  }

  clearBet() {
    if (this.chip) {
      this.chip.destroy()
      this.chip = null
    }
  }

  updateBet(volume: number) {
    this.clearBet()

    if (volume <= 0) {
      return
    }
    const attrs = getChipButtonData(volume)
    this.chip = new FixedChip(this.scene, 0, 23, attrs)
    this.add(this.chip)
  }

  confirmRate(rate: string) {
    // console.log(`confirmRate ${this.attributes.id} rate: ${rate}`)
    this.attributes.rate = rate
    this.rate.setText(`${rate}x`)
    this.oddsConfirmed = true
  }

  isEnabledAndConfirmed() {
    return this.enabled && this.oddsConfirmed
  }

  resetInteractive() {
    if (this.hitArea) {
      this.hitArea.destroy()
      this.hitArea = null
    }
    this.hitArea = new Phaser.GameObjects.Container(this.scene, 0, 0)
    this.hitArea.setSize(this.width, this.height)
    this.add(this.hitArea)

    this.hitArea
      .setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
        this.captured = false
        this.normal.setVisible(true)
        this.push.setVisible(false)
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        const lastCaptured = this.captured
        this.captured = false
        if (lastCaptured) {
          if (this.onClick) {
            this.onClick(this)
          }
          if (this.isEnabledAndConfirmed()) {
            this.normal.setVisible(true)
            this.push.setVisible(false)
          } else {
            this.normal.setVisible(false)
            this.push.setVisible(false)
          }
        }
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.isEnabledAndConfirmed()) {
          return
        }
        this.captured = true
        this.normal.setVisible(false)
        this.push.setVisible(true)
      })
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
    // console.log(`${this.attributes.id} ${this.enabled} ${this.oddsConfirmed}`)
    const alpha = this.isEnabledAndConfirmed() ? 1 : this.disabledAlpha
    this.normal.setAlpha(alpha)
    this.push.setAlpha(alpha)
    this.title.setAlpha(alpha)
    this.rate.setAlpha(alpha)
    if (this.headBox) {
      this.headBox.setAlpha(alpha)
    }
    if (this.headText) {
      this.headText.setAlpha(alpha)
    }
  }

  setHighlight(visible: boolean) {
    this.highlight.setVisible(visible)
  }

  recover() {
    this.transform(this.attributes.size, this.attributes.position)
  }

  transform(
    size: { cx: number; cy: number },
    position: { x: number; y: number },
  ) {
    this.tweenTransform(size, position)
  }

  transformImmediate(
    size: { cx: number; cy: number },
    position: { x: number; y: number },
  ) {
    this.setPosition(position.x, position.y)
    this.setSize(size.cx, size.cy)

    // children
    this.normal.setSize(size.cx, size.cy)
    this.push.setSize(size.cx, size.cy)
    this.highlight.setSize(size.cx, size.cy)

    this.resetInteractive()
  }

  tweenTransform(
    size: { cx: number; cy: number },
    position: { x: number; y: number },
  ) {
    // children
    const duration = 250
    const width = size.cx
    const height = size.cy
    const x = position.x
    const y = position.y
    const ease = Phaser.Math.Easing.Quadratic.Out

    // tween container position
    this.scene.tweens.add({
      targets: this,
      x,
      y,
      duration,
      ease,
      onComplete: () => {
        this.resetInteractive()
      },
    })
    // tween container size
    this.scene.tweens.add({
      targets: this,
      width,
      height,
      duration,
      ease,
    })
    // tween image size
    this.scene.tweens.add({
      targets: this.normal,
      width,
      height,
      duration,
      ease,
    })
    this.scene.tweens.add({
      targets: this.push,
      width,
      height,
      duration,
      ease,
    })
    this.scene.tweens.add({
      targets: this.highlight,
      width,
      height,
      duration,
      ease,
    })
  }

  tweenVisible(visible: boolean) {
    this.setVisible(visible)

    if (!visible) {
      return
    }

    const alphaTo = this.isEnabledAndConfirmed() ? 1 : this.disabledAlpha

    const duration = 250
    const ease = Phaser.Math.Easing.Quadratic.Out

    this.normal.setAlpha(0)
    this.scene.tweens.add({
      targets: this.normal,
      alpha: alphaTo,
      duration,
      ease,
    })
    this.push.setAlpha(0)
    this.scene.tweens.add({
      targets: this.push,
      alpha: alphaTo,
      duration,
      ease,
    })
    this.highlight.setAlpha(0)
    this.scene.tweens.add({
      targets: this.highlight,
      alpha: alphaTo,
      duration,
      ease,
    })

    this.scene.tweens.add({
      targets: this.title,
      alpha: alphaTo,
      duration,
      ease,
    })
    this.scene.tweens.add({
      targets: this.rate,
      alpha: alphaTo,
      duration,
      ease,
    })
  }
}
