/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import {
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material'
import { useFormik } from 'formik'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
//import WhiteTheme from '@themes/white.theme'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { IBankState, IMyBankInfo, Schema$BankState, Schema$MyBankInfo } from '@protocol/bank'
import apiUser from '@services/api/user'
import { invalidateNumber, numberWithCommas, popupCenter } from '@utils/utils'
import { BANK_MENU, BANK_CODES } from '~/constants/attrs'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'
import NumberFormat from 'react-number-format'

// icon
import { IconCopy } from '~/assets/images/layout_1'

// css
import { FormStyle } from '@styles/modal.style'
import { BasicButton, ItemButton } from '@styles/button.style'
import { DepositCoinStyle, InfoBoxBandStyle } from '../index.style'

const RequestDepositAuthVirtual: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [bonusChecked, setBonusChecked] = useState(-1)
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const [bankInfo, setBankInfo] = useState<Schema$MyBankInfo | null>(null)
  const { globalStore, userStore } = useRootStore()
  const refBlockSubmitting = useRef<boolean>(false)
  const [virtualAccountState, setVirtualAccountState] = useState('initial')
  const [copiedTooltip, setCopiedTooltip] = useState(false)

  const { userInfo } = userStore

  let canDeposit = false
  let txtDepositInfo = null
  let canBonus = false
  let canModifyAccountHolder = true

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [bankState])

  if (bankState) {
    canModifyAccountHolder = !bankState.transaction.is_readonly_depositor
    canDeposit = bankState.can_deposit
    txtDepositInfo = bankState.deposit_coin.deposit_text

    canBonus = bankState.is_bonus_active
    if (!canBonus && bonusChecked !== 0) {
      setBonusChecked(0)
    }
  }

  if (bankInfo) {
    if (!bankInfo.accountHolder) {
      canModifyAccountHolder = true
    }
  }

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  const fetchBankInfo = async (params: IMyBankInfo.Params) => {
    try {
      const { info } = await apiUser.getBankInfo(params)
      const sliceLength = info.accountNumber.length / 4
      const hideStart = sliceLength
      const hideEnd = info.accountNumber.length - sliceLength - 1
      const newAccountNumber = []
      for (let i = 0; i < info.accountNumber.length; i++) {
        if (hideStart <= i && i <= hideEnd) {
          newAccountNumber.push('*')
        } else {
          newAccountNumber.push(info.accountNumber[i])
        }
      }
      info.accountNumber = newAccountNumber.join('')
      formik.setFieldValue('name', info.accountHolder)
      setBankInfo(info)
      if (info.vBankCode) {
        setVirtualAccountState('issued')
      } else {
        setVirtualAccountState('request')
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    fetchBankState({})
    fetchBankInfo({})
  }, [])

  const submit = async (values: any) => {
    if (refBlockSubmitting.current) {
      return
    }
    if (!canDeposit) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.cannot.request.recharge' }),
      })
      return
    }
    const { name, volume } = values
    if (!name) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.deposit-name-require' })}`,
      })
      return
    }

    let amount = invalidateNumber(volume)
    try {
      if (bonusChecked === -1) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.check.recharge_bonus' }),
        })
        return
      }
      await apiUser.depositCommonVirtual({
        name: name.trim(),
        volume: amount,
        getBonus: bonusChecked === 1,
      })
      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-deposit-done' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const handleChangeBonus = (v: number) => {
    setBonusChecked(v)
  }

  const onClickRquestVirtualAccount = () => {
    requestAccount()
  }

  const requestAccount = async () => {
    if (!bankInfo.accountHolder || !bankInfo.accountNumber || !bankInfo.bankCode) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.use_after_register_account' }),
      })
      return
    }

    try {
      const { resultCode, resultMsg } = await apiUser.requestAuthVirtualAccount({})
      console.log(resultCode)
      console.log(resultMsg)
      globalStore.pushDialogOk({
        text: resultMsg,
      })
      setVirtualAccountState('confirm')
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickConfirmCode = () => {
    confirmCode()
  }

  const confirmCode = async () => {
    const confirmCode = formik.values.confirm_code
    const isFourDigitNumber = /^\d{4}$/.test(confirmCode)
    if (!isFourDigitNumber) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.enter_4_digit_account' }),
      })
      return
    }

    try {
      const { resultCode, resultMsg } = await apiUser.confirmAuthVirtualAccount({ confirmCode })
      console.log(resultCode)
      console.log(resultMsg)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.virtual_account_issued' }),
      })
      setVirtualAccountState('issued')
      fetchBankInfo({})
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const formik = useFormik({
    initialValues: { name: '', volume: 0, confirm_code: '' },
    onSubmit: values => {
      submit(values)
    },
  })

  const min_amount = bankState ? bankState.transaction.min_deposit_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_deposit_unit : 1000
  const transaction_text = `※ 충전은 ${numberWithCommas(min_amount)}원 이상부터 ${numberWithCommas(
    min_unit,
  )}원 단위로 신청 가능`

  let bankName = null
  let accountNumber = null
  let accountHolder = null

  if (bankInfo) {
    const found = _.find(BANK_CODES, o => {
      return o.code === bankInfo.bankCode
    })
    if (found) {
      bankName = found.name
    }
    accountNumber = bankInfo.accountNumber
    accountHolder = bankInfo.accountHolder
  }

  const handleTooltipClose = () => {
    setCopiedTooltip(false)
  }

  const handleTooltipOpen = () => {
    setCopiedTooltip(true)
  }

  let vBankName = null
  let vAccountNumber = null
  let vAccountHolder = null

  if (bankInfo && bankInfo.vBankCode) {
    const found = _.find(BANK_CODES, o => {
      return o.code === bankInfo.vBankCode
    })
    if (found) {
      vBankName = found.name
    }
    vAccountNumber = bankInfo.vAccountNumber
    vAccountHolder = bankInfo.vAccountHolder
  }

  return (
    <Grid container sx={{ py: 2, justifyContent: 'center' }}>
      <Grid item xs={12} sx={{ pb: 4 }}>
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <FormStyle>
            {virtualAccountState === 'initial' && <></>}
            {virtualAccountState === 'request' && (
              <>
                <InfoBoxBandStyle sx={{ mb: 5 }}>
                  <Box className="title">입금 계좌 발급 인증 안내</Box>
                  <Box className="inner_text">
                    <p className="center">
                      회원님의 안전한 입금 계좌발급을 위해 계좌인증을 진행합니다. 계좌 인증은 첫 1회
                      인증 시 더 이상의 인증이 필요하지 않습니다. <br />
                      회원님의 모든 계좌 인증 데이터는 수집하지 않으며,{' '}
                      <span className="danger">계좌인증 완료 시 모든 데이터는 삭제됩니다.</span>
                    </p>
                  </Box>
                </InfoBoxBandStyle>

                <Grid container sx={{ my: 3, alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => onClickRquestVirtualAccount()}
                      sx={{ minWidth: '128px', minHeight: '36px', fontSize: '13px' }}
                    >
                      가상계좌 발급
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}

            {virtualAccountState === 'confirm' && (
              <>
                <Grid
                  container
                  sx={{ mt: 5, mb: 2, alignItems: 'center', justifyContent: 'center' }}
                >
                  <Grid item xs={10} sx={{ my: 1 }}>
                    <Grid>
                      <Grid item xs={12}>
                        <Typography
                          sx={{ fontSize: '16px', color: '#b0c4de', textAlign: 'center' }}
                        >
                          [{bankInfo.accountHolder}]님 {bankName} {bankInfo.accountNumber} 계좌로
                          1원이 입금 되었습니다.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sx={{ my: 1 }}>
                        <Typography
                          variant="h5"
                          sx={{ fontSize: '16px', color: '#b0c4de', textAlign: 'center' }}
                        >
                          입금 내역을 확인해 4자리 숫자를 입력해 주세요.
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mb: 2, alignItems: 'center', justifyContent: 'center' }}>
                      <Grid item xs={12} sx={{ mb: 1 }}>
                        <label className="form_label required">인증 번호</label>
                      </Grid>
                      <Grid item xs={12} container alignItems="center">
                        <Grid item xs={9}>
                          <input
                            className="form_control"
                            name="confirm_code"
                            placeholder="4자리 숫자"
                            value={formik.values.confirm_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{ fontSize: '1.0rem', textAlign: 'end' }}
                            maxLength={4}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={3}
                          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                          <Button
                            color="error"
                            variant="contained"
                            onClick={() => onClickConfirmCode()}
                            sx={{ minWidth: '', minHeight: '36px', fontSize: '13px' }}
                          >
                            인증
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {virtualAccountState === 'issued' && (
              <>
                <InfoBoxBandStyle sx={{ mb: 5 }}>
                  <Box className="title">주의사항</Box>
                  <Box className="inner_text">
                    <p>
                      <span className="danger">
                        • 반드시 등록된 은행의 앱 뱅킹으로 입금해야 합니다.
                      </span>
                    </p>
                    <p>
                      • ATM기, 오픈뱅킹(토스뱅크 등), 은행창구 입금, 평생계좌번호 입금은 불가합니다.
                    </p>
                    <p>
                      • 입금은 광주은행 점검시간 외에 가능합니다.{' '}
                      <span className="danger">(매일 23:30~00:10 40분 동안 입금 불가)</span>
                    </p>
                    <p>
                      • 1회 최대 입금 한도 : 3,000,000원 / 1일 최대 입금 한도 : 60,000,000원 입니다.
                    </p>
                    <p>• 입금 한도 증액이 필요하신 분은 별도로 요청 해주세요.</p>
                  </Box>
                </InfoBoxBandStyle>

                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <Grid container sx={{ mb: 2, alignItems: 'center', justifyContent: 'center' }}>
                      <Grid item xs={12} sx={{ mb: 1 }}>
                        <label className="form_label">입금 은행</label>
                      </Grid>
                      <Grid item xs={12} sx={{ fontSize: '18px' }}>
                        <Box className="form_control disabled">
                          {vBankName} ({vAccountHolder})
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mb: 2, alignItems: 'center', justifyContent: 'center' }}>
                      <Grid item xs={12} sx={{ mb: 1 }}>
                        <label className="form_label">입금 계좌</label>
                      </Grid>
                      <Grid item xs={12} sx={{ position: 'relative', fontSize: '18px' }}>
                        <Box className="form_control disabled">{vAccountNumber}</Box>
                        <CopyToClipboard text={vAccountNumber} onCopy={handleTooltipOpen}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={copiedTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="top"
                            title="계좌번호가 복사되었습니다."
                            arrow
                          >
                            <IconButton
                              onMouseLeave={handleTooltipClose}
                              sx={{
                                position: 'absolute',
                                top: 7,
                                right: 0,
                                '& > svg': {
                                  width: 20,
                                  height: 20,
                                  color: '#fff',
                                },
                              }}
                            >
                              <IconCopy />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                      <Grid item xs={12} sx={{ mb: 1 }}>
                        <label className="form_label">
                          {intl.formatMessage({ id: 'component.bank.deposit-name' })}
                        </label>
                      </Grid>
                      <Grid item xs={12} sx={{ fontSize: '18px' }}>
                        <input
                          readOnly={!canModifyAccountHolder}
                          className="form_control"
                          name="name"
                          placeholder={intl.formatMessage({
                            id: 'component.bank.deposit-placeholder',
                          })}
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div>{formik.errors.name}</div>
                        )}
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mb: 2, alignItems: 'baseline' }}>
                      <Grid item xs={12} sx={{ mb: 1 }}>
                        <label className="form_label required">
                          {intl.formatMessage({ id: 'component.bank.deposit-volume' })}
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        <NumberFormat
                          className="form_control"
                          name="volume"
                          placeholder={intl.formatMessage({
                            id: 'component.bank.volume-placeholder',
                          })}
                          value={formik.values.volume}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          thousandSeparator={true}
                          style={{ fontSize: '1.3rem', textAlign: 'end' }}
                        ></NumberFormat>
                        {formik.touched.volume && formik.errors.volume && (
                          <div>{formik.errors.volume}</div>
                        )}
                        <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
                          <Typography sx={{ fontSize: '12px', color: 'red' }}>
                            {transaction_text}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            mt: 2,
                            '& > button': {
                              width: 'calc(25% - 4px)',
                            },
                          }}
                        >
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[0])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume0' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[1])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume1' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[2])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume2' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[3])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume3' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[4])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume4' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[5])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume5' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              let tmp = invalidateNumber(formik.values.volume)
                              formik.setFieldValue('volume', tmp + BankServiceVolumes[6])
                            }}
                          >
                            {intl.formatMessage({ id: 'component.bank.volume6' })}
                          </ItemButton>
                          <ItemButton
                            onClick={() => {
                              refBlockSubmitting.current = true
                              formik.setFieldValue('volume', 0)
                            }}
                            className="clear"
                          >
                            {intl.formatMessage({ id: 'component.bank.volume-clear' })}
                          </ItemButton>
                        </Box>
                      </Grid>
                    </Grid>
                    <DepositCoinStyle>
                      <NeatTextField
                        multiline
                        defaultValue={txtDepositInfo}
                        InputProps={{ readOnly: true }}
                      ></NeatTextField>
                    </DepositCoinStyle>
                  </Grid>
                </Grid>
              </>
            )}
          </FormStyle>

          {canBonus && virtualAccountState === 'issued' ? (
            <Box sx={{ mt: 2, mx: 'auto', width: '300px' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bonusChecked === 1}
                      onChange={() => handleChangeBonus(1)}
                      sx={{
                        py: 1 / 2,
                        color: '#3B4A65',
                        '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' },
                      }}
                    />
                  }
                  sx={{ color: '#D1D9E3' }}
                  label="첫충(매충) 보너스 받기"
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bonusChecked === 0}
                      onChange={() => handleChangeBonus(0)}
                      sx={{
                        py: 1 / 2,
                        color: '#3B4A65',
                        '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' },
                      }}
                    />
                  }
                  sx={{ color: '#D1D9E3' }}
                  label="첫충(매충) 보너스 받지 않기"
                />
              </FormGroup>
            </Box>
          ) : null}

          {virtualAccountState === 'issued' && (
            <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 3 }}>
              <BasicButton
                className="btn_main"
                type="submit"
                onClick={() => {
                  refBlockSubmitting.current = false
                  formik.setFieldValue('volume', formik.values.volume)
                }}
              >
                {intl.formatMessage({ id: 'component.bank.req-deposit' })}
              </BasicButton>
            </Stack>
          )}
        </form>
      </Grid>
    </Grid>
  )
}

export default observer(RequestDepositAuthVirtual)
