import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useForm, SubmitHandler } from 'react-hook-form'
import { AppBar, Tabs, Tab, Typography, Box, Grid } from '@mui/material'

import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, a11yProps, TabPanel } from '@shared/Common'
import { PointStyle } from '@views/MyPage/Point/index.style'
import { FormStyle } from '@styles/modal.style'
import { ReactComponent as DoubleArrow } from '@assets/images/layout_1/double_arrow.svg'
import { BasicButton } from '@styles/button.style'

import MoneyGive from './MoneyGive'
import MoneyTake from './MoneyTake'

const TAB_NAMES = ['money_give', 'money_take']

function PartnerMoneyTransfer({ location }) {
  const history = useHistory()

  const [tabIndex, setTabIndex] = React.useState(0)

  const query = queryString.parse(location.search)
  const { uuid, type } = query
  const member_id = uuid as string

  const ti = TAB_NAMES.indexOf(type as string)
  if (ti !== tabIndex) {
    setTabIndex(ti)
  }

  useEffect(() => {}, [])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //setTabIndex(newValue)
    const ntt = TAB_NAMES[newValue]
    history.push(`/partner/money-transfer/search?uuid=${uuid}&type=${ntt}`)
  }

  let panelContents = null
  switch (tabIndex) {
    case 0:
      panelContents = <MoneyGive member_id={member_id}></MoneyGive>
      break
    case 1:
      panelContents = <MoneyTake member_id={member_id}></MoneyTake>
      break
  }

  return (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Box sx={{ bgcolor: 'background.paper' }}>
        <AppBar position="static" sx={{ background: 'white' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
            TabIndicatorProps={{
              style: {
                background: 'white',
              },
            }}
          >
            <Tab label="지급" {...a11yProps(0)} sx={{ background: '#1565c0', fontSize: '15px' }} />
            <Tab label="회수" {...a11yProps(1)} sx={{ background: '#d22e2e', fontSize: '15px' }} />
          </Tabs>
        </AppBar>
        {panelContents}
      </Box>
    </ThemeProvider>
  )
}

export default observer(PartnerMoneyTransfer)
