export default class TextButton extends Phaser.GameObjects.Container {
  captured = false

  enabled = true

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    title: string,
    onClick: () => void,
  ) {
    super(scene, x, y)

    this.scene.add.existing(this)

    const txt = this.scene.add
      .text(0, 0, title, {
        fontSize: '13px',
        color: '#ffffff',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txt)

    this.setSize(txt.width, txt.height)

    this.setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
        this.captured = false
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        const lastCaptured = this.captured
        this.captured = false
        if (lastCaptured) {
          if (onClick) {
            onClick()
          }
        }
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.enabled) {
          return
        }
        this.captured = true
      })
  }
}
