/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Container } from '@mui/material'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

// css
import { MobileModalStyle, ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// images
import { imgMinigameNotice01 } from '~/assets/images/layout_1'

// icon
import CloseIcon from '@mui/icons-material/Close'

interface Props {
  kind: string
}

function PopupPowerballAlarm({ kind }: Props) {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore } = useRootStore()

  const [onceClosed, setOnceClosed] = useState(false)

  const { desktop } = globalStore.decideMedia(isDesktop)

  function onClosePopup() {
    setOnceClosed(true)
  }

  const now = new Date()
  let visible = 0 <= now.getHours() && now.getHours() < 6 && (kind === 'PB1' || kind === 'PL1')
  const open = visible && !onceClosed

  let contents = null
  if (desktop) {
    contents = (
      <Dialog
        open={open}
        onClose={onClosePopup}
        css={ModalStyle}
        maxWidth={'xs'}
        sx={{
          '& .MuiDialog-container': {
            height: '100% !important',
          },
          '& .MuiDialog-paper': {
            minHeight: '0 !important',
          },
        }}
      >
        <Box className="modal_content">
          <AppBar sx={{ position: 'relative' }}>
            <Typography className="modal_title" variant="h6" component="div">
              공지사항
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClosePopup} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <Box>
            <Container sx={{ p: '0 !important' }}>
              <img src={imgMinigameNotice01} style={{ width: '100%' }} />
              <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <BasicButton className="btn_sub" onClick={() => onClosePopup()}>
                  닫기
                </BasicButton>
              </Box>
            </Container>
          </Box>
        </Box>
      </Dialog>
    )
  } else {
    contents = (
      <Dialog open={open} onClose={onClosePopup} css={MobileModalStyle} sx={{ zIndex: '1301' }}>
        <Box className="modal_content">
          <AppBar sx={{ position: 'relative' }}>
            <Typography className="modal_title" variant="h6" component="div">
              공지사항
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClosePopup} aria-label="close">
              <CloseIcon />
            </IconButton>
          </AppBar>
          <Box>
            <Container sx={{ p: '0 !important' }}>
              <img src={imgMinigameNotice01} style={{ width: '100%' }} />
              <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
                <BasicButton className="btn_sub" onClick={() => onClosePopup()}>
                  닫기
                </BasicButton>
              </Box>
            </Container>
          </Box>
        </Box>
      </Dialog>
    )
  }

  return <>{contents}</>
}

export default observer(PopupPowerballAlarm)
