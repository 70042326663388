/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Grid, Box, IconButton, Button, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import apiPublic from '@services/api/public'
import { Schema$Captcha } from '@protocol/public'
import RefreshIcon from '@mui/icons-material/Refresh'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

//image
import asset_channel from '~/assets/channel'

//css
import { LogoStyle } from './index.style'
import { FormStyle2 } from '@styles/modal.style'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const SecurityText = {
  '& #captchaInput::placeholder': {
    color: '#9597a4',
  },
}

const LoginButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '51px',
  fontSize: '1.1rem',
  fontWeight: 'bold',
  color: '#000',
  background: 'linear-gradient(#ffcb64 0%, #ffbf44 100%)',
  '&:hover': {
    background: 'linear-gradient(#ffcb64 0%, #ffbf44 100%)',
  },
}))

const JoinButton = styled('a')(({ theme }) => ({
  borderBottom: '1px solid #edc827',
  fontSize: '1.1rem',
  fontWeight: 'bold',
  color: '#8c7d78',
  background: 'linear-gradient(#ffdf2d 0%, #e59a16 100%)',
  backgroundClip: 'text',
  textFillColor: 'transparent',
}))

const Logo = (
  <LogoStyle
    style={{
      display: 'flex',
      flexDirection: 'column',
      height: '130px',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <img src={asset_channel.common.main_logo} alt="" style={{ width: '60%' }} />
  </LogoStyle>
)

const SigninComponent: React.FC = () => {
  const intl = useIntl()
  const { globalStore, authStore } = useRootStore()
  const [captcha, setCaptcha] = useState<Schema$Captcha | null>(null)

  const { initialized } = authStore
  const { option } = globalStore // watch media_mode to get event
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const fetchCaptcha = async () => {
    try {
      const { captcha: c } = await apiPublic.getCaptcha({ type: 'signin' })
      if (c) {
        setCaptcha(c)
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (option && option.useLoginCaptcha) {
      fetchCaptcha()
    }
  }, [option])

  const onClickRefreshCaptcha = () => {
    fetchCaptcha()
  }

  const onClickJoin = () => {
    globalStore.showModal('login', 1)
  }

  const submit = async (values: any) => {
    const { username, password, captchaText } = values

    if (captcha) {
      if (!captchaText) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'input-captcha' }),
        })
        return
      }
    }

    let usernameVerified = username.trim()
    const succeed = await authStore.login(
      { username: usernameVerified, password, captchaText },
      intl,
    )
    if (!succeed) {
      fetchCaptcha()
    }
  }

  return (
    <>
      <Grid container sx={{ justifyContent: 'center' }}>
        <Grid item xs={9} sx={{ pt: 4, pb: 8 }}>
          {Logo}
          <Formik
            initialValues={{ username: '', password: '' }}
            onSubmit={submit}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
              password: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <FormStyle2>
                  <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                      <label className="form_label" style={{ marginBottom: '4px' }}>
                        아이디
                      </label>
                      <input
                        className="form_control"
                        type="text"
                        name="username"
                        placeholder={intl.formatMessage({ id: 'username' })}
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <label className="form_label" style={{ marginBottom: '4px' }}>
                        비밀번호
                      </label>
                      <input
                        className="form_control"
                        type="password"
                        name="password"
                        placeholder={intl.formatMessage({ id: 'password' })}
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        maxLength={16}
                      />
                    </Grid>
                  </Grid>
                  {captcha ? (
                    <>
                      <Grid container sx={{ justifyContent: 'center', mt: 3 }}>
                        <Grid item xs={10} sx={{ justifyContent: 'center', background: 'white' }}>
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <div
                              dangerouslySetInnerHTML={{ __html: captcha ? captcha.data : '' }}
                            ></div>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ height: '100%' }}
                          >
                            <IconButton
                              size="large"
                              onClick={onClickRefreshCaptcha}
                              aria-label="close"
                              sx={{
                                borderRadius: 0,
                                background: 'linear-gradient(#df5d39 0%, #aa3821 100%)',
                                width: '100%',
                                height: '100%',
                              }}
                            >
                              <RefreshIcon sx={{ color: '#fff' }} />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sx={SecurityText}>
                          <input
                            id="captchaInput"
                            className="form_control"
                            type="text"
                            name="captchaText"
                            placeholder={intl.formatMessage({
                              id: captcha.isMath ? 'input-captcha-math' : 'input-captcha',
                            })}
                            value={values.captchaText}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={16}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </FormStyle2>
                <Box sx={{ mt: 3 }}>
                  <LoginButton type="submit" size="large" disabled={isSubmitting}>
                    {intl.formatMessage({ id: 'login' })}
                  </LoginButton>
                </Box>
              </form>
            )}
          </Formik>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 3 }}>
            <Typography component="span" sx={{ color: '#fff', fontSize: '1.2rem' }}>
              아직 회원이 아니신가요?{' '}
            </Typography>
            <JoinButton onClick={() => onClickJoin()} sx={{ ml: 2 }}>
              지금 바로 회원가입
            </JoinButton>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(SigninComponent)
