import * as _ from 'lodash'
import { v4 } from 'uuid'
import EventManager from '../../helpers/event-manager'
import PanelScene, { BetAction, BetDoneData, GameInfo } from '../scenes/panel'
import { IBetEvo, Schema$MatchupBetRecord } from '~/v2/protocol/game'
import { INotifyEvoResult, Schema$EvoResult } from '~/v2/protocol/live/live'
import OddsButton from '../components/odds-button'
import { Schema$Matchup, Schema$MatchupOdds, Schema$TradingOddsOption } from '~/v2/interface/matchup'
import { MinigameMatchup } from '~/game/common'
import { toJS } from 'mobx'

export interface OddsEventData {
  oddsOptions: Schema$TradingOddsOption[]
}

export interface InitEventData {
  matchup: Schema$Matchup
}

export interface BetEventData {
  matchup: Schema$Matchup
}

export interface ResultEventData {
  matchup: Schema$Matchup
  result: Schema$EvoResult
}

export interface BetDoneEventData {
  betDoneData: BetDoneData
}

export interface BetRecordsEventData {
  tradingId: number
  items: Schema$MatchupBetRecord[]
}

export default class EntryPanelScene extends PanelScene {
  public preload() {
    super.preload()
  }

  public create() {
    super.create()

    this.clear()
    this.setEnabled(false)

    EventManager.getInstance().on('evo:odds', (data: OddsEventData) => {
      console.log('### evo:odds')
      const { oddsOptions } = data || {}
      this.updateOdds('evo:odds', oddsOptions)
    })

    EventManager.getInstance().on('evo:init', (data: InitEventData) => {
      console.log('### evo:init')
      const { matchup } = data || {}
      this.updateMatchup('evo:init', matchup)
    })

    EventManager.getInstance().on('evo:bet:start', (data: BetEventData) => {
      console.log('### evo:bet:start')
      const { matchup } = data || {}
      this.clear()
      this.updateMatchup('evo:bet:start', matchup)
    })

    EventManager.getInstance().on('evo:bet:end', (data: BetEventData) => {
      console.log('### evo:bet:end')
      const { matchup } = data || {}
      this.updateMatchup('evo:bet:end', matchup)
    })

    EventManager.getInstance().on('evo:result', (data: ResultEventData) => {
      console.log('### evo:result')
      const { matchup, result } = data || {}
      this.updateMatchup('evo:result', matchup)

      for (const [k, v] of Object.entries(result.oddsResult)) {
        const found = _.find(this.oddsComponent.buttons, (o: OddsButton) => {
          return o.attributes.id === k
        })
        if (found) {
          found.setHighlight(v)
        }
      }
    })

    EventManager.getInstance().on('evo:bet', (data: BetDoneEventData) => {
      console.log(`### evo:bet`)
      const { betDoneData } = data
      this.betDone(betDoneData)
    })

    EventManager.getInstance().on('evo:records', (data: BetRecordsEventData) => {
      console.log(`### evo:records`)
      const { tradingId, items } = data
      this.recoverBet(tradingId, items)
    })

    console.log(`####### panel initialized!`)
  }

  updateMatchup(eventId: string, source: Schema$Matchup) {
    if (source) {
      const now = new Date()
      const from = new Date(source.bet_start_time)
      const to = new Date(source.bet_end_time)
      const timeEnabled = from.getTime() <= now.getTime() && now.getTime() <= to.getTime()
      const betEnabled = eventId === 'evo:bet:start' || (eventId === 'evo:init' && timeEnabled)
      // console.log(`$$$ time enabled: ${timeEnabled}`)
      // console.log(`$$$ bet enabled: ${betEnabled}`)
      // console.log(`from: ${from.toString()}`)
      // console.log(`now: ${now.toString()}`)
      // console.log(`to: ${to.toString()}`)
      this.tweenEnabled(betEnabled)

      const tradingId = source.id
      const dateRound = source.minigame.round
      const origin = source.minigame.origin
      const roundId = source.minigame.roundId
      const destTime = new Date(source.bet_end_time)
      const duration = 40000

      this.updateGameInfo({ tradingId, dateRound, origin, roundId } as GameInfo)

      this.infoComponent.setDateRound(dateRound)
      if (betEnabled) {
        this.infoComponent.runTimerBy(v4(), destTime.getTime(), duration)
      }
    } else {
      this.setEnabled(false)
      this.infoComponent.setWaiting()
    }
  }

  updateOdds(eventId: string, oddsOptions: Schema$TradingOddsOption[]) {
    // update odds rate
    const filterd = _.filter(oddsOptions, (o: Schema$TradingOddsOption) => {
      return o.trading_group === 'EVOPB'
    })

    for (const el of this.oddsComponent.buttons) {
      const odds = _.find(filterd, (o: Schema$TradingOddsOption) => {
        return o.odds === el.attributes.id
      })
      if (odds) {
        el.confirmRate(odds.odds_rate)
      }
    }
  }

  recoverBet(tradingId: number, items: Schema$MatchupBetRecord[]) {
    if (!this.gameInfo) {
      return
    }

    if (tradingId !== this.gameInfo.tradingId) {
      return
    }

    this.clearBet()

    for (const el of items) {
      const [fold] = el.folds
      if (fold) {
        const targetButton = _.find(this.oddsComponent.buttons, (o: OddsButton) => {
          return o.attributes.id === fold.oddsId
        })
        if (targetButton) {
          const action: BetAction = {
            actionKey: v4(),
            data: [
              {
                marketId: fold.marketId,
                oddsId: fold.oddsId,
                oddsRate: fold.oddsRate,
                volume: el.betMoney,
              },
            ],
          }
          this.betActions.push(action)
          targetButton.updateBet(el.betMoney)
        }
      }
    }

    if (items.length > 0) {
      this.betDone({ tradingId })
    }
  }
}
