import { jsx, css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import { numberWithCommas } from '~/utils/utils'

interface Props {
  volume: number
}

let runningTarget = 0

const JackpotCounter = ({ volume }: Props) => {
  const counterRef = useRef(null)
  const [target, setTarget] = useState(-1)

  if (volume !== target) {
    runningTarget = volume
    setTarget(volume)
  }

  useEffect(() => {
    const canvas = counterRef.current
    canvas.height = 92

    const ctx = canvas.getContext('2d')

    let text = numberWithCommas(target).split('') // The message displayed
    // let text = '865,434,000'.split('') // The message displayed
    let chars = '1234567890,'.split('') // All possible Charactrers
    let scale = 60 // Font size and overall scale
    let breaks = 0.05 // Speed loss per frame
    let endSpeed = 0.01 // Speed at which the letter stops
    let firstLetter = 30 // Number of frames untill the first letter stopps (60 frames per second)
    let delay = 8 // Number of frames between letters stopping

    canvas.width = (scale / 1.5) * text.length + 5
    //canvas.width = 605
    // console.log('scale : ', scale)
    // console.log('text : ', text.length)
    

    let charMap = []
    let offset = []
    let offsetV = []

    for (var i = 0; i < chars.length; i++) {
      charMap[chars[i]] = i
    }

    for (var i = 0; i < text.length; i++) {
      var f = firstLetter + delay * i
      offsetV[i] = endSpeed + breaks * f
      offset[i] = ((1 + f) * (breaks * f + 2 * endSpeed)) / 2
    }

    const loop = () => {
      ctx.setTransform(1, 0, 0, 1, 0, 0)
      ctx.clearRect(0, 0, canvas.width, canvas.height)

      for (var i = 0; i < text.length; i++) {
        // text shadow
        ctx.shadowColor = '#111'
        ctx.shadowOffsetX = 3
        ctx.shadowOffsetY = 3
        ctx.shadowBlur = 4
        const gradient = ctx.createLinearGradient(0, -30, 0, 64)
        gradient.addColorStop(0, '#ffffff')
        gradient.addColorStop(0.3, '#ffd00e')
        gradient.addColorStop(0.6, '#d3922b')
        gradient.addColorStop(1, '#fafa0d')
        ctx.fillStyle = gradient
        ctx.textBaseline = 'middle'
        ctx.textAlign = 'center'
        ctx.setTransform(1, 0, 0, 1, 20, Math.floor(canvas.height / 2))
        var o = offset[i]
        while (o < 0) o++
        o %= 1
        var h = Math.ceil(canvas.height / 2 / scale)
        for (var j = -h; j < h; j++) {
          var c = charMap[text[i]] + j - Math.floor(offset[i])
          while (c < 0) c += chars.length
          c %= chars.length
          var s = 1 - Math.abs(j + o) / (canvas.height / 2 / scale + 1)
          ctx.globalAlpha = s
          ctx.font = scale * s + 'px Gmarket'
          ctx.fillText(chars[c], (scale * i) / 1.5, (j + o) * scale)
        }
        offset[i] -= offsetV[i]
        offsetV[i] -= breaks
        if (offsetV[i] < endSpeed) {
          offset[i] = 0
          offsetV[i] = 0
        }
      }

      if (runningTarget === target) {
        requestAnimationFrame(loop)
      }
    }
    requestAnimationFrame(loop)
  }, [target])

  return (
    <>
      <canvas ref={counterRef}></canvas>
    </>
  )
}

export default JackpotCounter
