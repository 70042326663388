/** @jsxImportSource @emotion/react */
import { jsx, ThemeProvider, Global } from '@emotion/react'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import cookies from 'react-cookies'

function Landing(props) {
  const history = useHistory()

  // localhost/landing/search?rcode=test01
  const { location } = props as any
  const query = queryString.parse(location.search)
  const { rcode } = query

  useEffect(() => {
    if (rcode) {
      const now = new Date()
      const expires = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
      cookies.save('rcode', rcode, {
        path: '/',
        expires: expires,
      })
    }
    history.push('/')
  }, [])

  return <></>
}

export default Landing
