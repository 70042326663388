/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect } from 'react'
import * as _ from 'lodash'
import { Grid, Box, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import { imgLoading } from '@assets/images/layout_1'
import useRootStore from '@store/useRootStore'
import { NeatTextField } from '../views/Partner/PartnerIntergratedMember/index.component'
import apiPublic from '@services/api/public'
import { sleep } from '~/utils/utils'
import { makeBrowserData } from '~/game/util'
import { useIntl } from 'react-intl'

const InputStyle = styled(NeatTextField)(({ theme }) => ({
  marginTop: 55,
  width: '100%',
  '& textarea': {
    color: theme.colors.listTitle,
    textAlign: 'center',
    fontSize: '16px',
  },
  '& fieldset': {
    border: 'none',
  },
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

function ProxySignIn(props) {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore } = useRootStore()

  // localhost/v5/signup/aksjfierni
  const { location } = props as any
  const query = queryString.parse(location.search)
  const { key: data } = query

  const fetch = async () => {
    try {
      if (_.isString(data)) {
        await apiPublic.signinV6({ data, browserData: makeBrowserData() })
        history.push('/')
      } else {
        globalStore.pushDialogOk({ text: intl.formatMessage({ id: 'msg.invalid_parameter' }) })
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
      history.push('/')
    }
  }

  useEffect(() => {
    fetch()
  }, [])

  let txtInfo = `잠시만 기다려 주세요.`

  return (
    <Box sx={{ background: 'black' }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: 700, m: 'auto', height: '100vh' }}
      >
        <Grid item xs={10} sx={{ mt: '-40px', textAlign: 'center' }}>
          {/* <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} /> */}
          <img src={imgLoading} alt="loading" style={{ maxWidth: '100%' }} />
          <InputStyle multiline value={txtInfo} InputProps={{ readOnly: true }}></InputStyle>
        </Grid>
      </Grid>
    </Box>
  )
}

export default observer(ProxySignIn)
