import { css, Theme, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import asset_channel from '@assets/channel'

const topHeaderHeight = 38
const secondaryHeaderHeight = 60
// header
export const TopHeaderStyle = (theme: Theme) => css`
  &.top_header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;
    min-width: 1248px;
    width: 100%;
    height: ${topHeaderHeight}px;
    background: #131313;

    .MuiIconButton-root {
      padding: 4.25px;
    }
    .MuiIconButton-root svg {
      width: 24px;
      height: 24px;
      transition: all 0.25s;
      fill: #73737B;
    }
    .MuiIconButton-root:hover svg {
      fill: #FBC342;
    }
    .MuiIconButton-root.active svg {
      animation: ${twinkle} 0.5s ease-in infinite;
    }

    .btn_myinfo > img {
      width: 25px;
    }
    .btn_myinfo .nickname {
      margin-left: 8px;
      color: #ffffff;
      font-size: 0.9rem;
      text-transform: none;
    }
    .btn_myinfo:hover .nickname {
      color: ${theme.colors.themeActive};
    }
    .btn_myinfo.active .nickname {
      color: ${theme.colors.themeActive};
    }

    .group_money {
      & .MuiButtonGroup-grouped:not(:last-of-type) {
        border-right: none;
      }
      & .history {
        background-color: #3b4c6e;
        &:hover {
          background-color: #2e3a4d;
        }
      }
      & .deposit {
        background-color: #289caf;
        &:hover {
          background-color: #177d8e;
        }
      }
      & .withdraw {
        background-color: #c92e2e;
        &:hover {
          background-color: #ac2626;
        }
      }
    }
  }
`
export const twinkle = keyframes`
  0% {
    fill: #73737B;
  }
  50% {
    fill: #FBC342;
  }
  100% {
    fill: #73737B;
  }
`

export const MainHeaderStyle = (theme: Theme) => css`
  &.secondary_header {
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 2;
    top: ${topHeaderHeight}px;
    min-width: 1248px;
    width: 100%;
    height: ${secondaryHeaderHeight}px;
    border-bottom: 1px solid ${theme.colors.themeActive};
    background: ${theme.colors.headerBg};
  }

  .dimmed_layer {
    position: absolute;
    top: ${secondaryHeaderHeight}px;
    left: 0;
    width: 100%;
    background: #080f1c;
  }

  .dimmed_layer.open {
    height: 200px;
    transition: height 0.15s ease-in;
  }

  .dimmed_layer.close {
    height: 0;
  }

  .main_menu_wrap {
    position: static;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }  

  & ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  .main_menu {
    display: flex;
    justify-content: space-around;
    width: 40%;
    height: ${secondaryHeaderHeight}px;
  }

  .main_menu > li {
    position: relative;
    //margin: 0 10px;
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${secondaryHeaderHeight}px;
    color: #b2b2b2;
    font-size: 1.2rem;
    transition: all 0.3s linear;
    cursor: pointer;
  }

  .main_menu > li:hover .nav-link,
  .nav-link.active {
    color: ${theme.colors.themeActive};
  }

  .main_menu > li:before {
    position: absolute;
    bottom: -62px;
    left: 50%;
    margin-left: -85px;
    display: none;
    width: 170px;
    height: 62px;
    background: url('${asset_channel.common.bg_submenu_on}') top center no-repeat;
    background-size: 100% auto;
    content: '';
  }

  &.secondary_header.open .main_menu > li:hover:before {
    display: block;
  }

  &.secondary_header .sub_menu {
    position: relative;
    z-indenx: 1;
    overflow: hidden;
  }

  &.secondary_header.open .sub_menu {
    height: 230px;
    transition: height 0.15s ease-in;
  }

  &.secondary_header.close .sub_menu {
    height: 0;
    //display: none;
  }

  .sub_menu_link {
    padding: 10px 0;
    color: #d1d9e3;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s linear;

    &:hover {
      color: ${theme.colors.themeActive};
  }
`

export const LogoStyle = styled.div`
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 1;
  width: 180px;
  height: 100%;
  margin-left: -89px;
  text-align: center;
  & img {
    height: 100%;
  }
`
