import { jsx, css, ThemeProvider, Theme, Global } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import {
  Container,
  Box,
  Tabs,
  Tab,
  Grid,
  Stack,
  MenuItem,
  TextField,
  Button,
  Pagination,
  Divider,
  Typography,
} from '@mui/material'
import { GridSortModel, GridSortItem } from '@mui/x-data-grid'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'

import WhiteTheme from '@themes/white.theme'
import {
  GlobalStyle,
  a11yProps,
  CustomLoadingOverlay,
  CustomNoRowsOverlay,
  PageStyle,
  StyledDataGrid,
} from '@shared/Common'
import { numberWithCommas, popupPartnerMember } from '@utils/utils'
import useRootStore from '@store/useRootStore'
import { default as apiUser } from '@services/api/user'
import { popupCenter } from '@utils/utils'
import { IMoneyTransferRecord, Schema$MoneyTransferRecordTrackData } from '@protocol/bank'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { MERCHANT_CLASS, USER_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { getColumns } from './index.component'

import { SOCKET_SERVICE_STATE } from '~/store/socketStore'

export const ItemPerPage = 10

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: Schema$MoneyTransferRecordTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

function MoneyTransferRecord({ location }) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { page } = query
  const startPage = page ? Number(page) : 1

  const history = useHistory()

  const [trackPage, setTrackPage] = useState(1)
  const [track, setTrack] = useState<IMoneyTransferRecord.Schema | null>(null)
  const [loading, setLoading] = useState(false)

  const { globalStore, authStore, userStore } = useRootStore()
  const { userInfo } = userStore

  const { uuid } = userInfo

  if (startPage !== trackPage) {
    setTrackPage(startPage)
  }

  const fetch = async (params: IMoneyTransferRecord.Params) => {
    setLoading(true)

    try {
      const ret = await apiUser.getMoneyTransferRecord(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }
  useEffect(() => {
    const params: IMoneyTransferRecord.Params = {
      offset: 0,
      limit: ItemPerPage,
    }

    fetch(params)
  }, [])

  useEffect(() => {
    const params: IMoneyTransferRecord.Params = {
      offset: (trackPage - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetch(params)
  }, [trackPage])

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/partner/money-transfer-record/search?page=${value}`)
  }

  const onClickCell = (uuid: string) => {
    if (uuid) {
      popupPartnerMember(uuid, '')
    }
  }

  let columns = getColumns()
  if (userInfo.merchant_class !== 1) {
    columns = _.filter(columns, o => {
      return o.field !== 'press'
    })
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const rows = []
  if (track) {
    const { hide_super_name } = track

    for (let i = 0; i < track.data.items.length; i++) {
      const el = track.data.items[i]
      const dateName = moment(el.date).format('YYYY-MM-DD HH:mm:ss')
      let reasonName = null
      let infoName = ''
      let amountContents = null
      const balanceName = numberWithCommas(el.balance)
      let info_uuid = ''
      let can_click_user = false
      switch (el.reason) {
        case 'MONEY_GIVE':
          reasonName = <Typography sx={{ color: 'red' }}>지급 ▶</Typography>
          infoName = el.passive_uuid ? `${el.passive_user_id} / ${el.passive_nickname}` : ''
          info_uuid = el.passive_uuid
          can_click_user = true
          amountContents = (
            <Typography sx={{ color: 'red' }}>-{numberWithCommas(el.amount)}</Typography>
          )
          break
        case 'MONEY_GIVEN':
          reasonName = <Typography sx={{ color: 'blue' }}>▶ 지급 받음</Typography>
          infoName = el.active_uuid ? `${el.active_user_id} / ${el.active_nickname}` : ''
          info_uuid = el.active_uuid
          amountContents = (
            <Typography sx={{ color: 'blue' }}>{numberWithCommas(el.amount)}</Typography>
          )
          break
        case 'MONEY_TAKE':
          reasonName = <Typography sx={{ color: 'blue' }}>회수 ◀</Typography>
          infoName = el.passive_uuid ? `${el.passive_user_id} / ${el.passive_nickname}` : ''
          info_uuid = el.passive_uuid
          can_click_user = true
          amountContents = (
            <Typography sx={{ color: 'blue' }}>{numberWithCommas(el.amount)}</Typography>
          )
          break
        case 'MONEY_TAKEN':
          reasonName = <Typography sx={{ color: 'red' }}>◀ 회수 당함</Typography>
          infoName = el.active_uuid ? `${el.active_user_id} / ${el.active_nickname}` : ''
          info_uuid = el.active_uuid
          amountContents = (
            <Typography sx={{ color: 'red' }}>-{numberWithCommas(el.amount)}</Typography>
          )
          break
      }

      const info = can_click_user ? (
        <Typography
          onClick={() => onClickCell(info_uuid)}
          sx={{
            textDecorationLine: 'underline',
            ':hover': {
              cursor: 'pointer',
            },
            fontWeight: 'bold',
            color: uuid === info_uuid ? 'blue' : 'black',
          }}
        >
          {infoName}
        </Typography>
      ) : (
        <Typography
          sx={{
            fontWeight: 'bold',
            color: uuid === info_uuid ? 'blue' : 'black',
          }}
        >
          {!hide_super_name ? infoName : '-'}
        </Typography>
      )

      const row = {
        id: i,
        uuid: info_uuid,
        time: <Typography>{dateName}</Typography>,
        reason: <Typography>{reasonName}</Typography>,
        info: info,
        inout_amount: amountContents,
        balance: <Typography>{balanceName}</Typography>,
      }
      rows.push(row)
    }
    pageData = getPageData(track.data)
  }

  const GlobalStyle = (theme: Theme) => css`
    html,
    body {
      min-width: 1200px;
    background: ${theme.colors.subBg};
    }
    .MuiDataGrid-columnHeaders {
      color: #ffffff !important;
      background-color: #007f93 !important;
    }
  `

  return (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container fixed style={{ minWidth: '1200px', minHeight: 'calc(100vh - 318px)' }}>
        <Grid container sx={{ marginTop: '10px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>머니 지급/회수 내역</Divider>
          </Grid>
        </Grid>
        <StyledDataGrid
          style={{ width: '100%', marginTop: '16px', minHeight: '400px' }}
          autoHeight
          rows={rows}
          columns={columns}
          rowHeight={42}
          headerHeight={42}
          disableColumnMenu
          sortingMode="server"
          // onSortModelChange={onSortChange}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
            NoRowsOverlay: CustomNoRowsOverlay,
            Footer: props => (
              <PageStyle
                count={pageData.totalPage}
                variant="outlined"
                page={pageData.currPage}
                onChange={handlePagingChange}
              />
            ),
          }}
          loading={loading}
        />
      </Container>
    </ThemeProvider>
  )
}

export default observer(MoneyTransferRecord)
