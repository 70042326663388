/** @jsxImportSource @emotion/react */
import { jsx, css, Global } from '@emotion/react'
import { useState, useEffect } from 'react'
import { Grid, Box, Typography, Button, IconButton, Select } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik, useFormik } from 'formik'
import * as Yup from 'yup'
import cookies from 'react-cookies'
import RefreshIcon from '@mui/icons-material/Refresh'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { MYPAGE_TAB_VALUES, GetFilteredBankCode } from '@constants/attrs'
import { MOBILE_CARRIERS, GENDERS } from '@v2/interface/config'

import apiAuth from '@services/api/auth'
import apiPublic from '@services/api/public'

//css
import { FormStyleBand } from '@styles/modalBand.style'
import { BasicButton } from '@styles/button.style'
import { Schema$Captcha } from '~/v2/protocol/public'

const BANK_CODES = GetFilteredBankCode()

const APP_TITLE = process.env.REACT_APP_TITLE || 'Unknown'
const DISABLE_RCODE_VIEW: boolean = process.env.REACT_APP_DISABLE_RCODE_VIEW === 'true' || false

const PHONE_CERTIFY_STEP = {
  NONE: 0,
  REQUIRE_CODE: 1,
  REQUIRE_CERTIFY: 2,
  CERTIFIED: 3,
}

const JoinButton = styled(Button)(({ theme }) => ({
  padding: '0.795rem 0.75rem',
  width: '100%',
  borderRadius: 0,
  backgroundColor: theme.colors.themeActive,
  opacity: 0.7,
  color: '#fff',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: theme.colors.themeActive,
    opacity: 1,
    color: '#fff',
    textShadow: '0px 0px 5px white',
  },
}))

const SignupComponentBand: React.FC = () => {
  const intl = useIntl()
  const [phoneCertifyStep, setPhoneCertifyStep] = useState(PHONE_CERTIFY_STEP.NONE)
  const [captcha, setCaptcha] = useState<Schema$Captcha | null>(null)
  const { globalStore, authStore } = useRootStore()
  const { signedin } = authStore
  const { option } = globalStore
  const { usePhoneCertify, includeMobileCarrierInSignUp, includeBirthdayInSignUp } = option || {}
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })

  const defaultReferer = cookies.load('rcode') || ''

  let HIDE_RCODE_VIEW: boolean = false
  if (defaultReferer !== '' && DISABLE_RCODE_VIEW) {
    HIDE_RCODE_VIEW = true
  }

  if (signedin) {
    globalStore.hideModal('signup')
  }

  const fetchCaptcha = async () => {
    try {
      const { captcha: c } = await apiPublic.getCaptcha({ type: 'signup' })
      if (c) {
        setCaptcha(c)
      }
    } catch (err) {}
  }

  useEffect(() => {
    if (usePhoneCertify) {
      setPhoneCertifyStep(PHONE_CERTIFY_STEP.REQUIRE_CODE)
    }
  }, [])

  useEffect(() => {
    if (option && option.useJoinCaptcha) {
      fetchCaptcha()
    }
  }, [option])

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      password2: '',
      nickname: '',
      bankCode: '',
      accountHolder: '',
      accountNumber: '',
      transferPassword: '',
      phoneNumber: '',
      certifyCode: '',
      captchaText: '',
      referer: defaultReferer,
      mobileCarrier: '',
      birthday: '',
      gender: '',
    },
    onSubmit: values => {
      submit(values)
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      password: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      password2: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      nickname: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      accountHolder: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      accountNumber: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      transferPassword: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      phoneNumber: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      referer: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
      mobileCarrier: Yup.lazy(value =>
        includeMobileCarrierInSignUp
          ? Yup.string().required(intl.formatMessage({ id: 'msg.field-require' }))
          : Yup.string(),
      ),
      birthday: Yup.lazy(value =>
        includeBirthdayInSignUp
          ? Yup.string().required(intl.formatMessage({ id: 'msg.field-require' }))
          : Yup.string(),
      ),
      gender: Yup.lazy(value =>
        includeBirthdayInSignUp
          ? Yup.string().required(intl.formatMessage({ id: 'msg.field-require' }))
          : Yup.string(),
      ),
    }),
  })

  const onClickGetPhoneCertifyCode = () => {
    async function inline() {
      try {
        const username = formik.getFieldProps('username')
        const phoneNumber = formik.getFieldProps('phoneNumber')
        if (username.value.length === 0) {
          globalStore.pushDialogOk({
            text: intl.formatMessage({ id: 'msg.required.field-id' }),
          })
          return
        }
        if (phoneNumber.value.length === 0) {
          globalStore.pushDialogOk({
            text: intl.formatMessage({ id: 'msg.input.mobile_number' }),
          })
          return
        }
        await apiAuth.postPhoneCertifyCode({ user_id: username.value, phone: phoneNumber.value })

        setPhoneCertifyStep(PHONE_CERTIFY_STEP.REQUIRE_CERTIFY)

        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.verification-phone-requested' }),
        })
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }
    }
    inline()
  }

  const onClickCertifyCode = () => {
    async function inline() {
      try {
        const username = formik.getFieldProps('username')
        const phoneNumber = formik.getFieldProps('phoneNumber')
        const certifyCode = formik.getFieldProps('certifyCode')
        if (username.value.length === 0) {
          globalStore.pushDialogOk({
            text: intl.formatMessage({ id: 'msg.required.field-id' }),
          })
          return
        }
        if (phoneNumber.value.length === 0) {
          globalStore.pushDialogOk({
            text: intl.formatMessage({ id: 'msg.required.field-phone' }),
          })
          return
        }
        if (certifyCode.value.length === 0) {
          globalStore.pushDialogOk({
            text: intl.formatMessage({ id: 'msg.required.verification-phone' }),
          })
          return
        }

        await apiAuth.phoneCertifyCode({
          user_id: username.value,
          phone: phoneNumber.value,
          code: certifyCode.value,
        })

        setPhoneCertifyStep(PHONE_CERTIFY_STEP.CERTIFIED)
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }
    }
    inline()
  }

  const onChangePhoneNumber = (
    e: React.ChangeEvent<any>,
    onChange: (e: React.ChangeEvent<any>) => void,
  ) => {
    onChange(e)
    formik.setFieldValue('certifyCode', '')
    if (phoneCertifyStep !== PHONE_CERTIFY_STEP.NONE) {
      setPhoneCertifyStep(PHONE_CERTIFY_STEP.REQUIRE_CODE)
    }
  }

  const onChangeCertifyCode = (
    e: React.ChangeEvent<any>,
    onChange: (e: React.ChangeEvent<any>) => void,
  ) => {
    onChange(e)
    // if (e.target.value.length > 0) {
    //   if (phoneCertifyStep === PHONE_CERTIFY_STEP.REQUIRE_CODE) {
    //     setPhoneCertifyStep(PHONE_CERTIFY_STEP.REQUIRE_CERTIFY)
    //   }
    // } else {
    //   setPhoneCertifyStep(PHONE_CERTIFY_STEP.REQUIRE_CODE)
    // }
  }

  const onClickRefreshCaptcha = () => {
    fetchCaptcha()
  }

  const submit = async (values: any) => {
    const {
      username,
      password,
      password2,
      nickname,
      bankCode,
      accountHolder,
      accountNumber,
      transferPassword,
      phoneNumber,
      certifyCode,
      referer,
      captchaText,
      mobileCarrier,
      birthday,
      gender,
    } = values
    formik.setSubmitting(false)
    if (password !== password2) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.required.confirm-password' }),
      })
      return
    }

    if (
      phoneCertifyStep !== PHONE_CERTIFY_STEP.NONE &&
      phoneCertifyStep !== PHONE_CERTIFY_STEP.CERTIFIED
    ) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.identify-verification' }),
      })

      return
    }

    if (captcha) {
      if (!captchaText) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.enter-security-code' }),
        })
        return
      }
    }

    try {
      await authStore.signup(
        {
          username: username.trim(),
          password: password.trim(),
          nickname: nickname.trim(),
          bankCode,
          accountHolder: accountHolder.trim(),
          accountNumber: accountNumber.trim(),
          transferPassword: transferPassword.trim(),
          phoneNumber,
          captchaText,
          refererUserId: referer.trim(),
          mobileCarrier,
          birthday: birthday.trim(),
          gender,
        },
        intl,
      )
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const bankContents = []
  for (const el of BANK_CODES) {
    bankContents.push(
      <option value={`${el.code}`} key={el.code}>
        {el.name}
      </option>,
    )
  }

  const mobileCarrierContents = []
  for (const el of MOBILE_CARRIERS) {
    mobileCarrierContents.push(
      <option value={`${el.code}`} key={el.code}>
        {el.name}
      </option>,
    )
  }

  const genderContents = []
  for (const el of GENDERS) {
    genderContents.push(
      <option value={`${el.code}`} key={el.code}>
        {el.name}
      </option>,
    )
  }

  let phoneCertifyContents = null
  switch (phoneCertifyStep) {
    case PHONE_CERTIFY_STEP.REQUIRE_CODE:
      phoneCertifyContents = (
        <Button
          variant="contained"
          sx={{ width: '100%', height: '100%', fontSize: '13px' }}
          onClick={() => onClickGetPhoneCertifyCode()}
        >
          인증번호 받기
        </Button>
      )
      break
    case PHONE_CERTIFY_STEP.REQUIRE_CERTIFY:
      phoneCertifyContents = (
        <Button
          variant="contained"
          color="warning"
          sx={{ width: '100%', height: '100%', fontSize: '13px' }}
          onClick={() => onClickCertifyCode()}
        >
          인증하기
        </Button>
      )
      break
    case PHONE_CERTIFY_STEP.CERTIFIED:
      phoneCertifyContents = (
        <Button
          variant="contained"
          color="success"
          sx={{ width: '100%', height: '100%', fontSize: '13px' }}
        >
          인증완료
        </Button>
      )
      break
  }

  return (
    <>
      <Grid
        container
        sx={{
          justifyContent: 'center',
          height: '100%',
          overflow: 'auto',
          maxHeight: isDesktop ? 'calc(100vh - 140px)' : 'none',
        }}
      >
        <Grid item xs={9} sx={{ pb: 8 }}>
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <FormStyleBand>
              <Grid container rowSpacing={2} sx={{ mt: 3 }}>
                <Grid item xs={12}>
                  <label className="form_label" style={{ marginBottom: '4px' }}>
                    아이디
                  </label>
                  <input
                    className="form_control"
                    type="text"
                    name="username"
                    placeholder={intl.formatMessage({ id: 'signup.field-id' })}
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={16}
                  />
                  {formik.touched.username && formik.errors.username && (
                    <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                      {formik.errors.username}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label className="form_label" style={{ marginBottom: '4px' }}>
                    비밀번호
                  </label>
                  <input
                    className="form_control"
                    type="password"
                    name="password"
                    placeholder={intl.formatMessage({ id: 'signup.field-password' })}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    minLength={6}
                    maxLength={16}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                      {formik.errors.password}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <input
                    className="form_control"
                    type="password"
                    name="password2"
                    placeholder={intl.formatMessage({ id: 'signup.field-password2' })}
                    value={formik.values.password2}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    minLength={6}
                    maxLength={16}
                  />
                  {formik.touched.password2 && formik.errors.password2 && (
                    <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                      {formik.errors.password2}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label className="form_label" style={{ marginBottom: '4px' }}>
                    닉네임
                  </label>
                  <input
                    className="form_control"
                    type="text"
                    name="nickname"
                    placeholder={intl.formatMessage({ id: 'signup.field-nickname' })}
                    value={formik.values.nickname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={8}
                  />
                  {formik.touched.nickname && formik.errors.nickname && (
                    <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                      {formik.errors.nickname}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label className="form_label" style={{ marginBottom: '4px' }}>
                    은행
                  </label>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={7}>
                      <select
                        value={formik.values.bankCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="bankCode"
                        className="form_control"
                      >
                        <option value="" disabled>
                          은행을 선택해주세요
                        </option>
                        {bankContents}
                      </select>
                    </Grid>
                    <Grid item xs={5}>
                      <input
                        className="form_control"
                        type="text"
                        name="accountHolder"
                        placeholder={intl.formatMessage({ id: 'signup.field-name' })}
                        value={formik.values.accountHolder}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={32}
                      />

                      {formik.touched.accountHolder && formik.errors.accountHolder && (
                        <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                          {formik.errors.accountHolder}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <input
                        className="form_control"
                        type="text"
                        name="accountNumber"
                        placeholder={intl.formatMessage({ id: 'signup.field-account' })}
                        value={formik.values.accountNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={32}
                      />
                      {formik.touched.accountNumber && formik.errors.accountNumber && (
                        <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                          {formik.errors.accountNumber}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <label className="form_label" style={{ marginBottom: '4px' }}>
                    환전 비밀번호
                  </label>
                  <input
                    className="form_control"
                    type="password"
                    name="transferPassword"
                    placeholder={intl.formatMessage({ id: 'signup.field-password' })}
                    value={formik.values.transferPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    maxLength={16}
                  />
                  {formik.touched.transferPassword && formik.errors.transferPassword && (
                    <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                      {formik.errors.transferPassword}
                      <br />
                      환전시 반드시 필요한 비밀번호 입니다.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <label className="form_label" style={{ marginBottom: '4px' }}>
                    휴대폰 번호
                  </label>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={includeMobileCarrierInSignUp ? 7 : 12} sx={{ mb: 1 }}>
                      <input
                        className="form_control"
                        type="text"
                        name="phoneNumber"
                        placeholder={intl.formatMessage({ id: 'signup.field-phonenumber' })}
                        value={formik.values.phoneNumber}
                        onChange={e => onChangePhoneNumber(e, formik.handleChange)}
                        onBlur={formik.handleBlur}
                        maxLength={11}
                        disabled={phoneCertifyStep === PHONE_CERTIFY_STEP.CERTIFIED}
                      />
                      {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                        <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                          {formik.errors.phoneNumber}
                        </Typography>
                      )}
                    </Grid>
                    {includeMobileCarrierInSignUp ? (
                      <Grid item xs={5} sx={{ mb: 1 }}>
                        <select
                          value={formik.values.mobileCarrier}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="mobileCarrier"
                          className="form_control"
                        >
                          <option value="" disabled>
                            통신사
                          </option>
                          {mobileCarrierContents}
                        </select>
                        {formik.touched.mobileCarrier && formik.errors.mobileCarrier && (
                          <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                            {formik.errors.mobileCarrier}
                          </Typography>
                        )}
                      </Grid>
                    ) : null}
                    {phoneCertifyStep >= PHONE_CERTIFY_STEP.REQUIRE_CODE ? (
                      <>
                        <Grid item xs={8} sx={{ mb: 1 }}>
                          <input
                            className="form_control"
                            type="text"
                            name="certifyCode"
                            placeholder="인증번호를 입력하세요."
                            value={formik.values.certifyCode}
                            onChange={e => onChangeCertifyCode(e, formik.handleChange)}
                            onBlur={formik.handleBlur}
                            maxLength={6}
                            disabled={phoneCertifyStep === PHONE_CERTIFY_STEP.CERTIFIED}
                          />
                          {formik.touched.certifyCode && formik.errors.certifyCode && (
                            <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                              {formik.errors.certifyCode}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={4} sx={{ mb: 1 }}>
                          {phoneCertifyContents}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
                {includeBirthdayInSignUp ? (
                  <Grid item xs={12}>
                    <label className="form_label" style={{ marginBottom: '4px' }}>
                      생년월일
                    </label>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={7}>
                        <input
                          className="form_control"
                          type="text"
                          name="birthday"
                          placeholder={intl.formatMessage({ id: 'signup.field-birthday' })}
                          value={formik.values.birthday}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          maxLength={8}
                        />
                        {formik.touched.birthday && formik.errors.birthday && (
                          <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                            {formik.errors.birthday}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        <select
                          className="form_control"
                          name="gender"
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option value="" disabled>
                            성별
                          </option>
                          {genderContents}
                        </select>
                        {formik.touched.gender && formik.errors.gender && (
                          <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                            {formik.errors.gender}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : null}

                {!HIDE_RCODE_VIEW ? (
                  <>
                    <Grid item xs={12}>
                      <label className="form_label" style={{ marginBottom: '4px' }}>
                        추천코드
                      </label>
                      <input
                        className="form_control"
                        type="text"
                        name="referer"
                        placeholder={intl.formatMessage({ id: 'signup.field-referer' })}
                        value={formik.values.referer}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={16}
                      />
                      {formik.touched.referer && formik.errors.referer && (
                        <Typography sx={{ mt: '4px', color: '#FE3D3D' }}>
                          {formik.errors.referer}
                        </Typography>
                      )}
                    </Grid>
                  </>
                ) : null}
              </Grid>
              {captcha ? (
                <>
                  <Grid container sx={{ justifyContent: 'center', mt: 3 }}>
                    <Grid item xs={10} sx={{ justifyContent: 'center', background: 'white' }}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <div
                          dangerouslySetInnerHTML={{ __html: captcha ? captcha.data : '' }}
                        ></div>
                      </Box>
                    </Grid>
                    <Grid item xs={2} sx={{ background: 'black' }}>
                      <Box display="flex" alignItems="center" justifyContent="center">
                        <IconButton
                          color="primary"
                          size="large"
                          onClick={onClickRefreshCaptcha}
                          aria-label="close"
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container rowSpacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                      <input
                        id="captchaInput"
                        className="form_control"
                        type="text"
                        name="captchaText"
                        placeholder={intl.formatMessage({
                          id: captcha.isMath ? 'input-captcha-math' : 'input-captcha',
                        })}
                        value={formik.values.captchaText}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        maxLength={16}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </FormStyleBand>
            <Box sx={{ display: 'flex', jsutifyContent: 'center', marginTop: 3 }}>
              <JoinButton type="submit" size="large" disabled={formik.isSubmitting}>
                {intl.formatMessage({ id: 'signup' })}
              </JoinButton>
            </Box>
          </form>
        </Grid>
      </Grid>
    </>
  )
}

export default SignupComponentBand
