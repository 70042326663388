import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const FooterStyle = styled('div')`
  background-color: ${props=>props.theme.footer.background};
  text-align: center;

  .MuiContainer-root {
    height: 100%;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 100%;
  }
  .image {
    padding: 12px 0;
    color: ${props=>props.theme.footer.innerColor};
  }
  .image > img {
    height: 100%;
  }
  .copyright {
    color: ${props=>props.theme.footer.copyright};
    text-transform: uppercase;
  }
`