/* eslint-disable jsx-a11y/iframe-has-title */
/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import * as _ from 'lodash'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import queryString from 'query-string'

import useRootStore from '@store/useRootStore'
import { getStateString, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { GlobalStore } from '@store/globalStore'
import { AuthStore } from '@store/authStore'
import { useMediaQuery } from 'react-responsive'
import { LOCALSTORAGE_KEYS, MEDIA_DESKTOP_WIDTH } from '@constants/index'

import PopupPowerballAlarm from '@views/Popup/PopupPowerballAlarm'
import PopupPowerballBettingGuide from '@views/Popup/PopupPowerballBettingGuide'
import { useHistory } from 'react-router-dom'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'

import DefaultMegahit from './d'
// import MobileMinigame from './m'

const Megahit: React.FC = props => {
  const { globalStore, liveStore, userStore, authStore } = useRootStore()

  const { initialized, signedin } = authStore
  const { serviceState } = liveStore

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useOnUnmount(() => {
    globalStore.setPowerballBettingGuideVisible(false)
  })

  if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
    userStore.setPositionPage(`MSL`)
  }

  return <DefaultMegahit></DefaultMegahit>
}

export default observer(Megahit)
