/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
//import { Offcanvas } from 'react-bootstrap'
// import { Card, Grid, Container, ListItem } from '@mui/material';
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { isString } from 'lodash'

import useRootStore from '@store/useRootStore'
import { routerStore } from '@store/index'

import PartnerSiderMenu from './PartnerSiderMenu'
import UserSiderMenu from './UserSiderMenu'
// import SiderMenu from './Menu'
import { RouteMenu } from './RouteMenu'

// css
import { OffcanvasStyle } from './index.style'

const Sider: React.FC<{}> = props => {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore, userStore } = useRootStore()
  const { siderVisible, partnerMode } = globalStore
  const { signedin } = authStore
  const { userInfo, userMoney } = userStore
  const { nickname, isPartner } = userInfo
  const { balance } = userMoney

  const partnerEnabled = signedin && partnerMode && (isPartner || true)

  const onClickRouteMenu = (el: RouteMenu) => {
    if (el.name === 'logout') {
      authStore.logout(intl)
    } else {
      if (isString(el.path) && el.path.length > 0) {
        history.push(el.path)
      } else if (isString(el.popup) && el.popup.length > 0) {
        globalStore.showModal(el.popup, el.popupIndex)
      }
    }
  }

  const siderProps = {
    ...props,
    signedin,
    onClick: onClickRouteMenu,
  }

  let headerContents
  if (signedin) {
    headerContents = (
      <div>닉네임 잔액</div>
      // <Offcanvas.Header>
      //   <Offcanvas.Title>
      //     <p className="nickname">{nickname}</p>
      //     <p className="account">{balance}</p>
      //   </Offcanvas.Title>
      // </Offcanvas.Header>
    )
  }

  let bodyContents
  if (partnerEnabled) {
    bodyContents = <PartnerSiderMenu {...siderProps}></PartnerSiderMenu>
  } else {
    bodyContents = <UserSiderMenu {...siderProps}></UserSiderMenu>
  }

  const onClose = () => {
    globalStore.toggleSider(false)
  }

  return (
    <></>
    // <div>닫기</div>
    // <Offcanvas show={siderVisible} onHide={onClose} css={OffcanvasStyle}>
    //   {headerContents}
    //   <Offcanvas.Body>{bodyContents}</Offcanvas.Body>
    // </Offcanvas>
  )
}

export default observer(Sider)
