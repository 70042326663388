/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { observer } from 'mobx-react'
import { useEffect, useState, useRef } from 'react'
import useRootStore from '~/store/useRootStore'
import ImplMarketDetail from './ImplMarketDetail'

function ContainerImplMarketDetail() {
  const { sportsStore } = useRootStore()
  const [fixtureId, setFixtureId] = useState<number>(0)
  const { selectedFixtureId, options } = sportsStore

  useEffect(() => {
    setFixtureId(0)
    setTimeout(() => {
      setFixtureId(selectedFixtureId)
    }, 100)
  }, [selectedFixtureId, options])

  let contents = null
  if (selectedFixtureId && fixtureId === selectedFixtureId) {
    contents = <ImplMarketDetail></ImplMarketDetail>
  }
  return contents
}

export default observer(ContainerImplMarketDetail)
