import React, { Fragment, useState } from 'react'
import { jsx, css, keyframes } from '@emotion/react'
import * as _ from 'lodash'
import {
  Grid,
  Box,
  AppBar,
  Button,
  List,
  ListSubheader,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import Badge from '@mui/material/Badge'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { ModalKeys } from '@store/globalStore'
import PartnerMenu from './PartnerMenu'
import { APP_CHARGES } from '~/utils/utils'

// css
import { styled } from '@mui/material/styles'

// icon
import RefreshIcon from '@mui/icons-material/Refresh'
import MailIcon from '@mui/icons-material/Mail'
import { ReactComponent as IconCoupon } from '@assets/images/layout_1/icon_coupon.svg'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import LogoutIcon from '@mui/icons-material/Logout'
import { ReactComponent as IconUser } from '@assets/images/layout_1/icon_user.svg'
import asset_channel from '@assets/channel'

// image
import { numberWithCommas } from '~/utils/utils'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import { propsToClassKey } from '@mui/styles'

export const twinkle = keyframes`
  0% {
    fill: #73737B;
  }
  50% {
    fill: #FBC342;
  }
  100% {
    fill: #73737B;
  }
`
const MobileHeaderStyle = styled('div')`
  .MuiAppBar-root {
    background: ${props => props.theme.colors.mobileHeaderBg};
    & .menu {
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .login {
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .btn_coupon {
      color: ${props => props.theme.colors.btnCoupon};
      & > svg {
        fill: ${props => props.theme.colors.btnCoupon};
      }
    }
    & .btn_partner {
      height: 100%;
      & > .text {
        display: inline-block;
        padding: 4px;
        height: 26px;
        background-color: #2366cb;
        border-radius: 4px;
        color: ${props => props.theme.colors.btnTxtLighten};
      }
    }
    & .btn_message {
      height: 100%;
      padding: 8px;
      & svg {
        fill: ${props => props.theme.colors.btnCoupon};
      }
      & .MuiBadge-badge {
        top: 5px;
        right: 2px;
        padding: 2px 6px;
        height: auto;
        line-height: 1.5;
      }
    }
    & .MuiIconButton-root.active > svg {
      animation: ${twinkle} 0.5s ease-in infinite;
    }
  }
  .MuiGrid-container {
    height: 48px;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
    height: 100%;

    & > img {
      height: 100%;
    }
  }
`

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    backgroundColor: theme.colors.drawerBg,
  },
}))

const GnbStyle = styled(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    backgroundColor: theme.colors.drawerSubBg,
    color: theme.colors.drawerMenuTxt,
  },
  '& .myinfo': {
    backgroundColor: theme.colors.drawerTopBg,
    color: theme.colors.drawerSubTxt,
    '& .login > .MuiButton-root': {
      color: theme.colors.drawerTxtLogin,
    },
    '& .nickname': {
      color: theme.colors.drawerTxtNick,
    },
    '& .money': {
      color: theme.colors.drawerTxtMoney,
    },
    '& .point': {
      color: theme.colors.drawerTxtPoint,
    },
  },
  '& .MuiListSubheader-root': {
    backgroundColor: theme.colors.drawerSubBg,
    color: theme.colors.drawerSubTxt,
    lineHeight: '32px',
  },
  '& .MuiListItemButton-root.outer': {
    padding: '6.5px 16px 5px',
    backgroundColor: theme.colors.drawerMenuBg,
    borderBottom: `1px solid ${theme.colors.drawerBorder}`,
  },
  '& .MuiListItemButton-root.outer.Mui-selected': {
    backgroundColor: theme.colors.drawerMenuBg,
    '& .MuiListItemText-root': {
      color: theme.colors.drawerTxtHover,
    },
    '& .MuiSvgIcon-root': {
      color: theme.colors.drawerTxtHover,
    },
  },
  '& .MuiListItemButton-root.nested': {
    padding: '0 16px',
  },
  '& .MuiListItemButton-root.outer.active, & .MuiListItemButton-root.nested.active .MuiListItemText-root .MuiTypography-root': {
    color: '#43C8DE',
  },
  '& .nested .MuiListItemText-root': {
    margin: 0,
    padding: '9.75px 0',
    borderBottom: `1px solid ${theme.colors.drawerBorder}`,
    '& .MuiTypography-root': {
      color: theme.colors.drawerSubTxt,
    },
  },
}))

function getLevelImage(level: number) {
  switch (level) {
    case 1:
      return asset_channel.common.icon_level01
    case 2:
      return asset_channel.common.icon_level02
    case 3:
      return asset_channel.common.icon_level03
    case 4:
      return asset_channel.common.icon_level04
    case 5:
      return asset_channel.common.icon_level05
    case 6:
      return asset_channel.common.icon_level06
    case 7:
      return asset_channel.common.icon_level07
    case 8:
      return asset_channel.common.icon_level08
    case 9:
      return asset_channel.common.icon_level09
    case 10:
      return asset_channel.common.icon_level10
  }
  return null
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let EXCLUDE_TOP_MENU = []
if (process.env.REACT_APP_EXCLUDE_TOP_MENU) {
  EXCLUDE_TOP_MENU = JSON.parse(process.env.REACT_APP_EXCLUDE_TOP_MENU) || []
}
const SHOW_GAME_MONEY = process.env.REACT_APP_SHOW_GAME_MONEY === 'true'

export interface HeaderProps {
  onClickNavPath: (path: string) => void
}

interface MobileHeaderProps {
  Header: React.ComponentType<HeaderProps>
}

export function Header(props: MobileHeaderProps) {
  const { Header } = props
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [listOpen, setListOpen] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const [refreshEnabled, setRefreshEnabled] = useState(null)

  const { partnerMode, option } = globalStore
  const { initialized, signedin } = authStore
  const { notification, userInfo, userMoney, userPoint, userComp, game_money } = userStore
  const { nickname, isPartner, user_level, comp_level, merchant_class } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { balance: balanceComp } = userComp

  const {
    open_trading_groups,
    open_trading_kinds,
    enableComp,
    enableCoupon,
    excludePartnerMenuClass,
    enableChangePassword,
  } = option || {}

  const contents_level = enableComp ? comp_level : user_level

  const couponActive = notification && notification.usableCouponCount > 0 ? 'active' : ''

  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    if (currentPath === path || currentPathSearch === path) {
      return 'active'
    } else {
      return ''
    }
  }

  function isEnabled(trading_group: string): boolean {
    let foundGroup = null
    let foundKind = null

    if (open_trading_groups) {
      foundGroup = _.find(open_trading_groups, o => {
        return o.trading_group === trading_group
      })
    }

    if (open_trading_kinds) {
      foundKind = _.find(open_trading_kinds, o => {
        return o.trading_group === trading_group
      })
    }

    return foundGroup && foundKind
  }

  const handleList = index => {
    //setListOpen(!listOpen);
    if (listOpen === index) {
      setListOpen('')
    } else {
      setListOpen(index)
    }
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onClickButton = (name: string) => {
    switch (name) {
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'game-money':
        const timer = setTimeout(() => {
          setRefreshEnabled(null)
        }, 5000)
        setRefreshEnabled(timer)
        userStore.loadGameMoney(intl)
        return
      case 'withdraw':
        globalStore.pushDialogYesNo({
          text: intl.formatMessage({ id: 'msg.ask.gamemoney-to-ownmoney' }),
          callbackPositive: async () => {
            const timer = setTimeout(() => {
              setRefreshEnabled(null)
            }, 5000)
            setRefreshEnabled(timer)
            userStore.withdrawGameMoney(intl)
          },
        })
        return
    }

    setOpen(false)
  }

  const onClickMenuItem = (id: string, arg: string | number) => {
    switch (id) {
      case 'live-casino':
        history.push('/live-casino')
        break
      case 'hotel-casino':
        history.push('/hotel-casino')
        break
      case 'slot':
        history.push('/slot')
        break
      case 'reel':
        history.push('/reel')
        break
      case 'minigame':
        if (arg) {
          history.push(`/minigame/search?main=${arg}`)
        } else {
          history.push('/minigame')
        }
        break
      case 'boardgame':
        history.push('/boardgame')
        break
      case 'msl':
        history.push('/msl')
        break
      case 'sports':
        history.push('/sports')
        break
      case 'bank':
        history.push(`/bank/search?main=${arg}`)
        break
      case 'notice':
        history.push(`/notice/search?main=${arg}`)
        break
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'myinfo':
        history.push(MYPAGE_TAB_VALUES.myinfo.url)
        break
      case 'qnaPersonal':
        history.push(MYPAGE_TAB_VALUES.qnaPersonal.url)
        break
      // case 'qnaPartner':
      //   history.push(MYPAGE_TAB_VALUES.qnaPartner.url)
      //   break
      case 'login':
        globalStore.showModal(ModalKeys.login, 0)
        break
      case 'join':
        globalStore.showModal(ModalKeys.login, 1)
        break
      case 'message':
        globalStore.showModal('message', 0)
        break
      case 'coupon':
        globalStore.showModal('coupon', 0)
        break
    }

    setOpen(false)
  }

  const onClickPartner = () => {
    if (channelSymbol === 'turtle' || channelSymbol === 'pacific') {
      history.push('/partner/members')
    } else {
      history.push('/partner')
    }
    setAnchorEl(null)
  }
  const onClickLogout = () => {
    history.push('/')
    authStore.logout(intl)
    setAnchorEl(null)
    setOpen(false)
  }

  const onClickLogo = () => {
    history.push('/')
    // window.location.reload()
    if (signedin) {
      userStore.loadAsset(intl)
    }
  }

  const showNotice = EXCLUDE_TOP_MENU.indexOf('NOTICE') < 0
  const showEvent = EXCLUDE_TOP_MENU.indexOf('EVENT') < 0
  const showPolicy = EXCLUDE_TOP_MENU.indexOf('POLICY') < 0
  const showQna = EXCLUDE_TOP_MENU.indexOf('QNA') < 0

  let rightContents = null
  let badgeContents = <MailIcon style={{ width: '25px' }} fontSize="large" />

  if (initialized) {
    if (signedin) {
      const showPartnerMenu = !excludePartnerMenuClass.includes(merchant_class.toString())
      if (notification) {
        const { unread } = notification
        if (unread > 0) {
          badgeContents = (
            <Badge
              badgeContent={unread}
              color="primary"
              sx={{
                marginTop: unread > 0 ? '10px' : '0px',
              }}
            >
              <MailIcon style={{ width: '25px' }} fontSize="large" />
            </Badge>
          )
        }
      }

      if (!badgeContents) {
        badgeContents = <MailIcon style={{ width: '25px' }} fontSize="large" />
      }

      rightContents = (
        <div style={{ height: '100%' }}>
          {/* <IconButton
            id="btn_level"
            aria-controls={anchorEl ? 'mobile_menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            onClick={handleMenu}
            sx={levelIconStyle}
          >
            <img src={getLevelImage(contents_level)} alt="" />
          </IconButton> */}
          {showPartnerMenu ? (
            <Button className="btn_partner" onClick={onClickPartner}>
              <span className="text">{intl.formatMessage({ id: 'component.partner' })}</span>
            </Button>
          ) : null}

          {/* <MobileMenuStyle
            id="mobile_menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'btn_level',
            }}
          >
            {showPartnerMenu ? (
              <MenuItem onClick={onClickPartner} sx={{ color: '#2A73E1' }}>
                {intl.formatMessage({ id: 'component.partner-management' })}
              </MenuItem>
            ) : null}
            <MenuItem onClick={onClickLogout} sx={{ color: '#FE3D3D' }}>
              {intl.formatMessage({ id: 'logout' })}
            </MenuItem>
          </MobileMenuStyle> */}
        </div>
      )
    } else {
      rightContents = (
        <>
          <Button
            className="login"
            onClick={() => onClickMenuItem('join', '0')}
            sx={{ minWidth: '68px' }}
          >
            <Typography>{intl.formatMessage({ id: 'signup' })}</Typography>
          </Button>
          <Button
            className="login"
            onClick={() => onClickMenuItem('login', '0')}
            sx={{ minWidth: '61px' }}
          >
            <Typography sx={{ paddingRight: '6px' }}>
              {intl.formatMessage({ id: 'signin' })}
            </Typography>
          </Button>
        </>
      )
    }
  }

  // Drawer----------
  const chargeContents = []
  for (let i = 0; i < APP_CHARGES.length; i += 1) {
    switch (APP_CHARGES[i]) {
      case 'deposit':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=deposit')}`}
            key="deposit"
            onClick={() => onClickMenuItem('bank', 'deposit')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.normal' })} />
          </ListItemButton>,
        )
        break
      case 'depositOnepay':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositOnepay')}`}
            key="depositOnepay"
            onClick={() => onClickMenuItem('bank', 'depositOnepay')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.onepay' })} />
          </ListItemButton>,
        )
        break
      case 'depositCoin':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositCoin')}`}
            key="depositCoin"
            onClick={() => onClickMenuItem('bank', 'depositCoin')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.coin' })} />
          </ListItemButton>,
        )
        break
      case 'depositCoinJun200':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositCoinJun200')}`}
            key="depositCoinJun200"
            onClick={() => onClickMenuItem('bank', 'depositCoinJun200')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.coin' })} />
          </ListItemButton>,
        )
        break
      case 'deposit5Koin':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=deposit5Koin')}`}
            key="deposit5Koin"
            onClick={() => onClickMenuItem('bank', 'deposit5Koin')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.5koin' })} />
          </ListItemButton>,
        )
        break
      case 'depositWonp':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositWonp')}`}
            key="depositWonp"
            onClick={() => onClickMenuItem('bank', 'depositWonp')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.wonp' })} />
          </ListItemButton>,
        )
        break
      case 'depositOnePayCoin':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositOnePayCoin')}`}
            key="depositOnePayCoin"
            onClick={() => onClickMenuItem('bank', 'depositOnePayCoin')}
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'component.bank.deposit.onepaycoin' })}
            />
          </ListItemButton>,
        )
        break
      case 'depositCommonCoin':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositCommonCoin')}`}
            key="depositCommonCoin"
            onClick={() => onClickMenuItem('bank', 'depositCommonCoin')}
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'component.bank.deposit.commoncoin' })}
            />
          </ListItemButton>,
        )
        break
      case 'depositCommonVirtual':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositCommonVirtual')}`}
            key="depositCommonVirtual"
            onClick={() => onClickMenuItem('bank', 'depositCommonVirtual')}
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'component.bank.deposit.commonvirtual' })}
            />
          </ListItemButton>,
        )
        break
      case 'depositAuthVirtual':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositAuthVirtual')}`}
            key="depositAuthVirtual"
            onClick={() => onClickMenuItem('bank', 'depositAuthVirtual')}
          >
            <ListItemText
              primary={intl.formatMessage({ id: 'component.bank.deposit.authvirtual' })}
            />
          </ListItemButton>,
        )
        break
      case 'depositMCI':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositMCI')}`}
            key="depositMCI"
            onClick={() => onClickMenuItem('bank', 'depositMCI')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.mci' })} />
          </ListItemButton>,
        )
        break
      case 'depositCodepay':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=depositCodepay')}`}
            key="depositCodepay"
            onClick={() => onClickMenuItem('bank', 'depositCodepay')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.codepay' })} />
          </ListItemButton>,
        )
        break
      case 'withdrawCodepay':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=withdrawCodepay')}`}
            key="withdrawCodepay"
            onClick={() => onClickMenuItem('bank', 'withdrawCodepay')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.withdraw.codepay' })} />
          </ListItemButton>,
        )
        break
      case 'withdraw':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=withdraw')}`}
            onClick={() => onClickMenuItem('bank', 'withdraw')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.withdraw' })} />
          </ListItemButton>,
        )
        break
      case 'history':
        chargeContents.push(
          <ListItemButton
            className={`nested ${navActive('?main=history')}`}
            onClick={() => onClickMenuItem('bank', 'history')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.bank.history' })} />
          </ListItemButton>,
        )
        break
    }
  }

  const list = (
    <GnbStyle component="nav" sx={{ width: 250 }} role="presentation">
      <Box className="myinfo">
        {signedin === true ? (
          <Grid container sx={{ px: 1 }}>
            <Grid item xs="auto" sx={{ py: '6px', '& > img': { width: '36px' } }}>
              <img src={getLevelImage(contents_level)} alt="" />
            </Grid>
            <Grid item xs>
              <List component="div">
                <ListItemButton className="nickname" sx={{ py: 0.5, px: 1 }} key="nickname">
                  {nickname}
                </ListItemButton>
                <ListItemButton sx={{ py: 0, px: 1 }} key="money">
                  <ListItemText
                    className="money"
                    sx={{
                      pr: 2,
                      '.MuiTypography-root': {
                        display: 'flex',
                        justifyContent: 'space-between',
                      },
                    }}
                  >
                    <span>{intl.formatMessage({ id: 'own-money' })}</span>
                    <span>{numberWithCommas(Math.floor(balanceMoney))}</span>
                  </ListItemText>
                  <ArrowForwardIosIcon sx={{ width: '12px' }} />
                </ListItemButton>
                <ListItemButton
                  sx={{ py: 0, px: 1 }}
                  key="point"
                  onClick={() => onClickButton('point')}
                >
                  <ListItemText
                    sx={{
                      pr: 2,
                      color: '#FFFFFF',
                      '.MuiTypography-root': {
                        display: 'flex',
                        justifyContent: 'space-between',
                      },
                    }}
                  >
                    <span>{intl.formatMessage({ id: 'point' })}</span>
                    {numberWithCommas(Math.floor(balancePoint))}
                  </ListItemText>
                  <ArrowForwardIosIcon sx={{ width: '12px' }} />
                </ListItemButton>
                {enableComp ? (
                  <ListItemButton
                    sx={{ py: 0, px: 1 }}
                    key="comp"
                    onClick={() => onClickButton('comp')}
                  >
                    <ListItemText
                      sx={{
                        pr: 2,
                        color: '#FFFFFF',
                        '.MuiTypography-root': {
                          display: 'flex',
                          justifyContent: 'space-between',
                        },
                      }}
                    >
                      <span>{intl.formatMessage({ id: 'comp' })}</span>
                      {numberWithCommas(Math.floor(balanceComp))}
                    </ListItemText>
                    <ArrowForwardIosIcon sx={{ width: '12px' }} />
                  </ListItemButton>
                ) : null}
                {SHOW_GAME_MONEY && !globalStore.option?.solution?.seamless && (
                  <>
                    <ListItemButton
                      disabled={!!refreshEnabled}
                      sx={{ py: 0, px: 1 }}
                      key="game-money"
                      onClick={() => onClickButton('game-money')}
                    >
                      <ListItemText
                        sx={{
                          pr: 2,
                          color: '#FFFFFF',
                          '.MuiTypography-root': {
                            display: 'flex',
                            justifyContent: 'space-between',
                          },
                        }}
                      >
                        <span>{intl.formatMessage({ id: 'game-money' })}</span>
                        {_.isNumber(game_money) ? numberWithCommas(Math.floor(game_money)) : '??'}
                      </ListItemText>
                      <RefreshIcon sx={{ width: '16px', ml: '-4px' }} />
                    </ListItemButton>
                    <ListItemButton>
                      <Button
                        disabled={!!refreshEnabled}
                        onClick={() => onClickButton('withdraw')}
                        color="error"
                        variant="contained"
                        size="small"
                        sx={{
                          width: '100%',
                          height: '20px',
                          '&:disabled': {
                            backgroundColor: '#931d1d',
                            color: '#cccccc',
                          },
                        }}
                      >
                        {intl.formatMessage({ id: 'msg.btn.gamemoney-to-ownmoney' })}
                      </Button>
                    </ListItemButton>
                  </>
                )}
              </List>
              <Divider sx={{ mx: 1, borderColor: '#747D8A' }} />
              <List component="div" sx={{ py: 0.5 }}>
                <ListItemButton key="logout" onClick={onClickLogout} sx={{ py: 0, px: 1 }}>
                  <ListItemText className="logout" sx={{ textDecoration: 'underline' }}>
                    {intl.formatMessage({ id: 'logout' })}
                  </ListItemText>
                  <LogoutIcon />
                </ListItemButton>
              </List>
            </Grid>
          </Grid>
        ) : (
          <Box className="login" sx={{ py: 2 }}>
            <Button onClick={() => onClickMenuItem('login', '0')} sx={{ width: '100%' }}>
              <IconUser style={{ fill: '#40485E', height: '100%', marginRight: '10px' }} />
              <ListItemText
                primary={intl.formatMessage({ id: 'msg.login-require' })}
                sx={{ textAlign: 'start' }}
              />
              <ArrowForwardIosIcon sx={{ width: '12px' }} />
            </Button>
          </Box>
        )}
      </Box>

      <Divider />
      <List
        component="div"
        className="category"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {intl.formatMessage({ id: 'category' })}
          </ListSubheader>
        }
        sx={{ pb: 0 }}
      >
        {channelSymbol === 'fair' ? (
          <ListItemButton
            hidden={!isEnabled('SP')}
            className={`outer ${navActive('/sports')}`}
            key="sports"
            onClick={() => {
              onClickMenuItem('sports', '')
            }}
          >
            <ListItemText primary={intl.formatMessage({ id: 'menu.header.sports' })} />
          </ListItemButton>
        ) : null}

        <ListItemButton
          hidden={!isEnabled('LCA')}
          className={`outer ${navActive('/live-casino')}`}
          key="live-casino"
          selected={listOpen === 'live-casino'}
          onClick={() => {
            onClickMenuItem('live-casino', 'evolution')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.live-casino' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('HCA')}
          className={`outer ${navActive('/hotel-casino')}`}
          key="hotel-casino"
          selected={listOpen === 'hotel-casino'}
          onClick={() => {
            // handleList('hotel-casino')
            onClickMenuItem('hotel-casino', 'uig')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.hotel-casino' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('MSL')}
          className={`outer ${navActive('/msl')}`}
          key="msl"
          onClick={() => {
            onClickMenuItem('msl', '')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.msl' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('SL')}
          className={`outer ${navActive('/slot')}`}
          key="slot"
          onClick={() => {
            onClickMenuItem('slot', '...')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.slot' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('BOARDGAME')}
          className={`outer ${navActive('/boardgame')}`}
          key="boardgame"
          onClick={() => {
            onClickMenuItem('boardgame', '...')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.boardgame' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('REEL')}
          className={`outer ${navActive('/reel')}`}
          key="reel"
          onClick={() => {
            onClickMenuItem('reel', '...')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.reel' })} />
        </ListItemButton>

        <ListItemButton
          hidden={
            !isEnabled('HPB') &&
            !isEnabled('NPB') &&
            !isEnabled('PB') &&
            !isEnabled('PL') &&
            !isEnabled('EOS') &&
            !isEnabled('PBG') &&
            !isEnabled('CPB') &&
            !isEnabled('CL') &&
            !isEnabled('SG') &&
            !isEnabled('WRBTCPB') &&
            !isEnabled('WRBTCPL') &&
            !isEnabled('WRBEXPB') &&
            !isEnabled('WRKENOPB') &&
            !isEnabled('WRKENOPL') &&
            !isEnabled('WRKENO')
          }
          className={`outer ${navActive('/minigame')}`}
          key="minigame"
          onClick={() => {
            onClickMenuItem('minigame', '')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.minigame' })} />
        </ListItemButton>

        {channelSymbol === 'fair' ? null : (
          <ListItemButton
            hidden={!isEnabled('SP')}
            className={`outer ${navActive('/sports')}`}
            key="sports"
            onClick={() => {
              onClickMenuItem('sports', '')
            }}
          >
            <ListItemText primary={intl.formatMessage({ id: 'menu.header.sports' })} />
          </ListItemButton>
        )}

        {showNotice ? (
          <ListItemButton
            className={`outer ${navActive('?main=NOTICE')}`}
            key="notice"
            onClick={() => onClickMenuItem('notice', 'NOTICE')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.notice.notice' })} />
          </ListItemButton>
        ) : null}

        {showEvent ? (
          <ListItemButton
            className={`outer ${navActive('?main=EVENT')}`}
            key="event"
            onClick={() => onClickMenuItem('notice', 'EVENT')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.notice.event' })} />
          </ListItemButton>
        ) : null}

        {showPolicy ? (
          <ListItemButton
            className={`outer ${navActive('?main=POLICY')}`}
            key="policy"
            onClick={() => onClickMenuItem('notice', 'POLICY')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.notice.policy' })} />
          </ListItemButton>
        ) : null}

        {showQna ? (
          <ListItemButton
            className={`outer ${navActive('?main=qnaPersonal')}`}
            key="qnaPersonal"
            onClick={() => onClickMenuItem('qnaPersonal', '')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'menu.header.qna' })} />
          </ListItemButton>
        ) : null}

        {signedin ? (
          <Fragment>
            <ListItemButton
              className="outer"
              key="account"
              selected={listOpen === 'account'}
              onClick={() => {
                handleList('account')
              }}
            >
              <ListItemText primary={intl.formatMessage({ id: 'component.bank-transaction' })} />
              {'account' === listOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={'account' === listOpen} unmountOnExit>
              <List component="div" disablePadding>
                {chargeContents}
              </List>
            </Collapse>

            <ListItemButton
              className="outer"
              key="mypage"
              selected={listOpen === 'mypage'}
              onClick={() => {
                handleList('mypage')
              }}
            >
              <ListItemText primary={intl.formatMessage({ id: 'component.mypage' })} />
              {'mypage' === listOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={'mypage' === listOpen} unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  className={`nested ${navActive('?main=point')}`}
                  key="point"
                  onClick={() => onClickMenuItem('point', '')}
                >
                  <ListItemText primary={intl.formatMessage({ id: 'component.point' })} />
                </ListItemButton>
                {enableComp ? (
                  <ListItemButton
                    className={`nested ${navActive('?main=comp')}`}
                    key="comp"
                    onClick={() => onClickMenuItem('comp', '')}
                  >
                    <ListItemText primary={intl.formatMessage({ id: 'component.comp' })} />
                  </ListItemButton>
                ) : null}

                {/* <ListItemButton className="nested" key="qnaPersonal" onClick={() => onClickMenuItem('qnaPersonal', '')}>
                  <ListItemText primary={intl.formatMessage({ id: 'component.private-qna' })} />
                </ListItemButton> */}
                {/* <ListItemButton className="nested" onClick={() => onClickMenuItem('qnaPartner', '')}>
                  <ListItemText primary={intl.formatMessage({ id: 'component.alliance-qna' })} />
                </ListItemButton> */}
                {enableChangePassword && (
                  <ListItemButton
                    className={`nested ${navActive('?main=myinfo')}`}
                    key="myinfo"
                    onClick={() => onClickMenuItem('myinfo', '')}
                  >
                    <ListItemText primary={intl.formatMessage({ id: 'component.modify-myinfo' })} />
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </Fragment>
        ) : null}
      </List>
    </GnbStyle>
  )
  // -----------Drawer

  return (
    <MobileHeaderStyle>
      <AppBar position="static">
        <Grid container>
          <Grid item xs={4}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              className="menu"
              sx={{ ml: 0 }}
              onClick={() => setOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            {enableCoupon ? (
              <IconButton
                className={`btn_coupon ${couponActive}`}
                onClick={() => onClickMenuItem('coupon', '')}
              >
                <IconCoupon />
              </IconButton>
            ) : null}

            <IconButton className="btn_message" onClick={() => onClickMenuItem('message', '')}>
              {badgeContents}
            </IconButton>
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: 'center', '& > img': { cursor: 'pointer' } }}>
            {channelSymbol === 'epic' || channelSymbol === 'noah' || channelSymbol === 'tsunami' ? (
              <img
                src={asset_channel.common.main_logo_gif_m}
                alt=""
                onClick={() => onClickLogo()}
              />
            ) : (
              <img src={asset_channel.common.main_logo_m} alt="" onClick={() => onClickLogo()} />
            )}
          </Grid>
          <Grid item xs={4} sx={{ justifyContent: 'end' }}>
            {rightContents}
          </Grid>
        </Grid>
      </AppBar>
      <PartnerMenu></PartnerMenu>

      <DrawerStyle anchor="left" open={open} onClose={() => setOpen(false)}>
        {list}
      </DrawerStyle>
    </MobileHeaderStyle>
  )
}

export default observer(Header)
