import http from '../http'
import {
  IBetEvo,
  IBet,
  IBetRecords,
  IBetPendings,
  IMinigameBetRecords,
  ICheckPopupGame,
  IWithdrawOnDemand,
  IGameMoney,
  ISportsBetRecords,
  ICancelBet,
  IHideSportsBetRecord,
  ITradingBetRecords,
} from '@protocol/game'

const defs = {
  betEvo(data: IBetEvo.Params): Promise<IBetEvo.Schema> {
    return http.post('api/game/bet-evo', data || {})
  },

  betMinigame(data: IBet.Params): Promise<IBet.Schema> {
    return http.post('api/game/bet-mg', data || {})
  },

  betSports(data: IBet.Params): Promise<IBet.Schema> {
    return http.post('api/game/bet-sp', data || {})
  },

  cancelBet(data: ICancelBet.Params): Promise<ICancelBet.Schema> {
    return http.post('api/game/cancel-bet', data || {})
  },

  records(data: IBetRecords.Params): Promise<IBetRecords.Schema> {
    return http.post('api/game/records', data || {})
  },

  pendings(data: IBetPendings.Params): Promise<IBetPendings.Schema> {
    return http.post('api/game/pendings', data || {})
  },

  tradingBetRecords(data: ITradingBetRecords.Params): Promise<ITradingBetRecords.Schema> {
    return http.post('api/game/trading-bet-records', data || {})
  },

  minigameRecords(data: IMinigameBetRecords.Params): Promise<IMinigameBetRecords.Schema> {
    return http.post('api/game/minigame-records', data || {})
  },

  sportsRecords(data: ISportsBetRecords.Params): Promise<ISportsBetRecords.Schema> {
    return http.post('api/game/sports-records', data || {})
  },

  hideSportsRecord(data: IHideSportsBetRecord.Params): Promise<IHideSportsBetRecord.Schema> {
    return http.post('api/game/hide-sports-record', data || {})
  },

  checkPopupGame(data: ICheckPopupGame.Params): Promise<ICheckPopupGame.Schema> {
    return http.post('api/game/check-popup-game', data || {})
  },

  withdrawOnDemand(data: IWithdrawOnDemand.Params): Promise<IWithdrawOnDemand.Schema> {
    return http.post('api/game/withdraw-ondemand', data || {})
  },

  getGameMoney(data: IGameMoney.Params): Promise<IGameMoney.Schema> {
    return http.post('api/game/game-money', data || {})
  },
}

export default defs
