import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { styled } from '@mui/material/styles'
import moment from 'moment'
import {
  Tab,
  Tabs,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  LinearProgress,
} from '@mui/material'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle } from '@shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { Schema$PartnerAccountBalanceItem, IPartnerAccountBalanceRecord } from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { getGainLossColor, numberWithCommas } from '~/utils/utils'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { INVOICE_STATE, ALL_GAME_INFO } from '@interface/config'
import { a11yProps } from '@shared/Common'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: number
  date_begin: Date
  date_end: Date
  date_request: Date
  date_done: Date
  volume: number
  state: string
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>정산 시작일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>정산 종료일</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>요청 일시</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>처리 일시</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>금액</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>상태</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData }) {
  const { row } = props

  const date_begin = row.date_begin ? moment(new Date(row.date_begin)).format('YYYY-MM-DD') : null
  const date_end = row.date_end ? moment(new Date(row.date_end)).format('YYYY-MM-DD') : null
  const date_request = row.date_request
    ? moment(new Date(row.date_request)).format('YYYY-MM-DD HH:mm::ss')
    : null
  const date_done = row.date_done
    ? moment(new Date(row.date_done)).format('YYYY-MM-DD HH:mm::ss')
    : null
  const volume = _.isNumber(row.volume) ? row.volume : 0

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{date_begin}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{date_end}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{date_request}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{date_done}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(volume)) }}>
            {numberWithCommas(Math.floor(volume))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.state}</Typography>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

function PartnerIntergratedBalanceAccountRecord({ location }) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { main } = query
  const startTab = Math.max(
    0,
    _.findIndex(ALL_GAME_INFO, o => {
      return o.group === main
    }),
  )

  // no paging for record
  const [tab, setTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<Schema$PartnerAccountBalanceItem[]>([])
  const [searchBegin, setSearchBegin] = useState<Date>(start)
  const [searchEnd, setSearchEnd] = useState<Date>(new Date())

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { option } = globalStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups } = option || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  if (startTab !== tab) {
    setTab(startTab)
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-BALANCE-RECORD')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.balance_account_record)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const fetch = async (params: IPartnerAccountBalanceRecord.Params) => {
    setLoading(true)

    try {
      const { items } = await apiPartner.getBalanceAccountRecord(params)
      setItems(items)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetch({
      trading_group: ALL_GAME_INFO[tab].group,
      search_begin: searchBegin.toString(),
      search_end: searchEnd.toString(),
    })
  }, [tab])

  const handleChangeTab = (event, newValue) => {
    history.push(`/partner/balance-record/search?main=${ALL_GAME_INFO[newValue].group}`)
  }

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
  }

  const onClickSearch = () => {
    fetch({
      trading_group: ALL_GAME_INFO[tab].group,
      search_begin: searchBegin.toString(),
      search_end: searchEnd.toString(),
    })
  }

  const tabItems = []
  for (let i = 0; i < ALL_GAME_INFO.length; i++) {
    const tabHidden =
      _.findIndex(open_trading_groups, o => {
        return o.trading_group === ALL_GAME_INFO[i].group
      }) < 0
    tabItems.push(
      <Tab
        hidden={tabHidden}
        label={ALL_GAME_INFO[i].name}
        {...a11yProps(i)}
        style={{ fontSize: '14px' }}
      />,
    )
  }

  const rows = []
  for (let i = 0; i < items.length; i++) {
    const el = items[i]
    const found = _.find(INVOICE_STATE, o => {
      return o.id === el.state
    })
    const stateName = found ? found.name : ''
    let endDate = new Date(el.date_end)
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    const row = {
      id: i,
      date_begin: new Date(el.date_begin),
      date_end: endDate,
      date_request: el.date_request ? new Date(el.date_request) : null,
      date_done: el.date_done ? new Date(el.date_done) : null,
      volume: el.volume,
      state: stateName,
    }
    rows.push(<Row key={row.id} row={row}></Row>)
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1248px',
          maxWidth: '1248px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>정산 내역</Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ borderWidth: '100%', borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabItems}
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
            <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
            <Table size="small" aria-label="collapsible table">
              {Head()}
              <TableBody>{rows}</TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedBalanceAccountRecord)
