/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import { Box } from '@mui/material'
import PortableText from 'react-portable-text'

//css
import { BoardStyle } from '@styles/modal.style'

interface Props {
  content: string
}

function NoticeView({ content }) {
  return (
    <BoardStyle className="board_view">
      <Box className="board_content" sx={{ py: 2 }}>
        <Box sx={{ width: '90%', m: 'auto', wordBreak: 'break-all' }} dangerouslySetInnerHTML={{ __html: content }}>
        </Box>
      </Box>
    </BoardStyle>
  )
}

export default NoticeView
