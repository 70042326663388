import * as _ from 'lodash'
import Button from '../premitives/button'
import { ChipAttributes, OddsPosition } from '../components/types'
import ChipButton from '../components/chip-button'
import { CHIP_BUTTONS } from '../components/chip-data'

export default class MobileChipComponent extends Phaser.GameObjects.Container {
  initialPosition: OddsPosition = { x: 0, y: 0 }

  btnCancelAll: Button = null
  btnCancelLastBet: Button = null
  btnDoubleupBet: Button = null

  chips: ChipButton[] = []
  selectedChip: ChipButton = null
  selectedChipId = 0

  bg: Phaser.GameObjects.Image = null

  selectChipMode = false

  handleChipSlelected: (id: number) => void = null
  handleClickChipButton: (id: number) => void = null
  handleCancelAll: () => void = null
  handleCancelLastBet: () => void = null
  handleDoubleBet: () => void = null

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    handleChipSlelected: (id: number) => void,
    handleClickChipButton: (id: number) => void,
    handleCancelAll: () => void,
    handleCancelLastBet: () => void,
    handleDoubleBet: () => void,
  ) {
    super(scene, x, y)

    scene.add.existing(this)

    this.handleChipSlelected = handleChipSlelected
    this.handleClickChipButton = handleClickChipButton
    this.handleCancelAll = handleCancelAll
    this.handleCancelLastBet = handleCancelLastBet
    this.handleDoubleBet = handleDoubleBet

    this.initialPosition = { x, y }

    this.bg = scene.add.image(100, 40, 'ui', 'chip_bg.png')
    this.bg.setOrigin(0.5, 1)
    this.bg.setScale(1.1, 1.1)
    this.bg.setAlpha(0.95, 0.95)
    this.bg.setVisible(false)
    this.add(this.bg)

    this.addButtons()
    this.addChips()
  }

  setEnabled(enabled: boolean) {
    this.setVisible(enabled)
  }

  addButtons() {
    // Cancel all
    this.btnCancelAll = new Button(
      this.scene,
      0,
      0,
      { texture: 'ui', frame: 'cancel_bet_all.png' },
      { texture: 'ui', frame: 'cancel_bet_all_push.png' },
      { texture: 'ui', frame: 'cancel_bet_all.png' },
      this.handleCancelAll.bind(this),
    )
    this.add(this.btnCancelAll)

    // Cancel last
    this.btnCancelLastBet = new Button(
      this.scene,
      45,
      0,
      { texture: 'ui', frame: 'cancel_bet.png' },
      { texture: 'ui', frame: 'cancel_bet_push.png' },
      { texture: 'ui', frame: 'cancel_bet.png' },
      this.onClickCancelLastBet.bind(this),
    )
    this.add(this.btnCancelLastBet)

    // Double up
    this.btnDoubleupBet = new Button(
      this.scene,
      156,
      0,
      { texture: 'ui', frame: 'times2_bet.png' },
      { texture: 'ui', frame: 'times2_bet_push.png' },
      { texture: 'ui', frame: 'times2_bet.png' },
      this.onClickDoubleupBet.bind(this),
    )
    this.add(this.btnDoubleupBet)

    // add text
    const txtCancelAll = this.scene.add
      .text(353, 281, '취소', {
        fontSize: '12px',
        color: '#ffffff88',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txtCancelAll)

    const txtCancelLast = this.scene.add
      .text(398, 281, '-1', {
        fontSize: '12px',
        color: '#ffffff88',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txtCancelLast)

    const txtDoubleup = this.scene.add
      .text(807, 281, '더블', {
        fontSize: '12px',
        color: '#ffffff88',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txtDoubleup)
  }

  addChips() {
    for (let i = 0; i < CHIP_BUTTONS.length; i += 1) {
      const chipData = CHIP_BUTTONS[i]
      const chip = new ChipButton(
        this.scene,
        chipData.position.x,
        chipData.position.y,
        chipData,
        this.onClickChipButton.bind(this),
      )
      chip.setVisible(false)
      this.add(chip)

      this.chips.push(chip)
    }
  }

  getChipButton(id: number) {
    const btn = _.find(this.chips, (o: ChipButton) => {
      return o.attributes.id === id
    })
    return btn
  }

  getChipButtonByType(type: string) {
    const btn = _.find(this.chips, (o: ChipButton) => {
      return o.attributes.type === type
    })
    return btn
  }

  releaseChip() {
    this.selectChip(0)
  }

  getSelectedChip() {
    return this.selectedChip
  }

  selectChip(id: number) {
    if (this.selectedChip) {
      this.selectedChip.destroy()
    }
    const chipData = _.find(CHIP_BUTTONS, (o: ChipAttributes) => {
      return o.id === id
    })
    this.selectedChip = new ChipButton(
      this.scene,
      100,
      4,
      chipData,
      this.onClickSelectedChip.bind(this),
    )
    this.add(this.selectedChip)
    this.selectedChip.setHighlight(true)
    this.selectedChipId = id

    if (this.handleChipSlelected) {
      this.handleChipSlelected(id)
    }
  }

  setSelectChipMode(enabled: boolean) {
    if (this.selectChipMode === enabled) {
      return
    }

    this.selectChipMode = enabled

    if (this.selectChipMode) {
      const duration = 150
      this.bg.setVisible(true)
      this.bg.setScale(0.1, 0.1)
      this.scene.tweens.add({
        targets: this.bg,
        scale: 1.1,
        duration,
        ease: Phaser.Math.Easing.Linear,
        onComplete: () => {
          for (const el of this.chips) {
            el.setVisible(true)
          }
        },
      })
    } else {
      for (const el of this.chips) {
        el.setVisible(false)
      }

      const duration = 150
      this.scene.tweens.add({
        targets: this.bg,
        scale: 0.1,
        duration,
        ease: Phaser.Math.Easing.Linear,
        onComplete: () => {
          this.bg.setVisible(false)
        },
      })
    }
  }

  onClickSelectedChip(btn: ChipButton) {
    this.setSelectChipMode(!this.selectChipMode)
  }

  onClickChipButton(btn: ChipButton) {
    if (this.handleClickChipButton) {
      this.handleClickChipButton(btn.attributes.id)
    }

    this.selectChip(btn.attributes.id)

    this.setSelectChipMode(false)
  }

  onClickCancelAll() {
    if (this.handleCancelAll) {
      this.handleCancelAll()
    }
  }

  onClickCancelLastBet() {
    if (this.handleCancelLastBet) {
      this.handleCancelLastBet()
    }
  }

  onClickDoubleupBet() {
    if (this.handleDoubleBet) {
      this.handleDoubleBet()
    }
  }
}
