import { EventEmitter } from 'eventemitter3'
import { reaction } from 'mobx'

//import { socketStore } from '@store/index'
import { SocketStore } from '@store/socketStore'

interface SocketMsg {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

/**
 * socket 通信
 *
 * @export
 * @class Socket
 */
export class Socket extends EventEmitter {
  onopen: () => void

  onmessage: (msg: SocketMsg) => void

  conn: WebSocket = null

  store: SocketStore = null

  reopenTimer: number = null

  disconnectInitiative = false

  constructor(socketStore: SocketStore) {
    super()

    this.store = socketStore
    this.run()
  }

  run() {
    this.onopen = () => {
      const text = 'socket connected !!!'
      if (this.store) {
        this.store.setSocketIsConnected(true)
        this.store.addMessage({
          event: 'connect',
          from: 'console',
          data: text,
        })
      }
    }

    this.onmessage = (msg: SocketMsg) => {
      if (!msg || !msg.data) {
        return
      }
      if (this.store) {
        this.store.addMessage({
          event: 'message',
          from: 'server',
          data: typeof msg.data === 'object' ? JSON.stringify(msg.data) : msg.data,
        })
      }
    }
  }

  send(data: string | PlainObject, retry = 0) {
    if (this.conn && this.conn.readyState === this.conn.OPEN) {
      this.conn.send(typeof data === 'object' ? JSON.stringify(data) : data)
    } else if (retry < 3) {
      setTimeout(() => {
        this.send(data, retry + 1)
      }, 300)
    }
  }

  open(url: string) {
    this.conn = new WebSocket(url, 'echo-protocol')
    this.conn.onclose = evt => {
      if (this.store) {
        this.store.setSocketIsConnected(false)
        const text = `socket close: ${typeof evt === 'object' ? evt.code : ''}`
        this.store.addMessage({
          event: 'close',
          from: 'console',
          data: text,
        })
      }
      if (this.reopenTimer) {
        clearTimeout(this.reopenTimer)
        this.reopenTimer = null
      }
      if (!this.disconnectInitiative) {
        this.reopenTimer = window.setTimeout(() => {
          this.open(url)
        }, 1000)
      }
      this.disconnectInitiative = false
    }
    this.conn.onerror = evt => {
      if (this.store) {
        this.store.setSocketIsConnected(false)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const text = `socket error: ${typeof evt === 'object' ? JSON.stringify((evt as any).code) : ''}`
        this.store.addMessage({
          event: 'error',
          from: 'console',
          data: text,
        })
      }
    }

    // reaction(
    //   () => socketStore.socketType,
    //   (_, r) => {
    //     clearTimeout(reopenTimer)
    //     // r.dispose()
    //   },
    // )

    if (this.onopen) {
      this.conn.onopen = this.onopen
    }
    if (this.onmessage) {
      this.conn.onmessage = this.onmessage
    }
    return this
  }

  canOpen = (): boolean => {
    return !(this.conn && this.conn.readyState === this.conn.OPEN)
  }

  connect = (url: string) => {
    if (!this.canOpen()) {
      return
    }

    this.disconnectInitiative = false
    if (this.reopenTimer) {
      clearTimeout(this.reopenTimer)
      this.reopenTimer = null
    }

    this.open(url)
  }

  disconnect = () => {
    if (this.conn && this.conn.readyState === this.conn.OPEN) {
      this.conn.close()
    }
    this.disconnectInitiative = true
    if (this.reopenTimer) {
      clearTimeout(this.reopenTimer)
      this.reopenTimer = null
    }
  }

  cleanup = () => {
    this.store = null
    this.disconnect()
  }

  sendMessage = (data: string | PlainObject) => {
    if (!this.conn && this.conn.readyState !== this.conn.OPEN) {
      return
    }
    this.send(data)

    // if (this.store) {
    //   this.store.addMessage({
    //     event: null,
    //     from: 'browser',
    //     data,
    //   })
    // }
  }
}
