/** @jsxImportSource @emotion/react */
import { Typography, Button } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { numberWithCommas } from '@utils/utils'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import ListIcon from '@mui/icons-material/List'

export function getColumns() {
  const columns: GridColumns = [
    { field: 'id', headerName: 'id', width: 90, hide: true, hideable: false },
    { field: 'uuid', headerName: 'uuid', width: 90, hide: true, hideable: false },
    {
      field: 'time',
      headerName: '시간',
      width: 252,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => params.value,
    },
    {
      field: 'reason',
      headerName: '사유',
      width: 200,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => params.value,
    },
    {
      field: 'info',
      headerName: '세부정보',
      width: 300,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => params.value,
    },
    {
      field: 'inout_amount',
      headerName: '입금/출금액',
      type: 'number',
      width: 200,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => params.value,
    },
    {
      field: 'balance',
      headerName: '잔액',
      type: 'number',
      width: 200,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => params.value,
    },
  ]

  return columns
}
