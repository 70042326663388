import React, { useState } from 'react'
import * as _ from 'lodash'
import { Box } from '@mui/material'
import { OddsBoxBaseline } from '.'
import { MarketArranged } from '~/game/st-util'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { useTheme } from '@mui/material/styles'
import { Schema$Prematch } from '~/v2/interface/st-schema'
import { SPORT_FLIGHT } from '~/store/sportsStore/sports-util'

interface Props {
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  prematch: Schema$Prematch
  arranged: MarketArranged
  unifiedName: string
  sx?: any
}

function MarketBoxBaseline({ desktop, lang, flight, prematch, arranged, unifiedName, sx }: Props) {
  const theme = useTheme()
  const { globalStore, sportsStore } = useRootStore()
  const [expanded, setExpanded] = useState(false)

  const { options } = sportsStore
  const { sports_configs } = options || {}
  const baseline_config = _.find(sports_configs, o => {
    return o.sport_id === prematch.SportId
  })

  const handleExpanded = () => {
    setExpanded(!expanded)
  }

  let topContents = null
  let addonContents = []

  let baseline_size = 0
  switch (unifiedName) {
    case 'HDP':
      baseline_size = baseline_config?.baseline_size_hdp
      break
    case 'UO':
      baseline_size = baseline_config?.baseline_size_uo
      break
  }

  // const canExpand = flight === 'europe' && arranged.addon.length > 0
  const canExpand = arranged.addon.length > 0

  const oddsProps = {
    theme,
    desktop,
    lang,
    flight,
    prematch: prematch,
    market: arranged.top,
    isTop: true,
    unifiedName,
    expand: canExpand,
    expanded,
    onToggleExpanded: handleExpanded,
  }
  topContents = <OddsBoxBaseline key={arranged.top.BaseLine} {...oddsProps}></OddsBoxBaseline>

  if (expanded) {
    for (let i = 0; i < arranged.addon.length; i += 1) {
      const oddsProps = {
        theme,
        desktop,
        lang,
        flight,
        prematch: prematch,
        market: arranged.addon[i],
        isTop: false,
        unifiedName,
        expand: false,
        expanded: false,
      }
      addonContents.push(<OddsBoxBaseline key={arranged.addon[i].BaseLine} {...oddsProps}></OddsBoxBaseline>)
    }
  }

  return (
    <Box sx={{ ...sx }}>
      {topContents}
      {addonContents}
    </Box>
  )
}

export default observer(MarketBoxBaseline)
