import { observable, action, makeObservable, computed, toJS } from 'mobx'
import * as _ from 'lodash'

import { StoreExt } from '@utils/reactExt'
import { delay } from '@utils/utils'
import { globalStore, liveStore, userStore } from '@store/index'
import { SocketStore } from '@store/socketStore'
import { Message, SOCKET_SERVICE_STATE } from '@store/socketStore'
import { ISignin, ISignup } from '@protocol/auth'
import { ERR } from '~/v2/interface/error'
import { LiveProtocol } from '@live/live-protocol'
import { PacketStructure, Schema$Result } from '@live/live'
import { MinigameMatchup, ODDS_COLOR } from '@game/common'
import { MakeTestMatchup } from '@game/common'
import { GetPowerballOddsColor } from '@game/powerball'
import { GetPowerladderOddsColor } from '@game/powerladder'
import { GetSquidgameOddsColor } from '@game/squidgame'
import { IBet, Schema$MatchupBetFold, Schema$MatchupBetRecord } from '@protocol/game'
import { ISyncEnter } from '@live/live'
import { DialogType } from '../globalStore'
import { rateMultiplied, dividendFixed } from '@game/rate-util'
import { Schema$Matchup, Schema$MatchupInfo, Schema$TradingOddsOption } from '@interface/matchup'
import { PowerballMarketMeta, PowerballOddsMeta } from '@v2/utils/powerball-meta'
import {
  WooriKenoPowerballMarketMeta,
  WooriKenoPowerballOddsMeta,
} from '@v2/utils/woori-keno-powerball-meta'
import { PowerladderMarketMeta, PowerladderOddsMeta } from '@v2/utils/powerladder-meta'
import { SquidgameMarketMeta, SquidgameOddsMeta } from '@v2/utils/squidgame-meta'
import { KinoMarketMeta, KinoOddsMeta } from '@v2/utils/kino-meta'

import {
  POWERBALL_MINIGAME_GROUP,
  POWERLADDER_MINIGAME_GROUP,
  WOORI_POWERBALL_MINIGAME_GROUP,
} from '~/v2/interface/config'
import { IntlShape } from 'react-intl'

export interface BettingCartItem {
  tradingKind: string

  marketGroup: number

  matchupId: string
  marketId: string
  oddsId: string

  round: number
  uniqueId: number
  market: string
  marketColor: string
  odds: string
  rate: string
}

export interface RecordTrackItem {
  kind: string
  kindName: string
  betId: number
  round: number
  uniqueId: number
  bettingTime: string
  txtMarket: string
  betAmount: number
  dividendAmount: number
  rate: string
  state: string // 대기, 당첨, 낙첨
  stateColor: string
}

export const MakeRecordTrack = (
  matchup: MinigameMatchup,
  records: Schema$MatchupBetRecord[],
): RecordTrackItem[] => {
  const items: RecordTrackItem[] = []
  if (!matchup) {
    return items
  }
  for (const el of records) {
    const item: RecordTrackItem = {
      kind: el.tradingKind,
      kindName: el.tradingKindName,
      betId: el.id,
      round: el.round,
      uniqueId: el.uniqueId,
      bettingTime: el.bettingTime,
      txtMarket: '',
      betAmount: el.betMoney,
      dividendAmount: el.dividend,
      rate: el.rate,
      state: '',
      stateColor: '',
    }
    switch (el.state) {
      case 'BET':
        item.state = '대기'
        item.stateColor = '#FBC342'
        break
      case 'WIN':
        item.state = '당첨'
        item.stateColor = '#2A73E1'
        break
      case 'LOSE':
        item.state = '낙첨'
        item.stateColor = '#FE3D3D'
        break
      case 'CANCEL':
        item.state = '취소'
        item.stateColor = '#27AE60'
        break
    }

    for (const el2 of el.folds) {
      if (el2.marketId in matchup.markets) {
        const market = matchup.markets[el2.marketId]
        const odds = _.find(market.odds, function(o) {
          return o.id === el2.oddsId
        })
        if (odds) {
          if (POWERBALL_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
            if (item.txtMarket.length > 0) {
              item.txtMarket += ' + '
            }
            item.txtMarket += `${market.category === 'PBP' ? '[파]' : '[일]'}`
            item.txtMarket += ` ${odds.locale.name}`
          } else if (WOORI_POWERBALL_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
            if (item.txtMarket.length > 0) {
              item.txtMarket += ' + '
            }
            item.txtMarket += `${market.category === 'PBP' ? '[파]' : '[슈]'}`
            item.txtMarket += ` ${odds.locale.name}`
          } else if (el.tradingGroup === 'KINO') {
            if (item.txtMarket.length > 0) {
              item.txtMarket += ' + '
            }
            switch (market.category) {
              case 'KL':
                item.txtMarket += '[행]'
                break
              case 'KS':
                item.txtMarket += '[합]'
                break
            }
            item.txtMarket += ` ${odds.locale.name}`
          } else {
            if (item.txtMarket.length > 0) {
              item.txtMarket += ' + '
            }
            item.txtMarket += ` ${odds.locale.name}`
          }
        }
      }
    }
    items.push(item)
  }
  return items
}

export const MakeHistoryTrack = (records: Schema$MatchupBetRecord[]): RecordTrackItem[] => {
  const items: RecordTrackItem[] = []
  for (const el of records) {
    const item: RecordTrackItem = {
      kind: el.tradingKind,
      kindName: el.tradingKindName,
      betId: el.id,
      round: el.round,
      uniqueId: el.uniqueId,
      bettingTime: el.bettingTime,
      txtMarket: '',
      betAmount: el.betMoney,
      dividendAmount: el.dividend,
      rate: el.rate,
      state: '',
      stateColor: '',
    }
    switch (el.state) {
      case 'BET':
        item.state = '대기'
        item.stateColor = '#FBC342'
        break
      case 'WIN':
        item.state = '당첨'
        item.stateColor = '#3498DB'
        break
      case 'LOSE':
        item.state = '낙첨'
        item.stateColor = '#E74C3C'
        break
      case 'CANCEL':
        item.state = '취소'
        item.stateColor = '#27AE60'
        break
    }

    for (const el2 of el.folds) {
      let marketMeta = null
      let oddsMeta = null
      if (POWERBALL_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
        marketMeta = PowerballMarketMeta
        oddsMeta = PowerballOddsMeta
      } else if (POWERLADDER_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
        marketMeta = PowerladderMarketMeta
        oddsMeta = PowerladderOddsMeta
      } else if (WOORI_POWERBALL_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
        marketMeta = WooriKenoPowerballMarketMeta
        oddsMeta = WooriKenoPowerballOddsMeta
      } else if (el.tradingGroup === 'SG') {
        marketMeta = SquidgameMarketMeta
        oddsMeta = SquidgameOddsMeta
      } else if (el.tradingGroup === 'KINO') {
        marketMeta = KinoMarketMeta
        oddsMeta = KinoOddsMeta
      }
      if (marketMeta) {
        const market = _.find(marketMeta, o => {
          return el2.marketId === o.id
        })
        if (market) {
          const odds = _.find(oddsMeta, function(o) {
            return o.id === el2.oddsId
          })
          if (odds) {
            if (POWERBALL_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
              if (item.txtMarket.length > 0) {
                item.txtMarket += ' + '
              }
              item.txtMarket += `${market.category === 'PBP' ? '[파]' : '[일]'}`
              item.txtMarket += ` ${odds.locale.name}`
            } else if (WOORI_POWERBALL_MINIGAME_GROUP.indexOf(el.tradingGroup) >= 0) {
              if (item.txtMarket.length > 0) {
                item.txtMarket += ' + '
              }
              item.txtMarket += `${market.category === 'PBP' ? '[파]' : '[슈]'}`
              item.txtMarket += ` ${odds.locale.name}`
            } else if (el.tradingGroup === 'KINO') {
              if (item.txtMarket.length > 0) {
                item.txtMarket += ' + '
              }
              switch (market.category) {
                case 'KL':
                  item.txtMarket += '[행]'
                  break
                case 'KS':
                  item.txtMarket += '[합]'
                  break
              }
              item.txtMarket += ` ${odds.locale.name}`
            } else {
              if (item.txtMarket.length > 0) {
                item.txtMarket += ' + '
              }
              item.txtMarket += ` ${odds.locale.name}`
            }
          }
        }
      }
    }
    items.push(item)
  }
  return items
}

export class MinigameStore extends StoreExt {
  @observable
  // gameTab = MG_TABS[0].trading_kind // trading kind
  gameTab = ''

  @observable
  matchupList: { [kind: string]: Schema$Matchup } = {}

  @observable
  oddsOptions: Schema$TradingOddsOption[] = []

  @observable
  matchupSource: Schema$Matchup = null

  @observable
  matchup: MinigameMatchup = null

  @observable
  cartCollapsed = true

  @observable
  cartItems: BettingCartItem[] = []

  @observable
  dividendRate = '0.0'

  @observable
  dividend = 0

  @observable
  betMoney = 0

  @observable
  volume = 0

  @observable
  message = ''

  @observable
  lastBetRecord = null

  @observable
  lastMatchupOutcome: { [tradingKind: string]: Schema$MatchupInfo } = {}

  constructor() {
    super()

    makeObservable(this)
  }

  @computed
  get getMatchupSource() {
    return this.matchupSource
  }

  @computed
  get getMatchup() {
    return this.matchup
  }

  @computed
  get getGameTab() {
    return this.gameTab
  }

  resetMessage = () => {
    this.message = ''
  }

  @action
  setMessage = (message: string) => {
    this.message = message
  }

  @action
  reset = () => {
    this.cartItems = []
    this.betMoney = 0
    this.cartCollapsed = true
    this.setLastBetRecord(null)
    this.updateCartValues()
  }

  @action
  resetBet = () => {
    this.cartItems = []
    this.betMoney = 0
    this.cartCollapsed = true
    this.updateCartValues()
  }

  @action
  setLastBetRecord = (lastBetRecord: Schema$MatchupBetRecord) => {
    this.lastBetRecord = lastBetRecord
  }

  @action
  setLastMatchupOutcome = (info: Schema$MatchupInfo) => {
    const clone = _.cloneDeep(this.lastMatchupOutcome)
    clone[info.tradingKind] = info
    this.lastMatchupOutcome = clone
  }

  @action
  setGameTab = (gameTab: string) => {
    if (this.gameTab !== gameTab) {
      this.gameTab = gameTab
      this.reset()
      this.fetchMatchup()
      this.setBetMoney(0)
    }
  }

  @action
  updateMatchup = (command: string, matchup: Schema$Matchup) => {
    if (!matchup) {
      return
    }
    const lastMatchup = _.get(this.matchupList, matchup.trading_kind)
    if (!lastMatchup || lastMatchup.id <= matchup.id) {
      this.matchupList[matchup.trading_kind] = matchup
    }

    this.fetchMatchup()

    if (command === 'bet:start') {
      this.setMessage(`command:bet:start:${matchup.trading_kind}`)
    } else if (command === 'bet:end') {
      this.setMessage(`command:bet:end:${matchup.trading_kind}`)
    }
  }

  @action
  removeMatchup = (tradingId: number) => {
    const omitted = _.omitBy(this.matchupList, o => {
      return o.id === tradingId
    })
    this.matchupList = omitted
    this.fetchMatchup()
  }

  @action
  updateMatchupList = (matchupList: Schema$Matchup[]) => {
    for (const el of matchupList) {
      const lastMatchup = _.get(this.matchupList, el.trading_kind)
      if (!lastMatchup || lastMatchup.id <= el.id) {
        this.matchupList[el.trading_kind] = el
      }
    }
    this.fetchMatchup()
  }

  @action
  updateOddsOptions = (oddsOptions: Schema$TradingOddsOption[]) => {
    this.oddsOptions = [...oddsOptions]
    this.fetchMatchup()
    this.resetBet()
  }

  @action
  setMatchup = (matchup: MinigameMatchup) => {
    this.matchup = matchup
  }

  @action
  fetchMatchup = () => {
    this.matchupSource = _.get(this.matchupList, this.gameTab)
    if (this.matchupSource) {
      let baseOutcome = []
      let marketMeta = {}
      let oddsMeta = {}

      if (POWERBALL_MINIGAME_GROUP.indexOf(this.matchupSource.trading_group) >= 0) {
        marketMeta = PowerballMarketMeta
        oddsMeta = PowerballOddsMeta
      } else if (POWERLADDER_MINIGAME_GROUP.indexOf(this.matchupSource.trading_group) >= 0) {
        marketMeta = PowerladderMarketMeta
        oddsMeta = PowerladderOddsMeta
      } else if (WOORI_POWERBALL_MINIGAME_GROUP.indexOf(this.matchupSource.trading_group) >= 0) {
        marketMeta = WooriKenoPowerballMarketMeta
        oddsMeta = WooriKenoPowerballOddsMeta
      } else if (this.matchupSource.trading_group === 'SG') {
        marketMeta = SquidgameMarketMeta
        oddsMeta = SquidgameOddsMeta
      } else if (this.matchupSource.trading_group === 'KINO') {
        marketMeta = KinoMarketMeta
        oddsMeta = KinoOddsMeta
      }

      let id = 0
      let round = 0
      let uniqueId = 0
      if (this.matchupSource.minigame) {
        id = this.matchupSource.minigame.id
        round = this.matchupSource.minigame.round
        uniqueId = this.matchupSource.minigame.uniqueId
      }
      const tmps = _.filter(this.oddsOptions, o => {
        return o.trading_group === this.matchupSource.trading_group
      })
      const filteredOddsOption = []
      for (const el of tmps) {
        filteredOddsOption.push(toJS(el))
      }

      const matchup = MakeTestMatchup(
        this.matchupSource.trading_group,
        id,
        round,
        uniqueId,
        filteredOddsOption,
        marketMeta,
        oddsMeta,
      )

      this.setMatchup(matchup)
    }
  }

  @action
  setBetMoney = (volume: number) => {
    this.betMoney = volume
    this.volume = volume
    this.updateCartValues()
  }

  @action
  setCartCollapsed = (collapsed: boolean) => {
    this.cartCollapsed = collapsed
  }

  @action
  toggleCartItem = (tradingKind: string, marketGroup: number, marketId: string, oddsId: string) => {
    const itemFound = _.find(this.cartItems, function(o) {
      return o.marketGroup === marketGroup && o.marketId === marketId && o.oddsId === oddsId
    })
    if (itemFound) {
      this.removeCartItem(marketGroup, marketId, oddsId)
    } else {
      this.addCartItem(tradingKind, marketGroup, marketId, oddsId)
    }
  }

  @action
  addCartItem = (tradingKind: string, marketGroup: number, marketId: string, oddsId: string) => {
    if (!_.has(this.matchup.markets, marketId)) {
      return
    }

    const market = this.matchup.markets[marketId]

    const odds = _.find(market.odds, function(o) {
      return o.id === oddsId
    })

    if (!odds) {
      return
    }

    // TODO: 베팅 조합 검사
    if (this.cartItems.length > 1) {
    }

    const filtered = _.filter(this.cartItems, function(o) {
      return o.marketGroup !== marketGroup
    })

    let GetOddsColor: any = null
    // if (tradingKind === 'PB') {
    //   GetOddsColor = GetPowerballOddsColor
    // } else if (tradingKind === 'PL') {
    //   GetOddsColor = GetPowerladderOddsColor
    // }

    let marketShortName = ''
    if (market.category === 'PBP') {
      marketShortName = '[파]'
    } else if (market.category === 'PBN') {
      marketShortName = '[일]'
    } else if (market.category === 'PBS') {
      marketShortName = '[슈]'
    } else if (market.category === 'KL') {
      marketShortName = '[행]'
    } else if (market.category === 'KS') {
      marketShortName = '[합]'
    }

    const item: BettingCartItem = {
      tradingKind,
      marketGroup,
      matchupId: '',
      marketId,
      oddsId,
      round: this.matchup.round,
      uniqueId: this.matchup.uniqueId,
      market: marketShortName,
      marketColor: GetOddsColor ? GetOddsColor(market.category, marketGroup, marketId, oddsId) : '',
      odds: odds.locale.name,
      rate: odds.rate,
    }
    filtered.push(item)

    // 마켓 그룹넘버 오더를 유지
    const lastCount = this.cartItems.length
    this.cartItems = _.sortBy(filtered, ['marketGroup'])

    if (this.cartItems.length > 0 && this.cartItems.length != lastCount) {
      this.cartCollapsed = false
    }

    this.updateCartValues()
  }

  @action
  removeCartItem = (marketGroup: number, marketId: string, oddsId: string) => {
    this.cartItems = _.filter(this.cartItems, function(o) {
      return o.marketGroup !== marketGroup && o.marketId !== marketId && o.oddsId !== oddsId
    })
    if (this.cartItems.length === 0) {
      this.cartCollapsed = true
    }

    this.updateCartValues()
  }

  @action
  removeCartItemAll = () => {
    this.cartCollapsed = true
    this.cartItems = []
    this.updateCartValues()
  }

  @action
  updateCartValues = () => {
    let rateList: string[] = []

    for (const el of this.cartItems) {
      rateList.push(el.rate)
    }

    this.dividendRate = rateMultiplied(rateList)
    this.dividend = dividendFixed(this.dividendRate, this.betMoney)
  }

  @action
  bet = async (intl: IntlShape) => {
    try {
      if (this.cartItems.length === 0) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.choose_bet',
        })
        return false
      }
      if (this.betMoney === 0) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.enter_bet_amount',
        })
        return false
      }

      // TODO: 베팅 최소/최대 금액 검사
      if (this.betMoney < 5000) {
        globalStore.pushDialogLocaleOk({
          text: 'msg.format.min_bet_amount',
          arg1: '5000',
        })
        return false
      }

      globalStore.pushDialogLocaleWait({
        text: 'msg.betting_ing',
      })

      const params: IBet.Params = {
        tradingId: this.matchupSource.id,
        tradingGroup: this.matchupSource.trading_group,
        tradingKind: this.matchupSource.trading_kind,
        folds: [],
        dividendRate: this.dividendRate,
        dividend: this.dividend,
        betMoney: this.betMoney,
      }
      for (const el of this.cartItems) {
        const fold: Schema$MatchupBetFold = {
          tradingId: 0,
          matchupId: el.matchupId,
          marketId: el.marketId,
          oddsId: el.oddsId,
          oddsRate: el.rate,
        }
        params.folds.push(fold)
      }

      const { record, userMoney } = await this.api.game.betMinigame(params)

      await delay(500)

      globalStore.pushDialogLocaleOk({
        text: 'msg.betting_done',
      })

      userStore.setUserMoney(userMoney)
      this.reset()
      this.setLastBetRecord(record)

      return true
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
      await this.reset()
    }

    return false
  }
}

export default new MinigameStore()
