// import { MarketGroup } from './market_group_types'
// export const FOOTBALL_PREMATCH_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const FOOTBALL_PREMATCH_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      { id: 1, unifiedName: '1X2', name: { en: '1X2', ko: '승무패' } },
      {
        id: 50,
        unifiedName: '1X2',
        name: { en: '1X2 Including Overtime', ko: '승무패 (연장포함)' },
      },
      { id: 52, unifiedName: '12', name: { en: '12', ko: '승패' } },
      { id: 226, unifiedName: '12', name: { en: '12 Including Overtime', ko: '승패 (연장포함)' } },
      { id: 3, unifiedName: 'HDP', name: { en: 'Asian Handicap', ko: '핸디캡 *' } },
      {
        id: 342,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap Including Overtime', ko: '핸디캡 (연장포함) *' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'Asian',
        },
        id: 835,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over', ko: '총 득점 *' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'Asian',
        },
        id: 1061,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over Including Overtime', ko: '총 득점 (연장포함) *' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'European',
        },
        id: 2,
        unifiedName: 'UO',
        name: { en: 'Under/Over', ko: '총 득점' },
      },
      {
        exclusive: {
          group: 'UO',
          id: 'European',
        },
        id: 28,
        unifiedName: 'UO',
        name: { en: 'Under/Over Including Overtime', ko: '총 득점 (연장포함)' },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: '1st Half', ko: '전반전' },
    markets: [
      {
        id: 41,
        unifiedName: '1X2',
        name: { en: '1st Period Winner', ko: '승무패 (전반전)' },
        period: 10,
      },
      {
        id: 202,
        unifiedName: '12',
        name: { en: '1st Period Winner Home/Away', ko: '승패 (전반전)' },
        period: 10,
      },
      {
        id: 64,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 1st Period', ko: '핸디캡 (전반전) *' },
      },
      {
        id: 836,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over 1st Period', ko: '총 득점 (전반전) *' },
        period: 10,
      },
      {
        id: 153,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period - Home Team', ko: '총 득점 - 홈팀 (전반전)' },
        period: 10,
      },
      {
        id: 155,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period - Away Team', ko: '총 득점 - 원정팀 (전반전)' },
        period: 10,
      },
      {
        id: 129,
        unifiedName: 'UO',
        name: { en: 'Under/Over Corners - 1st Half', ko: '총 코너 (전반전)' },
        period: 10,
      },
      {
        id: 134,
        unifiedName: 'LIST1',
        name: { en: 'Number of Goals 1st Half', ko: '총 득점 일치 (전반전)' },
        period: 10,
      },
      {
        id: 145,
        unifiedName: 'LIST1',
        name: { en: 'Home Team Number Of Goals In 1st Half', ko: '총 득점 일치 - 홈팀 (전반전)' },
        period: 10,
      },
      {
        id: 146,
        unifiedName: 'LIST1',
        name: { en: 'Away Team Number Of Goals In 1st Half', ko: '총 득점 일치 - 원정팀 (전반전)' },
        period: 10,
      },
      {
        id: 113,
        unifiedName: 'LIST2',
        name: { en: 'Both Teams To Score 1st Half', ko: '양팀 득점 (전반전)' },
        period: 10,
      },
      {
        id: 9,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score 1st Period', ko: '정확한 점수 (전반전)' },
        period: 10,
      },
      {
        id: 72,
        unifiedName: 'LIST2',
        name: { en: '1st Period Odd/Even', ko: '홀/짝 총 득점 (전반전)' },
        period: 10,
      },
      {
        id: 456,
        unifiedName: 'LIST1',
        name: { en: 'Double Chance 1st Period', ko: '더블 찬스 (전반전)' },
        period: 10,
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: '2nd Half', ko: '후반전' },
    markets: [
      {
        id: 73,
        unifiedName: 'LIST2',
        name: { en: '2nd Period Odd/Even', ko: '홀/짝 총 득점 (후반전)' },
      },
    ],
  },
  {
    groupId: 4,
    groupName: { en: 'Booking', ko: '부킹' },
    markets: [],
  },
  {
    groupId: 5,
    groupName: { en: 'Corners', ko: '코너킥' },
    markets: [
      {
        id: 95,
        unifiedName: 'HDP',
        name: { en: 'Corners Handicap', ko: '핸디캡 코너킥' },
      },
      {
        id: 11,
        unifiedName: 'UO',
        name: { en: 'Total Corners', ko: '총 코너킥' },
      },
    ],
  },
  {
    groupId: 6,
    groupName: { en: 'Team Specials', ko: '팀 스페셜' },
    markets: [
      {
        id: 101,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Home Team', ko: '총 득점 - 홈팀' },
      },
      {
        id: 102,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Away Team', ko: '총 득점 - 원정팀' },
      },
      {
        id: 221,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Home Team Including Overtime', ko: '총 득점 - 홈팀 (연장포함)' },
      },
      {
        id: 220,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over - Away Team Including Overtime',
          ko: '총 득점 - 원정팀 (연장포함)',
        },
      },
      {
        id: 149,
        unifiedName: 'LIST1',
        name: { en: 'Home Team Number Of Goals', ko: '총 득점 일치 - 홈팀' },
      },
      {
        id: 150,
        unifiedName: 'LIST1',
        name: { en: 'Away Team Number Of Goals', ko: '총 득점 일치 - 원정팀' },
      },
      {
        id: 17,
        unifiedName: 'LIST2',
        name: { en: 'Both Teams To Score', ko: '양팀 득점' },
      },
      {
        id: 23,
        unifiedName: 'LIST2',
        name: { en: 'Home Team to Score', ko: '홈팀 득점' },
      },
      {
        id: 22,
        unifiedName: 'LIST2',
        name: { en: 'Away Team to Score', ko: '원정팀 득점' },
      },
      {
        id: 198,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even - Home Team', ko: '홀/짝 총 득점 - 홈팀' },
      },
      {
        id: 199,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even - Away Team', ko: '홀/짝 총 득점 - 원정팀' },
      },
      {
        id: 97,
        unifiedName: 'LIST2',
        name: { en: 'Home Team Win To Nil', ko: '무실점 승 - 홈팀' },
      },
      {
        id: 96,
        unifiedName: 'LIST2',
        name: { en: 'Away Team Win To Nil', ko: '무실점 승 - 원정팀' },
      },
    ],
  },
  {
    groupId: 7,
    groupName: { en: 'Player Specials', ko: '플레이어 스페셜' },
    markets: [],
  },
  {
    groupId: 8,
    groupName: { en: 'Etc', ko: '기타' },
    markets: [
      {
        id: 4,
        unifiedName: 'LIST1',
        name: { en: 'HT/FT', ko: '전반/후반' },
      },
      {
        id: 5,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even', ko: '홀/짝 총 득점' },
      },
      {
        id: 6,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score', ko: '정확한 점수' },
      },
      {
        id: 7,
        unifiedName: 'LIST1',
        name: { en: 'Double Chance', ko: '더블 찬스' },
      },
      {
        id: 128,
        unifiedName: 'LIST1',
        name: { en: 'Number of Goals', ko: '총 득점 일치' },
      },
      {
        id: 239,
        unifiedName: 'LIST1',
        name: { en: 'Goals Range', ko: '총 득점 범위' },
      },
      {
        id: 16,
        unifiedName: 'LIST1',
        name: { en: 'First Team To Score', ko: '첫 득점 팀' },
      },
    ],
  },
]
