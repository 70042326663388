import { css, keyframes, Theme, ThemeProps } from '@emotion/react'
//import styled from '@emotion/styled'
import { styled } from '@mui/material/styles'
import { Button } from '@mui/material'

// button animation
export const ButtonHoverAni = keyframes`
  0% {
      transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  }
  35% {
    transform: translate3d(-50%, -50%, 0) scale3d(50, 50, 50);
  }
  100%  {
    transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 0);
  }
`

// /////////////////////////////////////////// button /////////////////////////////////////////////
// 공통 버튼 스타일
export const ButtonReset = (props: ThemeProps) => css`
  position: relative;
  text-align: center;
  border: none;
  box-shadow: none;
  background: transparent;
  border-radius: 0.25rem;
  font-size: 1.1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
`

// 기본 설정 버튼 (main, secondary, outline, icon)
export const BasicButton = styled(Button)<ThemeProps>`
  ${ButtonReset};

  // 아이콘 버튼
  display: ${props => (props.icon ? 'flex' : 'block')};
  align-items: ${props => (props.icon ? 'center' : 'unset')};
  min-width: ${props => (props.icon ? '0' : '130px')};
  padding: ${props => (props.icon ? '0' : '0.795rem 0.75rem')};

  &.btn_sm {
    padding: 0.2rem 0.6rem;
    font-size: 0.9rem;
    border-radius: 0.3rem;
  }

  &.btn_xl {
    padding: 0.925rem 1rem;
    font-size: 1.25rem;
    border-radius: 0.3rem;
  }

  &.btn_main {
    border: 1px solid transparent;
    background-color: ${props => props.theme.buttons.primary};
    color: ${props => props.theme.colors.btnTxtLighten};

    &:hover {
      background-color: ${props => props.theme.buttons.primaryHover};
    }
  }

  &.btn_outline_main {
    border: 1px solid ${props => props.theme.buttons.primary};
    background-color: transparent;
    color: ${props => props.theme.buttons.primary};

    &:hover {
      border-color: ${props => props.theme.buttons.primary};
      background-color: ${props => props.theme.buttons.primary};
      color: ${props => props.theme.colors.btnTxtLighten};
    }
  }

  &.btn_default {
    border: 1px solid transparent;
    background-color: #3B4C6E;
    color: #fff;

    &:hover {
      background-color: #2A3957;
    }
  }

  &.btn_outline_sub {
    border: 1px solid ${props => props.theme.buttons.danger};
    background-color: none;
    color: ${props => props.theme.buttons.danger};

    &:hover {
      border-color: ${props => props.theme.buttons.danger};
      background-color: ${props => props.theme.buttons.danger};
      color: ${props => props.theme.colors.btnTxtLighten};
    }
  }

  &.btn_sub {
    border: 1px solid ${props => props.theme.buttons.default};
    background-color: ${props => props.theme.buttons.default};
    color: #fff;

    &:hover {
      border-color: ${props => props.theme.buttons.defaultHover};
      background-color: ${props => props.theme.buttons.defaultHover};
      color: #fff;
    }
  }

  &.btn_translucent {
    border: 1px solid ${props => props.theme.colors.themeTxt};
    background-color: rgb(255 255 255 / 10%);
    color: ${props => props.theme.colors.themeTxt};
  }

  &.btn_betting {
    width: 100%;
    border: 1px solid transparent;
    background-color: ${props => props.theme.buttons.success};
    color: ${props => props.theme.colors.btnTxtLighten};

    & > svg {
      fill: ${props => props.theme.colors.btnTxtLighten};
    }

    &:hover {
      background-color: ${props => props.theme.buttons.successHover};
    }
  }
`

export const ItemButton = styled('button')`
  background-color: ${props => props.theme.buttons.primary};
  border: 1px solid transparent;
  border-radius: 0.3rem;
  color: #fff;
  font-size: 1.1rem;
  margin: 0.15rem;
  min-width: 62px;
  padding: 0.5rem 0;

  &:hover {
    background-color: ${props => props.theme.buttons.primaryHover};
    color: #fff;
  }
  &.clear {
    background-color: ${props => props.theme.buttons.default};

    &:hover {
      background-color: ${props => props.theme.buttons.defaultHover};
    }
  }
`

export const FilterBtnStyle = (theme: Theme) => css`
  position: absolute;
  top: 7px;
  right: -30px;
  width: 30px;
  height: 28px;
  border-radius: 13px 0 0 13px;
  svg {
    position: relative;
    top: 1px;
    width: 15px;
  }
`
/* 부가 버튼 */
// export const SecondaryButton = styled('button')`
//   ${ButtonReset};
//   color: ${props => props.theme.colors.themeTxt};
//   background: ${props => props.theme.colors.mainBg};
//   border: ${props => props.theme.borders.default}; */
// `
/* 비활성화 */
export const DisabledButton = styled('button')``
/* 타이틀 좌측 버튼 */
export const TitleButton = styled('button')`
  float: right;
  ${ButtonReset};
  padding: 0 10px;
  color: ${props => props.theme.colors.primary};
  background: transparent;
  &::before {
    display: none;
  }
  &:hover::before {
    transform: none;
  }
`
// 닫기 버튼
export const CloseBtn = (theme: Theme) => css`
  position: absolute;
  top: 0;
  right: -25px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  background: transparent;
  border: none;
  box-shadow: none;
  &::before {
    position: absolute;
    top: calc(50% - (2px));
    left: 14px;
    display: block;
    width: 15px;
    height: 2px;
    background: ${theme.colors.themeTxt};
    border: none;
    border-radius: 0;
    transform: rotate(45deg);
    opacity: 1;
    animation: none;
    content: '';
  }
  &::after {
    position: absolute;
    top: calc(50% - (2px));
    left: 14px;
    display: block;
    width: 15px;
    height: 2px;
    background: ${theme.colors.themeTxt};
    transform: rotate(-45deg);
    opacity: 1;
    content: '';
  }
  /* 풀 팝업 닫기 버튼 */
  &.close-btn {
    top: 3px;
    /* top: 5px; */
    right: 4px;
    padding: 0;
  }
  .close-txt {
    position: absolute;
    line-height: 9999;
  }
  /* 채팅 팝업 닫기 버튼 */
  &.chat {
    &.close-btn {
      top: -2px;
    }
    &::before,
    &::after {
    }
  }
  /* 서랍 메뉴 닫기 버튼 */
  &.sider-menu {
    right: -10px;
    &::before,
    &::after {
    }
  }
`

export const CheckBoxStyle = (props: ThemeProps) => css`
  /* check box */
  .ant-checkbox {
    width: 24px;
    height: 24px;
    input {
      width: inherit;
      height: inherit;
    }
    .ant-checkbox-inner {
      width: inherit;
      height: inherit;
      background: none;
      border: 4px solid ${props.theme.colors.disableTxt};
      border-radius: 50%;
    }
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${props.theme.colors.disableTxt};
  }
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: ${props.theme.colors.primary} !important;
    &::after {
      width: 8px;
      height: 14px;
      left: 28%;
      border: 2px solid ${props.theme.colors.themeTxt};
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1) translate(-80%, -40%);
    }
  }
  .ant-checkbox-checked::after {
    border-radius: 50%;
  }
`
// ///////////////////////////////// Modal button /////////////////////////////////
export const BottomBtnStyle = css`
  position: relative;
  left: -14px;
  width: calc(100% + 28px);
  height: 36px;
  border-radius: 0;
`
export const ButtonWrap = styled('div')`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  line-height: 3.6rem;
  &.modal {
    left: -14px;
    width: 100vw;
  }
`
export const PopupBtnStyle = (theme: Theme) => css`
  position: relative;
  width: 100%;
  height: 36px;
  margin-top: 14px;
  line-height: 3.6rem;
  &.one-button {
    width: 120%;
    left: -10%;
    border-radius: 0;
  }
  &.two-button {
    left: 7px;
    width: calc(100% - 14px);
    height: 28px;
    margin-top: 25px;
    line-height: 2.8rem;
    &.cancel {
      left: 0;
      width: 100%;
      background-color: ${theme.colors.primary};
    }
    /* 꽉찬 버튼 */
    &.full {
      left: 0;
      width: 60%;
      height: inherit;
      margin-top: 0;
      border-radius: 0;
      &.cancel {
        width: 40%;
      }
    }
  }
`
