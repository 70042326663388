/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Grid, Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import { string } from 'yup/lib/locale'

import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'

// css
import { FormStyle } from '~/styles/modal.style'
import { BasicButton } from '~/styles/button.style'
import { invalidateNumber, numberWithCommas, stringWithComma } from '~/utils/utils'
import apiUser from '@services/api/user'
import { IBankState, Schema$BankState } from '~/v2/protocol/bank'
import MobileCompRecord from './MobileCompRecord'

const PointStyle = styled('div')`
  .point_title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .point_box {
    padding: 16px;
    border: 1px solid #1e2b3d;
    border-radius: 3px;
    background-color: #16202f;
  }

  // 포인트 내 input 스타일 정의
  .form_label {
    margin-bottom: 4px;
  }
  .form_control {
    text-align: end;
    font-weight: bold;

    &:read-only {
      border-color: #2b384d;
      background-color: #0f151e;

      &:focus {
        outline: none;
      }
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
  }
`
export interface Props {
  style?: any
  location: any
}

interface IFormInput {
  volume: string
}

const PopupComp: React.FC<Props> = ({ style, location }) => {
  const intl = useIntl()
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const [accComp, setAccComp] = useState(0)
  const [targetComp, setTargetComp] = useState(0)
  const [targetMoney, setTargetMoney] = useState(0)
  const { globalStore, authStore, userStore } = useRootStore()
  const { currentKey, currentValue } = globalStore
  const { signedin } = authStore
  const { userMoney, userComp } = userStore
  let { balance: balanceMoney } = userMoney
  let { balance: balanceComp } = userComp
  const { initialized } = authStore

  balanceMoney = Math.floor(balanceMoney)
  balanceComp = Math.floor(balanceComp)
  let maxComp = balanceComp
  // let maxComp = 0
  // if (balanceComp) {
  //   balanceComp = Math.floor(balanceComp)
  //   maxComp = Math.floor(balanceComp / 1000) * 1000
  // }

  const modalKey = ModalKeys.menual_wonp
  const tabIndex = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = tabIndex !== ''

  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
  }

  const {
    resetField,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>()

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    async function inline() {
      try {
        const { comp } = await apiUser.getAccComp({})
        setAccComp(comp)
      } catch (err) {
        //
      }
    }
    inline()
    fetchBankState({})
  }, [])

  useEffect(() => {
    resetField('volume')
    volumeUpdated(0)
  }, [initialized, balanceMoney, balanceComp])

  const onSubmit: SubmitHandler<IFormInput> = async data => {
    const volume = invalidateNumber(data.volume)
    if (volume <= 0) {
      return
    }
    const ret = await userStore.transferComp(volume, intl)
    if (ret) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.transfer-comp-done' }),
      })
    }
  }

  const volumeUpdated = (v: number) => {
    setTargetComp(balanceComp - v)
    setTargetMoney(balanceMoney + v)
  }

  const onClickMax = () => {
    setValue('volume', stringWithComma(maxComp.toString()))
    volumeUpdated(maxComp)
  }

  const onChangeVolume = e => {
    const snumber = e.target.value.toString()
    let newVolume = 0
    if (_.isString(snumber) && snumber.length > 0) {
      newVolume = invalidateNumber(e.target.value)
      if (newVolume < 0) {
        newVolume = 0
      }
      if (newVolume > maxComp) {
        newVolume = maxComp
      }

      setValue('volume', stringWithComma(newVolume.toString()))
      volumeUpdated(newVolume)
    } else {
      resetField('volume')
      volumeUpdated(0)
    }
  }

  const min_amount = bankState ? bankState.transaction.min_to_comp_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_to_comp_unit : 1000
  const txtFormat = intl.formatMessage({ id: 'format.transfer-min-unit' })
  const transaction_text = txtFormat
    .replace('{0}', numberWithCommas(min_amount))
    .replace('{1}', numberWithCommas(min_unit))

  return (
    <Box sx={{ px: 2, mt: 3 }}>
      <PointStyle>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container columnSpacing={2} sx={{ position: 'relative' }}>
            <Grid item xs={6}>
              <Box className="point_title" sx={{ mb: 1, color: '#D1D9E3' }}>
                {intl.formatMessage({ id: 'component.point.current' })}
              </Box>
              <FormStyle className="point_box">
                <Grid container alignItems="center" sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <label className="form_label">{intl.formatMessage({ id: 'comp' })}</label>
                    <Typography className="form_control readonly">
                      {numberWithCommas(balanceComp)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <label className="form_label">{intl.formatMessage({ id: 'money' })}</label>
                    <Typography className="form_control readonly">
                      {numberWithCommas(balanceMoney)}
                    </Typography>
                  </Grid>
                </Grid>
              </FormStyle>
            </Grid>

            <Grid item xs={6}>
              <Box className="point_title" sx={{ mb: 1, color: '#2A73E1' }}>
                {intl.formatMessage({ id: 'component.comp.transfered' })}
              </Box>
              <FormStyle className="point_box">
                <Grid container alignItems="center" sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <label className="form_label">{intl.formatMessage({ id: 'comp' })}</label>
                    <Typography className="form_control readonly">
                      {numberWithCommas(targetComp)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <label className="form_label">{intl.formatMessage({ id: 'money' })}</label>
                    <Typography className="form_control readonly">
                      {numberWithCommas(targetMoney)}
                    </Typography>
                  </Grid>
                </Grid>
              </FormStyle>
            </Grid>
          </Grid>

          <FormStyle>
            <Typography sx={{ pt: 3, color: '#D1D9E3', mb: 1 / 2 }}>
              {intl.formatMessage({ id: 'component.comp.req-comp' })}
            </Typography>
            <Grid container columnSpacing={1} alignItems="stretch">
              <Grid item xs={8}>
                <input
                  {...register('volume', {
                    required: true,
                    onChange: e => onChangeVolume(e),
                  })}
                  className="form_control point"
                  placeholder={intl.formatMessage({ id: 'component.comp.input-comp' })}
                  type="text"
                  style={{ height: '100%' }}
                />
              </Grid>
              <Grid item xs={4}>
                <BasicButton
                  onClick={() => onClickMax()}
                  className="btn_outline_main"
                  sx={{ minWidth: '0', width: '100%' }}
                >
                  {intl.formatMessage({ id: 'component.comp.maximum' })}
                </BasicButton>
              </Grid>
            </Grid>
          </FormStyle>
          {errors.volume && (
            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '10px' }}>
              <span style={{ color: 'red' }}>{intl.formatMessage({ id: 'msg.enter-comp' })}</span>
            </Grid>
          )}

          <Box sx={{ pt: 1, display: 'flex', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '12px', color: 'red' }}>{transaction_text}</Typography>
          </Box>

          <Box sx={{ pt: 5, display: 'flex', justifyContent: 'center' }}>
            <BasicButton type="submit" className="btn_main" style={{ minWidth: '240px' }}>
              {intl.formatMessage({ id: 'component.comp.request-ok' })}
            </BasicButton>
          </Box>
        </form>
      </PointStyle>
      <Grid container alignItems="center" justifyContent="end" sx={{ mt: 5 }}>
        <Grid item>
          <Typography sx={{ fontSize: '1.2rem', color: '#fff', mr: 1 }}>
            {intl.formatMessage({ id: 'component.comp.accumulated' })}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ fontSize: '1.2rem', color: '#fff' }}>
            {numberWithCommas(Math.floor(accComp))}
          </Typography>
        </Grid>
      </Grid>
      <MobileCompRecord location={location}></MobileCompRecord>
    </Box>
  )
}

export default observer(PopupComp)
