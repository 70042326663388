/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Typography, Button } from '@mui/material'
import * as _ from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import { numberWithCommas, fillZero, invalidateNumber } from '~/utils/utils'
import useRootStore from '@store/useRootStore'
import { GlobalStore } from '@store/globalStore'
import BettingTimerbar from './BettingTimerbar'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import NumberFormat from 'react-number-format'
import { ModalKeys } from '@store/globalStore'

// icon
import { ReactComponent as IconTrash } from '@assets/images/layout_1/icon_trash.svg'
import { ReactComponent as IconBetting } from '@assets/images/layout_1/icon_betting.svg'

// css
import { BasicButton } from '~/styles/button.style'
import { BettingCartStyle, CartListStyle, AmountStyle } from './index.style'
import { string } from 'yup/lib/locale'
import { styled } from '@mui/material/styles'
import BettingPendings from './BettingPendings'
import { Formik } from 'formik'
import { useOnUnmount } from '~/utils/reactExt'

const CartFormStyle = styled('div')`
  .item_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const ItemButton = styled(Button)(({ theme }) => ({
  marginBottom: 8,
  backgroundColor: theme.buttons.success,
  border: '1px solid transparent',
  borderRadius: '0.3rem',
  color: '#fff',
  fontSize: '1rem',
  minWidth: '31.5%',
  padding: '0.5rem 0',

  '&:hover': {
    backgroundColor: theme.buttons.successHover,
    color: '#fff',
  },
  '&:disabled': {
    color: '#c0cad8',
  },
  '&.clear': {
    minWidth: '48.8%',
    backgroundColor: '#252f40',
  },
  '&.clear:hover': {
    backgroundColor: '#1a1e25',
  },
  '&.clear:disabled': {
    color: '#959595',
  },
}))

let timer

const s = 1000
const m = s * 60
const h = m * 60
const d = h * 24

const BettingCart: React.FC = props => {
  const intl = useIntl()
  const { globalStore, userStore, miniGameStore } = useRootStore()

  const [canBet, setCanBet] = useState(false)

  const refBlockSubmitting = useRef<boolean>(false)

  const { userMoney } = userStore
  const { matchupSource, cartCollapsed, cartItems, dividendRate, dividend, volume } = miniGameStore

  const { balance } = userMoney

  const setNextOpenTimeFunc = (dateFrom, dateTo) => {
    const now = new Date()

    const can_bet = dateFrom.getTime() < now.getTime() && now.getTime() < dateTo.getTime()
    if (canBet !== can_bet) {
      setCanBet(can_bet)
    }
  }

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    let from = new Date()
    let to = new Date()
    if (matchupSource) {
      from = new Date(matchupSource.bet_start_time)
      to = new Date(matchupSource.bet_end_time)
    }
    timer = setInterval(() => {
      setNextOpenTimeFunc(from, to)
    }, 500)
  }, [matchupSource])

  useOnUnmount(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
  })

  const onClickBettingRecord = () => {
    globalStore.showModal(ModalKeys.betting_record, 0)
  }

  const submit = async (values: any, { setSubmitting, setErrors, setStatus, resetForm }) => {
    if (refBlockSubmitting.current) {
      return
    }
    const { volume } = values
    let amount = invalidateNumber(volume)

    miniGameStore.setBetMoney(amount)

    const ret = await miniGameStore.bet(intl)
    if (ret) {
      resetForm()
    }
  }

  let oneItem = null
  if (cartItems.length === 1 && cartCollapsed) {
    const el = cartItems[0]
    oneItem = (
      <Box sx={{ display: 'flex', flex: '1 1 0', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flex: '1 1 0',
            fontSize: '1.1rem',
          }}
        >
          <div>
            <span style={{ color: el.marketColor }}>{el.market}</span>
            <span style={{ marginLeft: '0.5rem', color: '#FF5678' }}>{el.odds}</span>
            {/* <span style={{ marginLeft: '0.5rem', color: '#747D8A' }}>외 1</span> */}
          </div>
          <div>
            <span style={{ color: '#2A73E1', fontWeight: 'bold' }}>{el.rate}</span>
          </div>
        </Box>
        <BasicButton
          icon
          style={{ marginLeft: '0.5rem' }}
          onClick={() => miniGameStore.removeCartItem(el.marketGroup, el.marketId, el.oddsId)}
        >
          <IconTrash width="24" fill="#BBBDC1" />
        </BasicButton>
      </Box>
    )
  }

  const lookupColor = ['[파]', '[행]']
  const bettingItemContents = []
  for (const el of cartItems) {
    let categoryColor = lookupColor.indexOf(el.market) >= 0 ? '#5b9cfb' : '#ff5678'
    let style = {}
    _.extend(style, {
      paddingTop: bettingItemContents.length > 0 ? '4px' : '',
    })

    bettingItemContents.push(
      <li key={el.marketGroup}>
        <Box sx={{ display: 'flex', flex: '1 1 0', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flex: '1 1 0',
              fontSize: '1.1rem',
            }}
          >
            <div>
              <span style={{ color: categoryColor }}>{el.market}</span>
              <span style={{ color: el.marketColor, marginLeft: '4px' }}>{el.odds}</span>
            </div>
            <div>
              <span style={{ color: '#2E3A4D' }}>{el.rate}</span>
            </div>
          </Box>
          <BasicButton
            icon
            style={{ marginLeft: '8px' }}
            onClick={() => miniGameStore.removeCartItem(el.marketGroup, el.marketId, el.oddsId)}
          >
            <IconTrash width="24" fill="#BBBDC1" />
          </BasicButton>
        </Box>
      </li>,
    )
  }

  return (
    <BettingCartStyle>
      <Box className="bet_wrap">
        <Box columnGap={1} className="bet_title">
          <Typography component="h3">BET SLIP</Typography>
          {oneItem}
        </Box>

        <BettingTimerbar></BettingTimerbar>
        <CartListStyle>{bettingItemContents}</CartListStyle>
        <Formik
          initialValues={{
            volume: 0,
          }}
          onSubmit={submit}
          validationSchema={Yup.object().shape({
            // volume: Yup.number().required(intl.formatMessage({ id: 'msg.field-require' })),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setSubmitting,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <AmountStyle sx={{ px: 2, py: 1 }}>
                <p>
                  <span>총 배당률</span>
                  <span className="highlight">{dividendRate}</span>
                </p>
                <p>
                  <span>예상 배당금액</span>
                  <span>
                    <span className="highlight">{numberWithCommas(dividend)}</span>
                    {intl.formatMessage({ id: 'money-locale' })}
                  </span>
                </p>
                <p>
                  <span className="my_money">내 보유금액</span>
                  <span>
                    <span className="my_money">{numberWithCommas(balance)}</span>
                    {intl.formatMessage({ id: 'money-locale' })}
                  </span>
                </p>
                <div className="betting_input">
                  <div className="form_label">베팅</div>
                  <NumberFormat
                    disabled={!canBet}
                    className="form_control"
                    name="volume"
                    value={volume}
                    onChange={e => {
                      handleChange(e)
                      try {
                        const { value } = e.target
                        let tmp = invalidateNumber(value)
                        miniGameStore.setBetMoney(tmp)
                      } catch (err) {
                        console.log(err)
                      }
                    }}
                    onBlur={handleBlur}
                    thousandSeparator={true}
                    suffix="원"
                    style={{ padding: '0.35rem' }}
                  ></NumberFormat>
                </div>
              </AmountStyle>

              <CartFormStyle sx={{ px: 2, pb: 1 }}>
                <Box className="item_buttons">
                  <ItemButton
                    disabled={!canBet}
                    onClick={() => {
                      refBlockSubmitting.current = true
                      let tmp = invalidateNumber(volume)
                      tmp += 5000
                      tmp = Math.min(balance, tmp)
                      setFieldValue('volume', tmp)
                      miniGameStore.setBetMoney(tmp)
                    }}
                  >
                    5천
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    onClick={() => {
                      refBlockSubmitting.current = true
                      let tmp = invalidateNumber(volume)
                      tmp += 10000
                      tmp = Math.min(balance, tmp)
                      setFieldValue('volume', tmp)
                      miniGameStore.setBetMoney(tmp)
                    }}
                  >
                    1만
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    onClick={() => {
                      refBlockSubmitting.current = true
                      let tmp = invalidateNumber(volume)
                      tmp += 50000
                      tmp = Math.min(balance, tmp)
                      setFieldValue('volume', tmp)
                      miniGameStore.setBetMoney(tmp)
                    }}
                  >
                    5만
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    onClick={() => {
                      refBlockSubmitting.current = true
                      let tmp = invalidateNumber(volume)
                      tmp += 100000
                      tmp = Math.min(balance, tmp)
                      setFieldValue('volume', tmp)
                      miniGameStore.setBetMoney(tmp)
                    }}
                  >
                    10만
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    onClick={() => {
                      refBlockSubmitting.current = true
                      let tmp = invalidateNumber(volume)
                      tmp += 500000
                      tmp = Math.min(balance, tmp)
                      setFieldValue('volume', tmp)
                      miniGameStore.setBetMoney(tmp)
                    }}
                  >
                    50만
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    onClick={() => {
                      refBlockSubmitting.current = true
                      let tmp = invalidateNumber(volume)
                      tmp += 1000000
                      tmp = Math.min(balance, tmp)
                      setFieldValue('volume', tmp)
                      miniGameStore.setBetMoney(tmp)
                    }}
                  >
                    100만
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    className="clear"
                    onClick={() => {
                      refBlockSubmitting.current = true
                      setFieldValue('volume', balance)
                      miniGameStore.setBetMoney(balance)
                    }}
                  >
                    MAX
                  </ItemButton>
                  <ItemButton
                    disabled={!canBet}
                    className="clear"
                    onClick={() => {
                      refBlockSubmitting.current = true
                      setFieldValue('volume', 0)
                      miniGameStore.setBetMoney(0)
                    }}
                  >
                    초기화
                  </ItemButton>
                </Box>

                <Box sx={{ textAlign: 'center' }}>
                  <BasicButton
                    disabled={!canBet || isSubmitting}
                    type="submit"
                    className="btn_betting"
                    onClick={() => {
                      refBlockSubmitting.current = false
                      let tmp = invalidateNumber(values.volume)
                      setFieldValue('volume', tmp)
                    }}
                    sx={{ mt: 1 }}
                  >
                    <IconBetting width="24" />
                    베팅하기
                  </BasicButton>

                  <Button
                    onClick={onClickBettingRecord}
                    size="large"
                    sx={{
                      mt: 1,
                      mx: 'auto',
                      py: 0,
                      color: '#2366CB',
                      fontSize: '1.1rem',
                      textDecoration: 'underline',
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    베팅 내역 확인
                  </Button>
                </Box>
              </CartFormStyle>
            </form>
          )}
        </Formik>
      </Box>

      <BettingPendings></BettingPendings>
    </BettingCartStyle>
  )
}

export default observer(BettingCart)
