/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { Fragment } from 'react'
import { Typography, Button, TextField, Box, Checkbox, Input } from '@mui/material'
import { GridColumns } from '@mui/x-data-grid'
import { numberWithCommas } from '@utils/utils'
import { NumberInputStyle } from '~/containers/shared/Common'
import { darken, lighten } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import LooksOneIcon from '@mui/icons-material/LooksOne'
import Filter9PlusIcon from '@mui/icons-material/Filter9Plus'
import PropTypes from 'prop-types'

export function getMoneyPanelColumns() {
  const columns: GridColumns = [
    { field: 'id', headerName: 'id', width: 90, hide: true, hideable: false },
    {
      field: 'time',
      headerName: '시간',
      width: 180,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'type',
      headerName: '사유',
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'deposit',
      headerName: '입금',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#6170c6' }}>{params.value}</Typography>,
    },
    {
      field: 'withdraw',
      headerName: '출금',
      type: 'number',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#d73545' }}>{params.value}</Typography>,
    },
    {
      field: 'balance',
      headerName: '사용 가능 잔액',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'lock_balance',
      headerName: '사용 불가 잔액',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#b9b9b9' }}>{params.value}</Typography>,
    },
    {
      field: 'total_balance',
      headerName: '실제 잔액',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#b9b9b9' }}>{params.value}</Typography>,
    },
  ]

  return columns
}

export function getPointPanelColumns() {
  const columns: GridColumns = [
    { field: 'id', headerName: 'id', width: 90, hide: true, hideable: false },
    {
      field: 'time',
      headerName: '시간',
      width: 180,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'type',
      headerName: '사유',
      width: 150,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'deposit',
      headerName: '입금',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#6170c6' }}>{params.value}</Typography>,
    },
    {
      field: 'withdraw',
      headerName: '출금',
      type: 'number',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#d73545' }}>{params.value}</Typography>,
    },
    {
      field: 'balance',
      headerName: '사용 가능 잔액',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'lock_balance',
      headerName: '사용 불가 잔액',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#b9b9b9' }}>{params.value}</Typography>,
    },
    {
      field: 'total_balance',
      headerName: '실제 잔액',
      width: 160,
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      renderCell: params => <Typography sx={{ color: '#b9b9b9' }}>{params.value}</Typography>,
    },
  ]

  return columns
}

const InputStyle = css`
  width: 200px;
  height: auto;
  path {
    fill: #434343;
  }
`

const press_width = 230
export function getPressSettingPanelColumns() {
  const columns: GridColumns = [
    { field: 'id', headerName: 'id', width: 90, hide: true, hideable: false },
    {
      field: 'game',
      headerName: '게임',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
      hideable: false,
    },
    {
      field: 'single_fold',
      headerName: '단폴 적용',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'single_fold_per',
      headerName: '단폴 %',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <NeatTextField
          sx={{ width: '100%', background: 'white' }}
          type="number"
          inputProps={{
            min: 0,
            max: 100,
            step: 0.01,
          }}
        />
      ),
    },
    {
      field: 'multi_fold',
      headerName: '다폴 적용',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
    },
    {
      field: 'multi_fold_per',
      headerName: '다폴 %',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <NeatTextField
          sx={{ width: '100%', background: 'white' }}
          type="number"
          inputProps={{
            min: 0,
            max: 100,
            step: 0.01,
          }}
        />
      ),
    },
  ]

  return columns
}

export function getCommissionSettingColumns() {
  const columns: GridColumns = [
    { field: 'id', headerName: 'id', width: 90, hide: true, hideable: false },
    {
      field: 'game',
      headerName: '게임',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
      hideable: false,
    },
    {
      field: 'fold_type',
      headerName: '단폴 / 다폴',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => (
        <Fragment>
          {params.value === '단폴' ? <LooksOneIcon></LooksOneIcon> : <Filter9PlusIcon></Filter9PlusIcon>}
          <Typography>&nbsp;&nbsp;{params.value}</Typography>
        </Fragment>
      ),
    },
    // {
    //   field: 'apply_press',
    //   headerName: '누르기 적용',
    //   width: press_width,
    //   sortable: false,
    //   headerAlign: 'center',
    //   align: 'center',
    //   renderCell: params => (
    //     <Checkbox disabled={!canEdit} sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }} defaultChecked />
    //   ),
    // },
    {
      field: 'empty',
      headerName: '#',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'losing',
      headerName: '루징 %',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: params => params.value,
    },
    {
      field: 'commission',
      headerName: '롤링 %',
      width: press_width,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      type: 'number',
      renderCell: params => params.value,
    },
  ]

  return columns
}

export const CommissionSettingRows = [
  {
    id: 0,
    game: '라이브 카지노',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'LCA',
  },
  {
    id: 1,
    game: '호텔 카지노',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'HCA',
  },
  {
    id: 2,
    game: '슬롯게임',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'SL',
  },
  {
    id: 3,
    game: '국내스포츠',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'SP',
  },
  {
    id: 4,
    game: '국내스포츠',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'SP',
  },
  {
    id: 5,
    game: '해피545파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'HPB',
  },
  {
    id: 6,
    game: '해피545파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'HPB',
  },
  {
    id: 7,
    game: '엔트리파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'NPB',
  },
  {
    id: 8,
    game: '엔트리파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'NPB',
  },
  {
    id: 9,
    game: '동행파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'PB',
  },
  {
    id: 10,
    game: '동행파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'PB',
  },
  {
    id: 11,
    game: '동행파워사다리',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'PL',
  },
  {
    id: 12,
    game: '동행파워사다리',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'PL',
  },
  {
    id: 13,
    game: 'PBG 파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'PBG',
  },
  {
    id: 14,
    game: 'PBG 파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'PBG',
  },
  {
    id: 15,
    game: 'EOS 파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'EOS',
  },
  {
    id: 16,
    game: 'EOS 파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'EOS',
  },
  {
    id: 17,
    game: '오징어게임',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'SG',
  },
  {
    id: 18,
    game: '오징어게임',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'SG',
  },
  {
    id: 19,
    game: '코인파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'CPB',
  },
  {
    id: 20,
    game: '코인파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'CPB',
  },
  {
    id: 21,
    game: '코인사다리',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'CL',
  },
  {
    id: 22,
    game: '코인사다리',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'CL',
  },
  {
    id: 23,
    game: '우리볼BTC파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRBTCPB',
  },
  {
    id: 24,
    game: '우리볼BTC파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRBTCPB',
  },
  {
    id: 25,
    game: '우리볼BTC사다리',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRBTCPL',
  },
  {
    id: 26,
    game: '우리볼BTC사다리',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRBTCPL',
  },
  {
    id: 27,
    game: '우리볼BEX파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRBEXPB',
  },
  {
    id: 28,
    game: '우리볼BEX파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRBEXPB',
  },
  {
    id: 29,
    game: '동행키노플러스파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRKENOPB',
  },
  {
    id: 30,
    game: '동행키노플러스파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRKENOPB',
  },
  {
    id: 31,
    game: '동행키노사다리',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRKENOPL',
  },
  {
    id: 32,
    game: '동행키노사다리',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRKENOPL',
  },
  {
    id: 33,
    game: '동행키노파워볼',
    fold_type: '단폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRKENO',
  },
  {
    id: 34,
    game: '동행키노파워볼',
    fold_type: '다폴',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'WRKENO',
  },
]

// const getBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6))
// const getHoverBackgroundColor = (color, mode) => (mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5))
const getBackgroundColor = (color, mode) => {
  return lighten(color, 0.8)
}

export const CommissionSettingStyle = {
  height: 400,
  width: 1,
  '& .super-app-theme--LCA': {
    bgcolor: theme => getBackgroundColor('#8cceff', theme.palette.mode),
  },
  '& .super-app-theme--HCA': {
    bgcolor: theme => getBackgroundColor('#8cceff', theme.palette.mode),
  },
  '& .super-app-theme--MSL': {
    bgcolor: theme => getBackgroundColor('#b4e4b6', theme.palette.mode),
  },
  '& .super-app-theme--SL': {
    bgcolor: theme => getBackgroundColor('#b4e4b6', theme.palette.mode),
  },
  '& .super-app-theme--SP': {
    bgcolor: theme => getBackgroundColor('#ffc384', theme.palette.mode),
  },
  '& .super-app-theme--MG': {
    bgcolor: theme => getBackgroundColor('#ffaba5', theme.palette.mode),
  },
}

export function getDividendSettingColumns() {
  const columns: GridColumns = [
    { field: 'id', headerName: 'id', width: 90, hide: true, hideable: false },
    {
      field: 'game',
      headerName: '게임',
      width: 450,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <Typography>{params.value}</Typography>,
      hideable: false,
    },
    {
      field: 'data',
      headerName: '배당률(x)',
      width: 700,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: params => <></>,
    },
  ]
  return columns
}

export const DividendSettingRows = [
  {
    id: 0,
    game: '홀짝(언더오버)',
    data: [{ market: '홀짝(언더오버)', rate: 1.95 }],
    modify: false,
    losing: 0,
    commission: 0,
    status: 'LCA',
  },
  {
    id: 0,
    game: '홀짝(언더오버)',
    data: [{ market: '홀짝(언더오버)', rate: 1.95 }],
    modify: false,
    losing: 0,
    commission: 0,
    status: 'HCA',
  },
  {
    id: 1,
    game: '홀짝/언더오버',
    data: 'data',
    modify: false,
    losing: 0,
    commission: 0,
    status: 'SL',
  },
]

//---------------------------------------
export const useTextFieldStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  textFieldWithoutArrow: {
    // marginLeft: theme.spacing.unit,
    // marginRight: theme.spacing.unit,
    // width: 200,
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

  cssLabel: {
    color: 'green',
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: 'blue', //focused
      borderWidth: '1px',
    },
  },
  notchedOutline: {},
  cssFocused: {},
  error: {},
  // disabled: {},
}))

export function NeatTextField(props) {
  const { children, type, InputProps, inputProps, hideArrow, ...other } = props
  const classes = useTextFieldStyles()
  return (
    <TextField
      size="small"
      id="standard-basic"
      label=""
      type={type || 'text'}
      className={hideArrow ? classes.textFieldWithoutArrow : classes.textField}
      InputLabelProps={{
        shrink: false,
      }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
        },
        ...InputProps,
      }}
      inputProps={inputProps}
      {...other}
    >
      {children}
    </TextField>
  )
}
NeatTextField.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any,
  placeholder: PropTypes.any,
  defaultValue: PropTypes.any,
  type: PropTypes.string,
  select: PropTypes.bool,
  InputProps: PropTypes.any,
  inputProps: PropTypes.any,
  onChange: PropTypes.func,
  sx: PropTypes.any,
  id: PropTypes.string,
  variant: PropTypes.string,
  hideArrow: PropTypes.bool,
  multiline: PropTypes.bool,
}
