/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { Fragment, useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as _ from 'lodash'

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

import useRootStore from '~/store/useRootStore'
import {
  Schema$PartnerMemeberItem,
  Schema$CommissionModifiedItem,
  Schema$CommissionConfigItem,
} from '@protocol/partner'
import { CommissionSettingStyle } from './index.component'
import apiPartner from '@services/api/partner'
import { ALL_GAME_INFO } from '~/v2/interface/config'
import { useIntl } from 'react-intl'

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

function createData(
  id: string,
  game: string,
  fold_type: string,
  modify: boolean,
  losing_rate: number,
  commission_rate: number,
  parent_losing_rate: number,
  parent_commission_rate: number,
  status: string,
  canEditLosing: boolean,
  canEditCommission: boolean,
  canShowMax: boolean,
) {
  return {
    id,
    game,
    fold_type,
    modify,
    losing_rate,
    commission_rate,
    parent_losing_rate,
    parent_commission_rate,
    status,
    canEditLosing,
    canEditCommission,
    canShowMax,
  }
}

function Head(canShowMax: boolean) {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>게임</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>단폴 / 다폴</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>#</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>
            {canShowMax ? '루징 % / 최대' : '루징 %'}
          </Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>
            {canShowMax ? '롤링 % / 최대' : '롤링 %'}
          </Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: {
  row: ReturnType<typeof createData>
  handleChange: (event: any, id: string, name: string) => void
}) {
  const { row, handleChange } = props

  const rowClass = `super-app-theme--${row.status}`

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={rowClass}>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.game}</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.fold_type}</Typography>
        </MyTableCell>
        <MyTableCell align="center"></MyTableCell>
        <MyTableCell align="center">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input
              disabled={!row.canEditLosing}
              className="form_control"
              type="number"
              defaultValue={Math.floor(row.losing_rate)}
              onChange={event => handleChange(event, row.id, 'losing')}
              min={0}
              max={100}
              step={1}
              style={{ textAlign: 'right', fontSize: '14px', minWidth: '150px' }}
            />
            <Typography align="left" sx={{ pl: 2, pt: '8px', width: '80px', fontSize: '11px' }}>
              {row.canShowMax ? `/ ${row.parent_losing_rate}` : ''}
            </Typography>
          </Box>
        </MyTableCell>
        <MyTableCell align="center">
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <input
              disabled={!row.canEditCommission}
              className="form_control"
              type="number"
              defaultValue={row.commission_rate.toFixed(2)}
              onChange={event => handleChange(event, row.id, 'commission')}
              min={0}
              max={100}
              step={0.01}
              style={{ textAlign: 'right', fontSize: '14px', minWidth: '150px' }}
            />
            <Typography align="left" sx={{ pl: 2, pt: '8px', width: '80px', fontSize: '11px' }}>
              {row.canShowMax ? `/ ${row.parent_commission_rate}` : ''}
            </Typography>
          </Box>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

interface Props {
  memberInfo: Schema$PartnerMemeberItem
}

function PanelCommissionSetting({ memberInfo }: Props) {
  const intl = useIntl()
  const [modifyMode, setModifyMode] = useState('')
  const [commissionItems, setCommissionItems] = useState<Schema$CommissionConfigItem[]>([])

  const refModified = useRef<{ [id: string]: { losing?: string; commission?: string } }>({})

  const { userStore, globalStore } = useRootStore()
  const { userInfo } = userStore
  const { option } = globalStore
  const { open_trading_groups } = option || {}

  // const isMaster = userInfo && userInfo.merchant_class <= 1

  const canEdit =
    userInfo &&
    memberInfo &&
    userInfo.uuid !== memberInfo.uuid &&
    userInfo.uuid === memberInfo.merchant_uuid &&
    memberInfo.class >= userInfo.merchant_class &&
    memberInfo.class > 1

  const fetchCommissionSetting = async () => {
    try {
      const { commission_modify_mode, items } = await apiPartner.getCommissionInfo({
        uuid: memberInfo.uuid,
      })
      setModifyMode(commission_modify_mode)
      setCommissionItems([...items])
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const saveCommissionSetting = async (mitems: Schema$CommissionModifiedItem[]) => {
    try {
      const { items } = await apiPartner.saveCommissionInfo({
        uuid: memberInfo.uuid,
        items: [...mitems],
      })
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.edited' }),
      })
      setCommissionItems([])
      setCommissionItems([...items])
      refModified.current = {}
    } catch (err) {
      globalStore.pushErrorObject(err, intl)

      const cloned = _.clone(commissionItems)
      setCommissionItems([])
      setCommissionItems([...cloned])
      refModified.current = {}
    }
  }

  useEffect(() => {
    fetchCommissionSetting()
  }, [])

  const onClickSave = () => {
    if (Object.keys(refModified.current).length === 0) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.nothing_changed' }),
      })
    } else {
      const items: Schema$CommissionModifiedItem[] = []
      for (const [k, v] of Object.entries(refModified.current)) {
        const [group, odds, fold] = k.split(':')
        const { losing, commission } = v
        const item: Schema$CommissionModifiedItem = {
          trading_group: group,
          odds_type: odds,
          fold_type: fold,
        }
        if (losing) {
          item.losing_rate = Number(losing)
        }
        if (commission) {
          item.commission_rate = Number(commission)
        }
        items.push(item)
      }
      saveCommissionSetting(items)
    }
  }

  const handleChange = (event, id, name) => {
    const { target } = (event as any) || { target: { value: '0.00' } }
    const { value } = target
    if (_.isString(value)) {
      const found = _.get(refModified.current, id)
      if (!found) {
        refModified.current[id] = {}
      }
      refModified.current[id][name] = value
    }
  }

  // commission
  function makeRowId(data: Schema$CommissionConfigItem) {
    return `${data.trading_group}:${data.odds_type}:${data.fold_type}`
  }

  function makeRow(
    i: number,
    name: string,
    data: Schema$CommissionConfigItem,
    is_lock_any: boolean,
  ) {
    // const canEditableLosingGroup =
    //   ['LCA', 'HCA', 'MSL', 'SL', 'PB', 'PL', 'HPB', 'NPB', 'CPB', 'CL', 'SG', 'PBG', 'EOS', 'SP'].indexOf(
    //     data.trading_group,
    //   ) >= 0
    // const canEditableCommissionGroup =
    //   ['LCA', 'HCA', 'MSL', 'SL', 'PB', 'PL', 'HPB', 'NPB', 'CPB', 'CL', 'SG', 'PBG', 'EOS', 'SP'].indexOf(
    //     data.trading_group,
    //   ) >= 0
    // const canEditLosing = canEdit && canEditableLosingGroup && (modifyMode === 'ALWAYS' || !is_lock_any)
    // const canEditCommission = canEdit && canEditableCommissionGroup && (modifyMode === 'ALWAYS' || !is_lock_any)
    const canEditLosing = canEdit && (modifyMode === 'ALWAYS' || !is_lock_any)
    const canEditCommission = canEdit && (modifyMode === 'ALWAYS' || !is_lock_any)
    const canShowMax = canEdit
    const rowId = makeRowId(data)
    const foldType = data.fold_type === 'SINGLE' ? '단폴' : '다폴'
    const row = createData(
      rowId,
      name,
      foldType,
      false,
      data.losing_rate,
      data.commission_rate,
      data.parent_losing_rate,
      data.parent_commission_rate,
      data.trading_group,
      canEditLosing,
      canEditCommission,
      canShowMax,
    )
    return <Row key={row.id} row={row} handleChange={handleChange} />
  }
  const commissionRows = []
  if (commissionItems.length > 0) {
    const lockList = _.filter(commissionItems, o => {
      return o.is_lock
    })
    for (let i = 0; i < ALL_GAME_INFO.length; i++) {
      const idx = _.findIndex(open_trading_groups, o => {
        return o.trading_group === ALL_GAME_INFO[i].group
      })
      if (idx >= 0) {
        const el = ALL_GAME_INFO[i]
        const single = _.find(commissionItems, o => {
          return el.group === o.trading_group && o.fold_type === 'SINGLE'
        })
        const multi = _.find(commissionItems, o => {
          return el.group === o.trading_group && o.fold_type === 'MULTI'
        })
        if (single) {
          commissionRows.push(makeRow(i, el.name, single, lockList.length > 0))
        }
        if (multi) {
          commissionRows.push(makeRow(i, el.name, multi, lockList.length > 0))
        }
      }
    }
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table" sx={CommissionSettingStyle}>
          {Head(canEdit)}
          <TableBody>{commissionRows}</TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}
      >
        {canEdit && (
          <Button variant="contained" sx={{ background: '#007f93' }} onClick={() => onClickSave()}>
            저장
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default observer(PanelCommissionSetting)
