import React, { useState, useEffect } from 'react'
import { jsx, css, keyframes } from '@emotion/react'
import * as _ from 'lodash'
import {
  Grid,
  Box,
  AppBar,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
} from '@mui/material'
import Drawer from '@mui/material/Drawer'
import Badge from '@mui/material/Badge'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { ModalKeys } from '@store/globalStore'
import PartnerMenu from './PartnerMenu'
import { numberWithCommas } from '~/utils/utils'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import asset_channel from '@assets/channel'
import { APP_CHARGES } from '~/utils/utils'

// css
import { styled } from '@mui/material/styles'

// icon
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'

// image
import {
  icon_logout,
  icon_message,
  icon_coupon,
  icon_qna,
  icon_notice,
  icon_point,
  icon_partner,
  icon_deposit,
  icon_withdraw,
  icon_history,
} from '~/assets/images/layout_4'

export const twinkle = keyframes`
  0% {
    background: #ff9a9a;
  }
  50% {
    background: #ff1c1c;
  }
  100% {
    background: #ff9a9a;
  }
`

const MobileHeaderStyle = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1201;

  .MuiAppBar-root {
    background: ${props => props.theme.colors.mobileHeaderBg};
    & .menu {
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .login {
      padding: 0;
      color: ${props => props.theme.colors.mobileTxt};
      .MuiTypography-root {
        font-size: 0.9rem;
      }
    }
    & .MuiDivider-root {
      align-self: auto;
      margin-left: 5px;
      height: 16px;
      border-color: #fff;
    }
    & .btn_user {
      padding: 4px 8px;
      height: 26px;
      & > img {
        width: 26px;
      }
    }
  }

  .MuiGrid-container {
    height: 48px;
    border-bottom: 1px solid #4d4d4d;
  }
  .MuiGrid-item {
    display: flex;
    align-items: center;
    padding-right: 8px;
    height: 100%;
  }
`

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  top: '48px',

  '.MuiBackdrop-root': {
    top: '48px',
  },
  '& .MuiDrawer-paper': {
    top: '48px',
    width: '100%',
    maxHeight: 'calc(100% - 48px)',
    backgroundColor: theme.colors.drawerBg,
  },
}))

const GnbStyle = styled(Box)(({ theme }) => ({
  '& .myinfo': {
    padding: '16px 0',
    backgroundColor: theme.colors.drawerTopBg,
    color: theme.colors.drawerSubTxt,
    '& .MuiListItemText-root': {
      flex: '0 1 auto',
    },
    '& .MuiListItemText-root > span': {
      color: '#fff',
    },
    '& .MuiListItemText-root .account': {
      color: theme.colors.themeActive,
    },
    '& .btn_transfer': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: theme.colors.drawerTxtHover,
      borderRadius: '4px',
      color: theme.colors.btnTxtLighten,
    },
    '& .btn_refresh': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: theme.colors.drawerTxtHover,
      borderRadius: '4px',
      color: theme.colors.btnTxtLighten,
    },
  },
  '& .MuiDivider-root': {
    borderColor: theme.colors.drawerBorder,
  },
  '& .category': {
    backgroundColor: 'transparent',
    color: theme.colors.drawerSubTxt,
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  '& .game .MuiButton-root': {
    width: '100%',
    border: '1px solid #2b3134',
    borderRadius: 0,
    backgroundColor: '#17191a',
    color: theme.colors.drawerMenuTxt,
  },
  '& .game .MuiButton-root:hover, & .game .MuiButton-root.active': {
    color: theme.colors.themeActive,
    borderColor: '#1b1f23',
    borderBottom: `2px solid ${theme.colors.themeActive}`,
    backgroundColor: '#1b1f23',
  },
  '& .bottom_menu .MuiBadge-root': {
    display: 'block',
  },
  '.MuiBadge-root .MuiBadge-badge': {
    top: '16px',
    right: '24px',
    lineHeight: 1.5,
    minWidth: '35px',
    height: 'auto',
    fontSize: '.8rem',
  },
  '& .bottom_menu': {
    '.MuiGrid-item .MuiButton-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '1.3rem .85rem',
      width: '100%',
      border: '1px solid #2b3134',
      background: '#17191a',
      color: '#fff',
    },
    '.MuiGrid-item .MuiButton-root > img': {
      marginBottom: '8px',
      width: '25px',
      height: '25px',
    },
  },
}))

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let EXCLUDE_PARTNER_MENU_CLASS = []
if (process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) {
  EXCLUDE_PARTNER_MENU_CLASS =
    JSON.parse(process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) || []
}

function getLevelImage(level: number) {
  switch (level) {
    case 1:
      return asset_channel.common.icon_level01
    case 2:
      return asset_channel.common.icon_level02
    case 3:
      return asset_channel.common.icon_level03
    case 4:
      return asset_channel.common.icon_level04
    case 5:
      return asset_channel.common.icon_level05
    case 6:
      return asset_channel.common.icon_level06
    case 7:
      return asset_channel.common.icon_level07
    case 8:
      return asset_channel.common.icon_level08
    case 9:
      return asset_channel.common.icon_level09
    case 10:
      return asset_channel.common.icon_level10
  }
  return null
}

let EXCLUDE_TOP_MENU = []
if (process.env.REACT_APP_EXCLUDE_TOP_MENU) {
  EXCLUDE_TOP_MENU = JSON.parse(process.env.REACT_APP_EXCLUDE_TOP_MENU) || []
}
const SHOW_GAME_MONEY = process.env.REACT_APP_SHOW_GAME_MONEY === 'true'

export interface HeaderProps {
  onClickNavPath: (path: string) => void
}

interface MobileHeaderProps {
  Header: React.ComponentType<HeaderProps>
}

export function Header(props: MobileHeaderProps) {
  const { Header } = props
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [open, setOpen] = React.useState(false)
  const [openMypage, setOpenMypage] = React.useState(false)
  const [invisibleUnread, setInvisibleUnread] = useState(true)
  const [refreshEnabled, setRefreshEnabled] = useState(null)

  const { option, navChange, partnerMode } = globalStore
  const { initialized, signedin } = authStore
  const { notification, userInfo, userMoney, userPoint, userComp, game_money } = userStore
  const { nickname, isPartner, user_level, comp_level, merchant_class } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { balance: balanceComp } = userComp

  const {
    open_trading_groups,
    open_trading_kinds,
    enableComp,
    enableCoupon,
    excludePartnerMenuClass,
  } = option || {}

  const contents_level = enableComp ? comp_level : user_level

  const couponActive = notification && notification.usableCouponCount > 0 ? 'active' : ''

  const isHome = window.location.pathname === '/'

  useEffect(() => {
    // do not delete!
    // rerender when notice page tab changed, so header nav can changed
  }, [navChange, partnerMode])

  useEffect(() => {
    let visibleUnread = signedin && notification && notification.unread > 0
    const newInvisibleUnread = !visibleUnread
    if (newInvisibleUnread !== invisibleUnread) {
      setInvisibleUnread(newInvisibleUnread)
    }
  }, [notification])

  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    if (currentPath === path || currentPathSearch === path) {
      return 'active'
    } else {
      return ''
    }
  }

  function isEnabled(trading_group: string): boolean {
    let foundGroup = null
    let foundKind = null

    if (open_trading_groups) {
      foundGroup = _.find(open_trading_groups, o => {
        return o.trading_group === trading_group
      })
    }

    if (open_trading_kinds) {
      foundKind = _.find(open_trading_kinds, o => {
        return o.trading_group === trading_group
      })
    }

    return foundGroup && foundKind
  }

  const onClickMenu = () => {
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
      if (openMypage) {
        setOpenMypage(false)
      }
    }
  }

  const onClickMypage = () => {
    if (openMypage === true) {
      setOpenMypage(false)
    } else {
      setOpenMypage(true)
      if (open) {
        setOpen(false)
      }
    }
  }

  const onClickButton = (name: string) => {
    switch (name) {
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'game-money':
        const timer = setTimeout(() => {
          setRefreshEnabled(null)
        }, 5000)
        setRefreshEnabled(timer)
        userStore.loadGameMoney(intl)
        return
      case 'withdraw':
        globalStore.pushDialogYesNo({
          text: intl.formatMessage({ id: 'msg.ask.gamemoney-to-ownmoney' }),
          callbackPositive: async () => {
            const timer = setTimeout(() => {
              setRefreshEnabled(null)
            }, 5000)
            setRefreshEnabled(timer)
            userStore.withdrawGameMoney(intl)
          },
        })
        return
    }

    setOpen(false)
    setOpenMypage(false)
    window.scrollTo(0, 0)
  }

  const onClickMenuItem = (id: string, arg: string | number) => {
    switch (id) {
      case 'live-casino':
        history.push('/live-casino')
        globalStore.setNavChange('/live-casino')
        break
      case 'hotel-casino':
        history.push('/hotel-casino')
        globalStore.setNavChange('/hotel-casino')
        break
      case 'slot':
        history.push('/slot')
        globalStore.setNavChange('/slot')
        break
      case 'sports':
        history.push('/sports')
        globalStore.setNavChange('/sports')
        break
      case 'minigame':
        if (arg) {
          history.push(`/minigame/search?main=${arg}`)
          globalStore.setNavChange(`/minigame/search?main=${arg}`)
        } else {
          history.push('/minigame')
          globalStore.setNavChange('/minigame')
        }
        break
      case 'partner':
        history.push('/partner')
        globalStore.setNavChange('partner')
        break
      case 'bank':
        history.push(`/bank/search?main=${arg}`)
        globalStore.setNavChange(`/bank/search?main=${arg}`)
        break
      case 'notice':
        history.push(`/notice/search?main=${arg}`)
        globalStore.setNavChange(`/notice/search?main=${arg}`)
        break
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.point.url}`)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.comp.url}`)
        break
      case 'myinfo':
        history.push(MYPAGE_TAB_VALUES.myinfo.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.myinfo.url}`)
        break
      case 'qnaPersonal':
        history.push(MYPAGE_TAB_VALUES.qnaPersonal.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.qnaPersonal.url}`)
        break
      // case 'qnaPartner':
      //   history.push(MYPAGE_TAB_VALUES.qnaPartner.url)
      //   break
      case 'login':
        globalStore.showModal(ModalKeys.login, 0)
        break
      case 'join':
        globalStore.showModal(ModalKeys.login, 1)
        break
      case 'message':
        globalStore.showModal('message', 0)
        break
      case 'coupon':
        globalStore.showModal('coupon', 0)
        break
    }

    setOpen(false)
    setOpenMypage(false)
    window.scrollTo(0, 0)
  }

  const onClickLogout = () => {
    history.push('/')
    authStore.logout(intl)
    setAnchorEl(null)
    setOpen(false)
    setOpenMypage(false)
  }

  const onClickLogo = () => {
    history.push('/')
    setOpen(false)
    setOpenMypage(false)
    if (signedin) {
      userStore.loadAsset(intl)
    }
  }

  const showNotice = EXCLUDE_TOP_MENU.indexOf('NOTICE') < 0
  const showEvent = EXCLUDE_TOP_MENU.indexOf('EVENT') < 0
  const showPolicy = EXCLUDE_TOP_MENU.indexOf('POLICY') < 0
  const showQna = EXCLUDE_TOP_MENU.indexOf('QNA') < 0

  let rightContents = null
  let quickmenuPartner = null
  let badgeContents = (
    <Grid item xs={4}>
      <Button size="large" onClick={() => onClickMenuItem('message', '')}>
        <img src={icon_message} alt="message" />
        {intl.formatMessage({ id: 'component.message' })}
      </Button>
    </Grid>
  )

  if (initialized) {
    if (signedin) {
      const showPartnerMenu = !excludePartnerMenuClass.includes(merchant_class.toString())

      if (notification) {
        const { unread } = notification

        if (unread > 0) {
          badgeContents = (
            <Grid item xs={4}>
              <Badge badgeContent={unread} invisible={invisibleUnread} color="error">
                <Button size="large" onClick={() => onClickMenuItem('message', '')}>
                  <img src={icon_message} alt="message" />
                  {intl.formatMessage({ id: 'component.message' })}
                </Button>
              </Badge>
            </Grid>
          )
        }
      }

      if (showPartnerMenu) {
        quickmenuPartner = (
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('partner')}`}
              onClick={() => onClickMenuItem('partner', '')}
            >
              <img src={icon_partner} alt="partner" />
              {intl.formatMessage({ id: 'component.partner' })}
            </Button>
          </Grid>
        )
      }

      rightContents = (
        <>
          <Button
            size="large"
            className="btn_user"
            onClick={onClickMypage}
            sx={{ minWidth: '42px' }}
          >
            <img src={asset_channel.common.icon_user} alt="mypage" />
          </Button>
        </>
      )
    } else {
      rightContents = (
        <>
          <Button
            className="login"
            onClick={() => onClickMenuItem('join', '0')}
            sx={{ minWidth: '45px' }}
          >
            <Typography>{intl.formatMessage({ id: 'signup' })}</Typography>
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button
            className="login"
            onClick={() => onClickMenuItem('login', '0')}
            sx={{ minWidth: '45px' }}
          >
            <Typography>{intl.formatMessage({ id: 'login' })}</Typography>
          </Button>
        </>
      )
    }
  }

  // Drawer----------
  const chargeContents = []
  for (let i = 0; i < APP_CHARGES.length; i += 1) {
    switch (APP_CHARGES[i]) {
      case 'deposit':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=deposit')}`}
              onClick={() => {
                onClickMenuItem('bank', 'deposit')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.normal' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositOnepay':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositOnepay')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositOnepay')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.onepay' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositCoin':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositCoin')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositCoin')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.coin' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositCoinJun200':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositCoinJun200')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositCoinJun200')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.coin' })}
            </Button>
          </Grid>,
        )
        break
      case 'deposit5Koin':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=deposit5Koin')}`}
              onClick={() => {
                onClickMenuItem('bank', 'deposit5Koin')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.5koin' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositWonp':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositWonp')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositWonp')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.wonp' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositAuthVirtual':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositAuthVirtual')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositAuthVirtual')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.authvirtual' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositMCI':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositMCI')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositMCI')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.mci' })}
            </Button>
          </Grid>,
        )
        break
      case 'depositCodepay':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=depositCodepay')}`}
              onClick={() => {
                onClickMenuItem('bank', 'depositCodepay')
              }}
            >
              <img src={icon_deposit} alt="deposit" />
              {intl.formatMessage({ id: 'component.bank.deposit.codepay' })}
            </Button>
          </Grid>,
        )
        break
      case 'withdrawCodepay':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=withdrawCodepay')}`}
              onClick={() => onClickMenuItem('bank', 'withdrawCodepay')}
            >
              <img src={icon_withdraw} alt="withdraw" />
              {intl.formatMessage({ id: 'component.bank.withdraw.codepay' })}
            </Button>
          </Grid>,
        )
        break
      case 'withdraw':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=withdraw')}`}
              onClick={() => onClickMenuItem('bank', 'withdraw')}
            >
              <img src={icon_withdraw} alt="withdraw" />
              {intl.formatMessage({ id: 'component.bank.withdraw' })}
            </Button>
          </Grid>,
        )
        break
      case 'history':
        chargeContents.push(
          <Grid item xs={4}>
            <Button
              size="large"
              className={`${navActive('?main=history')}`}
              onClick={() => onClickMenuItem('bank', 'history')}
            >
              <img src={icon_history} alt="history" />
              {intl.formatMessage({ id: 'bank-history-short' })}
            </Button>
          </Grid>,
        )
        break
    }
  }

  const list =
    signedin === true ? (
      <Box className="myinfo">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
          <Button
            className="nickname"
            sx={{ display: 'flex', alignItems: 'center', pl: 0, py: 0, color: 'inherit' }}
            onClick={() => onClickMenuItem('myinfo', '')}
          >
            <Box sx={{ '& > img': { padding: '6px', width: '36px' } }}>
              <img src={getLevelImage(contents_level)} alt="" />
            </Box>
            <Box component="span" sx={{ textTransform: 'initial', fontSize: '1rem' }}>
              {nickname}
            </Box>
          </Button>
          <Box>
            <Button
              size="small"
              onClick={() => onClickMenuItem('myinfo', '')}
              sx={{ minWidth: '20px' }}
            >
              <SettingsRoundedIcon sx={{ color: '#fff', fontSize: '1.539rem' }} />
            </Button>
            <Button size="small" onClick={onClickLogout} sx={{ minWidth: '20px' }}>
              <img src={icon_logout} alt="logout" style={{ width: '20px' }} />
            </Button>
          </Box>
        </Box>

        <List component="div" sx={{ p: '0 8px 8px' }}>
          <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="money">
            <ListItemText>
              <span>{intl.formatMessage({ id: 'own-money' })}</span>
            </ListItemText>
            <ListItemText>
              <span className="account">{numberWithCommas(Math.floor(balanceMoney))}</span>
              <span>{intl.formatMessage({ id: 'money-locale' })}</span>
            </ListItemText>
          </ListItem>
          <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="point">
            <ListItemText>
              <span>{intl.formatMessage({ id: 'point' })}</span>
            </ListItemText>
            <ListItemText>
              <span className="account">{numberWithCommas(Math.floor(balancePoint))}</span>
              <span>P</span>
              <Button className="btn_transfer" onClick={() => onClickButton('point')}>
                전환
              </Button>
            </ListItemText>
          </ListItem>
          {enableComp ? (
            <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="comp">
              <ListItemText>
                <span>{intl.formatMessage({ id: 'comp' })}</span>
              </ListItemText>
              <ListItemText>
                <span className="account">{numberWithCommas(Math.floor(balanceComp))}</span>
                <Button className="btn_transfer" onClick={() => onClickButton('comp')}>
                  전환
                </Button>
              </ListItemText>
            </ListItem>
          ) : null}
          {SHOW_GAME_MONEY && !globalStore.option?.solution?.seamless && (
            <>
              <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="game-money">
                <ListItemText>
                  <span>{intl.formatMessage({ id: 'game-money' })}</span>
                </ListItemText>
                <ListItemText>
                  <span className="account">
                    {_.isNumber(game_money) ? numberWithCommas(Math.floor(game_money)) : '??'}
                  </span>
                  <Button className="btn_refresh" disabled={!!refreshEnabled}>
                    <RefreshIcon
                      sx={{ width: '16px' }}
                      onClick={() => onClickButton('game-money')}
                    />
                  </Button>
                  <Button
                    className="btn_transfer"
                    disabled={!!refreshEnabled}
                    onClick={() => onClickButton('withdraw')}
                    sx={{
                      '&:disabled': {
                        backgroundColor: '#931d1d',
                        color: '#cccccc',
                      },
                    }}
                  >
                    전환
                  </Button>
                </ListItemText>
              </ListItem>
            </>
          )}
        </List>
      </Box>
    ) : null
  // -----------Drawer

  return (
    <>
      <MobileHeaderStyle>
        <AppBar position="static">
          <Grid container>
            <Grid item xs={4}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                className="menu"
                sx={{ p: '6px', ml: '6px' }}
                onClick={() => onClickMenu()}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ justifyContent: 'center', '& > img': { cursor: 'pointer', height: '100%' } }}
            >
              <img src={asset_channel.common.main_logo_m} alt="" onClick={() => onClickLogo()} />
            </Grid>
            <Grid item xs={4} sx={{ justifyContent: 'end' }}>
              {rightContents}
            </Grid>
          </Grid>
        </AppBar>

        {/* 내정보 드롭다운 */}
        <DrawerStyle anchor="top" open={openMypage} onClose={() => setOpenMypage(false)}>
          <GnbStyle component="nav" role="presentation">
            {list}
          </GnbStyle>
        </DrawerStyle>

        {/* 메뉴 드롭다운 */}
        <DrawerStyle anchor="top" open={open} onClose={() => setOpen(false)}>
          <GnbStyle component="nav" role="presentation">
            <Box className="menu" sx={{ p: 2 }}>
              <Box className="category">Game</Box>
              {channelSymbol === 'world' ? (
                <Grid container spacing={1} className="game">
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('LCA')}
                      size="large"
                      className={`${navActive('/live-casino')}`}
                      onClick={() => {
                        onClickMenuItem('live-casino', 'evolution')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.live-casino' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('SL')}
                      size="large"
                      className={`${navActive('/slot')}`}
                      onClick={() => {
                        onClickMenuItem('slot', '...')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.slot' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('SP')}
                      size="large"
                      className={`${navActive('/sports')}`}
                      onClick={() => {
                        onClickMenuItem('sports', '')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.sports' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('HCA')}
                      size="large"
                      className={`${navActive('/hotel-casino')}`}
                      onClick={() => {
                        onClickMenuItem('hotel-casino', 'uig')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.hotel-casino' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={
                        !isEnabled('HPB') &&
                        !isEnabled('NPB') &&
                        !isEnabled('PB') &&
                        !isEnabled('PL') &&
                        !isEnabled('EOS') &&
                        !isEnabled('PBG') &&
                        !isEnabled('CPB') &&
                        !isEnabled('CL') &&
                        !isEnabled('SG') &&
                        !isEnabled('WRBTCPB') &&
                        !isEnabled('WRBTCPL') &&
                        !isEnabled('WRBEXPB') &&
                        !isEnabled('WRKENOPB') &&
                        !isEnabled('WRKENOPL') &&
                        !isEnabled('WRKENO')
                      }
                      size="large"
                      className={`${navActive('/minigame')}`}
                      onClick={() => {
                        onClickMenuItem('minigame', '')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.minigame' })}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1} className="game">
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('LCA')}
                      size="large"
                      className={`${navActive('/live-casino')}`}
                      onClick={() => {
                        onClickMenuItem('live-casino', 'evolution')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.live-casino' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('HCA')}
                      size="large"
                      className={`${navActive('/hotel-casino')}`}
                      onClick={() => {
                        onClickMenuItem('hotel-casino', 'uig')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.hotel-casino' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('SL')}
                      size="large"
                      className={`${navActive('/slot')}`}
                      onClick={() => {
                        onClickMenuItem('slot', '...')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.slot' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={
                        !isEnabled('HPB') &&
                        !isEnabled('NPB') &&
                        !isEnabled('PB') &&
                        !isEnabled('PL') &&
                        !isEnabled('EOS') &&
                        !isEnabled('PBG') &&
                        !isEnabled('CPB') &&
                        !isEnabled('CL') &&
                        !isEnabled('SG') &&
                        !isEnabled('WRBTCPB') &&
                        !isEnabled('WRBTCPL') &&
                        !isEnabled('WRBEXPB') &&
                        !isEnabled('WRKENOPB') &&
                        !isEnabled('WRKENOPL') &&
                        !isEnabled('WRKENO')
                      }
                      size="large"
                      className={`${navActive('/minigame')}`}
                      onClick={() => {
                        onClickMenuItem('minigame', '')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.minigame' })}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      hidden={!isEnabled('SP')}
                      size="large"
                      className={`${navActive('/sports')}`}
                      onClick={() => {
                        onClickMenuItem('sports', '')
                      }}
                    >
                      {intl.formatMessage({ id: 'menu.header.sports' })}
                    </Button>
                  </Grid>
                </Grid>
              )}

              <Box className="category" sx={{ pt: 2 }}>
                Quick menu
              </Box>
              <Grid container spacing={1} className="bottom_menu">
                {badgeContents}

                {enableCoupon ? (
                  <Grid item xs={4}>
                    <Button size="large" onClick={() => onClickMenuItem('coupon', '')}>
                      <img src={icon_coupon} alt="coupon" />
                      {intl.formatMessage({ id: 'component.coupon' })}
                    </Button>
                  </Grid>
                ) : null}
                {showQna && signedin ? (
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      className={`${navActive('?main=qnaPersonal')}`}
                      onClick={() => onClickMenuItem('qnaPersonal', '')}
                    >
                      <img src={icon_qna} alt="qna" />
                      {intl.formatMessage({ id: 'qna' })}
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Button
                    size="large"
                    className={`${navActive('?main=notice')}`}
                    onClick={() => onClickMenuItem('notice', 'NOTICE')}
                  >
                    <img src={icon_notice} alt="notice" />
                    {intl.formatMessage({ id: 'component.notice' })}
                  </Button>
                </Grid>
                {channelSymbol === 'world' ? (
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      className={`${navActive('?main=event')}`}
                      onClick={() => onClickMenuItem('notice', 'EVENT')}
                    >
                      <img src={icon_notice} alt="event" />
                      {intl.formatMessage({ id: 'component.notice.event' })}
                    </Button>
                  </Grid>
                ) : null}
                <Grid item xs={4}>
                  <Button
                    size="large"
                    className={`${navActive('?main=point')}`}
                    onClick={() => onClickMenuItem('point', '')}
                  >
                    <img src={icon_point} alt="point" />
                    {intl.formatMessage({ id: 'component.point' })}
                  </Button>
                </Grid>
                {/* {showPolicy ? (
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      className={`${navActive('?main=POLICY')}`}
                      onClick={() => onClickMenuItem('notice', 'POLICY')}
                    >
                      <img src={icon_policy} alt="policy" />
                      {intl.formatMessage({ id: 'component.notice.policy' })}
                    </Button>
                  </Grid>
                ) : null} */}

                {quickmenuPartner}

                {chargeContents}
              </Grid>
            </Box>
          </GnbStyle>
        </DrawerStyle>
      </MobileHeaderStyle>
      <PartnerMenu></PartnerMenu>
    </>
  )
}

export default observer(Header)
