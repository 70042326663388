import React, { useRef, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useRootStore from '~/store/useRootStore'
import apiGame from '@services/api/game'
import { UserComp, UserInfo, UserMoney, UserPoint } from '~/store/userStore'
import { Schema$Notification } from '~/v2/interface/common'
import { Schema$PublicOption } from '~/v2/protocol/public'
import asset_channel from '@assets/channel'
import { BANK_MENU, MYPAGE_TAB_VALUES } from '@constants/attrs'
import { observer } from 'mobx-react'
import { APP_CHARGES } from '~/utils/utils'
import { useIntl } from 'react-intl'

const LEVEL_IMAGES = [
  asset_channel.common.icon_level01,
  asset_channel.common.icon_level02,
  asset_channel.common.icon_level03,
  asset_channel.common.icon_level04,
  asset_channel.common.icon_level05,
  asset_channel.common.icon_level06,
  asset_channel.common.icon_level07,
  asset_channel.common.icon_level08,
  asset_channel.common.icon_level09,
  asset_channel.common.icon_level10,
]

export function getLevelImage(level: number) {
  const idx = level - 1
  if (idx < LEVEL_IMAGES.length) {
    return LEVEL_IMAGES[idx]
  }
  return null
}

export interface HeaderProps {
  signedin: boolean
  notification: Schema$Notification
  option: Schema$PublicOption
  userInfo: UserInfo
  userMoney: UserMoney
  userPoint: UserPoint
  userComp: UserComp
  game_money: number
  partnerMode: number
  onClickNavPath: (path: string) => void
  onClickHeaderButton: (name: string) => void
  onClickHeaderMenu: (name: string, arg: string) => void
  onClickHeaderPartnerMenu: (name: string, arg: string) => void
  onClickHeaderLogo: () => void
}

interface HeaderWrapperProps {
  Header: React.ComponentType<HeaderProps>
}

function HeaderWrapper(props: HeaderWrapperProps) {
  const { Header } = props

  const intl = useIntl()
  const history = useHistory()

  const { globalStore, authStore, userStore } = useRootStore()
  const { initialized, signedin } = authStore

  const { userInfo, userMoney, userPoint, userComp, game_money } = userStore
  const { notification } = userStore

  const { partnerMode, option, noticeTab, navChange } = globalStore

  useEffect(() => {
    // do not delete!
    // rerender when notice page tab changed, so header nav can changed
  }, [noticeTab, navChange])

  if (!initialized) {
    return null
  }

  const onClickButton = (name: string) => {
    switch (name) {
      case 'bank':
        if (APP_CHARGES.length > 0) {
          history.push(`/bank/search?main=${APP_CHARGES[0]}`)
        }
        break
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'game-money':
        userStore.loadGameMoney(intl)
        break
      case 'myinfo':
        history.push(MYPAGE_TAB_VALUES.myinfo.url)
        break
      case 'message':
        globalStore.showModal('message', 0)
        break
      case 'coupon':
        globalStore.showModal('coupon', 0)
        break
      case 'bank/deposit':
        if (APP_CHARGES.length > 0) {
          history.push(`/bank/search?main=${APP_CHARGES[0]}`)
          globalStore.setNavChange(`/bank/search?main=${APP_CHARGES[0]}`)
        }
        break
      case 'bank/withdraw':
        history.push(BANK_MENU.sub.withdraw.url)
        globalStore.setNavChange(BANK_MENU.sub.withdraw.url)
        break
      case 'bank/history':
        history.push(BANK_MENU.sub.history.url)
        globalStore.setNavChange(BANK_MENU.sub.history.url)
        break
      case 'qna':
        history.push(MYPAGE_TAB_VALUES.qnaPersonal.url)
        globalStore.setNavChange(MYPAGE_TAB_VALUES.qnaPersonal.url)
        break
      case 'policy':
        history.push('/mypage/search?main=POLICY')
        break
      case 'notice':
        if (APP_CHARGES.length > 0) {
          history.push('/notice/search?main=NOTICE')
        }
        break
      case 'event':
        if (APP_CHARGES.length > 0) {
          history.push('/notice/search?main=EVENT')
        }
        break
      case 'partner':
        history.push('/partner')
        break
      case 'partner/members':
        history.push('/partner/members')
        break
      case 'login':
        globalStore.showModal('login', 0)
        break
      case 'signup':
        globalStore.showModal('login', 1)
        break
      case 'logout':
        history.push('/')
        authStore.logout(intl)
        break
    }
  }

  const onClickMenu = (name: string, arg: string) => {
    switch (name) {
      case 'live-casino':
        history.push('/live-casino')
        globalStore.setNavChange('/live-casino')
        break
      case 'hotel-casino':
        history.push('/hotel-casino')
        globalStore.setNavChange('/hotel-casino')
        break
      case 'slot':
        history.push('/slot')
        globalStore.setNavChange('/slot')
        break
      case 'reel':
        history.push('/reel')
        break
      case 'sports':
        history.push('/sports')
        globalStore.setNavChange('/sports')
        break
      case 'minigame':
        if (arg) {
          history.push(`/minigame/search?main=${arg}`)
          globalStore.setNavChange(`/minigame/search?main=${arg}`)
        } else {
          history.push('/minigame')
          globalStore.setNavChange('/minigame')
        }
        break
      case 'boardgame':
        history.push('/boardgame')
        break
      case 'deposit':
        if (APP_CHARGES.length > 0) {
          history.push(`/bank/search?main=${APP_CHARGES[0]}`)
        }
        break
      case 'withdraw':
        history.push('/bank/search?main=withdraw')
        globalStore.setNavChange('/bank/search?main=withdraw')
        break
      case 'history':
        history.push('/bank/search?main=history')
        globalStore.setNavChange('/bank/search?main=history')
        break
      case 'partner':
        history.push('/partner')
        globalStore.setNavChange('/partner')
        break
      case 'partner/betting-record':
        history.push('/partner/betting-record')
        globalStore.setNavChange('/partner/betting-record')
        break
      case 'mypage':
        if (arg) {
          history.push(`/mypage/search?main=${arg}`)
          globalStore.setNavChange(`/mypage/search?main=${arg}`)
        } else {
          history.push('/mypage')
          globalStore.setNavChange('/mypage')
        }
        break
      case 'guide':
        if (arg) {
          history.push(`/guide/search?main=${arg}`)
          globalStore.setNavChange(`/guide/search?main=${arg}`)
        } else {
          history.push('/guide')
          globalStore.setNavChange('/guide')
        }
        break
      case 'news':
        if (arg) {
          history.push(`/notice/search?main=${arg}`)
          globalStore.setNavChange(`/notice/search?main=${arg}`)
        } else {
          history.push('/notice')
          globalStore.setNavChange('/notice')
        }
        break
      case 'bank':
        if (arg) {
          history.push(`/bank/search?main=${arg}`)
        }
        break
    }
  }

  const onClickPartnerMenu = (name: string, arg: string) => {
    switch (name) {
      case 'dashboard':
        history.push('/partner/dashboard')
        break
      case 'members.list':
        history.push('/partner/members')
        break
      case 'members.concurrent':
        history.push('/partner/members-concurrent')
        break
      case 'stats-game':
        history.push('/partner/stats-game')
        break
      case 'balance-record':
        history.push('/partner/balance-record')
        break
      case 'balance-request':
        history.push('/partner/balance-request')
        break
      case 'betting-record':
        history.push('/partner/betting-record')
        break
      case 'charge-record':
        history.push('/partner/charge-record')
        break
    }
  }

  const onClickLogo = () => {
    history.push('/')
    // window.location.reload()
    if (signedin) {
      userStore.loadAsset(intl)
    }
  }

  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    const pathname = `${currentPath}${currentPathSearch}`
    if (currentPath === path || currentPathSearch === path || path === pathname) {
      //console.log(`[ACTIVE] path: ${path} pathname: ${pathname}`)
      return 'active'
    } else {
      //console.log(`[      ] path: ${path} pathname: ${pathname}`)
      return ''
    }
  }

  const params: HeaderProps = {
    signedin,
    notification,
    option,
    userInfo,
    userMoney,
    userPoint,
    userComp,
    partnerMode,
    game_money,
    onClickNavPath: navActive,
    onClickHeaderButton: onClickButton,
    onClickHeaderMenu: onClickMenu,
    onClickHeaderPartnerMenu: onClickPartnerMenu,
    onClickHeaderLogo: onClickLogo,
  }

  return <Header {...params}></Header>
}

export default observer(HeaderWrapper)
