import * as _ from 'lodash'

// export const SQUIDGAME_OUTCOME_TEST = [
//   { id: 'SGDLEFT_SGDRIGHT', value: '1.95', odds: [] },
//   { id: 'SGMODD_SGMEVEN', value: '1.95', odds: [] },
//   { id: 'SGMUNDER_SGMOVER', value: '1.95', odds: [] },
//   { id: 'SGDLEFT_SGDRIGHT+SGMODD_SGMEVEN', value: '1.95', odds: [] },
//   { id: 'SGMODD_SGMEVEN+SGMUNDER_SGMOVER', value: '1.95', odds: [] },
//   { id: 'SGSODD_SGSEVEN', value: '1.95', odds: [] },
//   { id: 'SGSUNDER_SGSOVER', value: '1.95', odds: [] },
//   { id: 'SGDLEFT_SGDRIGHT+SGSODD_SGSEVEN', value: '1.95', odds: [] },
// ]

// category
// SG - Squidgame
export const SquidgameMarketMeta = [
  {
    id: 'SGDLEFT_SGDRIGHT',
    category: 'SG',
    locale: { name: '좌우', fullname: '오징어게임 좌우' },
    metaOdds: ['SGDLEFT', 'SGDRIGHT'],
  },
  // 마스터
  {
    id: 'SGMODD_SGMEVEN',
    category: 'SG',
    locale: { name: '홀짝', fullname: '마스터 홀짝' },
    metaOdds: ['SGMODD', 'SGMEVEN'],
  },
  {
    id: 'SGMUNDER_SGMOVER',
    category: 'SG',
    locale: { name: '언오버', fullname: '마스터 언오버' },
    metaOdds: ['SGMUNDER', 'SGMOVER'],
  },
  {
    id: 'SGDLEFT_SGDRIGHT+SGMODD_SGMEVEN',
    category: 'SG',
    locale: { name: '언오버', fullname: '마스터 좌우+언오버' },
    metaOdds: ['SGDLEFT+SGMODD', 'SGDLEFT+SGMEVEN', 'SGDRIGHT+SGMODD', 'SGDRIGHT+SGMEVEN'],
  },
  {
    id: 'SGMODD_SGMEVEN+SGMUNDER_SGMOVER',
    category: 'SG',
    locale: { name: '홀짝+언오버', fullname: '마스터 홀짝+언오버' },
    metaOdds: ['SGMODD+SGMUNDER', 'SGMODD+SGMOVER', 'SGMEVEN+SGMUNDER', 'SGMEVEN+SGMOVER'],
  },
  // 생존자
  {
    id: 'SGSODD_SGSEVEN',
    category: 'SG',
    locale: { name: '홀짝', fullname: '생존자 홀짝' },
    metaOdds: ['SGSODD', 'SGSEVEN'],
  },
  {
    id: 'SGSUNDER_SGSOVER',
    category: 'SG',
    locale: { name: '언오버', fullname: '생존자 언오버' },
    metaOdds: ['SGSUNDER', 'SGSOVER'],
  },
  {
    id: 'SGDLEFT_SGDRIGHT+SGSODD_SGSEVEN',
    category: 'SG',
    locale: { name: '언오버', fullname: '생존자 좌우+홀짝' },
    metaOdds: ['SGDLEFT+SGSODD', 'SGDLEFT+SGSEVEN', 'SGDRIGHT+SGSODD', 'SGDRIGHT+SGSEVEN'],
  },
]

export const SquidgameOddsMeta = [
  { id: 'SGDLEFT', locale: { name: '좌' } },
  { id: 'SGDRIGHT', locale: { name: '우' } },

  // 마스터
  { id: 'SGMODD', locale: { name: '홀' } },
  { id: 'SGMEVEN', locale: { name: '짝' } },
  { id: 'SGMUNDER', locale: { name: '언더' } },
  { id: 'SGMOVER', locale: { name: '오버' } },
  { id: 'SGDLEFT+SGMODD', locale: { name: '좌+홀' } },
  { id: 'SGDLEFT+SGMEVEN', locale: { name: '좌+짝' } },
  { id: 'SGDRIGHT+SGMODD', locale: { name: '우+홀' } },
  { id: 'SGDRIGHT+SGMEVEN', locale: { name: '우+짝' } },
  { id: 'SGMODD+SGMUNDER', locale: { name: '홀+언더' } },
  { id: 'SGMODD+SGMOVER', locale: { name: '홀+오버' } },
  { id: 'SGMEVEN+SGMUNDER', locale: { name: '짝+언더' } },
  { id: 'SGMEVEN+SGMOVER', locale: { name: '짝+오버' } },

  // 생존자
  { id: 'SGSODD', locale: { name: '홀' } },
  { id: 'SGSEVEN', locale: { name: '짝' } },
  { id: 'SGSUNDER', locale: { name: '언더' } },
  { id: 'SGSOVER', locale: { name: '오버' } },
  { id: 'SGDLEFT+SGSODD', locale: { name: '좌+홀' } },
  { id: 'SGDLEFT+SGSEVEN', locale: { name: '좌+짝' } },
  { id: 'SGDRIGHT+SGSODD', locale: { name: '우+홀' } },
  { id: 'SGDRIGHT+SGSEVEN', locale: { name: '우+짝' } },
]
