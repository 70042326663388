// import { MarketGroup } from './market_group_types'
// export const VOLLEYBALL_INPLAY_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const VOLLEYBALL_INPLAY_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      { id: 52, unifiedName: '12', name: { en: '12', ko: '승패' } },
      {
        id: 3,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap', ko: '핸디캡 *' },
      },
      { id: 2, unifiedName: 'UO', name: { en: 'Under/Over', ko: '총 득점' } },
      {
        id: 5,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even', ko: '홀/짝 총 득점' },
      },
      {
        id: 6,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score', ko: '정확한 세트점수' },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: 'Team', ko: '팀' },
    markets: [
      {
        id: 101,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Home Team', ko: '총 득점 - 홈팀' },
      },
      {
        id: 102,
        unifiedName: 'UO',
        name: { en: 'Under/Over - Away Team', ko: '총 득점 - 원정팀' },
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: 'Period', ko: '세트' },
    markets: [
      // 1세트
      {
        id: 202,
        unifiedName: '12',
        name: { en: '1st Period Winner Home/Away', ko: '승패 (1세트)' },
      },
      {
        id: 64,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 1st Period', ko: '핸디캡 (1세트) *' },
      },
      {
        id: 21,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period', ko: '총 득점 (1세트)' },
      },
      {
        id: 72,
        unifiedName: 'LIST2',
        name: { en: '1st Period Odd/Even', ko: '홀/짝 총 득점 (1세트)' },
      },
      {
        id: 329,
        unifiedName: 'N12',
        name: { en: '1st Period Race To', ko: '선취 득점 (1세트)' },
      },
      // {
      //   id: 9,
      //   unifiedName: 'LIST1',
      //   name: { en: 'Correct Score 1st Period', ko: '정확한 점수 (1세트)' },
      // },
      // {
      //   id: 1335,
      //   unifiedName: 'LIST1',
      //   name: {
      //     en: 'Winning Margin Teams - 1st Period',
      //     ko: '점수 마진 (1세트)',
      //   },
      // },
      // 2세트
      {
        id: 203,
        unifiedName: '12',
        name: {
          en: '2nd Period Winner Home/Away',
          ko: '승패 (2세트)',
        },
      },
      {
        id: 65,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 2nd Period', ko: '핸디캡 (2세트) *' },
      },
      {
        id: 45,
        unifiedName: 'UO',
        name: { en: 'Under/Over 2nd Period', ko: '언더/오버 (2세트)' },
      },
      {
        id: 73,
        unifiedName: 'LIST2',
        name: { en: '2nd Period Odd/Even', ko: '홀/짝 총 득점 (2세트)' },
      },
      {
        id: 330,
        unifiedName: 'N12',
        name: { en: '2nd Period Race To', ko: '선취 득점 (2세트)' },
      },
      // {
      //   id: 100,
      //   unifiedName: 'LIST1',
      //   name: { en: 'Correct Score 2nd Period', ko: '정확한 점수 (2세트)' },
      // },
      // {
      //   id: 1336,
      //   unifiedName: 'LIST1',
      //   name: {
      //     en: 'Winning Margin Teams - 2nd Period',
      //     ko: '점수 마진 (2세트)',
      //   },
      // },
      // 3세트
      {
        id: 204,
        unifiedName: '12',
        name: { en: '3rd Period Winner Home/Away', ko: '승패 (3세트)' },
      },
      {
        id: 66,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 3rd Period', ko: '핸디캡 (3세트) *' },
      },
      {
        id: 46,
        unifiedName: 'UO',
        name: { en: 'Under/Over 3rd Period', ko: '언더/오버 (3세트)' },
      },
      {
        id: 74,
        unifiedName: 'LIST2',
        name: { en: '3rd Period Odd/Even', ko: '홀/짝 총 득점 (3세트)' },
      },
      {
        id: 331,
        unifiedName: 'N12',
        name: { en: '3rd Period Race To', ko: '선취 득점 (3세트)' },
      },
      // {
      //   id: 217,
      //   unifiedName: 'LIST1',
      //   name: { en: 'Correct Score 3rd Period', ko: '정확한 점수 (3세트)' },
      // },
      // {
      //   id: 1337,
      //   unifiedName: 'LIST1',
      //   name: {
      //     en: 'Winning Margin Teams - 3rd Period',
      //     ko: '점수 마진 (3세트)',
      //   },
      // },
      // 4세트
      {
        id: 205,
        unifiedName: '12',
        name: { en: '4th Period Winner Home/Away', ko: '승패 (4세트)' },
      },
      {
        id: 67,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 4th Period', ko: '핸디캡 (4세트) *' },
      },
      {
        id: 47,
        unifiedName: 'UO',
        name: { en: 'Under/Over 4th Period', ko: '언더/오버 (4세트)' },
      },
      {
        id: 75,
        unifiedName: 'LIST2',
        name: { en: '4th Period Odd/Even', ko: '홀/짝 총 득점 (4세트)' },
      },
      {
        id: 332,
        unifiedName: 'N12',
        name: { en: '4th Period Race To', ko: '선취 득점 (4세트)' },
      },
      //  {
      //   id: 394,
      //   unifiedName: 'LIST1',
      //   name: { en: 'Correct Score 4th Period', ko: '정확한 점수 (4세트)' },
      // },
      // {
      //   id: 1338,
      //   unifiedName: 'LIST1',
      //   name: {
      //     en: 'Winning Margin Teams - 4th Period',
      //     ko: '점수 마진 (4세트)',
      //   },
      // },
    ],
  },
]
