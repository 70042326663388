/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef, Fragment } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Box, Typography, Divider, useTheme, Tabs, Tab, Grid, IconButton } from '@mui/material'
import { useMediaQuery } from 'react-responsive'

import useRootStore from '~/store/useRootStore'
import {
  DetailOddsFactory,
  SPORT_MARKET_FILTER,
} from '~/components/PrematchDetailOdds/detail_odds_factory'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { sleep } from '~/utils/utils'
import { SPORT_ID } from '~/v2/interface/st-types'
import PrematchFixtureTrack from './PrematchFixtureTrack'
import InplayFixtureTrack from './InplayFixtureTrack'
import DetailMarketbox from '~/components/PrematchDetailOdds/DetailMarketbox'
import { Schema$Market } from '~/v2/interface/st-schema'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

function TabItemProps(index: number) {
  return {
    id: `merchant-class-tab-${index}`,
    'aria-controls': `merchant-class-tabpanel-${index}`,
  }
}

function ImplMarketDetail() {
  const theme = useTheme()
  const { globalStore, sportsStore } = useRootStore()
  const [tab, setTab] = useState(1)
  const [contents, setContents] = useState<React.ReactNode>([])
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { desktop } = globalStore.decideMedia(isDesktop)
  const { flight, options, lang } = sportsStore

  const pd = sportsStore.getSelectedFixture()
  const { prematch, markets } = pd

  const { sports_configs } = options || {}
  const baseline_config = _.find(sports_configs, o => {
    return o.sport_id === prematch?.SportId
  })

  const filter = _.find(SPORT_MARKET_FILTER, o => {
    return o.sportId === prematch?.SportId
  })

  const marketGroup =
    (sportsStore.isFlightPrematch(flight) ? filter?.prematch : filter?.inplay) || []

  const disabledMarkets = _.filter(options.disabled_markets, o => {
    return o.sportId === prematch.SportId
  })
  const filteredMarket = _.filter(markets, o => {
    return (
      _.findIndex(disabledMarkets, dm => {
        return dm.marketId === o.Id
      }) < 0
    )
  })

  const updateContents = async () => {
    setContents([])

    // force redraw and clear cached children
    await sleep(1)

    if (!filter) {
      return
    }

    const newContents = []

    const filterGroup = _.find(marketGroup, o => {
      return o.groupId === tab
    })

    if (filterGroup) {
      const exclusiveList: { group: string; id: string }[] = []
      for (const el of filterGroup.markets) {
        const market = _.find(filteredMarket, o => {
          return o.Id === el.id
        })
        if (market) {
          if (el.exclusive) {
            const exclusiveGroup = _.find(exclusiveList, o => {
              return o.group === el.exclusive.group
            })
            if (exclusiveGroup) {
              if (exclusiveGroup.id !== el.exclusive.id) {
                continue
              }
            }
            exclusiveList.push(_.clone(el.exclusive))
          }

          newContents.push(
            <DetailMarketbox
              key={el.id}
              theme={theme}
              groupData={el}
              prematch={prematch}
              market={market}
              baseLineConfig={flight === 'inplay' ? null : baseline_config}
            ></DetailMarketbox>,
          )
        }
      }
      // const exclusiveList: ExclusiveData[] = []
      // for (const el of filterGroup.markets) {
      //   const market = _.find(filteredMarket, o => {
      //     return o.Id === el.id
      //   })
      //   if (market) {
      //     if (el.exclusive) {
      //       const exclusiveGroup = _.find(exclusiveList, o => {
      //         return o.group === el.exclusive.group
      //       })
      //       if (exclusiveGroup) {
      //         if (exclusiveGroup.id !== el.exclusive.id) {
      //           continue
      //         }
      //       }
      //       exclusiveList.push(_.clone(el.exclusive))
      //     }

      //     let baseline_size = null
      //     let enabled_baseline_zero = false
      //     if (el.unifiedName === 'HDP') {
      //       baseline_size = baseline_config?.baseline_size_hdp
      //       enabled_baseline_zero = baseline_config?.enabled_hdp_baseline_zero
      //     } else if (el.unifiedName === 'UO') {
      //       baseline_size = baseline_config?.baseline_size_uo
      //       enabled_baseline_zero = baseline_config?.enabled_uo_baseline_zero
      //     }
      //     const node = DetailOddsFactory(el.unifiedName, theme, prematch, market, baseline_size, enabled_baseline_zero)
      //     if (node && node.length > 0) {
      //       const oddsItems = []
      //       const key = el.id.toString()
      //       for (const odds of node) {
      //         oddsItems.push(
      //           <Box key={`${key}-${oddsItems.length}`} sx={{ mt: oddsItems.length > 0 ? '8px' : '0px' }}>
      //             {odds}
      //           </Box>,
      //         )
      //       }
      //       newContents.push(
      //         <Box key={key} sx={{ width: '100%', mt: newContents.length > 0 ? 1 : 0 }}>
      //           <Box display="flex" alignItems="center" sx={{ p: 2, height: '36px', background: '#08192e' }}>
      //             <Typography sx={{ color: 'white' }}>
      //               {/* {market.Id} {el.name[lang] || el.name[lang].en} */}
      //               {el.name[lang] || el.name[lang].en}
      //             </Typography>
      //           </Box>
      //           <Divider sx={{ background: '#82a6d2' }}></Divider>
      //           <Box alignItems="center" sx={{ p: 2, background: '#08192e' }}>
      //             {oddsItems}
      //           </Box>
      //         </Box>,
      //       )
      //     }
      //   }
      // }
    }

    setContents(newContents)
  }

  useEffect(() => {
    updateContents()
  }, [tab, prematch])

  const handleChange = (event, newValue) => {
    setTab(parseInt(newValue))
  }

  if (!prematch) {
    return <></>
  }

  // menu
  let tabItems = []
  for (const group of marketGroup) {
    const items = _.filter(filteredMarket, o => {
      return _.find(group.markets, e => e.id === o.Id)
    }) as Schema$Market[]
    if (items.length > 0) {
      tabItems.push(
        <Tab
          key={group.groupId}
          label={
            <Typography sx={{ fontSize: '13px', color: 'white' }}>
              {group.groupName[lang]} ({items.length})
            </Typography>
          }
          value={group.groupId}
          {...TabItemProps(group.groupId)}
        />,
      )
    }
  }

  let topContent = null
  if (flight === 'inplay') {
    topContent = <InplayFixtureTrack desktop={desktop} prematch={prematch}></InplayFixtureTrack>
  } else {
    topContent = <PrematchFixtureTrack desktop={desktop} prematch={prematch}></PrematchFixtureTrack>
  }

  return (
    <Fragment>
      {topContent}
      <Box key="tabs">
        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          sx={{
            '& .MuiTab-root.Mui-selected': {
              color: '#ff5500',
            },
          }}
          TabIndicatorProps={{ style: { background: '#ff5500' } }}
          value={tab}
          onChange={handleChange}
          ScrollButtonComponent={props => {
            if (props.direction === 'left' && !props.disabled) {
              return (
                <IconButton {...props}>
                  <ArrowBackIosIcon fontSize="medium" sx={{ color: '#d6d6d6' }} />
                </IconButton>
              )
            } else if (props.direction === 'right' && !props.disabled) {
              return (
                <IconButton {...props}>
                  <ArrowForwardIosIcon fontSize="medium" sx={{ color: '#d6d6d6' }} />
                </IconButton>
              )
            } else {
              return null
            }
          }}
        >
          {tabItems}
        </Tabs>
      </Box>
      {contents}
    </Fragment>
  )
}

export default observer(ImplMarketDetail)
