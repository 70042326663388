import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

export const OffcanvasStyle = (theme: Theme) => css`
  top: 60px;
  background-color: #1a1a1a;

  @media (min-width: 992px) {
    top: 80px;
  }

  .offcanvas-header {
    justify-content: end;
  }
  .offcanvas-title {
    text-align: end;

    & > p {
      margin-bottom: 0;
      font-size: 1rem;
    }
    .nickname {
      color: ${theme.colors.themeTxt};
    }
    .account {
      color: ${theme.colors.primary};
    }
  }
  .offcanvas-body {
    padding: 0;
  }
`
export const AccordionStyle = css`
  .accordion-item {
    background-color: #0f1111;
    border: none;
  }

  .accordion-header {
    padding: 0 1.5rem;
    background-color: #262626;

    & > button {
      padding: 0.95rem 0;
      border-bottom: 1px solid #2e2e2e;
      background-color: transparent;
      color: #fff;
      font-size: 1.1rem;
    }
    & > button:not(.collapsed) {
      color: #b737fe;
      background-color: inherit;
    }
    & > button:focus {
      border-color: #2e2e2e;
    }
    & .accordion-button::after {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23989898'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    }
  }

  .accordion-body {
    padding: 0 1.5rem;
  }
`

export const NavStyle = css`
  .nav-link {
    padding: 0.95rem 0;
    border-bottom: 1px solid #2e2e2e;
    color: #fff;
    font-size: 1.1rem;

    &:hover {
      color: #b737fe;
    }
  }
`
