// chip
export const CHIP_BUTTON_TYPE = {
  GRAY: 'ex_chip_gray.png',
  RED: 'ex_chip_red.png',
  GREEN: 'ex_chip_green.png',
  BLACK: 'ex_chip_black.png',
  PURPLE: 'ex_chip_purple.png',
  BROWN: 'ex_chip_brown.png',
}

export interface ChipAttributes {
  id: number // volume
  title: string // volume in string
  fontSize: number
  type: string // CHIP_BUTTON_TYPE
  position?: { x: number; y: number }
}

// odds button
export interface OddsColor {
  normal: string
  over: string
  push: string
  highlight: string
}

export const ODDS_BUTTON_COLOR: { [id: string]: OddsColor } = {
  BLUE: {
    normal: 'b_normal.png',
    over: '',
    push: 'b_click.png',
    highlight: 'b_hover.png',
  },
  RED: {
    normal: 'r_normal.png',
    over: '',
    push: 'r_click.png',
    highlight: 'r_hover.png',
  },
  YELLOW: {
    normal: 'n_normal.png',
    over: '',
    push: 'n_click.png',
    highlight: 'n_hover.png',
  },
}

export interface OddsPosition {
  x: number
  y: number
}

export interface OddsSize {
  cx: number
  cy: number
}

export const ODDS_BUTTON_SIZE = {
  SMALL: { cx: 62, cy: 72 },
  MEDIUM: { cx: 74, cy: 72 },
  LARGE: { cx: 81, cy: 72 },
  HUGE: { cx: 187, cy: 72 },
}

export interface OddsHeaderData {
  id: string
  title: string
  size: OddsSize
  position: OddsPosition
}

export interface OddsAttributes {
  id: string
  marketId: string
  title: string
  rate: string
  color: OddsColor // ODDS_BUTTON_COLOR
  size: OddsSize
  position: OddsPosition
  headText?: string
}

export interface OddsTransform {
  id: string
  size: OddsSize
  position: OddsPosition
}

const buttonOffsetY: number[] = [70, 177]

export const FRIST_ROW_BUTTONS: OddsAttributes[] = [
  {
    id: 'NODD',
    marketId: 'NODD_NEVEN',
    title: '홀',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 0, y: buttonOffsetY[0] },
  },
  {
    id: 'NEVEN',
    marketId: 'NODD_NEVEN',
    title: '짝',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 64, y: buttonOffsetY[0] },
  },
  {
    id: 'PODD',
    marketId: 'PODD_PEVEN',
    title: 'P홀',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 128, y: buttonOffsetY[0] },
  },
  {
    id: 'PEVEN',
    marketId: 'PODD_PEVEN',
    title: 'P짝',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 192, y: buttonOffsetY[0] },
  },
  {
    id: 'NLARGE',
    marketId: 'NLMS',
    title: '대',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.YELLOW,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 256, y: buttonOffsetY[0] },
    headText: '81-130',
  },
  {
    id: 'NMEDIUM',
    marketId: 'NLMS',
    title: '중',
    rate: '2.4',
    color: ODDS_BUTTON_COLOR.YELLOW,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 320, y: buttonOffsetY[0] },
    headText: '65-80',
  },
  {
    id: 'NSMALL',
    marketId: 'NLMS',
    title: '소',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.YELLOW,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 384, y: buttonOffsetY[0] },
    headText: '15-64',
  },
  {
    id: 'NODD+NUNDER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '홀언더',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 454, y: buttonOffsetY[0] },
  },
  {
    id: 'NODD+NOVER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '홀오버',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 530, y: buttonOffsetY[0] },
  },
  {
    id: 'NEVEN+NUNDER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '짝언더',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 606, y: buttonOffsetY[0] },
  },
  {
    id: 'NEVEN+NOVER',
    marketId: 'NODD_NEVEN+NUNDER_NOVER',
    title: '짝오버',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.MEDIUM,
    position: { x: 682, y: buttonOffsetY[0] },
  },
]

export const SECOND_ROW_BUTTONS: OddsAttributes[] = [
  {
    id: 'NUNDER',
    marketId: 'NUNDER_NOVER',
    title: '언더',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 0, y: buttonOffsetY[1] },
    headText: '15-72',
  },
  {
    id: 'NOVER',
    marketId: 'NUNDER_NOVER',
    title: '오버',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 64, y: buttonOffsetY[1] },
    headText: '73-130',
  },
  {
    id: 'PUNDER',
    marketId: 'PUNDER_POVER',
    title: 'P언더',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 128, y: buttonOffsetY[1] },
    headText: '0-4',
  },
  {
    id: 'POVER',
    marketId: 'PUNDER_POVER',
    title: 'P오버',
    rate: '1.97',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.SMALL,
    position: { x: 192, y: buttonOffsetY[1] },
    headText: '5-9',
  },
  {
    id: 'NODD+NLARGE',
    marketId: 'NODD_NEVEN+NLMS',
    title: '홀대',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 265, y: buttonOffsetY[1] },
  },
  {
    id: 'NODD+NMEDIUM',
    marketId: 'NODD_NEVEN+NLMS',
    title: '홀중',
    rate: '2.4',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 348, y: buttonOffsetY[1] },
  },
  {
    id: 'NODD+NSMALL',
    marketId: 'NODD_NEVEN+NLMS',
    title: '홀소',
    rate: '2.5',
    color: ODDS_BUTTON_COLOR.BLUE,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 431, y: buttonOffsetY[1] },
  },
  {
    id: 'NEVEN+NLARGE',
    marketId: 'NODD_NEVEN+NLMS',
    title: '짝대',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 514, y: buttonOffsetY[1] },
  },
  {
    id: 'NEVEN+NMEDIUM',
    marketId: 'NODD_NEVEN+NLMS',
    title: '짝중',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 597, y: buttonOffsetY[1] },
  },
  {
    id: 'NEVEN+NSMALL',
    marketId: 'NODD_NEVEN+NLMS',
    title: '짝소',
    rate: '3.7',
    color: ODDS_BUTTON_COLOR.RED,
    size: ODDS_BUTTON_SIZE.LARGE,
    position: { x: 680, y: buttonOffsetY[1] },
  },
]

export const ENLARGED_FRIST_ROW_BUTTONS: OddsTransform[] = [
  {
    id: 'NODD',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 62, y: buttonOffsetY[0] },
  },
  {
    id: 'NEVEN',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 251, y: buttonOffsetY[0] },
  },
  {
    id: 'PODD',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 440, y: buttonOffsetY[0] },
  },
  {
    id: 'PEVEN',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 629, y: buttonOffsetY[0] },
  },
]

export const ENLARGED_SECOND_ROW_BUTTONS: OddsTransform[] = [
  {
    id: 'NUNDER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 62, y: buttonOffsetY[1] },
  },
  {
    id: 'NOVER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 251, y: buttonOffsetY[1] },
  },
  {
    id: 'PUNDER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 440, y: buttonOffsetY[1] },
  },
  {
    id: 'POVER',
    size: ODDS_BUTTON_SIZE.HUGE,
    position: { x: 629, y: buttonOffsetY[1] },
  },
]

// headers
export const FIRST_ROW_HEADERS: OddsHeaderData[] = [
  {
    id: 'NODD_NEVEN',
    title: '일반볼 홀짝',
    size: { cx: 124, cy: 24 },
    position: { x: 32, y: 19 },
  },
  {
    id: 'PODD_PEVEN',
    title: '파워볼 홀짝',
    size: { cx: 124, cy: 24 },
    position: { x: 160, y: 19 },
  },
  {
    id: 'LMS',
    title: '일반볼 대/중/소',
    size: { cx: 189, cy: 24 },
    position: { x: 320, y: 19 },
  },
  {
    id: 'NODD_NEVEN+NUNDER_NOVER',
    title: '일반볼 조합 (홀짝+언더오버)',
    size: { cx: 299, cy: 24 },
    position: { x: 568, y: 19 },
  },
]

export const SECOND_ROW_HEADERS: OddsHeaderData[] = [
  {
    id: 'NUNDER_NOVER',
    title: '일반볼 언더오버',
    size: { cx: 124, cy: 24 },
    position: { x: 32, y: 126 },
  },
  {
    id: 'PUNDER_POVER',
    title: '파워볼 언더오버',
    size: { cx: 124, cy: 24 },
    position: { x: 160, y: 126 },
  },
  {
    id: 'NODD_NEVEN+NLMS',
    title: '일반볼 조합 (홀짝+대중소)',
    size: { cx: 494, cy: 24 },
    position: { x: 472, y: 126 },
  },
]

export const ENLARGED_FRIST_ROW_HEADERS: OddsTransform[] = [
  {
    id: 'NODD_NEVEN',
    size: { cx: 374, cy: 24 },
    position: { x: 157, y: 19 },
  },
  {
    id: 'PODD_PEVEN',
    size: { cx: 374, cy: 24 },
    position: { x: 535, y: 19 },
  },
]

export const ENLARGED_SECCOND_ROW_HEADERS: OddsTransform[] = [
  {
    id: 'NUNDER_NOVER',
    size: { cx: 374, cy: 24 },
    position: { x: 157, y: 126 },
  },
  {
    id: 'PUNDER_POVER',
    size: { cx: 374, cy: 24 },
    position: { x: 535, y: 126 },
  },
]
