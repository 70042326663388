export default class Button extends Phaser.GameObjects.Container {
  upImage: Phaser.GameObjects.Image = null
  downImage: Phaser.GameObjects.Image = null
  disableImage: Phaser.GameObjects.Image = null

  captured = false

  enabled = true

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    upTexture: {
      texture: string | Phaser.Textures.Texture
      frame?: string | number
    },
    downTexture: {
      texture: string | Phaser.Textures.Texture
      frame?: string | number
    },
    disableTexture: {
      texture: string | Phaser.Textures.Texture
      frame?: string | number
    },
    onClick: () => void,
  ) {
    super(scene, x, y)

    this.scene.add.existing(this)

    this.upImage = scene.add.image(0, 0, upTexture.texture, upTexture.frame)
    this.downImage = scene.add.image(
      0,
      0,
      downTexture.texture,
      downTexture.frame,
    )
    this.disableImage = scene.add.image(
      0,
      0,
      disableTexture.texture,
      disableTexture.frame,
    )

    // this.upImage.setOrigin(0, 0);
    // this.downImage.setOrigin(0, 0);
    // this.disableImage.setOrigin(0, 0);

    this.add(this.upImage)
    this.add(this.downImage)
    this.add(this.disableImage)

    this.downImage.setVisible(false)
    this.disableImage.setVisible(false)

    this.setSize(this.upImage.width, this.upImage.height)

    this.setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
        this.captured = false
        if (this.enabled) {
          this.upImage.setVisible(true)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(false)
        } else {
          this.upImage.setVisible(false)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(true)
        }
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        const lastCaptured = this.captured
        this.captured = false
        if (this.enabled) {
          this.upImage.setVisible(true)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(false)
        } else {
          this.upImage.setVisible(false)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(true)
        }
        if (lastCaptured) {
          if (onClick) {
            onClick()
          }
        }
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.enabled) {
          return
        }
        this.captured = true
        this.upImage.setVisible(false)
        this.downImage.setVisible(true)
        this.disableImage.setVisible(false)
      })
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
    this.upImage.setVisible(enabled)
    this.downImage.setVisible(false)
    this.disableImage.setVisible(!enabled)
  }
}
