/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Route, Switch, useHistory } from 'react-router-dom'
import { useWidgetState, LiveChatWidget } from '@livechat/widget-react'

import { GlobalStore, MEDIA_MODE } from '@store/globalStore'
import { AuthStore } from '@store/authStore'
import MyPage from '~/containers/views/MyPage'
import Minigame from '~/containers/views/Minigame'
import GameList from '~/containers/views/Minigame/GameList'
import Guide from '@views/Guide'
import Notice from '@views/Notice'
import LiveCasino from '~/containers/views/LiveCasino'
import HotelCasino from '~/containers/views/HotelCasino'
import Bank from '@views/Bank'
import Slot from '@views/Slot'
import Reel from '../Reel'
import Sports from '~/containers/views/Sports'
import SportsBettingRecord from '~/containers/views/Sports/BettingRecord'
import {
  PartnerIntergratedBettingRecord,
  PartnerIntergratedChargeRecord,
  PartnerIntergratedDashboard,
  PartnerIntergratedStatsRevenue,
  PartnerIntergratedStatsCommission,
  PartnerIntergratedStatsGame,
  PartnerIntergratedBalanceAccountRecord,
  PartnerIntergratedBalanceAccountRequest,
  PartnerIntergratedMembers,
  PartnerIntergratedMembersConcurrent,
} from '@views/Partner'

import PopupLayout from '../Popup'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import useRootStore from '~/store/useRootStore'

// css
// default(pc)
import channel from '../Channels'
import Footer from './Footer'

// mobile
import MobileHeader from './m/Header'
import MobileSider from './m/Sider'
import Megahit from '../Megahit'
import { toJS } from 'mobx'
import HeaderWrapper from '../Channels/common/HeaderWrapper'
import { useIntl } from 'react-intl'

const { Header, Home } = channel

function SecondaryLayout(props) {
  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { authStore, globalStore, userStore } = useRootStore()
  const widgetState = useWidgetState()

  const { option, navigate, media_mode } = globalStore // watch media_mode to get event
  const { contact } = option || {}
  const { desktop } = globalStore.decideMedia(isDesktop)
  const { initialized, signedin } = authStore

  const initializeSession = async () => {
    await authStore.recoverSession(intl)
  }

  useEffect(() => {
    initializeSession()
    globalStore.getPublicSettings(intl)
  }, [])

  useEffect(() => {
    const [data] = navigate
    if (data) {
      history.push(data)
    }
  }, [navigate])

  if (!initialized || !option) {
    return <></>
  }

  if (option.maintenance) {
    history.push('/maintenance')
    return <></>
  }

  if (option.useLoginDoor && !signedin) {
    history.push('/login')
    return <></>
  }

  const { location } = props
  const { pathname } = location

  let livechatContents = null
  const { livechat, livechat_on_before_login } = contact || {}
  if (livechat && pathname === '/') {
    if (signedin) {
      const { id, nickname } = userStore.userInfo
      livechatContents = (
        <LiveChatWidget
          license={livechat}
          visibility="minimized"
          customerEmail={id}
          customerName={nickname}
        />
      )
    } else if (livechat_on_before_login) {
      livechatContents = <LiveChatWidget license={livechat} visibility="minimized" />
    }
  }

  if (widgetState) {
    widgetState.availability = 'online'
    // if (pathname === '/') {
    // } else {
    //   livechatContents = null
    // }
  }

  let header = null
  let sider = null

  if (desktop) {
    header = <HeaderWrapper Header={Header} />
  } else {
    header = <MobileHeader Header={Header} />
    sider = <MobileSider />
  }

  return (
    <Fragment>
      {header}
      {sider}
      <Switch>
        <Route exact path="/home" component={Home}></Route>
        <Route exact path="/live-casino" component={LiveCasino}></Route>
        <Route exact path="/hotel-casino" component={HotelCasino}></Route>
        <Route exact path="/slot" component={Slot}></Route>
        <Route exact path="/reel" component={Reel}></Route>
        <Route exact path="/sports" component={Sports}></Route>
        <Route exact path="/sports/record" component={SportsBettingRecord}></Route>
        <Route exact path="/minigame" component={GameList}></Route>
        <Route exact path="/minigame/:search" component={Minigame}></Route>
        <Route exact path="/msl" component={Megahit}></Route>
        <Route exact path="/notice" component={Notice}></Route>
        <Route exact path="/notice/:search" component={Notice}></Route>
        <Route exact path="/bank" component={Bank}></Route>
        <Route exact path="/bank/:search" component={Bank}></Route>
        <Route exact path="/mypage" component={MyPage}></Route>
        <Route exact path="/mypage/:search" component={MyPage}></Route>
        <Route exact path="/partner" component={PartnerIntergratedDashboard}></Route>
        <Route exact path="/partner/dashboard" component={PartnerIntergratedDashboard}></Route>
        <Route
          exact
          path="/partner/dashboard/:search"
          component={PartnerIntergratedDashboard}
        ></Route>
        <Route exact path="/partner/members" component={PartnerIntergratedMembers}></Route>
        <Route exact path="/partner/members/:search" component={PartnerIntergratedMembers}></Route>
        <Route
          exact
          path="/partner/members-concurrent"
          component={PartnerIntergratedMembersConcurrent}
        ></Route>
        <Route exact path="/partner/stats-game" component={PartnerIntergratedStatsGame}></Route>
        <Route
          exact
          path="/partner/stats-game/:search"
          component={PartnerIntergratedStatsGame}
        ></Route>
        <Route
          exact
          path="/partner/balance-record"
          component={PartnerIntergratedBalanceAccountRecord}
        ></Route>
        <Route
          exact
          path="/partner/balance-record/:search"
          component={PartnerIntergratedBalanceAccountRecord}
        ></Route>
        <Route
          exact
          path="/partner/balance-request"
          component={PartnerIntergratedBalanceAccountRequest}
        ></Route>
        <Route
          exact
          path="/partner/balance-request/:search"
          component={PartnerIntergratedBalanceAccountRequest}
        ></Route>
        <Route
          exact
          path="/partner/betting-record"
          component={PartnerIntergratedBettingRecord}
        ></Route>
        <Route
          exact
          path="/partner/betting-record/:search"
          component={PartnerIntergratedBettingRecord}
        ></Route>
        <Route
          exact
          path="/partner/charge-record"
          component={PartnerIntergratedChargeRecord}
        ></Route>
        <Route
          exact
          path="/partner/charge-record/:search"
          component={PartnerIntergratedChargeRecord}
        ></Route>
        <Route path="/" component={Home}></Route>
      </Switch>
      <Footer />
      {livechatContents}
    </Fragment>
  )
}

// export default function Wrapper() {
//   const store = useRootStore()
//   return (
//     <PrimaryLayout {...store} />
//     // <RootConsumer>
//     //   {({ globalStore, authStore, routerStore }) => (
//     //     <PrimaryLayout globalStore={globalStore} authStore={authStore} routerStore={routerStore} />
//     //   )}
//     // </RootConsumer>
//   )
// }

export default observer(SecondaryLayout)
