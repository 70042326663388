import {
  iconFlagCN,
  iconFlagEN,
  iconFlagKO,
  iconFlagVN,
  iconFlagPH,
  iconFlagTH,
  iconFlagMN,
} from '~/assets/images/layout_1'

const APP_LOCALES = process.env.REACT_APP_LOCALES ? JSON.parse(process.env.REACT_APP_LOCALES) : []

export enum LOCALES_KEYS {
  EN_US = 'en-US',
  KO_KR = 'ko-KR',
  ZH_CN = 'zh-CN',
  VI_VN = 'vi-VN',
  TL_PH = 'tl-PH',
  TH_TH = 'th-TH',
  MN_MN = 'mn-MN',
}

export const SUPPORT_LOCALES = [
  {
    name: 'English',
    value: LOCALES_KEYS.EN_US,
    img: iconFlagEN,
  },
  {
    name: '한국어',
    value: LOCALES_KEYS.KO_KR,
    img: iconFlagKO,
  },
  {
    name: '中文(简体)',
    value: LOCALES_KEYS.ZH_CN,
    img: iconFlagCN,
  },
  {
    name: 'tiếng Việt',
    value: LOCALES_KEYS.VI_VN,
    img: iconFlagVN,
  },
  {
    name: 'Tagalog',
    value: LOCALES_KEYS.TL_PH,
    img: iconFlagPH,
  },
  {
    name: 'ไทย',
    value: LOCALES_KEYS.TH_TH,
    img: iconFlagTH,
  },
  {
    name: 'Монгол',
    value: LOCALES_KEYS.MN_MN,
    img: iconFlagMN,
  },
]

export const DEFAULT_LOCALE = LOCALES_KEYS.KO_KR

// export interface LocaleResponse {
//   localeData: StringObject
//   antdLocaleData: Locale
// }

// export function getLocaleLoader(locale: string): Promise<LocaleResponse> {
//   switch (locale) {
//     case LOCALES_KEYS.KO_KR:
//       return new Promise(async resolve => {
//         const loc = await import(/* webpackChunkName: "ko-KR" */ './ko_KR.json').then(m => m.default)
//         const antdLoc = await import(/* webpackChunkName: "antd-ko-KR" */ 'antd/lib/locale-provider/ko_KR').then(
//           m => m.default,
//         )
//         resolve({ localeData: loc, antdLocaleData: antdLoc })
//       })
//     case LOCALES_KEYS.ZH_CN:
//       return new Promise(async resolve => {
//         const loc = await import(/* webpackChunkName: "zh-CN" */ './zh_CN.json').then(m => m.default)
//         const antdLoc = await import(/* webpackChunkName: "antd-zh-CN" */ 'antd/lib/locale-provider/zh_CN').then(
//           m => m.default,
//         )
//         resolve({ localeData: loc, antdLocaleData: antdLoc })
//       })
//     default:
//       return new Promise(async resolve => {
//         const loc = await import(/* webpackChunkName: "en-US" */ './en_US.json').then(m => m.default)
//         const antdLoc = await import(/* webpackChunkName: "antd-en-US" */ 'antd/lib/locale-provider/en_US').then(
//           m => m.default,
//         )
//         resolve({ localeData: loc, antdLocaleData: antdLoc })
//       })
//   }
// }

export function getInitialLocale() {
  const currentLocale = localStorage.getItem('locale') ?? DEFAULT_LOCALE
  let newLocale = null
  if (APP_LOCALES.length === 0) {
    if (currentLocale !== DEFAULT_LOCALE) {
      newLocale = DEFAULT_LOCALE
    }
  } else if (APP_LOCALES.length > 0) {
    const hasLocale = APP_LOCALES.indexOf(currentLocale) >= 0
    if (!hasLocale) {
      newLocale = APP_LOCALES[0]
    }
  }
  return newLocale
}

export function getVerifiedLocale() {
  const locale = (getInitialLocale() || localStorage.getItem('locale')) ?? DEFAULT_LOCALE
  return locale
}
