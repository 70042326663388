/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Container, Button, Grid, Box, Typography } from '@mui/material'

import asset_channel from '@assets/channel'
import JackpotCounter from './JackpotCounter'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { randomBetween } from '~/utils/utils'
import apiPublic from '@services/api/public'

const CounterBanner = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 1200,
  margin: 'auto',
  '& > .MuiBox-root': {
    position: 'absolute',
    top: 34,
    right: 40,
  },
  '.MuiTypography-root': {
    background: 'linear-gradient(#ffffff 0%, #ffd00e 30%, #d3922b 60%, #fafa0d 100%)',
    backgroundClip: 'text',
    color: 'transparent',
    fontFamily: 'Gmarket',
    filter: 'drop-shadow(3px 3px 4px #111)',
  }
}))

let timer: NodeJS.Timeout = null

function Jackpot() {
  const [volume, setVolume] = useState(0) // 최대 1000억자리

  const fetchJackpot = async () => {
    try {
      const { volume } = await apiPublic.jackpot({})
      setVolume(volume)
      // const r = Math.floor(randomBetween(111111111111, 999999999999))
      // setVolume(11111111111111)
    } catch (err) {
      //
    }
  }

  useOnMount(() => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setInterval(() => {
      fetchJackpot()
    }, 5000) as NodeJS.Timeout
    fetchJackpot()
  })

  useOnUnmount(() => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  })

  return (
    <CounterBanner>
      <img src={asset_channel.common.banner_s01} alt="small banner" />
      <Box sx={{ display: 'flex', alignItems: 'end', width: '54%', justifyContent: 'end' }}>
        <Typography sx={{ fontSize: 40, mr: 1, mb: 1 }}>₩ </Typography>
        <JackpotCounter volume={volume} />
      </Box>
    </CounterBanner>
  )
}

export default Jackpot
