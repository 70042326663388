import { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Box, Button, Paper, SwipeableDrawer, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import { styled } from '@mui/material/styles'

import ImplSportsBettingCart from './ImplMobileBettingCart'

// icon
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import BalanceIcon from '@mui/icons-material/Balance'
import useRootStore from '~/store/useRootStore'

const BetSlip = styled('div')(({ theme }) => ({
  //height: '100%',
  '& .btn_open': {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 1210,
    width: '64px',
    height: '42px',
  },
}))

const drawerBleeding = 42

const BetSlipTop = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: -drawerBleeding,
  right: 0,
  left: 0,
  visibility: 'visible',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  backgroundColor: '#eaeaea',
  //backgroundColor: theme.buttons.success,
}))

function BettingCartIndicator() {
  const [open, setOpen] = useState(false)
  const { sportsStore } = useRootStore()
  const { cartItems, dividendRate, lang } = sportsStore

  const cartSize = cartItems?.length || 0
  const visible = cartSize > 0

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen)
  }

  if (!visible && open) {
    setOpen(false)
  }

  return (
    <BetSlip>
      <Button className="btn_open" onClick={toggleDrawer(true)}>
        &nbsp;&nbsp;&nbsp;
      </Button>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          zIndex: 1220,
          '.PrivateSwipeArea-root': {
            zIndex: 1220,
          },
          '.MuiPaper-root': {
            height: `calc(100% - ${drawerBleeding}px)`,
            overflow: 'visible',
          },
        }}
      >
        {visible && (
          <BetSlipTop>
            <Box
              sx={{ width: '100%', height: '100%' }}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h6"
                  component="h3"
                  sx={{
                    px: 2,
                    py: 1,
                    fontWeight: 'bold',
                  }}
                >
                  BET SLIP
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <ShoppingCartIcon sx={{ color: '#ff5500' }}></ShoppingCartIcon>
                  {lang === 'ko' ? (
                    <>
                      <Typography
                        sx={{
                          ml: '2px',
                          fontSize: '13px',
                        }}
                      >
                        선택경기
                      </Typography>
                      <Typography
                        sx={{
                          ml: '5px',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#ff5500',
                        }}
                      >
                        {cartSize} 건
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          ml: '2px',
                        }}
                      >
                        {cartSize}
                      </Typography>
                      <Typography
                        sx={{
                          ml: '5px',
                          fontSize: '13px',
                          fontWeight: 'bold',
                          color: '#ff5500',
                        }}
                      >
                        Selections
                      </Typography>
                    </>
                  )}
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center" sx={{ ml: 2 }}>
                  <BalanceIcon sx={{ color: '#2683bf' }}></BalanceIcon>
                  <Typography
                    sx={{
                      ml: '3px',
                      fontSize: '13px',
                    }}
                  >
                    {lang === 'ko' ? '예상배당' : 'Total Odds'}
                  </Typography>
                  <Typography
                    sx={{
                      ml: '5px',
                      color: '#357ae2',
                      fontSize: '13px',
                      fontWeight: 'bold',
                    }}
                  >
                    {dividendRate}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Button onClick={toggleDrawer(false)} sx={{ color: '#747D8A' }}>
                  {!open ? <ExpandLess /> : <ExpandMore />}
                </Button>
              </Box>
            </Box>
          </BetSlipTop>
        )}
        {open && <ImplSportsBettingCart></ImplSportsBettingCart>}
      </SwipeableDrawer>
    </BetSlip>
  )
}

export default observer(BettingCartIndicator)
