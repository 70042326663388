/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useRef } from 'react'
import { observer } from 'mobx-react'
import {
  Dialog,
  Box,
  Grid,
  Stack,
  IconButton,
  AppBar,
  Typography,
  FormControlLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import _ from 'lodash'
import axios from 'axios'

import useRootStore from '@store/useRootStore'
import apiUser from '@services/api/user'
import { useHistory } from 'react-router-dom'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import { ModalKeys } from '~/store/globalStore'
import http from '~/services/http'

// icon
import CloseIcon from '@mui/icons-material/Close'
import ClearIcon from '@mui/icons-material/Clear'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'
import { MobileModalStyle } from '@styles/modal.style'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const ButtonFileUpload = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  padding: '0.2rem 0.6rem',
  borderRadius: '0.3rem',
  backgroundColor: theme.buttons.primary,
  color: theme.colors.btnTxtLighten,
  '& > input[type="file"]': {
    display: 'none',
  },
  '& > span': {
    fontSize: '0.9rem',
  },
}))

const ImageBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  width: 140,
  height: 140,
  border: `1px solid ${theme.forms.formLine}`,
  backgroundColor: theme.forms.disabledBg,
  '& > img': {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  '& .MuiIconButton-root': {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.colors.btnTxtLighten,
  },
}))

const ChannelBg = () => css`
  .MuiPaper-root {
    background-color: #0a1424;
  }
`

const PopupWriteQnaPersonal: React.FC<{}> = () => {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const [file, setFile] = useState(null)
  const [imgUrl, setImgUrl] = useState('')
  const fileInput = useRef()

  const { initialized, signedin } = authStore
  const { currentKey, currentValue } = globalStore
  const modalKey = ModalKeys.write_qna_personal

  const type = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = type !== ''
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
  }

  const initialValues = { title: '', content: '' }

  if (type === 'deposit') {
    initialValues.title = intl.formatMessage({ id: 'deposit-inquiry' })
    initialValues.content = intl.formatMessage({ id: 'deposit-account-inquiry' })
  } else if (type === 'deposit.normal') {
    initialValues.title = intl.formatMessage({ id: 'deposit-inquiry.normal' })
    initialValues.content = intl.formatMessage({ id: 'deposit-account-inquiry.normal' })
  } else if (type === 'deposit.virtual') {
    initialValues.title = intl.formatMessage({ id: 'deposit-inquiry.virtual' })
    initialValues.content = intl.formatMessage({ id: 'deposit-account-inquiry.virtual' })
  } else if (type === 'sports') {
    // only jaba
    initialValues.title = intl.formatMessage({ id: 'sports-inquiry' })
    initialValues.content = intl.formatMessage({ id: 'sports-point-inquiry' })
  }

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const submit = async (values: any) => {
    const { title, content } = values
    if (!_.isString(title) || title.length === 0) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.qna-write.title-require' })}`,
      })
      return
    }

    if (!_.isString(content) || content.length === 0) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.qna-write.content-require' })}`,
      })
      return
    }

    try {
      const qnaType = type === 'deposit' ? 'DEPOSIT' : 'NORMAL'
      await apiUser.writeQna({ kind: qnaType, title, content, img: imgUrl })
      globalStore.hideModal(modalKey)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.qna-write.done' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onChangeFile = async e => {
    e.stopPropagation()
    e.preventDefault()
    setImgUrl(null)
    setFile(null)

    const filename = e.target.files[0].name.toLowerCase()
    const rexp = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/
    if (!rexp.test(filename)) {
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.image_formation_validation' }),
      })
      return
    }

    const maxAllowedSize = 5 * 1024 * 1024
    if (e.target.files[0].size > maxAllowedSize) {
      globalStore.pushDialogOk({ text: intl.formatMessage({ id: 'msg.image_size_validation' }) })
      return
    }

    let validBaseUrl = process.env.REACT_APP_API_BASEURL || 'https://localhost'
    const env = process.env.NODE_ENV
    if (env === 'production') {
      validBaseUrl = window.location.origin
    }

    const formData = new FormData()
    formData.append('img-qna', e.target.files[0])
    try {
      const { token } = await http.get('api/public/token', {})
      const resp = await axios({
        method: 'post',
        url: `${validBaseUrl}/api/upload/img-qna`,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data', 'csrf-token': token },
        withCredentials: true,
      })
      // console.log(resp)
      const { data } = resp || {}
      const { url, error, message } = data
      if (error) {
        resetFile()
        globalStore.pushError(error, message, intl, null)
      } else {
        setImgUrl(url)
        setFile({
          file: URL.createObjectURL(e.target.files[0]),
        })
      }
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }
  const resetFile = () => {
    ;(fileInput.current as any).value = ''
    setImgUrl(null)
    setFile(null)
  }

  let imgSrc = null
  if (imgUrl) {
    imgSrc = `${window.location.origin}/${imgUrl}`
  }

  return (
    <Dialog open={visible} onClose={onClose} css={[MobileModalStyle, ChannelBg]}>
      <Box className="modal_content">
        <AppBar sx={{ position: 'relative' }}>
          <Typography className="modal_title" variant="h6" component="div">
            {intl.formatMessage({ id: 'component.private-qna.write' })}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item xs={12}>
              <Formik
                initialValues={initialValues}
                onSubmit={submit}
                // validationSchema={Yup.object().shape({
                //   volume: Yup.string().required(intl.get('msg.field-require')),
                // })}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <FormStyle>
                      <Box sx={{ mb: 2 }}>
                        <label className="form_label" style={{ marginBottom: '5px' }}>
                          {intl.formatMessage({ id: 'table.title' })}
                        </label>
                        <input
                          className="form_control"
                          name="title"
                          placeholder={intl.formatMessage({
                            id: 'component.partner.title-placeholder',
                          })}
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.title && errors.title && <div>{errors.title}</div>}
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <label className="form_label" style={{ marginBottom: '5px' }}>
                          {intl.formatMessage({ id: 'table.content' })}
                        </label>
                        <textarea
                          className="form_control"
                          rows={6}
                          name="content"
                          placeholder={intl.formatMessage({
                            id: 'component.partner.content-placeholder',
                          })}
                          value={values.content}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {touched.content && errors.content && <div>{errors.content}</div>}
                      </Box>
                      <Box>
                        <label className="form_label" style={{ marginBottom: '5px' }}>
                          {intl.formatMessage({ id: 'table.upload-file' })}
                        </label>
                        <Stack direction="row">
                          <Box>
                            <ButtonFileUpload
                              label={intl.formatMessage({ id: 'table.select-file' })}
                              control={
                                <input type="file" ref={fileInput} onChange={onChangeFile} />
                              }
                            />
                          </Box>
                          <Stack direction="row" sx={{ '& > *': { mr: 1 } }}>
                            {file ? (
                              <ImageBox>
                                <img src={imgSrc} alt="" />
                                <IconButton onClick={resetFile}>
                                  <ClearIcon />
                                </IconButton>
                              </ImageBox>
                            ) : null}
                            {/* sample
                             <ImageBox>
                              <img src="https://previews.123rf.com/images/rglinsky/rglinsky1201/rglinsky120100188/12336990-vertical-oriented-image-of-famous-eiffel-tower-in-paris-france-.jpg" alt="sample" />
                            </ImageBox>
                            <ImageBox>
                              <img src="https://www.cleverfiles.com/howto/wp-content/uploads/2018/03/minion.jpg" alt="sample" />
                            </ImageBox> */}
                          </Stack>
                        </Stack>
                      </Box>

                      <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 5 }}>
                        <BasicButton
                          className=" btn_main"
                          type="submit"
                          disabled={isSubmitting}
                          sx={{ width: '100%' }}
                        >
                          {intl.formatMessage({ id: 'submit-qna' })}
                        </BasicButton>
                      </Stack>
                    </FormStyle>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default observer(PopupWriteQnaPersonal)
