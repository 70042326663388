export default class BetButton extends Phaser.GameObjects.Container {
  upImage: Phaser.GameObjects.Image = null
  downImage: Phaser.GameObjects.Image = null
  disableImage: Phaser.GameObjects.Image = null

  title: Phaser.GameObjects.Text = null

  bloomFX: Phaser.FX.Bloom = null
  tweenFX: Phaser.Tweens.Tween = null
  bloomEnabled = false

  captured = false

  enabled = true

  constructor(scene: Phaser.Scene, x: number, y: number, onClick: () => void) {
    super(scene, x, y)

    this.scene.add.existing(this)

    this.upImage = scene.add.image(0, 0, 'ui', 'btn_bet.png')
    this.downImage = scene.add.image(0, 0, 'ui', 'btn_bet_click.png')
    this.disableImage = scene.add.image(0, 0, 'ui', 'btn_bet.png')

    this.title = scene.add
      .text(0, 0, '베팅하기', {
        fontSize: '16px',
        color: '#ffffff',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)

    this.add(this.upImage)
    this.add(this.downImage)
    this.add(this.disableImage)
    this.add(this.title)

    this.downImage.setVisible(false)
    this.disableImage.setVisible(false)

    this.setSize(this.upImage.width, this.upImage.height)

    // interactives

    this.setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
        this.captured = false
        if (this.enabled) {
          this.upImage.setVisible(true)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(false)
        } else {
          this.upImage.setVisible(false)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(true)
        }
        this.title.setTint(0xffffff)
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        const lastCaptured = this.captured
        this.captured = false
        if (this.enabled) {
          this.upImage.setVisible(true)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(false)
        } else {
          this.upImage.setVisible(false)
          this.downImage.setVisible(false)
          this.disableImage.setVisible(true)
        }
        this.title.setTint(0xffffff)
        if (lastCaptured) {
          if (onClick) {
            onClick()
          }
        }
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.enabled) {
          return
        }
        this.captured = true
        this.upImage.setVisible(false)
        this.downImage.setVisible(true)
        this.disableImage.setVisible(false)
        this.title.setTint(0x000000)
      })

    this.setEnabled(false)

    //
    this.bloomFX = this.upImage.postFX.addBloom(0xffffff, 1, 1, 0, 1.2)

    this.tweenFX = this.scene.tweens.add({
      targets: this.bloomFX,
      blurStrength: 2,
      yoyo: true,
      duration: 500,
      paused: true,
      onComplete: () => {
        this.tweenFX.restart()
        this.tweenFX.pause()

        if (this.bloomEnabled) {
          setTimeout(() => {
            this.tweenFX.restart()
            this.tweenFX.play()
          }, 0)
        }
      },
    })

    this.setEnabled(true)
  }

  setBloom(enabled: boolean) {
    this.bloomEnabled = enabled
    if (this.enabled && this.bloomEnabled) {
      this.tweenFX.restart()
      this.tweenFX.play()
    }
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
    this.upImage.setVisible(enabled)
    this.downImage.setVisible(false)
    this.disableImage.setVisible(!enabled)
    this.title.setTint(0xffffff)

    const alpha = enabled ? 1 : 0.3
    this.upImage.setAlpha(alpha)
    this.disableImage.setAlpha(alpha)
    this.title.setAlpha(alpha)

    if (!enabled) {
      this.setBloom(false)
    }

    if (this.enabled && this.bloomEnabled) {
      this.tweenFX.restart()
      this.tweenFX.play()
    }
  }
}
