/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Box, Grid, Divider, Stack, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import _ from 'lodash'
import apiUser from '@services/api/user'

import useRootStore from '@store/useRootStore'
import { IMyBankInfo, Schema$MyBankInfo } from '@protocol/bank'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

function ChangePassword(props) {
  const intl = useIntl()
  const history = useHistory()
  // const [bankInfo, setBankInfo] = useState<Schema$MyBankInfo | null>(null)
  const { globalStore, userStore } = useRootStore()

  // async function fetchBankInfo() {
  //   try {
  //     const { info } = await apiUser.getBankInfo({})
  //     setBankInfo(info)
  //   } catch (err) {
  //     globalStore.pushDialogOk({
  //       title: intl.formatMessage({ id: 'error' }),
  //       text: err.message,
  //     })
  //   }
  // }

  // useEffect(() => {
  //   fetchBankInfo()
  // }, [])

  const submit = async (values: any) => {
    const { password, newPassword, newPassword2 } = values
    if (newPassword !== newPassword2) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.change-password-new-confirm' })}`,
      })
      return
    }

    const succeed = await userStore.changePassword({ password, newPassword }, intl)
    if (succeed) {
      history.push('/')
    }
  }

  return (
    <Grid container sx={{ py: 6, justifyContent: 'center' }}>
      <Grid item xs={10}>
        <Box sx={{ pb: 5, textAlign: 'center', color: 'white' }}>
          {intl.formatMessage({ id: 'modify-password' })}
        </Box>
        <Formik
          initialValues={{ password: '', newPassword: '', newPassword2: '' }}
          onSubmit={submit}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
            newPassword: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
            newPassword2: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
          })}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FormStyle>
                {/* <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                  <Grid item xs={4}>
                    <label className="form_label">연동계좌</label>
                  </Grid>
                  <Grid item xs={8} columnGap={1} sx={{ display: 'flex' }}>
                    <input
                      className="form_control"
                      type="text"
                      name="bank"
                      value={bankName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    <input
                      className="form_control"
                      type="text"
                      name="accountNumber"
                      value={accountNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ minWidth: '60%' }}
                      disabled
                    />
                  </Grid>
                </Grid> */}
                <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                  <Grid item xs={4}>
                    <label className="form_label">
                      {intl.formatMessage({ id: 'current-password' })}
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <input
                      className="form_control"
                      type="password"
                      name="password"
                      placeholder={intl.formatMessage({
                        id: 'component.mypage.password-placeholder',
                      })}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.password && errors.password && (
                      <Typography sx={{ color: '#FE3D3D' }}>{errors.password}</Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                  <Grid item xs={4}>
                    <label className="form_label">
                      {intl.formatMessage({ id: 'new-password' })}
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <input
                      className="form_control"
                      type="password"
                      name="newPassword"
                      placeholder={intl.formatMessage({
                        id: 'component.mypage.new-password-placeholder',
                      })}
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.newPassword && errors.newPassword && (
                      <Typography sx={{ color: '#FE3D3D' }}>{errors.newPassword}</Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                  <Grid item xs={4}>
                    <label className="form_label">
                      {intl.formatMessage({ id: 'verify-password' })}
                    </label>
                  </Grid>
                  <Grid item xs={8}>
                    <input
                      className="form_control"
                      type="password"
                      name="newPassword2"
                      placeholder={intl.formatMessage({
                        id: 'component.mypage.verify-password-placeholder',
                      })}
                      value={values.newPassword2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.newPassword2 && errors.newPassword2 && (
                      <Typography sx={{ color: '#FE3D3D' }}>{errors.newPassword2}</Typography>
                    )}
                  </Grid>
                </Grid>
              </FormStyle>

              <Stack direction="row" justifyContent="center" spacing={2} sx={{ pt: 3 }}>
                <BasicButton
                  className="btn_main"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{ width: '50%' }}
                >
                  {intl.formatMessage({ id: 'change-password' })}
                </BasicButton>
              </Stack>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default observer(ChangePassword)
