import { observable, action, computed, makeObservable } from 'mobx'
import { Socket } from '@services/websocket'
import { StoreExt } from '@utils/reactExt'

export interface Message {
  event: string
  time?: number
  from: 'browser' | 'server' | 'console'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any
}

export enum SOCKET_SERVICE_STATE {
  DISCONNECTED = 0,
  CONNECTING = 1,
  CONNECTED = 2,

  ENTERING = 3,
  ENTERED = 4,
  MAX = 5,
}

export const getStateString = (state: SOCKET_SERVICE_STATE) => {
  let ss = ''
  switch (state) {
    case SOCKET_SERVICE_STATE.DISCONNECTED:
      ss = 'DISCONNECTED'
      break
    case SOCKET_SERVICE_STATE.CONNECTING:
      ss = 'CONNECTING'
      break
    case SOCKET_SERVICE_STATE.CONNECTED:
      ss = 'CONNECTED'
      break
    case SOCKET_SERVICE_STATE.ENTERING:
      ss = 'ENTERING'
      break
    case SOCKET_SERVICE_STATE.ENTERED:
      ss = 'ENTERED'
      break
  }
  return ss
}

export type DispatchMessage = (msg: Message) => void

const coroutineInTimer = async (func: Function, timeout): Promise<void> => {
  return new Promise(resolve => {
    setTimeout(() => {
      func()
      resolve()
    }, timeout)
  })
}

type ConditionFunc = () => boolean
const coroutineInCondition = async (func: ConditionFunc, delays = 200): Promise<void> => {
  return new Promise(resolve => {
    const funcLoop = () => {
      setTimeout(() => {
        const ret = func()
        if (ret === true) {
          resolve()
        } else {
          funcLoop()
        }
      })
    }
    funcLoop()
  })
}

/**
 * socket debugger store
 *
 * @export
 * @class SocketStore
 * @extends {StoreExt}
 */
export class SocketStore extends StoreExt {
  @observable
  socketIsConnected = false

  @observable
  serviceState = SOCKET_SERVICE_STATE.DISCONNECTED

  @observable
  messages: Message[] = []

  url: string
  socket: Socket = null
  dispatchMessage: DispatchMessage = null

  constructor() {
    super()

    this.rebind()

    makeObservable(this)
  }

  rebind = () => {
    if (this.socket) {
      this.socket.cleanup()
    }

    this.socket = new Socket(this)
    this.socketIsConnected = false
    this.serviceState = SOCKET_SERVICE_STATE.DISCONNECTED
  }

  close = () => {
    this.socket.disconnect()
    this.setServiceState(SOCKET_SERVICE_STATE.DISCONNECTED)
  }

  connect = async () => {
    try {
      // console.log('SocketStore enter')
      if (this.serviceState !== SOCKET_SERVICE_STATE.DISCONNECTED) {
        return
      }
      this.setServiceState(SOCKET_SERVICE_STATE.CONNECTING)
      this.socket.connect(this.url)
      // await coroutineInTimer(() => {
      //   this.setServiceState(SOCKET_SERVICE_STATE.CONNECTING)
      // }, 1000)

      // if (this.socketIsConnected === false) {
      //   this.socket.connect(this.url)
      //   await coroutineInCondition(() => {
      //     return this.socketIsConnected
      //   })
      //   await coroutineInTimer(() => {
      //     this.setServiceState(SOCKET_SERVICE_STATE.CONNECTED)
      //   }, 100)
      // } else {
      //   console.error('SocketStore.enter already SOCKET_SERVICE_STATE.CONNECTED')
      // }
    } catch (err) {
      throw err
    }
  }

  sendPacket = (protocol: number, data) => {
    // console.log(`sendPacket > protocol: ${protocol}`)
    this.socket.sendMessage({
      protocol,
      data,
    })
  }

  // @action
  // setSessionSpace = (space: string) => {
  //   this.sessionSpace = space
  // }

  @action
  setServiceState = (state: SOCKET_SERVICE_STATE): SOCKET_SERVICE_STATE => {
    this.serviceState = state
    return state
  }

  @action
  setSocketIsConnected = (socketIsConnected: boolean) => {
    this.socketIsConnected = socketIsConnected
  }

  @action
  clearMessages = () => {
    this.messages = []
  }

  @action
  addMessage = (message: Message) => {
    if (!message.time) {
      message.time = new Date().getTime()
    }
    this.messages = this.messages.concat([message])

    if (this.dispatchMessage) {
      this.dispatchMessage(message)
    }
  }
}

export default new SocketStore()
