import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Box, Container, Grid, Typography, Divider, Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import useRootStore from '@store/useRootStore'
import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import apiPublic from '@services/api/public'
import { Schema$NoticeItem } from '@interface/common'
import { INotices } from '@protocol/public'
import asset_channel from '@assets/channel'

import Jackpot from './Jackpot'
import NoticeBox from './NoticeBox'
import WithdrawRankingBox from './WithdrawRankingBox'
import RealtimeTransferBox from './RealtimeTransferBox'
import TopBanner from './TopBanner'

// icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

// css
import { MobileHomeContent, SnsLink } from './index.style'

// image
import {
  imgTelegram,
  imgKakaotalk,
  gameTitle01,
  gameTitle02,
  gameTitle03,
  gameTitle04,
  gameTitle05,
  gameTitle06,
  gameTitle07,
  gameTitle08,
  gameTitle09,
  gameTitle10,
  gameTitle11,
  gameTitle12,
  gameTitle13,
  gameTitle14,
  gameTitle15,
  gameTitle16,
  gameTitle17,
  gameTitle18,
  gameTitle19,
  gameTitle20,
  gameTitle21,
} from '@assets/images/layout_1'

import { AllSlotGames } from '~/game/slot_data'
import { AllMegahitGames } from '~/game/mg_data'
import { AllCaGames } from '~/game/ca_data'
import { AllMiniGames } from '~/game/mg_data'
import { splitOpenTradingKinds } from '~/game/util'

import { AllReelGames } from '~/game/reel_data'
import { STAR_GAME_ID, STAR_GAME_INFO } from '~/v2/interface/types'

const MainBanner = styled('div')`
  width: 100%;
  //overflow: hidden;

  & > div {
    position: relative;
    height: 360px;
  }

  & > div > img {
    position: absolute;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
`
const MainBannerTitle = styled('div')`
  position: absolute;
  top: 0;
  left: 50%;
  padding-top: 15%;
  width: 90%;
  font-weight: bold;
  font-size: 2rem;
  color: #fff;
  transform: translate(-50%, 0);
  text-shadow: #000 1px 1px 5px, #000 1px 1px 5px, #000 1px 1px 5px;
`

const APP_TITLE = process.env.REACT_APP_TITLE || 'Unknown'

const SEASTORY_INFO = _.find(STAR_GAME_INFO, o => {
  return o.gameId === STAR_GAME_ID.SEASTORY
})

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const Home: React.FC = props => {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const { option } = globalStore
  const { mainTitle, htmlMainTitleMobile, contact, open_trading_kinds, showDepositWithdrawBox } =
    option || {}
  const { signedin } = authStore

  const [notices, setNotices] = useState<Schema$NoticeItem[]>([])

  useEffect(() => {
    globalStore.getPublicSettings(intl)
    fetchNotices()
  }, [])

  const fetchNotices = async () => {
    try {
      const params: INotices.Params = {
        kind: 'ALL',
        limit: 5,
        offset: 0,
      }
      const { kind, offset, limit, total, items } = await apiPublic.getNotices(params)
      const notices: Schema$NoticeItem[] = [...items]
      setNotices(notices)
    } catch (err) {}
  }

  const onClickGameCard = (group: string, kind: string) => {
    if (group === 'REEL') {
      globalStore.signedinNewGame(group, kind, signedin, intl)
      return
    }
    globalStore.leadToGame(group, kind, history, signedin, intl)
  }

  const onClickMoreCasino = () => {
    history.push('/live-casino')
  }

  const onClickMoreSlot = () => {
    history.push('/slot')
  }

  const onClickMoreMinigame = () => {
    history.push('/minigame')
  }

  const caContents = []
  const slContents = []
  const mgContents = []

  const { lca, hca, sl, reel, mg, sp } = splitOpenTradingKinds(open_trading_kinds, null)

  // ca
  const caOrdered = []
  const tmp = []
  tmp.push(...lca.slice(0, 3))
  tmp.push(...hca)
  tmp.push(...lca.slice(3, lca.length - 1))
  caOrdered.push(...tmp.slice(0, 4))
  for (const trading of caOrdered) {
    const el = _.find(AllCaGames, o => {
      return o.kind === trading.trading_kind
    })
    if (el) {
      caContents.push(
        <Grid item xs={6} key={el.kind}>
          <a onClick={() => onClickGameCard(trading.trading_group, trading.trading_kind)}>
            <img src={el.thumbnail} alt={el.kind} style={{ width: '100%' }} />
            <p className="marketing_banner_text">{trading.name}</p>
          </a>
        </Grid>,
      )
    }
  }

  const arr = reel.slice(0, 1)
  const merged = arr.concat(sl)

  // slot
  for (const trading of merged) {
    let el = _.find(AllSlotGames, o => {
      return o.kind === trading.trading_kind
    })
    if (!el) {
      el = _.find(AllReelGames, o => {
        return o.kind === trading.trading_kind
      })
    }
    if (el) {
      let name = trading.name
      if (trading.trading_kind.indexOf('STAR_SEASTORY') >= 0) {
        name = SEASTORY_INFO?.title || name
      }
      slContents.push(
        <Grid item xs={6} key={el.kind}>
          <a onClick={() => onClickGameCard(trading.trading_group, trading.trading_kind)}>
            <img src={el.thumbnail} alt={el.kind} style={{ width: '100%' }} />
            <p className="marketing_banner_text">{name}</p>
          </a>
        </Grid>,
      )
    }
    if (slContents.length >= 4) {
      break
    }
  }

  // minigame
  for (const trading of mg) {
    const el = _.find(AllMiniGames, o => {
      return o.kind === trading.trading_kind
    })
    if (el) {
      mgContents.push(
        <Grid item xs={6} key={el.kind}>
          <a onClick={() => onClickGameCard(trading.trading_group, trading.trading_kind)}>
            <img src={el.thumbnail} alt={el.kind} style={{ width: '100%' }} />
            <p className="marketing_banner_text">{trading.name}</p>
          </a>
        </Grid>,
      )
    }
    if (mgContents.length >= 4) {
      break
    }
  }

  const contents = (
    <>
      {caContents.length > 0 ? (
        <Box className="marketing_banner">
          <Box className="game_title">
            <Typography>{intl.formatMessage({ id: 'menu.header.live-casino' })}</Typography>
            <button type="button" onClick={() => onClickMoreCasino()} className="more">
              <Typography component="span">{intl.formatMessage({ id: 'more' })}</Typography>
              <ArrowForwardIosIcon sx={{ width: '14px' }} />
            </button>
          </Box>
          {caContents.length > 0 ? (
            <Grid container spacing={2}>
              {caContents}
            </Grid>
          ) : null}
        </Box>
      ) : null}
      {slContents.length > 0 ? (
        <Box className="marketing_banner">
          <Box className="game_title">
            <Typography>{intl.formatMessage({ id: 'menu.header.slot' })}</Typography>
            <button type="button" onClick={() => onClickMoreSlot()} className="more">
              <Typography component="span">{intl.formatMessage({ id: 'more' })}</Typography>
              <ArrowForwardIosIcon sx={{ width: '14px' }} />
            </button>
          </Box>
          {slContents.length > 0 ? (
            <Grid container spacing={2}>
              {slContents}
            </Grid>
          ) : null}
        </Box>
      ) : null}
      {mgContents.length > 0 ? (
        <Box className="marketing_banner">
          <Box className="game_title">
            <Typography>{intl.formatMessage({ id: 'menu.header.minigame' })}</Typography>
            <button type="button" onClick={() => onClickMoreMinigame()} className="more">
              <Typography component="span">{intl.formatMessage({ id: 'more' })}</Typography>
              <ArrowForwardIosIcon sx={{ width: '14px' }} />
            </button>
          </Box>
          {mgContents.length > 0 ? (
            <Grid container spacing={2}>
              {mgContents}
            </Grid>
          ) : null}
        </Box>
      ) : null}
    </>
  )

  let kakaotalk = ''
  let kakaotalk_url = ''
  let telegram = ''
  if (contact) {
    kakaotalk = contact.kakaotalk
    kakaotalk_url = contact.kakaotalk_url
    telegram = contact.telegram
  }

  const onClickOpenTelegram = id => {
    // @를 제외한 아이디
    window.open(`https://t.me/${id}`)
  }

  return (
    <MobileHomeContent>
      <MainBanner>
        {/* <div>
          <img src={asset_channel.common.m_main_banner} alt="main banner" />
          <MainBannerTitle>
            {htmlMainTitleMobile ? (
              <div dangerouslySetInnerHTML={{ __html: htmlMainTitleMobile }}></div>
            ) : (
              <Typography style={{ fontSize: '2rem', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
                {mainTitle}
              </Typography>
            )}
          </MainBannerTitle>
        </div> */}
        <TopBanner />
      </MainBanner>

      <Container sx={{ position: 'relative' }}>
        <Jackpot />
        <Box sx={{ mb: 6, p: '0 16px 24px', backgroundColor: appBackground }}>{contents}</Box>

        {showDepositWithdrawBox ? (
          <Grid container rowSpacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <NoticeBox></NoticeBox>
            </Grid>
            <Grid item xs={12}>
              <WithdrawRankingBox></WithdrawRankingBox>
            </Grid>
            <Grid item xs={12}>
              <RealtimeTransferBox></RealtimeTransferBox>
            </Grid>
          </Grid>
        ) : null}

        <SnsLink container rowSpacing={1} sx={{ mb: 6 }}>
          {telegram ? (
            <Grid item xs={12}>
              <Box className="main_list">
                <div className="main_title">
                  <span className="title_wrap">
                    <img src={imgTelegram} />
                    <span className="title">{intl.formatMessage({ id: 'msg.telegram' })}</span>
                  </span>
                  <Button
                    onClick={() => onClickOpenTelegram(telegram)}
                    sx={{
                      textTransform: 'none',
                      fontSize: '1.2rem',
                      paddingRight: '18px',
                    }}
                  >
                    @{telegram}
                  </Button>
                </div>
              </Box>
            </Grid>
          ) : null}

          {kakaotalk ? (
            <Grid item xs={12}>
              <Box className="main_list">
                <div className="main_title">
                  <span className="title_wrap">
                    <img src={imgKakaotalk} />
                    <span className="title">{intl.formatMessage({ id: 'msg.kakaotalk' })}</span>
                  </span>
                  <div className="no_link">{kakaotalk}</div>
                </div>
              </Box>
            </Grid>
          ) : null}
        </SnsLink>

        <Grid container sx={{ mb: 6, textAlign: 'center', '& img': { width: '33.3%' } }}>
          <Grid item xs={12}>
            <img src={gameTitle01} alt="" />
            <img src={gameTitle02} alt="" />
            <img src={gameTitle03} alt="" />
            <img src={gameTitle04} alt="" />
            <img src={gameTitle05} alt="" />
            <img src={gameTitle06} alt="" />
            <img src={gameTitle07} alt="" />
            <img src={gameTitle08} alt="" />
            <img src={gameTitle09} alt="" />
            <img src={gameTitle10} alt="" />
            <img src={gameTitle11} alt="" />
            <img src={gameTitle12} alt="" />
            <img src={gameTitle13} alt="" />
            <img src={gameTitle14} alt="" />
            <img src={gameTitle15} alt="" />
            <img src={gameTitle16} alt="" />
            <img src={gameTitle17} alt="" />
            <img src={gameTitle18} alt="" />
            <img src={gameTitle19} alt="" />
            <img src={gameTitle20} alt="" />
            <img src={gameTitle21} alt="" />
          </Grid>
        </Grid>
      </Container>
    </MobileHomeContent>
  )
}

export default observer(Home)
