/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Chip } from '@mui/material'
import { popupCenter } from '@utils/utils'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

// css
import { ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// icon
import CloseIcon from '@mui/icons-material/Close'

// image
import DefaultContainer, { DefaultImages } from './d'
import MobileContainer, { MobileImages } from './m'

const NumberStyle = {
  mr: 1,
  borderRadius: 0,
  backgroundColor: '#FE3D3D',
  color: '#FFFFFF',
  height: 26,
  '.MuiChip-label': {
    padding: '0 10px',
  },
}

const PopupManualOnepay: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore } = useRootStore()

  const [state, setState] = useState(false)

  const { currentKey, currentValue } = globalStore
  const { initialized, signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.menual_onepay
  const tabIndex = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = tabIndex !== ''

  let visible = visibleOnDemand && signedin
  if (!signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
    return null
  }

  if (visible !== state) {
    setState(visible)
    return null
  }

  function onClose() {
    globalStore.hideModal(modalKey)
  }

  const targetImages = desktop ? DefaultImages : MobileImages

  const innerContents = (
    <>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="1" />
        환전 비밀번호를 입력하고 다음을 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[0]} alt="menual01" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="2" />
        로그인 페이지 로그인을 진행합니다. 이때 아이디는 해당 카지노의 로그인 아이디, 비밀번호는 등록한 계좌번호 마지막
        4자리를 입력합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[1]} alt="menual02" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="3" />
        최초 로그인 후 계좌인증을 진행합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[2]} alt="menual03" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="4" />
        계좌인증 후, 첫 화면에서 충전 버튼을 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[3]} alt="menual04" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="5" />
        충전 신청 금액 설정 후 확인 버튼을 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[4]} alt="menual05" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="6" />
        충전 신청 확인하면 인증한 핸드폰으로 알림톡이 전송됩니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[5]} alt="menual06" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="7" />
        카카오톡 알림톡 메시지 확인 후 동의 버튼 누르면 가상계좌 안내 페이지가 팝업
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[6]} alt="menual07" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="8" />
        가상 계좌 안내 – 안내된 가상 계좌로 충전 신청 금액 입금
        <span style={{ color: '#FE3D3D', marginLeft: '6px' }}>※ 본인 등록 계좌 및 신청 금액 일치 시에만 충전 가능</span>
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[7]} alt="menual08" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="9" />
        원넷충전이 완료되면 입금자명과 금액을 입력하고 충전 신청을 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[8]} alt="menual09" style={{ width: '100%' }} />
      </Box>

      <Typography component={'h2'} sx={{ fontSize: 24, textAlign: 'center', mb: 3 }}>
        원넷 회원가입
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="1" />
        회원은 받은 URL을 클릭하여 가입을 진행합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[9]} alt="menual01" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="2" />
        휴대폰 본인인증 후, 나머지 양식 입력 및 등록 신청을 완료합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[10]} alt="menual02" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <BasicButton className="btn_sub" onClick={() => onClose()} sx={{ width: '300px' }}>
          닫기
        </BasicButton>
      </Box>
    </>
  )

  let contents = null
  if (desktop) {
    contents = <DefaultContainer visible={visible} onClose={onClose} innerContents={innerContents}></DefaultContainer>
  } else {
    contents = <MobileContainer visible={visible} onClose={onClose} innerContents={innerContents}></MobileContainer>
  }
  return <>{contents}</>
}

export default observer(PopupManualOnepay)
