/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React from 'react'
import { Nav } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import {
  PartnerMembersMenus,
  PartnerBankTransactionMenus,
  PartnerBalanceAccountMenus,
  PartnerTransactionHistoryMenus,
  MyInfoMenus,
} from './RouteMenu'
import SubMenu from './SubMenu'

interface Props {
  signedin: boolean
  onClick: (RouteMenu) => void
}

const PartnerSiderMenu: React.FC<Props> = (props: Props) => {
  const intl = useIntl()
  const { onClick } = props

  return (
    <Nav className="flex-column">
      <SubMenu
        title={intl.formatMessage({ id: 'menu.sider.partner-members' })}
        items={PartnerMembersMenus}
        onClick={onClick}
      />
      <SubMenu
        title={intl.formatMessage({ id: 'menu.sider.partner-bank-transaction' })}
        items={PartnerBankTransactionMenus}
        onClick={onClick}
      />
      <SubMenu
        title={intl.formatMessage({ id: 'menu.sider.partner-balance-account' })}
        items={PartnerBalanceAccountMenus}
        onClick={onClick}
      />
      <SubMenu
        title={intl.formatMessage({ id: 'menu.sider.partner-transaction-history' })}
        items={PartnerTransactionHistoryMenus}
        onClick={onClick}
      />
      <SubMenu title={intl.formatMessage({ id: 'menu.sider.myinfo' })} items={MyInfoMenus} onClick={onClick} />
    </Nav>
  )
}

export default PartnerSiderMenu
