import * as _ from 'lodash'

// export const POWERBALL_OUTCOME_TEST = [
//   { id: 'PODD_PEVEN', value: '1.95', odds: [] },
//   { id: 'PUNDER_POVER', value: '1.95', odds: [] },
//   { id: 'PODD_PEVEN+PUNDER_POVER', value: '3.84' },
//   {
//     id: 'PABCD',
//     value: '3.15',
//     odds: [
//       { id: 'PA', value: '3.14' },
//       { id: 'PB', value: '3.13' },
//       { id: 'PC', value: '3.12' },
//       { id: 'PD', value: '3.12' },
//     ],
//   },
//   { id: 'PNUMBER', value: '9.5', odds: [] },
//   { id: 'NODD_NEVEN', value: '1.95', odds: [] },
//   { id: 'NUNDER_NOVER', value: '1.95', odds: [] },
//   { id: 'NODD_NEVEN+NUNDER_NOVER', value: '3.84', odds: [] },
//   {
//     id: 'NLMS',
//     value: '2.90',
//     odds: [
//       { id: 'NLARGE', value: '2.80' },
//       { id: 'NMEDIUM', value: '2.70' },
//       { id: 'NSMALL', value: '2.60' },
//     ],
//   },
//   {
//     id: 'NODD_NEVEN+NLMS',
//     value: '2.90',
//     odds: [
//       { id: 'NODD+NLARGE', value: '2.80' },
//       { id: 'NODD+NMEDIUM', value: '2.70' },
//       { id: 'NODD+NSMALL', value: '2.60' },
//       { id: 'NEVEN+NLARGE', value: '2.80' },
//       { id: 'NEVEN+NMEDIUM', value: '2.70' },
//       { id: 'NEVEN+NSMALL', value: '2.60' },
//     ],
//   },
//   { id: 'NPERFECT_ODD_EVEN', value: '25.0', odds: [] },
//   { id: 'NPERFECT_UNDER_OVER', value: '25.0', odds: [] },
// ]

// category
// PBP - Powerball Power
// PBN - Powerball Normal
export const PowerballMarketMeta = [
  // 파워볼
  {
    id: 'PODD_PEVEN',
    category: 'PBP',
    locale: { name: '홀짝', fullname: '파워볼 홀짝' },
    metaOdds: ['PODD', 'PEVEN'],
  },
  {
    id: 'PUNDER_POVER',
    category: 'PBP',
    locale: { name: '언오버', fullname: '파워볼 언오버' },
    metaOdds: ['PUNDER', 'POVER'],
  },
  {
    id: 'PODD_PEVEN+PUNDER_POVER',
    category: 'PBP',
    locale: { name: '홀짝+언오버', fullname: '파워볼 홀짝+언오버' },
    metaOdds: ['PODD+PUNDER', 'PODD+POVER', 'PEVEN+PUNDER', 'PEVEN+POVER'],
  },
  {
    id: 'PABCD',
    category: 'PBP',
    locale: { name: '구간', fullname: '파워볼 구간' },
    metaOdds: ['PA', 'PB', 'PC', 'PD'],
  },
  {
    id: 'PNUMBER',
    category: 'PBP',
    locale: { name: '숫자', fullname: '일반볼합 숫자' },
    metaOdds: [
      'PNUMBER_0',
      'PNUMBER_1',
      'PNUMBER_2',
      'PNUMBER_3',
      'PNUMBER_4',
      'PNUMBER_5',
      'PNUMBER_6',
      'PNUMBER_7',
      'PNUMBER_8',
      'PNUMBER_9',
    ],
  },
  // 일반볼
  {
    id: 'NODD_NEVEN',
    category: 'PBN',
    locale: { name: '홀짝', fullname: '일반볼합 홀짝' },
    metaOdds: ['NODD', 'NEVEN'],
  },
  {
    id: 'NUNDER_NOVER',
    category: 'PBN',
    locale: { name: '언오버', fullname: '일반볼합 언오버' },
    metaOdds: ['NUNDER', 'NOVER'],
  },
  {
    id: 'NODD_NEVEN+NUNDER_NOVER',
    category: 'PBN',
    locale: { name: '홀짝+언오버', fullname: '일반볼합 홀짝+언오버' },
    metaOdds: ['NODD+NUNDER', 'NODD+NOVER', 'NEVEN+NUNDER', 'NEVEN+NOVER'],
  },
  {
    id: 'NLMS',
    category: 'PBN',
    locale: { name: '대중소', fullname: '일반볼합 대중소' },
    metaOdds: ['NLARGE', 'NMEDIUM', 'NSMALL'],
  },
  {
    id: 'NODD_NEVEN+NLMS',
    category: 'PBN',
    locale: { name: '홀짝+대중소', fullname: '일반볼합 홀짝+대중소' },
    metaOdds: ['NODD+NLARGE', 'NODD+NMEDIUM', 'NODD+NSMALL', 'NEVEN+NLARGE', 'NEVEN+NMEDIUM', 'NEVEN+NSMALL'],
  },
  {
    id: 'NPERFECT_ODD_EVEN',
    category: 'PBN',
    locale: { name: '퍼펙트 홀짝', fullname: '일반볼합 퍼펙트 홀짝' },
    metaOdds: ['NPERFECT_ODD', 'NPERFECT_EVEN'],
  },
  {
    id: 'NPERFECT_UNDER_OVER',
    category: 'PBN',
    locale: { name: '퍼펙트 언오버', fullname: '일반볼합 퍼펙트 언오버' },
    metaOdds: ['NPERFECT_UNDER', 'NPERFECT_OVER'],
  },
]

export const PowerballOddsMeta = [
  { id: 'PODD', locale: { name: '홀' } },
  { id: 'PEVEN', locale: { name: '짝' } },
  { id: 'PUNDER', locale: { name: '언더' } },
  { id: 'POVER', locale: { name: '오버' } },
  { id: 'PODD+PUNDER', locale: { name: '홀+언더' } },
  { id: 'PODD+POVER', locale: { name: '홀+오버' } },
  { id: 'PEVEN+PUNDER', locale: { name: '짝+언더' } },
  { id: 'PEVEN+POVER', locale: { name: '짝+오버' } },
  { id: 'PA', locale: { name: 'A-0,1,2' }, data: [0, 1, 2] },
  { id: 'PB', locale: { name: 'B-3,4' }, data: [3, 4] },
  { id: 'PC', locale: { name: 'C-5,6' }, data: [5, 6] },
  { id: 'PD', locale: { name: 'D-7,8,9' }, data: [7, 8, 9] },
  { id: 'PNUMBER_0', locale: { name: '0' }, data: [0] },
  { id: 'PNUMBER_1', locale: { name: '1' }, data: [1] },
  { id: 'PNUMBER_2', locale: { name: '2' }, data: [2] },
  { id: 'PNUMBER_3', locale: { name: '3' }, data: [3] },
  { id: 'PNUMBER_4', locale: { name: '4' }, data: [4] },
  { id: 'PNUMBER_5', locale: { name: '5' }, data: [5] },
  { id: 'PNUMBER_6', locale: { name: '6' }, data: [6] },
  { id: 'PNUMBER_7', locale: { name: '7' }, data: [7] },
  { id: 'PNUMBER_8', locale: { name: '8' }, data: [8] },
  { id: 'PNUMBER_9', locale: { name: '9' }, data: [9] },
  { id: 'NODD', locale: { name: '홀' } },
  { id: 'NEVEN', locale: { name: '짝' } },
  { id: 'NUNDER', locale: { name: '언더' } },
  { id: 'NOVER', locale: { name: '오버' } },
  { id: 'NODD+NUNDER', locale: { name: '홀+언더' } },
  { id: 'NODD+NOVER', locale: { name: '홀+오버' } },
  { id: 'NEVEN+NUNDER', locale: { name: '짝+언더' } },
  { id: 'NEVEN+NOVER', locale: { name: '짝+오버' } },
  { id: 'NSMALL', locale: { name: '소 15~64' }, data: [15, 64] },
  { id: 'NMEDIUM', locale: { name: '중 65~80' }, data: [65, 80] },
  { id: 'NLARGE', locale: { name: '대 81~130' }, data: [81, 130] },
  { id: 'NODD+NSMALL', locale: { name: '홀+소' }, data: [15, 64] },
  { id: 'NODD+NMEDIUM', locale: { name: '홀+중' }, data: [65, 80] },
  { id: 'NODD+NLARGE', locale: { name: '홀+대' }, data: [81, 130] },
  { id: 'NEVEN+NSMALL', locale: { name: '짝+소' }, data: [15, 64] },
  { id: 'NEVEN+NMEDIUM', locale: { name: '짝+중' }, data: [65, 80] },
  { id: 'NEVEN+NLARGE', locale: { name: '짝+대' }, data: [81, 130] },
  { id: 'NPERFECT_ODD', locale: { name: '퍼펙트 홀' } },
  { id: 'NPERFECT_EVEN', locale: { name: '퍼펙트 짝' } },
  { id: 'NPERFECT_UNDER', locale: { name: '퍼펙트 언더' } },
  { id: 'NPERFECT_OVER', locale: { name: '퍼펙트 오버' } },
]
