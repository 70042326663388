/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Grid, Box, Typography, Button } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'

// icon
import { ReactComponent as IconBetHistory } from '@assets/images/layout_1/icon_bet_history.svg'
import { ReactComponent as IconScore } from '@assets/images/layout_1/icon_score.svg'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useHistory } from 'react-router-dom'
import useRootStore from '~/store/useRootStore'
import apiGame from '@services/api/game'
import { SPORTS_LOCALE, SPORT_FILTER_ITEMS } from '@game/st-util'
import { ModalKeys } from '~/store/globalStore'
import urlJoin from 'url-join'
import { useIntl } from 'react-intl'

const BetInfoList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 10,

  '& .btn_menu': {
    padding: '13px 14px',
    backgroundColor: theme.sportsButtons.largeMenu,
    //borderBottom: '1px solid #303030',
    borderRadius: 0,
    color: '#D6D6D6',
  },
}))

const HighlightList = styled(Box)(({ theme }) => ({
  marginBottom: 10,
  borderRadius: '5px',
  border: `1px solid ${theme.sportsButtons.accordianTop}`,
  backgroundColor: theme.sportsButtons.accordianSub,
  paddingBottom: '10px',

  '& .top_title': {
    padding: '5px 10px',
    backgroundColor: theme.sportsButtons.accordianTop,
    color: '#D6D6D6',
    fontSize: '1.1rem',
  },
  '& .item': {
    cursor: 'pointer',
    padding: '5px 10px',
    borderBottom: `1px solid ${theme.sportsButtons.accordianTop}`,
    color: '#D6D6D6',
    fontSize: '0.9rem',
  },
}))

const CountryList = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: 10,
  borderRadius: '5px',
  border: `1px solid ${theme.sportsButtons.accordianTop}`,
  backgroundColor: theme.sportsButtons.accordianSub,
  paddingBottom: '10px',
}))

const CountryTitle = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: '5px 10px',
  minHeight: 0,
  backgroundColor: theme.sportsButtons.accordianTop,
  color: '#D6D6D6',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
    color: '#D6D6D6',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
}))

const CountryDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,

  '& .top_title': {
    fontSize: '1.1rem',
  },
  '& .item': {
    cursor: 'pointer',
    padding: '5px 10px',
    borderBottom: `1px solid ${theme.sportsButtons.accordianTop}`,
    color: '#D6D6D6',
    fontSize: '0.9rem',
  },
  '& .item > svg': {
    marginRight: '.5rem',
  },
  '& .item > span': {
    verticalAlign: 'middle',
  },
}))

const CountrySubList = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: theme.sportsButtons.accordianSub,
}))

const CountrySubTitle = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  minHeight: 0,

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
    color: '#D6D6D6',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '&.item .MuiAccordionSummary-content svg': {
    marginRight: '.5rem',
  },
  '&.item .MuiAccordionSummary-content span': {
    verticalAlign: 'middle',
  },
}))

const CountrySubDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,

  '& .sub_item': {
    cursor: 'pointer',
    padding: '5px 10px 5px 20px',
    borderBottom: `1px solid ${theme.sportsButtons.accordianTop}`,
    color: '#707070',
    fontSize: '0.9rem',
  },
}))

interface LeagueRowProps {
  lang: string
  id: number
  locationName: any
  leagueName: any
  symbol: string
  emblemPath: string
  locationEmblem: string
  leagueEmblem: string
  selectedId: number
  onClick: (data: any) => void
}

function LeagueRow({
  lang,
  id,
  locationName,
  leagueName,
  symbol,
  emblemPath,
  locationEmblem,
  leagueEmblem,
  selectedId,
  onClick,
}: LeagueRowProps) {
  const [locationEmblemError, setLocationEmblemError] = useState<boolean>(false)
  const [leagueEmblemError, setLeagueEmblemError] = useState<boolean>(false)

  let elLocationEmblem = null
  if (!locationEmblemError) {
    elLocationEmblem = emblemPath && locationEmblem ? urlJoin(emblemPath, locationEmblem) : null
  }

  let elLeagueEmblem = null
  if (!leagueEmblemError) {
    elLeagueEmblem = emblemPath && leagueEmblem ? urlJoin(emblemPath, leagueEmblem) : null
  }

  const onLocationEmblemError = () => {
    setLocationEmblemError(true)
  }

  const onLeagueEmblemError = () => {
    setLeagueEmblemError(true)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      className="item"
      onClick={() => onClick({ leagueId: id })}
      sx={selectedId === id ? { background: '#2a8dcc' } : {}}
    >
      {symbol}
      {elLocationEmblem ? (
        <img
          alt={null}
          src={elLocationEmblem}
          style={{ height: 18, marginLeft: '4px' }}
          onError={onLocationEmblemError}
        ></img>
      ) : (
        <Typography sx={{ ml: '3px' }}>{locationName[lang] || locationName.en}</Typography>
      )}
      {/* {elLeagueEmblem && (<img alt={null} src={elLeagueEmblem} style={{ height: 18, marginLeft: '4px' }} onError={onLeagueEmblemError}></img>)} */}
      <Typography sx={{ ml: '4px' }}>{leagueName[lang] || locationName.en}</Typography>
    </Box>
  )
}

interface LocationRowProps {
  lang: string
  id: number
  name: any
  emblemPath: string
  emblem: string
  selectedId: number
  onClick: (data: any) => void
}

function LocationRow({
  lang,
  id,
  name,
  emblemPath,
  emblem,
  selectedId,
  onClick,
}: LocationRowProps) {
  const [emblemError, setEmblemError] = useState<boolean>(false)

  let elEmblem = null
  if (!emblemError) {
    elEmblem = emblemPath && emblem ? urlJoin(emblemPath, emblem) : null
  }

  const onEmblemError = () => {
    setEmblemError(true)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      className="item"
      onClick={() => onClick({ locationId: id })}
      sx={selectedId === id ? { background: '#2a8dcc' } : {}}
    >
      {elEmblem && (
        <img alt={null} src={elEmblem} style={{ height: 18 }} onError={onEmblemError}></img>
      )}
      <Typography sx={{ ml: elEmblem ? '4px' : 0 }}>{name[lang] || name.en}</Typography>
    </Box>
  )
}

const BettingMenu: React.FC<{}> = props => {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, sportsStore } = useRootStore()
  const { options } = sportsStore

  const { lang, highlight_leagues, normal_leagues, watch } = sportsStore
  const { leagueId, locationId } = watch
  const { emblem_path } = options || {}

  // accordion
  const [expanded, setExpanded] = useState<string | false>('panel1')
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const onClickBettingRecord = () => {
    history.push('/sports/record')
  }

  const onClickSportsResult = () => {
    globalStore.pushDialogOk({ text: intl.formatMessage({ id: 'msg.coming-soon' }) })
  }

  const onClickMenu = (data: any) => {
    sportsStore.setWatch(data)
  }

  const hightlightContents = []
  const normalContents = []

  for (const el of highlight_leagues) {
    const filterItem = _.find(SPORT_FILTER_ITEMS, o => {
      return o.value === el.sportId.toString()
    })
    const row = (
      <LeagueRow
        key={el.leagueId}
        lang={lang}
        id={el.leagueId}
        locationName={el.locationName}
        leagueName={el.leagueName}
        symbol={filterItem?.symbol}
        emblemPath={emblem_path}
        locationEmblem={el.locationEmblem}
        leagueEmblem={el.leagueEmblem}
        selectedId={leagueId}
        onClick={onClickMenu}
      ></LeagueRow>
    )
    hightlightContents.push(row)
  }

  for (const el of normal_leagues) {
    const filterItem = _.find(SPORT_FILTER_ITEMS, o => {
      return o.value === el.sportId.toString()
    })
    const row = (
      <LeagueRow
        key={el.leagueId}
        id={el.leagueId}
        lang={lang}
        locationName={el.locationName}
        leagueName={el.leagueName}
        symbol={filterItem?.symbol}
        emblemPath={emblem_path}
        locationEmblem={el.locationEmblem}
        leagueEmblem={el.leagueEmblem}
        selectedId={leagueId}
        onClick={onClickMenu}
      ></LeagueRow>
    )
    normalContents.push(row)
  }

  return (
    <>
      <BetInfoList>
        <Button
          startIcon={<IconBetHistory />}
          className="btn_menu"
          onClick={() => onClickBettingRecord()}
        >
          {SPORTS_LOCALE.betting_records.name[lang]}
          <Box component="span" sx={{ marginLeft: 'auto' }}>
            <ArrowForwardIosIcon />
          </Box>
        </Button>
      </BetInfoList>

      <HighlightList>
        <Box className="top_title">{SPORTS_LOCALE.highlights.name[lang]}</Box>
        {hightlightContents}
      </HighlightList>

      <CountryList expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <CountryTitle aria-controls="panel1d-content" id="panel1d-header" className="top_title">
          {SPORTS_LOCALE.league.name[lang]}
        </CountryTitle>
        <CountryDetails>{normalContents}</CountryDetails>
      </CountryList>
    </>
  )
}

export default observer(BettingMenu)
