/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { AppBar, Box, Dialog, Grid, IconButton, Stack, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'

// icon
import CloseIcon from '@mui/icons-material/Close'

import useRootStore from '@store/useRootStore'
//import { BankServiceVolumes } from '@store/userStore'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton, ItemButton } from '@styles/button.style'
import { useHistory } from 'react-router-dom'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import { ModalKeys } from '~/store/globalStore'
import { MobileModalStyle } from '@styles/modal.style'

const APP_TITLE = process.env.REACT_APP_TITLE || 'Unknown'

const PopupWriteQnaPartner: React.FC<{}> = () => {
  const intl = useIntl()
  const history = useHistory()
  const [name, setName] = useState('')
  const [content, setAmount] = useState('')
  const { globalStore, authStore } = useRootStore()

  const { initialized, signedin } = authStore
  const { currentKey, currentValue } = globalStore
  const modalKey = ModalKeys.write_qna_partner

  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex >= 0
  let visible = visibleOnDemand && signedin
  if (visibleOnDemand && !signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
  }

  const onClose = () => {
    globalStore.hideModal(modalKey)
  }

  const submit = async (values: any) => {
    if (!_.isString(name) || name.length === 0) {
      globalStore.pushDialogOk({
        text: `${intl.formatMessage({ id: 'msg.deposit-name-require' })}`,
      })
      return
    }

    // const [smallest] = BankServiceVolumes
    // if (!_.isNumber(volume) || volume < smallest) {
    //   globalStore.pushDialogOk({
    //     text: `${smallest}${intl.get('money-locale')}${intl.get('msg.deposit-validation-suffix')}`,
    //   })
    //   return
    // }

    // const ret = await userStore.deposit({ name, volume })
    // if (ret) {
    //   // move to history
    //   globalStore.showModal('bank', 2)
    // }
  }

  // const onClickVolume = (vol: number) => {
  //   const safeVolume = volume || 0
  //   setAmount(safeVolume + vol)
  // }

  // const onClickClear = () => {
  //   setAmount(0)
  // }

  const onClickAskPersonal = (type: string) => {
    if (type === 'list') {
      history.push(MYPAGE_TAB_VALUES.qnaPersonal.sub.list.url)
    } else if (type === 'write') {
      history.push(MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url)
    }
  }

  const handleChangeName = (obj: any) => {
    setName(obj.target.value)
  }

  const handleChangeContent = (obj: any) => {
    setAmount(obj.target.valueAsNumber)
  }

  return (
    <Dialog open={visible} onClose={onClose} css={MobileModalStyle}>
      <Box className="modal_content">
        <AppBar sx={{ position: 'relative' }}>
          <Typography className="modal_title" variant="h6" component="div">
            {intl.formatMessage({ id: 'component.alliance-qna.write' })}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </AppBar>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item xs={12}>
              <Formik
                initialValues={{ name: '', content: '' }}
                onSubmit={null}
                // validationSchema={Yup.object().shape({
                //   volume: Yup.string().required(intl.get('msg.field-require')),
                // })}
              >
                {({ errors, touched, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <FormStyle>
                      <Box sx={{ mb: 2 }}>
                        <label className="form_label" style={{ marginBottom: '5px' }}>
                          {intl.formatMessage({ id: 'table.title' })}
                        </label>
                        <input
                          className="form_control"
                          type="text"
                          name="nickname"
                          placeholder={intl.formatMessage({
                            id: 'component.private-qna.title-placeholder',
                          })}
                        />
                      </Box>
                      <Box sx={{ mb: 2 }}>
                        <label className="form_label" style={{ marginBottom: '5px' }}>
                          {intl.formatMessage({ id: 'table.content' })}
                        </label>
                        <textarea
                          className="form_control"
                          name="content"
                          placeholder={intl.formatMessage({
                            id: 'component.private-qna.content-placeholder',
                          })}
                          style={{ minHeight: '100px' }}
                        />
                        <p style={{ color: '#FE3D3D' }}>
                          ※ {APP_TITLE}과 함께 성장할 수 있는 모든 분을 찾습니다.
                        </p>
                      </Box>

                      <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 5 }}>
                        <BasicButton className="btn_main" style={{ width: '100%' }}>
                          {intl.formatMessage({ id: 'submit-qna' })}
                        </BasicButton>
                      </Stack>
                    </FormStyle>
                  </form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default observer(PopupWriteQnaPartner)
