/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useEffect } from 'react'
import { Grid, Box, GlobalStyles, Typography, styled } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { CasinoComing } from '@assets/images/layout_1'
import { useHistory } from 'react-router-dom'

import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'
import { useIntl } from 'react-intl'

const TextStyle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: theme.colors.listTitle,
}))

const InputStyle = styled(NeatTextField)(({ theme }) => ({
  marginTop: 55,
  width: '100%',
  '& textarea': {
    color: theme.colors.listTitle,
    textAlign: 'center',
    fontSize: '16px',
  },
  '& fieldset': {
    border: 'none',
  },
}))

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

let timer: NodeJS.Timeout = null

function Maintenance(props) {
  const intl = useIntl()
  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { option } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  useEffect(() => {
    globalStore.getPublicSettings(intl)
    if (timer) {
      clearTimeout(timer)
    }
    timer = setInterval(() => {
      globalStore.getPublicSettings(intl)
    }, 5000) as NodeJS.Timeout
  }, [])

  useOnUnmount(() => {
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
  })

  const { maintenance, maintenanceNotice } = option || {}
  if (maintenance === false) {
    history.push('/')
    return <></>
  }

  let txtInfo = maintenanceNotice || '지금은 점검중입니다.'

  return (
    <>
      <GlobalStyles
        styles={{
          //html: { backgroundColor: appBackground },
          body: {
            //backgroundColor: appBackground,
            backgroundPosition: 'top',
            backgroundSize: 'cover',
          },
        }}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ maxWidth: 700, m: 'auto', height: '100vh' }}
      >
        <Grid item xs={10} sx={{ p: '40px 0', textAlign: 'center', background: '#0a1424' }}>
          <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} />
          {/* <TextStyle>현재 카지노는 준비중입니다.</TextStyle> */}
          <InputStyle multiline value={txtInfo} InputProps={{ readOnly: true }}></InputStyle>
        </Grid>
      </Grid>
    </>
  )
}

export default observer(Maintenance)
