import React, { useState, Fragment } from 'react'
import * as _ from 'lodash'
import { Grid } from '@mui/material'
import { getDetailOddsboxName } from '~/game/st-util'
import { Schema$FilteredMarket, Schema$MarketBet, Schema$Prematch } from '~/v2/interface/st-schema'
import { observer } from 'mobx-react'
import useRootStore from '~/store/useRootStore'
import { Theme } from '@mui/material/styles'
import DetailOddsBoxAtom from './DetailOddsBoxAtom'
import { chunkArray } from '~/utils/utils'

interface OddsProps {
  theme: Theme
  column: number
  unifiedName: string // market name
  prematch: Schema$Prematch
  market: Schema$FilteredMarket
}

function DetailOddsBoxList({ theme, column, unifiedName, prematch, market }: OddsProps) {
  const { sportsStore } = useRootStore()
  const { cartItems, lang } = sportsStore

  const { FixtureId } = prematch
  const orderedBets = _.orderBy(market.Bets, ['Name'], ['asc'])

  const foundCartItem = _.find(cartItems, o => {
    return (
      o.fixtureId === FixtureId && o.marketId === market.Id && o.bet.BaseLine === market.BaseLine
    )
  })

  const xsWidth = 12 / column
  const itemNodes = []
  const chunked = chunkArray(orderedBets, column)
  if (chunked) {
    for (let i = 0; i < chunked.length; i += 1) {
      const gridItems = []
      const keyChildren: string[] = []
      for (let j = 0; j < chunked[i].length; j += 1) {
        const el = chunked[i][j] as Schema$MarketBet
        keyChildren.push(el.Name)
        const selected = foundCartItem && el?.Id === foundCartItem.bet.Id
        const name = getDetailOddsboxName(unifiedName, prematch, market.Id, el, lang)
        const childKey = `${prematch.FixtureId.toString()}:${market.Id}:${el.Name}`
        gridItems.push(
          <Grid key={childKey} item xs={xsWidth} sx={{ height: '30px', p: '0px 2px 0px 2px' }}>
            <DetailOddsBoxAtom
              theme={theme}
              name={name}
              unifiedName={unifiedName}
              atomName="home"
              fixtureId={FixtureId}
              marketId={market.Id}
              marketName={market.Name}
              market={market}
              bet={el}
              selected={selected}
              drawSymbol={false}
            ></DetailOddsBoxAtom>
          </Grid>,
        )
      }
      const key = `${prematch.FixtureId.toString()}:${market.Id}:${keyChildren.join(':')}`
      itemNodes.push(
        <Grid key={key} container sx={{ mt: itemNodes.length > 0 ? '8PX' : '0PX' }}>
          {gridItems}
        </Grid>,
      )
    }
  }

  return <Fragment>{itemNodes}</Fragment>
}

export default observer(DetailOddsBoxList)
