import React, { Fragment } from 'react'
import { jsx, css } from '@emotion/react'
import * as _ from 'lodash'
import { Box, Tabs, Tab } from '@mui/material'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import asset_channel from '@assets/channel'
import styled from '@emotion/styled'

const CasinoMenuStyle = styled('div')`  
  & .main_menu {
    margin-bottom: 0;
    padding: 2rem 1.5rem;
    background: ${props => props.theme.colors.subBg};
    background-size: 100% auto;
    background-position: top center;
  }
  .main_menu > li {
    width: 100%;
  }

  .nav-link {
    margin: .5rem 0;
    padding: 1px;
    background: linear-gradient(168deg, #5f3e15 0%, #5f3e15 40%, #bf944f 50%, #4d3211 60%, #4d3211 100%);
  }
  
  .link_wrap {
    padding: .8rem 1.5rem;
    color: #fff;
    font-size: ${props => props.theme.fonts.small};
    font-weight: bold;
    background: ${props => props.theme.colors.headerBg};
    cursor: pointer;
  }

  .link_wrap .en {
    margin-left: 0.5rem;
    color: #8c7d78;
  }
  
  .main_menu > li:hover .nav-link,
  .nav-link.active {
    background: linear-gradient(168deg, #b97809 0%, #b97809 40%, #fff416 50%, #a76c08 60%, #a76c08 100%);
  }

  .main_menu > li:hover .nav-link .link_wrap,
  .nav-link.active .link_wrap {
    color: #e9c227;
  }
  .main_menu > li:hover .link_wrap .en,
  .nav-link.active .link_wrap .en {
    color: #e9c227;
  }
`

interface Props {
  onClickMenuItem: (id: string, arg: string) => void
}

export function CasinoMenu(props: Props) {
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [listOpen, setListOpen] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const { onClickMenuItem } = {...props}

  const { partnerMode, option } = globalStore
  const { signedin } = authStore
  const { userInfo, userMoney, userPoint } = userStore
  const { nickname } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint

  const { open_trading_groups, open_trading_kinds } = option || {}
  
  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    if (currentPath === path || currentPathSearch === path) {
      return 'active'
    } else {
      return ''
    }
  }

  function isEnabled(trading_group: string): boolean {
    let foundGroup = null
    let foundKind = null

    if (open_trading_groups) {
      foundGroup = _.find(open_trading_groups, o => {
        return o.trading_group === trading_group
      })
    }

    if (open_trading_kinds) {
      foundKind = _.find(open_trading_kinds, o => {
        return o.trading_group === trading_group
      })
    }

    return foundGroup && foundKind
  }

  const handleClick = (id, arg) => {
    onClickMenuItem(id, arg)
  }

  if (partnerMode) {
    return null
  }

  return (
    <CasinoMenuStyle>
      <ul className="main_menu">
        <li hidden={!isEnabled('LCA')}>
          <a
            className={`nav-link ${navActive('/live-casino')}`}
            onClick={() => {
              handleClick('live-casino', '')
            }}
          >
            <div className="link_wrap">
              <span>{intl.formatMessage({ id: 'menu.header.live-casino' })}</span>
              <span className="en">Live Casino</span>
            </div>
          </a>
        </li>
        <li hidden={!isEnabled('HCA')}>
          <a
            className={`nav-link ${navActive('/hotel-casino')}`}
            onClick={() => {
              handleClick('hotel-casino', '')
            }}
          >
            <div className="link_wrap">
              <span>{intl.formatMessage({ id: 'menu.header.hotel-casino' })}</span>
              <span className="en">Hotel Casino</span>
            </div>
          </a>
        </li>
        <li hidden={!isEnabled('SL')}>
          <a
            className={`nav-link ${navActive('/slot')}`}
            onClick={() => {
              handleClick('slot', '')
            }}
          >
            <div className="link_wrap">
              <span>{intl.formatMessage({ id: 'menu.header.slot' })}</span>
              <span className="en">Slot</span>
            </div>
          </a>
        </li>
        <li
          hidden={
            !isEnabled('HPB') &&
            !isEnabled('NPB') &&
            !isEnabled('PB') &&
            !isEnabled('PL') &&
            !isEnabled('EOS') &&
            !isEnabled('PBG') &&
            !isEnabled('CPB') &&
            !isEnabled('CL') &&
            !isEnabled('SG') &&
            !isEnabled('WRBTCPB') &&
            !isEnabled('WRBTCPL') &&
            !isEnabled('WRBEXPB') &&
            !isEnabled('WRKENOPB') &&
            !isEnabled('WRKENOPL') &&
            !isEnabled('WRKENO')
          }
        >
          <a
            className={`nav-link ${navActive('/minigame')}`}
            onClick={() => {
              handleClick('minigame', '')
            }}
          >
            <div className="link_wrap">
              <span>{intl.formatMessage({ id: 'menu.header.minigame' })}</span>
              <span className="en">Minigame</span>
            </div>
          </a>
        </li>
        <li hidden={!isEnabled('SP')}>
          <a
            className={`nav-link ${navActive('/sports')}`}
            onClick={() => {
              handleClick('sports', '')
            }}
          >
            <div className="link_wrap">
              <span>{intl.formatMessage({ id: 'menu.header.sports' })}</span>
              <span className="en">Sports</span>
            </div>
          </a>
        </li>
      </ul>
    </CasinoMenuStyle>
  )
}

export default observer(CasinoMenu)
