import React, { Fragment, useState, useEffect, useRef } from 'react'
import { jsx, css, keyframes } from '@emotion/react'
import * as _ from 'lodash'
import {
  Grid,
  Box,
  AppBar,
  Button,
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import Menu, { MenuProps } from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import Badge from '@mui/material/Badge'

import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { ModalKeys } from '@store/globalStore'
import PartnerMenu from './PartnerMenu'
import CasinoMenu from './CasinoMenu'
import { numberWithCommas } from '~/utils/utils'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import { propsToClassKey } from '@mui/styles'
import asset_channel from '@assets/channel'
import { APP_CHARGES } from '~/utils/utils'

// css
import { styled } from '@mui/material/styles'

// icon
import RefreshIcon from '@mui/icons-material/Refresh'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'

// image
import { iconCoupon, iconMessage, icon_logout, icon_mypage } from '@assets/images/layout_1'

export const twinkle = keyframes`
  0% {
    fill: #73737B;
  }
  50% {
    fill: #FBC342;
  }
  100% {
    fill: #73737B;
  }
`
const MainBanner = styled(Box)(({ theme }) => ({
  '& img': {
    width: '100%',
  },
}))

const MobileHeaderStyle = styled('div')`
  position: sticky;
  top: 0;
  z-index: 1;

  .MuiAppBar-root {
    background: ${props => props.theme.colors.mobileHeaderBg};
    & .menu {
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .login {
      color: ${props => props.theme.colors.mobileTxt};
    }
    & .btn_coupon,
    & .btn_message {
      height: 100%;
      & img {
        width: 25px;
      }
    }
    & .btn_message .MuiBadge-badge {
      top: 4px;
      right: 4px;
      padding: 0 4px;
      height: 16px;
    }
    & .btn_partner {
      height: 100%;
      & > .text {
        display: inline-block;
        padding: 4px;
        height: 26px;
        background: linear-gradient(#18b8f4 0%, #1372df 100%);
        border-radius: 4px;
        color: ${props => props.theme.colors.btnTxtLighten};
      }
    }
    & .MuiIconButton-root.active > svg {
      animation: ${twinkle} 0.5s ease-in infinite;
    }
  }

  .MuiGrid-container {
    height: 48px;
    border-bottom: 2px solid #fad028;
  }
  .MuiGrid-item {
    display: flex;
    height: 100%;

    & > img {
      height: 100%;
    }
  }
`

const DrawerStyle = styled(Drawer)(({ theme }) => ({
  top: '48px',

  '.MuiBackdrop-root': {
    top: '48px',
  },
  '& .MuiDrawer-paper': {
    top: '48px',
    height: 'calc(100% - 48px)',
    backgroundColor: theme.colors.drawerBg,
  },
}))

const GnbStyle = styled(Box)(({ theme }) => ({
  '& .myinfo': {
    backgroundColor: theme.colors.drawerTopBg,
    color: theme.colors.drawerSubTxt,
    '& .MuiListItemText-root': {
      flex: '0 1 auto',
    },
    '& .btn_transfer': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: 'linear-gradient(#fc6363 0%, #d50d0d 100%)',
      borderRadius: '4px',
      color: theme.colors.btnTxtLighten,
    },
    '& .btn_refresh': {
      marginLeft: '5px',
      padding: '0px',
      minWidth: '30px',
      background: 'linear-gradient(#18b8f4 0%, #1372df 100%)',
      borderRadius: '4px',
      color: theme.colors.btnTxtLighten,
    },
  },
  '& .MuiDivider-root': {
    borderColor: theme.colors.drawerBorder,
  },
  '& .category .MuiListSubheader-root': {
    backgroundColor: theme.colors.drawerTopBg,
    color: theme.colors.drawerSubTxt,
    fontWeight: 'bold',
    lineHeight: '25px',
  },
  '& .MuiListItemButton-root.outer': {
    padding: '4px 16px',
    '& .MuiListItemText-root > span': {
      background: theme.colors.drawerMenuTxt,
      backgroundClip: 'text',
      textFillColor: 'transparent',
      fontWeight: 'bold',
    },
  },
  '& .MuiListItemButton-root.outer:hover, & .MuiListItemButton-root.outer.active': {
    '& .MuiListItemText-root > span': {
      background: theme.colors.drawerTxtHover,
      backgroundClip: 'text',
    },
  },
  '& .MuiList-root.submenu': {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '0 10px',
  },
  '& .MuiListItemButton-root.nested': {
    flex: '1 1 50%',
    padding: '0 16px',
  },
  '& .MuiListItemButton-root.nested.active .MuiListItemText-root .MuiTypography-root': {
    color: theme.colors.themeActive,
  },
  '& .MuiListItemButton-root.nested .MuiListItemText-root': {
    margin: 0,
    padding: '6.75px 0',
    '& .MuiTypography-root': {
      color: theme.colors.drawerSubTxt,
    },
  },
}))

const LoginButton = styled(Button)(({ theme }) => ({
  minWidth: '100px',
  fontSize: '1.1rem',
  color: '#000',
  background: 'linear-gradient(#ffcb64 0%, #ffbf44 100%)',
  '&:hover': {
    background: 'linear-gradient(#ffcb64 0%, #ffbf44 100%)',
  },
}))

const JoinButton = styled(Button)(({ theme }) => ({
  ml: 1,
  minWidth: '100px',
  fontSize: '1.1rem',
  color: '#fff',
  background: 'linear-gradient(#df5d39 0%, #aa3821 100%)',
  '&:hover': {
    background: 'linear-gradient(#df5d39 0%, #aa3821 100%)',
  },
}))

let EXCLUDE_PARTNER_MENU_CLASS = []
if (process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) {
  EXCLUDE_PARTNER_MENU_CLASS =
    JSON.parse(process.env.REACT_APP_DISABLE_PARTNER_MERCHANT_CLASS) || []
}

function getLevelImage(level: number) {
  switch (level) {
    case 1:
      return asset_channel.common.icon_level01
    case 2:
      return asset_channel.common.icon_level02
    case 3:
      return asset_channel.common.icon_level03
    case 4:
      return asset_channel.common.icon_level04
    case 5:
      return asset_channel.common.icon_level05
    case 6:
      return asset_channel.common.icon_level06
    case 7:
      return asset_channel.common.icon_level07
    case 8:
      return asset_channel.common.icon_level08
    case 9:
      return asset_channel.common.icon_level09
    case 10:
      return asset_channel.common.icon_level10
  }
  return null
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let EXCLUDE_TOP_MENU = []
if (process.env.REACT_APP_EXCLUDE_TOP_MENU) {
  EXCLUDE_TOP_MENU = JSON.parse(process.env.REACT_APP_EXCLUDE_TOP_MENU) || []
}
const SHOW_GAME_MONEY = process.env.REACT_APP_SHOW_GAME_MONEY === 'true'

export interface HeaderProps {
  onClickNavPath: (path: string) => void
}

interface MobileHeaderProps {
  Header: React.ComponentType<HeaderProps>
}

export function Header(props: MobileHeaderProps) {
  const { Header } = props
  const history = useHistory()
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { globalStore, authStore, userStore } = useRootStore()
  const [listOpen, setListOpen] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const headerRef = useRef<HTMLInputElement>()
  const bannerRef = useRef<HTMLInputElement>()

  const [refreshEnabled, setRefreshEnabled] = useState(null)

  const { option, navChange, partnerMode } = globalStore
  const { initialized, signedin } = authStore
  const { notification, userInfo, userMoney, userPoint, userComp, game_money } = userStore
  const { nickname, isPartner, user_level, comp_level, merchant_class } = userInfo
  const { balance: balanceMoney } = userMoney
  const { balance: balancePoint } = userPoint
  const { balance: balanceComp } = userComp

  const {
    open_trading_groups,
    open_trading_kinds,
    enableComp,
    enableCoupon,
    excludePartnerMenuClass,
  } = option || {}

  const contents_level = enableComp ? comp_level : user_level

  const couponActive = notification && notification.usableCouponCount > 0 ? 'active' : ''

  const isHome = window.location.pathname === '/'
  const isLCA = window.location.pathname === '/live-casino'
  const isHCA = window.location.pathname === '/hotel-casino'
  const isSlot = window.location.pathname === '/slot'
  const isMinigame = window.location.pathname === '/minigame'

  useEffect(() => {
    // do not delete!
    // rerender when notice page tab changed, so header nav can changed
  }, [navChange, partnerMode])

  const navActive = (path: string) => {
    let currentPath = history.location.pathname
    let currentPathSearch = history.location.search
    if (currentPath === path || currentPathSearch === path) {
      return 'active'
    } else {
      return ''
    }
  }

  function isEnabled(trading_group: string): boolean {
    let foundGroup = null
    let foundKind = null

    if (open_trading_groups) {
      foundGroup = _.find(open_trading_groups, o => {
        return o.trading_group === trading_group
      })
    }

    if (open_trading_kinds) {
      foundKind = _.find(open_trading_kinds, o => {
        return o.trading_group === trading_group
      })
    }

    return foundGroup && foundKind
  }

  const handleList = index => {
    //setListOpen(!listOpen);
    if (listOpen === index) {
      setListOpen('')
    } else {
      setListOpen(index)
    }
  }

  const onClickMenu = () => {
    let location = headerRef.current.getBoundingClientRect().top
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(true)
      if (isHome && location > 0) {
        const bannerHeight = bannerRef.current.getBoundingClientRect().height

        window.scrollTo({ top: bannerHeight })
      }
    }
  }

  const onClickButton = (name: string) => {
    switch (name) {
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        break
      case 'game-money':
        const timer = setTimeout(() => {
          setRefreshEnabled(null)
        }, 5000)
        setRefreshEnabled(timer)
        userStore.loadGameMoney(intl)
        return
      case 'withdraw':
        globalStore.pushDialogYesNo({
          text: intl.formatMessage({ id: 'msg.ask.gamemoney-to-ownmoney' }),
          callbackPositive: async () => {
            const timer = setTimeout(() => {
              setRefreshEnabled(null)
            }, 5000)
            setRefreshEnabled(timer)
            userStore.withdrawGameMoney(intl)
          },
        })
        return
    }

    setOpen(false)
  }

  const onClickMenuItem = (id: string, arg: string | number) => {
    switch (id) {
      case 'live-casino':
        history.push('/live-casino')
        globalStore.setNavChange('/live-casino')
        break
      case 'hotel-casino':
        history.push('/hotel-casino')
        globalStore.setNavChange('/hotel-casino')
        break
      case 'slot':
        history.push('/slot')
        globalStore.setNavChange('/slot')
        break
      case 'sports':
        history.push('/sports')
        globalStore.setNavChange('/sports')
        break
      case 'minigame':
        if (arg) {
          history.push(`/minigame/search?main=${arg}`)
          globalStore.setNavChange(`/minigame/search?main=${arg}`)
        } else {
          history.push('/minigame')
          globalStore.setNavChange('/minigame')
        }
        break
      case 'msl':
        history.push('/msl')
        globalStore.setNavChange('/msl')
        break
      case 'bank':
        history.push(`/bank/search?main=${arg}`)
        globalStore.setNavChange(`/bank/search?main=${arg}`)
        break
      case 'notice':
        history.push(`/notice/search?main=${arg}`)
        globalStore.setNavChange(`/notice/search?main=${arg}`)
        break
      case 'point':
        history.push(MYPAGE_TAB_VALUES.point.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.point.url}`)
        break
      case 'comp':
        history.push(MYPAGE_TAB_VALUES.comp.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.comp.url}`)
        break
      case 'myinfo':
        history.push(MYPAGE_TAB_VALUES.myinfo.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.myinfo.url}`)
        break
      case 'qnaPersonal':
        history.push(MYPAGE_TAB_VALUES.qnaPersonal.url)
        globalStore.setNavChange(`${MYPAGE_TAB_VALUES.qnaPersonal.url}`)
        break
      // case 'qnaPartner':
      //   history.push(MYPAGE_TAB_VALUES.qnaPartner.url)
      //   break
      case 'login':
        globalStore.showModal(ModalKeys.login, 0)
        break
      case 'join':
        globalStore.showModal(ModalKeys.login, 1)
        break
      case 'message':
        globalStore.showModal('message', 0)
        break
      case 'coupon':
        globalStore.showModal('coupon', 0)
        break
    }

    setOpen(false)
  }

  const onClickPartner = () => {
    history.push('/partner')
    setAnchorEl(null)
  }
  const onClickLogout = () => {
    history.push('/')
    authStore.logout(intl)
    setAnchorEl(null)
    setOpen(false)
  }

  const onClickLogo = () => {
    history.push('/')
    setOpen(false)
    // window.location.reload()
    if (signedin) {
      userStore.loadAsset(intl)
    }
  }

  const showNotice = EXCLUDE_TOP_MENU.indexOf('NOTICE') < 0
  const showEvent = EXCLUDE_TOP_MENU.indexOf('EVENT') < 0
  const showPolicy = EXCLUDE_TOP_MENU.indexOf('POLICY') < 0
  const showQna = EXCLUDE_TOP_MENU.indexOf('QNA') < 0

  let rightContents = null
  let badgeContents = <img src={iconMessage} alt="" />

  let showPartnerMenu = false
  if (initialized) {
    if (signedin) {
      showPartnerMenu = !excludePartnerMenuClass.includes(merchant_class.toString())
    }

    if (signedin) {
      if (notification) {
        const { unread } = notification
        if (unread > 0) {
          badgeContents = (
            <Badge badgeContent={unread} color="primary">
              <img src={iconMessage} alt="" />
            </Badge>
          )
        }
      }

      if (!badgeContents) {
        badgeContents = <img src={iconMessage} alt="" />
      }

      // rightContents = (
      //   <div style={{ height: '100%' }}>
      //     {showPartnerMenu ? (
      //       <Button className="btn_partner" onClick={onClickPartner}>
      //         <span className="text">{intl.formatMessage({ id: 'component.partner' })}</span>
      //       </Button>
      //     ) : null}
      //     <IconButton className={`btn_coupon ${couponActive}`} onClick={() => onClickMenuItem('coupon', '')}>
      //       <img src={iconCoupon} />
      //     </IconButton>
      //     <IconButton className="btn_message" onClick={() => onClickMenuItem('message', '')}>
      //       {badgeContents}
      //     </IconButton>
      //   </div>
      // )
    } else {
      // rightContents = (
      //   <>
      //     <Button className="login" onClick={() => onClickMenuItem('join', '0')} sx={{ minWidth: '68px' }}>
      //       <Typography>{intl.formatMessage({ id: 'signup' })}</Typography>
      //     </Button>
      //     <Button className="login" onClick={() => onClickMenuItem('login', '0')} sx={{ minWidth: '61px' }}>
      //       <Typography sx={{ paddingRight: '6px' }}>{intl.formatMessage({ id: 'login' })}</Typography>
      //     </Button>
      //   </>
      // )
    }

    rightContents = (
      <div style={{ height: '100%' }}>
        {showPartnerMenu ? (
          <Button className="btn_partner" onClick={onClickPartner}>
            <span className="text">{intl.formatMessage({ id: 'component.partner' })}</span>
          </Button>
        ) : null}

        {enableCoupon ? (
          <IconButton
            className={`btn_coupon ${couponActive}`}
            onClick={() => onClickMenuItem('coupon', '')}
          >
            <img src={iconCoupon} />
          </IconButton>
        ) : null}

        <IconButton className="btn_message" onClick={() => onClickMenuItem('message', '')}>
          {badgeContents}
        </IconButton>
      </div>
    )
  }

  // Drawer----------
  const chargeContents = []
  if (APP_CHARGES.indexOf('deposit') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=deposit')}`}
        key="deposit"
        onClick={() => onClickMenuItem('bank', 'deposit')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.normal' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositOnepay') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositOnepay')}`}
        key="depositOnepay"
        onClick={() => onClickMenuItem('bank', 'depositOnepay')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.onepay' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositCoin') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositCoin')}`}
        key="depositCoin"
        onClick={() => onClickMenuItem('bank', 'depositCoin')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.coin' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositCoinJun200') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositCoinJun200')}`}
        key="depositCoinJun200"
        onClick={() => onClickMenuItem('bank', 'depositCoinJun200')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.coin' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('deposit5Koin') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=deposit5Koin')}`}
        key="deposit5Koin"
        onClick={() => onClickMenuItem('bank', 'deposit5Koin')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.5koin' })} />
      </ListItemButton>,
    )
  }
  if (APP_CHARGES.indexOf('depositWonp') >= 0) {
    chargeContents.push(
      <ListItemButton
        className={`nested ${navActive('?main=depositWonp')}`}
        key="depositWonp"
        onClick={() => onClickMenuItem('bank', 'depositWonp')}
      >
        <ListItemText primary={intl.formatMessage({ id: 'component.bank.deposit.wonp' })} />
      </ListItemButton>,
    )
  }

  const list = (
    <GnbStyle component="nav" sx={{ width: 250 }} role="presentation">
      <Box className="myinfo">
        {signedin === true ? (
          <>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}
            >
              <Button
                className="nickname"
                sx={{ display: 'flex', alignItems: 'center', pl: 0, py: 0, color: 'inherit' }}
                onClick={() => onClickMenuItem('myinfo', '')}
              >
                <Box sx={{ '& > img': { padding: '6px', width: '36px' } }}>
                  <img src={getLevelImage(contents_level)} alt="" />
                </Box>
                <Box component="span" sx={{ textTransform: 'initial', fontSize: '1rem' }}>
                  {nickname}
                </Box>
              </Button>
              <Box>
                <Button
                  size="small"
                  onClick={() => onClickMenuItem('myinfo', '')}
                  sx={{ minWidth: '20px' }}
                >
                  <img src={icon_mypage} alt="mypage" style={{ width: '20px' }} />
                </Button>
                <Button size="small" onClick={onClickLogout} sx={{ minWidth: '20px' }}>
                  <img src={icon_logout} alt="logout" style={{ width: '20px' }} />
                </Button>
              </Box>
            </Box>
            <List component="div" sx={{ p: '0 8px 8px' }}>
              <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="money">
                <ListItemText>
                  <span>{intl.formatMessage({ id: 'own-money' })}</span>
                </ListItemText>
                <ListItemText
                  sx={{
                    color: '#fff',
                    '.account': {
                      color: '#fdd92b',
                    },
                  }}
                >
                  <span className="account">{numberWithCommas(Math.floor(balanceMoney))}</span>
                  <span>{intl.formatMessage({ id: 'money-locale' })}</span>
                </ListItemText>
              </ListItem>
              <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="point">
                <ListItemText>
                  <span>{intl.formatMessage({ id: 'point' })}</span>
                </ListItemText>
                <ListItemText
                  sx={{
                    color: '#fdd92b',
                    '.account': {
                      color: '#fdd92b',
                    },
                  }}
                >
                  <span className="account">{numberWithCommas(Math.floor(balancePoint))}</span>
                  <span>P</span>
                  <Button className="btn_transfer" onClick={() => onClickButton('point')}>
                    전환
                  </Button>
                </ListItemText>
              </ListItem>
              {enableComp ? (
                <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="comp">
                  <ListItemText>
                    <span>{intl.formatMessage({ id: 'comp' })}</span>
                  </ListItemText>
                  <ListItemText
                    sx={{
                      color: '#fdd92b',
                    }}
                  >
                    <span>{numberWithCommas(Math.floor(balanceComp))}</span>
                    <Button className="btn_transfer" onClick={() => onClickButton('comp')}>
                      전환
                    </Button>
                  </ListItemText>
                </ListItem>
              ) : null}
              {SHOW_GAME_MONEY && !globalStore.option?.solution?.seamless && (
                <>
                  <ListItem sx={{ py: 0, px: 1, justifyContent: 'space-between' }} key="game-money">
                    <ListItemText>
                      <span>{intl.formatMessage({ id: 'game-money' })}</span>
                    </ListItemText>
                    <ListItemText
                      sx={{
                        color: '#fff',
                        '.account': {
                          color: '#fdd92b',
                        },
                      }}
                    >
                      <span className="account">
                        {_.isNumber(game_money) ? numberWithCommas(Math.floor(game_money)) : '??'}
                      </span>
                      <Button className="btn_refresh" disabled={!!refreshEnabled}>
                        <RefreshIcon
                          sx={{ width: '16px' }}
                          onClick={() => onClickButton('game-money')}
                        />
                      </Button>
                      <Button
                        className="btn_transfer"
                        disabled={!!refreshEnabled}
                        onClick={() => onClickButton('withdraw')}
                        sx={{
                          '&:disabled': {
                            backgroundColor: '#931d1d',
                            color: '#cccccc',
                          },
                        }}
                      >
                        전환
                      </Button>
                    </ListItemText>
                  </ListItem>
                </>
              )}
            </List>
          </>
        ) : (
          <Box sx={{ py: 2, textAlign: 'center' }}>
            <LoginButton onClick={() => onClickMenuItem('login', '0')}>
              {intl.formatMessage({ id: 'login' })}
            </LoginButton>
            <JoinButton onClick={() => onClickMenuItem('join', '0')} sx={{ ml: 1 }}>
              {intl.formatMessage({ id: 'signup' })}
            </JoinButton>
          </Box>
        )}
      </Box>

      <Divider />
      <List
        component="div"
        className="category"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            게임
          </ListSubheader>
        }
        sx={{ pb: 0 }}
      >
        <ListItemButton
          hidden={!isEnabled('LCA')}
          className={`outer ${navActive('/live-casino')}`}
          key="live-casino"
          onClick={() => {
            onClickMenuItem('live-casino', 'evolution')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.live-casino' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('HCA')}
          className={`outer ${navActive('/hotel-casino')}`}
          key="hotel-casino"
          onClick={() => {
            // handleList('hotel-casino')
            onClickMenuItem('hotel-casino', 'uig')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.hotel-casino' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('MSL')}
          className={`outer ${navActive('/msl')}`}
          key="msl"
          onClick={() => {
            onClickMenuItem('msl', '')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.msl' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('SL')}
          className={`outer ${navActive('/slot')}`}
          key="slot"
          onClick={() => {
            onClickMenuItem('slot', '...')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.slot' })} />
        </ListItemButton>

        <ListItemButton
          hidden={
            !isEnabled('HPB') &&
            !isEnabled('NPB') &&
            !isEnabled('PB') &&
            !isEnabled('PL') &&
            !isEnabled('EOS') &&
            !isEnabled('PBG') &&
            !isEnabled('CPB') &&
            !isEnabled('CL') &&
            !isEnabled('SG') &&
            !isEnabled('WRBTCPB') &&
            !isEnabled('WRBTCPL') &&
            !isEnabled('WRBEXPB') &&
            !isEnabled('WRKENOPB') &&
            !isEnabled('WRKENOPL') &&
            !isEnabled('WRKENO')
          }
          className={`outer ${navActive('/minigame')}`}
          key="minigame"
          onClick={() => {
            onClickMenuItem('minigame', '')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.minigame' })} />
        </ListItemButton>

        <ListItemButton
          hidden={!isEnabled('SP')}
          className={`outer ${navActive('/sports')}`}
          key="sports"
          onClick={() => {
            onClickMenuItem('sports', '')
          }}
        >
          <ListItemText primary={intl.formatMessage({ id: 'menu.header.sports' })} />
        </ListItemButton>
      </List>

      <Divider />

      <List
        component="div"
        className="category"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            서비스
          </ListSubheader>
        }
        sx={{ pb: 0 }}
      >
        {signedin ? (
          <Fragment>
            <ListItemButton className="outer" key="account">
              <ListItemText primary={intl.formatMessage({ id: 'component.bank-transaction' })} />
            </ListItemButton>
            <List component="div" disablePadding className="submenu">
              {chargeContents}
              <ListItemButton
                className={`nested ${navActive('?main=withdraw')}`}
                onClick={() => onClickMenuItem('bank', 'withdraw')}
              >
                <ListItemText primary={intl.formatMessage({ id: 'component.bank.withdraw' })} />
              </ListItemButton>
              <ListItemButton
                className={`nested ${navActive('?main=history')}`}
                onClick={() => onClickMenuItem('bank', 'history')}
              >
                <ListItemText primary={intl.formatMessage({ id: 'component.bank.history' })} />
              </ListItemButton>
            </List>

            <ListItemButton
              className={`outer ${navActive('?main=myinfo')}`}
              key="myinfo"
              onClick={() => onClickMenuItem('myinfo', '')}
            >
              <ListItemText primary={intl.formatMessage({ id: 'component.mypage' })} />
            </ListItemButton>
            <ListItemButton
              className={`outer ${navActive('?main=point')}`}
              key="point"
              onClick={() => onClickMenuItem('point', '')}
            >
              <ListItemText primary={intl.formatMessage({ id: 'component.point' })} />
            </ListItemButton>
            {enableComp ? (
              <ListItemButton
                className={`outer ${navActive('?main=comp')}`}
                key="comp"
                onClick={() => onClickMenuItem('comp', '')}
              >
                <ListItemText primary={intl.formatMessage({ id: 'component.comp' })} />
              </ListItemButton>
            ) : null}
          </Fragment>
        ) : null}

        {showNotice ? (
          <>
            <ListItemButton
              className={`outer ${navActive('?main=NOTICE')}`}
              key="notice"
              onClick={() => onClickMenuItem('notice', 'NOTICE')}
            >
              <ListItemText primary={intl.formatMessage({ id: 'component.notice.notice' })} />
            </ListItemButton>
          </>
        ) : null}

        {showQna && signedin ? (
          <ListItemButton
            className={`outer ${navActive('?main=qnaPersonal')}`}
            key="qnaPersonal"
            onClick={() => onClickMenuItem('qnaPersonal', '')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'menu.header.qna' })} />
          </ListItemButton>
        ) : null}

        {showEvent ? (
          <ListItemButton
            className={`outer ${navActive('?main=EVENT')}`}
            key="event"
            onClick={() => onClickMenuItem('notice', 'EVENT')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.notice.event' })} />
          </ListItemButton>
        ) : null}

        {showPolicy ? (
          <ListItemButton
            className={`outer ${navActive('?main=POLICY')}`}
            key="policy"
            onClick={() => onClickMenuItem('notice', 'POLICY')}
          >
            <ListItemText primary={intl.formatMessage({ id: 'component.notice.policy' })} />
          </ListItemButton>
        ) : null}
      </List>
    </GnbStyle>
  )
  // -----------Drawer

  return (
    <>
      {isHome ? (
        <MainBanner ref={bannerRef}>
          <img src={asset_channel.common.m_main_banner} alt="main banner" />
        </MainBanner>
      ) : null}
      <MobileHeaderStyle>
        <AppBar position="static" ref={headerRef}>
          <Grid container>
            <Grid item xs={6}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                className="menu"
                sx={{ ml: 0 }}
                onClick={() => onClickMenu()}
              >
                <MenuIcon />
              </IconButton>
              <Box
                sx={{ justifyContent: 'center', '& > img': { cursor: 'pointer', height: '100%' } }}
              >
                <img src={asset_channel.common.main_logo_m} alt="" onClick={() => onClickLogo()} />
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ justifyContent: 'end' }}>
              {rightContents}
            </Grid>
          </Grid>
        </AppBar>

        <DrawerStyle anchor="left" open={open} onClose={() => setOpen(false)}>
          {list}
        </DrawerStyle>
      </MobileHeaderStyle>

      {isHome || isLCA || isHCA || isSlot || isMinigame ? (
        <>
          <CasinoMenu onClickMenuItem={onClickMenuItem}></CasinoMenu>
        </>
      ) : null}
      <PartnerMenu></PartnerMenu>
    </>
  )
}

export default observer(Header)
