/** @jsxImportSource @emotion/react */
import { jsx, ThemeProvider, Global } from '@emotion/react'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import cookies from 'react-cookies'

function LandingR(props) {
  const history = useHistory()
  const params = useParams()

  // localhost/r/test01
  // test01 is rcode
  const { rcode } = params as any

  useEffect(() => {
    if (rcode) {
      const now = new Date()
      const expires = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
      cookies.save('rcode', rcode, {
        path: '/',
        expires: expires,
      })
    }
    history.push('/')
  }, [])

  return <></>
}

export default LandingR
