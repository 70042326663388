import * as _ from 'lodash'
import {
  ENLARGED_FRIST_ROW_BUTTONS,
  ENLARGED_FRIST_ROW_HEADERS,
  ENLARGED_SECCOND_ROW_HEADERS,
  ENLARGED_SECOND_ROW_BUTTONS,
  FIRST_ROW_HEADERS,
  FRIST_ROW_BUTTONS,
  OddsAttributes,
  OddsPosition,
  OddsTransform,
  SECOND_ROW_BUTTONS,
  SECOND_ROW_HEADERS,
} from './types'
import OddsButton from './odds-button'
import OddsHeader from './odds-header'

export default class OddsComponent extends Phaser.GameObjects.Container {
  initialPosition: OddsPosition = { x: 0, y: 0 }

  buttons: OddsButton[] = []
  headers: OddsHeader[] = []

  buttonsOddEven: OddsButton[] = []
  buttonsUnerOver: OddsButton[] = []
  buttonsEtc: OddsButton[] = []

  headersOddEven: OddsHeader[] = []
  headersUnderOver: OddsHeader[] = []
  headersEtc: OddsHeader[] = []

  handleClickOddsButton: (btn: OddsButton) => void = null

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    handleClickOddsButton: (btn: OddsButton) => void,
  ) {
    super(scene, x, y)

    scene.add.existing(this)

    this.handleClickOddsButton = handleClickOddsButton

    this.initialPosition = { x, y }

    this.addHeaders()

    this.addRow(FRIST_ROW_BUTTONS)
    this.addRow(SECOND_ROW_BUTTONS)

    // collect buttons
    const idsBtnOddEven = ['NODD', 'NEVEN', 'PODD', 'PEVEN']
    const idsBtnUnderOver = ['NUNDER', 'NOVER', 'PUNDER', 'POVER']
    const idsBtnEtc = idsBtnOddEven.concat(idsBtnUnderOver)

    this.buttonsOddEven = _.filter(this.buttons, (o: OddsButton) => {
      return idsBtnOddEven.indexOf(o.attributes.id) >= 0
    })
    this.buttonsUnerOver = _.filter(this.buttons, (o: OddsButton) => {
      return idsBtnUnderOver.indexOf(o.attributes.id) >= 0
    })
    this.buttonsEtc = _.filter(this.buttons, (o: OddsButton) => {
      return idsBtnEtc.indexOf(o.attributes.id) < 0
    })

    // collect headers
    const idsHeaderOddEven = ['NODD_NEVEN', 'PODD_PEVEN']
    const idsHeaderUnderOver = ['NUNDER_NOVER', 'PUNDER_POVER']
    const idsHeaderEtc = idsHeaderOddEven.concat(idsHeaderUnderOver)

    this.headersOddEven = _.filter(this.headers, (o: OddsHeader) => {
      return idsHeaderOddEven.indexOf(o.headerData.id) >= 0
    })
    this.headersUnderOver = _.filter(this.headers, (o: OddsHeader) => {
      return idsHeaderUnderOver.indexOf(o.headerData.id) >= 0
    })
    this.headersEtc = _.filter(this.headers, (o: OddsHeader) => {
      return idsHeaderEtc.indexOf(o.headerData.id) < 0
    })
  }

  clear() {
    for (const el of this.buttons) {
      el.clear()
    }
  }

  getOddsButton(oddsId: string): OddsButton {
    const btn = _.find(this.buttons, (o: OddsButton) => {
      return o.attributes.id === oddsId
    })
    return btn
  }

  setEnabled(enabled: boolean) {
    for (const el of this.buttons) {
      el.setEnabled(enabled)
    }

    const offsetY = enabled ? 0 : 40
    this.y = this.initialPosition.y + offsetY
  }

  tweenEnabled(enabled: boolean) {
    for (const el of this.buttons) {
      el.setEnabled(enabled)
    }

    const duration = 250
    const offsetY = enabled ? 0 : 40
    const ease = Phaser.Math.Easing.Quadratic.Out

    this.scene.tweens.add({
      targets: this,
      y: this.initialPosition.y + offsetY,
      duration,
      ease,
    })
  }

  recover() {
    for (const el of this.buttonsOddEven) {
      el.recover()
    }
    for (const el of this.buttonsUnerOver) {
      el.recover()
    }
    for (const el of this.headersOddEven) {
      el.recover()
    }
    for (const el of this.headersUnderOver) {
      el.recover()
    }
    setTimeout(() => {
      for (const el of this.buttonsEtc) {
        el.tweenVisible(true)
      }
      for (const el of this.headersEtc) {
        el.tweenVisible(true)
      }
    }, 100)
  }

  enlarge() {
    this.enlargeButtons(this.buttonsOddEven, ENLARGED_FRIST_ROW_BUTTONS)
    this.enlargeButtons(this.buttonsUnerOver, ENLARGED_SECOND_ROW_BUTTONS)
    this.enlargeHeaders(this.headersOddEven, ENLARGED_FRIST_ROW_HEADERS)
    this.enlargeHeaders(this.headersUnderOver, ENLARGED_SECCOND_ROW_HEADERS)
    for (const el of this.buttonsEtc) {
      el.tweenVisible(false)
    }
    for (const el of this.headersEtc) {
      el.tweenVisible(false)
    }
  }

  enlargeHeaders(targets: OddsHeader[], tf: OddsTransform[]) {
    for (const el of targets) {
      const enlarged = _.find(tf, (o) => {
        return o.id === el.headerData.id
      })
      const btnData = _.cloneDeep(enlarged)
      el.transform(btnData.size, btnData.position)
    }
  }

  enlargeButtons(targets: OddsButton[], tf: OddsTransform[]) {
    for (const el of targets) {
      const enlarged = _.find(tf, (o) => {
        return o.id === el.attributes.id
      })
      const btnData = _.cloneDeep(enlarged)
      el.transform(btnData.size, btnData.position)
    }
  }

  addHeaders() {
    for (const el of FIRST_ROW_HEADERS) {
      const header = new OddsHeader(this.scene, el)
      this.add(header)
      this.headers.push(header)
    }

    for (const el of SECOND_ROW_HEADERS) {
      const header = new OddsHeader(this.scene, el)
      this.add(header)
      this.headers.push(header)
    }
  }

  addRow(data: OddsAttributes[]) {
    for (let i = 0; i < data.length; i += 1) {
      const btnData = _.cloneDeep(data[i])
      const btn = new OddsButton(
        this.scene,
        btnData,
        this.onClickButton.bind(this),
      )
      this.add(btn)
      this.buttons.push(btn)
    }
  }

  onClickButton(btn: OddsButton) {
    // console.log(`click! ${btn.buttonData.id} ${btn.buttonData.title}`)
    if (this.handleClickOddsButton) {
      this.handleClickOddsButton(btn)
    }
  }
}
