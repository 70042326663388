/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import { useEffect, useState, useRef } from 'react'
import {
  GlobalStyles,
  Box,
  Container,
  Fab,
  Zoom,
  Button,
  Typography,
  AppBar,
  IconButton,
} from '@mui/material'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { ReactComponent as IconBetHistory } from '@assets/images/layout_1/icon_bet_history.svg'
import ContainerImplMarketDetail from './ContainerImplMarketDetail'

import SportsBettingCart from './BettingCart'
import BettingMenu from './BettingMenu'
import PrematchPanel from './PrematchPanel'
import SportsHeader from './SportsHeader'
import BettingCartIndicator from './MobileBettingCart'
import MobileBettingCart from './MobileBettingCart'
import MobileMarketDetail from './MobileMarketDetail'
import ImplMarketDetail from './ImplMarketDetail'

// icon
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

// css
import { DarkenBg } from '@styles/base.style'
import InplayPanel from './InplayPanel'
import SportsBonusPanel from './SportsBonusPanel'
import { SPORTS_LOCALE } from '~/game/st-util'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const TopButtonWrap = styled('div')`
  float: right;
  margin-top: -60px;
  margin-right: 10px;

  .MuiFab-root {
    width: 60px;
    height: 60px;
    border: 1px solid #1e2b3d;
    border-radius: 20px;
    background-color: #1e2b3d;
    box-shadow: 0px 3px 6px #00000059;

    &:hover {
      background-color: #1e2b3d;
    }
  }

  .MuiSvgIcon-root {
    color: #d1d9e3;
    font-size: 24px;
  }
`

const BettingMenuWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexBasis: 300,
  maxWidth: 300,
  marginRight: 16,
  minHeight: 476,
  maxHeight: `calc(100vh - ${theme.height.header + theme.height.footer + 32}px)`,
  overflowY: 'auto',

  //scroll custom
  scrollbarWidth: 'thin',
  scrollbarColor: '#182740 transparent',
  '&::-webkit-scrollbar': {
    width: '0.4rem',
    height: '0.4rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#182740',
  },
  '&:hover': {
    scrollbarColor: '#19253a transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#19253a',
  },
}))

const PrematchWrap = styled(Box)(({ theme }) => ({
  minHeight: 428,
  maxHeight: `calc(100vh - ${theme.height.header + 80}px)`,
  overflowY: 'auto',

  //scroll custom
  scrollbarWidth: 'thin',
  scrollbarColor: '#182740 transparent',
  '&::-webkit-scrollbar': {
    width: '0.4rem',
    height: '0.4rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#182740',
  },
  '&:hover': {
    scrollbarColor: '#19253a transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#19253a',
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    )

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Zoom in={trigger}>
      <TopButtonWrap onClick={handleClick} role="presentation">
        {children}
      </TopButtonWrap>
    </Zoom>
  )
}

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const Sports: React.FC<{}> = props => {
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, sportsLiveStore, sportsStore } = useRootStore()
  const refSport = useRef<string>()
  const refBookmaker = useRef<string>()

  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)
  const { serviceState, inSpace, inGameId, inWatch, spaceReloadedTimestamp } = sportsLiveStore
  const { flight, flightConflict, lang } = sportsStore
  const refTimeout = useRef<NodeJS.Timeout>(null)

  useOnMount(() => {
    sportsStore.start()
    sportsLiveStore.enter()
  })

  useOnUnmount(() => {
    sportsLiveStore.cleanup()
    sportsStore.cleanup()
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (flightConflict > 0) {
      sportsLiveStore.cleanup()
      sportsStore.cleanup()

      sportsStore.start()
      sportsLiveStore.enter()
    }
  }, [flightConflict])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('SP')
      const inplay = flight === 'inplay'
      const spaceName = inplay ? 'inplay' : 'prematch'
      sportsLiveStore.enterSpace(spaceName, 'SP', '', '', false)
    }
  }, [signedin, initialized, serviceState])

  useEffect(() => {
    if (inSpace) {
      // liveStore.watch(refSport.current)
      sportsLiveStore.watch('all', 'active')
    }
  }, [inSpace, spaceReloadedTimestamp])

  if (!signedin && initialized) {
    globalStore.pushLogin()
    return <></>
  }

  // const width = desktop ? '1752px' : '100%'

  const onChangeBegin = (sportId: string) => {
    refSport.current = sportId
    sportsStore.clearPrematches()
  }

  const onChangeEnd = (sportId: string) => {
    if (inSpace === 'prematch' || inSpace === 'inplay') {
      // liveStore.watch(sportId.toString())
      sportsStore.setWatch(sportId === 'all' ? {} : { sportId: Number(sportId) })
    }
  }

  const inplay = flight === 'inplay'
  const desktopMobile = desktop && (flight === 'europe' || inplay)
  // console.log(`desktop: ${desktop} desktopMobile: ${desktopMobile}`)

  let contents = null

  const header = (
    <>
      <SportsBonusPanel></SportsBonusPanel>
      <SportsHeader
        id="back-to-top-anchor"
        onChangeBegin={onChangeBegin}
        onChangeEnd={onChangeEnd}
      ></SportsHeader>
    </>
  )

  let bodyMinWidth = ''
  if (isDesktop) {
    if (channelSymbol === 'marine2') {
      bodyMinWidth = 'min-width: 1734px'
    } else {
      bodyMinWidth = 'min-width: 1740px'
    }
  } else {
    bodyMinWidth = ''
  }

  const Marine2Global = () => css`
    body {
      ${bodyMinWidth}
    }
    .content_wrap > .MuiContainer-root {
      padding: 16px 0;
      minwidth: 1443px;
      margin: 0;
    }
  `

  const BandGlobal = () => css`
    body {
      ${bodyMinWidth}
    }
    .content_wrap {
      padding-top: 24px;
      margin: 0 0 0 310px;
      width: 1506px;
    }
  `

  const GlobalStyle = () => css`
    body {
      ${bodyMinWidth}
    }
    .content_wrap {
      width: 1740px;
    }
  `

  const MobileGlobalStyle = () => css`
    body {
      ${bodyMinWidth}
    }
  `

  if (desktop) {
    let panelContents = null
    if (desktopMobile) {
      panelContents = (
        <Box display="flex">
          <PrematchWrap sx={{ width: '50%' }}>
            {inplay ? (
              <InplayPanel desktop={desktop} virtualDesktop={false}></InplayPanel>
            ) : (
              <PrematchPanel desktop={desktop} virtualDesktop={false}></PrematchPanel>
            )}
          </PrematchWrap>
          <PrematchWrap sx={{ width: '50%' }}>
            <AppBar
              sx={{
                position: 'relative',
                background: '#080f1c',
                padding: '11px 0',
                mt: 1,
                boxShadow: 'none',
              }}
            >
              <Typography sx={{ textAlign: 'center' }} variant="h6" component="div">
                {flight === 'inplay'
                  ? SPORTS_LOCALE.live_betting.name[lang]
                  : SPORTS_LOCALE.more_betting.name[lang]}
              </Typography>
            </AppBar>
            <ContainerImplMarketDetail></ContainerImplMarketDetail>
          </PrematchWrap>
        </Box>
      )
    } else {
      panelContents = inplay ? (
        <InplayPanel desktop={desktop} virtualDesktop={true}></InplayPanel>
      ) : (
        <PrematchPanel desktop={desktop} virtualDesktop={true}></PrematchPanel>
      )
    }

    if (channelSymbol === 'marine2') {
      contents = (
        <>
          <GlobalStyles styles={Marine2Global} />
          <Box className="content_wrap">
            <Container>
              <Box display="flex" sx={{ p: '0 16px' }}>
                <BettingMenuWrap>
                  <SportsBettingCart />
                  <Box sx={{ mt: 2 }}>
                    <BettingMenu />
                  </Box>
                </BettingMenuWrap>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '1200px',
                    flexGrow: 1,
                    maxHeight: 'calc(100vh - 282px)',
                    overflowY: 'auto',
                  }}
                >
                  {header}
                  {panelContents}
                  {/* {scrollTop} */}
                </Box>
              </Box>
            </Container>
          </Box>
        </>
      )
    } else if (channelSymbol === 'band' || channelSymbol === 'world' || channelSymbol === 'emart') {
      contents = (
        <>
          <GlobalStyles styles={BandGlobal} />
          <DarkenBg>
            <Box className="content_wrap">
              <Box display="flex">
                <BettingMenuWrap>
                  <BettingMenu />
                </BettingMenuWrap>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '1200px',
                    flexGrow: 1,
                  }}
                >
                  {header}
                  {panelContents}
                  {/* {scrollTop} */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexBasis: 300,
                    maxWidth: 300,
                    paddingLeft: '16px',
                  }}
                >
                  <SportsBettingCart></SportsBettingCart>
                </Box>
              </Box>
            </Box>
          </DarkenBg>
        </>
      )
    } else {
      contents = (
        <>
          <GlobalStyles styles={GlobalStyle} />
          <DarkenBg>
            <Box className="content_wrap">
              <Box display="flex">
                <BettingMenuWrap>
                  <BettingMenu />
                </BettingMenuWrap>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '1200px',
                    flexGrow: 1,
                  }}
                >
                  {header}
                  {panelContents}
                  {/* {scrollTop} */}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexBasis: 300,
                    maxWidth: 300,
                    paddingLeft: '16px',
                  }}
                >
                  <SportsBettingCart></SportsBettingCart>
                </Box>
              </Box>
            </Box>
          </DarkenBg>
        </>
      )
    }
  } else {
    contents = (
      <>
        <GlobalStyles styles={MobileGlobalStyle} />
        <Box sx={{ backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}>
          <Box
            sx={{
              padding: '16 0 16px 0',
              display: 'flex',
              flexDirection: 'column',
              maxWidth: '100%',
              flexGrow: 1,
            }}
          >
            {header}
            {inplay ? (
              <InplayPanel desktop={desktop} virtualDesktop={desktop}></InplayPanel>
            ) : (
              <PrematchPanel desktop={desktop} virtualDesktop={desktop}></PrematchPanel>
            )}
          </Box>
          <MobileBettingCart></MobileBettingCart>
          <MobileMarketDetail></MobileMarketDetail>
        </Box>
      </>
    )
  }

  return <>{contents}</>
}

export default observer(Sports)
