/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import { Grid, Box, Typography, Chip } from '@mui/material'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import QnaView from './QnaView'
import { ModalKeys } from '@store/globalStore'
import { Schema$QnaTrackData } from '@protocol/user'
import { QNA_STATE } from '@interface/config'
import { IQnaRecord } from '@protocol/user'
import apiUser from '@services/api/user'
import { APP_CHARGES } from '~/utils/utils'

// css
import { BasicButton } from '@styles/button.style'
import { NoList } from '@styles/modal.style'
import { PaginationStyle } from '@styles/base.style'

//image
import { CasinoComing } from '@assets/images/layout_1'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginBottom: '16px',
  backgroundColor: '#080F1C',
  borderRadius: '4px',
  overflow: 'hidden',
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor: '#16202F',
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
  '& .MuiChip-root': {
    height: '20px',
    color: '#FFF',
  },
  '& .title': {
    color: '#D1D9E3',
  },
  '& .date': {
    color: '#747D8A',
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0',
}))

export interface Props {
  style?: any
}

type TrackData = Schema$QnaTrackData
const ItemPerPage = 10

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

function QnaPersonal({ location }) {
  const query = queryString.parse(location.search)
  const { page } = query
  const startPage = page ? Number(page) : 1

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [expanded, setExpanded] = React.useState<string | false>('')
  const lastModalKey = useRef<string>('')

  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore, userStore } = useRootStore()

  const { currentKey, currentValue } = globalStore
  const { signedin } = authStore
  const { userInfo } = userStore
  const modalKey = ModalKeys.qna
  const tabIndex = currentKey === modalKey ? currentValue : -1
  const visibleOnDemand = tabIndex >= 0
  let visible = visibleOnDemand && signedin

  const date = new Date()
  const curDate = `${(date.getMonth() + 1).toString()}/${date.getDate().toString()}`

  if (visibleOnDemand && !signedin) {
    // popup login
    globalStore.showModal('login', 0)
  }

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  const fetch = async (params: IQnaRecord.Params) => {
    try {
      const { data } = await apiUser.getQnaRecord(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const initList = () => {
    const params: IQnaRecord.Params = {
      kind: 'NORMAL',
      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetch(params)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [track])

  useEffect(() => {
    initList()
  }, [tabPage])

  if (
    lastModalKey.current === ModalKeys.write_qna_personal &&
    currentKey !== ModalKeys.write_qna_personal
  ) {
    initList()
  }

  lastModalKey.current = currentKey

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/mypage/search?main=qnaPersonal&page=${value}`)
  }

  const onClick = (id: string) => {
    if (id === 'write') {
      globalStore.showModal(ModalKeys.write_qna_personal, 'write')
    } else if (id === 'deposit') {
      globalStore.showModal(ModalKeys.write_qna_personal, 'deposit')
    } else if (id === 'deposit.normal') {
      globalStore.showModal(ModalKeys.write_qna_personal, 'deposit.normal')
    } else if (id === 'deposit.virtual') {
      globalStore.showModal(ModalKeys.write_qna_personal, 'deposit.virtual')
    } else if (id === 'sports') {
      // only jaba
      globalStore.showModal(ModalKeys.write_qna_personal, 'sports')
    }
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const listItems = []
  if (track) {
    for (const el of track.items) {
      const stateObj = _.find(QNA_STATE, o => {
        return o.id === el.state
      })
      const stateName = stateObj ? stateObj.name : ''
      const dateName = moment(el.created_at).format('YYYY-MM-DD HH:mm:ss')
      const color = stateObj && stateObj.id === 'ANSWER' ? '#2366CB' : '#FBC342'
      const viewProps = {
        question: el.content,
        answer: el.answer,
        state: el.state,
      }
      listItems.push(
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          key={el.id}
          expanded={expanded === `pannel-${el.id}`}
          onChange={handleChange(`pannel-${el.id}`)}
        >
          <AccordionSummary id={`panel-${el.id}-header`} aria-controls={`panel-${el.id}-content`}>
            <Grid container sx={{ py: 2, alignItems: 'center' }}>
              <Grid
                item
                xs={12}
                sx={{
                  mb: 1 / 2,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Chip label={stateName} size="small" sx={{ backgroundColor: color }} />
                <span className="date">{dateName}</span>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: 'start' }}>
                <span className="title">{el.title}</span>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <QnaView {...viewProps} />
          </AccordionDetails>
        </Accordion>,
      )
    }

    pageData = getPageData(track)
  }

  let emptyContents = null
  if (listItems.length === 0) {
    emptyContents = (
      <NoList>
        <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} />
        <p>{intl.formatMessage({ id: 'component.no-private-qna' })}</p>
      </NoList>
    )
  }

  const canDepositNormal = APP_CHARGES.indexOf('deposit') >= 0
  const canDepositMCI = APP_CHARGES.indexOf('depositMCI') >= 0

  return (
    <Box sx={{ px: 2, mt: 2 }}>
      <Box sx={{ my: 5 }}>
        <Typography sx={{ mb: 0, color: '#D1D9E3', textAlign: 'center', fontSize: '1.1rem' }}>
          {intl.formatMessage({ id: 'component.has-question' })}
        </Typography>
        <Typography
          sx={{
            mt: 0,
            mb: 0,
            color: '#0b1526',
            fontSize: '15px',
            lineHeight: '15px',
            userSelect: 'none',
            textAlign: 'center',
          }}
        >
          {curDate} {userInfo.id}
        </Typography>
        {canDepositMCI ? (
          <Grid container sx={{ justifyContent: 'center', marginTop: '10px' }}>
            <Grid item xs={8}>
              <BasicButton
                className="btn_default"
                onClick={() => onClick('deposit.virtual')}
                style={{ width: '100%' }}
              >
                {intl.formatMessage({ id: 'component.private-qna.deposit.virtual' })}
              </BasicButton>
            </Grid>
          </Grid>
        ) : null}
        {canDepositNormal ? (
          <Grid container sx={{ justifyContent: 'center', marginTop: '10px' }}>
            <Grid item xs={8}>
              <BasicButton
                className="btn_default"
                onClick={() => onClick(canDepositMCI ? 'deposit.normal' : 'deposit')}
                style={{ width: '100%' }}
              >
                {intl.formatMessage({
                  id: canDepositMCI
                    ? 'component.private-qna.deposit.normal'
                    : 'component.private-qna.deposit',
                })}
              </BasicButton>
            </Grid>
          </Grid>
        ) : null}
        <Grid container sx={{ justifyContent: 'center', marginTop: '10px' }}>
          <Grid item xs={8}>
            <BasicButton
              className="btn_main"
              onClick={() => onClick('write')}
              style={{ width: '100%' }}
            >
              {intl.formatMessage({ id: 'component.private-qna.write' })}
            </BasicButton>
          </Grid>
        </Grid>
        {channelSymbol === 'jaba' ? (
          <Grid container sx={{ justifyContent: 'center', marginTop: '10px' }}>
            <Grid item xs={8}>
              <BasicButton
                className="btn_main"
                onClick={() => onClick('sports')}
                style={{ width: '100%' }}
              >
                {intl.formatMessage({ id: 'sports-inquiry' })}
              </BasicButton>
            </Grid>
          </Grid>
        ) : null}
      </Box>

      <Box>
        {listItems}
        {emptyContents}
      </Box>

      <PaginationStyle
        count={pageData.totalPage}
        variant="outlined"
        page={pageData.currPage}
        onChange={handlePagingChange}
      />
    </Box>
  )
}

export default observer(QnaPersonal)
