/* eslint-disable react-hooks/exhaustive-deps */
import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import * as _ from 'lodash'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import {
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  LinearProgress,
} from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import {
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
} from '@utils/utils'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import queryString from 'query-string'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import WhiteTheme from '@themes/white.theme'
import { GlobalStyle, PageStyle } from '~/containers/shared/Common'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { Schema$PartnerChargeRecordTrackData, IPartnerChargeRecord } from '@protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import { BET_STATE } from '@interface/config'
import { PartnerModeKeys } from '~/store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
  paddingRight: '16px',
  paddingTop: '6px',
  paddingBottom: '6px',
}))

interface RowData {
  id: number
  uuid: string
  date_request: string
  date_done: string
  transfer_kind: string
  user_id: string
  nickname: string
  deposit: number
  withdraw: number
  bonus: number
  status: string
}

function Head() {
  return (
    <TableHead sx={{ bgcolor: '#007f93' }}>
      <TableRow>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>신청 일시</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>처리 일시</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>입/출금</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>아이디</Typography>
        </MyTableCell>
        <MyTableCell align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>닉네임</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>입금액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>출금액</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>보너스</Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

function Row(props: { row: RowData; onClick: (field: string, id: number) => void }) {
  const { row, onClick } = props

  const isSum = row.status === 'sum'
  let rowStyle = isSum ? { background: '#d8e4ff' } : {}

  const typeColor = row.transfer_kind === 'IN' ? 'blue' : 'red'
  const txt_transfer_kind = row.transfer_kind === 'IN' ? '입금' : '출금'
  const date_request = row.date_request
    ? moment(new Date(row.date_request)).format('YYYY-MM-DD HH:mm::ss')
    : null
  const date_done = row.date_done
    ? moment(new Date(row.date_done)).format('YYYY-MM-DD HH:mm::ss')
    : null
  const deposit = Math.abs(row.deposit) > 0 ? numberWithCommas(Math.abs(row.deposit)) : null
  const withdraw = Math.abs(row.withdraw) > 0 ? numberWithCommas(-Math.abs(row.withdraw)) : null
  const bonus = Math.abs(row.bonus) > 0 ? numberWithCommas(Math.abs(row.bonus)) : null

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...rowStyle }}>
        {isSum ? (
          <MyTableCell align="center" colSpan={5}>
            <Typography sx={{ fontSize: '12x' }}>합계</Typography>
          </MyTableCell>
        ) : (
          <>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12x' }}>{date_request}</Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12x' }}>{date_done}</Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography sx={{ fontSize: '12px', color: typeColor }}>
                {txt_transfer_kind}
              </Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => onClick('account', row.id)}
              >
                {row.user_id}
              </Typography>
            </MyTableCell>
            <MyTableCell align="center">
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  ':hover': {
                    cursor: 'pointer',
                  },
                }}
                onClick={() => onClick('account', row.id)}
              >
                {row.nickname}
              </Typography>
            </MyTableCell>
          </>
        )}
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'blue' }}>{deposit}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'red' }}>{withdraw}</Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12px', color: 'blue' }}>{bonus}</Typography>
        </MyTableCell>
      </TableRow>
    </Fragment>
  )
}

type TrackData = Schema$PartnerChargeRecordTrackData
const ItemPerPage = 15

interface PageData {
  currPage: number
  totalPage: number
}

const getPageData = (track: TrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const SEARCH_KIND_NAMES = [
  { id: 'ALL', name: '전체' },
  { id: 'IN', name: '입금' },
  { id: 'OUT', name: '출금' },
]

const SEARCH_KEY_NAMES = [
  { id: 'ID', name: '아이디' },
  { id: 'NICK', name: '닉네임' },
]

const now = new Date()
const start = new Date(now.getFullYear(), now.getMonth(), 1)

function PartnerIntergratedChargeRecord({ location }) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { main, page } = query

  const [tabPage, setTabPage] = useState<{ tab: number; page: number }>({ tab: 0, page: 1 })
  const [track, setTrack] = useState<TrackData | null>(null)
  const [loading, setLoading] = useState(false)
  const [searchBegin, setSearchBegin] = useState<Date>(start)
  const [searchEnd, setSearchEnd] = useState<Date>(new Date())
  const [searchKind, setSearchKind] = useState('ALL')
  const [searchKey, setSearchKey] = useState('ID')
  const [includeLess, setIncludeLess] = useState(true)
  const refSearchValue = useRef<string>('')

  const history = useHistory()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { userInfo } = userStore
  const { option } = globalStore
  const { isPartner } = userInfo
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { open_trading_groups, open_trading_kinds } = option || {}

  const isValid = signedin && isPartner
  if (initialized && !isValid) {
    history.push('/')
  }

  const startPage = page ? Number(page) : 1

  if (startPage !== tabPage.page) {
    setTabPage({ tab: 0, page: startPage })
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('PARTNER-CHARGE-RECORD')
    }
  }, [signedin, initialized, serviceState])

  useOnMount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.charge_record)
  })

  useOnUnmount(() => {
    globalStore.enablePartnerMode(PartnerModeKeys.none)
  })

  const fetch = async (params: IPartnerChargeRecord.Params) => {
    setLoading(true)

    try {
      const { data } = await apiPartner.getChargeRecord(params)
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    const params: IPartnerChargeRecord.Params = {
      start_date: searchBegin.toString(),
      end_date: searchEnd.toString(),
      searchKind: searchKind,
      searchKey: searchKey,
      searchValue: refSearchValue.current,
      includeLess: includeLess,

      offset: (tabPage.page - 1) * ItemPerPage,
      limit: ItemPerPage,
    }

    fetch(params)
  }, [tabPage])

  const handleChangeSearchBegin = newValue => {
    setSearchBegin(newValue)
  }

  const handleChangeSearchEnd = newValue => {
    setSearchEnd(newValue)
  }

  const handleChangeSearchKind = event => {
    setSearchKind(event.target.value)
  }

  const handleChangeSearchKey = event => {
    setSearchKey(event.target.value)
  }

  const handleChangeSearchValue = event => {
    refSearchValue.current = event.target.value
  }

  const handleChangeIncludeLess = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIncludeLess(event.target.checked)
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/partner/charge-record/search?page=${value}`)
  }

  const onClickSearch = () => {
    const params: IPartnerChargeRecord.Params = {
      start_date: searchBegin.toString(),
      end_date: searchEnd.toString(),
      searchKind: searchKind,
      searchKey: searchKey,
      searchValue: refSearchValue.current,
      includeLess: includeLess,

      offset: 0,
      limit: ItemPerPage,
    }

    fetch(params)
  }

  const onClickCell = (field: string, id: number) => {
    if (track) {
      const found = _.find(track.items, o => {
        return o.id === id
      })
      if (found) {
        switch (field) {
          case 'account':
            popupPartnerMemberWithPeriod(found.uuid, 'detail', searchBegin, searchEnd)
            break
          case 'detail':
            break
        }
      }
    }
  }

  let pageData = { currPage: 0, totalPage: 0 }
  const rows = []
  if (track) {
    for (const el of track.items) {
      const row: RowData = {
        id: el.id,
        uuid: el.uuid,
        date_request: el.date_request,
        date_done: el.date_done,
        transfer_kind: el.transfer_kind,
        user_id: el.user_id,
        nickname: el.nickname,
        deposit: el.deposit,
        withdraw: el.withdraw,
        bonus: el.bonus,
        status: 'normal',
      }

      rows.push(<Row key={row.id} row={row} onClick={onClickCell}></Row>)
    }

    //if (rows.length > 0 && track.sum) {
    if (track.sum) {
      const sumData: RowData = {
        id: track.sum.id,
        uuid: track.sum.uuid,
        date_request: track.sum.date_request,
        date_done: track.sum.date_done,
        transfer_kind: track.sum.transfer_kind,
        user_id: track.sum.user_id,
        nickname: track.sum.nickname,
        deposit: track.sum.deposit,
        withdraw: track.sum.withdraw,
        bonus: track.sum.bonus,
        status: 'sum',
      }
      rows.unshift(<Row key={sumData.id} row={sumData} onClick={onClickCell}></Row>)
      rows.push(<Row key={sumData.id} row={sumData} onClick={onClickCell}></Row>)
    }

    pageData = getPageData(track)
  }

  const filterKindItems = []
  for (const el of SEARCH_KIND_NAMES) {
    filterKindItems.push(<MenuItem value={el.id}>{el.name}</MenuItem>)
  }

  const filterKeyItems = []
  for (const el of SEARCH_KEY_NAMES) {
    filterKeyItems.push(<MenuItem value={el.id}>{el.name}</MenuItem>)
  }

  const innerContents = (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container
        fixed
        style={{
          minWidth: '1248px',
          maxWidth: '1248px',
        }}
      >
        <Grid container sx={{ margin: '10px 0 48px' }}>
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'end' }}
          >
            <Divider sx={{ width: '200px', fontSize: '16px' }}>충/환전 내역</Divider>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ marginTop: '14px' }}>
              <Stack direction="row-reverse" spacing={1}>
                <Button
                  variant="contained"
                  sx={{ height: '36px', background: '#007f93' }}
                  onClick={() => onClickSearch()}
                >
                  검색
                </Button>
                <FormGroup>
                  <FormControlLabel
                    sx={{ marginTop: '-6px' }}
                    control={
                      <Checkbox
                        checked={includeLess}
                        onChange={handleChangeIncludeLess}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                      />
                    }
                    label="하위포함"
                  />
                </FormGroup>
                <TextField
                  onChange={handleChangeSearchValue}
                  size="small"
                  variant={'outlined'}
                  label="검색값"
                  style={{ width: '180px' }}
                ></TextField>
                <TextField
                  value={searchKey}
                  onChange={handleChangeSearchKey}
                  select
                  size="small"
                  variant={'outlined'}
                  label="검색키"
                  style={{ width: '100px' }}
                >
                  {filterKeyItems}
                </TextField>
                <TextField
                  value={searchKind}
                  onChange={handleChangeSearchKind}
                  select
                  size="small"
                  variant={'outlined'}
                  label="입/출금"
                  style={{ width: '100px' }}
                >
                  {filterKindItems}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="종료"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchEnd}
                    onChange={newValue => handleChangeSearchEnd(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                  <DesktopDatePicker
                    label="시작"
                    inputFormat="yyyy-MM-dd"
                    mask={'____-__-__'}
                    value={searchBegin}
                    onChange={newValue => handleChangeSearchBegin(newValue)}
                    renderInput={params => <TextField size="small" {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ marginTop: '16px' }}>
              <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
              <Table size="small" aria-label="collapsible table">
                {Head()}
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <PageStyle
                count={pageData.totalPage}
                variant="outlined"
                page={pageData.currPage}
                onChange={handlePagingChange}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )

  let contents = null
  if (desktop) {
    contents = (
      <Box
        component="div"
        className={
          channelSymbol === 'marine' ||
            channelSymbol === 'tiger' ||
            channelSymbol === 'hilton' ||
            channelSymbol === 'soft' ||
            channelSymbol === 'gangnam' ||
            channelSymbol === 'milky' ||
            channelSymbol === 'hulk'
            ? 'content_wrap_marine_partner'
            : 'content_wrap'
        }
      >
        {innerContents}
      </Box>
    )
  } else {
    contents = (
      <Box component="div" sx={{ overflow: 'auto', minHeight: 'calc(100vh - 250px)' }}>
        {innerContents}
      </Box>
    )
  }
  return <>{contents}</>
}

export default observer(PartnerIntergratedChargeRecord)
