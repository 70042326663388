import Phaser from 'phaser'
import { getDeviceType } from '~/utils'

const SceneName = 'loader'
export default class LoaderScene extends Phaser.Scene {
  constructor() {
    super({ key: SceneName })
  }

  public preload() {
    // global option
    this.sound.pauseOnBlur = false

    this.load.path = 'assets/evo/'

    this.load.atlas('ui', 'ui/ui.png', 'ui/ui.json')
  }

  public create() {
    console.log(`${SceneName} created!`)

    if (getDeviceType() === 'mobile') {
      this.scene.start('m-panel')
    } else {
      this.scene.start('panel')
    }
  }
}
