import * as _ from 'lodash'
import DarkTheme from './dark.theme'

const WhiteTheme = _.cloneDeep(DarkTheme)

// mui
///WhiteTheme.palette.primary.main = '#6772e5'

// custom
WhiteTheme.colors.mainBg = '#FAFAFA'
WhiteTheme.colors.subBg = '#FFFFFF'
WhiteTheme.colors.btnClear = '#3B4C6E'
WhiteTheme.colors.btnTxtLighten = '#FFFFFF'
WhiteTheme.colors.themeTxt = '#2E3A4D'
WhiteTheme.colors.themeTxtLighten = '#2E3A4D'
WhiteTheme.colors.modalLine = '#DDDFE1'

WhiteTheme.borders.default = '#DDDFE1'

WhiteTheme.buttons.default = '#b0b5bb'
WhiteTheme.buttons.defaultHover = '#9ca1a8'

WhiteTheme.boards.titleBg = '#FAFAFA'
WhiteTheme.boards.contentBg = '#FFF'
WhiteTheme.boards.txtHover = '#43C8DE'

WhiteTheme.pagination.default = '#FAFAFA'
WhiteTheme.pagination.hover = '#43C8DE'
WhiteTheme.pagination.active = '#43C8DE'
WhiteTheme.pagination.txtActive = '#FFFFFF'
WhiteTheme.pagination.border = '1px solid #DDDFE1'
WhiteTheme.pagination.txtDisabled = '#CBCCCC'

WhiteTheme.forms.formBg = '#FFFFFF'
WhiteTheme.forms.formLine = '#DDDFE1' 
WhiteTheme.forms.disabledBg = '#f5f5f5' 
WhiteTheme.forms.disabledBorder = '#dddfe1'

WhiteTheme.tabs.default = '#FFF'
WhiteTheme.tabs.tabBorder = '#DDDFE1'
WhiteTheme.tabs.tabBg = '#FAFAFA'
WhiteTheme.tabs.tabActive = '#43C8DE'

WhiteTheme.footer.background = '#ffffff'

export default WhiteTheme
