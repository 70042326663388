/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import { observer } from 'mobx-react'
import { Box, Typography, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch, { SwitchProps } from '@mui/material/Switch'
import * as _ from 'lodash'
import moment from 'moment'
import * as Yup from 'yup'

import { numberWithCommas, fillZero, invalidateNumber } from '~/utils/utils'
import useRootStore from '@store/useRootStore'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import NumberFormat from 'react-number-format'
import { ModalKeys } from '@store/globalStore'

// icon
import { ReactComponent as IconTrash } from '@assets/images/layout_1/icon_trash.svg'
import { ReactComponent as IconBetting } from '@assets/images/layout_1/icon_betting.svg'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// css
import { BasicButton } from '~/styles/button.style'
import { BettingCartStyle, CartListStyle, AmountStyle } from './index.style'
import { string } from 'yup/lib/locale'
import { styled } from '@mui/material/styles'
import { Formik } from 'formik'
import { useOnUnmount } from '~/utils/reactExt'
import SportsBettingCartItem from './BettingCartItem'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '~/constants'

const BettingControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '6px 8px',
  borderTop: '1px solid #CFCFCF',
  fontSize: '0.9rem',

  '& .badge': {
    backgroundColor: '#0F0F0F',
    borderRadius: '50%',
    color: '#fff',
    padding: '3px 0',
    width: '16px',
  },
}))

const Android12Switch = styled(Switch)(({ theme }) => ({
  width: 55,
  height: 35,
  padding: 8,
  marginLeft: '-6px',
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      color: '#fff',
      fontSize: '0.8rem',
      content: `"on"`,
      left: 12,
    },
    '&:after': {
      color: '#fff',
      fontSize: '0.8rem',
      content: `"off"`,
      right: 9,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 15,
    height: 15,
    margin: 1,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#fff',
  },
  '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
    backgroundColor: '#43C8DE',
    opacity: '1',
  },
}))

const CartFormStyle = styled('div')`
  .item_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const ItemButton = styled(Button)(({ theme }) => ({
  marginBottom: 8,
  backgroundColor: theme.buttons.success,
  border: '1px solid transparent',
  borderRadius: '0.3rem',
  color: '#fff',
  fontSize: '1rem',
  minWidth: '31.5%',
  padding: '0.5rem 0',

  '&:hover': {
    backgroundColor: theme.buttons.successHover,
    color: '#fff',
  },
  '&:disabled': {
    color: '#c0cad8',
  },
  '&.clear': {
    minWidth: '48.8%',
    backgroundColor: '#252f40',
  },
  '&.clear:hover': {
    backgroundColor: '#1a1e25',
  },
  '&.clear:disabled': {
    color: '#959595',
  },
}))

let timer

const s = 1000
const m = s * 60
const h = m * 60
const d = h * 24

const BettingCartControlBox: React.FC<{}> = props => {
  const intl = useIntl()
  const { userStore, sportsStore } = useRootStore()

  const refBlockSubmitting = useRef<boolean>(false)

  const { userMoney } = userStore
  const { dividendRate, dividend, lang } = sportsStore

  const { balance } = userMoney

  const submit = async (values: any, { setSubmitting, setErrors, setStatus, resetForm }) => {
    if (refBlockSubmitting.current) {
      return
    }
    const { volume } = values
    let amount = invalidateNumber(volume)
    sportsStore.setBetMoney(amount)
    const ret = await sportsStore.bet(intl)
    if (ret) {
      resetForm()
    }
  }

  return (
    <Formik
      initialValues={{
        volume: 0,
      }}
      onSubmit={submit}
      validationSchema={Yup.object().shape({
        // volume: Yup.number().required(intl.formatMessage({ id: 'msg.field-require' })),
      })}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setSubmitting,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <AmountStyle sx={{ px: 2, py: 1 }}>
            <p>
              <span>{lang === 'ko' ? '총 배당률' : 'Total Odds'}</span>
              <span className="highlight">{dividendRate}</span>
            </p>
            <p>
              <span>{lang === 'ko' ? '예상 배당금액' : 'Dividends'}</span>
              <span>
                <span className="highlight">{numberWithCommas(dividend)}</span>
                {intl.formatMessage({ id: 'money-locale' })}
              </span>
            </p>
            <p>
              <span className="my_money">{lang === 'ko' ? '내 보유금액' : 'Balance'}</span>
              <span>
                <span className="my_money">{numberWithCommas(balance)}</span>
                {intl.formatMessage({ id: 'money-locale' })}
              </span>
            </p>
            <div className="betting_input">
              <div className="form_label">{lang === 'ko' ? '베팅' : 'Betting'}</div>
              <NumberFormat
                className="form_control"
                name="volume"
                value={values.volume}
                onChange={e => {
                  handleChange(e)
                  try {
                    const { value } = e.target
                    let tmp = invalidateNumber(value)
                    sportsStore.setBetMoney(tmp)
                  } catch (err) {
                    console.log(err)
                  }
                }}
                onBlur={handleBlur}
                thousandSeparator={true}
                suffix={lang === 'ko' ? '원' : intl.formatMessage({ id: 'money-locale' })}
                style={{ padding: '0.35rem' }}
              ></NumberFormat>
            </div>
          </AmountStyle>

          <CartFormStyle sx={{ px: 2, pb: 1 }}>
            <Box className="item_buttons">
              <ItemButton
                onClick={() => {
                  refBlockSubmitting.current = true
                  let tmp = invalidateNumber(values.volume)
                  tmp += 5000
                  tmp = Math.min(balance, tmp)
                  setFieldValue('volume', tmp)
                  sportsStore.setBetMoney(tmp)
                }}
              >
                {lang === 'ko' ? '5천' : '5K'}
              </ItemButton>
              <ItemButton
                onClick={() => {
                  refBlockSubmitting.current = true
                  let tmp = invalidateNumber(values.volume)
                  tmp += 10000
                  tmp = Math.min(balance, tmp)
                  setFieldValue('volume', tmp)
                  sportsStore.setBetMoney(tmp)
                }}
              >
                {lang === 'ko' ? '1만' : '10K'}
              </ItemButton>
              <ItemButton
                onClick={() => {
                  refBlockSubmitting.current = true
                  let tmp = invalidateNumber(values.volume)
                  tmp += 50000
                  tmp = Math.min(balance, tmp)
                  setFieldValue('volume', tmp)
                  sportsStore.setBetMoney(tmp)
                }}
              >
                {lang === 'ko' ? '5만' : '50K'}
              </ItemButton>
              <ItemButton
                onClick={() => {
                  refBlockSubmitting.current = true
                  let tmp = invalidateNumber(values.volume)
                  tmp += 100000
                  tmp = Math.min(balance, tmp)
                  setFieldValue('volume', tmp)
                  sportsStore.setBetMoney(tmp)
                }}
              >
                {lang === 'ko' ? '10만' : '100K'}
              </ItemButton>
              <ItemButton
                onClick={() => {
                  refBlockSubmitting.current = true
                  let tmp = invalidateNumber(values.volume)
                  tmp += 500000
                  tmp = Math.min(balance, tmp)
                  setFieldValue('volume', tmp)
                  sportsStore.setBetMoney(tmp)
                }}
              >
                {lang === 'ko' ? '50만' : '500K'}
              </ItemButton>
              <ItemButton
                onClick={() => {
                  refBlockSubmitting.current = true
                  let tmp = invalidateNumber(values.volume)
                  tmp += 1000000
                  tmp = Math.min(balance, tmp)
                  setFieldValue('volume', tmp)
                  sportsStore.setBetMoney(tmp)
                }}
              >
                {lang === 'ko' ? '100만' : '1M'}
              </ItemButton>
              <ItemButton
                className="clear"
                onClick={() => {
                  refBlockSubmitting.current = true
                  setFieldValue('volume', balance)
                  sportsStore.setBetMoney(balance)
                }}
              >
                MAX
              </ItemButton>
              <ItemButton
                className="clear"
                onClick={() => {
                  refBlockSubmitting.current = true
                  setFieldValue('volume', 0)
                  sportsStore.setBetMoney(0)
                }}
              >
                {lang === 'ko' ? '초기화' : 'Reset'}
              </ItemButton>
            </Box>

            <Box sx={{ textAlign: 'center' }}>
              <BasicButton
                disabled={isSubmitting}
                type="submit"
                className="btn_betting"
                onClick={() => {
                  refBlockSubmitting.current = false
                  let tmp = invalidateNumber(values.volume)
                  setFieldValue('volume', tmp)
                }}
                sx={{ mt: 1 }}
              >
                <IconBetting width="24" />
                {lang === 'ko' ? '베팅하기' : 'Place Bet'}
              </BasicButton>
            </Box>
          </CartFormStyle>
        </form>
      )}
    </Formik>
  )
}

export default observer(BettingCartControlBox)
