import { v4 } from 'uuid'

export default class InfoComponent extends Phaser.GameObjects.Container {
  timerBox: Phaser.GameObjects.Graphics = null
  timerBar: Phaser.GameObjects.Graphics = null
  timerText: Phaser.GameObjects.Text = null

  dateRound: Phaser.GameObjects.Text = null
  roundId: Phaser.GameObjects.Text = null

  onTimerEvent: (timerId: string, eventId: string) => void = null
  onTimerComplete: (timerId: string) => void = null

  timerId = ''
  running = false
  duration = 0
  startTime = 0
  destTime = 0
  ellapsedTime = 0 // milliseconds
  eventFired: string[] = []

  barHeight = 0
  barWidth = 0
  barX = 0
  barY = 0
  loadingBarBgColor = 0x222222
  loadingBarFgColor = 0x069c46
  loadingBarTweenColor = 0xf23b44

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    onTimerEvent: (timerId: string, eventId: string) => void,
    onTimerComplete: (timerId: string) => void,
  ) {
    super(scene, x, y)

    this.scene.add.existing(this)

    this.onTimerEvent = onTimerEvent
    this.onTimerComplete = onTimerComplete

    const boxWidth = 744
    const boxHeight = 6
    const boxX = -boxWidth * 0.5
    const boxY = -boxHeight * 0.5 - 16
    const timerTextOffsetY = 3
    const horzOffset = 366
    const roundIdColor = '#a5a5a5'
    const roundIdPosX = horzOffset

    // const boxWidth = 744
    // const boxHeight = 22
    // const boxX = -boxWidth * 0.5
    // const boxY = -boxHeight * 0.5
    // const timerTextOffsetY = 0
    // const horzOffset = 366
    // const roundIdPosX = -horzOffset + 140
    // const roundIdColor = '#d9d9d9'

    const barPadding = 2
    this.barHeight = boxHeight - barPadding * 2
    this.barWidth = boxWidth - barPadding * 2
    this.barX = boxX + barPadding
    this.barY = boxY + barPadding

    this.timerBox = this.scene.add.graphics()
    this.timerBox.fillStyle(this.loadingBarBgColor, 0.8)
    this.timerBox.fillRect(boxX, boxY, boxWidth, boxHeight)
    this.timerBar = this.scene.add.graphics()
    this.add(this.timerBox)
    this.add(this.timerBar)

    this.timerText = this.scene.add
      .text(0, timerTextOffsetY, '', {
        fontSize: '16px',
        fontFamily: 'PT Serif',
      })
      .setOrigin(1, 0.5)
    this.add(this.timerText)

    // add text

    this.dateRound = this.scene.add
      .text(-horzOffset, 0, '', {
        fontSize: '12px',
        color: '#d9d9d9',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0, 0.5)
    this.add(this.dateRound)

    this.roundId = this.scene.add
      .text(roundIdPosX, 0, '', {
        fontSize: '12px',
        color: roundIdColor,
        fontFamily: 'PT Serif',
      })
      .setOrigin(1, 0.5)
    this.add(this.roundId)
  }

  update(time: number, deltaTime: number) {
    // console.log(`${time} ${deltaTime}`)

    if (!this.running) {
      return
    }

    // const duration = this.duration // ms
    // this.ellapsedTime += Math.floor(deltaTime)

    // if (this.ellapsedTime > duration) {
    //   this.ellapsedTime = duration
    //   this.running = false
    //   if (this.onTimerComplete) {
    //     this.onTimerComplete(this.timerId)
    //   }
    // }

    const now = new Date()
    const duration = this.duration // ms
    this.ellapsedTime = Math.min(now.getTime() - this.startTime, this.duration)
    this.ellapsedTime = Math.max(this.ellapsedTime, 0)
    if (this.ellapsedTime >= this.duration) {
      this.running = false
      this.onTimerComplete(this.timerId)
    }

    this.setTimerProgress(this.ellapsedTime, duration)
  }

  setWaiting() {
    this.setDateRound(0)
    this.setRoundId(0)
    this.timerText.setText('대기중입니다.')
  }

  setDateRound(dateRound: number) {
    this.dateRound.setText(`${dateRound} 회차`)
  }

  setRoundId(roundId: number) {
    this.roundId.setText(roundId > 0 ? `(${roundId})` : '')
  }

  runTimer(timerId: string, duration: number) {
    const now = new Date()
    const destTime = now.getTime() + duration

    this.timerId = timerId
    this.running = true
    this.startTime = destTime - duration
    this.destTime = destTime
    this.duration = duration
    this.ellapsedTime = 0
    this.eventFired = []
  }

  runTimerBy(timerId: string, destTime: number, duration: number) {
    this.timerId = timerId
    this.running = true
    this.duration = duration
    this.startTime = destTime - duration
    this.destTime = destTime
    this.ellapsedTime = 0
    this.eventFired = []

    const now = new Date()
    const startTime = new Date(destTime - duration)
    this.ellapsedTime = now.getTime() - startTime.getTime()
  }

  fireEventOnce(eventId: string) {
    if (this.eventFired.indexOf(eventId) < 0) {
      this.onTimerEvent(this.timerId, eventId)
      this.eventFired.push(eventId)
    }
  }

  setTimerProgress(ellapsedTime: number, maxTime: number) {
    const ratio = ellapsedTime / maxTime
    const offset = Math.floor(this.barWidth * ratio)
    const width = this.barWidth - Math.max(offset, 0)

    let color = this.loadingBarFgColor
    const leftRatio = 1 - ratio
    if (leftRatio < 0.5) {
      color = this.loadingBarTweenColor
      this.fireEventOnce('half')
    }
    if (leftRatio < 0.3 && this.duration >= 10 * 1000) {
      const rest = ellapsedTime % 500
      if (rest < 100) {
        color = 0xffb5b5
      }
      this.fireEventOnce('soon')
    }

    this.timerBar.clear()
    this.timerBar.fillStyle(color, 1)
    this.timerBar.fillRect(this.barX, this.barY, width, this.barHeight)

    const timeRest = maxTime - ellapsedTime
    if (timeRest > 0) {
      const tmp = Math.floor(timeRest * 0.001)
      this.timerText.setText((tmp + 1).toString())
    } else {
      this.timerText.setText('0')
      setTimeout(() => {
        this.timerText.setText('')
      }, 1000)
    }
  }
}
