import { v4 } from 'uuid'
import { ChipAttributes } from './types'

export default class ChipButton extends Phaser.GameObjects.Container {
  normal: Phaser.GameObjects.Image = null
  over: Phaser.GameObjects.Image = null

  highlight: Phaser.GameObjects.Image = null

  title: Phaser.GameObjects.Text = null

  captured = false

  enabled = true

  attributes: ChipAttributes = null

  onClick: (btn: ChipButton) => void = null

  disabledAlpha = 0.6

  highlighted = false

  instanceId = ''

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    attributes: ChipAttributes,
    onClick: (btn: ChipButton) => void,
  ) {
    super(scene, x, y)

    this.scene.add.existing(this)

    this.instanceId = v4()

    this.attributes = attributes
    this.onClick = onClick

    this.normal = scene.add.image(0, 0, 'ui', attributes.type)
    this.over = scene.add.image(0, 0, 'ui', 'chip_over.png')
    this.highlight = scene.add.image(0, 0, 'ui', 'chip_highlight.png')

    this.add(this.over)
    this.add(this.normal)
    this.add(this.highlight)

    this.over.setVisible(false)
    this.highlight.setVisible(false)

    this.setSize(this.normal.width, this.normal.height)

    // add text
    this.title = this.makeTitle()
    this.add(this.title)

    this.setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OVER, () => {
        // this.captured = false	// prevent over for mobile
        this.normal.setVisible(true)
        this.over.setVisible(this.enabled)
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_OUT, () => {
        this.captured = false
        this.normal.setVisible(true)
        this.over.setVisible(false)
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_UP, () => {
        const lastCaptured = this.captured
        this.captured = false
        this.normal.setVisible(true)
        this.over.setVisible(this.enabled)
        if (lastCaptured) {
          if (this.onClick) {
            this.onClick(this)
          }
        }
      })
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
        if (!this.enabled) {
          return
        }
        this.captured = true
        this.normal.setVisible(true)
        this.over.setVisible(true)
      })
  }

  makeTitle() {
    const fontSize = `${this.attributes.fontSize}px`
    // let fontSize = '13px'
    // if (data.title.length === 3) {
    //   fontSize = '15px'
    // } else if (data.title.length < 3) {
    //   fontSize = '19px'
    // }
    const title = this.scene.add
      .text(0, 0, this.attributes.title, {
        fontSize,
        color: '#ffffff',
        fontFamily: 'PT Serif',
        fontStyle: 'bold',
        // stroke: '#6b6b6b',
        // strokeThickness: 1,
        shadow: {
          offsetX: 1,
          offsetY: 1,
          color: '#2c280c',
          stroke: true,
          fill: true,
        },
      })
      .setOrigin(0.5, 0.5)
    return title
  }

  // makeGhost(): Phaser.GameObjects.Container {
  //   const container = new Phaser.GameObjects.Container(this.scene, 0, 0)
  //   const normal = this.scene.add.image(0, 0, 'ui', this.buttonData.type)
  //   const title = this.makeTitle()
  //   container.add(normal)
  //   container.add(title)
  //   return container
  // }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
    const alpha = enabled ? 1 : this.disabledAlpha
    this.normal.setAlpha(alpha)
    this.over.setAlpha(alpha)
    this.title.setAlpha(alpha)

    this.setHighlight(false)
  }

  setHighlight(highlight: boolean) {
    // console.log(`${this.attributes.id} ${highlight}`)
    this.highlighted = highlight
    this.highlight.setVisible(highlight)
  }
}
