/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import React from 'react'
import { observer } from 'mobx-react'
import * as _ from 'lodash'
import moment from 'moment'

import { numberWithCommas, fillZero } from '~/utils/utils'
import useRootStore from '@store/useRootStore'

// css
import { Timebar } from './index.style'
import { string } from 'yup/lib/locale'
import { useEffect, useState } from 'react'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'

let timer

const s = 1000
const m = s * 60
const h = m * 60
const d = h * 24

const BettingTimerbar: React.FC = props => {
  const { miniGameStore } = useRootStore()
  const { matchupSource, matchup } = miniGameStore
  const [nextOpenTime, setNextOpenTime] = useState('59:59')
  const [progressBettingTime, setProgressBettingTime] = useState(0)

  const setNextOpenTimeFunc = (dateFrom, dateTo) => {
    const now = new Date()
    const diff = dateTo.getTime() - now.getTime()

    const seconds = Math.floor((diff % m) / s)
    const minutes = Math.floor((diff % h) / m)
    const arr = [_.padStart(minutes.toString(), 2, '0'), _.padStart(seconds.toString(), 2, '0')]
    let txtRemain = arr.join(':')

    const offset = dateTo.getTime() - dateFrom.getTime()
    let percentage = (diff / offset) * 100

    if (percentage < 0.0) {
      txtRemain = '베팅이 종료되었습니다.'
    }
    if (diff <= offset) {
      setNextOpenTime(txtRemain)
      setProgressBettingTime(percentage < 0 ? 0 : percentage)
    } else {
      setNextOpenTime('베팅 대기중입니다.')
      setProgressBettingTime(0)
    }
  }

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
    let from = new Date()
    let to = new Date()
    if (matchupSource) {
      from = new Date(matchupSource.bet_start_time)
      to = new Date(matchupSource.bet_end_time)
    }
    timer = setInterval(() => {
      setNextOpenTimeFunc(from, to)
    }, 500)
  }, [matchupSource])

  useOnUnmount(() => {
    if (timer) {
      clearInterval(timer)
      timer = null
    }
  })

  let txtRoundInfo = ''
  if (matchup) {
    txtRoundInfo = `${matchup.round}회 ${nextOpenTime}`
  }

  return (
    <Timebar>
      <div className="gauge">
        <div className="inner" style={{ width: `${progressBettingTime}%` }}></div>
        <span className="round_time">{txtRoundInfo}</span>
      </div>
    </Timebar>
  )
}

export default observer(BettingTimerbar)
