/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Button, Typography } from '@mui/material'
import useRootStore from '@store/useRootStore'

function Fallback() {
  const onClickClose = () => {
    window.opener = null
    window.open('', '_self')
    window.close()
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={3} sx={{ color: '#b2c7d9', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h4">게임이 종료되었습니다.</Typography>
      </Grid>
      <Grid item xs={3} sx={{ color: '#b2c7d9', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h4">현재 창을 닫아주세요.</Typography>
      </Grid>
      <Grid item xs={3} sx={{ mt: '20px', color: '#b2c7d9', alignItems: 'center', justifyContent: 'center' }}>
        <Button size="large" variant="contained" onClick={onClickClose}>
          <Typography variant="h6">창 닫기</Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

export default Fallback
