/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as _ from 'lodash'
import 'slick-carousel/slick/slick.css'
import asset_channel from '@assets/channel'
import Slider from 'react-slick'

const SliderWrap = styled(Box)(() => ({
  '& .slick-slide img': {
    transform: 'scale(1)',
    transition: 'transform .5s linear',
  },
  '& .slick-slide.slick-active img': {
    transform: 'scale(1.03)',
  }
}))

const sliderInfo = [
  {
    image: asset_channel.common.img_sample_main_banner01_m,
    title: '01',
  },
  {
    image: asset_channel.common.img_sample_main_banner02_m,
    title: '02',
  },
  {
    image: asset_channel.common.img_sample_main_banner03_m,
    title: '03',
  },
  {
    image: asset_channel.common.img_sample_main_banner04_m,
    title: '04',
  },
]

const TopBanner = () => {
  const arrSliderInfo = []
  const sliderImage = []
  for (let i = 0; i < sliderInfo.length; i++) {
    const el = sliderInfo[i]
    
    arrSliderInfo.push(el)
    sliderImage.push(
      <img
        key={i.toString()}
        src={el.image}
        alt={'banner' + el.title}
        style={{ width: '100%', cursor: 'pointer' }}
      />,
    )
  }

  const settings = {
    dots: false,
    fade: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  }

  return (
    <SliderWrap>
      <Slider {...settings}>
        {sliderImage}
      </Slider>
    </SliderWrap>
  )
}

export default TopBanner