/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React from 'react'
import { Accordion, Nav } from 'react-bootstrap'
import classNames from 'classnames'
import { isGetAccessorDeclaration } from 'typescript'

import { RouteMenu } from './RouteMenu'

// css
import { AccordionStyle, NavStyle } from './index.style'

interface Props {
  title: string
  items: RouteMenu[]
  onClick: (RouteMenu) => void
}

interface State {
  collapsed: boolean
}

class SubMenu extends React.Component<Props, State> {
  state = { collapsed: true }

  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed,
    })
  }

  render() {
    const { title, items, onClick } = this.props

    const bodyItems: React.ReactNode[] = []

    for (const el of items) {
      bodyItems.push(
        <Nav.Item key={el.id} css={NavStyle}>
          <Nav.Link
            onClick={() => {
              onClick(el)
            }}
          >
            {/* {intl.get(el.locale)} */}
          </Nav.Link>
        </Nav.Item>,
      )
    }

    return (
      <Nav.Item className={classNames({ open: !this.state.collapsed })}>
        <Accordion css={AccordionStyle}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{title}</Accordion.Header>
            <Accordion.Body>{bodyItems}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Nav.Item>
    )

    // return (
    //   <div>
    //     <NavItem
    //       onClick={this.toggleNavbar}
    //       className={classNames({ "menu-open": !this.state.collapsed })}
    //     >
    //       <NavLink className="dropdown-toggle" href="#">
    //         {title}
    //       </NavLink>
    //     </NavItem>
    //     <Collapse
    //       isOpen={!this.state.collapsed}
    //       navbar
    //       className={classNames("items-menu", {
    //         "mb-1": !this.state.collapsed
    //       })}
    //     >
    //       {items.map(item => (
    //         <NavItem key={item} className="pl-4">
    //           <NavLink>{item}</NavLink>
    //         </NavItem>
    //       ))}
    //     </Collapse>
    //   </div>
    // );
  }
}

export default SubMenu
