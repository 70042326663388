// image
import {
  homeBannerMg_PB1,
  homeBannerMg_PL1,
  homeBannerMg_CPB3M,
  homeBannerMg_CPB5M,
  homeBannerMg_CL3M,
  homeBannerMg_CL5M,
  homeBannerMg_SG2M,
  homeBannerMg_SG3M,
  homeBannerMg_SG5M,
  homeBannerMg_EOS1M,
  homeBannerMg_EOS2M,
  homeBannerMg_EOS3M,
  homeBannerMg_EOS4M,
  homeBannerMg_EOS5M,
  homeBannerMg_PBG,
  homeBannerMg_KINO1,
  homeBannerMg_HB,
  homeBannerMg_NPB,
  homeBannerMg_EVO,
  homeBannerMg_WRBTCPB1,
  homeBannerMg_WRBTCPL1,
  homeBannerMg_WRBEXPB1,
  homeBannerMg_WRKENOPB1,
  homeBannerMg_WRKENOPL1,
} from '@assets/images/layout_1'
import {
  RENAISSANCE,
  BLUEOCEAN,
  SEAWORLD,
  YAMATO,
  RENAISSANCE_BIG,
  BLUEOCEAN_BIG,
  SEAWORLD_BIG,
  YAMATO_BIG,
} from '~/assets/images/megahit'
import * as imgMinigame from '~/assets/images/layout_1/minigame'
import * as imgMinigameMarine from '~/assets/images/layout_1/minigame/marine'
import * as imgMinigameMarine2 from '~/assets/images/layout_3/minigame/marine2'

const channelSymbol = process.env.REACT_APP_CHANNEL || ''
let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

let mergedImgMinigame = { ...imgMinigame }
let mergedHoverImg = { ...imgMinigameMarine }

switch (channelSymbol) {
  case 'marine':
  case 'tiger':
  case 'hilton':
  case 'soft':
  case 'gangnam':
  case 'milky':
  case 'hulk':
    mergedImgMinigame = { ...mergedImgMinigame, ...imgMinigameMarine }
    mergedHoverImg = { ...mergedHoverImg, ...imgMinigameMarine }
    break
  case 'marine2':
    mergedImgMinigame = { ...mergedImgMinigame, ...imgMinigameMarine2 }
    break
}

export const AllMiniGames = [
  {
    kind: 'EVOPB1',
    thumbnail: homeBannerMg_EVO,
    title: '에볼루션 파워볼',
    localeId: 'trading.MG.EVOPB1',
  },
  {
    kind: 'HPB1',
    thumbnail: mergedImgMinigame.homeBannerMg_HB,
    title: '해피545 파워볼',
    hoverimg: mergedHoverImg.homeBannerMg_HB_h,
    localeId: 'trading.MG.HPB1',
  },
  {
    kind: 'NPB1',
    thumbnail: mergedImgMinigame.homeBannerMg_NPB,
    title: '엔트리 파워볼',
    hoverimg: mergedHoverImg.homeBannerMg_NPB_h,
    localeId: 'trading.MG.NPB1',
  },
  {
    kind: 'PB1',
    thumbnail: mergedImgMinigame.homeBannerMg_PB1,
    title: '동행 파워볼',
    hoverimg: mergedHoverImg.homeBannerMg_PB1_h,
    localeId: 'trading.MG.PB1',
  },
  {
    kind: 'PL1',
    thumbnail: mergedImgMinigame.homeBannerMg_PL1,
    title: '동행 파워사다리',
    hoverimg: mergedHoverImg.homeBannerMg_PL1_h,
    localeId: 'trading.MG.PL1',
  },
  {
    kind: 'EOS1M',
    thumbnail: mergedImgMinigame.homeBannerMg_EOS1M,
    title: 'EOS 파워볼 1분',
    hoverimg: mergedHoverImg.homeBannerMg_EOS1M_h,
    localeId: 'trading.MG.EOS1M',
  },
  {
    kind: 'EOS2M',
    thumbnail: mergedImgMinigame.homeBannerMg_EOS2M,
    title: 'EOS 파워볼 2분',
    hoverimg: mergedHoverImg.homeBannerMg_EOS2M_h,
    localeId: 'trading.MG.EOS2M',
  },
  {
    kind: 'EOS3M',
    thumbnail: mergedImgMinigame.homeBannerMg_EOS3M,
    title: 'EOS 파워볼 3분',
    hoverimg: mergedHoverImg.homeBannerMg_EOS3M_h,
    localeId: 'trading.MG.EOS3M',
  },
  {
    kind: 'EOS4M',
    thumbnail: mergedImgMinigame.homeBannerMg_EOS4M,
    title: 'EOS 파워볼 4분',
    hoverimg: mergedHoverImg.homeBannerMg_EOS4M_h,
    localeId: 'trading.MG.EOS4M',
  },
  {
    kind: 'EOS5M',
    thumbnail: mergedImgMinigame.homeBannerMg_EOS5M,
    title: 'EOS 파워볼 5분',
    hoverimg: mergedHoverImg.homeBannerMg_EOS5M_h,
    localeId: 'trading.MG.EOS5M',
  },
  {
    kind: 'PBG1',
    thumbnail: mergedImgMinigame.homeBannerMg_PBG,
    title: 'PBG 파워볼',
    hoverimg: mergedHoverImg.homeBannerMg_PBG_h,
    localeId: 'trading.MG.PBG1',
  },
  {
    kind: 'KINO1',
    thumbnail: mergedImgMinigame.homeBannerMg_KINO1,
    title: '동행스피드키노',
    hoverimg: mergedHoverImg.homeBannerMg_KINO1_h,
    localeId: 'trading.MG.KINO1',
  },
  {
    kind: 'CPB3M',
    thumbnail: mergedImgMinigame.homeBannerMg_CPB3M,
    title: '코인 파워볼 3분',
    hoverimg: mergedHoverImg.homeBannerMg_CPB3M_h,
    localeId: 'trading.MG.CPB3M',
  },
  {
    kind: 'CPB5M',
    thumbnail: mergedImgMinigame.homeBannerMg_CPB5M,
    title: '코인 파워볼 5분',
    hoverimg: mergedHoverImg.homeBannerMg_CPB5M_h,
    localeId: 'trading.MG.CPB5M',
  },
  {
    kind: 'CL3M',
    thumbnail: mergedImgMinigame.homeBannerMg_CL3M,
    title: '코인 사다리 3분',
    hoverimg: mergedHoverImg.homeBannerMg_CL3M_h,
    localeId: 'trading.MG.CL3M',
  },
  {
    kind: 'CL5M',
    thumbnail: mergedImgMinigame.homeBannerMg_CL5M,
    title: '코인 사다리 5분',
    hoverimg: mergedHoverImg.homeBannerMg_CL5M_h,
    localeId: 'trading.MG.CL5M',
  },
  {
    kind: 'SG2M',
    thumbnail: homeBannerMg_SG2M,
    title: '오징어게임 2분',
    localeId: 'trading.MG.SG2M',
  },
  {
    kind: 'SG3M',
    thumbnail: homeBannerMg_SG3M,
    title: '오징어게임 3분',
    localeId: 'trading.MG.SG3M',
  },
  {
    kind: 'SG5M',
    thumbnail: homeBannerMg_SG5M,
    title: '오징어게임 5분',
    localeId: 'trading.MG.SG5M',
  },
  {
    kind: 'WRBTCPB1',
    thumbnail: homeBannerMg_WRBTCPB1,
    title: '우리볼BTC파워볼',
    localeId: 'trading.MG.WRBTCPB1',
  },
  {
    kind: 'WRBTCPL1',
    thumbnail: homeBannerMg_WRBTCPL1,
    title: '우리볼BTC사다리',
    localeId: 'trading.MG.WRBTCPL1',
  },
  {
    kind: 'WRBEXPB1',
    thumbnail: homeBannerMg_WRBEXPB1,
    title: '우리볼BEX파워볼',
    localeId: 'trading.MG.WRBEXPB1',
  },
  {
    kind: 'WRKENOPB1',
    thumbnail: homeBannerMg_WRKENOPB1,
    title: '동행키노플러스파워볼',
    localeId: 'trading.MG.WRKENOPB1',
  },
  {
    kind: 'WRKENOPL1',
    thumbnail: homeBannerMg_WRKENOPL1,
    title: '동행키노사다리',
    localeId: 'trading.MG.WRKENOPL1',
  },
  {
    kind: 'WRKENO1',
    thumbnail: homeBannerMg_WRKENOPB1,
    title: '동행키노파워볼',
    localeId: 'trading.MG.WRKENO1',
  },
]

export const AllMegahitGames = [
  {
    kind: 'SEASTORY',
    thumbnail: SEAWORLD,
    thumbnail_big: SEAWORLD_BIG,
    // title: '씨월드',
  },
  {
    kind: 'OCEANPARADISE',
    thumbnail: BLUEOCEAN,
    thumbnail_big: BLUEOCEAN_BIG,
    // title: '블루오션',
  },
  {
    kind: 'DAVINCH',
    thumbnail: RENAISSANCE,
    thumbnail_big: RENAISSANCE_BIG,
    // title: '르네상스',
  },
  {
    kind: 'YAMATO',
    thumbnail: YAMATO,
    thumbnail_big: YAMATO_BIG,
    // title: '야마토',
  },
]
