/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Chip } from '@mui/material'
import { popupCenter } from '@utils/utils'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

// css
import { ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// icon
import CloseIcon from '@mui/icons-material/Close'

// image
import DefaultContainer, { DefaultImages } from './d'
import MobileContainer, { MobileImages } from './m'

const NumberStyle = {
  mr: 1,
  borderRadius: 0,
  backgroundColor: '#FE3D3D',
  color: '#FFFFFF',
  height: 26,
  '.MuiChip-label': {
    padding: '0 10px',
  },
}

const PopupManualCoin: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore } = useRootStore()

  const [state, setState] = useState(false)

  const { currentKey, currentValue } = globalStore
  const { initialized, signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.menual_coin
  const tabIndex = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = tabIndex !== ''

  let visible = visibleOnDemand && signedin
  if (!signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
    return null
  }

  if (visible !== state) {
    setState(visible)
    return null
  }

  function onClose() {
    globalStore.hideModal(modalKey)
  }

  const targetImages = desktop ? DefaultImages : MobileImages

  const innerContents = (
    <>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="1" />
        충전페이지에서 코인결제를 누르면 비트덱스 페이지로 자동 접속됩니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[0]} alt="menual01" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="2" />
        하단의 인증 후, 거래하기 버튼을 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[1]} alt="menual02" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="3" />
        신원 인증단계 팝업이 나타납니다. 인증은 처음 한번만 진행합니다. 0단계부터 3단계까지 진행합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[2]} alt="menual03" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="4" />
        모든 인증 완료 후 지정 가상계좌가 발급됩니다. 구매 금액을 입력 후 가상계좌에 구매 금액을 이체시킨 후
        '입금확인'을 선택합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[3]} alt="menual04" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="5" />
        충전페이지에서 코인조회를 누르고 원하는 전환 금액을 입력 후 신청하기를 누르면 게임머니가 제공됩니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[4]} alt="menual05" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <BasicButton className="btn_sub" onClick={() => onClose()} sx={{ width: '300px' }}>
          닫기
        </BasicButton>
      </Box>
    </>
  )

  let contents = null
  if (desktop) {
    contents = <DefaultContainer visible={visible} onClose={onClose} innerContents={innerContents}></DefaultContainer>
  } else {
    contents = <MobileContainer visible={visible} onClose={onClose} innerContents={innerContents}></MobileContainer>
  }
  return <>{contents}</>
}

export default observer(PopupManualCoin)
