/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useIntl } from 'react-intl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

import useRootStore from '@store/useRootStore'
import { BankServiceVolumes } from '@store/userStore'
import { IBankState, IMyBankInfo, Schema$BankState, Schema$MyBankInfo } from '@protocol/bank'
import { BANK_CODES, BANK_MENU } from '~/constants/attrs'
import { numberWithCommas, popupCenter } from '~/utils/utils'
import apiUser from '@services/api/user'

//css
import { FormStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'
import { DepositCoinStyle } from '../index.style'
import { NeatTextField } from '@views/Partner/PartnerIntergratedMember/index.component'

const BankStyle = styled(Box)(({ theme }) => ({
  '& .MuiGrid-item .MuiButton-root': {
    padding: 0,
    height: '41px',
    minWidth: 0,
    width: '100%',
  },
}))

const SITE_ID = process.env.REACT_APP_VENICN_SITE_ID || ''
const API_KEY = process.env.REACT_APP_VENICN_API_KEY || ''
const API_BUY_URL = process.env.REACT_APP_VENICN_API_BUY_URL || ''
const API_LOOKUP_URL = process.env.REACT_APP_VENICN_API_LOOKUP_URL || ''

const RequestDepositVENICN: React.FC = () => {
  const history = useHistory()
  const intl = useIntl()
  const [point, setPoint] = useState(0)
  const [bonusChecked, setBonusChecked] = useState(-1)
  const [confirmedPassword, setConfirmedPassword] = useState('12341234') // set password
  const [bankState, setBankState] = useState<Schema$BankState | null>(null)
  const [bankInfo, setBankInfo] = useState<Schema$MyBankInfo | null>(null)
  const { globalStore, userStore } = useRootStore()
  const { userInfo } = userStore

  const krw = point * 1000

  let canDeposit = false
  let txtDepositInfo = null
  let canBonus = false

  if (bankState) {
    canDeposit = bankState.can_deposit
    txtDepositInfo = bankState.deposit_venicn.deposit_text

    canBonus = bankState.is_bonus_active
    if (!canBonus && bonusChecked !== 0) {
      setBonusChecked(0)
    }
  }

  const fetchBankState = async (params: IBankState.Params) => {
    try {
      const { state } = await apiUser.getBankState(params)
      setBankState(state)
    } catch (err) {}
  }

  const fetchBankInfo = async (params: IMyBankInfo.Params) => {
    try {
      const { info } = await apiUser.getBankInfo(params)
      const sliceLength = info.accountNumber.length / 4
      const hideStart = sliceLength
      const hideEnd = info.accountNumber.length - sliceLength - 1
      const newAccountNumber = []
      for (let i = 0; i < info.accountNumber.length; i++) {
        if (hideStart <= i && i <= hideEnd) {
          newAccountNumber.push('*')
        } else {
          newAccountNumber.push(info.accountNumber[i])
        }
      }
      info.accountNumber = newAccountNumber.join('')
      setBankInfo(info)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  useEffect(() => {
    fetchBankState({})
    fetchBankInfo({})
    fetchPoint()
  }, [confirmedPassword])

  const submitTransferPassword = async (values: any) => {
    const { transferPassword } = values
    try {
      const { password2 } = await apiUser.confirmTransferPassword({
        password2: transferPassword,
      })
      setConfirmedPassword(password2)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchPoint = async () => {
    try {
      const { point } = await apiUser.getVenicnInfo({})
      setPoint(point)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const fetchBuyPoint = async () => {
    try {
      const { userid, password, siteid, apikey, error, message } = {
        userid: userInfo.id,
        password: '',
        siteid: SITE_ID,
        apikey: API_KEY,
        error: null,
        message: null,
      }

      if (error) {
        globalStore.pushDialogOk({
          text: message || error,
        })
      } else {
        const url = API_BUY_URL
        var form = document.createElement('form')
        form.setAttribute('method', 'post')
        form.setAttribute('action', url)
        form.setAttribute('target', 'popup_id')

        var input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'id'
        input.value = userid
        form.appendChild(input)

        input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'site'
        input.value = siteid
        form.appendChild(input)

        input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'apikey'
        input.value = apikey
        form.appendChild(input)

        document.body.appendChild(form)

        const w = 600
        const h = (window.screen.height * 80) / 100

        popupCenter({ url, title: 'popup_id', w, h })

        form.submit()

        document.body.removeChild(form)
      }
    } catch (err) {
      globalStore.pushDialogOk({
        title: intl.formatMessage({ id: 'error' }),
        text: err.message,
      })
    }
  }

  const fetchDepositPoint = async () => {
    try {
      if (!canDeposit) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.cannot.request.recharge' }),
        })
        return
      }

      if (bonusChecked === -1) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.check.recharge_bonus' }),
        })
        return
      }

      await apiUser.depositVenicn({
        point: point,
        getBonus: bonusChecked === 1,
      })
      history.push(BANK_MENU.sub.history.url)
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.req-deposit-done' }),
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const onClickChargePoint = () => {
    fetchBuyPoint()
  }

  const onClickLookupPoint = () => {
    fetchPoint()
  }

  const onClickTransferPoint = () => {
    fetchDepositPoint()
  }

  const handleChangeBonus = (v: number) => {
    setBonusChecked(v)
  }

  const min_amount = bankState ? bankState.transaction.min_deposit_amount : 10000
  const min_unit = bankState ? bankState.transaction.min_deposit_unit : 1000
  const transaction_text = `※ 충전은 ${numberWithCommas(
    min_amount,
  )}포인트 이상부터 ${numberWithCommas(min_unit)}포인트 단위로 신청 가능`

  let contents = null
  if (confirmedPassword) {
    let bankName = null
    let accountNumber = null
    let accountHolder = null
    if (bankInfo) {
      const found = _.find(BANK_CODES, o => {
        return o.code === bankInfo.bankCode
      })
      if (found) {
        bankName = found.name
      }
      accountNumber = bankInfo.accountNumber
      accountHolder = bankInfo.accountHolder
    }

    contents = (
      <>
        <BankStyle sx={{ py: 3 }}>
          <form name="popForm">
            <input type="hidden" name="userid" value="test01" />
            <input type="hidden" name="password" value="14321432" />
            <input type="hidden" name="siteid" value="SITEID" />
            <input type="hidden" name="apikey" value="APIKEY" />
          </form>
          <DepositCoinStyle>
            <Typography component="div">※ 가상계좌충전 충전 안내</Typography>
            <NeatTextField
              multiline
              defaultValue={txtDepositInfo}
              InputProps={{ readOnly: true }}
            ></NeatTextField>
          </DepositCoinStyle>
        </BankStyle>

        <Grid display="flex" alignItems="center" justifyContent="center" sx={{ mt: 1.5 }}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <FormStyle>
                <Grid container sx={{ mb: 1, alignItems: 'center' }}>
                  <Grid item xs={3}>
                    <label className="form_label">
                      {intl.formatMessage({ id: 'component.bank.withdraw-bank' })}
                    </label>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      className="form_control readonly"
                      sx={{ fontSize: '1.3rem', textAlign: 'end', height: '46.86px' }}
                    >
                      {bankName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ mb: 1, alignItems: 'center' }}>
                  <Grid item xs={3}>
                    <label className="form_label">
                      {intl.formatMessage({ id: 'component.bank.withdraw-account' })}
                    </label>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      className="form_control readonly"
                      sx={{ fontSize: '1.3rem', textAlign: 'end', height: '46.86px' }}
                    >
                      {accountNumber}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ alignItems: 'center' }}>
                  <Grid item xs={3}>
                    <label className="form_label">
                      {intl.formatMessage({ id: 'component.bank.withdraw-account-holder' })}
                    </label>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      className="form_control readonly"
                      sx={{ fontSize: '1.3rem', textAlign: 'end', height: '46.86px' }}
                    >
                      {accountHolder}
                    </Typography>
                  </Grid>
                </Grid>
              </FormStyle>
            </Grid>

            <Grid item xs={12}>
              <FormStyle>
                <Grid container columnSpacing={1}>
                  <Grid item xs={12}>
                    <BasicButton
                      onClick={() => onClickChargePoint()}
                      className="btn_sub"
                      sx={{ display: 'block', margin: 'auto' }}
                    >
                      충전
                    </BasicButton>
                  </Grid>
                </Grid>
              </FormStyle>
            </Grid>

            <Grid item xs={12}>
              <FormStyle>
                <Grid container columnSpacing={1}>
                  <Grid item xs={8}>
                    <Typography
                      className="form_control readonly"
                      sx={{ textAlign: 'end', height: '100%' }}
                    >
                      {numberWithCommas(point)} 원
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <BasicButton
                      onClick={() => onClickLookupPoint()}
                      className="btn_outline_main"
                      sx={{ width: '100%', height: '100%' }}
                    >
                      충전금액 조회
                    </BasicButton>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={8}>
                    <Typography
                      className="form_control readonly"
                      sx={{ textAlign: 'end', height: '100%' }}
                    >
                      {numberWithCommas(krw)} 원
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <BasicButton
                      onClick={() => onClickTransferPoint()}
                      className="btn_main"
                      sx={{ width: '100%' }}
                    >
                      신청하기
                    </BasicButton>
                  </Grid>
                </Grid>
                {canBonus ? (
                  <Grid container sx={{ mt: 2, alignItems: 'center', justifyContent: 'center' }}>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bonusChecked === 1}
                              onChange={() => handleChangeBonus(1)}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                            />
                          }
                          sx={{ color: '#D1D9E3', justifyContent: 'center' }}
                          label="첫충(매충) 보너스 받기"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={bonusChecked === 0}
                              onChange={() => handleChangeBonus(0)}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 28, color: '#D1D9E3' } }}
                            />
                          }
                          sx={{ color: '#D1D9E3', justifyContent: 'center' }}
                          label="첫충(매충) 보너스 받지 않기"
                        />
                      </FormGroup>
                    </Grid>
                  </Grid>
                ) : null}
              </FormStyle>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  } else {
    contents = (
      <Grid container sx={{ py: 3, justifyContent: 'center' }}>
        <Grid item xs={7}>
          <Formik
            initialValues={{
              transferPassword: '',
            }}
            onSubmit={submitTransferPassword}
            validationSchema={Yup.object().shape({
              transferPassword: Yup.string().required(
                intl.formatMessage({ id: 'msg.field-require' }),
              ),
            })}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} autoComplete="off">
                <FormStyle>
                  <Grid container sx={{ mb: 2, alignItems: 'center' }}>
                    <Grid item xs={3}>
                      <label className="form_label required">
                        {intl.formatMessage({ id: 'component.bank.trasnfer-password' })}
                      </label>
                    </Grid>
                    <Grid item xs={9}>
                      <input
                        name="transferPassword"
                        className="form_control"
                        type="password"
                        style={{ fontSize: '1.3rem' }}
                        value={values.transferPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <DepositCoinStyle>
                    <Box sx={{ color: '#FE3D3D' }}>
                      환전 비밀번호 확인 후 충전신청을 할 수 있습니다.
                    </Box>
                  </DepositCoinStyle>
                </FormStyle>

                <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 4 }}>
                  <BasicButton className="btn_main" type="submit" disabled={isSubmitting}>
                    {intl.formatMessage({ id: 'ok' })}
                  </BasicButton>
                </Stack>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container sx={{ py: 2, justifyContent: 'center' }}>
      <Grid item xs={10} sx={{ pb: 4 }}>
        {contents}
      </Grid>
    </Grid>
  )
}

export default observer(RequestDepositVENICN)
