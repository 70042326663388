/** @jsxImportSource @emotion/react */
import { jsx, css, Global, Theme } from '@emotion/react'
import React, { useEffect, useState, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { LOCALSTORAGE_KEYS, MEDIA_DESKTOP_WIDTH } from '@constants/index'
import DefaultHome from './DefaultHome'
import { default as MobileHome } from './m'
import PopupBanner from '@views/Popup/PopupBanner'
import { Schema$PopupItem } from '~/v2/interface/common'
import apiPublic from '@services/api/public'
import { PopupStorage } from '~/store/globalStore'
import { isDateSame } from '~/utils/utils'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import asset_channel from '@assets/channel'

//css
import { PopupWrapStyle } from './index.style'

const Home: React.FC<{}> = props => {
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { authStore, globalStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { media_mode, option } = globalStore // watch media_mode to get event
  const { popupStyle } = option
  const { desktop } = globalStore.decideMedia(isDesktop)
  const [notices, setNotices] = useState<Schema$PopupItem[]>([])
  const [popup, setPopup] = useState<React.ReactNode>(null)
  const [backdrop, setBackdrop] = useState(false)
  const closeIds = useRef<number[]>([])

  const { signedin, initialized } = authStore

  let globalWidth = {}
  let globalBackground = {}
  if (desktop) {
    globalWidth = {
      minWidth: '1248px',
    }
    globalBackground = {
      background: `#060503 url(${asset_channel.common.bg_subbg}) 0 98px no-repeat`,
      backgroundSize: 'contain',
    }
  } else {
    globalBackground = {
      background: `#060503 url(${asset_channel.common.bg_subbg_m}) center 48px no-repeat`,
      backgroundSize: '100%',
    }
  }

  const GlobalStyle = (theme: Theme) => css`
    html, body {
      ${globalWidth}
    }
    body {
      ${globalBackground}
    }
    #root {
      position: relative
    }
  `

  const onClose = (id: number, nomore: boolean) => {
    const popup = localStorage.getItem(LOCALSTORAGE_KEYS.POPUP_NOTICE)
    const casted = JSON.parse(popup) as PopupStorage
    const { date, ids } = casted || {}
    if (nomore) {
      if (date && ids) {
        const oldDate = new Date(date)
        const now = new Date()
        if (isDateSame(oldDate, now)) {
          const temp = ids as number[]
          if (temp.indexOf(id) < 0) {
            temp.push(id)

            localStorage.setItem(
              LOCALSTORAGE_KEYS.POPUP_NOTICE,
              JSON.stringify({
                date: now,
                ids: temp,
              }),
            )
          }
        } else {
          localStorage.setItem(
            LOCALSTORAGE_KEYS.POPUP_NOTICE,
            JSON.stringify({
              date: new Date(),
              ids: [id],
            }),
          )
        }
      } else {
        //
        localStorage.setItem(
          LOCALSTORAGE_KEYS.POPUP_NOTICE,
          JSON.stringify({
            date: new Date(),
            ids: [id],
          }),
        )
      }
    }

    if (closeIds.current.indexOf(Number(id)) < 0) {
      closeIds.current.push(Number(id))
    }
    updatePopup()
  }

  const updatePopup = async () => {
    try {
      let noticeIds = []
      for (const el of notices) {
        noticeIds.push(el.id)
      }
      let popupIds = [...noticeIds]

      const popup = localStorage.getItem(LOCALSTORAGE_KEYS.POPUP_NOTICE)
      const casted = JSON.parse(popup) as PopupStorage
      const { date, ids } = casted || {}
      if (date && ids) {
        const oldDate = new Date(date)
        const now = new Date()
        if (isDateSame(oldDate, now)) {
          for (const el of ids) {
            popupIds = _.filter(popupIds, o => {
              return o !== el
            })
          }
        }
      }

      for (const el of closeIds.current) {
        popupIds = _.filter(popupIds, o => {
          return o !== el
        })
      }

      const contents = []
      for (const [index, el] of popupIds.entries()) {
        const found = _.find(notices, o => {
          return o.id === el
        })
        const { data } = found || {}
        if (data) {
          const { popup_top, popup_left, popup_width, popup_height, popup_type, popup_image } = data

          contents.push(
            <PopupBanner
              id={found.id}
              title={found.title}
              content={found.content}
              width={Number(popup_width)}
              height={Number(popup_height)}
              x={0}
              y={210}
              popupStyle={Number(popupStyle)}
              popupType={popup_type}
              popupImage={popup_image}
              onClose={onClose}
            ></PopupBanner>,
          )
        }
      }

      if (contents.length > 0) {
        setBackdrop(true)
      } else {
        setBackdrop(false)
      }
      setPopup(contents)
    } catch (err) {
      // 리스트를 비운다
    }
  }

  const fetchNotices = async () => {
    try {
      closeIds.current = []

      const { items } = await apiPublic.getPopups({})
      setNotices([...items])
    } catch (err) { }
  }

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('HOME')
    }
  }, [signedin, initialized, serviceState])

  useEffect(() => {
    if (signedin || initialized) {
      fetchNotices()
    }
  }, [signedin, initialized])

  useEffect(() => {
    updatePopup()
  }, [notices])

  let contents = null
  if (desktop) {
    contents = <DefaultHome />
  } else {
    contents = <MobileHome />
  }

  return (
    <>
      <Global styles={GlobalStyle} />
      {contents}
      {backdrop ? (
        <PopupWrapStyle>
          {popup}
        </PopupWrapStyle>
      ) : null}
    </>
  )
}

export default observer(Home)
