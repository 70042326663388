/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx, css, Theme } from '@emotion/react'
import React, { Fragment, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Box, Grid, Chip, IconButton, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import * as _ from 'lodash'
import { FormattedMessage, useIntl, IntlShape } from 'react-intl'
import moment from 'moment'

import useRootStore from '@store/useRootStore'
import { numberWithCommas } from '@utils/utils'
import apiUser from '@services/api/user'
import { IBankHistory } from '~/v2/protocol/bank'

// css
import { NoList } from '@styles/modal.style'
import { PaginationStyle } from '~/styles/base.style'

//image
import { CasinoComing } from '~/assets/images/layout_1'
import { BONUS_KIND } from '~/v2/interface/config'

//icon
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'

const MobileBoardStyle = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  marginBottom: '16px',
  backgroundColor: theme.boards.contentBg,
  borderRadius: '4px',
  color: theme.colors.listTitle,

  '& > .MuiGrid-container': {
    padding: '16px',
    alignItems: 'center',
  },

  '& .MuiChip-root': {
    minWidth: '64px',
    height: '20px',
    color: '#FFF',
  },
  '& .info_table .MuiBox-root': {
    display: 'flex',
    textAlign: 'center',
  },
  '& table': {
    width: '100%',
    borderCollapse: 'collapse',
    textAlign: 'center',
  },
  '& .title th': {
    color: '#747D8A',
    fontWeight: 'normal',
    fontSize: '0.9rem',
  },
  '& .date': {
    color: '#747D8A',
  },
  '& .state': {
    color: '#FFF',
  },
}))

interface PageData {
  currPage: number
  totalPage: number
}

type BankHistoryTrackData = IBankHistory.Schema

const getPageData = (track: BankHistoryTrackData): PageData => {
  let currPage = 0
  let totalPage = 0
  if (track.total > 0) {
    currPage = track.offset / track.limit + 1
    totalPage = Math.floor(track.total / track.limit)
    if (track.total % track.limit > 0) {
      totalPage++
    }
  }
  return { currPage, totalPage }
}

const ItemPerPage = 10

const RequestDeposit: React.FC = () => {
  const intl = useIntl()
  const [track, setTrack] = useState(null)
  const { globalStore } = useRootStore()

  useEffect(() => {
    fetch({ offset: 0, limit: ItemPerPage })
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [track])

  const fetch = async (params: any) => {
    try {
      const { offset, limit, total, items } = await apiUser.bankHistory(params)
      const data: BankHistoryTrackData = {
        offset,
        limit,
        total,
        items,
      }
      setTrack(data)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    return null
  }

  const handlePagingChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const offset = (value - 1) * ItemPerPage
    fetch({ offset, limit: ItemPerPage })
  }

  const onDeleteRecord = (_id: number) => {
    async function testDeleteRecord(id: number) {
      try {
        await apiUser.deleteBankHistoryItem({ id: id })
        fetch({ offset: 0, limit: ItemPerPage })
      } catch (err) {
        globalStore.pushErrorObject(err, intl)
      }
    }
    testDeleteRecord(_id)
  }

  const listItems = []

  let currTrackPage = 0
  let totalTrackPage = 0

  if (track) {
    const ret = getPageData(track)
    currTrackPage = ret.currPage
    totalTrackPage = ret.totalPage

    for (const el of track.items) {
      let txtKind = '-'
      switch (el.kind) {
        case 'IN':
          txtKind = intl.formatMessage({ id: 'component.bank.deposit' })
          break
        case 'OUT':
          txtKind = intl.formatMessage({ id: 'component.bank.withdraw' })
          break
      }

      const dateFormatted = moment(el.date).format('YYYY-MM-DD HH:mm')
      const txtVolume = numberWithCommas(el.volume)
      let txtBonus = null
      let txtBonusName = null
      if (el.bonus > 0) {
        txtBonus = `${numberWithCommas(el.bonus)} P`
        if (el.bonus_kind !== 'NONE') {
          const objBonusKind = _.find(BONUS_KIND, o => {
            return o.id === el.bonus_kind
          })
          txtBonusName = objBonusKind && objBonusKind.name
        }
      }
      let canDeleteRecord = false
      let txtState = <div></div>
      switch (el.state) {
        case 'REQUEST':
          const requestName =
            el.kind === 'IN'
              ? intl.formatMessage({ id: 'bank.progress.REQUEST_IN' })
              : intl.formatMessage({ id: 'bank.progress.REQUEST_OUT' })
          txtState = <Chip label={requestName} sx={{ backgroundColor: '#FBC342' }} />
          break
        case 'PROCESS':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.PROCESS' })}
              sx={{ backgroundColor: '#3B4C6E' }}
            />
          )
          break
        case 'SUCCESS':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.SUCCESS' })}
              sx={{ backgroundColor: '#2366CB' }}
            />
          )
          canDeleteRecord = true
          break
        case 'CANCEL':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.CANCEL' })}
              sx={{ backgroundColor: '#FE3D3D' }}
            />
          )
          canDeleteRecord = true
          break
        case 'FAIL':
          txtState = (
            <Chip
              label={intl.formatMessage({ id: 'bank.progress.FAIL' })}
              sx={{ backgroundColor: '#FE3D3D' }}
            />
          )
          canDeleteRecord = true
          break
      }
      let eDeleteRecord = null
      if (canDeleteRecord) {
        eDeleteRecord = (
          <Tooltip title="내역을 삭제합니다.">
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              onClick={() => onDeleteRecord(el.id)}
              aria-label="close"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        )
      }
      listItems.push(
        <MobileBoardStyle key={el.id}>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
            >
              {txtKind}
              <span className="date">{dateFormatted}</span>
            </Grid>
            {txtBonus || txtBonusName ? (
              <Grid item xs={12}>
                <table>
                  <thead>
                    <tr className="title">
                      <th style={{ textAlign: 'left' }}>
                        {intl.formatMessage({ id: 'table.bonus' })}
                      </th>
                      <th style={{ width: '113px' }}>
                        {intl.formatMessage({ id: 'table.bonus_type' })}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ textAlign: 'left' }}>{txtBonus}</td>
                      <td>{txtBonusName}</td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <table>
                <thead>
                  <tr className="title">
                    <th style={{ textAlign: 'left' }}>
                      {intl.formatMessage({ id: 'table.req-volume' })}
                    </th>
                    <th style={{ width: '113px' }}>
                      {intl.formatMessage({ id: 'table.progress' })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'left' }}>
                      {txtVolume}&nbsp;{intl.formatMessage({ id: 'money-locale' })}
                    </td>
                    <td className="state">
                      {txtState}
                      {eDeleteRecord}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
        </MobileBoardStyle>,
      )
    }
  }

  let emptyContents = null
  if (listItems.length === 0) {
    emptyContents = (
      <NoList>
        <img src={CasinoComing} alt="coming-soon" style={{ width: '100%' }} />
        <p>{intl.formatMessage({ id: 'msg.bank.no-history' })}</p>
      </NoList>
    )
  }

  return (
    <Fragment>
      <Box sx={{ px: 3, mt: 3 }}>
        <Box>{listItems}</Box>
        {emptyContents}
      </Box>

      <PaginationStyle
        count={totalTrackPage}
        variant="outlined"
        page={currTrackPage}
        onChange={handlePagingChange}
      />
    </Fragment>
  )
}

export default observer(RequestDeposit)
