import * as _ from 'lodash'
import { v4 } from 'uuid'
import ChipButton from '../components/chip-button'
import { getChipButtonData } from '../components/chip-data'
import FixedChip from '../components/fixed-chip'
import OddsButton from '../components/odds-button'
import { OddsPosition } from '../components/types'
import BetButton from '../premitives/bet-button'
import Button from '../premitives/button'
import TextButton from '../premitives/text-button'
import { BetAction, BetData, BetDoneData, FLY_DURATION, GameInfo } from '../scenes/panel'
import MobileInfoComponent from './m-info-component'
import MobileOddsComponent from './m-odds-component'
import MobileChipComponent from './m-chip-component'
import EventManager from '../../helpers/event-manager'

const SceneName = 'm-panel'
export default class MobilePanelScene extends Phaser.Scene {
  infoComponent: MobileInfoComponent = null
  oddsComponent: MobileOddsComponent = null
  chipComponent: MobileChipComponent = null

  btnBet: BetButton = null

  btnEnlarge: Button = null
  btnRecover: Button = null

  container: Phaser.GameObjects.Container = null

  enabled = true

  betActions: BetAction[] = []

  fchips: FixedChip[] = []
  tweenList: Phaser.Tweens.Tween[] = []
  timeoutList: NodeJS.Timeout[] = []

  currentActionId = 0

  gameInfo: GameInfo = null
  betDoneData: BetDoneData = null

  // test buttons
  btnToggleEnabled: TextButton = null
  btnClear: TextButton = null

  constructor() {
    super({ key: SceneName })
  }

  public preload() {}

  public create() {
    console.log(`${SceneName} created!`)

    this.infoComponent = new MobileInfoComponent(
      this,
      245,
      24,
      this.onTimerEvent.bind(this),
      this.onTimerComplete.bind(this),
    )

    this.oddsComponent = new MobileOddsComponent(this, 35, 40, this.handleClickOddsButton.bind(this))

    this.chipComponent = new MobileChipComponent(
      this,
      145,
      396,
      this.handleChipSlelected.bind(this),
      this.handleClickChipButton.bind(this),
      this.handleCancelAll.bind(this),
      this.handleCancelLastBet.bind(this),
      this.handleDoubleBet.bind(this),
    )

    this.container = new Phaser.GameObjects.Container(this, 0, 0)
    this.add.existing(this.container)

    // add buttons
    // 벳
    this.btnBet = new BetButton(this, 418, 400, this.onClickBet.bind(this))

    const btnPosX = 30
    const btnPosY = 430

    // 확대
    this.btnEnlarge = new Button(
      this,
      btnPosX,
      btnPosY,
      { texture: 'ui', frame: 'btn2.png' },
      { texture: 'ui', frame: 'btn2_push.png' },
      { texture: 'ui', frame: 'btn2.png' },
      this.onClickEnlarge.bind(this),
    )

    // 축소
    this.btnRecover = new Button(
      this,
      btnPosX,
      btnPosY,
      { texture: 'ui', frame: 'btn3.png' },
      { texture: 'ui', frame: 'btn3_push.png' },
      { texture: 'ui', frame: 'btn3.png' },
      this.onClickRecover.bind(this),
    )

    this.btnEnlarge.setVisible(true)
    this.btnRecover.setVisible(false)

    // init
    this.chipComponent.selectChip(5000)

    this.btnBet.setEnabled(false)
  }

  update(time: number, deltaTime: number) {
    this.infoComponent.update(time, deltaTime)
  }

  updateGameInfo(gameInfo: GameInfo) {
    this.gameInfo = gameInfo
    this.validateBetDone()
  }

  betDone(betDoneData: BetDoneData) {
    this.betDoneData = betDoneData
    this.validateBetDone()
  }

  validateBetDone() {
    if (!this.gameInfo || !this.betDoneData) {
      return
    }
    if (this.gameInfo.tradingId !== this.betDoneData.tradingId) {
      this.clearBet()
      return
    }
    this.tweenEnabled(false)
  }

  onTimerEvent(timerId: string, eventId: string) {
    if (eventId === 'soon') {
      this.btnBet.setBloom(true)
    }
  }

  onTimerComplete(timerId: number) {
    // this.tweenEnabled(false)
  }

  addTestButtons() {
    const y = 430
    // Toggle
    this.btnToggleEnabled = new TextButton(this, 100, y, '활성/비활성', this.onClickToggleEnabled.bind(this))

    // Toggle
    this.btnClear = new TextButton(this, 170, y, '초기화', this.onClickClear.bind(this))
  }

  flyChip(fchip: FixedChip, from: OddsPosition, to: OddsPosition, duration: number) {
    this.fchips.push(fchip)
    this.container.add(fchip)
    fchip.setPosition(from.x, from.y)
    const tween = this.tweens.add({
      targets: fchip,
      x: to.x,
      y: to.y,
      duration,
      ease: Phaser.Math.Easing.Quadratic.Out,
      onComplete: () => {
        this.fchips = _.filter(this.fchips, (o: FixedChip) => {
          return o.instanceId !== fchip.instanceId
        })
        fchip.destroy()
      },
    })
    this.tweenList.push(tween)
  }

  flyToOdds(fchip: FixedChip, chip: ChipButton, btn: OddsButton) {
    const from: OddsPosition = {
      x: this.chipComponent.x + chip.x,
      y: this.chipComponent.y + chip.y,
    }
    const offsetY = 23
    const to: OddsPosition = {
      x: this.oddsComponent.x + btn.x,
      y: this.oddsComponent.y + btn.y + offsetY,
    }
    this.flyChip(fchip, from, to, FLY_DURATION)
  }

  flyToChip(fchip: FixedChip, btn: OddsButton, chip: ChipButton) {
    const offsetY = 23
    const from: OddsPosition = {
      x: this.oddsComponent.x + btn.x,
      y: this.oddsComponent.y + btn.y + offsetY,
    }
    const to: OddsPosition = {
      x: this.chipComponent.x + chip.x,
      y: this.chipComponent.y + chip.y,
    }
    this.flyChip(fchip, from, to, FLY_DURATION)
  }

  getOddsBetVolume(oddsId: string): number {
    const data: BetData[] = []
    for (const el of this.betActions) {
      const filtered = _.filter(el.data, (o: BetData) => {
        return o.oddsId === oddsId
      })
      data.push(...filtered)
    }
    let total = 0
    for (const el of data) {
      total += el.volume
    }
    return total
  }

  addBet(targetButton: OddsButton) {
    const selectedChip = this.chipComponent.getSelectedChip()
    if (!selectedChip) {
      return
    }
    if (this.betActions.length === 0) {
      this.btnBet.setEnabled(true)
    }
    const volume = this.chipComponent.selectedChipId
    const action: BetAction = {
      actionKey: v4(),
      data: [
        {
          marketId: targetButton.attributes.marketId,
          oddsId: targetButton.attributes.id,
          oddsRate: targetButton.attributes.rate,
          volume,
        },
      ],
    }
    this.betActions.push(action)
    const fchip = new FixedChip(this, 0, 0, selectedChip.attributes)
    this.flyToOdds(fchip, selectedChip, targetButton)
    const oddsVolume = this.getOddsBetVolume(targetButton.attributes.id)
    const timeout = setTimeout(() => {
      targetButton.updateBet(oddsVolume)
    }, FLY_DURATION)
    this.timeoutList.push(timeout)
  }

  cancelLastBet() {
    const lastActions = this.betActions.pop()
    if (!lastActions) {
      return
    }
    for (const el of lastActions.data) {
      const chipButtonData = getChipButtonData(el.volume)
      if (!chipButtonData) {
        return
      }
      const oddsButton = this.oddsComponent.getOddsButton(el.oddsId)
      const chipButton = this.chipComponent.getChipButtonByType(chipButtonData.type)
      if (!oddsButton || !chipButton) {
        return
      }
      const fchip = new FixedChip(this, 0, 0, chipButton.attributes)
      this.flyToChip(fchip, oddsButton, this.chipComponent.getSelectedChip())
      const oddsVolume = this.getOddsBetVolume(oddsButton.attributes.id)
      oddsButton.updateBet(oddsVolume)
    }
    if (this.betActions.length === 0) {
      this.btnBet.setEnabled(false)
    }
  }

  doubleBet() {
    const data: BetData[] = []
    for (const el of this.betActions) {
      data.push(...el.data)
    }
    const betData: BetData[] = []
    const grouped = _.groupBy(data, 'oddsId')
    for (const [oddsId, v] of Object.entries(grouped)) {
      const [top] = v as BetData[]
      const oddsVolume = this.getOddsBetVolume(oddsId)
      const chipButtonData = getChipButtonData(oddsVolume)
      if (!chipButtonData) {
        continue
      }
      const chipButton = this.chipComponent.getChipButtonByType(chipButtonData.type)
      const oddsButton = this.oddsComponent.getOddsButton(oddsId)
      if (!chipButton || !oddsButton) {
        continue
      }
      betData.push({
        marketId: top.marketId,
        oddsId,
        oddsRate: top.oddsRate,
        volume: oddsVolume,
      })
      const newOddsVolume = oddsVolume * 2
      const fchip = new FixedChip(this, 0, 0, chipButtonData)
      this.flyToOdds(fchip, chipButton, oddsButton)
      const timeout = setTimeout(() => {
        oddsButton.updateBet(newOddsVolume)
      }, FLY_DURATION)
      this.timeoutList.push(timeout)
    }
    if (betData.length > 0) {
      const action = { actionKey: v4(), data: betData }
      this.betActions.push(action)
    }
  }

  clearBet() {
    this.betActions = []
    this.oddsComponent.clear()
    for (const el of this.tweenList) {
      el.destroy()
    }
    for (const el of this.timeoutList) {
      clearTimeout(el)
    }
    for (const el of this.fchips) {
      if (el) {
        el.destroy()
      }
    }
    this.fchips = []
    this.tweenList = []
    this.timeoutList = []
    this.betDoneData = null
  }

  clear() {
    this.clearBet()
    this.btnBet.setEnabled(false)
  }

  checkBetValid() {
    const isBetValid = !this.gameInfo || !this.betDoneData || this.gameInfo.tradingId !== this.betDoneData.tradingId
    if (isBetValid) {
      this.clearBet()
    }
  }

  setEnabled(enabled: boolean) {
    this.enabled = enabled
    this.oddsComponent.setEnabled(this.enabled)
    this.chipComponent.setEnabled(this.enabled)
    this.btnBet.setVisible(this.enabled)
    this.checkBetValid()

    const y = this.enabled ? 400 : 430
    this.btnEnlarge.y = y
    this.btnRecover.y = y
  }

  tweenEnabled(enabled: boolean) {
    this.enabled = enabled
    this.oddsComponent.tweenEnabled(this.enabled)
    const timeout = enabled ? 150 : 0
    setTimeout(() => {
      this.chipComponent.setEnabled(this.enabled)
      this.btnBet.setVisible(this.enabled)

      const y = this.enabled ? 400 : 430
      this.btnEnlarge.y = y
      this.btnRecover.y = y
    }, timeout)
    this.checkBetValid()
  }

  onClickBet() {
    const action: BetAction = {
      actionKey: v4(),
      data: [],
    }
    const betDataList: BetData[] = []
    for (const el of this.betActions) {
      betDataList.push(...el.data)
    }
    const oddsGroup = _.groupBy(betDataList, 'oddsId')
    for (const [oddsId, v] of Object.entries(oddsGroup)) {
      const bdList = v as BetData[]
      const [top] = bdList
      const marketId = top.marketId
      const oddsRate = top.oddsRate
      let volume = 0
      for (const el of bdList) {
        volume += el.volume
      }
      action.data.push({
        marketId,
        oddsId,
        oddsRate,
        volume,
      })
    }
    EventManager.getInstance().emit('bet', action)
  }

  onClickEnlarge() {
    this.btnEnlarge.setVisible(false)
    this.btnRecover.setVisible(true)
    this.oddsComponent.enlarge()
  }

  onClickRecover() {
    this.btnEnlarge.setVisible(true)
    this.btnRecover.setVisible(false)
    this.oddsComponent.recover()
  }

  // chip component handlers
  handleChipSlelected(id: number) {
    //
  }

  handleClickChipButton(id: number) {
    //
  }

  handleClickOddsButton(btn: OddsButton) {
    this.addBet(btn)
  }

  handleDoubleBet() {
    this.doubleBet()
  }

  handleCancelAll() {
    this.clear()
  }

  handleCancelLastBet() {
    this.cancelLastBet()
  }

  // test button handler
  onClickToggleEnabled() {
    this.tweenEnabled(!this.enabled)
  }

  onClickClear() {
    this.clear()
  }
}
