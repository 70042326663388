import http from '../http'
import { ISignup, ISignin, ILogout, IPostPhoneCertifyCode, IConfirmPhoneCertifyCode } from '@protocol/auth'

const defs = {
  signup(data: ISignup.Params): Promise<ISignup.Schema> {
    return http.post('api/auth/signup', data || {})
  },

  login(data: ISignin.Params): Promise<ISignin.Schema> {
    return http.post('api/auth/signin', data || {})
  },

  logout(data: ILogout.Params): Promise<ILogout.Schema> {
    return http.get('api/auth/logout', data || {})
  },

  postPhoneCertifyCode(data: IPostPhoneCertifyCode.Params): Promise<IPostPhoneCertifyCode.Schema> {
    return http.post('api/auth/post-phone-certify-code', data || {})
  },

  phoneCertifyCode(data: IConfirmPhoneCertifyCode.Params): Promise<IConfirmPhoneCertifyCode.Schema> {
    return http.post('api/auth/confirm-phone-certify-code', data || {})
  },
}

export default defs
