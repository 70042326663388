/** @jsxImportSource @emotion/react */
/* eslint-disable jsx-a11y/iframe-has-title */
// eslint-disable-next-line
import { jsx, css } from '@emotion/react'
import React, { Fragment, useState } from 'react'
import { observer } from 'mobx-react'
import { Card, Grid, Box } from '@mui/material'
import * as _ from 'lodash'

import useRootStore from '@store/useRootStore'
import { MarketMatrixData, MakeTestMatchupMatrix } from '~/game/common'
import { SQUIDGAME_MARKET_GROUP, MarketMatrix } from '~/game/squidgame'
import { BettingCartItem } from '~/store/minigameStore'
import { useOnMount } from '~/utils/reactExt'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'

// css
import { BasicButton } from '~/styles/button.style'
import { SelectBox, ScrollableStyle } from './index.style'

// const loadableText = 'N/A'
const loadableText = '. . .'

const makeMarketBlock = (
  tradingKind: string,
  marketGroup: number,
  srcMatrix: MarketMatrixData[][],
  selectedId: string,
  onClick: (tradingKind: string, marketGroup: number, marketId: string, oddsId: string) => void,
  colType: string,
) => {
  const contents = []
  for (const row of srcMatrix) {
    for (const col of row) {
      const innerItems = []
      const disabled = selectedId === '0' || !col.odds
      //const color = selectedId === '1' || selectedId === col.id ? col.acolor : col.dcolor
      const active = selectedId === col.id ? 'selected' : ''
      let bgColor = col.dcolor
      if (selectedId === col.id) {
        bgColor = col.acolor
      }
      let oddsName = <div></div>
      if (col.odds) {
        if (col.odds.disabled) {
          continue
        }
        if (col.marketId === 'PABCD') {
          const [section, tmp] = col.odds.locale.name.split('-')
          const numbers = tmp.split(',')
          let balls = []
          for (const el of numbers) {
            //const num = Number(el)
            balls.push(<span>{el}</span>)
          }
          oddsName = (
            <div className="ball">
              {section} - {balls}
            </div>
          )
        } else if (col.marketId === 'PNUMBER') {
          const num = Number(col.odds.locale.name)
          oddsName = (
            <div className="ball">
              <span>{num}</span>
            </div>
          )
        } else {
          oddsName = <div>{col.odds ? col.odds.locale.name : loadableText}</div>
        }
      }

      innerItems.push(
        <BasicButton
          key={col.id}
          className={'btn_bet ' + active}
          disabled={disabled}
          onClick={() => onClick(tradingKind, marketGroup, col.marketId, col.id)}
          style={{ backgroundColor: bgColor }}
        >
          {oddsName}
          <div>{col.odds ? col.odds.rate : loadableText}</div>
        </BasicButton>,
      )
      if (colType === 'type1') {
        contents.push(
          <Grid item xs={6} key={col.id}>
            {innerItems}
          </Grid>,
        )
      } else if (colType === 'type2') {
        contents.push(
          <Grid item xs={6} key={col.id}>
            {innerItems}
          </Grid>,
        )
      } else if (colType === 'type3') {
        contents.push(
          <Grid item xs={6} key={col.id}>
            {innerItems}
          </Grid>,
        )
      }
    }
  }
  return contents
}

const getSelectedId = (items: BettingCartItem[], marketGroup: number) => {
  const powerItem = _.find(items, function(o) {
    return o.marketGroup === marketGroup
  })
  return powerItem ? powerItem.oddsId : '1'
}

interface Props {
  kind: string
  width?: number
}

const MarketPanelSquidgame: React.FC<Props> = ({ kind, width }) => {
  // '0': disabled, '1': interfactable, 'xxxx': selected
  const { miniGameStore } = useRootStore()
  const [mounted, setMounted] = useState<boolean>(false)
  const { matchup, cartItems } = miniGameStore

  useOnMount(() => {
    miniGameStore.setGameTab(kind)
    setTimeout(() => {
      setMounted(true)
    }, 150)
  })

  let selected = getSelectedId(cartItems, SQUIDGAME_MARKET_GROUP.ALL)

  const matchupMatrix = MakeTestMatchupMatrix(MarketMatrix, matchup)

  const directionContents = makeMarketBlock(
    kind,
    SQUIDGAME_MARKET_GROUP.ALL,
    matchupMatrix['SG']['DIRECTION'],
    selected,
    miniGameStore.toggleCartItem,
    'type3',
  )
  const masterContents = makeMarketBlock(
    kind,
    SQUIDGAME_MARKET_GROUP.ALL,
    matchupMatrix['SG']['MASTER'],
    selected,
    miniGameStore.toggleCartItem,
    'type1',
  )
  const survivorContents = makeMarketBlock(
    kind,
    SQUIDGAME_MARKET_GROUP.ALL,
    matchupMatrix['SG']['SURVIVOR'],
    selected,
    miniGameStore.toggleCartItem,
    'type1',
  )

  let url = 'https://dr-score.com/minigame/broadcast/squidball'
  let urlResult = 'https://dr-score.com/minigame/date/squidball'
  switch (miniGameStore.getGameTab) {
    case 'SG1M':
      url = `${url}1`
      urlResult = `${urlResult}1`
      break
    case 'SG2M':
      url = `${url}2`
      urlResult = `${urlResult}2`
      break
    case 'SG3M':
      // default
      break
    case 'SG5M':
      url = `${url}5`
      urlResult = `${urlResult}5`
      break
  }

  const onClickResult = () => {
    window.open(urlResult, '_blank')
  }

  const origin = { w: 874, h: 609 }
  const sclX = (width || window.innerWidth) / origin.w
  const height = origin.h * sclX

  return (
    <Fragment>
      <ScrollableStyle style={{ height: `${height}px` }}>
        {mounted && (
          <iframe
            scrolling="no"
            src={url}
            className="game"
            style={{
              transform: `scale(${sclX})`,
              transformOrigin: '0 0',
              width: `${origin.w}px`,
              height: `${origin.h}px`,
            }}
          ></iframe>
        )}
      </ScrollableStyle>
      <Card css={SelectBox}>
        <Box className="card_header" sx={{ textAlign: 'end' }}>
          <BasicButton
            className="btn_sub"
            sx={{ display: 'inline-block', padding: '0.4rem 0.55rem', minWidth: 120 }}
            onClick={() => onClickResult()}
          >
            지난 회차 보기
          </BasicButton>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2 }}>
        <Box className="card_header">좌우패턴</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {directionContents}
          </Grid>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2 }}>
        <Box className="card_header">마스터맨 기준</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {masterContents}
          </Grid>
        </Box>
      </Card>
      <Card css={SelectBox} sx={{ mt: 4, px: 2, pb: 7 }}>
        <Box className="card_header">생존자 기준</Box>
        <Box className="card_body">
          <Grid container spacing={1}>
            {survivorContents}
          </Grid>
        </Box>
      </Card>
    </Fragment>
  )
}

export default observer(MarketPanelSquidgame)
