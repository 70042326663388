/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'

import { LoadSpinWrap, LoadSpin } from '@styles/base.style'

function PageLoading() {
  return (
    <LoadSpinWrap>
      <LoadSpin />
    </LoadSpinWrap>
  )
}

export default PageLoading
