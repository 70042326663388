/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import React, { useState } from 'react'
import * as _ from 'lodash'
import { ReactComponent as MypageMenu1 } from '@assets/images/layout_1/mypage_1.svg'
import { ReactComponent as MypageMenu2 } from '@assets/images/layout_1/mypage_2.svg'
import { ReactComponent as MypageMenu3 } from '@assets/images/layout_1/mypage_3.svg'
import { ReactComponent as MypageMenu4 } from '@assets/images/layout_1/mypage_4.svg'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { Stack, Box, Grid, Container, Tab, Tabs, Typography, Divider } from '@mui/material'

import { useIntl } from 'react-intl'
import useRootStore from '~/store/useRootStore'
//import WhiteTheme from '@themes/white.theme'

import QnaPersonal from './QnaPersonal/QnaPersonal'
import QnaPersonalWrite from './QnaPersonal/QnaPersonalWrite'
import Point from './Point'
import Comp from './Comp'
import MyInfo from './MyInfo'
import { useHistory } from 'react-router-dom'
import { MYPAGE_TAB_VALUES } from '@constants/attrs'
import { APP_CHARGES } from '~/utils/utils'

// icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

//css
import { BasicButton } from '@styles/button.style'
import { VerticalTabs, TitleDivider, DarkenBg } from '@styles/base.style'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface Props {
  main: string
  sub: string
  type: string
  kind: string
  location: any
}

const EXCLUDE_MENU = process.env.REACT_APP_EXCLUDE_MENU || []
const channelSymbol = process.env.REACT_APP_CHANNEL || ''

function DesktopMyPage(props: Props) {
  const intl = useIntl()
  const history = useHistory()
  const { authStore, globalStore, userStore } = useRootStore()
  const { initialized, signedin } = authStore
  const { option } = globalStore
  const { enableComp, enableCoupon, enableChangePassword } = option || {}
  const { userInfo } = userStore

  const { main, sub, location } = props
  const { id: tabValue, title, localeId } = MYPAGE_TAB_VALUES[(main as string) || 'point']

  const date = new Date()
  const curDate = `${(date.getMonth() + 1).toString()}/${date.getDate().toString()}`

  if (!signedin && initialized) {
    globalStore.showModal('login', 0)
    return <></>
  }

  const handleChange = (event, idx) => {
    for (const [key, value] of Object.entries(MYPAGE_TAB_VALUES)) {
      if (value.id === idx) {
        const { url } = value
        history.push(url)
        break
      }
    }
  }

  const onClickAskPersonal = (type: string) => {
    if (type === 'list') {
      history.push(MYPAGE_TAB_VALUES.qnaPersonal.sub.list.url)
    } else if (type === 'write') {
      history.push(MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url)
    } else if (type === 'deposit') {
      history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=deposit`)
    } else if (type === 'deposit.normal') {
      history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=deposit&kind=normal`)
    } else if (type === 'deposit.virtual') {
      history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=deposit&kind=virtual`)
    } else if (type === 'sports') {
      // only jaba
      history.push(`${MYPAGE_TAB_VALUES.qnaPersonal.sub.write.url}&type=sports`)
    }
  }

  const canDepositNormal = APP_CHARGES.indexOf('deposit') >= 0
  const canDepositMCI = APP_CHARGES.indexOf('depositMCI') >= 0

  const showQna = EXCLUDE_MENU.indexOf('QNA') < 0

  return (
    <DarkenBg>
      <Box className="content_wrap">
        <Container fixed sx={{ minWidth: '1248px' }}>
          <Grid container columnSpacing={2} sx={{ pt: 3 }}>
            {/* <Grid item xs={2.2}>
            <Typography variant="h5" component="h2" sx={{ py: 3, fontWeight: 'bold', color: '#D1D9E3' }}>
              {intl.formatMessage({ id: 'component.mypage' })}
            </Typography>
            <Tabs value={tabValue} orientation="vertical" onChange={handleChange} css={VerticalTabs}>
              <Tab
                icon={<ArrowForwardIosIcon />}
                iconPosition="end"
                className="vertical_tab"
                {...a11yProps(0)}
                label={
                  <div className="symbol">
                    <MypageMenu1 />
                    {intl.formatMessage({ id: 'component.point' })}
                  </div>
                }
              />
              <Tab
                hidden={!enableComp}
                icon={<ArrowForwardIosIcon />}
                iconPosition="end"
                className="vertical_tab"
                {...a11yProps(1)}
                label={
                  <div className="symbol">
                    <MypageMenu4 />
                    {intl.formatMessage({ id: 'component.comp' })}
                  </div>
                }
              />
              <Tab
                hidden={!enableChangePassword}
                icon={<ArrowForwardIosIcon />}
                iconPosition="end"
                className="vertical_tab"
                {...a11yProps(2)}
                label={
                  <div className="symbol">
                    <MypageMenu2 />
                    {intl.formatMessage({ id: 'component.modify-myinfo' })}
                  </div>
                }
              />
                {showQna && (
                  <>
                    <Tab
                      icon={<ArrowForwardIosIcon />}
                      iconPosition="end"
                      className="vertical_tab"
                      {...a11yProps(0)}
                      label={
                        <div className="symbol">
                          <MypageMenu1 />
                          {intl.formatMessage({ id: 'component.point' })}
                        </div>
                      }
                    />
                    <Tab
                      hidden={!enableComp}
                      icon={<ArrowForwardIosIcon />}
                      iconPosition="end"
                      className="vertical_tab"
                      {...a11yProps(1)}
                      label={
                        <div className="symbol">
                          <MypageMenu4 />
                          {intl.formatMessage({ id: 'component.comp' })}
                        </div>
                      }
                    />
                    <Tab
                      icon={<ArrowForwardIosIcon />}
                      iconPosition="end"
                      className="vertical_tab"
                      {...a11yProps(2)}
                      label={
                        <div className="symbol">
                          <MypageMenu2 />
                          {intl.formatMessage({ id: 'component.modify-myinfo' })}
                        </div>
                      }
                    />
                    <Tab
                      icon={<ArrowForwardIosIcon />}
                      iconPosition="end"
                      className="vertical_tab"
                      {...a11yProps(3)}
                      label={
                        <div className="symbol">
                          <MypageMenu3 />
                          {intl.formatMessage({ id: 'component.private-qna' })}
                        </div>
                      }
                    />
                  </>
                )}
              </Tabs>
            </Grid> */}
            <Grid item xs={12}>
              <TabPanel value={tabValue} index={0}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ py: 3, fontWeight: 'bold', color: '#D1D9E3' }}
                >
                  {intl.formatMessage({ id: localeId, defaultMessage: title })}
                </Typography>
                <Divider css={TitleDivider}></Divider>
                <Point location={location}></Point>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ py: 3, fontWeight: 'bold', color: '#D1D9E3' }}
                >
                  {intl.formatMessage({ id: localeId, defaultMessage: title })}
                </Typography>
                <Divider css={TitleDivider}></Divider>
                <Comp location={location}></Comp>
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <Typography
                  variant="h5"
                  component="h3"
                  sx={{ py: 3, fontWeight: 'bold', color: '#D1D9E3' }}
                >
                  {intl.formatMessage({ id: localeId, defaultMessage: title })}
                </Typography>
                <Divider css={TitleDivider}></Divider>
                <MyInfo></MyInfo>
              </TabPanel>
              <TabPanel value={tabValue} index={3}>
                <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', pt: 2 }}>
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{ py: 0, fontWeight: 'bold', color: '#D1D9E3' }}
                    >
                      {intl.formatMessage({ id: localeId, defaultMessage: title })}
                      <small style={{ fontSize: '1.1rem', fontWeight: 'normal', marginLeft: '16px' }}>
                        {intl.formatMessage({ id: 'component.has-question' })}
                      </small>
                    </Typography>
                  </Grid>
                  <Grid item>
                    {main === 'qnaPersonal' &&
                      (sub !== 'write' ? (
                        <Stack spacing={1} direction="row">
                          {canDepositMCI ? (
                            <BasicButton
                              onClick={() => onClickAskPersonal('deposit.virtual')}
                              className="btn_main"
                            >
                              {intl.formatMessage({
                                id: 'component.private-qna.deposit.virtual',
                              })}
                            </BasicButton>
                          ) : null}
                          {canDepositNormal ? (
                            <BasicButton
                              onClick={() =>
                                onClickAskPersonal(canDepositMCI ? 'deposit.normal' : 'deposit')
                              }
                              className="btn_main"
                            >
                              {intl.formatMessage({
                                id: canDepositMCI
                                  ? 'component.private-qna.deposit.normal'
                                  : 'component.private-qna.deposit',
                              })}
                            </BasicButton>
                          ) : null}
                          <BasicButton
                            onClick={() => onClickAskPersonal('write')}
                            className="btn_main"
                          >
                            {intl.formatMessage({ id: 'component.private-qna.write' })}
                          </BasicButton>
                          {channelSymbol === 'jaba' ? (
                            <BasicButton
                              onClick={() => onClickAskPersonal('sports')}
                              className="btn_main"
                            >
                              {intl.formatMessage({ id: 'sports-inquiry' })}
                            </BasicButton>
                          ) : null}
                        </Stack>
                      ) : (
                        <BasicButton onClick={() => onClickAskPersonal('list')} className="btn_main">
                          {intl.formatMessage({ id: 'list' })}
                        </BasicButton>
                      ))}
                  </Grid>
                </Grid>
                <Typography
                  sx={{
                    mt: 0,
                    mb: 0,
                    color: '#0b1526',
                    fontSize: '15px',
                    lineHeight: '15px',
                    userSelect: 'none',
                    textAlign: 'right',
                  }}
                >
                  {curDate} {userInfo.id}
                </Typography>

                <Divider css={TitleDivider}></Divider>
                {main === 'qnaPersonal' &&
                  (sub === 'write' ? (
                    <QnaPersonalWrite {...props}></QnaPersonalWrite>
                  ) : (
                    <QnaPersonal location={location}></QnaPersonal>
                  ))}
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </DarkenBg>
  )
}

export default observer(DesktopMyPage)
