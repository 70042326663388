/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { Fragment, useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import * as _ from 'lodash'
import moment from 'moment'
import queryString from 'query-string'

import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import { Box } from '@mui/material'
import { LinearProgress } from '@mui/material'
import { Stack } from '@mui/material'
import { styled } from '@mui/material/styles'

import {
  getGainLossColor,
  numberWithCommas,
  popupCenter,
  popupPartnerMember,
  popupPartnerMemberWithPeriod,
  sleep,
} from '~/utils/utils'
import {
  IPartnerConcurrentUsers,
  IPartnerMemberSummary,
  Schema$PartnerMemberSummaryItem,
  Schema$PartnerUserConfig,
} from '~/v2/protocol/partner'
import { default as apiPartner } from '@services/api/partner'
import useRootStore from '@store/useRootStore'
import { USER_STATE } from '~/v2/interface/config'
import { IntlShape, useIntl } from 'react-intl'

const MyTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: '4px',
  paddingRight: '4px',
  paddingTop: '4px',
  paddingBottom: '4px',
}))

const TypographyLink = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
  color: '#1976d2',
  ':hover': {
    cursor: 'pointer',
  },
  paddingLeft: '5px',
  paddingRight: '5px',
}))

function Head(top: boolean, canTransferMoney: boolean, showName: boolean, clickHead: Function) {
  const topStyle = top
    ? {
        paddingTop: '6px',
        paddingBottom: '6px',
      }
    : {}

  const handleClick = event => {
    clickHead(event.target.getAttribute('data-value'))
  }

  return (
    <TableHead sx={{ bgcolor: '#007f93', cursor: 'pointer' }}>
      <TableRow>
        <MyTableCell sx={{ width: '100px', ...topStyle }} align="center">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="child_size"
          >
            하부 유저수
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '56px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white' }}>번호</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '56px', ...topStyle }} align="center">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="level"
          >
            레벨
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px', ...topStyle }} align="left">
          <Typography
            sx={{ fontSize: '12px', color: 'white', cursor: 'pointer' }}
            onClick={handleClick}
            data-value="user_id"
          >
            아이디
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px', ...topStyle }} align="center">
          <Typography
            sx={{ fontSize: '12px', color: 'white', cursor: 'pointer' }}
            onClick={handleClick}
            data-value="nickname"
          >
            닉네임
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px', ...topStyle }} align="center" hidden={!showName}>
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="name"
          >
            이름
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px', ...topStyle }} align="center">
          <Typography sx={{ fontSize: '12px', color: 'white', cursor: 'pointer' }}>
            <span onClick={handleClick} data-value="date_join">
              가입일
            </span>
            ,
            <span onClick={handleClick} data-value="date_login">
              로그인
            </span>
          </Typography>
        </MyTableCell>
        <MyTableCell
          sx={{ width: canTransferMoney ? '200px' : '100px', ...topStyle }}
          align="center"
        >
          <Typography sx={{ fontSize: '12px', color: 'white' }}>
            {canTransferMoney ? '설정 / 머니 지급,회수' : '설정'}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="deposit"
          >
            충전액
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="bonus"
          >
            충전보너스(P)
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="withdraw"
          >
            환전액
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="deposit_withdraw_offset"
          >
            충환손익
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="bet_amount"
          >
            베팅액
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="win_amount"
          >
            당첨액
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="bet_win_offset"
          >
            베팅손익
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="money"
          >
            보유머니
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="point"
          >
            보유포인트
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px', ...topStyle }} align="right">
          <Typography
            sx={{ fontSize: '12px', color: 'white' }}
            onClick={handleClick}
            data-value="comp"
          >
            보유콤프
          </Typography>
        </MyTableCell>
      </TableRow>
    </TableHead>
  )
}

interface RowProps {
  intl: IntlShape
  location: any
  default_begin: Date
  default_end: Date
  row: HierarachyData
  root: boolean
  target_uuid: string
  canTransferMoney: boolean
  showName: boolean
  is_active_money_give_and_take: boolean
  onClickMember: (uuid: string, key: string) => void
}

function Row({
  intl,
  location,
  default_begin,
  default_end,
  row,
  root,
  target_uuid,
  canTransferMoney,
  showName,
  is_active_money_give_and_take,
  onClickMember,
}: RowProps) {
  const query = queryString.parse(location.search)
  const { begin, end, key, value, ts } = query

  const [open, setOpen] = useState(false)

  const [loading, setLoading] = useState(false)
  const [track, setTrack] = useState<IPartnerMemberSummary.Schema | null>(null)

  const { globalStore } = useRootStore()

  const [order_field, setOrderField] = useState('user_id')
  const [order_direction, setOrderDirection] = useState('desc')

  const clickHead = (field: string) => {
    setOrderField(field)
    setOrderDirection(order_direction == 'desc' ? 'asc' : 'desc')
  }

  const fetch = async (params: IPartnerMemberSummary.Params) => {
    setLoading(true)
    await sleep(300)
    try {
      const ret = await apiPartner.getMemberSummary(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (row.openChildren) {
      setOpen(row.openChildren)
    }
  }, [])

  useEffect(() => {
    if (open) {
      let date_begin = begin ? (begin as string) : default_begin.toString()
      let date_end = end ? (end as string) : default_end.toString()
      const params: IPartnerMemberSummary.Params = {
        date_begin: date_begin,
        date_end: date_end,
        searchKey: '',
        searchValue: '',
        flag: 'add',
        target_uuid: row.uuid,
        order_field: order_field,
        order_direction: order_direction,
      }

      fetch(params)
    }
  }, [open, order_field, order_direction])

  let children: HierarachyData[] = []
  if (track && track.items && track.items.length) {
    for (let i = 0; i < track.items.length; i++) {
      const el = track.items[i]
      const data = {
        uuid: el.uuid,
        item: el,
        index: i,
        children: [],
        openChildren: false,
      }
      children.push(data)
    }
  }

  const hasChildren = !root && row.item.child_size > 0
  const txtJoinDate = row.item.date_join ? moment(row.item.date_join).format('MM/DD') : '-'
  const txtLoginDate = row.item.date_login ? moment(row.item.date_login).format('MM/DD') : '-'
  let txtDate = `${txtJoinDate}, ${txtLoginDate}`
  if (row.item.away_days !== 0) {
    txtDate = `${txtDate}(${row.item.away_days})`
  }

  const rowStyle = root ? { background: '#e2e2c2' } : {}

  const showTransferMoney =
    is_active_money_give_and_take && canTransferMoney && row.item.merchant_uuid === target_uuid

  const obj_user_state = _.find(USER_STATE, o => {
    return o.id === row.item.user_state
  })
  const ty_user_state =
    obj_user_state?.id === 'BLOCK' || obj_user_state?.id === 'BLACK' ? (
      <span style={{ fontSize: '12px', color: '#c62828' }}>({obj_user_state.name})</span>
    ) : null

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, ...rowStyle }}>
        <MyTableCell sx={{ paddingLeft: '16px', width: '100px' }}>
          <IconButton
            disabled={!hasChildren}
            style={hasChildren ? {} : { color: 'white' }}
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {hasChildren ? <>({row.item.child_size})</> : null}
        </MyTableCell>
        <MyTableCell sx={{ width: '56px' }} align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.index + 1}</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '56px' }} align="center">
          <Typography sx={{ fontSize: '12x' }}>{row.item.level}</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px' }} align="center">
          <Box display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                textDecorationLine: 'underline',
                ':hover': {
                  cursor: 'pointer',
                },
              }}
              onClick={() => onClickMember(row.uuid, 'detail')}
            >
              {row.item.user_id}
            </Typography>
            {ty_user_state}
          </Box>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px' }} align="center">
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              textDecorationLine: 'underline',
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onClickMember(row.uuid, 'detail')}
          >
            {row.item.nickname}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '120px' }} align="center" hidden={!showName}>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 'bold',
              textDecorationLine: 'underline',
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={() => onClickMember(row.uuid, 'detail')}
          >
            {row.item.name}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '150px' }} align="center">
          <Typography sx={{ fontSize: '12x' }}>{txtDate}</Typography>
        </MyTableCell>
        <MyTableCell sx={{ width: '100px' }} align="center">
          <Stack direction="row" justifyContent="center">
            <TypographyLink onClick={() => onClickMember(row.uuid, 'commission')}>
              요율
            </TypographyLink>
            <TypographyLink onClick={() => onClickMember(row.uuid, 'detail')}>상세</TypographyLink>
            <TypographyLink hidden={!showTransferMoney}>/</TypographyLink>
            <TypographyLink
              hidden={!showTransferMoney}
              onClick={() => onClickMember(row.uuid, 'money_give')}
            >
              지급
            </TypographyLink>
            <TypographyLink
              hidden={!showTransferMoney}
              onClick={() => onClickMember(row.uuid, 'money_take')}
            >
              회수
            </TypographyLink>
          </Stack>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.deposit))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.bonus))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(Math.floor(row.item.withdraw)))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{
              fontSize: '12px',
              color: getGainLossColor(Math.floor(row.item.deposit_withdraw_offset)),
            }}
          >
            {numberWithCommas(Math.floor(row.item.deposit_withdraw_offset))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.bet_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.win_amount))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography
            sx={{ fontSize: '12px', color: getGainLossColor(Math.floor(row.item.bet_win_offset)) }}
          >
            {numberWithCommas(Math.floor(row.item.bet_win_offset))}
          </Typography>
        </MyTableCell>
        <MyTableCell align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.money))}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.point))}
          </Typography>
        </MyTableCell>
        <MyTableCell sx={{ paddingRight: '16px' }} align="right">
          <Typography sx={{ fontSize: '12x' }}>
            {numberWithCommas(Math.floor(row.item.comp))}
          </Typography>
        </MyTableCell>
      </TableRow>
      {hasChildren ? (
        <TableRow sx={{ background: '#a4a4a4' }}>
          <TableCell style={{ padding: 0 }} colSpan={showName ? 18 : 17}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <TableContainer component={Paper} sx={{ margin: '3px', width: 'calc(100% - 6px)' }}>
                <Table size="small" aria-label="collapsible table">
                  {Head(false, canTransferMoney, showName, clickHead)}
                  <TableBody>
                    {children.map(row => (
                      <Row
                        intl={intl}
                        key={row.uuid}
                        location={location}
                        default_begin={default_begin}
                        default_end={default_end}
                        row={row}
                        root={false}
                        target_uuid={target_uuid}
                        canTransferMoney={canTransferMoney}
                        showName={showName}
                        is_active_money_give_and_take={is_active_money_give_and_take}
                        onClickMember={onClickMember}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </Fragment>
  )
}

class HierarachyData {
  uuid: string
  item: Schema$PartnerMemberSummaryItem
  index: number
  children: HierarachyData[] = []
  openChildren: boolean
}

function makeHierarachy(parent: HierarachyData, items: Schema$PartnerMemberSummaryItem[]) {
  if (!parent) {
    return null
  }

  let children = _.filter(items, { merchant_uuid: parent.uuid })
  children = _.orderBy(children, ['class'], ['asc'])

  for (let i = 0; i < children.length; i++) {
    const child: HierarachyData = {
      uuid: children[i].uuid,
      item: children[i],
      index: i,
      children: [],
      openChildren: false,
    }
    parent.children.push(child)
    makeHierarachy(child, items)
  }
}

interface Props {
  location: any
  default_begin: Date
  default_end: Date
}

function PartnerIntergratedMemberTable({ location, default_begin, default_end }: Props) {
  const intl = useIntl()
  const query = queryString.parse(location.search)
  const { begin, end, key, value, ts } = query

  const [timestamp, setTimestamp] = useState('')
  const [loading, setLoading] = useState(false)
  const [track, setTrack] = useState<IPartnerMemberSummary.Schema | null>(null)
  const [partnerUserConfig, setPartnerUserConfig] = useState<Schema$PartnerUserConfig | null>(null)
  const { userStore, globalStore } = useRootStore()

  const { userInfo } = userStore
  const { uuid } = userInfo || {}
  const { option } = globalStore
  const { privacy, canTransferMoney } = option || {}
  const { is_disclose_name } = privacy || {}
  const showName = is_disclose_name === true

  const { is_active_give_money, is_active_take_money } = partnerUserConfig || {}
  const is_active_money_give_and_take = is_active_give_money || is_active_take_money

  if (timestamp !== (ts as string)) {
    setTimestamp(ts as string)
  }

  const fetchPartnerUserConfig = async () => {
    try {
      const { config } = await apiPartner.getUserConfig({})
      setPartnerUserConfig(config)
    } catch (err) {}
  }

  const fetch = async (params: IPartnerMemberSummary.Params) => {
    setTrack(null)
    setLoading(true)
    try {
      const ret = await apiPartner.getMemberSummary(params)
      setTrack(ret)
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
    setLoading(false)
  }

  useEffect(() => {
    let date_begin = begin ? (begin as string) : default_begin.toString()
    let date_end = end ? (end as string) : default_end.toString()
    const params: IPartnerMemberSummary.Params = {
      date_begin: date_begin,
      date_end: date_end,
      searchKey: key as string,
      searchValue: value as string,
      flag: '',
      target_uuid: '',
    }

    fetch(params)
    fetchPartnerUserConfig()
  }, [timestamp])

  const onClickMember = (uuid: string, key: string) => {
    let titlePrefix = ''
    let url = ''
    let dw = 0
    let dh = 0
    switch (key) {
      case 'detail':
      case 'commission':
        let date_begin = begin ? new Date(begin as string) : default_begin
        let date_end = end ? new Date(end as string) : default_end
        popupPartnerMemberWithPeriod(uuid, key, date_begin, date_end)
        break
      case 'money_give':
      case 'money_take':
        titlePrefix = 'MoneyTransfer'
        url = `/partner/money-transfer/search?uuid=${uuid}&type=${key}`
        dw = 800
        dh = 430
        break
    }

    if (url.length > 0) {
      const w = dw || 1220
      const h = dh || (window.screen.height * 80) / 100
      popupCenter({ url, title: `${titlePrefix}-${uuid}`, w, h })
    }
  }

  const onClickCollapseAll = (collapse: boolean) => {
    //
  }

  let children: HierarachyData[] = []
  if (track && track.items && track.items.length) {
    const defaultTrack = track.flag !== 'add'
    for (let i = 0; i < track.items.length; i++) {
      const el = track.items[i]
      const data = {
        uuid: el.uuid,
        item: el,
        index: i,
        children: [],
        openChildren: defaultTrack,
      }
      children.push(data)
    }
  }

  return (
    <>
      {/* <Button
        variant="contained"
        sx={{ minHeight: '24px', maxHeight: '24px', background: 'grey' }}
        onClick={() => onClickCollapseAll(true)}
      >
        모두접기
      </Button>
      <Button
        variant="contained"
        sx={{ minHeight: '24px', maxHeight: '24px', background: 'grey', marginLeft: '4px' }}
        onClick={() => onClickCollapseAll(false)}
      >
        모두펼치기
      </Button> */}
      <TableContainer component={Paper}>
        <Box sx={{ width: '100%', height: '4px' }}>{loading ? <LinearProgress /> : null}</Box>
        <Table size="small" aria-label="collapsible table" sx={{ width: '100%' }}>
          {Head(true, canTransferMoney, showName, () => {})}
          <TableBody>
            {children.map(row => (
              <Row
								intl={intl}
                key={row.uuid}
                location={location}
                default_begin={default_begin}
                default_end={default_end}
                row={row}
                root={false}
                target_uuid={uuid}
                canTransferMoney={canTransferMoney}
                showName={showName}
                is_active_money_give_and_take={is_active_money_give_and_take}
                onClickMember={onClickMember}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default observer(PartnerIntergratedMemberTable)
