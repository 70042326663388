/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import { jsx } from '@emotion/react'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './containers/app'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import Provider from './containers/shared/Context/Provider'
// import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack'

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();;;
