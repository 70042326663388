import * as _ from 'lodash'
import Button from '../premitives/button'
import ChipButton from './chip-button'
import { CHIP_BUTTONS } from './chip-data'
import { OddsPosition } from './types'

export default class ChipComponent extends Phaser.GameObjects.Container {
  initialPosition: OddsPosition = { x: 0, y: 0 }

  btnCancelAll: Button = null
  btnCancelLastBet: Button = null
  btnDoubleupBet: Button = null

  chips: ChipButton[] = []

  selectedChipId = 0

  handleChipSlelected: (id: number) => void = null
  handleClickChipButton: (id: number) => void = null
  handleCancelAll: () => void = null
  handleCancelLastBet: () => void = null
  handleDoubleBet: () => void = null

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    handleChipSlelected: (id: number) => void,
    handleClickChipButton: (id: number) => void,
    handleCancelAll: () => void,
    handleCancelLastBet: () => void,
    handleDoubleBet: () => void,
  ) {
    super(scene, x, y)

    scene.add.existing(this)

    this.handleChipSlelected = handleChipSlelected
    this.handleClickChipButton = handleClickChipButton
    this.handleCancelAll = handleCancelAll
    this.handleCancelLastBet = handleCancelLastBet
    this.handleDoubleBet = handleDoubleBet

    this.initialPosition = { x, y }

    this.addButtons()
    this.addChips()
  }

  setEnabled(enabled: boolean) {
    this.setVisible(enabled)
  }

  addButtons() {
    // Cancel all
    this.btnCancelAll = new Button(
      this.scene,
      353,
      252,
      { texture: 'ui', frame: 'cancel_bet_all.png' },
      { texture: 'ui', frame: 'cancel_bet_all_push.png' },
      { texture: 'ui', frame: 'cancel_bet_all.png' },
      this.handleCancelAll.bind(this),
    )
    this.add(this.btnCancelAll)

    // Cancel last
    this.btnCancelLastBet = new Button(
      this.scene,
      398,
      252,
      { texture: 'ui', frame: 'cancel_bet.png' },
      { texture: 'ui', frame: 'cancel_bet_push.png' },
      { texture: 'ui', frame: 'cancel_bet.png' },
      this.onClickCancelLastBet.bind(this),
    )
    this.add(this.btnCancelLastBet)

    // Double up
    this.btnDoubleupBet = new Button(
      this.scene,
      807,
      252,
      { texture: 'ui', frame: 'times2_bet.png' },
      { texture: 'ui', frame: 'times2_bet_push.png' },
      { texture: 'ui', frame: 'times2_bet.png' },
      this.onClickDoubleupBet.bind(this),
    )
    this.add(this.btnDoubleupBet)

    // add text
    // const txtCancel = this.scene.add
    //   .text(370, 256, '이전베팅 취소', {
    //     fontSize: '12px',
    //     color: '#ffffff88',
    //     fontFamily: 'PT Serif',
    //   })
    //   .setOrigin(1, 0.5)
    // this.add(txtCancel)
    const txtCancelAll = this.scene.add
      .text(353, 281, '취소', {
        fontSize: '12px',
        color: '#ffffff88',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txtCancelAll)

    const txtCancelLast = this.scene.add
      .text(398, 281, '-1', {
        fontSize: '12px',
        color: '#ffffff88',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txtCancelLast)

    const txtDoubleup = this.scene.add
      .text(807, 281, '더블', {
        fontSize: '12px',
        color: '#ffffff88',
        fontFamily: 'PT Serif',
      })
      .setOrigin(0.5, 0.5)
    this.add(txtDoubleup)
  }

  addChips() {
    const startX = 458
    const chipWidth = 58
    for (let i = 0; i < CHIP_BUTTONS.length; i += 1) {
      const chipData = CHIP_BUTTONS[i]
      const chip = new ChipButton(
        this.scene,
        startX + i * chipWidth,
        256,
        chipData,
        this.onClickChipButton.bind(this),
      )
      this.add(chip)

      this.chips.push(chip)
    }
  }

  getChipButton(id: number) {
    const btn = _.find(this.chips, (o: ChipButton) => {
      return o.attributes.id === id
    })
    return btn
  }

  getChipButtonByType(type: string) {
    const btn = _.find(this.chips, (o: ChipButton) => {
      return o.attributes.type === type
    })
    return btn
  }

  releaseChip() {
    this.selectChip(0)
  }

  getSelectedChip() {
    for (const el of this.chips) {
      if (el.attributes.id === this.selectedChipId) {
        return el
      }
    }
    return null
  }

  selectChip(id: number) {
    this.selectedChipId = id
    for (const el of this.chips) {
      el.setHighlight(el.attributes.id === id)
    }

    if (this.handleChipSlelected) {
      this.handleChipSlelected(id)
    }
  }

  onClickChipButton(btn: ChipButton) {
    if (this.handleClickChipButton) {
      this.handleClickChipButton(btn.attributes.id)
    }

    this.selectChip(btn.attributes.id)
  }

  onClickCancelAll() {
    if (this.handleCancelAll) {
      this.handleCancelAll()
    }
  }

  onClickCancelLastBet() {
    if (this.handleCancelLastBet) {
      this.handleCancelLastBet()
    }
  }

  onClickDoubleupBet() {
    if (this.handleDoubleBet) {
      this.handleDoubleBet()
    }
  }
}
