import * as _ from 'lodash'
import { Schema$OpenTradingKind } from '../v2/protocol/public'

interface MAP_DATA {
  id: string
  name: string
}
export const POSITION_MAP: MAP_DATA[] = [
  {
    id: 'HOME',
    name: '홈',
  },
  {
    id: 'HCA',
    name: '호텔카지노',
  },
  {
    id: 'LCA',
    name: '라이브카지노',
  },
  {
    id: 'SL',
    name: '슬롯',
  },
  {
    id: 'REEL',
    name: '릴',
  },
  {
    id: 'BOARDGAME',
    name: '보드게임',
  },
  {
    id: 'SP',
    name: '스포츠',
  },
  {
    id: 'MSL',
    name: '메가슬롯',
  },
  {
    id: 'MG',
    name: '미니게임',
  },
  {
    id: 'BANK-DEPOSIT-NORMAL',
    name: '충전',
  },
  {
    id: 'BANK-DEPOSIT-DC',
    name: '충전',
  },
  {
    id: 'BANK-DEPOSIT-ONEPAY',
    name: '충전',
  },
  {
    id: 'BANK-DEPOSIT-COIN',
    name: '충전',
  },
  {
    id: 'BANK-DEPOSIT-COIN-JUN200',
    name: '충전',
  },
  {
    id: 'BANK-DEPOSIT-5KOIN',
    name: '충전',
  },
  {
    id: 'BANK-DEPOSIT-WONP',
    name: '충전',
  },
  {
    id: 'BANK-WITHDRAW',
    name: '환전',
  },
  {
    id: 'BANK-RECORD',
    name: '충/환전 내역',
  },
  {
    id: 'MYPAGE-POINT',
    name: '마이페이지',
  },
  {
    id: 'MYPAGE-COMP',
    name: '마이페이지',
  },
  {
    id: 'MYPAGE-ACCOUNT',
    name: '마이페이지',
  },
  {
    id: 'MYPAGE-QNA',
    name: '고객센터',
  },
  {
    id: 'MESSAGE',
    name: '쪽지함',
  },
  {
    id: 'COUPON',
    name: '쿠폰함',
  },
  {
    id: 'PARTNER-BALANCE-RECORD',
    name: '정산내역',
  },
  {
    id: 'PARTNER-BALANCE-REQUEST',
    name: '정산요청',
  },
  {
    id: 'PARTNER-BETTING',
    name: '베팅내역',
  },
  {
    id: 'PARTNER-HOME',
    name: '파트너홈',
  },
  {
    id: 'PARTNER-MEMBERS-CONCURRENT',
    name: '현재접속자',
  },
  {
    id: 'PARTNER-MEMBERS',
    name: '회원리스트',
  },
  {
    id: 'PARTNER-COUPON',
    name: '쿠폰내역',
  },
  {
    id: 'PARTNER-CHARGE-RECORD',
    name: '충/환전 내역',
  },
  {
    id: 'PARTNER-STATS-ROLLING',
    name: '게임통계',
  },
  {
    id: 'PARTNER-STATS-GAME',
    name: '게임통계',
  },
  {
    id: 'PARTNER-STATS-REVENUE',
    name: '게임통계',
  },
  {
    id: 'NOTICE-NOTICE',
    name: '공지사항',
  },
  {
    id: 'NOTICE-EVENT',
    name: '이벤트',
  },
  {
    id: 'NOTICE-POLICY',
    name: '이용규정',
  },
]

export function getPositionPageName(pageName: string, tradingKinds: Schema$OpenTradingKind[]) {
  if (!pageName) {
    return 'NULL'
  }
  const found = _.find(POSITION_MAP, o => {
    return o.id === pageName
  })
  if (found) {
    return found.name
  }
  let name = pageName
  let kind: Schema$OpenTradingKind = null
  const arr = pageName.split('-')
  if (arr.length === 1) {
    kind = _.find(tradingKinds, o => {
      return o.trading_kind === arr[0]
    })
  } else if (arr.length === 2) {
    kind = _.find(tradingKinds, o => {
      return o.trading_kind === arr[1]
    })
  }

  if (kind) {
    name = kind.name
    if (kind.trading_group === 'SL') {
      name = `슬롯-${kind.name}`
    }
  }

  return name
}
