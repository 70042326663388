// import { MarketGroup } from './market_group_types'
// // 퍽라인은 포함하지 않는다.
// export const ICEHOCKEY_INPLAY_MARKET_GROUP: MarketGroup[] = [		// not working in client

// 퍽라인은 포함하지 않는다.
export const ICEHOCKEY_INPLAY_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      {
        id: 52,
        unifiedName: '12',
        name: { en: '12', ko: '승패' },
      },
      {
        id: 226,
        unifiedName: '12',
        name: { en: '12 Including Overtime', ko: '승패 (연장포함)' },
      },
      { id: 1, unifiedName: '1X2', name: { en: '1X2', ko: '승무패' } },
      {
        id: 342,
        unifiedName: 'HDP',
        name: {
          en: 'Asian Handicap Including Overtime',
          ko: '핸디캡 (연장포함) *',
        },
      },
      {
        id: 1061,
        unifiedName: 'UO',
        name: {
          en: 'Asian Under/Over Including Overtime',
          ko: '총 득점 (연장포함) *',
        },
      },
      {
        id: 28,
        unifiedName: 'UO',
        name: { en: 'Under/Over Including Overtime', ko: '총 득점 (연장포함)' },
      },
      {
        id: 835,
        unifiedName: 'UO',
        name: { en: 'Asian Under/Over', ko: '총 득점 *' },
      },
      {
        id: 69,
        unifiedName: 'LIST2',
        name: { en: 'Will The Game Go To Overtime?', ko: '연장전?' },
      },
      {
        id: 51,
        unifiedName: 'LIST2',
        name: {
          en: 'Odd/Even Including Overtime',
          ko: '홀/짝 총 득점 (연장포함)',
        },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: 'Period', ko: '피리어드' },
    markets: [
      // 1피리어드
      {
        id: 41,
        unifiedName: '1X2',
        name: { en: '1st Period Winner', ko: '승무패 (1피리어드)' },
      },
      {
        id: 202,
        unifiedName: '12',
        name: { en: '1st Period Winner Home/Away', ko: '승패 (1피리어드)' },
      },
      {
        id: 64,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 1st Period', ko: '핸디캡 (1피리어드) *' },
      },
      {
        id: 21,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period', ko: '총 득점 (1피리어드)' },
      },
      {
        id: 836,
        unifiedName: 'UO',
        name: {
          en: 'Asian Under/Over 1st Period',
          ko: '총 득점 (1피리어드) *',
        },
      },
      {
        id: 9,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score 1st Period', ko: '정확한 점수 (1피리어드)' },
      },
      {
        id: 727,
        unifiedName: 'LIST2',
        name: {
          en: '1st Period Both Teams To Score',
          ko: '양팀 득점 (1피리어드)',
        },
      },
      // 2피리어드
      {
        id: 42,
        unifiedName: '1X2',
        name: { en: '1st Period Winner', ko: '승무패 (2피리어드)' },
      },
      {
        id: 203,
        unifiedName: '12',
        name: { en: '2nd Period Winner Home/Away', ko: '승패 (2피리어드)' },
      },
      {
        id: 65,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 2nd Period', ko: '핸디캡 (2피리어드) *' },
      },
      {
        id: 45,
        unifiedName: 'UO',
        name: { en: 'Under/Over 2nd Period', ko: '총 득점 (2피리어드)' },
      },
      {
        id: 1053,
        unifiedName: 'UO',
        name: {
          en: 'Asian Under/Over 2nd Period',
          ko: '총 득점 (2피리어드) *',
        },
      },
      {
        id: 100,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score 2nd Period', ko: '정확한 점수 (2피리어드)' },
      },
      {
        id: 728,
        unifiedName: 'LIST2',
        name: {
          en: '2nd Period Both Teams To Score',
          ko: '양팀 득점 (2피리어드)',
        },
      },
      // 3피리어드
      {
        id: 43,
        unifiedName: '1X2',
        name: { en: '3rd Period Winner', ko: '승무패 (3피리어드)' },
      },
      {
        id: 204,
        unifiedName: '12',
        name: { en: '3rd Period Winner Home/Away', ko: '승패 (3피리어드)' },
      },
      {
        id: 66,
        unifiedName: 'HDP',
        name: { en: 'Asian Handicap 3rd Period', ko: '핸디캡 (3피리어드) *' },
      },
      {
        id: 46,
        unifiedName: 'UO',
        name: { en: 'Under/Over 3rd Period', ko: '총 득점 (3피리어드)' },
      },
      {
        id: 1054,
        unifiedName: 'UO',
        name: {
          en: 'Asian Under/Over 3rd Period',
          ko: '총 득점 (3피리어드) *',
        },
      },
      {
        id: 217,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score 3rd Period', ko: '정확한 점수 (3피리어드)' },
      },
      {
        id: 729,
        unifiedName: 'LIST2',
        name: {
          en: '3rd Period Both Teams To Score',
          ko: '양팀 득점 (3피리어드)',
        },
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: 'Score', ko: '점수' },
    markets: [
      {
        id: 5,
        unifiedName: 'LIST2',
        name: { en: 'Odd/Even', ko: '홀/짝 총 득점' },
      },
      {
        id: 341,
        unifiedName: 'N12',
        name: { en: 'Race To', ko: '선취 득점' },
      },
      {
        id: 59,
        unifiedName: 'LIST3',
        name: { en: 'Next Goal', ko: '다음 득점 팀' },
      },
      {
        id: 56,
        unifiedName: 'LIST3',
        name: { en: 'Last Team To Score', ko: '마지막 득점 팀' },
      },
      {
        id: 6,
        unifiedName: 'LIST1',
        name: { en: 'Correct Score', ko: '정확한 점수' },
      },
      {
        id: 602,
        unifiedName: 'LIST1',
        name: {
          en: 'Correct Score Including Overtime',
          ko: '정확한 점수 (연장포함)',
        },
      },
      {
        id: 128,
        unifiedName: 'LIST1',
        name: { en: 'Number of Goals', ko: '총 득점 일치' },
      },
      {
        id: 149,
        unifiedName: 'LIST1',
        name: {
          en: 'Home Team Number Of Goals',
          ko: '총 득점 일치 - 홈팀',
        },
      },
      {
        id: 150,
        unifiedName: 'LIST1',
        name: {
          en: 'Away Team Number Of Goals',
          ko: '총 득점 일치 - 원정팀',
        },
      },
    ],
  },
]
