/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import { useEffect } from 'react'
import { Box, Container, Typography, Grid } from '@mui/material'
import ImageList from '@mui/material/ImageList'
import ImageListItemBar from '@mui/material/ImageListItemBar'
import { styled } from '@mui/material/styles'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import useRootStore from '@store/useRootStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'
import { SOCKET_SERVICE_STATE } from '~/store/socketStore'
import { useIntl } from 'react-intl'

// css
import { ImageListItemStyle, ImageListItemStyle2 } from '@styles/base.style'

// image
import { CasinoComing } from '~/assets/images/layout_1'
import * as imgCasino from '~/assets/images/layout_1/casino'
import * as imgCasinoMarine from '~/assets/images/layout_1/casino/marine'
import * as imgCasinoMarine2 from '~/assets/images/layout_3/casino/marine2'

const TextStyle = styled(Typography)(({ theme }) => ({
  fontSize: '2rem',
  fontWeight: 'bold',
  color: theme.colors.listTitle,
}))

const channelSymbol = process.env.REACT_APP_CHANNEL || ''

let mergedImgCasino = { ...imgCasino }

switch (channelSymbol) {
  case 'marine':
  case 'tiger':
  case 'hilton':
  case 'soft':
  case 'gangnam':
  case 'milky':
  case 'hulk':
    mergedImgCasino = { ...mergedImgCasino, ...imgCasinoMarine }
    break
  case 'marine2':
    mergedImgCasino = { ...mergedImgCasino, ...imgCasinoMarine2 }
    break
}

const ListTitle = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  color: theme.colors.listTitle,
  fontWeight: 'bold',
  fontSize: '1.8rem',
}))

let hcaResources = [
  {
    name: 'VT',
    thumb: mergedImgCasino.imgHotelCasino_bota,
    title: '보타 카지노 입장',
    hoverimg: imgCasinoMarine.imgHotelCasino_bota_h,
    localeId: 'trading.HCA.VT',
  },
  {
    name: 'UIG',
    thumb: mergedImgCasino.imgHotelCasino_uig,
    title: 'UIG 카지노 입장',
    hoverimg: imgCasinoMarine.imgHotelCasino_uig_h,
    localeId: 'trading.HCA.UIG',
  },
  {
    name: 'DW',
    thumb: mergedImgCasino.imgHotelCasino_dowin,
    title: '두윈 카지노 입장',
    hoverimg: imgCasinoMarine.imgHotelCasino_dowin_h,
    localeId: 'trading.HCA.DW',
  },
]

let appBackground = process.env.REACT_APP_BACKGROUND
if (appBackground) {
  appBackground = `#${appBackground}`
}

const HotelCasino: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore, userStore, liveStore } = useRootStore()

  const { serviceState } = liveStore
  const { initialized, signedin } = authStore
  const { media_mode } = globalStore // watch media_mode to get event
  const { desktop } = globalStore.decideMedia(isDesktop)

  const { option } = globalStore
  const { open_trading_kinds } = option || {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (signedin && initialized && serviceState === SOCKET_SERVICE_STATE.ENTERED) {
      userStore.setPositionPage('HCA')
    }
  }, [signedin, initialized, serviceState])

  function onClickCard(kind: string) {
    globalStore.signedinNewGame('HCA', kind, signedin, intl)
  }

  // exceptional maintenance
  let exMaintenance = false
  // switch (channelSymbol) {
  //   case 'myeongseong':
  //     exMaintenance = true
  //     break
  // }

  if (exMaintenance) {
    let responsiveHeight = ''
    if (desktop) {
      responsiveHeight = 'calc(100vh - 288px)'
    } else {
      responsiveHeight = 'calc(100vh - 202px)'
    }

    return (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ maxWidth: 1248, m: 'auto', minHeight: responsiveHeight, background: '#15191c' }}
        >
          <Grid item xs={10} sx={{ p: '40px 0', textAlign: 'center' }}>
            <img src={CasinoComing} alt="coming-soon" style={{ maxWidth: '100%' }} />
            <TextStyle>점검중입니다.</TextStyle>
          </Grid>
        </Grid>
      </>
    )
  }

  const listItems = []

  for (const el of hcaResources) {
    const trading = _.find(open_trading_kinds, o => {
      return o.trading_kind === el.name
    })
    if (trading) {
      if (
        channelSymbol === 'marine' ||
        channelSymbol === 'tiger' ||
        channelSymbol === 'hilton' ||
        channelSymbol === 'soft' ||
        channelSymbol === 'gangnam' ||
        channelSymbol === 'milky' ||
        channelSymbol === 'hulk'
      ) {
        listItems.push(
          <ImageListItemStyle onClick={() => onClickCard(el.name)} key={el.name}>
            <div className="img_wrap">
              <img
                src={el.thumb}
                alt={'hotel casino image ' + el.name}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line">
                <img
                  src={el.hoverimg}
                  alt={'hotel casino image ' + el.name}
                  loading="lazy"
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </ImageListItemStyle>,
        )
      } else if (channelSymbol === 'marine2') {
        listItems.push(
          <ImageListItemStyle2 onClick={() => onClickCard(el.name)} key={el.name}>
            <div className="img_wrap">
              <img
                src={el.thumb}
                alt={'hotel casino image ' + el.name}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line"></div>
            </div>
          </ImageListItemStyle2>,
        )
      } else {
        listItems.push(
          <ImageListItemStyle onClick={() => onClickCard(el.name)} key={el.name}>
            <div className="img_wrap">
              <img
                src={el.thumb}
                alt={'hotel casino image ' + el.name}
                loading="lazy"
                style={{ width: '100%' }}
              />
              <div className="border_line"></div>
            </div>
            <ImageListItemBar
              title={
                <div style={{ fontSize: '1.1rem' }}>
                  {intl.formatMessage({ id: el.localeId, defaultMessage: trading.name })}
                </div>
              }
              position="below"
              sx={{
                border: 'none',
                '& .MuiImageListItemBar-titleWrap': {
                  padding: '6px 0',
                },
              }}
            />
          </ImageListItemStyle>,
        )
      }
    }
  }

  let contents = null
  let minWidthDesktop = '1248px'

  if (
    channelSymbol === 'today' ||
    channelSymbol === 'hongyeom' ||
    channelSymbol === 'ace' ||
    channelSymbol === 'ssg' ||
    channelSymbol === 'praha'
  ) {
    minWidthDesktop = '1448px'
  }

  if (desktop) {
    if (
      channelSymbol === 'marine' ||
      channelSymbol === 'tiger' ||
      channelSymbol === 'hilton' ||
      channelSymbol === 'soft' ||
      channelSymbol === 'gangnam' ||
      channelSymbol === 'milky' ||
      channelSymbol === 'hulk'
    ) {
      contents = (
        <Container className="content_wrap" sx={{ minWidth: '1648px', pb: 5 }}>
          <ImageList cols={5} gap={12}>
            {listItems}
          </ImageList>
        </Container>
      )
    } else if (channelSymbol === 'marine2') {
      contents = (
        <Box className="content_wrap" sx={{ pb: 5 }}>
          <Container sx={{ minWidth: '1368px', mx: 0 }}>
            <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
              {intl.formatMessage({ id: 'trading.HCA' })}
            </ListTitle>
            <ImageList cols={4} gap={12}>
              {listItems}
            </ImageList>
          </Container>
        </Box>
      )
    } else {
      contents = (
        <Container
          className="content_wrap"
          sx={{ minWidth: minWidthDesktop, pb: 5, backgroundColor: appBackground }}
        >
          <ListTitle sx={{ pt: 4, fontSize: '1.8rem' }}>
            {intl.formatMessage({ id: 'trading.HCA' })}
          </ListTitle>
          <ImageList cols={4} gap={12}>
            {listItems}
          </ImageList>
        </Container>
      )
    }
  } else {
    if (
      channelSymbol === 'marine' ||
      channelSymbol === 'marine2' ||
      channelSymbol === 'tiger' ||
      channelSymbol === 'hilton' ||
      channelSymbol === 'soft' ||
      channelSymbol === 'gangnam' ||
      channelSymbol === 'milky' ||
      channelSymbol === 'hulk'
    ) {
      contents = (
        <Box
          className="content_wrap_m"
          sx={{ p: 2, backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}
        >
          <ImageList cols={2} gap={12}>
            {listItems}
          </ImageList>
        </Box>
      )
    } else {
      contents = (
        <Box
          className="content_wrap_m"
          sx={{ p: 2, backgroundColor: appBackground, minHeight: 'calc(100vh - 202px)' }}
        >
          <ListTitle sx={{ fontSize: '1.2rem' }}>
            {intl.formatMessage({ id: 'trading.HCA' })}
          </ListTitle>
          <ImageList cols={2} gap={12}>
            {listItems}
          </ImageList>
        </Box>
      )
    }
  }

  return <>{contents}</>
}

export default observer(HotelCasino)
