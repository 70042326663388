import React, { useEffect, useRef, useState } from 'react'
import * as _ from 'lodash'
import { Theme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Schema$FilteredMarket, Schema$Market, Schema$MarketBet } from '~/v2/interface/st-schema'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'
import { toJS } from 'mobx'
import useRootStore from '~/store/useRootStore'
import { render } from '@testing-library/react'
import { sportsStore } from '~/store'
import { getBetStatusColor } from '~/game/st-util'
import { over, under, iconH } from '~/assets/images/layout_1'
import { constants } from 'zlib'
import { BET_STATUS } from '~/v2/interface/st-types'
import LockIcon from '@mui/icons-material/Lock'
import { UPDATE_FIELD_TYPE } from '~/store/sportsStore/sports-util'

const CornerMark = styled(Typography)(({ theme }) => ({
  '&.up, &.down': {
    position: 'absolute',
    display: 'block',
    width: 0,
    height: 0,
    borderWidth: '5px',
    borderStyle: 'solid',
  },
  '&.up': {
    top: '-5px',
    borderColor: 'transparent',
    borderTopColor: 'red',
  },
  '&.down': {
    bottom: '-5px',
    borderColor: 'transparent',
    borderTopColor: 'green',
  },
  '&.up.prefix': {
    left: '-5px',
    transform: 'rotate(135deg)',
  },
  '&.up.suffix': {
    right: '-5px',
    transform: 'rotate(225deg)',
  },
  '&.down.prefix': {
    left: '-5px',
    transform: 'rotate(45deg)',
  },
  '&.down.suffix': {
    right: '-5px',
    transform: 'rotate(315deg)',
  },
}))

const LockBox = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgb(0 0 0 / 0.5)',
}))

const REVERSE_ATOM_NAMES = ['away', 'b']

interface Props {
  theme: Theme
  name: string //	좌측 오즈 선택 이름
  unifiedName: string // 홈승, 원정승, 언더, 오버등의 odds 이름
  atomName: string // home, away, a, b 등의 심볼
  fixtureId: number
  marketId: number
  marketName: string // 1X2, 12 등의 마켓 이름
  market: Schema$Market | Schema$FilteredMarket
  bet: Schema$MarketBet
  selected: boolean
  drawSymbol: boolean
}

// interface State {
//   currentBet: Schema$MarketBet
//   // sxUpdated: Object
//   updated: string // inc, dec
// }

function DetailOddsBoxAtom({
  theme,
  name,
  unifiedName,
  atomName,
  fixtureId,
  marketId,
  marketName,
  market,
  bet,
  selected,
  drawSymbol,
}: Props) {
  const timer = useRef<NodeJS.Timeout>(null)
  const [currentBet, setCurrentBet] = useState<Schema$MarketBet>(_.cloneDeep(bet))
  const [updated, setUpdated] = useState<string>('')

  const updateField = (newBet: Schema$MarketBet, type: UPDATE_FIELD_TYPE) => {
    if (currentBet.ClientPrice === newBet.ClientPrice && currentBet.Status === newBet.Status) {
      return
    }

    let updated = ''
    if (type !== 'silent') {
      const oldPrice = Number(currentBet.ClientPrice)
      const newPrice = Number(newBet.ClientPrice)

      if (oldPrice > newPrice) {
        updated = 'dec'
      } else if (oldPrice < newPrice) {
        updated = 'inc'
      }
    }

    if (updated) {
      if (timer.current) {
        clearTimeout(timer.current)
        timer.current = null
      }
      timer.current = setTimeout(() => {
        setUpdated('')
      }, 10000)
    }

    setCurrentBet(_.cloneDeep(newBet))
    setUpdated(updated)
  }

  useEffect(() => {
    updateField(bet, 'update')
  }, [bet])

  const onClick = () => {
    sportsStore.toggleCartItem(fixtureId, marketId, currentBet.Name, currentBet.BaseLine)
    // TEST
    // sportsStore.test(fixtureId, marketId, currentBet.Name, currentBet.BaseLine, 'test')
  }

  let sxUpdated = {}
  if (updated) {
    sxUpdated = {
      border: 1,
      borderColor: '#2a5280',
    }
  }

  const reversed = REVERSE_ATOM_NAMES.indexOf(atomName) >= 0

  let updatedContentsPrefix = null
  let updatedContentsSuffix = null

  if (updated === 'dec') {
    if (reversed) {
      updatedContentsSuffix = <CornerMark className="down prefix"></CornerMark>
    } else {
      updatedContentsPrefix = <CornerMark className="down suffix"></CornerMark>
    }
  } else if (updated === 'inc') {
    if (reversed) {
      updatedContentsSuffix = <CornerMark className="up prefix"></CornerMark>
    } else {
      updatedContentsPrefix = <CornerMark className="up suffix"></CornerMark>
    }
  }

  const a = name ? (
    <Typography key="a" sx={{ color: '#d3d3d3', fontWeight: 'bold' }}>
      {name}
    </Typography>
  ) : null
  let b = null
  let symbolPrefix = null
  let symbolSuffix = null
  if (drawSymbol) {
    if (unifiedName === 'HDP') {
      if (atomName === 'a') {
        symbolPrefix = (
          <Box sx={{ mr: '5px', display: 'flex' }}>
            <img src={iconH} style={{ height: '12px' }} />
          </Box>
        )
      } else if (atomName === 'b') {
        symbolSuffix = (
          <Box sx={{ ml: '6px', display: 'flex' }}>
            <img src={iconH} style={{ height: '12px' }} />
          </Box>
        )
      }
    } else if (unifiedName === 'UO') {
      if (atomName === 'a') {
        symbolPrefix = (
          <Box sx={{ mr: '6px', mt: '-3px' }}>
            <img src={under} alt="under" style={{ height: '16px' }} />
          </Box>
        )
      } else if (atomName === 'b') {
        symbolSuffix = (
          <Box sx={{ ml: '8px', mt: '-2px' }}>
            <img src={over} alt="under" style={{ height: '16px' }} />
          </Box>
        )
      }
    }
  }

  if (!b) {
    b = (
      <Box key="b" display="flex" alignItems="center">
        {updatedContentsPrefix}
        {symbolPrefix}
        <Typography key="price" sx={{ color: getBetStatusColor(currentBet.Status) }}>
          {currentBet.ClientPrice}
        </Typography>
        {symbolSuffix}
        {updatedContentsSuffix}
      </Box>
    )
  }

  const atoms = reversed ? [b, a] : [a, b]
  const lock = bet.Status !== BET_STATUS.Open

  return (
    <>
      <Box
        onClick={() => onClick()}
        display="flex"
        alignItems="center"
        justifyContent={name ? 'space-between' : 'center'}
        sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          background: selected ? theme.sportsMatchup.oddsBoxSelected : theme.sportsMatchup.oddsBoxBg,
          cursor: 'pointer',
          pointerEvents: lock ? 'none' : 'auto',
          pl: 1.5,
          pr: 1.5,
          ...sxUpdated,
        }}
      >
        {atoms}
        {lock && (
          <LockBox>
            <LockIcon sx={{ color: '#ec801a' }}></LockIcon>
          </LockBox>
        )}
      </Box>
    </>
  )
}

export default DetailOddsBoxAtom
