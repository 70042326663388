/** @jsxImportSource @emotion/react */
import { jsx, css } from '@emotion/react'
import { useState, useEffect, useRef } from 'react'
import * as _ from 'lodash'
import { observer } from 'mobx-react'
import { Dialog, AppBar, IconButton, Box, Typography, Chip } from '@mui/material'
import { popupCenter } from '@utils/utils'
import { useIntl } from 'react-intl'
import useRootStore from '@store/useRootStore'
import { ModalKeys } from '@store/globalStore'
import { useMediaQuery } from 'react-responsive'
import { MEDIA_DESKTOP_WIDTH } from '@constants/index'

// css
import { ModalStyle } from '@styles/modal.style'
import { BasicButton } from '@styles/button.style'

// icon
import CloseIcon from '@mui/icons-material/Close'

// image
import DefaultContainer, { DefaultImages } from './d'
import MobileContainer, { MobileImages } from './m'

const NumberStyle = {
  mr: 1,
  borderRadius: 0,
  backgroundColor: '#FE3D3D',
  color: '#FFFFFF',
  height: 26,
  '.MuiChip-label': {
    padding: '0 10px',
  },
}

const PopupManualWonp: React.FC<{}> = props => {
  const intl = useIntl()
  const isDesktop = useMediaQuery({ minWidth: MEDIA_DESKTOP_WIDTH })
  const { globalStore, authStore } = useRootStore()

  const [state, setState] = useState(false)

  const { currentKey, currentValue } = globalStore
  const { initialized, signedin } = authStore
  const { desktop } = globalStore.decideMedia(isDesktop)

  const modalKey = ModalKeys.menual_wonp
  const tabIndex = currentKey === modalKey ? currentValue : ''
  const visibleOnDemand = tabIndex !== ''

  let visible = visibleOnDemand && signedin
  if (!signedin && initialized) {
    // popup login
    globalStore.showModal('login', 0)
    return null
  }

  if (visible !== state) {
    setState(visible)
    return null
  }

  function onClose() {
    globalStore.hideModal(modalKey)
  }

  const targetImages = desktop ? DefaultImages : MobileImages

  const innerContents = (
    <>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="1" />
        갤럭시 - 플레이스토어, 아이폰 - 앱스토어에서 원피를 검색하여 다운로드 해주세요.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[0]} alt="menual01" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="2" />
        접근 권한을 수락 후 원피 비밀번호를 설정합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[1]} alt="menual02" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="3" />
        원피 간편계좌를 생성합니다. '지금 생성하러 가기' 버튼을 클릭하여 간편계좌를 생성합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[2]} alt="menual03" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="4" />
        원피충전을 누르고 안심 본인인증을 완료합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[3]} alt="menual04" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="5" />
        충전 금액을 입력하고 충전신청을 누릅니다. 입금할 계좌번호에 입금하면 자동으로 원피가 충전됩니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[4]} alt="menual05" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="6" />
        원피 충전에서 환전 비밀번호를 입력하고 다음을 클릭합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[5]} alt="menual06" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="7" />
        표시된 입금계좌로 충전할 금액을 입력하고 충전 신청을 누른 후 다시 원피앱으로 이동합니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[6]} alt="menual07" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Chip component="span" sx={NumberStyle} label="8" />
        결제/송금을 누르고 정해진 계좌와 송금액을 입력하고 송금을 완료하면 게임머니가 제공됩니다.
      </Box>
      <Box sx={{ mb: 7 }}>
        <img src={targetImages[7]} alt="menual08" style={{ width: '100%' }} />
      </Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
        <BasicButton className="btn_sub" onClick={() => onClose()} sx={{ width: '300px' }}>
          닫기
        </BasicButton>
      </Box>
    </>
  )

  let contents = null
  if (desktop) {
    contents = <DefaultContainer visible={visible} onClose={onClose} innerContents={innerContents}></DefaultContainer>
  } else {
    contents = <MobileContainer visible={visible} onClose={onClose} innerContents={innerContents}></MobileContainer>
  }
  return <>{contents}</>
}

export default observer(PopupManualWonp)
