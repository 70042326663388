import React, { useState } from 'react'
import * as _ from 'lodash'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PrematchFixture from '../PrematchFixture/d'
import { Schema$Prematch } from '~/v2/interface/st-schema'
import urlJoin from 'url-join'
import { useTheme } from '@mui/material/styles'
import DesktopPrematchLeagueBox from './d'
import MobilePrematchLeagueBox from './m'
import { PrematchData, SPORT_FLIGHT } from '~/store/sportsStore/sports-util'

interface Props {
  desktop: boolean
  virtualDesktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  items: PrematchData[]
}

function PrematchLeagueBox({ desktop, virtualDesktop, lang, flight, items }: Props) {
  return (
    <>
      {virtualDesktop ? (
        <DesktopPrematchLeagueBox lang={lang} flight={flight} items={items}></DesktopPrematchLeagueBox>
      ) : (
        <MobilePrematchLeagueBox desktop={desktop} lang={lang} flight={flight} items={items}></MobilePrematchLeagueBox>
      )}
    </>
  )
}

export default PrematchLeagueBox
