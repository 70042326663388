export const SPORT_ID = {
  None: 0,
  BeachVolleyball: 621569,
  TableTennis: 265917,
  Futsal: 687887,
  HorseRacing: 687888,
  Golf: 687889,
  EGames: 687890,
  Greyhounds: 687893,
  Trotting: 687894,
  Speedway: 687895,
  Football: 6046,
  Basketball: 48242,
  MotoGP: 687896,
  Chess: 687897,
  Volleyball: 154830,
  IceHockey: 35232,
  Cricket: 452674,
  EquineSports: 291987,
  AustralianRules: 389537,
  Badminton: 1149093,
  Formula1: 1149094,
  Nascar: 1149095,
  Hockey: 530129,
  Supercars: 1149096,
  Netball: 1149097,
  Surfing: 1149098,
  Cycling: 1149099,
  GaelicSports: 1149100,
  Biathlon: 1149101,
  Motorbikes: 1149102,
  Athletics: 1149103,
  Squash: 1149104,
  Basketball3X3: 1149105,
  Floorball: 35706,
  Sumo: 1149107,
  Virtualsports: 1149108,
  Handball: 35709,
  Politics: 1149109,
  Weather: 1149110,
  TVGames: 1149111,
  Lottery: 1149112,
  Bowls: 1149113,
  Poker: 1149114,
  Waterpolo: 388764,
  AlpineSkiing: 261354,
  Sailing: 1149115,
  Hurling: 1149117,
  SkiJumping: 1149118,
  Indy: 1149119,
  Lacrosse: 1149120,
  Olympics: 1149121,
  Softball: 1149122,
  Bandy: 46957,
  Kabaddi: 1149123,
  MotorSports: 165874,
  Baseball: 154914,
  AUDL: 1149124,
  RugbyUnion: 274791,
  RugbyLeague: 274792,
  Curling: 307126,
  Boxing: 154919,
  Darts: 154923,
  Tennis: 54094,
  AmericanFootball: 131506,
  Snooker: 262622,
}

export const PACKAGE_SPORT_ID = [
  SPORT_ID.Football,
  SPORT_ID.Basketball,
  SPORT_ID.Baseball,
  SPORT_ID.Volleyball,
  SPORT_ID.IceHockey,
]

export const PACKAGE_BOOKMAKER = [
  {
    Id: 4,
    Name: 'Pinnacle',
  },
  {
    Id: 8,
    Name: 'Bet365',
  },
]

export const PACKAGE_BOOKMAKER_ID = {
  Pinnacle: 4,
  Bet365: 8,
}

export const INTERESTING_SPORT_ID = [
  SPORT_ID.Football,
  SPORT_ID.Basketball,
  SPORT_ID.Baseball,
  SPORT_ID.Volleyball,
  SPORT_ID.IceHockey,
]

export const MessageType = {
  Fixture_Metadata_Update: 1,
  Livescore_Update: 2,
  Market_Update: 3,
  Keep_Alive: 31,
  Heartbeat: 32,
  Settlements: 35,
  Snapshot: 36,
  OutrightFixture: 37,
  OutrightLeague: 38,
  OutrightScore: 39,
  OutrightLeagueMarket: 40,
  OutrightFixtureMarket: 41,
  OutrightSettlements: 42,
}

export const SUBSCRIBED_PACKAGE_TYPE = {
  InPlay: 1,
  PreMatch: 2,
}

export const SUBSCRIBED_INPLAY_STATUS = {
  Subscribed: 1,
  Pending: 2,
  Unsubscribed: 3,
  Deleted: 4,
}

export const BET_SUSPENSION_REASON = {
  Providers: 1,
  Majority: 3,
  Threshold: 4,
  MandatoryProviders: 5,
  LivescoreInconsistency: 6,
  OutOfPriceRange: 7,
  LineTypeRemoval: 8,
  OutOfLineRange: 9,
  FixtureStatus: 10,
  MainLineOnly: 11,
  DataLimit: 12,
  Outlier: 13,
  SettingsChange: 14,
}

export const STATUS_DESCRIPTION = {
  Halftime: 1,
  OvertimeHalftime: 2,
  HomeParticipantHasRetired: 3,
  AwayParticipantHasRetired: 4,
  CoverageForThisEventHasBeenLost: 5,
  MedicalTimeout: 6,
  TimeoutOfHometeam: 7,
  TimeoutOfAwayTeam: 8,
  Timeout: 9,
  HomeWalkover: 10,
  AwayWalkover: 11,
}

export const FIXTURE_STATUS = {
  NotStartedYet: 1, // 대기
  InProgress: 2, // 게임중
  Finished: 3, // 종료
  Canceled: 4, // 취소(적특)
  Postponed: 5, // 연기(적특)
  Interrupted: 6, // 중단(다음 status로)
  Abandoned: 7, // 폐기(적특)
  CoverageLost: 8, // 적용범위 상실(다음 status로)
  AboutToStart: 9, // 곧 시작
}

// 각 벳의 결과 처리가 될수 있는 FIXTURE_STATUS 리스트
export const FIXTURE_STATUS_OUTCOME = [
  FIXTURE_STATUS.InProgress,
  FIXTURE_STATUS.Finished,
  FIXTURE_STATUS.Canceled,
  FIXTURE_STATUS.Postponed,
  FIXTURE_STATUS.Abandoned,
]

export const FIXTURE_STATUS_OUTCOME_RESTRICTED = [
  FIXTURE_STATUS.Finished,
  FIXTURE_STATUS.Canceled,
  FIXTURE_STATUS.Postponed,
  FIXTURE_STATUS.Abandoned,
]

export const BET_STATUS = {
  Open: 1,
  Suspended: 2,
  Settled: 3,
}

export const BET_SETTLEMENT = {
  Canceled: -1,
  Loser: 1,
  Winner: 2,
  Refund: 3,
  HalfLost: 4,
  HalfWon: 5,
}

export const UNIFIED_MARKET_NAME = {
  '12': ['12', '12 Including Overtime'],
  '1X2': ['1X2', '1X2 Including Overtime'],
  HDP: [
    'Asian Handicap',
    'Asian Handicap Including Overtime',
    'Asian Handicap Sets', // volleyball
  ],
  UO: [
    'Asian Under/Over',
    'Asian Under/Over Including Overtime',
    'Under/Over Sets', // volleyball
    'Under/Over', // basketball, baseball, icehockey
    'Under/Over Including Overtime', // basketball, baseball, icehockey
  ],
}

// 경기가 정상적으로 끝났을때 결과가 셋팅되는 마켓
// with fixture status FINISHED
export const SETTLED_MARKET_TYPE = [
  { id: 1, market_name: '1X2' },
  { id: 50, market_name: '1X2 Including Overtime' },
  { id: 52, market_name: '12' },
  { id: 226, market_name: '12 Including Overtime' },
  { id: 3, market_name: 'Asian Handicap' },
  { id: 342, market_name: 'Asian Handicap Including Overtime' },
  { id: 835, market_name: 'Asian Under/Over' },
  { id: 1061, market_name: 'Asian Under/Over Including Overtime' },
  { id: 2, market_name: 'Under/Over' },
  { id: 28, market_name: 'Under/Over Including Overtime' },
  // set game such as ice hockey
  { id: 866, market_name: 'Asian Handicap Sets' },
  { id: 165, market_name: 'Under/Over Sets', ko: '총 득점' },
]

export const SETTLED_MARKET_ID = [1, 50, 52, 226, 3, 342, 835, 1061, 2, 28, 866, 165]
export const SETTLED_MARKET_IDS = [
  '1',
  '50',
  '52',
  '226',
  '3',
  '342',
  '835',
  '1061',
  '2',
  '28',
  '866',
  '165',
]

export const SUPPORT_PREMATCH_MARKETS = {
  // 축구
  '6046': [
    1,
    50,
    52,
    226,
    3,
    342,
    835,
    1016,
    2,
    28,
    41,
    202,
    64,
    836,
    153,
    155,
    129,
    134,
    145,
    146,
    113,
    9,
    72,
    456,
    73,
    95,
    11,
    101,
    102,
    221,
    220,
    149,
    150,
    17,
    23,
    22,
    198,
    199,
    97,
    96,
    4,
    5,
    6,
    7,
    128,
    239,
    16,
  ],
  // 농구
  '48242': [1, 50, 52, 226, 3, 342, 835, 1061, 2, 28, 63, 53, 77, 354, 355, 101, 102, 221, 220],
  // 야구
  '154914': [1, 50, 52, 226, 3, 342, 835, 1061, 2, 28, 281, 236, 2644, 2645, 101, 102, 221, 220],
  // 배구
  '154830': [1, 50, 52, 226, 866, 165, 202, 64, 21, 153, 155],
  // 아이스하키
  '35232': [1, 50, 52, 226, 3, 342, 835, 1061, 2, 28, 202, 64, 21, 153, 155, 101, 102, 221, 220],
}

export const SUPPORT_INPLAY_MARKETS = {
  // 축구
  '6046': [
    64,
    836,
    3,
    342,
    835,
    1061,
    1552,
    305,
    11,
    880,
    129,
    820,
    579,
    81,
    59,
    56,
    5,
    17,
    6,
    41,
    21,
    9,
    42,
  ],
  // 농구
  '48242': [
    51,
    226,
    69,
    342,
    28,
    221,
    220,
    354,
    355,
    41,
    21,
    64,
    72,
    317,
    731,
    735,
    42,
    45,
    65,
    73,
    318,
    732,
    736,
    154,
    156,
    43,
    46,
    66,
    74,
    319,
    733,
    737,
    223,
    222,
    44,
    47,
    67,
    75,
    320,
    734,
    738,
    287,
    288,
    282,
    62,
    53,
    77,
  ],
  // 야구
  '154914': [
    226,
    342,
    28,
    69,
    563,
    322,
    602,
    41,
    42,
    43,
    44,
    49,
    348,
    434,
    21,
    45,
    46,
    47,
    48,
    352,
    353,
    436,
    221,
    220,
    505,
    506,
    507,
    508,
    509,
    510,
    511,
    512,
    514,
    515,
    516,
    517,
    518,
    520,
    521,
  ],
  // 배구
  '154830': [
    52,
    3,
    2,
    5,
    6,
    101,
    102,
    202,
    64,
    21,
    72,
    329,
    203,
    65,
    45,
    73,
    330,
    204,
    66,
    46,
    74,
    331,
    205,
    67,
    47,
    75,
    332,
  ],
  // 아이스하키
  '35232': [
    52,
    226,
    342,
    1061,
    28,
    835,
    69,
    51,
    41,
    202,
    64,
    21,
    836,
    9,
    727,
    42,
    203,
    65,
    45,
    1053,
    100,
    728,
    43,
    204,
    66,
    46,
    1054,
    217,
    729,
    5,
    341,
    59,
    56,
    6,
    602,
    128,
    149,
    150,
  ],
}
