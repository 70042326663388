import { jsx, css, ThemeProvider, Global } from '@emotion/react'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { Button, Container, Card, Grid, MenuItem, Typography, CardContent } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { GlobalStyle } from '@shared/Common'
import {
  getMoneyPanelColumns,
  NeatTextField,
} from '@views/Partner/PartnerIntergratedMember/index.component'
import { numberWithCommas } from '@utils/utils'
import { useIntl } from 'react-intl'
import { MERCHANT_CLASS, USER_STATE } from '~/v2/interface/config'
import { GetFilteredBankCode } from '~/constants/attrs'
import useRootStore from '~/store/useRootStore'
import apiPartner from '@services/api/partner'
import * as GameUtil from '@game/util'
import WhiteTheme from '@themes/white.theme'

// css
import { FormStyle } from '@styles/modal.style'

const BANK_CODES = GetFilteredBankCode()

const TypographyName = styled(Typography)({
  height: '100%',
  paddingTop: '6px',
})

function PartnerCreateMember() {
  const intl = useIntl()
  const { userStore, authStore, globalStore } = useRootStore()
  const { userInfo } = userStore

  const defaultReferer = userInfo ? userInfo.id || '' : ''
  const defaultState = 'WAITING'
  const defaultMerchantClass = Math.min(userInfo.merchant_class + 1, 5)
  let defaultOddsType = ''

  useEffect(() => {
    // fetch user data
    ;(async function inline() {
      const recovered = await authStore.recoverSession(intl)
      if (!recovered) {
        globalStore.pushDialogOk({
          title: intl.formatMessage({ id: 'error' }),
          text: intl.formatMessage({ id: 'msg.no_login_info' }),
          callbackPositive: () => {
            window.close()
          },
        })
      }
    })()
  }, [])

  const submit = async (values: any) => {
    try {
      const {
        merchantClass,
        username,
        password,
        password2,
        nickname,
        bankCode,
        accountHolder,
        accountNumber,
        transferPassword,
        phoneNumber,
        oddsType,
      } = values
      if (password !== password2) {
        globalStore.pushDialogOk({
          text: intl.formatMessage({ id: 'msg.required.confirm-password' }),
        })
        return
      }
      const browserData = GameUtil.makeBrowserData()
      await apiPartner.createMember({
        merchantClass: Number(merchantClass),
        username: username.trim(),
        password: password.trim(),
        nickname: nickname.trim(),
        bankCode,
        accountHolder: accountHolder.trim(),
        accountNumber: accountNumber.trim(),
        transferPassword: transferPassword.trim(),
        phoneNumber,
        oddsType: oddsType,
        browserData: browserData,
      })
      globalStore.pushDialogOk({
        text: intl.formatMessage({ id: 'msg.member_created' }),
        callbackPositive: () => {
          window.close()
        },
      })
    } catch (err) {
      globalStore.pushErrorObject(err, intl)
    }
  }

  const filterClassItems = []
  for (let i = 0; i < MERCHANT_CLASS.length; i++) {
    if (i > userInfo.merchant_class || i === 5) {
      filterClassItems.push(
        <option value={i} key={MERCHANT_CLASS[i]}>
          {MERCHANT_CLASS[i]}
        </option>,
      )
    }
  }

  const filterStateItems = []
  for (const el of USER_STATE) {
    filterStateItems.push(
      <MenuItem value={el.id} key={el.id}>
        {el.name}
      </MenuItem>,
    )
  }

  const bankContents = []
  for (const el of BANK_CODES) {
    bankContents.push(
      <option value={`${el.code}`} key={el.code}>
        {el.name}
      </option>,
    )
  }

  return (
    <ThemeProvider theme={WhiteTheme}>
      <Global styles={GlobalStyle} />
      <Container>
        <Card variant="outlined" sx={{ my: '10px', background: '#e5f6fd' }}>
          <CardContent>
            <br />
            <br />
            <Formik
              enableReinitialize
              initialValues={{
                merchantClass: defaultMerchantClass,
                username: '',
                password: '',
                password2: '',
                nickname: '',
                bankCode: '',
                accountHolder: '',
                accountNumber: '',
                transferPassword: '',
                phoneNumber: '',
                oddsType: defaultOddsType,
              }}
              onSubmit={submit}
              validationSchema={Yup.object().shape({
                username: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
                password: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
                password2: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
                nickname: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
                accountHolder: Yup.string().required(
                  intl.formatMessage({ id: 'msg.field-require' }),
                ),
                accountNumber: Yup.string().required(
                  intl.formatMessage({ id: 'msg.field-require' }),
                ),
                transferPassword: Yup.string().required(
                  intl.formatMessage({ id: 'msg.field-require' }),
                ),
                phoneNumber: Yup.string().required(intl.formatMessage({ id: 'msg.field-require' })),
              })}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormStyle>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container spacing={1} rowSpacing={1}>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%', pt: '6px' }}>
                              상위 아이디
                            </TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <NeatTextField
                              value={defaultReferer}
                              InputProps={{ readOnly: true }}
                              sx={{ background: '#eceff2', width: '100%' }}
                            />
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%', pt: '8px' }}>
                              회원 상태
                            </TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <NeatTextField
                              value={defaultState}
                              InputProps={{ readOnly: true }}
                              sx={{ background: '#eceff2', width: '100%' }}
                              select
                            >
                              {filterStateItems}
                            </NeatTextField>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName>생성 등급</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <select
                              className="form_control"
                              name="merchantClass"
                              value={values.merchantClass}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              {filterClassItems}
                            </select>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%' }}>아이디*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              className="form_control"
                              type="text"
                              name="username"
                              placeholder={intl.formatMessage({ id: 'signup.field-id' })}
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                              style={{ width: '100%' }}
                            />
                            {touched.username && errors.username && (
                              <Typography sx={{ color: '#FE3D3D' }}>{errors.username}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%' }}>닉네임*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              className="form_control"
                              type="text"
                              name="nickname"
                              placeholder={intl.formatMessage({ id: 'signup.field-nickname' })}
                              value={values.nickname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={8}
                            />
                            {touched.nickname && errors.nickname && (
                              <Typography sx={{ color: '#FE3D3D' }}>{errors.nickname}</Typography>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <TypographyName sx={{ height: '100%' }}>비밀번호*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <input
                                  className="form_control"
                                  type="password"
                                  name="password"
                                  placeholder={intl.formatMessage({ id: 'signup.field-password' })}
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  minLength={6}
                                  maxLength={16}
                                />
                                {touched.password && errors.password && (
                                  <Typography sx={{ color: '#FE3D3D' }}>
                                    {errors.password}
                                  </Typography>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <input
                                  className="form_control"
                                  type="password"
                                  name="password2"
                                  placeholder={intl.formatMessage({ id: 'signup.field-password' })}
                                  value={values.password2}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  minLength={6}
                                  maxLength={16}
                                />
                                {touched.password2 && errors.password2 && (
                                  <Typography sx={{ color: '#FE3D3D' }}>
                                    {errors.password2}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%' }}>은행*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={6}>
                                <select
                                  value={values.bankCode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="bankCode"
                                  className="form_control"
                                >
                                  <option value="" disabled>
                                    은행을 선택해주세요
                                  </option>
                                  {bankContents}
                                </select>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <input
                                  className="form_control"
                                  type="text"
                                  name="accountHolder"
                                  placeholder={intl.formatMessage({ id: 'signup.field-name' })}
                                  value={values.accountHolder}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={32}
                                />
                                {touched.accountHolder && errors.accountHolder && (
                                  <Typography sx={{ color: '#FE3D3D' }}>
                                    {errors.accountHolder}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%' }}>계좌번호*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              className="form_control"
                              type="text"
                              name="accountNumber"
                              placeholder={intl.formatMessage({ id: 'signup.field-account' })}
                              value={values.accountNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={32}
                            />
                            {touched.accountNumber && errors.accountNumber && (
                              <Typography sx={{ color: '#FE3D3D' }}>
                                {errors.accountNumber}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%' }}>휴대폰번호*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              className="form_control"
                              type="text"
                              name="phoneNumber"
                              placeholder={intl.formatMessage({ id: 'signup.field-phonenumber' })}
                              value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={11}
                            />
                            {touched.phoneNumber && errors.phoneNumber && (
                              <Typography sx={{ color: '#FE3D3D' }}>
                                {errors.phoneNumber}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <TypographyName sx={{ height: '100%' }}>환전 비밀번호*</TypographyName>
                          </Grid>
                          <Grid item xs={8}>
                            <input
                              className="form_control"
                              type="password"
                              name="transferPassword"
                              placeholder={intl.formatMessage({ id: 'signup.field-password' })}
                              value={values.transferPassword}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={16}
                            />
                            {touched.transferPassword && errors.transferPassword && (
                              <Typography sx={{ color: '#FE3D3D' }}>
                                {errors.transferPassword}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={4}></Grid>
                          <Grid
                            item
                            xs={8}
                            display="flex"
                            sx={{ flexDirection: 'column', justify: 'center', marginTop: '20px' }}
                          >
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              variant="contained"
                              style={{ background: '#007f93' }}
                            >
                              회원 생성
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormStyle>
                </form>
              )}
            </Formik>
            <br />
            <br />
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  )
}

export default observer(PartnerCreateMember)
