import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Box, Divider, Typography } from '@mui/material'
import { getMarketArrangedFromList, getTeamName } from '~/game/st-util'
import moment from 'moment'
import useRootStore from '~/store/useRootStore'
import { observer } from 'mobx-react'
import { useTheme } from '@mui/material/styles'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ModalKeys } from '~/store/globalStore'
import MarketBoxMoneyline from '../PrematchOdds/MarketBoxMoneyline'
import MarketBoxBaseline from '../PrematchOdds/MarketBoxBaseline'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { PrematchData, SPORT_FLIGHT, UPDATE_FIELD_TYPE } from '~/store/sportsStore/sports-util'
import { Schema$Market, Schema$Prematch } from '~/v2/interface/st-schema'
import { useOnMount, useOnUnmount } from '~/utils/reactExt'

interface Props {
  desktop: boolean
  lang: string
  flight: SPORT_FLIGHT
  item: PrematchData
  sx?: any
}

function MobilePrematchFixture({ desktop, lang, flight, item, sx }: Props) {
  const theme = useTheme()
  const { globalStore, sportsStore } = useRootStore()
  const [timestamp, setTimestamp] = useState<number>(0)

  const { prematch, markets } = item

  const updatePrematch = (updateMarket: Schema$Prematch, type: UPDATE_FIELD_TYPE) => {
    setTimestamp(new Date().getTime())
  }

  const updateMarket = (updateMarket: Schema$Market, type: UPDATE_FIELD_TYPE) => {
    setTimestamp(new Date().getTime())
  }

  useOnMount(() => {
    const { FixtureId } = item.prematch
    sportsStore.addFixtureController(FixtureId, updatePrematch)
    sportsStore.addUnifiedController(FixtureId, '1X2', updateMarket)
    sportsStore.addUnifiedController(FixtureId, '12', updateMarket)
    sportsStore.addUnifiedController(FixtureId, 'HDP', updateMarket)
    sportsStore.addUnifiedController(FixtureId, 'UO', updateMarket)
  })

  useOnUnmount(() => {
    const { FixtureId } = item.prematch
    sportsStore.deleteFixtureController(FixtureId)
    sportsStore.deleteUnifiedController(FixtureId, '1X2')
    sportsStore.deleteUnifiedController(FixtureId, '12')
    sportsStore.deleteUnifiedController(FixtureId, 'HDP')
    sportsStore.deleteUnifiedController(FixtureId, 'UO')
  })

  const handleMoreOdds = () => {
    sportsStore.setSelectedFixture(item.prematch.FixtureId)
    globalStore.showModal(ModalKeys.sport_market, 0)
  }

  const { options } = sportsStore
  const { sports_configs } = options || {}
  const baseline_config = _.find(sports_configs, o => {
    return o.sport_id === prematch.SportId
  })

  const homeName = getTeamName(prematch, '1', lang)
  const awayName = getTeamName(prematch, '2', lang)

  const startDate = new Date(prematch.StartDate)
  let dateOfMatch = moment(startDate).format('MM-DD')
  const timeOfMatch = moment(startDate).format('HH:mm')

  let bet1X2 = null
  let betHdp = null
  let betUnderOver = null
  let addonSize = 0

  if (markets) {
    const disabledMarkets = _.filter(options.disabled_markets, o => {
      return o.sportId === prematch.SportId
    })
    const filteredMarket = _.filter(markets, o => {
      return (
        _.findIndex(disabledMarkets, dm => {
          return dm.marketId === o.Id
        }) < 0
      )
    })

    addonSize = filteredMarket.length

    const unifiedNames = ['1X2', '12']
    for (const el of unifiedNames) {
      const market = getMarketArrangedFromList(filteredMarket, el, 0, false)
      if (market.top) {
        const oddsProps = {
          theme,
          desktop: false,
          lang,
          flight,
          prematch,
          market: market.top,
          unifiedName: el,
        }
        bet1X2 = <MarketBoxMoneyline {...oddsProps}></MarketBoxMoneyline>
        addonSize -= 1
        break
      }
    }

    const marketHdp = getMarketArrangedFromList(
      filteredMarket,
      'HDP',
      baseline_config?.baseline_size_hdp,
      baseline_config?.enabled_hdp_baseline_zero,
    )
    if (marketHdp.top) {
      const oddsProps = {
        theme,
        desktop: false,
        lang,
        flight,
        prematch: prematch,
        arranged: marketHdp,
        unifiedName: 'HDP',
      }
      betHdp = <MarketBoxBaseline {...oddsProps}></MarketBoxBaseline>
      addonSize -= 1
    }

    const marketUnderOver = getMarketArrangedFromList(
      filteredMarket,
      'UO',
      baseline_config?.baseline_size_uo,
      baseline_config?.enabled_uo_baseline_zero,
    )
    if (marketUnderOver.top) {
      const oddsProps = {
        theme,
        desktop: false,
        lang,
        flight,
        prematch: prematch,
        arranged: marketUnderOver,
        unifiedName: 'UO',
      }
      betUnderOver = <MarketBoxBaseline {...oddsProps}></MarketBoxBaseline>
      addonSize -= 1
    }
  }

  const showAddonSize = flight === 'europe' && addonSize > 0

  return (
    <Box
      textAlign={{ xs: 'center', md: 'left' }}
      justifyContent="space-between"
      sx={{ background: theme.sportsMatchup.fixtureBg, borderRadius: '5px', ...sx, p: 1 }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              fontSize: '14px',
              color: '#838485',
              fontWeight: 'bold',
            }}
          >
            {dateOfMatch}
          </Typography>
          <Typography
            sx={{
              marginLeft: '6px',
              fontSize: '14px',
              color: '#d6d6d6',
              fontWeight: 'bold',
            }}
          >
            {timeOfMatch}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end" sx={{ width: '60px' }}>
          {showAddonSize && (
            <Box
              onClick={() => handleMoreOdds()}
              display="flex"
              sx={{
                cursor: 'pointer',
                ':hover .child': {
                  color: '#f85300',
                },
              }}
            >
              <Typography
                className="child"
                sx={{
                  color: 'white',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  mt: '4px',
                }}
              >
                +{addonSize}
              </Typography>
              <ChevronRightIcon
                className="child"
                sx={{
                  color: 'white',
                  fontSize: '24px',
                  mt: '3px',
                  ml: '-3px',
                }}
              ></ChevronRightIcon>
            </Box>
          )}
        </Box>
      </Box>
      <Divider variant="fullWidth" sx={{ mt: '8px', mb: '8px', color: '#888888' }}></Divider>
      <CopyToClipboard text={prematch.FixtureId}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ color: '#d6d6d6', fontSize: '14px' }}>{homeName}</Typography>
          <Typography sx={{ color: '#838485', fontSize: '14px' }}>&nbsp;vs&nbsp;</Typography>
          <Typography sx={{ color: '#d6d6d6', fontSize: '14px' }}>{awayName}</Typography>
        </Box>
      </CopyToClipboard>
      <Box sx={{ width: '100%', mt: 1 }}>
        <Box>{bet1X2}</Box>
        <Box sx={{ mt: '4px' }}>{betHdp}</Box>
        <Box sx={{ mt: '4px' }}>{betUnderOver}</Box>
      </Box>
    </Box>
  )
}

export default observer(MobilePrematchFixture)
