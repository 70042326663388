/** @jsxImportSource @emotion/react */
import { jsx, css, Theme } from '@emotion/react'
import React, { useEffect } from 'react'
import * as _ from 'lodash'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import { Container, Button, Grid, Box, Typography } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import useRootStore from '@store/useRootStore'
import { useOnUnmount } from '~/utils/reactExt'
import { useHistory } from 'react-router-dom'
import { AllSlotGames } from '~/game/slot_data'
import { AllCaGames } from '~/game/ca_data'
import { AllMiniGames } from '~/game/mg_data'
import { splitOpenTradingKinds } from '~/game/util'
import { AllReelGames } from '~/game/reel_data'
import { STAR_GAME_ID, STAR_GAME_INFO } from '~/v2/interface/types'

import Jackpot from './Jackpot'
import NoticeBox from './NoticeBox'
import RealtimeDepositBox from './RealtimeDepositBox'
import RealtimeWithdrawBox from './RealtimeWithdrawBox'
import TopBanner from './TopBanner'

// icon
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

//css
import { HomeContent, SnsLink } from './index.style'

// image
import {
  imgTelegram,
  imgKakaotalk,
  gameTitle01,
  gameTitle02,
  gameTitle03,
  gameTitle04,
  gameTitle05,
  gameTitle06,
  gameTitle07,
  gameTitle08,
  gameTitle09,
  gameTitle10,
  gameTitle11,
  gameTitle12,
  gameTitle13,
  gameTitle14,
  gameTitle15,
  gameTitle16,
  gameTitle17,
  gameTitle18,
  gameTitle19,
  gameTitle20,
  gameTitle21,
} from '@assets/images/layout_1'

const MainBanner = css`
  min-width: 1248px;
  width: 100%;
  //overflow: hidden;

  & > div {
    display: block;
    width: 100%;
    margin: auto;
    position: relative;
    height: 390px;
  }

  & > div > img {
    position: absolute;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
`
const MainBannerTitle = css`
  position: relative;
  padding-top: 13%;
  font-weight: bold;
  font-size: 2.6rem;
  color: #fff;
  text-shadow: #000 1px 1px 5px, #000 1px 1px 5px, #000 1px 1px 5px;
`

const APP_TITLE = process.env.REACT_APP_TITLE || 'Unknown'

const SEASTORY_INFO = _.find(STAR_GAME_INFO, o => {
  return o.gameId === STAR_GAME_ID.SEASTORY
})

function DefaultHome() {
  const intl = useIntl()
  const history = useHistory()
  const { globalStore, authStore } = useRootStore()
  const { option } = globalStore
  const { mainTitle, htmlMainTitleDesktop, contact } = option || {}
  const { signedin } = authStore

  const { open_trading_kinds, showDepositWithdrawBox } = option || {}

  useEffect(() => {
    globalStore.getPublicSettings(intl)
  }, [])

  useOnUnmount(() => {
    globalStore.setNavChange('')
  })

  let kakaotalk = ''
  let telegram = ''
  if (contact) {
    kakaotalk = contact.kakaotalk
    telegram = contact.telegram
  }

  const onClickGameCard = (group: string, kind: string) => {
    if (group === 'REEL') {
      globalStore.signedinNewGame(group, kind, signedin, intl)
      return
    }
    globalStore.leadToGame(group, kind, history, signedin, intl)
  }

  const onClickMoreCasino = () => {
    history.push('/live-casino')
    globalStore.setNavChange('/live-casino')
  }

  const onClickMoreSlot = () => {
    history.push('/slot')
    globalStore.setNavChange('/slot')
  }

  const onClickMoreMinigame = () => {
    history.push('/minigame')
    globalStore.setNavChange('/minigame')
  }

  const caContents = []
  const slContents = []
  const mgContents = []
  const mslContents = []

  const { lca, hca, sl, reel, mg, sp } = splitOpenTradingKinds(open_trading_kinds, null)

  // ca
  {
    const caOrdered = []
    const tmp = []
    tmp.push(...lca.slice(0, 3))
    tmp.push(...hca)
    tmp.push(...lca.slice(3, lca.length - 1))
    caOrdered.push(...tmp.slice(0, 4))
    for (const trading of caOrdered) {
      const el = _.find(AllCaGames, o => {
        return o.kind === trading.trading_kind
      })
      if (el) {
        caContents.push(
          <Grid item key={el.kind} xs={3}>
            <a onClick={() => onClickGameCard(trading.trading_group, trading.trading_kind)}>
              <img src={el.thumbnail} alt={el.kind} style={{ width: '100%' }} />
              <p className="marketing_banner_text">{trading.name}</p>
            </a>
          </Grid>,
        )
      }
    }
  }

  const arr = reel.slice(0, 1)
  const merged = arr.concat(sl)

  // slot
  for (const trading of merged) {
    let el = _.find(AllSlotGames, o => {
      return o.kind === trading.trading_kind
    })
    if (!el) {
      el = _.find(AllReelGames, o => {
        return o.kind === trading.trading_kind
      })
    }
    if (el) {
      let name = trading.name
      if (trading.trading_kind.indexOf('STAR_SEASTORY') >= 0) {
        name = SEASTORY_INFO?.title || name
      }
      slContents.push(
        <Grid item xs={3} key={el.kind}>
          <a onClick={() => onClickGameCard(trading.trading_group, trading.trading_kind)}>
            <img src={el.thumbnail} alt={el.kind} style={{ width: '100%' }} />
            <p className="marketing_banner_text">{name}</p>
          </a>
        </Grid>,
      )
    }
    if (slContents.length >= 4) {
      break
    }
  }

  // minigame
  for (const trading of mg) {
    const el = _.find(AllMiniGames, o => {
      return o.kind === trading.trading_kind
    })
    if (el) {
      mgContents.push(
        <Grid item xs={3} key={el.kind}>
          <a onClick={() => onClickGameCard(trading.trading_group, trading.trading_kind)}>
            <img src={el.thumbnail} alt={el.kind} style={{ width: '100%' }} />
            <p className="marketing_banner_text">{trading.name}</p>
          </a>
        </Grid>,
      )
    }
    if (mgContents.length >= 4) {
      break
    }
  }

  const contents = (
    <>
      {caContents.length > 0 ? (
        <Box className="marketing_banner">
          <Box className="game_title">
            <Typography>{intl.formatMessage({ id: 'menu.header.live-casino' })}</Typography>
            <button type="button" onClick={() => onClickMoreCasino()} className="more">
              <Typography component="span">{intl.formatMessage({ id: 'more' })}</Typography>
              <ArrowForwardIosIcon sx={{ width: '14px' }} />
            </button>
          </Box>
          {caContents.length > 0 ? (
            <Grid container spacing={2}>
              {caContents}
            </Grid>
          ) : null}
        </Box>
      ) : null}
      {slContents.length > 0 ? (
        <Box className="marketing_banner">
          <Box className="game_title">
            <Typography>{intl.formatMessage({ id: 'menu.header.slot' })}</Typography>
            <button type="button" onClick={() => onClickMoreSlot()} className="more">
              <Typography component="span">{intl.formatMessage({ id: 'more' })}</Typography>
              <ArrowForwardIosIcon sx={{ width: '14px' }} />
            </button>
          </Box>
          {slContents.length > 0 ? (
            <Grid container spacing={2}>
              {slContents}
            </Grid>
          ) : null}
        </Box>
      ) : null}
      {mgContents.length > 0 ? (
        <Box className="marketing_banner">
          <Box className="game_title">
            <Typography>{intl.formatMessage({ id: 'menu.header.minigame' })}</Typography>
            <button type="button" onClick={() => onClickMoreMinigame()} className="more">
              <Typography component="span">{intl.formatMessage({ id: 'more' })}</Typography>
              <ArrowForwardIosIcon sx={{ width: '14px' }} />
            </button>
          </Box>
          {mgContents.length > 0 ? (
            <Grid container spacing={2}>
              {mgContents}
            </Grid>
          ) : null}
        </Box>
      ) : null}
    </>
  )

  const onClickOpenTelegram = id => {
    // @를 제외한 아이디
    window.open(`https://t.me/${id}`)
  }

  return (
    <>
      <HomeContent>
        <div css={MainBanner}>
          <div>
            {/* <img src={asset_channel.common.img_sample_main_banner01} alt="main banner" />
            <div css={MainBannerTitle}>
              {htmlMainTitleDesktop ? (
                <div dangerouslySetInnerHTML={{ __html: htmlMainTitleDesktop }}></div>
              ) : (
                <Typography style={{ fontSize: '2.6rem', fontWeight: 'bold', whiteSpace: 'pre-line' }}>
                  {mainTitle}
                </Typography>
              )}
            </div> */}
            <TopBanner />
          </div>
        </div>

        <Container fixed style={{ position: 'relative', minWidth: '1248px', maxWidth: '1248px' }}>
          <Jackpot />
          <Box sx={{ mb: 6, pb: 3, px: '20px', backgroundColor: '#0d0d0d' }}>{contents}</Box>

          {showDepositWithdrawBox ? (
            <Grid container columnSpacing={1.5} sx={{ mb: 6 }}>
              <Grid item xs={4}>
                <NoticeBox></NoticeBox>
              </Grid>
              <Grid item xs={4}>
                <RealtimeDepositBox></RealtimeDepositBox>
              </Grid>
              <Grid item xs={4}>
                <RealtimeWithdrawBox></RealtimeWithdrawBox>
              </Grid>
            </Grid>
          ) : null}

          <SnsLink container columnSpacing={1.5} sx={{ mb: 6 }}>
            {telegram ? (
              <Grid item xs={4}>
                <Box className="main_list">
                  <div className="main_title">
                    <span className="title_wrap">
                      <img src={imgTelegram} />
                      <span className="title">{intl.formatMessage({ id: 'msg.telegram' })}</span>
                    </span>
                    <Button
                      onClick={() => onClickOpenTelegram(telegram)}
                      sx={{
                        textTransform: 'none',
                        fontSize: '1.2rem',
                        paddingRight: '18px',
                      }}
                    >
                      @{telegram}
                    </Button>
                  </div>
                </Box>
              </Grid>
            ) : null}

            {kakaotalk ? (
              <Grid item xs={4}>
                <Box className="main_list">
                  <div className="main_title">
                    <span className="title_wrap">
                      <img src={imgKakaotalk} />
                      <span className="title">{intl.formatMessage({ id: 'msg.kakaotalk' })}</span>
                    </span>
                    <div className="no_link">{kakaotalk}</div>
                  </div>
                </Box>
              </Grid>
            ) : null}
          </SnsLink>

          <Grid
            container
            spacing={1.5}
            sx={{ mb: 6, textAlign: 'center', '& img': { width: 200 } }}
          >
            <Grid item xs={12}>
              <img src={gameTitle01} alt="" />
              <img src={gameTitle02} alt="" />
              <img src={gameTitle03} alt="" />
              <img src={gameTitle04} alt="" />
              <img src={gameTitle05} alt="" />
              <img src={gameTitle06} alt="" />
              <img src={gameTitle07} alt="" />
              <img src={gameTitle08} alt="" />
              <img src={gameTitle09} alt="" />
              <img src={gameTitle10} alt="" />
              <img src={gameTitle11} alt="" />
              <img src={gameTitle12} alt="" />
              <img src={gameTitle13} alt="" />
              <img src={gameTitle14} alt="" />
              <img src={gameTitle15} alt="" />
              <img src={gameTitle16} alt="" />
              <img src={gameTitle17} alt="" />
              <img src={gameTitle18} alt="" />
              <img src={gameTitle19} alt="" />
              <img src={gameTitle20} alt="" />
              <img src={gameTitle21} alt="" />
            </Grid>
          </Grid>
        </Container>
      </HomeContent>
    </>
  )
}

export default observer(DefaultHome)
