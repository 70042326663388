// import { MarketGroup } from './market_group_types'
// export const BASEBALL_INPLAY_MARKET_GROUP: MarketGroup[] = [		// not working in client

export const BASEBALL_INPLAY_MARKET_GROUP = [
  {
    groupId: 1,
    groupName: { en: 'Game Lines', ko: '경기' },
    markets: [
      {
        id: 226,
        unifiedName: '12',
        name: { en: '12 Including Overtime', ko: '승패 (연장포함)' },
      },
      {
        id: 342,
        unifiedName: 'HDP',
        name: {
          en: 'Asian Handicap Including Overtime',
          ko: '핸디캡 (연장포함) *',
        },
      },
      {
        id: 28,
        unifiedName: 'UO',
        name: { en: 'Under/Over Including Overtime', ko: '총 득점 (연장포함)' },
      },
      {
        id: 69,
        unifiedName: 'LIST2',
        name: { en: 'Will The Game Go To Overtime?', ko: '연장전?' },
      },
      {
        id: 563,
        unifiedName: 'N12',
        name: { en: 'Race To Including Overtime', ko: '선취 득점 (연장포함)' },
      },
      {
        id: 322,
        unifiedName: 'NUEO',
        name: {
          en: 'Under/Exactly/Over',
          ko: '총 득점 3Way',
        },
      },
      {
        id: 602,
        unifiedName: 'LIST1',
        name: {
          en: 'Correct Score Including Overtime',
          ko: '정확한 점수 (연장포함)',
        },
      },
    ],
  },
  {
    groupId: 2,
    groupName: { en: 'Period', ko: '이닝' },
    markets: [
      {
        id: 41,
        unifiedName: '1X2',
        name: { en: '1st Period Winner', ko: '승무패 (1이닝)' },
      },
      {
        id: 42,
        unifiedName: '1X2',
        name: { en: '2nd Period Winner', ko: '승무패 (2이닝)' },
      },
      {
        id: 43,
        unifiedName: '1X2',
        name: { en: '3rd Period Winner', ko: '승무패 (3이닝)' },
      },
      {
        id: 44,
        unifiedName: '1X2',
        name: { en: '4th Period Winner', ko: '승무패 (4이닝)' },
      },
      {
        id: 49,
        unifiedName: '1X2',
        name: { en: '5th Period Winner', ko: '승무패 (5이닝)' },
      },
      {
        id: 348,
        unifiedName: '1X2',
        name: { en: '6th Period Winner', ko: '승무패 (6이닝)' },
      },
      {
        id: 434,
        unifiedName: '1X2',
        name: { en: '8th Period Winner', ko: '승무패 (8이닝)' },
      },
      {
        id: 21,
        unifiedName: 'UO',
        name: { en: 'Under/Over 1st Period', ko: '총 득점 (1이닝)' },
      },
      {
        id: 45,
        unifiedName: 'UO',
        name: { en: 'Under/Over 2nd Period', ko: '총 득점 (2이닝)' },
      },
      {
        id: 46,
        unifiedName: 'UO',
        name: { en: 'Under/Over 3rd Period', ko: '총 득점 (3이닝)' },
      },
      {
        id: 47,
        unifiedName: 'UO',
        name: { en: 'Under/Over 4th Period', ko: '총 득점 (4이닝)' },
      },
      {
        id: 48,
        unifiedName: 'UO',
        name: { en: 'Under/Over 5th Period', ko: '총 득점 (5이닝)' },
      },
      {
        id: 352,
        unifiedName: 'UO',
        name: { en: 'Under/Over 6th Period', ko: '총 득점 (6이닝)' },
      },
      {
        id: 353,
        unifiedName: 'UO',
        name: { en: 'Under/Over 7th Period', ko: '총 득점 (7이닝)' },
      },
      {
        id: 436,
        unifiedName: 'UO',
        name: { en: 'Under/Over 8th Period', ko: '총 득점 (8이닝)' },
      },
    ],
  },
  {
    groupId: 3,
    groupName: { en: 'Team', ko: '팀' },
    markets: [
      {
        id: 221,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over - Home Team Including Overtime',
          ko: '총 득점 - 홈팀 (연장포함)',
        },
      },
      {
        id: 220,
        unifiedName: 'UO',
        name: {
          en: 'Under/Over - Away Team Including Overtime',
          ko: '총 득점 - 원정팀 (연장포함)',
        },
      },
      // 홈
      {
        id: 505,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 1st inning',
          ko: '득점 - 홈팀 (1이닝)',
        },
      },
      {
        id: 506,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 2nd inning',
          ko: '득점 - 홈팀 (2이닝)',
        },
      },
      {
        id: 507,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 3rd inning',
          ko: '득점 - 홈팀 (3이닝)',
        },
      },
      {
        id: 508,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 4th inning',
          ko: '득점 - 홈팀 (4이닝)',
        },
      },
      {
        id: 509,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 5th inning',
          ko: '득점 - 홈팀 (5이닝)',
        },
      },
      {
        id: 510,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 6th inning',
          ko: '득점 - 홈팀 (6이닝)',
        },
      },
      {
        id: 511,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 7th inning',
          ko: '득점 - 홈팀 (7이닝)',
        },
      },
      {
        id: 512,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - home team 8th inning',
          ko: '득점 - 홈팀 (8이닝)',
        },
      },
      // 원정
      {
        id: 514,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 1st inning',
          ko: '득점 - 원정팀 (1이닝)',
        },
      },
      {
        id: 515,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 2nd inning',
          ko: '득점 - 원정팀 (2이닝)',
        },
      },
      {
        id: 516,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 3rd inning',
          ko: '득점 - 원정팀 (3이닝)',
        },
      },
      {
        id: 517,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 4th inning',
          ko: '득점 - 원정팀 (4이닝)',
        },
      },
      {
        id: 518,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 5th inning',
          ko: '득점 - 원정팀 (5이닝)',
        },
      },
      {
        id: 520,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 7th inning',
          ko: '득점 - 원정팀 (7이닝)',
        },
      },
      {
        id: 521,
        unifiedName: 'LIST2',
        name: {
          en: 'A Run Scored - away team 8th inning',
          ko: '득점 - 원정팀 (8이닝)',
        },
      },
    ],
  },
]
