import { css, Theme } from '@emotion/react'
import { styled } from '@mui/material/styles'

export const PointStyle = styled('div')`
  overflow: hidden;
  
  .point_title {
    text-align: center;
    font-weight: bold;
    font-size: 1.2rem;
  }
  .point_box {
    padding: 2rem;
    border: 1px solid #1E2B3D;
    border-radius: 1rem;
    background-color: #16202F;
  }
  .center_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 90px;
    left: 50%;
    margin-left: 17px;
    width: 46px;
    height: 46px;
    border: 1px solid #1E2B3D;
    border-radius: 50%;
    background-color: #16202F;
    color: #D1D9E3;
  }

  // 포인트 내 input 스타일 정의
  .form_control {
    text-align: end;
    font-weight: bold;

    &:read-only {
      border-color: #2B384D;
      background-color: #0F151E;
      color: #D1D9E3;

      &:focus {
        outline: none;
      }
    }
  }
    
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    appearance: none;
  }
`